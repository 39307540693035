export const logDataDog = (key: string | undefined, title: string, text: string, tags?: string[]) => {
	try {
		fetch('/api/dd', {
			method: 'POST',
			body: JSON.stringify({
				key: key,
				title: title,
				text: text,
				tags: tags,
			}),
			headers: {
				'Content-Type': 'application/json',
			},
		})
	} catch (e) {
		console.log(e)
	}
}
