import { IconComponentType } from '../../types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 41 40"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			fill={color}
			d="M40.64,8.14c-.26-.26-.64-.4-1.06-.36-1.48.12-2.78.32-4.02.62-.8.2-1.54.46-2.32.72-.06-2.04-.44-4.04-1.16-6.1-.18-.5-.42-.82-.76-.96-.48-.2-.94,0-1.26.14-1.84.9-3.54,2.04-5.1,3.46-.8-1.76-1.9-3.4-3.32-5.04-.36-.42-.74-.62-1.12-.62-.44,0-.76.2-1.14.62-1.4,1.56-2.52,3.26-3.32,5.04-1.46-1.34-3.16-2.5-5.04-3.44-.34-.16-.82-.36-1.3-.16-.46.18-.66.66-.78,1-.72,2.02-1.08,4-1.14,6.06-2.02-.72-4.06-1.16-6.2-1.32-.56-.06-.96.08-1.24.36-.2.2-.4.56-.34,1.18v.12c.08.64.16,1.26.28,1.9,1.36,7.18,5.34,12.42,11.86,15.54,2.94,1.42,5.58,2.1,8.16,2.1.82,0,1.64-.08,2.46-.2,5.82-.98,10.52-3.74,13.94-8.2,2.5-3.24,3.94-7.06,4.28-11.34.04-.44-.08-.86-.36-1.12ZM38.4,10.36c-.7,4.5-2.7,8.3-5.96,11.28-2.52,2.3-5.58,3.82-9.1,4.56,2.06-3.04,3.26-6.42,3.56-10.06.02-.06.02-.1.12-.2,2.92-2.88,6.44-4.72,10.5-5.44l.36-.06c.18-.02.36-.04.52-.08ZM26.6,7.5c.22-.18.44-.36.68-.56.62-.48,1.18-.96,1.82-1.36.32-.22.66-.4.98-.6.5,1.7.7,3.42.66,5.24-1.36.68-2.62,1.5-3.84,2.5-.08-1.18-.26-2.52-.74-3.82-.24-.66-.14-.96.44-1.4ZM10.94,4.98c1.48.84,2.84,1.88,4.14,3.16-.48,1.42-.8,2.96-.96,4.58-.9-.76-1.98-1.56-3.2-2.14-.58-.28-.68-.54-.66-1.18.08-1.54.28-3,.68-4.42ZM14.66,25.34c-6.48-2.62-10.48-7.38-11.88-14.18-.04-.18-.08-.38-.1-.56-.02-.08-.02-.16-.04-.24,2.46.34,4.76,1.08,6.8,2.18,1.26.68,2.52,1.56,3.84,2.7.6.5.86.98.94,1.66.32,3.28,1.5,6.4,3.46,9.32-.98-.22-2.02-.48-3.02-.88ZM16.6,15.98l-.04-.32c-.02-.14-.02-.26-.04-.38v-.76c.06-4.22,1.38-8.02,4-11.4,2.76,3.56,4.1,7.58,3.98,11.98-.12,3.94-1.46,7.54-3.98,10.82-2.32-2.98-3.64-6.32-3.92-9.94Z"
		/>
	</svg>
)

export default icon
