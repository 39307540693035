import { FC } from 'react'
import styles from './CheckboxSelection.module.scss'
import { CheckboxSelectionItemProps, CheckboxSelectionProps } from './CheckboxSelection.types'
import * as React from 'react'
import { Icon } from 'components/Phantom/Icon'
import { PricingInfo } from 'components/Phantom/_shop/PricingInfo'
import { Button } from 'components/Phantom/Button'
import { Type } from 'components/Type'
import useABTestVariant from 'hooks/useABTestVariant'
import { shippingTimelinesAB } from 'ab_testing'

export const CheckboxSelection: FC<CheckboxSelectionProps> = (props) => {
	const { onChange, options, error, currentSelections, onModalTrigger } = props
	const isTimelinesAB = useABTestVariant(shippingTimelinesAB, 1)

	if (!options || options.length === 0) return null

	return (
		<div className={styles.container}>
			{options.map((option) => {
				const trueValue = option.trueValue || 'true'
				const falseValue = option.falseValue || 'false'
				return (
					<label
						className={styles.option}
						key={option.selectionKey}
						data-selected={currentSelections[option.selectionKey] === trueValue}
					>
						<input
							type={'checkbox'}
							checked={currentSelections[option.selectionKey] === trueValue}
							onChange={(e) => {
								if (e.target.checked) {
									onChange(option.selectionKey, trueValue)
								} else {
									onChange(option.selectionKey, falseValue)
								}
							}}
							className={styles.checkbox}
						/>

						<span>
							<Type.Headline6
								as={'span'}
								className={styles.title}
							>
								{option.title}
							</Type.Headline6>
							<Type.Body3
								as={'span'}
								className={styles.description}
							>
								{option.description}
							</Type.Body3>

							<Icons {...option} />

							<PricingInfo
								price={option.pricingData.price}
								comparePrice={option.pricingData.strikePrice}
								discountText={option.pricingData.discountText}
							/>
							{isTimelinesAB && (
								<Type.Body3
									className={styles.shipping_text}
									as={'span'}
								>
									Ships separately from Pod
								</Type.Body3>
							)}

							{option.modal && (
								<Button.Empty
									id={`${option.modal.modalKey}-modal-trigger`}
									onClick={() => onModalTrigger(option.modal.modalKey)}
									className={styles.modal_trigger}
								>
									{option.modal.text}
								</Button.Empty>
							)}
						</span>
					</label>
				)
			})}
		</div>
	)
}

const Icons: FC<CheckboxSelectionItemProps> = (props) => {
	const { icons } = props

	if (!icons || icons.length === 0) return null

	return (
		<ul className={styles.icon_list}>
			{icons.map((icon, i) => (
				<li
					className={styles.icon_row}
					key={`upsell-icon-${i}`}
				>
					<Icon
						name={icon.icon}
						color={'#a0a0a0'}
						size={16}
					/>
					<Type.Body3>{icon.text}</Type.Body3>
				</li>
			))}
		</ul>
	)
}
