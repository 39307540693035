import { FC, PropsWithChildren, ReactNode, useEffect, useRef, useState } from 'react'
import styles from './FeaturedReviews.module.scss'
import { FeaturedReviewsProps } from './types'
import { ArticleCard, TweetCard, VideoCard } from 'components/WallOfLove'
import { elon, graham, hamilton, rogan, seanJohnson, trexler, wells, whyteQuote, zero, zuck } from 'components/WallOfLove/wallOfLoveItems'
import { QuoteCard } from 'components/WallOfLove/QuoteCard'
import { Button } from 'components/Phantom/Button'
import { Type } from 'components/Type'
import { useAnimateInOnScroll } from 'components/_hooks/useAnimateInOnScroll'

export const FeaturedReviews: FC<FeaturedReviewsProps> = (props) => {
	const { cta = true, showZero = true } = props
	const renderCTA = cta ? (
		<div className={styles.cta_wrapper}>
			<Button.Dark
				id={'featured-reviews-cta'}
				href={'/wall-of-love'}
			>
				View more reviews
				<svg
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M7.51161 5.41686L14.7136 5.28591M14.7136 5.28591L14.5827 12.4879M14.7136 5.28591L5.28553 14.714"
						stroke="white"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</Button.Dark>
		</div>
	) : null
	return (
		<section className={styles.container}>
			<div className={styles.max_width_wrapper}>
				<Type.Headline2 className={styles.heading}>
					{props.title ?? (
						<>
							Thousands of trusted reviews
							<br />
							<span> from extraordinary people like you</span>
						</>
					)}
				</Type.Headline2>
				<div className={styles.wrapper}>
					<Masonry
						items={[
							{
								node: (
									<VideoCard
										{...wells}
										video={'https://d115sb6i1ixllw.cloudfront.net/assets/e52893b038b441cc9f5bd05d5fdd8341.mp4'}
										autoplay={false}
										id={'featured-reviews-wells'}
									/>
								),
								approximateHeight: 520,
							},
							{
								node: (
									<TweetCard
										{...zuck}
										id={'featured-reviews-zuck'}
									/>
								),
								approximateHeight: 150,
							},
							{
								node: (
									<ArticleCard
										{...hamilton}
										disableLink
										id={'featured-reviews-hamilton'}
									/>
								),
								approximateHeight: 520,
							},
							{
								node: showZero ? (
									<TweetCard
										{...zero}
										id={'featured-reviews-zero'}
									/>
								) : (
									<TweetCard
										{...seanJohnson}
										id={'featured-reviews-sean-johnson'}
									/>
								),
								approximateHeight: 150,
							},
							{
								node: (
									<TweetCard
										{...trexler}
										id={'featured-reviews-trexler'}
									/>
								),
								approximateHeight: 225,
							},
							{
								node: (
									<VideoCard
										{...rogan}
										video={'https://d115sb6i1ixllw.cloudfront.net/assets/9393dd7e2d3c4ef39e703c91d6ba5d45.mp4'}
										autoplay={false}
										id={'featured-reviews-rogan'}
									/>
								),
								approximateHeight: 520,
							},

							{
								node: (
									<QuoteCard
										{...whyteQuote}
										className={styles.quote_card}
									/>
								),
								approximateHeight: 400,
							},
							{
								node: (
									<TweetCard
										{...graham}
										id={'featured-reviews-graham'}
									/>
								),
								approximateHeight: 150,
							},

							{
								node: (
									<TweetCard
										{...elon}
										id={'featured-reviews-elon'}
									/>
								),
								approximateHeight: 150,
							},
						]}
					/>
				</div>
				{renderCTA}
			</div>
		</section>
	)
}

const Masonry: FC<{
	items: {
		node: ReactNode
		approximateHeight: number
	}[]
}> = ({ items }) => {
	const [layout, setLayout] = useState<ReactNode[][]>([])

	useEffect(() => {
		const resizeHandler = () => {
			const getNumColumns = (windowWidth: number) => {
				if (windowWidth >= 1000) return 4
				if (windowWidth >= 700) return 3
				if (windowWidth >= 375) return 2
				return 1
			}

			const numColumns = getNumColumns(window.innerWidth)
			if (layout.length === numColumns) return // Prevent layout re-calculation if the number of columns hasn't changed

			const columns: ReactNode[][] = new Array(numColumns).fill(null).map(() => [])
			const columnHeights: number[] = new Array(numColumns).fill(0)

			for (let i = 0; i < items.length; i++) {
				const item = items[i]
				const minColumnIndex = columnHeights.indexOf(Math.min(...columnHeights))
				columns[minColumnIndex].push(item.node)
				columnHeights[minColumnIndex] += item.approximateHeight
			}
			setLayout(columns)
		}

		resizeHandler()

		window.addEventListener('resize', resizeHandler)

		return () => {
			window.removeEventListener('resize', resizeHandler)
		}
	}, [layout.length])

	return (
		<div className={styles.masonry}>
			{layout.map((column, index) => (
				<ul key={index}>
					{column.map((item, index) => (
						<MasonryItem key={index}>{item}</MasonryItem>
					))}
				</ul>
			))}
		</div>
	)
}

const MasonryItem: FC<PropsWithChildren> = (props) => {
	const ref = useRef<HTMLLIElement>(null)
	useAnimateInOnScroll(ref)
	return (
		<li
			className={styles.masonry_item}
			ref={ref}
		>
			{props.children}
		</li>
	)
}
