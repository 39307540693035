import React, { FC } from 'react'
import { RegionShort, regionIsEU } from 'utils/internationalization'
import styles from 'components/WebEv/Shop/Modals/Modals.module.scss'
import { SizeGuideBed, SizeGuideBedProps } from 'components/SizeGuideBed/SizeGuideBed'
import { useRootStore } from 'components/_hooks/useRootStore'
import { observer } from 'mobx-react'

export const SizesModal = observer(() => {
	const { settingsStore } = useRootStore()
	const region = settingsStore.currentRegion

	return (
		<div className={styles.modal_inner}>
			<h2 className={styles.title}>Size guide</h2>

			<div className={styles.size_table}>
				<SizeTable region={region} />
			</div>
		</div>
	)
})

export const SizeTable = (props: { region: RegionShort }) => {
	const { region } = props

	if (region === 'au') {
		return SizesAU()
	}
	if (region === 'uk') {
		return SizesUK()
	}
	if (regionIsEU(region)) {
		return SizesEU()
	}
	if (region === 'ca') {
		return SizesCA()
	}
	if (region === 'ae') {
		return SizesAE()
	}
	return SizesUS()
}

export const SizesModalDisconnected = (props: { region: RegionShort }) => {
	const { region } = props
	return (
		<div className={styles.modal_inner}>
			<h2 className={styles.title}>Size guide</h2>

			<div className={styles.size_table}>
				<SizeTable region={region} />
			</div>
		</div>
	)
}

const SizesUS = () => (
	<ul>
		<li>
			<BedSizeEntry
				title={'Full'}
				length={75}
				width={53}
				unit={'in'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'Queen'}
				length={80}
				width={60}
				unit={'in'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'King'}
				length={80}
				width={76}
				unit={'in'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'Cali King'}
				length={84}
				width={72}
				unit={'in'}
				sizeMultiplier={0.75}
			/>
		</li>
	</ul>
)

const SizesCA = () => (
	<ul>
		<li>
			<BedSizeEntry
				title={'CA Full'}
				length={190}
				width={135}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'CA Queen'}
				length={200}
				width={150}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'CA King'}
				length={200}
				width={190}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
	</ul>
)

const SizesAE = () => (
	<ul>
		<li>
			<BedSizeEntry
				title={'AE Queen'}
				length={200}
				width={160}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'AE King'}
				length={200}
				width={180}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'AE Emperor'}
				length={200}
				width={200}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
	</ul>
)

const SizesEU = () => (
	<ul>
		<li>
			<BedSizeEntry
				title={'Double'}
				length={190}
				width={135}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'Large Double'}
				length={200}
				width={160}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'Super King'}
				length={200}
				width={180}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'Emperor'}
				length={200}
				width={200}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
	</ul>
)

const SizesAU = () => (
	<ul>
		<li>
			<BedSizeEntry
				title={'Double'}
				length={190}
				width={135}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'Queen'}
				length={200}
				width={150}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'King'}
				length={200}
				width={180}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
	</ul>
)

const SizesUK = () => (
	<ul>
		<li>
			<BedSizeEntry
				title={'UK Double'}
				length={190}
				width={135}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'UK King'}
				length={200}
				width={150}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'UK Super King'}
				length={200}
				width={180}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
		<li>
			<BedSizeEntry
				title={'UK Emperor'}
				length={200}
				width={200}
				unit={'cm'}
				sizeMultiplier={0.75}
			/>
		</li>
	</ul>
)

interface BedSizeEntryProps extends SizeGuideBedProps {
	title: string
}

const BedSizeEntry: FC<BedSizeEntryProps> = (props) => (
	<>
		<p>{props.title}</p>
		<SizeGuideBed {...props} />
	</>
)
