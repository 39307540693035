import { FC } from 'react'
import styles from './Benefits.module.scss'
import { BenefitLogo, BenefitsProps } from './Benefits.types'
import { Type } from 'components/Type'
import { Icon } from 'components/Phantom/Icon'
import { Img } from 'components/basic/Img'

export const Benefits: FC<BenefitsProps> = (props) => {
	const { benefits = getDefaultBenefits(), logos = getLogos() } = props
	return (
		<section className={styles.container}>
			<Img
				src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-waitlist/waitlist-nightly-new-desktop-background.png'}
				alt=""
				className={styles.background_img}
				objectFit={'cover'}
				sources={[
					{
						src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-waitlist/benefits_mobile_new.jpg',
						mediaQuery: '(max-width: 1023px)',
						dprHeight: 1500,
					},
					{
						src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-waitlist/waitlist-nightly-new-desktop-background.png',
						mediaQuery: '(min-width: 1024px)',
						dprHeight: 1500,
					},
				]}
			/>
			<div className={styles.content_wrapper}>
				<div className={styles.benefits_container}>
					<header>
						<Type.Headline2 animateOnScroll>Fit for nightly use</Type.Headline2>
						<Type.Body1 animateOnScroll>Take 1–3 capsules, 60 minutes before bed every night, to prepare your body for optimal rest.</Type.Body1>
					</header>
					<ul className={styles.benefits}>
						{benefits.map((benefit, i) => (
							<li key={benefit}>
								<Icon
									name={'CheckCircleDark'}
									color={'white'}
									size={24}
								/>
								<Type.Headline6 as={'p'}>{benefit}</Type.Headline6>
							</li>
						))}
					</ul>
					{/*<ul className={styles.logos}>*/}
					{/*	{logos.map((item, i) => (*/}
					{/*		<Img*/}
					{/*			src={item.src}*/}
					{/*			key={item.src}*/}
					{/*			alt={item.alt}*/}
					{/*			className={styles.logo}*/}
					{/*		></Img>*/}
					{/*	))}*/}
					{/*</ul>*/}
				</div>
			</div>
		</section>
	)
}

const getDefaultBenefits = (): BenefitsProps['benefits'] => [
	'Non-habit forming',
	'Natural ingredients',
	'No melatonin',
	'Free of heavy metals',
	'No added sugar or artificial sweeteners',
	'Made in the USA',
]

const getLogos = (): BenefitLogo[] => [
	{
		src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-waitlist/fda.png',
		alt: 'FDA approved',
	},
	{
		src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-waitlist/madeinusa.png',
		alt: 'Made in USA',
	},
	{
		src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-waitlist/usp.png',
		alt: 'USP approved',
	},
]
