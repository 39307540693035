import { GetStaticPaths, GetStaticProps } from 'next'
import { inject, observer } from 'mobx-react'
import { RootStore } from 'stores/index'
import { generatePathPermutationsForExperiments } from 'ab_testing'
import { config } from 'utils/internationalization'
import Homepage from 'pageCores/homepage/Homepage'

interface IStaticProps {
	rootStore: RootStore
}

interface RegionPath {
	params: {
		index: string
		locale: string
	}
}

function Index() {
	return <Homepage />
}

export const getStaticProps: GetStaticProps = async () => {
	const props: Partial<IStaticProps> = {}

	return {
		props,
	}
}

export const getStaticPaths: GetStaticPaths = async () => {
	const regionPaths: RegionPath[]  = config.allRegions.map((it) => ({ params: { index: '', locale: it } }))
	regionPaths.push({ params: { index: 'sa', locale: 'sa' } })
	const paths = [...regionPaths].flatMap((it) => generatePathPermutationsForExperiments(it.params.index).map((p) => ({ params: { locale: it.params.locale, experiments: p } })))
	return {
		paths,
		fallback: false,
	}
}

export default inject('rootStore')(observer(Index))
