import * as React from 'react'
import { Main as MainLayout } from 'layouts'
import { AppMetadata } from 'components/AppMetadata'
import Head from 'next/head'
import { AppTour, EvolvingFormulaConnected, Ingredients, SleepElixirStats, SleepFitness, TextChangeSingle } from './SleepElixirLP/components'
import styles from '../pagestyles/sleep-elixir-pdp.module.scss'
import { ShopStoreState, VariantData } from 'stores/shop-store'
import { ATC } from 'components/ATC'
import { Pod4FAQ } from 'components/Pod4FAQ'
import { FourColumnBenefits } from 'components/FourColumnBenefits'
import { BenefitProps } from 'components/_utils/utilityTypes'
import { getSleepElixirVisuals } from 'shop/visualData/sleepElixirVisuals'
import { UserReviews } from './SleepElixirLP/components/UserReviews'
import { ExpertReviews } from './SleepElixirLP/components/ExpertReviews'
import { Rituals } from './SleepElixirLP/components/Rituals'
import { Attribute, LineItem, NewCartStore } from 'cart'
import { sendTrackingEvents } from 'components/WebEv/Shop/context/checkoutUtils'
import { createCartLineItem, getProductByVariantId } from 'products/utils'
import { Currency } from 'prices/types'
import { useState } from 'react'

const Benefits = (): BenefitProps[] => [
	{
		icon: 'MeditationLight',
		text: 'Calms your mind',
		secondaryText: 'Unwind faster, experience fewer racing thoughts',
	},
	{
		icon: 'MoonLight',
		text: `Improves sleep cycles`,
		secondaryText: "Align with your body's natural cycles for deeper, more restful sleep",
	},
	{
		icon: 'RefreshDark',
		text: `Non-habit forming`,
		secondaryText: 'Safe for nightly use, with benefits that grow over time',
	},
]

const onAtcClick = async (cartStore: NewCartStore, variantKey: string, variant: VariantData, buttonID: string, currency: Currency) => {
	const product = getProductByVariantId(variant.id)

	const attributes: Attribute[] = [{ key: 'size', value: variantKey }]

	const item: LineItem = {
		variantId: variant.id,
		quantity: 1,
		attributes: attributes,
	}

	const itemsToAdd: LineItem[] = []
	itemsToAdd.push(item)

	sendTrackingEvents(
		createCartLineItem(
			{
				id: variant.id,
				name: variant.name,
				description: [variant.description],
				key: variantKey,
				currency: currency,
				prices: {
					comparePrice: variant.comparePrice,
					price: variant.price,
					comparePriceString: variant.comparePriceString,
					priceString: variant.priceString,
					currency: currency,
				},
			},
			product,
			null
		),
		buttonID
	)

	await cartStore.addItems(itemsToAdd)
	cartStore.redirectToCheckout()
}

export const SleepElixirPDP = ({ pagePath, initialShopStoreState }: { pagePath: string; initialShopStoreState: ShopStoreState }) => {
	const [currentVariant, setCurrentVariant] = useState<VariantData | null>(null)
	return (
		<MainLayout>
			<Head>
				{getSleepElixirVisuals().map((visual) => (
					<link
						rel="preload"
						as="image"
						href={visual.data.src}
						key={visual.data.src}
					/>
				))}
			</Head>
			<AppMetadata
				path={pagePath}
				key={pagePath}
			/>
			{/* <SimpleSticky
				productName={initialShopStoreState.data.productTitle}
				ctaLabel="Add to cart"
				currentVariant={currentVariant}
				benefits={[currentVariant && currentVariant.id === SupplementProducts.SleepElixir.variants.monthly.id ? initialShopStoreState.data.features[2] : initialShopStoreState.data.features[1]].map(
					(it) => ({
						text: it.title,
						icon: it.icon,
					})
				)}
				handleCheckout={onAtcClick}
			/> */}
			<ATC
				initialShopStoreState={initialShopStoreState}
				handleCheckout={onAtcClick}
				setCurrentVariant={setCurrentVariant}
			/>
			<FourColumnBenefits
				benefits={Benefits()}
				header={'Sleep Elixir helps you ease into sleep, faster, by leveraging over 600 million hours of sleep data and 100% natural ingredients'}
			/>
			<TextChangeSingle className={styles.text_change} />
			<SleepElixirStats />
			<UserReviews />
			<Ingredients />
			{/* <ExpertReviews /> until we get actual quotes */}
			<Rituals />
			<AppTour />
			<EvolvingFormulaConnected />
			<SleepFitness />
			<Pod4FAQ
				faq={[
					{
						question: 'Who should take Sleep Elixir? ',
						answer: 'Sleep Elixir was designed for adults 18+.',
					},
					{
						question: 'When should I take Sleep Elixir? ',
						answer: 'For best results, take Sleep Elixir 60-30 minutes before bed.',
					},
					{
						question: 'Is Sleep Elixir habit-forming or addictive?',
						answer: 'No, Sleep Elixir is not habit-forming and does not create a dependency. It supports healthy sleep patterns without leading to addiction or withdrawal effects.',
					},
					{
						question: 'Can I take Sleep Elixir with other medications or supplements?',
						answer:
							"Sleep Elixir may interact with prescription sleep medications, sedatives and antidepressants among others. Always consult your healthcare professional if you're taking other medications or supplements.",
					},
					{
						question: 'Can I take Sleep Elixir while pregnant or lactating?',
						answer: 'It is not advisable to take Sleep Elixir while pregnant or lactating. For more specific advice, please consult with your healthcare provider.',
					},
					{
						question: 'Can I take Sleep Elixir if I have a sleep disorder?',
						answer: 'Always consult your health care practitioner before using any dietary supplement if you are pregnant or nursing, using medications or have a medical condition.',
					},
					{
						question: 'Can I take Sleep Elixir if I have allergies or dietary restrictions?',
						answer: 'Sleep Elixir is suitable for most individuals. However, if you have specific dietary restrictions or allergies, always consult your healthcare professional before use.',
					},
					{
						question: 'Is Sleep Elixir designed for long-term use?',
						answer: 'Yes, Sleep Elixir is safe for long-term use. It is formulated with natural ingredients that work synergistically to improve your sleep patterns with continued use. ',
					},
					{
						question: 'Can I take more than 3 capsules per night?',
						answer: 'We don’t recommend exceeding 3 capsules per night. If you have any specific questions or concerns, always consult with your health care practitioner.',
					},
					{
						question: 'What testing do you do on Sleep Elixir?',
						answer:
							'Sleep Elixir is produced in FDA Registered and Inspected Facilities that comply with FDA 21 CFR 111 standards and are cGMP Certified. Before production, all raw materials are tested for purity and quality. Certificates of Analysis (COAs) are performed on each batch of Sleep Elixir to ensure the final product meets high standards, including testing for heavy metals, microbes, allergens, and contaminants.',
					},
				]}
			/>
		</MainLayout>
	)
}
