import React, { FC } from 'react'

export interface RadioButtonValues {
	value: string
	groupName: string
	component: React.ReactNode
}

export interface RadioButtonProps extends RadioButtonValues {
	checked: boolean
	onSelect: (value: string) => void
}

export const RadioButton: FC<RadioButtonProps> = (props) => {
	const { value, groupName, checked, component } = props
	function onChange() {
		props.onSelect(value)
	}

	return (
		<label>
			<input
				type="radio"
				name={groupName}
				value={value}
				onChange={onChange}
				checked={checked}
			/>
			{component}
		</label>
	)
}
