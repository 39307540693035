import { FC, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import styles from './ImageCarousel.module.scss'
import { ImageCarouselHandle, ImageCarouselProps } from './ImageCarousel.types'
import { useSwipe } from 'components/_hooks/useSwipe'
import { clamp } from 'components/_utils/mathUtils'
import { amSwipeImageCarousel } from 'events/amplitude'
import { MediaRendered } from './subcomponents/MediaRendered'
import { NavigationButtons } from './subcomponents/NavigationButtons'
import { Thumbnails } from './subcomponents/Thumbnails'
import { BenefitProps } from 'components/_utils/utilityTypes'
import { Icon } from 'components/Phantom/Icon'
import { Type } from 'components/Type'
import useIsMobile from 'hooks/useIsMobile'

export const ImageCarousel = forwardRef<ImageCarouselHandle, ImageCarouselProps>((props, ref) => {
	const { items, maxThumbnails, useDots = false } = props

	const sliderRef = useRef<HTMLUListElement>(null)

	const [currentIndex, setCurrentIndex] = useState(0)

	const isMobile = useIsMobile(600)

	useSwipe(
		sliderRef,
		(direction: 'left' | 'right') => {
			const length = items.length
			amSwipeImageCarousel(direction, props.id)
			if (direction === 'left') {
				setCurrentIndex((prev) => Math.min(length - 1, prev + 1))
			} else if (direction === 'right') {
				setCurrentIndex((prev) => Math.max(0, prev - 1))
			}
		},
		50,
		500,
		[items.length]
	)

	useImperativeHandle(ref, () => ({
		goToSlide: (index: number) => {
			setCurrentIndex(index)
		},
	}))

	useEffect(() => {
		setCurrentIndex(0)
	}, [props.id, items.length])

	const clampedIndex = clamp(currentIndex, 0, items.length - 1)

	return (
		<div className={`${styles.container} ${props.className}`}>
			<ul
				className={styles.media_stack}
				ref={sliderRef}
			>
				{items.map((item, index) => (
					<li
						key={`${index}`}
						className={styles.media_item}
						style={{
							transform: `translateX(calc(${(index - clampedIndex) * 100.1}%))`,
						}}
					>
						{item.pills && <Pills pills={isMobile ? item.pills.slice(0, 2) : item.pills} />}
						<MediaRendered
							{...item}
							active={clampedIndex === index}
						/>
					</li>
				))}

				<NavigationButtons
					items={items}
					itemsLength={items.length}
					currentIndex={clampedIndex}
					setCurrentIndex={setCurrentIndex}
				/>
			</ul>

			<Thumbnails
				items={items}
				currentIndex={clampedIndex}
				setCurrentIndex={setCurrentIndex}
				useDots={useDots}
				maxThumbnails={maxThumbnails}
			/>
		</div>
	)
})

const Pills: FC<{ pills: BenefitProps[] }> = (props) => {
	const { pills } = props
	if (!pills) return null

	return (
		<ul className={styles.pills}>
			{pills.map((benefit, i) => (
				<li key={i}>
					<span className={styles.pill}>
						{benefit.icon && (
							<Icon
								name={benefit.icon}
								color={'black'}
								size={16}
							/>
						)}
						<Type.Eyebrow>{benefit.text}</Type.Eyebrow>
					</span>
				</li>
			))}
		</ul>
	)
}
