import { forwardRef, VideoHTMLAttributes } from 'react'
import { srcToImgix } from 'components/basic/Img'
import useIsMobile from 'hooks/useIsMobile'

interface SourceProps {
	src: string
	type: string
}

interface VideoGifProps extends VideoHTMLAttributes<HTMLVideoElement> {
	sources?: SourceProps[]
	mobileSrc?: string
}

const processVideoPoster = (videoProps: VideoGifProps) => {
	if (!videoProps) return undefined
	if (!videoProps.poster) return undefined

	return srcToImgix(videoProps.poster)
}

export const VideoGif = forwardRef<HTMLVideoElement, VideoGifProps>((props, ref) => {
	const { sources, ...videoProps } = props

	const poster = processVideoPoster(videoProps)
	const isMobile = useIsMobile(1024)
	const getSrc = () => {
		if (!videoProps.mobileSrc) return videoProps.src
		if (isMobile) return videoProps.mobileSrc
		return videoProps.src
	}

	const src = getSrc()

	const videoPropsWithoutGifProps = { ...props }
	delete videoPropsWithoutGifProps.sources
	delete videoPropsWithoutGifProps.mobileSrc

	return (
		<video
			playsInline
			disablePictureInPicture
			disableRemotePlayback
			autoPlay
			loop
			muted
			ref={ref}
			{...videoPropsWithoutGifProps}
			src={src}
			poster={poster}
			key={src}
		>
			{sources?.map((source, index) => (
				<source
					key={index}
					src={source.src}
					type={source.type}
				/>
			))}
		</video>
	)
})

VideoGif.displayName = 'VideoGif'
