import type { NextRouter } from 'next/router'
import { RegionShort } from 'utils/internationalization'

export const getNormalizedURL = (router: NextRouter): string => {
	const queryRegion = router.query.locale as RegionShort
	const isDynamicRoute = router.pathname.includes('[experiments]')
	const tempPath = (
		typeof window !== 'undefined'
			? router.asPath
			: '/' +
			  router.asPath
					.split('/')
					.slice(isDynamicRoute ? 3 : 1)
					.join('/')
	)
		.replace('#', '')
		.replaceAll('//', '/')

	return tempPath.replace(`/${queryRegion}/`, '/')
}

/**
 * Gets the current environment based on the hostname. **This function should not be used in SSR settings.**
 */
export const getEnv = (): 'localhost' | 'dev' | 'prod' => {
	if (typeof window === 'undefined') {
		return 'prod'
	}

	if (window.location.hostname === 'localhost') {
		return 'localhost'
	}

	if (window.location.hostname.includes('dev') || window.location.hostname.includes('vercel')) {
		return 'dev'
	}

	return 'prod'
}

export const getBaseURL = () => {
	switch (getEnv()) {
		case 'localhost':
			return 'http://localhost:3000'
		case 'dev':
			return 'https://eightsleep-git-pod4-eightsleep.vercel.app'
		case 'prod':
			return 'https://eightsleep.com'
	}
}

export const generateRandomState = (): string => {
	return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
}
