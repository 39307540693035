import React, { CSSProperties, FC, useState } from 'react'
import styles from './FinancingTabModal.module.scss'
import { FinancingTabModalProps } from './FinancingTabModal.types'
import { Button } from 'components/Phantom/Button'
import { FinancingProvider } from 'components/_utils/utilityTypes'
import { AffirmLogo } from 'components/_const_assets/affirmLogo'
import { KlarnaLogo } from 'components/_const_assets/klarnaLogo'
import { observer } from 'mobx-react'
import { useRootStore } from 'components/_hooks/useRootStore'
import { PriceManager } from 'prices'
import { ItemizedSelection, PaymentOption } from 'shop/Shop.types'
import { Currency } from 'prices/types'
import Tippy from '@tippyjs/react'
import { Icon } from 'components/Phantom/Icon'

export const FinancingTabModalConnected = observer(() => {
	const { priceStore, shopStore } = useRootStore()

	const financingProvider = priceStore.financingProvider

	const showAutopilotPricing = shopStore.showAutopilotPricing
	const itemizedSelections = shopStore.itemizedSelections.filter((it) => {
		if (showAutopilotPricing) return true
		return !it.subscription
	})

	const shopTotal = shopStore.shopTotal

	const paymentOptions: PaymentOption[] = ['cash']
	if (financingProvider !== 'none') paymentOptions.push(financingProvider)
	if (priceStore.currency === 'USD') paymentOptions.push('truemed')

	return (
		<FinancingTabModal
			paymentOptions={paymentOptions}
			itemizedSelections={itemizedSelections}
			shopTotal={shopTotal}
			currency={priceStore.currency}
			selectedTab={shopStore.paymentOption}
			setSelectedTab={shopStore.setPaymentOption}
		/>
	)
})

export const PaymentOptionTabsConnected = observer(() => {
	const { priceStore, shopStore } = useRootStore()
	const financingProvider = priceStore.financingProvider

	const paymentOptions: PaymentOption[] = ['cash']
	if (financingProvider !== 'none') paymentOptions.push(financingProvider)
	if (priceStore.currency === 'USD') paymentOptions.push('truemed')

	return (
		<PaymentOptionTabs
			selectedTab={shopStore.paymentOption}
			setSelectedTab={shopStore.setPaymentOption}
			paymentOptions={paymentOptions}
		/>
	)
})

export const FinancingTabModal: FC<FinancingTabModalProps> = (props) => {
	const { paymentOptions } = props

	const [selectedTab, setSelectedTab] = useState<PaymentOption>('cash')

	const _selectedTab = props.selectedTab || selectedTab
	const _setSelectedTab = props.setSelectedTab || setSelectedTab

	return (
		<article className={styles.container}>
			<PaymentOptionTabs
				selectedTab={_selectedTab}
				setSelectedTab={_setSelectedTab}
				paymentOptions={paymentOptions}
			/>

			<div className={styles.tab_content}>
				{paymentOptions.map((option, i) => (
					<section
						id={`tab-panel-${i}`}
						role="tabpanel"
						aria-labelledby={`tab-${i}`}
						data-selected={_selectedTab === option}
						key={option}
					>
						<Disclaimer
							{...props}
							option={option}
						/>
						<PricingTable
							{...props}
							option={option}
						/>
						<div className={styles.total_spacer} />
						<AffirmSubscriptionInfo
							{...props}
							option={option}
						/>
						<Total
							{...props}
							option={option}
						/>
					</section>
				))}
			</div>
		</article>
	)
}

interface PaymentOptionTabsProps {
	selectedTab: PaymentOption
	setSelectedTab: (tab: PaymentOption) => void
	paymentOptions: PaymentOption[]
}

export const PaymentOptionTabs: FC<PaymentOptionTabsProps> = (props) => {
	const { selectedTab, setSelectedTab, paymentOptions } = props

	if (paymentOptions.length <= 1) return null

	return (
		<nav aria-label="Payment Options">
			<ul
				role="tablist"
				className={styles.tabs}
				style={
					{
						'--selected-tab': paymentOptions.indexOf(selectedTab),
						'--num-tabs': paymentOptions.length,
					} as CSSProperties
				}
			>
				{paymentOptions.map((option, i) => (
					<li
						role="presentation"
						data-selected={selectedTab === option}
						key={option}
					>
						<Button.Empty
							role="tab"
							aria-selected="true"
							aria-controls={`tab-panel-${i}`}
							id={`tab-${i}`}
							onClick={() => setSelectedTab(option)}
						>
							<DescriptiveOption option={option} />
						</Button.Empty>
					</li>
				))}
			</ul>
		</nav>
	)
}

const AffirmSubscriptionInfo: FC<FinancingTabModalProps & { option: PaymentOption }> = (props) => {
	const { option, currency, itemizedSelections } = props
	const isFinancing = option === 'affirm'

	if (!isFinancing) return null

	const subscriptionItems = itemizedSelections?.filter((item) => item.subscription) ?? []
	const noSubscriptionItemsPresent = subscriptionItems.length === 0
	if (noSubscriptionItemsPresent) return null

	return (
		<>
			<table className={styles.pricing_table}>
				<thead>
					<tr>
						<th className={'vh'}>Product</th>
						<th className={'vh'}>Pricing</th>
					</tr>
				</thead>
				<tbody>
					{subscriptionItems.map((item, i) => {
						if (!item.subscription) return null
						return (
							<tr key={`item-${i}`}>
								<td>
									{item.productTitle}
									<Tooltip tooltip={item.tooltip} />
								</td>

								<PricingTableItem
									{...item}
									currency={currency}
								/>
							</tr>
						)
					})}
				</tbody>
			</table>
			<div className={styles.spacer} />
		</>
	)
}

const DescriptiveOption = (props: { option: PaymentOption }) => {
	const { option } = props

	if (option === 'cash') {
		return <>Pay now</>
	}

	if (option === 'truemed') {
		return (
			<>
				<span className={styles.mobile_hide}>Pay with </span>HSA/FSA
			</>
		)
	}

	return <FinancingContent provider={option} />
}

const FinancingContent = (props: { provider: FinancingProvider }) => {
	const { provider } = props
	if (provider === 'affirm') {
		return (
			<>
				<span className={styles.mobile_hide}>Finance with </span>
				<AffirmLogo className={styles.affirm_text} />
			</>
		)
	}

	if (provider === 'klarna') {
		return (
			<>
				<span className={styles.mobile_hide}>Pay in 3 with </span>
				<KlarnaLogo
					color={'black'}
					className={styles.klarna_text}
				/>
			</>
		)
	}

	return null
}

const Disclaimer: FC<FinancingTabModalProps & { option: PaymentOption }> = (props) => {
	const { option } = props
	switch (option) {
		case 'truemed':
			return (
				<p className={styles.disclaimer}>
					Complete a quick health survey at checkout to confirm eligibility. Save an average of 30% when you pay with your pre-tax medical spending account. Membership subscription paid separately.
				</p>
			)
		case 'affirm':
			return <p className={styles.disclaimer}>Buy now, pay later with $0 down. Choose a payment plan at checkout and complete your order. Membership subscription paid separately.</p>
		case 'klarna':
			return (
				<p className={styles.disclaimer}>
					Split your total into three payments, with no hidden fees. Choose a payment plan at checkout and complete your order. Membership subscription paid separately.
				</p>
			)
		case 'cash':
		default:
			return null
	}
}

const Tooltip = (props: { tooltip: string }) => {
	if (!props.tooltip) return null
	return (
		<span className={styles.tooltip_container}>
			<Tippy
				content={<p className={styles.tooltip_content}>{props.tooltip}</p>}
				theme={'grey'}
				duration={500}
				arrow={true}
				animation={'shift-away'}
				trigger={'mouseenter focus click'}
				appendTo={'parent'}
				className={styles.tooltip}
			>
				<button className={styles.tooltip_button}>
					<Icon
						name={'InformationCircleLight'}
						color={'#636363'}
						size={16}
					/>
				</button>
			</Tippy>
		</span>
	)
}

const PricingTable: FC<FinancingTabModalProps & { option: PaymentOption }> = (props) => {
	const { itemizedSelections, currency, option, shopTotal } = props

	const subscriptionItems = itemizedSelections?.filter((item) => item.subscription) ?? []
	const totalSubscriptionCost = subscriptionItems.reduce((acc, item) => acc + item.price, 0)
	const totalCost = shopTotal?.price - totalSubscriptionCost
	const totalCompareCost = shopTotal?.comparePrice - totalSubscriptionCost
	const totalMonthlyCost = PriceManager.getAffirmFinancingAmount(totalCost)
	const totalMonthlyCompareCost = PriceManager.getAffirmFinancingAmount(totalCompareCost)

	const totalSavings =
		itemizedSelections?.reduce((acc, item) => {
			const price = item.comparePrice ?? item.price
			return acc + (price - item.price)
		}, 0) ?? 0

	return (
		<table className={styles.pricing_table}>
			<thead>
				<tr>
					<th className={'vh'}>Product</th>
					<th className={'vh'}>Pricing</th>
				</tr>
			</thead>
			<tbody>
				{itemizedSelections?.map((item, i) => {
					if (option === 'affirm' && item.subscription) return null
					return (
						<tr key={`item-${i}`}>
							<td>
								{item.productTitle}
								<Tooltip tooltip={item.tooltip} />
							</td>
							<PricingTableItem
								{...item}
								option={option}
								currency={currency}
								totalCost={totalCost}
								totalCompareCost={totalCompareCost}
								totalMonthlyCost={totalMonthlyCost}
								totalMonthlyCompareCost={totalMonthlyCompareCost}
							/>
						</tr>
					)
				})}
				<tr>
					<td>Savings</td>
					<td>
						<span>-{PriceManager.formatPriceToCurrencyNoDecimal(totalSavings / 100, currency)}</span>
					</td>
				</tr>
			</tbody>
		</table>
	)
}

interface PricingTableItemProps extends ItemizedSelection {
	currency: Currency
	option?: PaymentOption
	totalCost?: number
	totalCompareCost?: number
	totalMonthlyCost?: number
	totalMonthlyCompareCost?: number
}

const PricingTableItem: FC<PricingTableItemProps> = (props) => {
	const { price, comparePrice, subscription, currency, totalMonthlyCost, totalCost, option, totalCompareCost, totalMonthlyCompareCost } = props

	if (subscription) {
		return (
			<td>
				<span className={styles.subscription_info}>({PriceManager.formatPriceToCurrencyNoDecimal(price / 100 / 12, currency)}/mo billed yearly)</span>
				<span>{PriceManager.formatPriceToCurrencyNoDecimal(price / 100, currency)}</span>
			</td>
		)
	}

	const roundedMonthlyCost = Math.ceil(totalMonthlyCost / 100)
	const roundedMonthlyCompareCost = Math.ceil(totalMonthlyCompareCost / 100)

	if (option === 'affirm' && totalMonthlyCost && totalCost) {
		return (
			<td>
				{comparePrice !== price && (
					<del>
						(<span>{PriceManager.formatPriceToCurrencyNoDecimal((comparePrice / totalCompareCost) * roundedMonthlyCompareCost, currency)}/mo</span>)
					</del>
				)}
				<span>{PriceManager.formatPriceToCurrencyNoDecimal((price / totalCost) * roundedMonthlyCost, currency)}/mo</span>
			</td>
		)
	}

	return (
		<td>
			{/*{item.comparePrice !== item.price && <em>{PriceManager.formatPriceToCurrencyNoDecimal((item.comparePrice - item.price) / 100, currency)} off</em>}*/}
			{comparePrice !== price && (
				<del>
					(<span>{PriceManager.formatPriceToCurrencyNoDecimal(comparePrice / 100, currency)}</span>)
				</del>
			)}
			<span>{PriceManager.formatPriceToCurrencyNoDecimal(price / 100, currency)}</span>
		</td>
	)
}

const Total: FC<FinancingTabModalProps & { option: PaymentOption }> = (props) => {
	const { option, shopTotal, itemizedSelections, currency } = props
	const isFinancing = option === 'affirm'

	const subscriptionItems = itemizedSelections?.filter((item) => item.subscription) ?? []
	const totalSubscriptionCost = subscriptionItems.reduce((acc, item) => acc + item.price, 0)
	const totalCost = shopTotal?.price - totalSubscriptionCost

	const getPriceString = () => {
		if (isFinancing) {
			return PriceManager.formatPriceToCurrencyNoDecimal(totalSubscriptionCost / 100, currency)
		}

		return shopTotal?.priceString
	}

	const getSubPriceItem = () => {
		const monthlyPrice = PriceManager.getAffirmFinancingAmount(totalCost)

		return (
			<tr
				className={styles.financing_extra}
				style={{ visibility: isFinancing ? 'visible' : 'hidden' }}
				aria-hidden={!isFinancing}
			>
				<td>
					<p className={styles.financing_extra_item}>Monthly payments after today</p>
				</td>
				<td>
					<p className={styles.financing_extra_item}>
						<span>as low as {PriceManager.formatPriceToCurrencyNoDecimal(Math.ceil(monthlyPrice / 100), currency)}/mo</span>
					</p>
				</td>
			</tr>
		)
	}

	return (
		<table className={styles.total_table}>
			<thead>
				<tr>
					<th className={'vh'}>Product</th>
					<th className={'vh'}>Subtotal</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>
						<p className={styles.total}>
							Total
							{isFinancing && <span> today</span>}
							{option === 'truemed' && <small>before tax savings</small>}
						</p>
					</td>
					<td>
						<p className={styles.total}>{getPriceString()}</p>
					</td>
				</tr>
				{getSubPriceItem()}
			</tbody>
		</table>
	)
}

const PriceInfo: FC<{ item: ItemizedSelection; currency: Currency }> = (props) => {
	const { item, currency } = props
	return (
		<>
			{item.comparePrice !== item.price && <em>{PriceManager.formatPriceToCurrencyNoDecimal((item.comparePrice - item.price) / 100, currency)} off</em>}
			{item.comparePrice !== item.price && (
				<del>
					(<span>{PriceManager.formatPriceToCurrencyNoDecimal(item.comparePrice / 100, currency)}</span>)
				</del>
			)}
			<span>{PriceManager.formatPriceToCurrencyNoDecimal(item.price / 100, currency)}</span>
		</>
	)
}
