const EU_LANGUAGE_ASSETS = {
	'product-breakdown-wide': {
		'*': 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/Pod+4+Product+Breakdown+%E2%80%93+Wide.png',
		fr: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/French+-+Pod+4+Product+Breakdown+%E2%80%93+Wide.png',
		es: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/Spanish+-+Pod+4+Product+Breakdown+%E2%80%93+Wide.png',
		de: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/German+-+Pod+4+Product+Breakdown+%E2%80%93+Wide.png',
		it: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/Italian+-+Pod+4+Product+Breakdown+%E2%80%93+Wide.png',
	},
	'product-breakdown-mobile': {
		'*': 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/Pod+4+Product+Breakdown+%E2%80%93+Mobile.png',
		fr: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/French+-+Pod+4+Product+Breakdown+%E2%80%93+Mobile.png',
		es: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/Spanish+-+Pod+4+Product+Breakdown+%E2%80%93+Mobile.png',
		de: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/German+-+Pod+4+Product+Breakdown+%E2%80%93+Mobile.png',
		it: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/Italian+-+Pod+4+Product+Breakdown+%E2%80%93+Mobile.png',
	},
	'product-ultra-breakdown-wide': {
		'*': 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/Pod+4+Ultra+Product+Breakdown+%E2%80%93+Wide.png',
		fr: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/French+-+Pod+4+Ultra+Product+Breakdown+%E2%80%93+Wide.png',
		es: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/Spanish+-+Pod+4+Ultra+Product+Breakdown+%E2%80%93+Wide.png',
		de: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/German+-+Pod+4+Ultra+Product+Breakdown+%E2%80%93+Wide.png',
		it: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/Italian+-+Pod+4+Ultra+Product+Breakdown+%E2%80%93+Wide.png',
	},
	'product-ultra-breakdown-mobile': {
		'*': 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/Pod+4+Ultra+Product+Breakdown+%E2%80%93+Mobile.png',
		fr: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/French+-+Pod+4+Ultra+Product+Breakdown+%E2%80%93+Mobile.png',
		es: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/Spanish+-+Pod+4+Ultra+Product+Breakdown+%E2%80%93+Mobile.png',
		de: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/German+-+Pod+4+Ultra+Product+Breakdown+%E2%80%93+Mobile.png',
		it: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/Italian+-+Pod+4+Ultra+Product+Breakdown+%E2%80%93+Mobile.png',
	},
	'autopilot-slide-1': {
		'*': 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/shop_assets_1112/Autopilot+%E2%80%93+Wide-4.png',
		fr: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/French+-+Autopilot+%E2%80%93+Wide.png',
		es: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/Spanish+-+Autopilot+%E2%80%93+Wide.png',
		de: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/German+-+Autopilot+%E2%80%93+Wide.png',
		it: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/Italian+-+Autopilot+%E2%80%93+Wide.png',
	},
	'autopilot-slide-1-mobile': {
		'*': 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/shop_assets_1112/Autopilot+%E2%80%93+Mobile-2.png',
		fr: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/French+-+Autopilot+%E2%80%93+Mobile.png',
		es: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/Spanish+-+Autopilot+%E2%80%93+Mobile.png',
		de: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/German+-+Autopilot+%E2%80%93+Mobile.png',
		it: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/Italian+-+Autopilot+%E2%80%93+Mobile.png',
	},
	'autopilot-slide-3': {
		'*': 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/English+-+Temperature+%E2%80%93+Wide.png',
		fr: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/French-+Temperature+%E2%80%93+Wide.png',
		es: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/Spanish+-+Temperature+%E2%80%93+Wide.png',
		de: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/German+-+Temperature+%E2%80%93+Wide.png',
		it: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/Italian+-+Temperature+%E2%80%93+Wide.png',
	},
	'autopilot-slide-3-mobile': {
		'*': 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/English+-+Temperature+%E2%80%93+Mobile.png',
		fr: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/French+-+Temperature+%E2%80%93+Mobile+-+v2.png',
		es: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/Spanish+-+Temperature+%E2%80%93+Mobile+-+v2.png',
		de: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/German+-+Temperature+%E2%80%93+Mobile+-+v2.png',
		it: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/Italian+-+Temperature+%E2%80%93+Mobile+-+v2.png',
	},
	'autopilot-slide-4': {
		'*': 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/autopilot-breakdown-slide-4-desktop.png',
		fr: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/French+-+Snoring+%E2%80%93+Wide.png',
		es: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/Spanish+-+Snoring+%E2%80%93+Wide.png',
		de: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/German+-+Snoring+%E2%80%93+Wide.png',
		it: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/Italian+-+Snoring+%E2%80%93+Wide.png',
	},
	'autopilot-slide-4-mobile': {
		'*': 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/shop_assets_1112/Snoring+%E2%80%93+Mobile.png',
		fr: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/French+-+Snoring+%E2%80%93+Mobile.png',
		es: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/Spanish+-+Snoring+%E2%80%93+Mobile.png',
		de: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/German+-+Snoring+%E2%80%93+Mobile.png',
		it: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/Italian+-+Snoring+%E2%80%93+Mobile.png',
	},
	'autopilot-slide-5': {
		'*': 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/shop_assets_1112/Alarm+%E2%80%93+Wide-2.png',
		fr: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/French+-+Alarm+%E2%80%93+Wide.png',
		es: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/Spanish+-+Alarm+%E2%80%93+Wide.png',
		de: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/German+-+Alarm+%E2%80%93+Wide.png',
		it: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/Italian+-+Alarm+%E2%80%93+Wide.png',
	},
	'autopilot-slide-5-mobile': {
		'*': 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/shop_assets_1112/Alarm+%E2%80%93+Mobile.png',
		fr: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/French+-+Alarm+%E2%80%93+Mobile.png',
		es: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/Spanish+-+Alarm+%E2%80%93+Mobile.png',
		de: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/German+-+Alarm+%E2%80%93+Mobile.png',
		it: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/Italian+-+Alarm+%E2%80%93+Mobile.png',
	},
	'autopilot-slide-6': {
		'*': 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/autopilot-breakdown-slide-6.png',
		fr: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/French+-+Metrics+%E2%80%93+Wide.png',
		es: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/Spanish+-+Metrics+%E2%80%93+Wide.png',
		de: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/German+-+Metrics+%E2%80%93+Wide.png',
		it: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/Italian+-+Metrics+%E2%80%93+Wide.png',
	},
	'autopilot-slide-6-mobile': {
		'*': 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/shop_assets_1112/Metrics+%E2%80%93+Mobile.png',
		fr: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/French+-+Metrics+%E2%80%93+Mobile.png',
		es: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/Spanish+-+Metrics+%E2%80%93+Mobile.png',
		de: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/German+-+Metrics+%E2%80%93+Mobile.png',
		it: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/shop-page-translations/Italian+-+Metrics+%E2%80%93+Mobile.png',
	},
}

type EULanguageAssetKey = keyof typeof EU_LANGUAGE_ASSETS

export const getLanguageAssets = (src: EULanguageAssetKey, languageCode: string) => {
	return EU_LANGUAGE_ASSETS[src][languageCode] ?? EU_LANGUAGE_ASSETS[src]['*']
}
