import { KeyframeSectionProps } from './types'

export const getDefaultKeyframes = (): KeyframeSectionProps[] => {
	return [
		{
			title: 'Remind it and rest easier',
			tag: 'At night',
			description: 'Receive reminders based on your bedtime, so you stay consistent and never forget to take your supplements',
			startFrame: 0,
			endFrame: 22,
			mobileImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-waitlist/Single_new.png',
		},
		{
			title: 'Tag it and unwind',
			tag: 'Next morning',
			description: 'Log your doses of Sleep Elixir in the app to begin seeing how much easier it is to fall asleep as cortisol levels lower and relaxation deepens',
			startFrame: 100,
			endFrame: 171,
			mobileImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-waitlist/sleep-stages-card-new-v3.png',
		},
		{
			title: 'Track it and see results',
			tag: 'Week after',
			description: 'Monitor your progress in the app as your sleep cycles improve. With deeper, restorative rest, your body aligns with its natural rhythms',
			startFrame: 221,
			endFrame: 258,
			mobileImage: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-waitlist/Benefit_new.png',
		},
	]
}
