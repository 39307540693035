// noinspection SpellCheckingInspection

import { ArticleItem, CTAItem, QuoteItem, TweetItem, VideoItem } from 'components/WallOfLove/wallOfLoveIterfaces'

export const elon = {
	picture: 'https://pbs.twimg.com/profile_images/1683325380441128960/yRsRRjGO_normal.jpg',
	name: 'Elon Musk',
	handle: 'elonmusk',
	unixDateMS: 1677568668000,
	platform: 'twitter',
	content: '@eightsleep It’s good',
	verified: true,
	url: 'https://twitter.com/elonmusk/status/1630467288720719873',
	categories: ['Familiar faces'],
} as TweetItem

export const zuck = {
	picture: 'https://eightsleep.imgix.net/zuckpfp.jpg?v=1689013673',
	verified: true,
	name: 'Mark Zuckerberg',
	handle: 'zuck',
	unixDateMS: 1689016230000,
	platform: 'threads',
	content: '7-8 hours usually. Really dialed in my sleep with Eight Sleep and Oura.',
	url: '',
	categories: ['Familiar faces'],
} as TweetItem

export const sorkin = {
	picture: 'https://pbs.twimg.com/profile_images/673272991816548353/tn5thWZR_normal.jpg',
	name: 'Andrew Ross Sorkin',
	handle: 'andrewrsorkin',
	unixDateMS: 1677608430000,
	platform: 'twitter',
	content: '+1. It is very good.',
	verified: true,
	url: 'https://twitter.com/andrewrsorkin/status/1630634060115525653',
	categories: ['Familiar faces'],
} as TweetItem

export const whyte = {
	picture: 'https://pbs.twimg.com/profile_images/1172592811885002752/V01ol79g_normal.jpg',
	name: 'Trevor Whyte',
	handle: 'trevorwhyte7',
	unixDateMS: 1646581995000,
	platform: 'twitter',
	content:
		'As a RespiratoryTherapist interested in physiologic monitoring, ICU style body temperature control and AI. @eightsleep checks a lot of boxes for me. <br/><br/>as I have yet to see a comphrensive write up. here is a thread on 8 ways 8Sleep could have important effects on health',
	verified: false,
	url: 'https://twitter.com/trevorwhyte7/status/1500499760838369282',
	categories: ['Sleep improvement'],
} as TweetItem

export const whyteQuote = {
	imageSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Trevor+Whyte+2.png',
	description: 'Member since 2022',
	name: 'Trevor Whyte',
	quote:
		'I dedicate more focus to my sleep when I notice a sudden unexpected drop in my heart rate variability (HRV) from the Eight Sleep App. I believe this has helped me avoid illnesses like colds that the rest of my family sometimes experiences.',
} as QuoteItem

export const huberman = {
	name: 'Dr. Andrew Huberman',
	thumbnail: 'https://eightsleep.imgix.net/meta_huberman_8efc0983-9e0b-4497-9ab4-804fb61fa4ce.jpg?v=1691777753',
	video: 'https://d115sb6i1ixllw.cloudfront.net/assets/812fe9f2863e46a9842ee7eca56ec6fa.mp4',
	categories: ['Familiar faces'],
	title: 'Neuroscientist - Stanford University',
} as VideoItem

export const gunn = {
	name: 'Janet Gunn',
	thumbnail: 'https://eightsleep.imgix.net/0d589a2a7885703785f8dcf3f45f6ac7.png?v=1691609380',
	video: 'https://d115sb6i1ixllw.cloudfront.net/assets/bdd475a0b31e4b4da10522cb10ab5594.mp4',
	categories: ['Everyday love'],
	title: 'Lifestyle & nutrition blogger',
} as VideoItem

export const rogan = {
	name: 'Joe Rogan',
	thumbnail: 'https://eightsleep.imgix.net/0556307274237a85c20447f53e6a7bde.png?v=1691607091',
	video: 'https://d115sb6i1ixllw.cloudfront.net/assets/9393dd7e2d3c4ef39e703c91d6ba5d45.mp4',
	categories: ['Familiar faces'],
	title: 'Podcaster & entertainer',
} as VideoItem

export const hamilton = {
	logo: 'sun',
	link: 'https://www.thesun.co.uk/sport/19950362/hamilton-russell-mercedes-f1-bed/',
	headline: 'Lewis Hamilton has new secret weapon as F1 star and team-mate George Russell sleep in £2,000 ‘stethoscope’ bed',
	image: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/hamilton+updated+tweet.png',
	categories: ['Familiar faces'],
} as ArticleItem

export const judge = {
	logo: 'menshealth',
	link: 'https://www.menshealth.com/technology-gear/g41447176/aaron-judge-six-pack/',
	headline: 'Aaron Judge Uses These 6 Things to Stay at the Top of His Game',
	image: 'https://eightsleep.imgix.net/mh-9-29-judge-2-1664479051.png?v=1692029340',
	categories: ['Familiar faces'],
} as ArticleItem

export const wells = {
	name: 'Brooke Wells',
	thumbnail: 'https://eightsleep.imgix.net/Brooke_Wells.png?v=1692031578',
	video: 'https://d115sb6i1ixllw.cloudfront.net/assets/e52893b038b441cc9f5bd05d5fdd8341.mp4',
	categories: ['Familiar faces'],
	title: 'CrossFit Athlete',
} as VideoItem

export const clarke = {
	name: 'Sami Clarke',
	thumbnail: 'https://eightsleep.imgix.net/Screen_Shot_2023-08-15_at_9.25.54_AM.png?v=1692115401',
	video: 'https://d115sb6i1ixllw.cloudfront.net/assets/d4e66ca064894ca7a88d3259359ca960.mp4',
	categories: ['Familiar faces'],
	title: 'Wellness influencer',
} as VideoItem

export const zuck2 = {
	logo: 'fortune',
	link: 'https://fortune.com/well/2023/07/07/mark-zuckerberg-sleep-routine/',
	headline: 'The luxurious sleep lives of CEOs—the $2,000 smart mattress Mark Zuckerberg and Bryan Johnson use to master their sleep',
	image: 'https://eightsleep.imgix.net/167d4366b9adac9a1669c547d6e4997e.png?v=1691609193',
	categories: ['Familiar faces'],
} as ArticleItem

export const zero = {
	picture: 'https://pbs.twimg.com/profile_images/1807062106690453504/c4oN4ZFG_400x400.jpg',
	name: 'Zero',
	handle: 'bryan_johnson',
	unixDateMS: 1690303076000,
	platform: 'twitter',
	content: "@eightsleep, it's listed on the blueprint website 🤝",
	verified: true,
	url: 'https://twitter.com/bryan_johnson/status/1683879265740394496',
	categories: ['Familiar faces'],
} as TweetItem

export const seanJohnson = {
	platform: 'twitter',
	verified: true,
	url: 'https://twitter.com/intentionally/status/1502322440566886402',
	name: 'Sean Johnson 🔥',
	handle: 'intentionally',
	content: '@eightsleep is the best thing I’ve ever bought.',
	unixDateMS: 1647016556000,
	picture: 'https://pbs.twimg.com/profile_images/1647015695882788865/7Dqoy_rV_normal.jpg',
	categories: ['Familiar faces'],
} as TweetItem

export const graham = {
	picture: 'https://pbs.twimg.com/profile_images/1824002576/pg-railsconf_normal.jpg',
	name: 'Paul Graham',
	handle: 'paulg',
	unixDateMS: 1661407891000,
	platform: 'twitter',
	content: 'Jessica and I met Matteo Franceschetti of Eight Sleep today, and 5 minutes into the conversation we ordered one.',
	verified: true,
	url: 'https://twitter.com/paulg/status/1562684073365057537',
	categories: ['Familiar faces'],
} as TweetItem

export const trexler = {
	picture: 'https://pbs.twimg.com/profile_images/1719001776005787648/WdC4K6Lv_400x400.jpg',
	name: 'christina trexler',
	handle: 'xtinacomputes',
	unixDateMS: 1673237383000,
	platform: 'twitter',
	content: 'perfect temperature to sleep all the time, good to see how my HR and HRV react to different night routines, warns me if i’m getting sick, warms in the morning, i sleep so good',
	verified: true,
	url: 'https://twitter.com/xtinacomputes/status/1612300559624855554',
	categories: ['Familiar faces'],
} as TweetItem

export const items: (TweetItem | VideoItem | ArticleItem | CTAItem)[] = [
	zuck,
	huberman,
	hamilton,
	zero,
	gunn,
	{
		name: 'Joe Rogan',
		thumbnail: 'https://eightsleep.imgix.net/0556307274237a85c20447f53e6a7bde.png?v=1691607091',
		video: 'https://d115sb6i1ixllw.cloudfront.net/assets/9393dd7e2d3c4ef39e703c91d6ba5d45.mp4',
		categories: ['Familiar faces'],
		title: 'Podcaster & entertainer',
	},
	zuck2,
	elon,
	{
		picture: 'https://pbs.twimg.com/profile_images/1685130833823436800/SjZkO7Rb_normal.jpg',
		name: 'Bryan Johnson /dd',
		handle: 'bryan_johnson',
		unixDateMS: 1715209967000,
		platform: 'twitter',
		content:
			'My @eightsleep bed helped me achieve eight months of 100% sleep performance. <br/><br/>Congratulations to @m_franceschetti and team for getting this upgrade out and saving us from the insanity of sleep deprivation. ',
		verified: false,
		url: 'https://twitter.com/bryan_johnson/status/1788346338087743701',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1824002576/pg-railsconf_normal.jpg',
		name: 'Paul Graham',
		handle: 'paulg',
		unixDateMS: 1661407891000,
		platform: 'twitter',
		content: 'Jessica and I met Matteo Franceschetti of Eight Sleep today, and 5 minutes into the conversation we ordered one.',
		verified: true,
		url: 'https://twitter.com/paulg/status/1562684073365057537',
		categories: ['Familiar faces'],
	},
	{
		name: 'Unbox Therapy',
		thumbnail: 'https://eightsleep.imgix.net/da6a17a7e4c5084f2a0d2472dc8711df.png?v=1691609914',
		video: 'https://d115sb6i1ixllw.cloudfront.net/assets/43869ddfcf654ea68764f40a3885d0b4.mp4',
		categories: ['Familiar faces'],
		title: 'Product reviewer',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1602390323606392832/eV0wwl_6_normal.jpg',
		name: 'christina trexler',
		handle: 'xtinacomputes',
		unixDateMS: 1673237383000,
		platform: 'twitter',
		content: 'perfect temperature to sleep all the time, good to see how my HR and HRV react to different night routines, warns me if i’m getting sick, warms in the morning, i sleep so good',
		verified: true,
		url: 'https://twitter.com/xtinacomputes/status/1612300559624855554',
		categories: ['Familiar faces'],
	},
	{
		name: 'Laura Sanko',
		thumbnail: 'https://eightsleep.imgix.net/Laura_Sanko.png?v=1692115541',
		video: 'https://d115sb6i1ixllw.cloudfront.net/assets/d424dc9ebf614b67ae4768bcd20619fe.mp4',
		categories: ['Familiar faces'],
		title: 'UFC Commentator & TV Personality',
	},
	{
		name: 'Cody Ko',
		thumbnail: 'https://eightsleep.imgix.net/Cody_Ko.png?v=1691777567',
		video: 'https://d115sb6i1ixllw.cloudfront.net/assets/6a7eb5850c254ff6a4d95aa4d79b6419.mp4',
		categories: ['Familiar faces'],
		title: 'YouTube',
	},
	{
		name: 'Alex Warren',
		thumbnail: 'https://eightsleep.imgix.net/Alex_Warren.png?v=1691777570',
		video: 'https://d115sb6i1ixllw.cloudfront.net/assets/cd321cfc9e194feca3e2b7dcf8908e54.mp4',
		categories: ['Familiar faces'],
		title: 'Musician & entertainer',
	},
	{
		logo: 'menshealth',
		link: 'https://www.menshealth.com/technology-gear/g41447176/aaron-judge-six-pack/',
		headline: 'Aaron Judge Uses These 6 Things to Stay at the Top of His Game',
		image: 'https://eightsleep.imgix.net/mh-9-29-judge-2-1664479051.png?v=1692029340',
		categories: ['Familiar faces'],
	},
	{
		name: 'Iman Gadzhi',
		thumbnail: 'https://eightsleep.imgix.net/Iman_Gadzhi.png?v=1691777565',
		video: 'https://d115sb6i1ixllw.cloudfront.net/assets/2b055905ad504082b9c244516df54f94.mp4',
		categories: [],
		title: 'Founder',
	},
	clarke,
	{
		logo: 'insider',
		link: 'https://www.insider.com/crossfit-justin-medeiros-muscle-recovery-fitness-sleep-ice-baths-sauna-2022-3',
		headline: 'The Fittest Man on Earth says sleeping in, taking ice baths, and spending time in the sauna maximizes his muscle recovery',
		image: 'https://eightsleep.imgix.net/622fab46843ef30018e6fefe.webp?v=1692030568',
		categories: ['Familiar faces'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1584343135076892683/3ngvuzH__normal.jpg',
		name: 'lil uzi perf',
		handle: 'ken_wheeler',
		unixDateMS: 1715177328000,
		platform: 'twitter',
		content: '@KennethHazlett_ @eightsleep love mine',
		verified: false,
		url: 'https://twitter.com/ken_wheeler/status/1788209440140689617',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1768666625367314432/S9xHqGsh_normal.jpg',
		name: 'joel 🌦️',
		handle: 'jhooks',
		unixDateMS: 1715178095000,
		platform: 'twitter',
		content:
			"@KennethHazlett_ @ken_wheeler @eightsleep we've had it for about 9 months. she keeps her side turned up like Kenny Rogers Roasters and mine has me resting like a cold slab of beef lol<br/><br/>it delivers on its promise and i'll be upgrading it as they drop new models",
		verified: false,
		url: 'https://twitter.com/jhooks/status/1788212656538878200',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1770385035436396544/7A8VyaL2_normal.jpg',
		name: 'Tom',
		handle: 'torquay_rea',
		unixDateMS: 1715179223000,
		platform: 'twitter',
		content: '@BallsInYaMe0wth @eightsleep From someone who has had a Pod 3 for 7 months now, you’re missing out. The quality of life improvement this thing gives you is honestly astounding.',
		verified: false,
		url: 'https://twitter.com/torquay_rea/status/1788217387420188782',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1339713932085346306/jDTi4HKH_normal.jpg',
		name: 'Andrew D. Huberman, Ph.D.',
		handle: 'hubermanlab',
		unixDateMS: 1715192924000,
		platform: 'twitter',
		content:
			'I am very excited about @eightsleep releasing this new technology — especially the no phone option (for obvious reasons related to sleep hygiene). Temperature is a game changing factor for quality sleep. Yes, Eight Sleep is a Huberman Lab sponsor... because it works! ',
		verified: false,
		url: 'https://twitter.com/hubermanlab/status/1788274856120991851',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1474048180908175363/b6PCpGwj_normal.jpg',
		name: 'Sam Parr',
		handle: 'thesamparr',
		unixDateMS: 1715182795000,
		platform: 'twitter',
		content: "I just got one of these. It's awesome. ",
		verified: false,
		url: 'https://twitter.com/thesamparr/status/1788232371088634333',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1528907467253596160/jfu8AffN_normal.jpg',
		name: 'Brad Siegler 🇺🇸 🚀',
		handle: 'BradSiegler',
		unixDateMS: 1715216104000,
		platform: 'twitter',
		content: 'I don’t have much of a following on X, but for those who see this, I HIGHLY recommend the @eightsleep pod. Was a game changer for my sleep health. ',
		verified: false,
		url: 'https://twitter.com/BradSiegler/status/1788372077700899299',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1601416285702586368/V08c7STc_normal.jpg',
		name: 'Pascal Guyon',
		handle: 'pascalguyon',
		unixDateMS: 1720811848000,
		platform: 'twitter',
		content:
			'@JNAranda @eightsleep @m_franceschetti So far I’m impressed! Seems high quality material, temperature changes fast, super comfortable, not feeling the tubes at all, not changing the comfort of my medium/soft Tempur pedic mattress, the computer makes almost no noise at all👍 ',
		verified: false,
		url: 'https://twitter.com/pascalguyon/status/1811842328866513061',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/GST0_gpXwAAG7Ow.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1749190456830050304/83HjgmkL_normal.jpg',
		name: 'BowTied Kobra, Sports Med',
		handle: 'bowtiedkobra',
		unixDateMS: 1715216756000,
		platform: 'twitter',
		content: "The pod was one of the best things I've ever purchased for myself ",
		verified: false,
		url: 'https://twitter.com/bowtiedkobra/status/1788374813389844630',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1503079332746514435/yxthcdXR_normal.jpg',
		name: 'Andrew McCalip',
		handle: 'andrewmccalip',
		unixDateMS: 1715264979000,
		platform: 'twitter',
		content: "@m_franceschetti @eightsleep Y'all have one of the only life changing products in tech 👍👍👍",
		verified: false,
		url: 'https://twitter.com/andrewmccalip/status/1788577073738469709',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1768666625367314432/S9xHqGsh_normal.jpg',
		name: 'joel 🌦️',
		handle: 'jhooks',
		unixDateMS: 1715177592000,
		platform: 'twitter',
		content: '@ken_wheeler @KennethHazlett_ @eightsleep i also love mine<br/><br/>3:30am night sweats are gone',
		verified: false,
		url: 'https://twitter.com/jhooks/status/1788210547248496673',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1584343135076892683/3ngvuzH__normal.jpg',
		name: 'lil uzi perf',
		handle: 'ken_wheeler',
		unixDateMS: 1715180711000,
		platform: 'twitter',
		content: '@KennethHazlett_ @eightsleep yeah dude i stay asleep now its wild. shit makes microadjustments to keep your ass asleep.',
		verified: false,
		url: 'https://twitter.com/ken_wheeler/status/1788223629597040955',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1770385035436396544/7A8VyaL2_normal.jpg',
		name: 'Tom',
		handle: 'torquay_rea',
		unixDateMS: 1715179223000,
		platform: 'twitter',
		content: '@BallsInYaMe0wth @eightsleep From someone who has had a Pod 3 for 7 months now, you’re missing out. The quality of life improvement this thing gives you is honestly astounding.',
		verified: false,
		url: 'https://twitter.com/torquay_rea/status/1788217387420188782',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1340259169840951298/rdKVj2h3_normal.jpg',
		name: 'Maneet',
		handle: 'maneetks',
		unixDateMS: 1715183974000,
		platform: 'twitter',
		content: '@eightsleep changed my life and @m_franceschetti is a beast ',
		verified: false,
		url: 'https://twitter.com/maneetks/status/1788237315435089926',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1773721481899393024/c2ANpCMq_normal.jpg',
		name: 'Tom Schmidt ＞|＜',
		handle: 'tomhschmidt',
		unixDateMS: 1715185311000,
		platform: 'twitter',
		content: 'We simply like the Pod ',
		verified: false,
		url: 'https://twitter.com/tomhschmidt/status/1788242921990369539',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1715074747762126849/f8Sla_nW_normal.jpg',
		name: 'Morgan Brown',
		handle: 'morganb',
		unixDateMS: 1715186023000,
		platform: 'twitter',
		content:
			"🤩 @erikab and I always say, our @eightsleep is our favorite piece of tech in our house. Excited to add this to our home soon and upgrade our guest room w/the existing pod. If you haven't tried it yet, I can't recommend it highly enough. ",
		verified: false,
		url: 'https://twitter.com/morganb/status/1788245907991073150',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/844908853741301761/tRzUKHy4_normal.jpg',
		name: 'Alexis Rivas',
		handle: 'alexisxrivas',
		unixDateMS: 1715176667000,
		platform: 'twitter',
		content:
			'I’ve been using an @eightsleep for years to optimize my sleep. It works. <br/><br/>@m_franceschetti @a_zatarain and team just launched their newest product. <br/><br/>If you’re serious about health and maintaining peak daily performance this should be in your bed. ',
		verified: false,
		url: 'https://twitter.com/alexisxrivas/status/1788206666431013269',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1768666625367314432/S9xHqGsh_normal.jpg',
		name: 'joel 🌦️',
		handle: 'jhooks',
		unixDateMS: 1715177592000,
		platform: 'twitter',
		content: '@ken_wheeler @KennethHazlett_ @eightsleep i also love mine<br/><br/>3:30am night sweats are gone',
		verified: false,
		url: 'https://twitter.com/jhooks/status/1788210547248496673',
		categories: ['Sleep improvement'],
	},
	{
		name: 'Jordynn Wynn',
		thumbnail: 'https://eightsleep.imgix.net/Jordynn_Wynn.png?v=1691777569',
		video: 'https://d115sb6i1ixllw.cloudfront.net/assets/3e7071cdf077411a836f1f48e3fe8e0e.mp4',
		categories: ['Everyday love'],
		title: 'Lifestyle influencer',
	},
	{
		name: 'Brooke Wells',
		thumbnail: 'https://eightsleep.imgix.net/Brooke_Wells.png?v=1692031578',
		video: 'https://d115sb6i1ixllw.cloudfront.net/assets/e52893b038b441cc9f5bd05d5fdd8341.mp4',
		categories: ['Familiar faces'],
		title: 'CrossFit Athlete',
	},
	{
		name: 'Dr. Dustin Portela',
		thumbnail: 'https://eightsleep.imgix.net/Dr._Dustin_Portela.png?v=1691777567',
		video: 'https://d115sb6i1ixllw.cloudfront.net/assets/0ba5de8ed46d4271aac963d1e76fe29d.mp4',
		categories: [],
		title: 'Skincare & health expert',
	},
	{
		name: 'Julian Lina',
		thumbnail: 'https://eightsleep.imgix.net/Julian_Lina.png?v=1691777568',
		video: 'https://d115sb6i1ixllw.cloudfront.net/assets/3233cdc3399c44758422fcfb7f904b18.mp4',
		categories: ['Everyday love'],
		title: 'Member since 2022',
	},
	sorkin,
	{
		platform: 'twitter',
		verified: true,
		url: 'https://twitter.com/louisanicola_/status/1506681218775011334',
		name: 'Louisa Nicola',
		handle: 'louisanicola_',
		content:
			'Whenever someone asks "how is your sleep fitness, Louisa", I send them this photo. \n\nAll jokes aside, I just hit 1 year in my \n@eightsleep\n fitness journey and the most noticeable changes are; decreased sleep latency and increased TST (total sleep time).',
		unixDateMS: 1648055769000,
		picture: 'https://pbs.twimg.com/profile_images/1580188498748968961/L6lebFCL_normal.jpg',
		image: 'https://pbs.twimg.com/media/FOjNghkXIAMbkhg?format=jpg&name=4096x4096',
		categories: ['Familiar faces'],
	},

	{
		picture: 'https://pbs.twimg.com/profile_images/1372490197414391808/mQsXSdbW_normal.jpg',
		name: 'dougvk',
		handle: 'dougvk',
		unixDateMS: 1703695457000,
		platform: 'twitter',
		content:
			'@rabois @eightsleep been tracking all my stats for 2 years using WHOOP, slowly improved my sleep through various experiments. decided 1 month ago to get an eightsleep matress and nearly all my (already great) stats improved 10-20%. crazy.',
		verified: false,
		url: 'https://twitter.com/dougvk/status/1740050984238301213',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1446004946646749185/WiyH7gkr_normal.jpg',
		name: 'Romain Grosjean',
		handle: 'RGrosjean',
		unixDateMS: 1665932925000,
		platform: 'twitter',
		content: 'First night with our new <br/>@eightsleep<br/>  pod 3 cover<br/>Slept incredibly well<br/>Went cycling and cycled super fast<br/>Good sleep = performance <br/>💯💪🏻📖😍',
		verified: true,
		url: 'https://twitter.com/RGrosjean/status/1581663444486406144',
		categories: ['Familiar faces'],
	},

	{
		picture: 'https://pbs.twimg.com/profile_images/1603245131733250049/uArFBrH5_normal.jpg',
		name: 'JD Ross',
		handle: 'justindross',
		unixDateMS: 1691520222000,
		platform: 'twitter',
		content:
			"Metrics wise: HRV up 10-15%, resting heart rate down around 3-5 bpm, deep sleep up 20-50% vs long term stays elsewhere\n\t\t\t<br/><br/>\n\t\t\tQualitatively I don't get very tired around 4pm versus always having an afternoon crash when elsewhere\n\t\t\t<br/><br/>\n\t\t\tSleeping at ~67 degrees matches about half the effect",
		verified: true,
		url: 'https://twitter.com/justindross/status/1688984344742834176?s=20',
		categories: ['Familiar faces'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1524528385950593026/dMfDafhn_normal.jpg',
		name: 'Morgan Brown',
		handle: 'morganb',
		unixDateMS: 1673484582000,
		platform: 'twitter',
		content: "Literally the best thing I've bought in the last two years. My wife and I love ours and miss it when we're away from home.",
		verified: true,
		url: 'https://twitter.com/morganb/status/1613337389933690883',
		categories: ['Familiar faces'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1477773123894185987/BLxr7vL-_normal.jpg',
		name: 'Jason Staats⚡',
		handle: 'JStaatsCPA',
		unixDateMS: 1662500207000,
		platform: 'twitter',
		content: 'I know this is tongue-in-check but @eightsleep genuinely changed my life\n\t\t\t\t\t<br/><br/>\n\t\t\t\t\tI used to be a sweaty boi\n\t\t\t\t\t<br/><br/> \n\t\t\t\t\tNo more',
		verified: true,
		url: 'https://twitter.com/JStaatsCPA/status/1567265579593236481',
		categories: ['Familiar faces'],
	},

	{
		picture: 'https://pbs.twimg.com/profile_images/1637880453276336129/Iwuv-zvl_normal.jpg',
		name: 'Juan Manuel Correa',
		handle: 'JMCorrea__',
		unixDateMS: 1658768888000,
		platform: 'twitter',
		content: 'Rested and ready 👊 @eightsleep ',
		verified: true,
		url: 'https://twitter.com/JMCorrea__/status/1551615295114059776',
		categories: ['Familiar faces'],
		image: 'https://pbs.twimg.com/media/FYhx4dqXkAA8SX4.jpg',
	},
	{
		platform: 'twitter',
		verified: true,
		url: 'https://twitter.com/MissGinaDarling/status/1556571333030359040',
		name: 'GINA DARLING',
		handle: 'MissGinaDarling',
		content: 'I got a water cooled/heated bed cover a while back from @eightsleep and it’s still my most fav thing ever. It’s hot af in LA and my bed is at 55 degrees. Nuts.',
		unixDateMS: 1659950500000,
		picture: 'https://pbs.twimg.com/profile_images/1638300003977551872/9xUDGO8E_normal.jpg',
		categories: ['Familiar faces'],
	},
	{
		logo: 'theinformation',
		link: 'https://www.theinformation.com/articles/the-co-founders-of-eight-sleep-want-to-optimize-your-performance-in-bed',
		headline: 'The Co-Founders of Eight Sleep Want to Optimize Your Performance (in Bed)',
		image: 'https://tii.imgix.net/production/articles/9325/6467824e-0d85-4fa7-8a88-b1b8a2f4ca55.gif',
		categories: [],
	},

	{
		picture: 'https://pbs.twimg.com/profile_images/1363316601974644736/JKdIckKh_normal.jpg',
		name: 'Matthew Dellavedova',
		handle: 'matthewdelly',
		unixDateMS: 1661529150000,
		platform: 'twitter',
		content: 'First thing I had on my list to get set up in Sacramento- thanks @eightsleep 💤👍',
		verified: true,
		image: 'https://pbs.twimg.com/media/FbGTcmGVQAAaPb8?format=jpg&name=large',
		url: 'https://twitter.com/matthewdelly/status/1563192671614554112',
		categories: ['Familiar faces'],
	},

	{
		picture: 'https://pbs.twimg.com/profile_images/1658240864706084865/lixSwgYL_normal.jpg',
		name: 'Benjamin Smith',
		handle: 'itmebenjamin',
		unixDateMS: 1660915801000,
		platform: 'twitter',
		content:
			'Better comfort means better sleep.\n\t\t\t<br/><br/>\n\t\t\tBetter sleep means better health, happiness, and productivity.\n\t\t\t<br/><br/>\n\t\t\tMy @eightsleep mattress has been one of the best investments I’ve made. ',
		verified: true,
		url: 'https://twitter.com/itmebenjamin/status/1560620102101245953',
		categories: ['Familiar faces'],
	},

	{
		picture: 'https://pbs.twimg.com/profile_images/1745355827761025024/09OxXngw_normal.jpg',
		name: 'The Crypto Dog 📈',
		handle: 'TheCryptoDog',
		unixDateMS: 1695034880000,
		platform: 'twitter',
		content:
			'I have made two "expensive" purchases in my life that have dwarfed all other spending in outcomes - literally life changing.<br/><br/>The 1st was Lasik eye surgery - switching from contacts that constantly wore out, got dirty, made my eyes dry, to simply having perfect vision from when I…  ',
		verified: false,
		url: 'https://twitter.com/TheCryptoDog/status/1703725889450889538',
		categories: ['Sleep improvement', 'Everyday love'],
		image: 'https://pbs.twimg.com/media/F6TZqXkaUAAjGKm.jpg',
	},
	{
		platform: 'twitter',
		verified: true,
		url: 'https://twitter.com/heyKevD/status/1557051326042968066',
		name: 'collectorkev 🧙🏻‍♂️',
		handle: 'heyKevD',
		content: 'I got the @nectarsleepmattress with the @eightsleep pod system and its amazing. Adding the temp control has been a game changer for my sleep tbh ',
		unixDateMS: 1660064939000,
		picture: 'https://pbs.twimg.com/profile_images/1574742348801740801/cLrY-19G_normal.jpg',
		categories: ['Familiar faces'],
	},

	{
		platform: 'twitter',
		verified: false,
		url: 'https://twitter.com/rachneylan/status/1556275587370651648',
		name: 'Rachel Neylan OLY',
		handle: 'rachneylan',
		content: "The heatwave continues but so do post training recovery naps enabled by \n\t\t\t@eightsleep\n\t\t\t... much needed after last week's load from \n\t\t\t@LeTourFemmes\n\t\t\t! 😴💯🙋🏼‍♀️🙌🏻.",
		unixDateMS: 1659879988000,
		picture: 'https://pbs.twimg.com/profile_images/1609897292487856129/iMpRgSEH_normal.jpg',
		image: 'https://pbs.twimg.com/media/FZkAZhPWIAE6mOO?format=jpg&name=large',
		categories: ['Familiar faces'],
	},
	{
		platform: 'twitter',
		verified: false,
		url: 'https://twitter.com/flu_dot_com/status/1552304100091596804',
		name: 'Andre Fluellen',
		handle: 'flu_dot_com',
		content:
			'I didn’t know I was sleeping dumb until I started sleeping smart! 😂😂😂 @eightsleep beds are the TRUTH! For a night sweater like myself, the bed always keeps me cool. I’m excited for the new Pod 3!',
		unixDateMS: 1658933112000,
		picture: 'https://pbs.twimg.com/profile_images/1038787293555974146/p0aHbyCS_normal.jpg',
		categories: ['Familiar faces'],
	},
	{
		logo: 'theathletic',
		link: 'https://theathletic.com/5099278/2023/11/30/brock-purdy-recovery-49ers-sleep-nick-bosa',
		headline: 'On Brock Purdy’s quick recovery, his mental sharpness and the 49ers’ power of sleep',
		image: 'https://api.typeform.com/responses/files/1270af33fd4d114b0452f19d4f01eaf39c822454d0b6dd4f26ebafeb08212d51/brockpurdy.jpg',
		categories: ['Familiar faces'],
	},

	{
		platform: 'twitter',
		verified: false,
		url: 'https://twitter.com/rachneylan/status/1549389876189138951',
		name: 'Rachel Neylan OLY',
		handle: 'rachneylan',
		content:
			'Early trainings, afternoon naps and full nights of deep quality sleep during a heatwave... for the first time in my life made possible by @eightsleep! 😴🌡 <br/><br/>\n#heatwave #sleepfitness',
		unixDateMS: 1658238307000,
		picture: 'https://pbs.twimg.com/profile_images/1609897292487856129/iMpRgSEH_normal.jpg',
		image: 'https://pbs.twimg.com/media/FYCJ4Y_WIAAP4Oo?format=jpg&name=large',
		categories: ['Familiar faces'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1602122467002155010/MI7V7cqu_normal.png',
		name: 'Josh Ong',
		handle: 'beijingdou',
		unixDateMS: 1606761565000,
		platform: 'twitter',
		content: 'So, I offered to get an @eightsleep tattoo in exchange for a Pod Pro. Every owner I know says the bed changed their life. I can get behind a brand that champions better sleep. 🛌 ',
		verified: true,
		image: 'https://pbs.twimg.com/media/EoF30PPWMAMRrLK?format=jpg&name=4096x4096',
		url: 'https://twitter.com/beijingdou/status/1333480771412250624?s=20',
		categories: ['Familiar faces'],
	},
	{
		platform: 'twitter',
		verified: true,
		url: 'https://twitter.com/stevencundari/status/1542912349375324160/photo/1',
		name: 'Steven Cundari',
		handle: 'stevencundari',
		content: 'Word on the street is that this new mattress is going to be life changing \n@eightsleep\n…',
		unixDateMS: 1658238307000,
		picture: 'https://pbs.twimg.com/profile_images/1677036257086693376/SC9i7yYH_normal.jpg',
		image: 'https://pbs.twimg.com/media/FWmGmVbUYAAPQEQ?format=jpg&name=large',
		categories: ['Familiar faces'],
	},
	{
		platform: 'twitter',
		verified: true,
		url: 'https://twitter.com/Nicolascole77/status/1542114479927861253',
		name: 'Nicolas Cole 🚢',
		handle: 'Nicolascole77',
		content: 'Most life-changing purchase I’ve made in a long time. It’s insanely addicting',
		unixDateMS: 1656503717000,
		picture: 'https://pbs.twimg.com/profile_images/1643230477854097408/Rp3nKAK4_normal.jpg',
		categories: ['Familiar faces'],
	},
	{
		platform: 'twitter',
		verified: true,
		url: 'https://twitter.com/adamjustice/status/1531712623158476801',
		name: 'Adam Justice',
		handle: 'adamjustice',
		content: 'It is such a great product!  Added to the list of things I miss when I travel now!',
		unixDateMS: 1654023721000,
		picture: 'https://pbs.twimg.com/profile_images/1581017161836920834/038uwkoB_normal.jpg',
		categories: ['Familiar faces'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1596150277450653696/yQKnOmbF_normal.jpg',
		name: 'Omar Morales',
		handle: 'OmarMora1es',
		unixDateMS: 1657475157000,
		platform: 'twitter',
		content: '2 weeks in..<br/><br/>can confirm @eightsleep is a must ',
		verified: true,
		url: 'https://twitter.com/OmarMora1es/status/1546188995310374913',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FXUqsiMXEAEDyAA.jpg',
	},

	{
		platform: 'twitter',
		verified: true,
		url: 'https://twitter.com/sthenc/status/1528474368925261825',
		name: 'Stephen Cole',
		handle: 'sthenc',
		content: "I'm not an investor (sad!) but love @eightsleep. Been using it almost 2 years now and it's been a big improvement in sleep quality.",
		unixDateMS: 1653251661000,
		picture: 'https://pbs.twimg.com/profile_images/1421511472694267905/CDAOU4nR_normal.jpg',
		categories: ['Familiar faces'],
	},

	{
		platform: 'twitter',
		verified: false,
		url: 'https://twitter.com/rachneylan/status/1549389876189138951',
		name: 'Rachel Neylan OLY',
		handle: 'rachneylan',
		content: 'Time to unbox an exciting new world of insight with @eightsleep!',
		unixDateMS: 1652374433000,
		picture: 'https://pbs.twimg.com/profile_images/1609897292487856129/iMpRgSEH_normal.jpg',
		image: 'https://pbs.twimg.com/media/FSko-e7XEAEQoUB?format=jpg&name=medium',
		categories: ['Familiar faces'],
	},
	{
		platform: 'twitter',
		verified: true,
		url: 'https://twitter.com/tclarkmedia/status/1524379476012019720',
		name: 'Tommy Clark',
		handle: 'tclarkmedia',
		content: 'also desperately miss my @eightsleep and a normal sleep schedule LOL',
		unixDateMS: 1652275363000,
		picture: 'https://pbs.twimg.com/profile_images/1669443301425008644/LGhjhaob_normal.jpg',
		categories: ['Familiar faces'],
	},

	{
		platform: 'twitter',
		verified: true,
		url: 'https://twitter.com/bentossell/status/1520347271824560128',
		name: 'Ben Tossell',
		handle: 'bentossell',
		content: 'Finally! \n\t\t\t<br/><br/>\n\t\t\tMe in bed: cold\n\t\t\tMy wife in bed: hot\n\t\t\t<br/><br/>\n\t\t\t🥳 \n\t\t\t@eightsleep',
		unixDateMS: 1651314010000,
		picture: 'https://pbs.twimg.com/profile_images/1595060668897677314/pHMUc1Zb_normal.jpg',
		image: 'https://pbs.twimg.com/media/FRlbyO5WYAAl-mu?format=jpg&name=large',
		categories: ['Familiar faces'],
	},
	{
		name: "Kristi Eramo O'Connell",
		thumbnail: 'https://eightsleep.imgix.net/Kristi_Eramo_O_Connell.png?v=1692115540',
		video: 'https://d115sb6i1ixllw.cloudfront.net/assets/382a09c6153b41fe976d26331bb46311.mp4',
		categories: ['Familiar faces'],
		title: 'CrossFit Athlete',
	},
	{
		platform: 'twitter',
		verified: true,
		url: 'https://twitter.com/jbonhotal/status/1517619950722031616',
		name: 'Joshua Bonhotal',
		handle: 'jbonhotal',
		content: 'We have an old house, so the AC is basically non-existent in our upstairs bedroom — Eight Sleep solves the problem entirely.',
		unixDateMS: 1650663766000,
		picture: 'https://pbs.twimg.com/profile_images/1569837782620688384/00iZoTJN_normal.jpg',
		categories: ['Familiar faces'],
	},
	{
		platform: 'twitter',
		verified: false,
		url: 'https://twitter.com/vc/status/1516243541361500163',
		name: 'Jake Chapman 🇺🇸🚀 ✨🇺🇸',
		handle: 'vc',
		content: 'It’s awesome!',
		unixDateMS: 1650663766000,
		picture: 'https://pbs.twimg.com/profile_images/1600898941540671488/vZ-cvZ4W_normal.jpg',
		categories: ['Familiar faces'],
	},
	{
		platform: 'twitter',
		verified: true,
		url: 'https://twitter.com/anothercohen/status/1514461570935537665',
		name: 'Alex Cohen',
		handle: 'anothercohen',
		content: "Most expensive mattress I've ever purchased, hope it's life changing",
		unixDateMS: 1649910750000,
		picture: 'https://pbs.twimg.com/profile_images/1670980940535939072/4oULSqIz_normal.jpg',
		image: 'https://pbs.twimg.com/media/FQRyq-PWUAov88m?format=jpg&name=4096x4096',
		categories: ['Familiar faces'],
	},
	{
		platform: 'twitter',
		verified: false,
		url: 'https://twitter.com/Gojo_Crypto/status/1508967692144832513',
		name: 'Gojo',
		handle: 'Gojo_Crypto',
		content: 'Highly recommend @eightsleep!',
		unixDateMS: 1648600907000,
		picture: 'https://pbs.twimg.com/profile_images/1471890137948717059/3AIcR-7W_normal.jpg',
		categories: ['Familiar faces'],
	},
	{
		logo: 'thetimes',
		link: 'https://www.thetimes.co.uk/article/how-do-tech-millionaires-sleep-at-night-in-this-couples-bed-xffmcrx9q',
		headline: "How do tech millionaires sleep at night? In this couple's bed\n",
		image: 'https://eightsleep.imgix.net/methode-times-prod-web-bin-0c6aae10-081a-11ed-a986-fc91b4ad48f0.jpg?v=1692029340',
		categories: ['Familiar faces'],
	},
	{
		platform: 'twitter',
		verified: true,
		url: 'https://twitter.com/Trace_Cohen/status/1503705823968731140',
		name: 'Trace Cohen',
		handle: 'Trace_Cohen',
		content: 'When you’re not feeling well for multiple days but have no fever, test negative etc but your smart bed \n@eightsleep\n backs you up to show you’re not crazy/lazy…',
		unixDateMS: 1647346380000,
		picture: 'https://pbs.twimg.com/profile_images/1687521292587585555/qeMNK-hI_normal.jpg',
		image: 'https://pbs.twimg.com/media/FN48egyXoAABP0N?format=jpg&name=large',
		categories: ['Familiar faces'],
	},
	{
		name: 'Shervin Shares',
		thumbnail: 'https://eightsleep.imgix.net/IMG_6163_1.jpg?v=1692041493',
		video: 'https://d115sb6i1ixllw.cloudfront.net/assets/106fcec5037b4fdfaa1883fa5c815646.mov',
		categories: ['Everyday love'],
		title: 'Biohacker',
	},
	{
		platform: 'twitter',
		verified: true,
		url: 'https://twitter.com/intentionally/status/1502322440566886402',
		name: 'Sean Johnson 🔥',
		handle: 'intentionally',
		content: '@eightsleep is the best thing I’ve ever bought.',
		unixDateMS: 1647016556000,
		picture: 'https://pbs.twimg.com/profile_images/1647015695882788865/7Dqoy_rV_normal.jpg',
		categories: ['Familiar faces'],
	},
	{
		platform: 'twitter',
		verified: false,
		url: 'https://twitter.com/aweberphoto/status/1501745910522560512',
		name: 'Andrew Weber',
		handle: 'aweberphoto',
		content: 'Order an @eightsleep now!',
		unixDateMS: 1646879100000,
		picture: 'https://pbs.twimg.com/profile_images/1431311053879717890/v_AmaOVj_normal.jpg',
		categories: ['Familiar faces'],
	},
	{
		platform: 'twitter',
		verified: true,
		url: 'https://twitter.com/tclarkmedia/status/1500480473386082309',
		name: 'Tommy Clark',
		handle: 'tclarkmedia',
		content:
			'First night in the \n\t\t\t@eightsleep\n\t\t\t </br></br>\n\t\t\tREM has historically been way low for me (like less than 30 minutes)\n\t\t\t </br></br>\n\t\t\tOver an hour last night 🤯',
		unixDateMS: 1646577397000,
		picture: 'https://pbs.twimg.com/profile_images/1669443301425008644/LGhjhaob_normal.jpg',
		image: 'https://pbs.twimg.com/media/FNLHCa1XEAE_4ag?format=jpg&name=large',
		categories: ['Familiar faces'],
	},
	{
		platform: 'twitter',
		verified: true,
		url: 'https://twitter.com/tclarkmedia/status/1499451646111268865',
		name: 'Tommy Clark',
		handle: 'tclarkmedia',
		content: 'it’s hereeeee @eightsleep 😍',
		unixDateMS: 1646332105000,
		picture: 'https://pbs.twimg.com/profile_images/1669443301425008644/LGhjhaob_normal.jpg',
		image: 'https://pbs.twimg.com/media/FM8fTuAXoAIeviP?format=jpg&name=large',
		categories: ['Familiar faces'],
	},
	{
		platform: 'twitter',
		verified: true,
		url: 'https://twitter.com/CaseyAdams/status/1499416240938381312',
		name: 'Casey Adams',
		handle: 'CaseyAdams',
		content: 'I highly recommend \n@eightsleep\n. It has completely upgraded my sleeping experience.',
		unixDateMS: 1646323664000,
		picture: 'https://pbs.twimg.com/profile_images/1593018180896575489/hEKh6Dh1_normal.jpg',
		categories: ['Familiar faces'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1602390323606392832/eV0wwl_6_normal.jpg',
		name: 'christina trexler',
		handle: 'xtinacomputes',
		unixDateMS: 1646250229000,
		platform: 'twitter',
		content: 'i’ve been loving it so far',
		verified: true,
		url: 'https://twitter.com/xtinacomputes/status/1499108231343312901',
		categories: ['Familiar faces'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1463499282036756482/D3nSbqZb_normal.jpg',
		name: 'Josh Wolfe',
		handle: 'wolfejosh',
		unixDateMS: 1705015666000,
		platform: 'twitter',
		content:
			'Truly incredible vision, leadership, execution from @m_franceschetti + full @eightsleep team 🛏<br/><br/>Product + design + function + culture + customer service<br/><br/>I still regret the miss for us @ Lux BUT at least I get to reap the dividends of amazing sleep😴💤<br/><br/>KEEP GOING! KEEP GROWING!',
		verified: false,
		url: 'https://twitter.com/wolfejosh/status/1745588340638765350',
		categories: ['Familiar faces'],
	},
	{
		platform: 'twitter',
		verified: true,
		url: 'https://twitter.com/BillBrewsterTBB/status/1498107481549152260',
		name: 'Bill Brewster',
		handle: 'BillBrewsterTBB',
		content: '@eightsleep<br/><br/>\nIts a serious gamechanger for me.',
		unixDateMS: 1646011631000,
		picture: 'https://pbs.twimg.com/profile_images/1408274077660454913/pjOVowDS_normal.jpg',
		categories: ['Familiar faces'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1524528385950593026/dMfDafhn_normal.jpg',
		name: 'Morgan Brown',
		handle: 'morganb',
		unixDateMS: 1646401558000,
		platform: 'twitter',
		content: 'Yes went from Amazon > Purple > Eight Sleep. Massive difference w/ \n@eightsleep\n   Best purchase I’ve probably ever made.',
		verified: true,
		url: 'https://twitter.com/morganb/status/1499742953266302977',
		categories: ['Familiar faces'],
	},

	{
		picture: 'https://pbs.twimg.com/profile_images/1285717583249973250/Gb6M9zPg_normal.jpg',
		name: 'Jordan Cooper',
		handle: 'jordancooper',
		unixDateMS: 1701718387000,
		platform: 'twitter',
		content:
			'I’ve wanted an Eight Sleep for over a year. Finally bit the bullet (it’s expensive). Measuring against historical Whoop data, it’s 2x’d both my REM and Deep sleep relative to previous averages 🤯. Only 2 days in, so who knows, but I think I’m a believer…',
		verified: false,
		url: 'https://twitter.com/jordancooper/status/1731758550538461566',
		categories: ['Sleep improvement'],
	},
	{
		platform: 'twitter',
		verified: true,
		url: 'https://twitter.com/NivDror/status/1499488959709290499?s=20&t=HkFKWpFHmBHzag_2tmhvrg',
		name: 'Niv Dror',
		handle: 'NivDror',
		content: 'new outdoor furniture',
		unixDateMS: 1646341001000,
		picture: 'https://pbs.twimg.com/profile_images/1253596896800542720/_AYMSHsD_normal.jpg',
		video: 'https://d115sb6i1ixllw.cloudfront.net/assets/def3d0cc030d4958a8c792ab282118ef.mp4',
		categories: ['Familiar faces'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1464099414570520576/A8MEHy4P_400x400.jpg',
		name: 'Kosti Vasilakakis',
		handle: 'kcvasilakakis',
		unixDateMS: 1702431488000,
		platform: 'twitter',
		content:
			'17% increase of restorative sleep with vs. without @eightsleep. June and August without vs. September onwards with. Impressive! Have been feeling the difference but it’s quite eye opening to back it up with real data. Nice work @m_franceschetti and thanks to @WHOOP for the Year in Review insights! We live in the future!',
		verified: true,
		url: 'https://twitter.com/kcvasilakakis/status/1734749510952214993',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/GBMRfCUXAAAM4Rn?format=jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1596150277450653696/yQKnOmbF_normal.jpg',
		name: 'Omar Morales',
		handle: 'OmarMora1es',
		unixDateMS: 1667993755000,
		platform: 'twitter',
		content:
			'Cant recommend @eightsleep enough<br/><br/>It used to be impossible for me to sleep more than 5-6 hrs a day. <br/><br/>Now I’m sleeping 7-8hrs/day and waking up with noticeably more energy<br/><br/>Cc @m_franceschetti 🙏🏼 ',
		verified: true,
		url: 'https://twitter.com/OmarMora1es/status/1590307190476533760',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FhHn9ehWAAAVmxN.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1600461694572142592/fL5rbRGy_normal.jpg',
		name: 'kevin flynn',
		handle: 'flynniest',
		unixDateMS: 1663761297000,
		platform: 'twitter',
		content: 'Without @eightsleep vs with @eightsleep ',
		verified: true,
		url: 'https://twitter.com/flynniest/status/1572554977448722432',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FdLWapFXgAQSX58.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1453810309831946251/i5XlmjqB_normal.jpg',
		name: 'JT ✨',
		handle: 'jtjdt',
		unixDateMS: 1662500120000,
		platform: 'twitter',
		content: 'So, @eightsleep has been an incredible investment with all this excessive heat 🥶🥶🥶 ',
		verified: false,
		url: 'https://twitter.com/jtjdt/status/1567265216702074882',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FcALUjsacAArRY7.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1419009800646561792/HNdvSmcN_normal.jpg',
		name: 'Kyriakos Eleftheriou',
		handle: 'kyriakosel',
		unixDateMS: 1646928179000,
		platform: 'twitter',
		content: '1 month with @eightsleep. From 30 tosses and turns, to 12. This works 🔥@m_franceschetti ',
		verified: true,
		url: 'https://twitter.com/kyriakosel/status/1501951760432128005',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FNgBKZ-VQAI9CR7.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1537934745937453056/E8Lvr25D_normal.jpg',
		name: 'Pierre Lipton',
		handle: 'LiptonPierre',
		unixDateMS: 1654787128000,
		platform: 'twitter',
		content: 'Got my @eightsleep last night… can you tell? ',
		verified: false,
		url: 'https://twitter.com/LiptonPierre/status/1534914583604912128',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FU0crZsXoAAbDLD.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1592894439479021568/FQsQbXd8_normal.jpg',
		name: 'Luke Fischer',
		handle: 'FischerLukeM',
		unixDateMS: 1666625138000,
		platform: 'twitter',
		content: 'After a very fun weekend here in Austin (thanks @bp22 !!) I needed my @eightsleep to start the week right! ',
		verified: true,
		url: 'https://twitter.com/FischerLukeM/status/1584566793448095745',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/Ff2DGb3WIAAy7MT.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1525673296792113152/RwbJHL7-_normal.jpg',
		name: 'Jesse Daugherty',
		handle: 'jtdaugh',
		unixDateMS: 1666798197000,
		platform: 'twitter',
		content: 'Such a life hack @eightsleep ',
		verified: true,
		url: 'https://twitter.com/jtdaugh/status/1585292658540437505',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FgAXRQEWQAESfjs.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1234859826158743552/57DkMD7n_normal.jpg',
		name: 'Bob Freier',
		handle: 'bobfreier',
		unixDateMS: 1662125631000,
		platform: 'twitter',
		content: 'I woke up today feeling great. Then I looked and found out why. @eightsleep ',
		verified: false,
		url: 'https://twitter.com/bobfreier/status/1565694497052364804',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/Fbp22M-WQAIz248.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1592894439479021568/FQsQbXd8_normal.jpg',
		name: 'Luke Fischer',
		handle: 'FischerLukeM',
		unixDateMS: 1661435609000,
		platform: 'twitter',
		content: 'Nice info from @eightsleep. I’ve been using the mediations in the app as well as @Headspace instead of mindlessly scrolling Twitter at night. 😴💤 ',
		verified: true,
		url: 'https://twitter.com/FischerLukeM/status/1562800333587329024',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FbAul_xWAAAVyxl.jpg',
	},

	{
		picture: 'https://pbs.twimg.com/profile_images/1419009800646561792/HNdvSmcN_normal.jpg',
		name: 'Kyriakos Eleftheriou',
		handle: 'kyriakosel',
		unixDateMS: 1661420924000,
		platform: 'twitter',
		content: "@paulg My HRV is consistently 50% lower when I don't use my @eightsleep",
		verified: true,
		url: 'https://twitter.com/kyriakosel/status/1562738740086587394',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1607404671349424134/rOAC7eu6_normal.jpg',
		name: 'Robin',
		handle: 'codeOfRobin',
		unixDateMS: 1660920300000,
		platform: 'twitter',
		content: 'I’ve had an @eightsleep for all of 2 days and I’m already feeling fresher in the morning 🙌 ',
		verified: false,
		url: 'https://twitter.com/codeOfRobin/status/1560638971364839424',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FaiA30rVQAIDppP.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1671143758920839169/aZt9mhKT_normal.jpg',
		name: '꧁𝓝𝓲𝓬𝓸𝓵𝓮 𝓜𝓪𝓻𝓲𝓮꧂',
		handle: 'nmbernard',
		unixDateMS: 1660557582000,
		platform: 'twitter',
		content: 'It’s going to be a good, good day… 😴💤<br/>@eightsleep ',
		verified: false,
		url: 'https://twitter.com/nmbernard/status/1559117622754660353',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FaMZNt0XwAEDZV2.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1580188498748968961/L6lebFCL_normal.jpg',
		name: 'Louisa Nicola',
		handle: 'louisanicola_',
		unixDateMS: 1660223332000,
		platform: 'twitter',
		content: 'My Oura is telling me that I’m pretty much dying. I haven’t had a sip of alcohol on this trip… my low score is because I’m away from my @eightsleep - it’s the only explanation ',
		verified: true,
		url: 'https://twitter.com/louisanicola_/status/1557715674331549698',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FZ4eJVPWAAAOwqt.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1589748123026366464/vEDgWQEc_normal.jpg',
		name: 'Jonny Miller',
		handle: 'jonnym1ller',
		unixDateMS: 1660155733000,
		platform: 'twitter',
		content:
			'Experimenting with the @eightsleep pod pro cover &amp; v. impressed so far.<br/><br/>@kellycwilde and I have different preferred sleep temps so being able to adjust each half of our bed has been a huge win 🌡️🥶 ',
		verified: true,
		url: 'https://twitter.com/jonnym1ller/status/1557432143344652288',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FZ0cR1TXkAITRZa.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1672109011389534208/-3VQ1iSB_normal.jpg',
		name: 'Travis Boudreaux',
		handle: 'tjboudreaux',
		unixDateMS: 1659373229000,
		platform: 'twitter',
		content:
			'As the weight started coming off my sleep started improving, my thought process became clearer and I decided to get an @eightsleep to start hacking my sleep. <br/><br/>My mood improved, appetite control improved and my ability to think and create found new levels. ',
		verified: true,
		url: 'https://twitter.com/tjboudreaux/status/1554150084450734080',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FZFzQ7fVUAEDENb.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1656798354461044737/7QxhvJl3_normal.jpg',
		name: 'Bryan Altman',
		handle: 'altmbr',
		unixDateMS: 1704394266000,
		platform: 'twitter',
		content:
			'Is @eightsleep worth it? Personal data shows 46% more sleep (src: 1 week each w @Apple Watch Ultra 2).<br/><br/>Avg NO Eightsleep :<br/>-&gt; REM : 1hr17min<br/>-&gt; Deep : 44min<br/><br/>Avg WITH Eightsleep :<br/>-&gt; REM : 1hr49min<br/>-&gt; Deep : 1hr6min<br/><br/>Note on Experimental Bias : NO Eightsleep recorded at Cottage.… ',
		verified: false,
		url: 'https://twitter.com/altmbr/status/1742982001345298616',
		categories: ['Sleep improvement', 'Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/3401499052/0c16e8ad3d06a1102a8f96f82baed177_normal.jpeg',
		name: 'Ramsey Cox',
		handle: 'RamseyCox22',
		unixDateMS: 1658869780000,
		platform: 'twitter',
		content: 'Sleeping is healing, thanks @eightsleep 💪 ',
		verified: false,
		url: 'https://twitter.com/RamseyCox22/status/1552038465260228608',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FYnyweWUUAURQGy.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1663958551117561860/tXy7zFPu_normal.jpg',
		name: 'Barely Accredited',
		handle: 'baccredited',
		unixDateMS: 1658414436000,
		platform: 'twitter',
		content: 'My @eightsleep knew I had COVID before I did ',
		verified: false,
		url: 'https://twitter.com/baccredited/status/1550128614934360072',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FYMpwe-UcAAU5Tk.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1271264092729335808/og1Gc6ot_normal.jpg',
		name: 'Dr. Sohaib Imtiaz, MD MPH',
		handle: 'digitald0ctor',
		unixDateMS: 1657622145000,
		platform: 'twitter',
		content: 'The power of tech. Caught covid on the weekend, @eightsleep picked it up with a rise in sleeping heart rate a day before my test was positive @m_franceschetti ',
		verified: true,
		url: 'https://twitter.com/digitald0ctor/status/1546805506999738368',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FXdbaNdXwAMh8qa.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1287042110412271619/hv5MHqGx_normal.jpg',
		name: 'Chris Young',
		handle: 'Homekitgeek',
		unixDateMS: 1657593747000,
		platform: 'twitter',
		content: 'Worst part of having an @eightsleep sleep system is those nights when you don’t. I know exactly what affected my sleep last night thanks… Missing my bed. ',
		verified: false,
		url: 'https://twitter.com/Homekitgeek/status/1546686397746126848',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FXbvFMeWQAIatfx.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1419009800646561792/HNdvSmcN_normal.jpg',
		name: 'Kyriakos Eleftheriou',
		handle: 'kyriakosel',
		unixDateMS: 1657565747000,
		platform: 'twitter',
		content: "@elonmusk Or just use @eightsleep - here's my actual results on sleep improvement<br/>",
		verified: true,
		url: 'https://twitter.com/kyriakosel/status/1546568956240166913',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1206207547453005825/u1HEyuJQ_normal.jpg',
		name: 'Alexander A. Morgan, MD PhD',
		handle: 'genomicsdoc',
		unixDateMS: 1657084214000,
		platform: 'twitter',
		content: 'Thermal imaging of my side of the bed (right) and my wife’s on the left.  Thank you @eightsleep. ',
		verified: false,
		url: 'https://twitter.com/genomicsdoc/status/1544549258103115776',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FW9XXVPUUAACoVS.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1443605189391372293/LU-50-DC_normal.jpg',
		name: 'Patrick Campbell',
		handle: 'Patticus',
		unixDateMS: 1656936507000,
		platform: 'twitter',
		content: 'Can you tell when I slept with @eightsleep and when I didn’t?<br/><br/>I’d never believe it unless the difference was this dramatic. ',
		verified: true,
		url: 'https://twitter.com/Patticus/status/1543929732030406658',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FW0j6ILX0AAORn5.jpg',
	},
	{
		name: 'Amy Bream',
		thumbnail: 'https://eightsleep.imgix.net/Amy_Bream.png?v=1692115540',
		video: 'https://d115sb6i1ixllw.cloudfront.net/assets/e7475c9e01334aa3b52c12c039456477.mp4',
		categories: ['Familiar faces'],
		title: 'CrossFit Athlete',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1600216663319842835/4tk7mSB3_normal.jpg',
		name: 'E-wan Kenobi',
		handle: 'EwwyVert',
		unixDateMS: 1655732833000,
		platform: 'twitter',
		content: 'First night back in the @eightsleep after 10 days away 😂😂😂 I was missing it clearly ',
		verified: false,
		url: 'https://twitter.com/EwwyVert/status/1538881155193266181',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FVs0QNFWYAEKhL_.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1295837948257038336/EEUg1rUL_normal.jpg',
		name: 'MattyG',
		handle: 'meawoppl',
		unixDateMS: 1655501519000,
		platform: 'twitter',
		content: 'Oh man. @eightsleep provided some lovely plots this week to help explain why I felt like total crap today.<br/><br/>Here is "COVID fever night" ',
		verified: false,
		url: 'https://twitter.com/meawoppl/status/1537910956046438406',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FVfB3XOVsAAoEOd.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1642135743689641985/dipuZiBO_normal.jpg',
		name: 'Trent Turner',
		handle: 'RealTrentTurner',
		unixDateMS: 1655386449000,
		platform: 'twitter',
		content: 'Let’s goo 🏃‍♂️😴 @eightsleep ',
		verified: true,
		url: 'https://twitter.com/RealTrentTurner/status/1537428315686023168',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FVYK51AXEAApzLo.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1659946435888963585/gn4dfBKE_normal.jpg',
		name: 'Nafis Azad',
		handle: 'AzadNafis',
		unixDateMS: 1655216654000,
		platform: 'twitter',
		content: 'First night on my @eightsleep 💯 ',
		verified: true,
		url: 'https://twitter.com/AzadNafis/status/1536716144815611909',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FVODMFlX0AAHta6.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/3401499052/0c16e8ad3d06a1102a8f96f82baed177_normal.jpeg',
		name: 'Ramsey Cox',
		handle: 'RamseyCox22',
		unixDateMS: 1655216320000,
		platform: 'twitter',
		content: '@m_franceschetti @eightsleep The one day I had alcohol my HRV was only 62. Amazing how mailable sleep metrics are ',
		verified: false,
		url: 'https://twitter.com/RamseyCox22/status/1536714743812984832',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FVOB6idUUAAYifS.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1537934745937453056/E8Lvr25D_normal.jpg',
		name: 'Pierre Lipton',
		handle: 'LiptonPierre',
		unixDateMS: 1654787128000,
		platform: 'twitter',
		content: 'Got my @eightsleep last night… can you tell? ',
		verified: false,
		url: 'https://twitter.com/LiptonPierre/status/1534914583604912128',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FU0crZsXoAAbDLD.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1234859826158743552/57DkMD7n_normal.jpg',
		name: 'Bob Freier',
		handle: 'bobfreier',
		unixDateMS: 1654437420000,
		platform: 'twitter',
		content: 'Sundays are usually chill days for me. But I have too much energy to chill. Thanks @eightsleep ',
		verified: false,
		url: 'https://twitter.com/bobfreier/status/1533447799601250306',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FUfmpVWX0AEGFCS.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1667970409461948416/2MFxDvM0_normal.jpg',
		name: 'Rob Broderick',
		handle: 'RobxBroderick',
		unixDateMS: 1654337635000,
		platform: 'twitter',
		content: 'Let’s go 💪🏻 <br/><br/>@eightsleep ',
		verified: true,
		url: 'https://twitter.com/RobxBroderick/status/1533029270339170305',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FUZp_FHXsAAWzEn.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1668448923692957696/MTtKNq1X_normal.jpg',
		name: 'Nate Williams',
		handle: 'naywilliams',
		unixDateMS: 1654013360000,
		platform: 'twitter',
		content: '.@eightsleep “You complete me” ✅🏆💥🤝 ',
		verified: true,
		url: 'https://twitter.com/naywilliams/status/1531669166351994880',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FUGU_PSUsAATgVP.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1600593627033501697/Oip202cn_normal.jpg',
		name: 'Greg Kahn | GK Digital Ventures',
		handle: 'GK_Ventures',
		unixDateMS: 1653669618000,
		platform: 'twitter',
		content: 'Thankful for a great @eightsleep night ',
		verified: false,
		url: 'https://twitter.com/GK_Ventures/status/1530227407160061955',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FTx1t27XEAMj2u6.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1634974141248856064/KBH_JDC6_normal.jpg',
		name: 'kirtan',
		handle: 'kirtandesai',
		unixDateMS: 1653232343000,
		platform: 'twitter',
		content: 'My RHR has been 10 beats/min lower since I started sleeping on @eightsleep . The drop is consistent on all monitors. Whoop, Oura, Apple Watch, etc',
		verified: false,
		url: 'https://twitter.com/kirtandesai/status/1528393341838282752',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1271264092729335808/og1Gc6ot_normal.jpg',
		name: 'Dr. Sohaib Imtiaz, MD MPH',
		handle: 'digitald0ctor',
		unixDateMS: 1653138078000,
		platform: 'twitter',
		content: 'It’s gonna be a good day @eightsleep ',
		verified: true,
		url: 'https://twitter.com/digitald0ctor/status/1527997965679218688',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FTSKDCVWYAA1Va-.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1541488975864537088/fuqAL7e3_normal.jpg',
		name: 'Erik Pavia',
		handle: 'erikpavia',
		unixDateMS: 1653082506000,
		platform: 'twitter',
		content:
			"I was making coffee this morning and felt weirdly warm. I realized I accidentally left the A/C off all night.<br/><br/>My Eight Sleep kept me cool enough that I didn't notice, and my body temperature actually dropped.<br/><br/>Such a great product for people in warm climates. @eightsleep ",
		verified: true,
		url: 'https://twitter.com/erikpavia/status/1527764879557394433',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FTO2DzjXoAY1qaV.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1432011083733258249/GTy9Ugqf_normal.jpg',
		name: 'Jay Muller',
		handle: 'JayMuller10',
		unixDateMS: 1652269191000,
		platform: 'twitter',
		content: 'Sleep streak underway 3x100. Going for the perfect week @eightsleep ',
		verified: false,
		url: 'https://twitter.com/JayMuller10/status/1524353588687945728',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FSeXgmXXIAAm6Xv.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1366408601766490114/TnjFqILn_normal.jpg',
		name: 'Michal Cieplinski',
		handle: 'm_cieplinski',
		unixDateMS: 1652197053000,
		platform: 'twitter',
		content: 'Fully recovered from an amazing F1 in Miami. Thank you @eightsleep for the best rejuvenation tool! ',
		verified: true,
		url: 'https://twitter.com/m_cieplinski/status/1524051022192078848',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FSaEU8GVEAA1Ilz.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1105148647157620737/6GoBkSpB_normal.png',
		name: 'Michael Schade',
		handle: 'sch',
		unixDateMS: 1652141448000,
		platform: 'twitter',
		content: 'proof from @eightsleep that spending the day with your mom is good for you ',
		verified: true,
		url: 'https://twitter.com/sch/status/1523817797868048384',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FSWwKd0XMAAdtKI.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1019628812483604480/965ymS27_normal.jpg',
		name: 'Matt Haggman 🇺🇦',
		handle: 'matthaggman',
		unixDateMS: 1651747735000,
		platform: 'twitter',
		content: 'Always a good way to start. Wishing a great day ahead. Let’s make it count. @eightsleep ',
		verified: false,
		url: 'https://twitter.com/matthaggman/status/1522166442682200064',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FR_ST2pXwAAqHaX.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1589827000788832257/jCCDWfNu_normal.jpg',
		name: 'Kayla Diaz de Arce',
		handle: 'KaylahRoxOut',
		unixDateMS: 1651701293000,
		platform: 'twitter',
		content: 'Almost 7 weeks postpartum and I finally got my first 💯 in months @eightsleep <br/>Crazy to see how much pregnancy impacts sleep ',
		verified: false,
		url: 'https://twitter.com/KaylahRoxOut/status/1521971651415580672',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FR8hJdzXoAQVQ9M.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1580188498748968961/L6lebFCL_normal.jpg',
		name: 'Louisa Nicola',
		handle: 'louisanicola_',
		unixDateMS: 1651152311000,
		platform: 'twitter',
		content: '@m_franceschetti @eightsleep Woke up this morning feeling refreshed and energised! Such a long sleep, my brain needed it. ',
		verified: true,
		url: 'https://twitter.com/louisanicola_/status/1519669053530517504',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FRby8wPWQAI8Pet.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1590567752879644672/kwD9_k2w_normal.jpg',
		name: 'Nick',
		handle: 'TheRoofMD',
		unixDateMS: 1650983875000,
		platform: 'twitter',
		content: 'Taking recovery with @eightsleep as seriously as training  ',
		verified: true,
		url: 'https://twitter.com/TheRoofMD/status/1518962581557301248',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FRRwar6VIAAbWF6.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1671143758920839169/aZt9mhKT_normal.jpg',
		name: '꧁𝓝𝓲𝓬𝓸𝓵𝓮 𝓜𝓪𝓻𝓲𝓮꧂',
		handle: 'nmbernard',
		unixDateMS: 1650103105000,
		platform: 'twitter',
		content: 'The best sleep score I’ve gotten in a long, long time… <br/><br/>I ❤️ my @eightsleep! ',
		verified: false,
		url: 'https://twitter.com/nmbernard/status/1515268365438406660',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FQdQjASWUAMdjwP.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1328440064632819712/YP6jLQ3a_normal.jpg',
		name: 'Brian Robinson / brobinson.eth',
		handle: 'br524',
		unixDateMS: 1649593934000,
		platform: 'twitter',
		content: '@tyrelkenmore @eightsleep Yes I slept like a champion last night for 8 hours and 1 minute. ',
		verified: false,
		url: 'https://twitter.com/br524/status/1513132750211104769',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FP-6Nz6XEAES6Jc.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1532008723417387011/EpSI3JLj_normal.jpg',
		name: 'Justin Darcy',
		handle: 'justinsdarcy',
		unixDateMS: 1649547970000,
		platform: 'twitter',
		content: '@tyrelkenmore @eightsleep I got Covid a week ago and being able to cool my bed on demand allowed me to sleep so much better',
		verified: false,
		url: 'https://twitter.com/justinsdarcy/status/1512939960063586306',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1234859826158743552/57DkMD7n_normal.jpg',
		name: 'Bob Freier',
		handle: 'bobfreier',
		unixDateMS: 1648822905000,
		platform: 'twitter',
		content: '@whaleorbust @eightsleep Little over 3 months in. I can honestly say it changed my life. My last night 👇 ',
		verified: false,
		url: 'https://twitter.com/bobfreier/status/1509898818581639207',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FPQ8-LXXwAUZ4q_.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1609052417269678082/h24vP8dO_normal.jpg',
		name: 'CannoliKid',
		handle: 'CannolieKid',
		unixDateMS: 1648818238000,
		platform: 'twitter',
		content:
			'@BayAreaChris2 @m_franceschetti @mirmanwar @eightsleep Got mine August 2021 (traveled a lot in Sept) but you can see huge gains I’ve experienced in deep sleep since, from a little over 1hr to close to 2hrs now (@ouraring data) ',
		verified: false,
		url: 'https://twitter.com/CannolieKid/status/1509879241588064256',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FPQrKqoXoAk4gL4.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1616241327712382978/1oeuEXRD_normal.jpg',
		name: 'Jack',
		handle: 'jack_naglieri',
		unixDateMS: 1648235967000,
		platform: 'twitter',
		content: 'Technology should make your life better, and this is a perfect example @eightsleep ',
		verified: true,
		url: 'https://twitter.com/jack_naglieri/status/1507437021945466880',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FOt9-5VVsAMtAvi.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1369064896420315146/V7dM8wSa_normal.jpg',
		name: 'Al Jefferson',
		handle: 'Zannjay',
		unixDateMS: 1648133893000,
		platform: 'twitter',
		content: 'Woah almost 11 hours of uninterrupted sleep @eightsleep ',
		verified: true,
		url: 'https://twitter.com/Zannjay/status/1507008891325804554',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FOn4mJ5VQBAKih0.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1419009800646561792/HNdvSmcN_normal.jpg',
		name: 'Kyriakos Eleftheriou',
		handle: 'kyriakosel',
		unixDateMS: 1648122594000,
		platform: 'twitter',
		content: 'My bed is telling me to avoid late meals 🔥 @eightsleep ',
		verified: true,
		url: 'https://twitter.com/kyriakosel/status/1506961501286027265',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FOnNf5aXoAgvNNA.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1646359367351164929/raamPqao_normal.jpg',
		name: 'Wes Wade',
		handle: 'weswadejr',
		unixDateMS: 1647564852000,
		platform: 'twitter',
		content: 'Two @eightsleep 100s in a row. Am I winning @m_franceschetti? ',
		verified: false,
		url: 'https://twitter.com/weswadejr/status/1504622162463797259',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FOF94duVsCUqxCL.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1616241327712382978/1oeuEXRD_normal.jpg',
		name: 'Jack',
		handle: 'jack_naglieri',
		unixDateMS: 1647559567000,
		platform: 'twitter',
		content: "I'd highly recommend the @eightsleep morning flows - great way to start the day",
		verified: true,
		url: 'https://twitter.com/jack_naglieri/status/1504599995609100304',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1328440064632819712/YP6jLQ3a_normal.jpg',
		name: 'Brian Robinson / brobinson.eth',
		handle: 'br524',
		unixDateMS: 1647096244000,
		platform: 'twitter',
		content: 'Three straight 100s, and now we have to change the clocks.  Gotta keep the streak!<br/>P.s. get yourself $200 off an @eightsleep here:  ',
		verified: false,
		url: 'https://twitter.com/br524/status/1502656675697901570',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FNqCSD5XIAIU-Df.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1600593627033501697/Oip202cn_normal.jpg',
		name: 'Greg Kahn | GK Digital Ventures',
		handle: 'GK_Ventures',
		unixDateMS: 1647021750000,
		platform: 'twitter',
		content: 'First 100% score on @eightsleep <br/><br/>Need all my energy for the weekend ahead @ #SXSW2022! ',
		verified: false,
		url: 'https://twitter.com/GK_Ventures/status/1502344224678846464',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FNlmHA5XMBQOVMN.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1234859826158743552/57DkMD7n_normal.jpg',
		name: 'Bob Freier',
		handle: 'bobfreier',
		unixDateMS: 1647006711000,
		platform: 'twitter',
		content: '@m_franceschetti @eightsleep Ready to work hard today. ',
		verified: false,
		url: 'https://twitter.com/bobfreier/status/1502281146549391368',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FNksvadXMAsAMwX.jpg',
	},
	whyte,
	{
		picture: 'https://pbs.twimg.com/profile_images/1554827587523776514/FPUKHovB_normal.jpg',
		name: 'Matt Blank',
		handle: 'MattyBuilds',
		unixDateMS: 1645715348000,
		platform: 'twitter',
		content: '@jackmclary @eightsleep @whoop It’s hard to emphasize just how impactful temperature control is<br/><br/>The proof is in the data ',
		verified: true,
		url: 'https://twitter.com/MattyBuilds/status/1496864780073668609',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1554827587523776514/FPUKHovB_normal.jpg',
		name: 'Matt Blank',
		handle: 'MattyBuilds',
		unixDateMS: 1645625669000,
		platform: 'twitter',
		content: 'Sleep is one of my biggest health priorities right now, and @eightsleep + @whoop have been game changers for tracking and improving my sleep ',
		verified: true,
		url: 'https://twitter.com/MattyBuilds/status/1496488639798419458',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1600593627033501697/Oip202cn_normal.jpg',
		name: 'Greg Kahn | GK Digital Ventures',
		handle: 'GK_Ventures',
		unixDateMS: 1645621654000,
		platform: 'twitter',
		content: 'Gotta love the weather/temperature readings on @eightsleep mattresses.<br/><br/>The NYC area is balmy today! 🌴 ',
		verified: false,
		url: 'https://twitter.com/GK_Ventures/status/1496471797952397314',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FMSJK1CXwAEOftL.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1616241327712382978/1oeuEXRD_normal.jpg',
		name: 'Jack',
		handle: 'jack_naglieri',
		unixDateMS: 1648235967000,
		platform: 'twitter',
		content: 'Technology should make your life better, and this is a perfect example @eightsleep ',
		verified: true,
		url: 'https://twitter.com/jack_naglieri/status/1507437021945466880',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FOt9-5VVsAMtAvi.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1369064896420315146/V7dM8wSa_normal.jpg',
		name: 'Al Jefferson',
		handle: 'Zannjay',
		unixDateMS: 1648133893000,
		platform: 'twitter',
		content: 'Woah almost 11 hours of uninterrupted sleep @eightsleep ',
		verified: true,
		url: 'https://twitter.com/Zannjay/status/1507008891325804554',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FOn4mJ5VQBAKih0.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1234859826158743552/57DkMD7n_normal.jpg',
		name: 'Bob Freier',
		handle: 'bobfreier',
		unixDateMS: 1647525225000,
		platform: 'twitter',
		content: 'Feeling energized today. Coincidence? I think not. @eightsleep ',
		verified: false,
		url: 'https://twitter.com/bobfreier/status/1504455954439544836',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FODmt1sXoAIKY4z.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1043688085819543553/01t1gUkf_normal.jpg',
		name: 'Ian',
		handle: 'ianmturk',
		unixDateMS: 1646798693000,
		platform: 'twitter',
		content: '@noneis @eightsleep I love it! My sleep quality has improved dramatically since I started using it. Especially when I hit ideal temperatures for different phases.',
		verified: false,
		url: 'https://twitter.com/ianmturk/status/1501408659825254404',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1550457418676441088/PrPZ8kTE_normal.jpg',
		name: 'Ron',
		handle: 'RonSyntax',
		unixDateMS: 1646820413000,
		platform: 'twitter',
		content:
			"I'm traveling to see family but I miss my @eightsleep  Bed. Idk, if I could go back to a regular bed...<br/><br/>Also, after the poor sleep, I've had the last 6 days is catching up to me. Time Zone difference is also killing me",
		verified: false,
		url: 'https://twitter.com/RonSyntax/status/1501499756496965634',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1655762064051142656/n9dY9Phd_normal.jpg',
		name: 'The V₳nilla Godzill₳',
		handle: '5050isaloss',
		unixDateMS: 1646164985000,
		platform: 'twitter',
		content:
			"@Swag_Stakepool @Padierfind @eightsleep @hashoshi4 I can attest to Jeff's opinion on this mattress pad. I have heating issues, this keeps my body from overheating st night. I now stay asleep for much more of the night.",
		verified: true,
		url: 'https://twitter.com/5050isaloss/status/1498750694030983170',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1600176789384470529/Ov473Rmm_normal.jpg',
		name: 'DeeZe 🫡',
		handle: 'DeeZe',
		unixDateMS: 1687175163000,
		platform: 'twitter',
		content:
			"@haroldeytan @eightsleep we've been using it for three years now and love it. I sleep like absolute shit without it now <br/><br/>after a year the first one we had got a leak (guessing maybe our cat punctured one of the water lines) and they replaced it for free no questions asked too",
		verified: true,
		url: 'https://twitter.com/DeeZe/status/1670759847313170433',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1356612192657960968/m8vXg36K_normal.jpg',
		name: 'Stu 🤯',
		handle: 'stuthesmith',
		unixDateMS: 1685369877000,
		platform: 'twitter',
		content: 'When you finally start to recover from body gremlins @eightsleep ',
		verified: false,
		url: 'https://twitter.com/stuthesmith/status/1663187927910760448',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/FxTUmw5WAAAHE3O.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1604639668019949568/IbsoHcJ5_normal.jpg',
		name: 'courtney',
		handle: 'courtney_xyz',
		unixDateMS: 1673213114000,
		platform: 'twitter',
		content:
			"@drewfallon12 May depend on your why.<br/><br/>I was dealing with years of sleep issues (extreme night sweats; like... awful). <br/><br/>It's changed my life. It's probably the best purchase I've made in the last decade.",
		verified: true,
		url: 'https://twitter.com/courtney_xyz/status/1612198770338574337',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1600104896967188482/qLpB2FnO_normal.jpg',
		name: 'Peter Rousseau',
		handle: 'peterrrousseau',
		unixDateMS: 1673236115000,
		platform: 'twitter',
		content: '@drewfallon12 Highly recommend. Got it three weeks ago and my sleep score (per @whoop) has never been as consistently high.',
		verified: true,
		url: 'https://twitter.com/peterrrousseau/status/1612295242392240130',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1652093699042553860/BN49aRIk_normal.jpg',
		name: '97 39 76 3327 00 The Void',
		handle: 'ourokronos',
		unixDateMS: 1673474483000,
		platform: 'twitter',
		content:
			'@PeterDiamandis @eightsleep Mine was a game changer. I’ve had it for over a year and a half now. I’m someone who really relies on the room being pretty cold (preferably below 64F) and it upped my average weekly sleep score by a whopping 18 points (measured via the Oura ring I use to have).',
		verified: true,
		url: 'https://twitter.com/ourokronos/status/1613295029266837505',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/718598294323073024/qu7IlBA8_normal.jpg',
		name: 'Billy Jalbert',
		handle: 'Mauibilly',
		unixDateMS: 1669416269000,
		platform: 'twitter',
		content:
			"@gregisenberg I've tried just about everything over 5 years. The two biggest winners (all tracked) were 3 hour cutoff for food before bed and @eightsleep pod pro cover with a very cold setting. Average about 40% more deep sleep.",
		verified: false,
		url: 'https://twitter.com/Mauibilly/status/1596273648142548993',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/2398344802/bm0r011ulnx6nd6ndtn9_normal.jpeg',
		name: 'Vinod Khosla',
		handle: 'vkhosla',
		unixDateMS: 1688932156000,
		platform: 'twitter',
		content: 'Me too ',
		verified: true,
		url: 'https://twitter.com/vkhosla/status/1678129210861420544',
		categories: ['Familiar faces'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1609897292487856129/iMpRgSEH_normal.jpg',
		name: 'Rachel Neylan OLY',
		handle: 'rachneylan',
		unixDateMS: 1652374433000,
		platform: 'twitter',
		content: 'Time to unbox an exciting new world of insight with @eightsleep! ',
		verified: false,
		url: 'https://twitter.com/rachneylan/status/1524795006422331393',
		categories: ['Familiar faces'],
		image: 'https://pbs.twimg.com/media/FSko-e7XEAEQoUB.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1099515396191936512/W0C3xHBl_normal.jpg',
		name: 'Chelsea Elise',
		handle: 'cheeeelise',
		unixDateMS: 1686028888000,
		platform: 'twitter',
		content: 'Ever since I was pregnant I run really hot at night, but jumping into a warm bed after a shower is 🤤 Thank you @eightsleep',
		verified: false,
		url: 'https://twitter.com/cheeeelise/status/1665952022636683264',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1527588460609028096/mR2Nj06d_normal.jpg',
		name: 'M@ W3TR1CH',
		handle: 'MattWetrich',
		unixDateMS: 1689893008000,
		platform: 'twitter',
		content:
			'@thogge @eightsleep It recognizes sleep patterns + whether you’re falling asleep “on time”. If not, it will adjust temperature accordingly. I’ve had trouble with insomnia my whole life, this has kept me asleep longer. Great data / analytics, and customer service is excellent.',
		verified: false,
		url: 'https://twitter.com/MattWetrich/status/1682159315669352453',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1636640554741288960/rT-TsdX7_normal.jpg',
		name: 'Graham 🛡️',
		handle: 'grahamcodes',
		unixDateMS: 1689900456000,
		platform: 'twitter',
		content:
			'@thogge @eightsleep What I like most about it is that it gives me better sleep in a way that’s objectively measurable. My resting heart rate, HRV, and deep/REM sleep are all better when I sleep at home vs sleeping elsewhere. Continuous temperature management while sleeping really is the key.',
		verified: false,
		url: 'https://twitter.com/grahamcodes/status/1682190553226809345',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/2284843893/p9iorqrb90gcpvxqb2eu_normal.jpeg',
		name: 'Andrew Young',
		handle: 'ndrew_3000',
		unixDateMS: 1689894821000,
		platform: 'twitter',
		content:
			'@thogge @eightsleep Regulates temperature so I sleep better. I don’t wake up hot anymore in the middle of the night.<br/><br/>Data from my Garmin watch shows I’m sleeping better since I got my eight sleep too.',
		verified: false,
		url: 'https://twitter.com/ndrew_3000/status/1682166918088884224',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1668259073664450560/2fucW__T_normal.jpg',
		name: 'CryptoJustin',
		handle: 'TheCryptoCatGuy',
		unixDateMS: 1689821560000,
		platform: 'twitter',
		content:
			'My @eightsleep let me know I was getting sick (COVID positive) before I ever felt it in my body. <br/><br/>It’s not helping me keep my fever in check. <br/><br/>Can’t wait to see their next generation of their smart mattress. ',
		verified: false,
		url: 'https://twitter.com/TheCryptoCatGuy/status/1681859639879643137',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/F1cqbdOWwAAGf1G.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1678815431451381760/c1hXiplk_normal.jpg',
		name: 'Brian Harrington',
		handle: 'BrainHarrington',
		unixDateMS: 1685857084000,
		platform: 'twitter',
		content: 'The number one worst part about having @eightsleep at home is that traveling mattresses are all hot and make you feel stuffy',
		verified: true,
		url: 'https://twitter.com/BrainHarrington/status/1665231424826253314',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1375265268687982592/oLoZ8KQP_normal.jpg',
		name: 'Tate Hackert',
		handle: 'TateHackert',
		unixDateMS: 1685843100000,
		platform: 'twitter',
		content:
			'@simonkalouche @eightsleep Takes a couple weeks to get used to and calibrate.<br/><br/>The true value will be noticed after a couple months when you go to a hotel and then come back home.',
		verified: true,
		url: 'https://twitter.com/TateHackert/status/1665172768772554752',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1208260254242426880/hFjgZDnM_normal.jpg',
		name: 'Simon Kalouche',
		handle: 'simonkalouche',
		unixDateMS: 1685837045000,
		platform: 'twitter',
		content: 'First night using @eightsleep tonight. Very pumped for this.  ',
		verified: true,
		url: 'https://twitter.com/simonkalouche/status/1665147372853411840',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FxvKkjjagAEH5mT.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1607112416730710016/cwQPvTtr_normal.jpg',
		name: 'Timothy Young',
		handle: 'timyoung',
		unixDateMS: 1620410206000,
		platform: 'twitter',
		content: 'Feeling like the future just arrived courtesy of @eightsleep and @m_franceschetti. ',
		verified: true,
		url: 'https://twitter.com/timyoung/status/1390727319275249668',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/E0zbH_wVEAArmGT.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1611021264776491008/7mvnNr0o_normal.jpg',
		name: 'Krista Doebel-Hickok',
		handle: 'KristabelDH',
		unixDateMS: 1685064433000,
		platform: 'twitter',
		content: 'This is cool. <br/><br/>Actually, most of the night it’s hot. <br/><br/>Are you following me? @eightsleep is 👏 👏 ',
		verified: false,
		url: 'https://twitter.com/KristabelDH/status/1661906802374746113',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FxBHbcNaYAAboQr.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1356612192657960968/m8vXg36K_normal.jpg',
		name: 'Stu 🤯',
		handle: 'stuthesmith',
		unixDateMS: 1685060922000,
		platform: 'twitter',
		content:
			'I’m 7 days into a crazy inflammation flare up and it’s wild looking at my @eightsleep graphs and seeing just how much it’s affecting me. <br/><br/>I’ve been dialing in sleep the last year and reliably get to about 95%+ most nights. ',
		verified: false,
		url: 'https://twitter.com/stuthesmith/status/1661892078400425984',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FxA6CWzXgAE0tZL.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1673770161440976898/-iGM61S6_normal.jpg',
		name: 'Fed 🚀',
		handle: 'feulf',
		unixDateMS: 1685047974000,
		platform: 'twitter',
		content: "@0xjpb1 I refuse! We're not in the future until we all sleep on an @eightsleep",
		verified: false,
		url: 'https://twitter.com/feulf/status/1661837771395178502',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1611021264776491008/7mvnNr0o_normal.jpg',
		name: 'Krista Doebel-Hickok',
		handle: 'KristabelDH',
		unixDateMS: 1684758380000,
		platform: 'twitter',
		content:
			'The @eightsleep Pod on my bed and @Hyperice Normatec under it: pretty much my private recovery center. <br/><br/>Also, my recovery center has noted that I am still a tad jet lagged. Just a tad. ',
		verified: false,
		url: 'https://twitter.com/KristabelDH/status/1660623126357737473',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/Fwu37keacAQqk1X.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1668788351946686464/z9CzzgaP_normal.jpg',
		name: 'Matt_M',
		handle: 'MattM3389',
		unixDateMS: 1684687693000,
		platform: 'twitter',
		content: '@TheCoachRaj @eightsleep has made a huge difference in my sleep ',
		verified: false,
		url: 'https://twitter.com/MattM3389/status/1660326641338335235',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FwqqRbhXwAIhq9Z.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1627788153502171137/UFy9WNuV_normal.jpg',
		name: 'Frank',
		handle: 'FrankOftring',
		unixDateMS: 1684457717000,
		platform: 'twitter',
		content: 'Traveling for 3 days vs sleeping with @eightsleep the last 4 nights. <br/><br/>Whoop recovery scores don’t lie ',
		verified: false,
		url: 'https://twitter.com/FrankOftring/status/1659362054652981253',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/Fwc8_draUAAmmZb.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1629159856585179136/rJRAgNvm_normal.jpg',
		name: 'Huxsoma',
		handle: 'huxsoma',
		unixDateMS: 1684334715000,
		platform: 'twitter',
		content: '@m_franceschetti @FitFounder @eightsleep 100%. Doubled my deep sleep.',
		verified: false,
		url: 'https://twitter.com/huxsoma/status/1658846145508962309',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1619095267638132737/_diIHBO6_normal.jpg',
		name: 'Adam Gooch',
		handle: 'Asgooch',
		unixDateMS: 1684329059000,
		platform: 'twitter',
		content: '@ZssBecker The @eightsleep really is a game changer ',
		verified: false,
		url: 'https://twitter.com/Asgooch/status/1658822422361980931',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FwVSM7DXoAIq6en.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1234859826158743552/57DkMD7n_normal.jpg',
		name: 'Bob Freier',
		handle: 'bobfreier',
		unixDateMS: 1684161837000,
		platform: 'twitter',
		content: '@AnitaPerry84764 @eightsleep Same here. I just don’t want them to be known for ruining vacations. We need the pod in every hotel room.',
		verified: false,
		url: 'https://twitter.com/bobfreier/status/1658121041162510340',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1677830793974906881/UcBw5oNy_normal.jpg',
		name: 'Alden Darby',
		handle: 'Pic6Darb',
		unixDateMS: 1683548221000,
		platform: 'twitter',
		content: 'The vibrating bed to wake you up in the mornings is everything @eightsleep ',
		verified: false,
		url: 'https://twitter.com/Pic6Darb/status/1655547349379215366',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FvmvijnWAAUZIfy.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/2479644874/image_normal.jpg',
		name: 'Jeff Berry',
		handle: 'jeffsberry',
		unixDateMS: 1683490994000,
		platform: 'twitter',
		content: '@SecretCFO @eightsleep Terrific product. It’s so good it will be difficult to sleep on non-Eight Sleep mattresses when traveling.',
		verified: false,
		url: 'https://twitter.com/jeffsberry/status/1655307324162994176',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/754477005/DSC_0142_2_normal.jpg',
		name: 'Scott',
		handle: 'slarche',
		unixDateMS: 1683494210000,
		platform: 'twitter',
		content: '@SecretCFO @eightsleep Love mine. Sleep great at home and terribly in hotels now. Used to sleep terribly everywhere.',
		verified: false,
		url: 'https://twitter.com/slarche/status/1655320811048259586',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1528866009364549640/w-_8WqNB_normal.jpg',
		name: 'Joe Pohlen',
		handle: 'joepohlen',
		unixDateMS: 1683493283000,
		platform: 'twitter',
		content: '@SecretCFO @eightsleep I use it. It’s great',
		verified: true,
		url: 'https://twitter.com/joepohlen/status/1655316922332770307',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1589777434991333377/aezyCHOH_normal.jpg',
		name: 'R O ₿ ₿ I E _V 13%',
		handle: 'robb1011',
		unixDateMS: 1683493417000,
		platform: 'twitter',
		content: '@SecretCFO @eightsleep I have it. The pod plus mattress. I really like it. The temp control is worth it alone. But it’s a very nice mattress, too.',
		verified: false,
		url: 'https://twitter.com/robb1011/status/1655317484180844545',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/458801582029938688/oueqfI9C_normal.jpeg',
		name: 'ɹǝʞɹɐd ʍǝɹpuɐ',
		handle: 'andrewparker',
		unixDateMS: 1683409844000,
		platform: 'twitter',
		content:
			"@superamit @maiab @eightsleep If you sleep hot, buy something. Works well for Hawaii summers and more energy efficient than AC. <br/><br/>If money is no object, go 8. When my Ooler stops and starts on its own because the SW is fubar, it's frustrating... But other than that, works great and is much more affordable.",
		verified: false,
		url: 'https://twitter.com/andrewparker/status/1654966956406235137',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1469100992213966850/QAXKRSk7_normal.jpg',
		name: 'Iman Jalali',
		handle: 'Stealx',
		unixDateMS: 1683410041000,
		platform: 'twitter',
		content:
			'Upgrades I’d pay for at a hotel:<br/><br/>- @eightsleep mattress <br/><br/>- Dual monitor setup for a laptop<br/><br/>- Flexible check in &amp; check out times<br/> <br/>- Being able to order @AthleticGreens &amp; @supergut_ from room service <br/><br/>What upgrades would you want?',
		verified: true,
		url: 'https://twitter.com/Stealx/status/1654967780326752258',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1115472724657238016/mvn_gw-E_normal.png',
		name: 'Arif Janmohamed',
		handle: 'arifj',
		unixDateMS: 1683404457000,
		platform: 'twitter',
		content:
			'A friend asked me yesterday about my favorite product from <br/> the past 2 years. It’s easily the @eightsleep bed cover, which has dramatically helped improve my sleep quality. It’s an investment, but absolutely worth it.  Nice work @m_franceschetti and thx @LingWong for rec!',
		verified: true,
		url: 'https://twitter.com/arifj/status/1654944358318346240',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1240848985587216385/Flt6pa4x_normal.jpg',
		name: 'Matt Mireles | OASIS',
		handle: 'mattmireles',
		unixDateMS: 1683397150000,
		platform: 'twitter',
		content: '@maiab @eightsleep Still my best purchase of 2021',
		verified: true,
		url: 'https://twitter.com/mattmireles/status/1654913711596003329',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1324779923630886915/rUcaG0au_normal.jpg',
		name: 'Trae Robrock',
		handle: 'trobrock',
		unixDateMS: 1683370934000,
		platform: 'twitter',
		content: '@thesamparr @eightsleep This was one of my best purchases this year. And coincidentally I got the recommendation through @HamptonFounders',
		verified: true,
		url: 'https://twitter.com/trobrock/status/1654803754057605122',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1677830793974906881/UcBw5oNy_normal.jpg',
		name: 'Alden Darby',
		handle: 'Pic6Darb',
		unixDateMS: 1683374806000,
		platform: 'twitter',
		content: 'Me and my @eightsleep Pod are quite the team 🖤🎱 1st night with the Pod going to keep with the updates to show the improvements of my sleep quality and recovery ',
		verified: false,
		url: 'https://twitter.com/Pic6Darb/status/1654819993060536321',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FvcaAy9XwAAP0OY.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1609897292487856129/iMpRgSEH_normal.jpg',
		name: 'Rachel Neylan OLY',
		handle: 'rachneylan',
		unixDateMS: 1670948133000,
		platform: 'twitter',
		content:
			'Next level tech delivering the future of high-performance health...<br/><br/>Amazing to see what evolves when science, tech and driven people willing to change the status quo all unite 👉🏼 @eightsleep 😴💪🏼 ',
		verified: false,
		url: 'https://twitter.com/rachneylan/status/1602698751403073537',
		categories: ['Familiar faces'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1487654529088720896/xZBKkzdv_normal.jpg',
		name: 'Melanie Balke',
		handle: 'Melanie_Balke',
		unixDateMS: 1683361026000,
		platform: 'twitter',
		content:
			'@thesamparr @eightsleep I got mine because my boyfriend said he can’t sleep in my hell like temperatures. Since mine is set to like 90+ in winter I’ve accepted I’m the problem. At least he sleeps fine now on his side and I can stick a leg over there to cool off when needed 😂 ',
		verified: true,
		url: 'https://twitter.com/Melanie_Balke/status/1654762196164055046',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/Fvblcl2akAI2WpM.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1626139179121012736/zKBTqj-7_normal.jpg',
		name: 'Daniel Peleg | Email Growth Magician',
		handle: 'daniel_peleg',
		unixDateMS: 1683334106000,
		platform: 'twitter',
		content: '@thesamparr @eightsleep I\'ve been thinking about getting one of these. <br/><br/>Apparently, we experience better "deep sleep" when our body temperature is a bit lower.',
		verified: true,
		url: 'https://twitter.com/daniel_peleg/status/1654649288134197248',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1559568954409881600/uB0K30NT_normal.jpg',
		name: 'Dhru Purohit',
		handle: 'dhrupurohit',
		unixDateMS: 1683308536000,
		platform: 'twitter',
		content: 'Love my @eightsleep and feel the same way. I have a hard time sleeping on anything else. ',
		verified: true,
		url: 'https://twitter.com/dhrupurohit/status/1654542036731822080',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/997438041189892097/b2uBk8Dn_normal.jpg',
		name: 'Ammaar Reshi',
		handle: 'ammaar',
		unixDateMS: 1683317442000,
		platform: 'twitter',
		content: '@rrhoover @thesamparr @eightsleep Had the exact same skepticism, felt overhyped, but it’s totally worth it. And I felt like it actually made my bed softer, would recommend.',
		verified: true,
		url: 'https://twitter.com/ammaar/status/1654579394252525573',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1595437961985888256/HpOiU_H3_normal.jpg',
		name: "Sean O'Dowd",
		handle: 'SeanODowd15',
		unixDateMS: 1683305145000,
		platform: 'twitter',
		content: '@thesamparr @eightsleep @eightsleep is the best thing I’ve ever bought, hands down<br/><br/>I love it',
		verified: true,
		url: 'https://twitter.com/SeanODowd15/status/1654527814295486464',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1601002664640274433/985LZbUV_normal.jpg',
		name: 'John Egan',
		handle: 'john_m_egan',
		unixDateMS: 1683312503000,
		platform: 'twitter',
		content: '@thesamparr @eightsleep Once you go Eight Sleep, you never go back. <br/><br/>(4+ years now sleeping on one)',
		verified: true,
		url: 'https://twitter.com/john_m_egan/status/1654558677095817216',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1677156000942415878/1YPKQS6Z_normal.jpg',
		name: 'Luca || Web3 Nomad ✈️',
		handle: '_Web3Nomad',
		unixDateMS: 1683306666000,
		platform: 'twitter',
		content:
			'@thesamparr @eightsleep I think every 8sleep user goes through the same journey.<br/><br/>First few weeks, I was like "meeeeh"<br/><br/>Now when we\'re traveling for 2-3 months, my 8sleep is the main reason why I want to come home 😂',
		verified: true,
		url: 'https://twitter.com/_Web3Nomad/status/1654534194364821505',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1592194144608346112/NrTJ81lQ_normal.jpg',
		name: '𝖱𝖮𝖡 𝖬𝖠𝖣𝖤𝖫𝖬𝖠𝖸𝖤𝖱 🇺🇸',
		handle: 'RMadelmayer',
		unixDateMS: 1683298975000,
		platform: 'twitter',
		content: '@thesamparr @eightsleep i have had one for 2 years and feel this tweet<br/><br/>@Marriott needs to offer these in some of their hotels <br/><br/>i would happily pay a premium for this',
		verified: true,
		url: 'https://twitter.com/RMadelmayer/status/1654501935347650563',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1573080797371924480/12Yom6Qu_normal.png',
		name: 'KippBodnar.eth',
		handle: 'kippbodnar',
		unixDateMS: 1683303718000,
		platform: 'twitter',
		content: '@thesamparr @eightsleep Love mine. It is a game changer',
		verified: false,
		url: 'https://twitter.com/kippbodnar/status/1654521828663074818',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1474048180908175363/b6PCpGwj_normal.jpg',
		name: 'Sam Parr',
		handle: 'thesamparr',
		unixDateMS: 1683298753000,
		platform: 'twitter',
		content:
			'I got an @eightsleep mattress last year. At first, I thought it was dumb. <br/><br/>After like 2 weeks I was like ok, this slightly colder-than-normal mattress is kinda neat.<br/><br/>Now I struggle to sleep away from home. <br/><br/>The colder mattress really makes a difference.',
		verified: true,
		url: 'https://twitter.com/thesamparr/status/1654501005206102021',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1595437961985888256/HpOiU_H3_normal.jpg',
		name: "Sean O'Dowd",
		handle: 'SeanODowd15',
		unixDateMS: 1683305145000,
		platform: 'twitter',
		content: '@thesamparr @eightsleep @eightsleep is the best thing I’ve ever bought, hands down<br/><br/>I love it',
		verified: true,
		url: 'https://twitter.com/SeanODowd15/status/1654527814295486464',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1156766656829386752/okhCftE1_normal.jpg',
		name: 'Emmet Peppers',
		handle: 'EmmetPeppers',
		unixDateMS: 1683001880000,
		platform: 'twitter',
		content:
			'@heyitsyashu Oura Ring and @eightsleep combo along with not eating for at least 3 hours before you go to bed and getting at least a few minutes of sunlight as soon as possible once you do wake up',
		verified: true,
		url: 'https://twitter.com/EmmetPeppers/status/1653255829913767936',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1662177323276222470/-2iz2h7l_normal.jpg',
		name: 'Amanda Goetz',
		handle: 'AmandaMGoetz',
		unixDateMS: 1682941466000,
		platform: 'twitter',
		content: 'Taylor recovery looks like this @eightsleep ',
		verified: true,
		url: 'https://twitter.com/AmandaMGoetz/status/1653002435894427649',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FvCk9DgWwAIkY9n.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1636954384310366209/W7WGOJZW_normal.jpg',
		name: 'AWONG',
		handle: 'awongaw',
		unixDateMS: 1682806651000,
		platform: 'twitter',
		content: 'Let’s see what the hype is about 🤔 @eightsleep ',
		verified: true,
		url: 'https://twitter.com/awongaw/status/1652436979873718273',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/Fu6irIVXsAA9eza.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1592894439479021568/FQsQbXd8_normal.jpg',
		name: 'Luke Fischer',
		handle: 'FischerLukeM',
		unixDateMS: 1682776076000,
		platform: 'twitter',
		content: 'I take great satisfaction going to bed early on a Friday to get results like these. @eightsleep 🖤🎱for the win ',
		verified: true,
		url: 'https://twitter.com/FischerLukeM/status/1652308740454899712',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/Fu4uCjuWAAEGo_j.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1529183190920962049/nUCB6-lF_normal.jpg',
		name: 'Eduardo Calderas',
		handle: 'educalderas',
		unixDateMS: 1682701812000,
		platform: 'twitter',
		content: '@FitFounder @eightsleep without a doubt! @m_franceschetti life changer/saver',
		verified: false,
		url: 'https://twitter.com/educalderas/status/1651997254528512014',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1503079332746514435/yxthcdXR_normal.jpg',
		name: 'Andrew McCalip',
		handle: 'andrewmccalip',
		unixDateMS: 1682571247000,
		platform: 'twitter',
		content: "I sleep so well on the eightsleep that my wife will occasionally check to make sure I'm still alive. <br/><br/>@eightsleep",
		verified: true,
		url: 'https://twitter.com/andrewmccalip/status/1651449625096904704',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1626869353361842176/QjtjYHnQ_normal.jpg',
		name: 'The Crypto Dog🫐',
		handle: 'TheCryptoDog',
		unixDateMS: 1681926856000,
		platform: 'twitter',
		content:
			"Now that I sleep every night in a bed that automatically liquid cools my body and draws my heat away as I'm asleep under my covers, I can't ever imagine going back.<br/><br/>Not sponsored (I wish)<br/><br/>Really cool product @eightsleep ",
		verified: true,
		url: 'https://twitter.com/TheCryptoDog/status/1648746851418652673',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FuGGhITakAEdF3h.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1671178879644364800/2LH2JGIh_normal.jpg',
		name: '₿ullish 🐃⚡️',
		handle: 'ccapitalllc',
		unixDateMS: 1681908108000,
		platform: 'twitter',
		content: 'I’ll take it. Let’s get after it. #eightsleep @eightsleep ',
		verified: false,
		url: 'https://twitter.com/ccapitalllc/status/1648668218427420675',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FuE_Aa2XgAArP8D.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1590747762676715520/V4pRkEBS_normal.jpg',
		name: 'Mike Annunziata',
		handle: 'nunzi46',
		unixDateMS: 1681489146000,
		platform: 'twitter',
		content: '@___sujan @eightsleep My wife and I would be very upset if someone took ours.<br/><br/>Probably the best way to frame an answer.',
		verified: true,
		url: 'https://twitter.com/nunzi46/status/1646910963659022336',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1354906705440747520/zhOGFu5q_normal.jpg',
		name: 'Michael Lorenzos',
		handle: 'michaellorenzos',
		unixDateMS: 1681288860000,
		platform: 'twitter',
		content: 'Good morning @eightsleep ',
		verified: false,
		url: 'https://twitter.com/michaellorenzos/status/1646070905213272070',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FtgEwwOWYAEJjUI.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1608532441307701248/Tlaa94Zv_normal.jpg',
		name: 'Mike Benson',
		handle: 'Unbox_warehouse',
		unixDateMS: 1681251449000,
		platform: 'twitter',
		content:
			'@Chen @eightsleep I travel a lot and even the nicest hotels can’t compare to my Eight Sleep. You know that feeling when you flip you pillow and scoot over to a cool spot in the bed. It’s like that all night long.',
		verified: true,
		url: 'https://twitter.com/Unbox_warehouse/status/1645913989094731778',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1645926483171393536/K6d3qQyi_normal.jpg',
		name: 'epictetus',
		handle: 'tomfoolerycap',
		unixDateMS: 1681254289000,
		platform: 'twitter',
		content:
			'@Chen @eightsleep Getting @eightsleep was one of the best decisions I’ve made. Worth the price. Their customer service team is both incredibly responsive but also receptive to feedback and improvement ideas. Low hassle and requires infrequent refills.',
		verified: false,
		url: 'https://twitter.com/tomfoolerycap/status/1645925903694970880',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1608532441307701248/Tlaa94Zv_normal.jpg',
		name: 'Mike Benson',
		handle: 'Unbox_warehouse',
		unixDateMS: 1681251449000,
		platform: 'twitter',
		content:
			'@Chen @eightsleep I travel a lot and even the nicest hotels can’t compare to my Eight Sleep. You know that feeling when you flip you pillow and scoot over to a cool spot in the bed. It’s like that all night long.',
		verified: true,
		url: 'https://twitter.com/Unbox_warehouse/status/1645913989094731778',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1373694792350203908/_HOPj9IU_normal.jpg',
		name: 'Alex Realmuto',
		handle: 'realmuto',
		unixDateMS: 1681250936000,
		platform: 'twitter',
		content: '@ethankimmel @Chen @eightsleep @leesasleep Also will really make you think twice having that drink. The impact alcohol has on HRV is wild',
		verified: true,
		url: 'https://twitter.com/realmuto/status/1645911838042001408',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1234859826158743552/57DkMD7n_normal.jpg',
		name: 'Bob Freier',
		handle: 'bobfreier',
		unixDateMS: 1681250596000,
		platform: 'twitter',
		content:
			'@Chen @eightsleep Been using it for a year and a half. Probably one of the best purchases I’ve ever made. It has honestly changed mine and my wife’s life. I’d say the firmness of the mattress is a 7 out of 10.',
		verified: false,
		url: 'https://twitter.com/bobfreier/status/1645910411601137664',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1650512809740861442/2Rdhlvwu_normal.jpg',
		name: 'Noah Loffredo',
		handle: 'LoffredoNoah',
		unixDateMS: 1681226186000,
		platform: 'twitter',
		content: 'Back in my @eightsleep again after several months away…<br/><br/>1 week straight of 100% sleep rating and a big uptick in deep sleep😴<br/><br/>Data from Apple Watch and @AutoSleepApp ',
		verified: false,
		url: 'https://twitter.com/LoffredoNoah/status/1645808029487579137',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FtcVrW8aIAIQl8I.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1687521292587585555/qeMNK-hI_normal.jpg',
		name: 'Trace Cohen',
		handle: 'Trace_Cohen',
		unixDateMS: 1681223247000,
		platform: 'twitter',
		content: '@Chen @eightsleep Temp controls are great, improves my sleep no question.',
		verified: true,
		url: 'https://twitter.com/Trace_Cohen/status/1645795703128731650',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1315273281315000321/nv0yfG3W_normal.jpg',
		name: 'Gassquatch',
		handle: 'FartingBigfoot',
		unixDateMS: 1680962257000,
		platform: 'twitter',
		content:
			'@aymanalabdul @m_franceschetti @Freedom_Matt1 @WHOOP @eightsleep I just have the pad because I loved my mattress. Changed my life though. Must have for me, makes traveling and sleeping in hotels miserable though.',
		verified: true,
		url: 'https://twitter.com/FartingBigfoot/status/1644701032881197062',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1580198891919736832/wORQDZgK_normal.jpg',
		name: 'Noah Berkson',
		handle: 'NoahBerkson',
		unixDateMS: 1680800828000,
		platform: 'twitter',
		content: 'Excited to try this. Who else uses @eightsleep ? ',
		verified: true,
		url: 'https://twitter.com/NoahBerkson/status/1644023946835271680',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FtC_ELDaQAEBcOD.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1639753951934402560/7LGno-6v_normal.jpg',
		name: 'Suzanne Griffin',
		handle: 'suzannerenee6',
		unixDateMS: 1680785222000,
		platform: 'twitter',
		content: 'Love to see it @eightsleep ! ',
		verified: false,
		url: 'https://twitter.com/suzannerenee6/status/1643958492217110540',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FtCDiR3XgAE3KD2.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/3401499052/0c16e8ad3d06a1102a8f96f82baed177_normal.jpeg',
		name: 'Ramsey Cox',
		handle: 'RamseyCox22',
		unixDateMS: 1680524253000,
		platform: 'twitter',
		content: '@eightsleep wants me to go to bed hungry. No eating after early afternoon steam room sessions ',
		verified: false,
		url: 'https://twitter.com/RamseyCox22/status/1642863907126775809',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FsygBAaacAEpsSo.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1310340856205828096/Ghb53Plx_normal.jpg',
		name: 'Ryan Delk',
		handle: 'delk',
		unixDateMS: 1680489442000,
		platform: 'twitter',
		content:
			'It’s cliché to even say at this point, but these sorts of things only happen because of the herculean effort of many folks, so here goes:<br/><br/>@EightSleep is the closest I’ve seen a startup come to an Apple-level hardware experience.<br/><br/>Upgrading to the new Pod and it feels like magic. ',
		verified: true,
		url: 'https://twitter.com/delk/status/1642717902796394496',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FswbOVRakAAfBgZ.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1679150934222413825/pcOefUzm_normal.jpg',
		name: 'Bruno Brasil Faviero | Co-Founder @ Magna',
		handle: 'Bfaviero',
		unixDateMS: 1680472557000,
		platform: 'twitter',
		content:
			'@m_franceschetti @web4O @eightsleep It was honestly incredible. Have tried competitors. I highly recommend if you have trouble sleeping hot. <br/><br/>It’s just so cold for most of the year I just left my windows open in NYC during winter. <br/><br/>In the summer it was a lifesaver… I’m just on a founder’s budget 😅😅',
		verified: true,
		url: 'https://twitter.com/Bfaviero/status/1642647081872420864',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1316110067117092864/SW8SYkam_normal.jpg',
		name: 'Ketsuki @ Wowhead',
		handle: 'Ketsuki_WoW',
		unixDateMS: 1680380061000,
		platform: 'twitter',
		content:
			'I made an investment in my sleep and I am happy to say it has paid off. <br/><br/>I got a @eightsleep and after a few weeks I am throughly happy with my purchase, I am sleeping much better and being able to monitor my sleep is def a plus. <br/><br/>The true test will be the Summer heat though.',
		verified: false,
		url: 'https://twitter.com/Ketsuki_WoW/status/1642259124451950592',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1595717985666211840/FSGSWT_0_normal.jpg',
		name: 'Hammad Mohyuddin',
		handle: 'Hammad_Saquib',
		unixDateMS: 1680353589000,
		platform: 'twitter',
		content: '@BillyM2k Use @eightsleep',
		verified: false,
		url: 'https://twitter.com/Hammad_Saquib/status/1642148093431783431',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1527281319784349696/5mF2SuMG_normal.jpg',
		name: 'Cody White',
		handle: 'cewh1te',
		unixDateMS: 1680317511000,
		platform: 'twitter',
		content: '@flipwhisperer @m_franceschetti @eightsleep can vouch for eightsleep, has been a game changer for sleep quality',
		verified: false,
		url: 'https://twitter.com/cewh1te/status/1641996768932921344',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1132354427732389888/y6ddJfKh_normal.jpg',
		name: 'Jaximus',
		handle: 'OnlyJaximus',
		unixDateMS: 1680304795000,
		platform: 'twitter',
		content: '@snoopeh @eightsleep @OfficialSleepme @BedJetSleep Eight sleep is legit - I was pretty skeptical but it has been awesome so far',
		verified: false,
		url: 'https://twitter.com/OnlyJaximus/status/1641943435153141760',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/679306559361409024/QfMQlDNZ_normal.jpg',
		name: 'Chris A James',
		handle: 'chrisalwinjames',
		unixDateMS: 1705355053000,
		platform: 'twitter',
		content:
			"I wasn't sure how much of a difference the @eightsleep system would make for me... but it's been night and day. I fall asleep much quicker and stay asleep through the night, getting about 2 hours more sleep for the same amount of time in bed. Thank you for a great product. ",
		verified: false,
		url: 'https://twitter.com/chrisalwinjames/status/1747011830705521027',
		categories: ['Everyday love', 'Sleep improvement'],
		image: 'https://pbs.twimg.com/media/GD6f27BaIAAxA6G.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1610135606704603140/t2rQjDVU_normal.jpg',
		name: 'Alex Gonzalez',
		handle: 'AlexGLogics',
		unixDateMS: 1680280283000,
		platform: 'twitter',
		content: '@snoopeh @eightsleep Eight Sleep is one of the best purchases I have ever made.',
		verified: true,
		url: 'https://twitter.com/AlexGLogics/status/1641840622829961223',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/665235330929328128/vLTYQmjV_normal.jpg',
		name: 'Paul Haskell',
		handle: 'phaskell',
		unixDateMS: 1679715674000,
		platform: 'twitter',
		content: '@FitFounder Getting an @eightsleep mattress and taking magnesium 90 min before bed.',
		verified: false,
		url: 'https://twitter.com/phaskell/status/1639472483794395137',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1684131247423905795/z9_0sD9o_normal.jpg',
		name: 'Gordon Swaby',
		handle: 'Gordonswaby',
		unixDateMS: 1679579314000,
		platform: 'twitter',
		content: '@Mekiedoll Yup! I was shocked lol. I RARELY ever get so much deep sleep. It’s hardware + an app. @eightsleep',
		verified: true,
		url: 'https://twitter.com/Gordonswaby/status/1638900545992704000',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1119870834238058498/ML-_vzR4_normal.png',
		name: 'Pat Shams',
		handle: 'shamrock8905',
		unixDateMS: 1679545653000,
		platform: 'twitter',
		content: '@MaxxChewning Whatever you want…then get a @eightsleep. LOVE it!!!!',
		verified: false,
		url: 'https://twitter.com/shamrock8905/status/1638759362339700736',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1610296338293608448/e_8iXVrB_normal.jpg',
		name: 'Andrew Lolk',
		handle: 'AndrewLolk',
		unixDateMS: 1679418704000,
		platform: 'twitter',
		content:
			"@PauloWaine @bryan_johnson @eightsleep You're welcome. <br/><br/>Just wait until the \"warm\" summer months come. That's where it really shines. <br/><br/>I go to Denmark 3-4 times a year, and when it's summer time I miss the EightSleep terribly.",
		verified: false,
		url: 'https://twitter.com/AndrewLolk/status/1638226898735206400',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1625182849790877696/dA4_ZDDS_normal.jpg',
		name: 'Kelly',
		handle: 'kellykiewel',
		unixDateMS: 1679357899000,
		platform: 'twitter',
		content: 'Did I win @eightsleep ? ',
		verified: true,
		url: 'https://twitter.com/kellykiewel/status/1637971867775410176',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/Frs-u7YXgAE0JyG.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1652503338666139649/6SxA53jf_normal.jpg',
		name: 'Blake Holman',
		handle: 'blakeholmanfit',
		unixDateMS: 1679235756000,
		platform: 'twitter',
		content: 'Ready for the day @eightsleep 🦾⚡️ ',
		verified: true,
		url: 'https://twitter.com/blakeholmanfit/status/1637459559718481921',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FrlsyqgXwAIdMfL.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1234859826158743552/57DkMD7n_normal.jpg',
		name: 'Bob Freier',
		handle: 'bobfreier',
		unixDateMS: 1679180872000,
		platform: 'twitter',
		content: 'Me and my @eightsleep Pod are quite the team 🖤🎱 2 days in a row! ',
		verified: false,
		url: 'https://twitter.com/bobfreier/status/1637229359953068034',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FribbLNWIAAq8VW.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1610296338293608448/e_8iXVrB_normal.jpg',
		name: 'Andrew Lolk',
		handle: 'AndrewLolk',
		unixDateMS: 1679062890000,
		platform: 'twitter',
		content:
			"@PauloWaine @bryan_johnson @eightsleep You'll love it! <br/><br/>A few tips:<br/><br/>1) Set it up high to fall asleep, and then lower it throughout the night. <br/><br/>2) Still use AC or windows to cool down the room.<br/><br/>3) Set to 0 or +1 for waking up during the winter. It's wonderful!",
		verified: false,
		url: 'https://twitter.com/AndrewLolk/status/1636734507838750722',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1566531173127438341/mJXCwnWv_normal.jpg',
		name: 'Paul Waine',
		handle: 'PauloWaine',
		unixDateMS: 1679062373000,
		platform: 'twitter',
		content: '@bryan_johnson @eightsleep Update.. never looked forward to going to sleep so much! ',
		verified: false,
		url: 'https://twitter.com/PauloWaine/status/1636732340310274050',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FrbXYvBXgAAF82a.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1532549695943450624/SzQeing3_normal.jpg',
		name: 'Austin Bell ⁉️',
		handle: 'eureka_coach',
		unixDateMS: 1679062483000,
		platform: 'twitter',
		content: '@eightsleep the heat is sweet! 💯 ',
		verified: true,
		url: 'https://twitter.com/eureka_coach/status/1636732800777764865',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FrbXzK2XsAMveWw.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1220948970144485377/MzWsMxiW_normal.jpg',
		name: 'David Liu',
		handle: 'thedak',
		unixDateMS: 1678638287000,
		platform: 'twitter',
		content: "@DJMaculate For sure! I'm also a big fan of @eightsleep which was an investment but it's been keeping me in good shape every day since 2021.",
		verified: false,
		url: 'https://twitter.com/thedak/status/1634953593030856705',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1503557428662743042/fnjalYKH_normal.jpg',
		name: 'That heavy bottomed man?',
		handle: 'doctuh_p',
		unixDateMS: 1678638339000,
		platform: 'twitter',
		content: 'Because I’m a hot sleeper I decided to get the @eightsleep pod cover. When I tell y’all I’m probably never leaving my bed now. Highly recommend. ',
		verified: false,
		url: 'https://twitter.com/doctuh_p/status/1634953810669080576',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FrCF0yGWwAAerso.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1256769775323226113/_7jhh0cO_normal.jpg',
		name: 'Jim Murphy',
		handle: 'jimmurphy',
		unixDateMS: 1678564735000,
		platform: 'twitter',
		content: '@proetrie @ouraring Or get the @eightsleep and have it adjust temps for you to actually sleep better.',
		verified: false,
		url: 'https://twitter.com/jimmurphy/status/1634645093205307392',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1676208012967411713/7oIEVITQ_normal.jpg',
		name: 'Greg Campion | Intentional Wisdom Podcast',
		handle: 'gregorycampion',
		unixDateMS: 1678446001000,
		platform: 'twitter',
		content:
			"Current sleep routine:<br/><br/>• No alcohol<br/><br/>• Wake up by 6:30am 7 days/week<br/><br/>• Workout hard so I'm physically exhausted<br/><br/>• Greek yogurt + 1/2 banana 1-hour before bed<br/><br/>• @eightsleep for temperature control (I like it cold)<br/><br/>What I've cut recently:<br/><br/>• Magnesium<br/>• Weighted blanket",
		verified: true,
		url: 'https://twitter.com/gregorycampion/status/1634147087938998276',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1623368640681676801/2-mswfvO_normal.jpg',
		name: 'Jana',
		handle: 'TechieTex',
		unixDateMS: 1678327249000,
		platform: 'twitter',
		content: '@eightsleep Love this! I can attest that the pod cover is absolutely an essential tool for regulating night time hot flashes.',
		verified: false,
		url: 'https://twitter.com/TechieTex/status/1633649007065956355',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1566894252684091395/rXWMcJ5q_normal.jpg',
		name: 'ericosiu',
		handle: 'ericosiu',
		unixDateMS: 1677948060000,
		platform: 'twitter',
		content: 'Combining @eightsleep with @timeshifterapp minimizes my jetlag and maximizes my sleep. <br/><br/>This is after a trip back from Dubai to LA and I feel like a million bucks. ',
		verified: true,
		url: 'https://twitter.com/ericosiu/status/1632058573911101441',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FqY8nxLagAA8AqE.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1578163806189912064/R-vsHNLH_normal.jpg',
		name: 'Dr. Alex Lawrence',
		handle: 'Evautoalex',
		unixDateMS: 1677876949000,
		platform: 'twitter',
		content:
			'@GJMcClintock @cam_utah @eightsleep absolutely. I am truly bugged when i’m not sleeping on it. game changer for me. changed my sleep quality immensely. last night it was really needed. I was so so hot. the super cold mode allowed me to sleep otherwise I would’ve been miserable. temp impacts my sleep big time.',
		verified: false,
		url: 'https://twitter.com/Evautoalex/status/1631760310792241152',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1578163806189912064/R-vsHNLH_normal.jpg',
		name: 'Dr. Alex Lawrence',
		handle: 'Evautoalex',
		unixDateMS: 1677874389000,
		platform: 'twitter',
		content: '@cam_utah any mattress you like but add @eightsleep onto it and change your life. not kidding.',
		verified: false,
		url: 'https://twitter.com/Evautoalex/status/1631749573143334912',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1553140640233422853/9PXyW2FE_normal.png',
		name: 'HeatMan',
		handle: 'HeatMan1010',
		unixDateMS: 1677857245000,
		platform: 'twitter',
		content: "@RealDoctorMike @MedCrisis In all fairness, I've had the @eightsleep for years and it's awesome. I don't even use the health tracking features - just want that cold bed at night!",
		verified: false,
		url: 'https://twitter.com/HeatMan1010/status/1631677665009627136',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1600026509762187264/zgyPnkXG_normal.jpg',
		name: 'jacob peters',
		handle: 'J__Cub',
		unixDateMS: 1677609963000,
		platform: 'twitter',
		content:
			'my latest sleep stack:<br/>  <br/>- @eightsleep for 68 degree bed <br/>- @pillowcube pillow for neck support<br/>- @ouraring for sleep tracking<br/>- @PureEncaps magnesium glycinate <br/>- @_SportsResearch L-theanine<br/>- @jarrowformulas phosphatidylserine<br/>- @MantaSleep blackout mask<br/>- @coyuchi organic… ',
		verified: true,
		url: 'https://twitter.com/J__Cub/status/1630640490109599744',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1689978823662788608/Oib2_93N_normal.jpg',
		name: 'Terry Dell',
		handle: 'terrydell',
		unixDateMS: 1677807196000,
		platform: 'twitter',
		content: '@jdklein33 @housleyd I have a similar routine but just bought @eightsleep Pod 3 cover and it’s spectacular!',
		verified: false,
		url: 'https://twitter.com/terrydell/status/1631467747443101696',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1644073785790722049/OCKeAmEW_normal.jpg',
		name: 'David Salazar',
		handle: 'davidj_salazar',
		unixDateMS: 1677794494000,
		platform: 'twitter',
		content: 'I also got to cover @eightsleep, whose smart mattress cover has become a must-have among wellness-minded entrepreneurs. ',
		verified: false,
		url: 'https://twitter.com/davidj_salazar/status/1631414471540236288',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1268341972135301121/bKb7QX3x_normal.jpg',
		name: 'The1adydeath',
		handle: 'The1adydeath',
		unixDateMS: 1677755361000,
		platform: 'twitter',
		content: "@LauraTryUK @hubermanlab @eightsleep Also you'll notice your electric bill will be lower in the summer if you use AC as you won't need to depend on it as much at night.",
		verified: false,
		url: 'https://twitter.com/The1adydeath/status/1631250332473860099',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1678815431451381760/c1hXiplk_normal.jpg',
		name: 'Brian Harrington',
		handle: 'BrainHarrington',
		unixDateMS: 1677753932000,
		platform: 'twitter',
		content:
			'Under talked about feature of @eightsleep <br/><br/>You end up needing to run your heat less and run your AC less because you can use the mattress itself to keep you warm or cool while you sleep<br/><br/>This contributes to more solar surplus available for #Bitcoin mining',
		verified: true,
		url: 'https://twitter.com/BrainHarrington/status/1631244339228844033',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/854315173993205760/8xISVqUy_normal.jpg',
		name: 'Tim Greive, CFA, CAIA💰🍷🍔🥂🎾🏈🎣👨‍👩‍👧🍺🍕⛷️',
		handle: 'greive',
		unixDateMS: 1677702011000,
		platform: 'twitter',
		content: '@pmarca The @eightsleep HRV measure falls and dings my sleep score every time i have one drink (or several)',
		verified: false,
		url: 'https://twitter.com/greive/status/1631026566103805952',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1676208012967411713/7oIEVITQ_normal.jpg',
		name: 'Greg Campion | Intentional Wisdom Podcast',
		handle: 'gregorycampion',
		unixDateMS: 1677684912000,
		platform: 'twitter',
		content:
			"@codywanner Those stats are impressive! I use @eightsleep and I really like it.  I run hot so having a cooled bed is huge for sleep for me. I'm relying more on Whoop for my actual sleep stats but may evolve that to the eight sleep stats over time... we'll see.",
		verified: true,
		url: 'https://twitter.com/gregorycampion/status/1630954851256479746',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/904357890928111617/BAETaeF8_normal.jpg',
		name: 'Laura Try',
		handle: 'LauraTryUK',
		unixDateMS: 1677683776000,
		platform: 'twitter',
		content: 'Here we go!<br/>@hubermanlab recommends it, so let’s see what @eightsleep is all about 🛌 😴 💤 ',
		verified: true,
		url: 'https://twitter.com/LauraTryUK/status/1630950083553382408',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FqJMPowWcAAjCN6.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1627788153502171137/UFy9WNuV_normal.jpg',
		name: 'Frank',
		handle: 'FrankOftring',
		unixDateMS: 1684457717000,
		platform: 'twitter',
		content: 'Traveling for 3 days vs sleeping with @eightsleep the last 4 nights. <br/><br/>Whoop recovery scores don’t lie ',
		verified: false,
		url: 'https://twitter.com/FrankOftring/status/1659362054652981253',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/Fwc8_draUAAmmZb.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1363316601974644736/JKdIckKh_normal.jpg',
		name: 'Matthew Dellavedova',
		handle: 'matthewdelly',
		unixDateMS: 1671256184000,
		platform: 'twitter',
		content:
			'Mission Sleep Fitness 2022 was a success. On my \n\t\t\t@eightsleep\n\t\t\t Pod, I got the best recovery each night🧑‍🚀🪐🚀\n\t\t\t<br/><br/>\n\t\t\tThank you \n\t\t\t@m_franceschetti\n\t\t\t + \n\t\t\t@a_zatarain\n\t\t\t  💤👍\n\t\t\t<br/><br/>\n\t\t\t#YearInSleep',
		verified: false,
		url: 'https://twitter.com/matthewdelly/status/1603990811607277568?s=20',
		categories: ['Familiar faces'],
		image: 'https://pbs.twimg.com/media/FkKFIF3XwAAfA5n?format=jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1608532441307701248/Tlaa94Zv_normal.jpg',
		name: 'Mike Benson',
		handle: 'Unbox_warehouse',
		unixDateMS: 1681251449000,
		platform: 'twitter',
		content:
			'@Chen @eightsleep I travel a lot and even the nicest hotels can’t compare to my Eight Sleep. You know that feeling when you flip you pillow and scoot over to a cool spot in the bed. It’s like that all night long.',
		verified: true,
		url: 'https://twitter.com/Unbox_warehouse/status/1645913989094731778',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1668259073664450560/2fucW__T_normal.jpg',
		name: 'CryptoJustin',
		handle: 'TheCryptoCatGuy',
		unixDateMS: 1677597466000,
		platform: 'twitter',
		content:
			'@mikegoldmanlive @elonmusk @eightsleep Tell me you have zero idea what @eightsleep is without telling me. <br/><br/>It’s not a “blankee”. This bed had so much tech in it it’s insane. Monitors my heart rate, breathing, etc. My pod tells me about 2-3 days before I feel sick that I’m actually getting sick based on metrics.',
		verified: false,
		url: 'https://twitter.com/TheCryptoCatGuy/status/1630588073334407168',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1594906916832309248/50pWzqVN_normal.jpg',
		name: 'Chase Larabee',
		handle: 'ChaseLarabee',
		unixDateMS: 1673198147000,
		platform: 'twitter',
		content: '@drewfallon12 Do it and don’t look back. Buying the pod 3 was easily my favorite purchase of 2022. Game changer absolutely!',
		verified: false,
		url: 'https://twitter.com/ChaseLarabee/status/1612135994526175233',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1332134245800751104/6xARs5TS_normal.jpg',
		name: 'Greg Frontiero',
		handle: 'SfwGreg',
		unixDateMS: 1673203242000,
		platform: 'twitter',
		content: '@drewfallon12 The greatest purchase I have ever made alongside lasik.',
		verified: false,
		url: 'https://twitter.com/SfwGreg/status/1612157365268135936',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1675122423044141056/wgjEnouL_normal.jpg',
		name: 'Adam Epstein',
		handle: 'aepstein_',
		unixDateMS: 1673207411000,
		platform: 'twitter',
		content: '@drewfallon12 10/10 NPS -- complete gamechanger',
		verified: true,
		url: 'https://twitter.com/aepstein_/status/1612174848813326338',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1356409687856603141/6pL7Id9p_normal.jpg',
		name: 'Dr. Market Logic',
		handle: 'drmarketlogic',
		unixDateMS: 1673237210000,
		platform: 'twitter',
		content: '@drewfallon12 Changed my life. Highly recommend @eightsleep.',
		verified: false,
		url: 'https://twitter.com/drmarketlogic/status/1612299833351770112',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1595437961985888256/HpOiU_H3_normal.jpg',
		name: "Sean O'Dowd",
		handle: 'SeanODowd15',
		unixDateMS: 1673214282000,
		platform: 'twitter',
		content: '@drewfallon12 I absolutely love ours. Best purchase I’ve ever had',
		verified: true,
		url: 'https://twitter.com/SeanODowd15/status/1612203669314846721',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1510252599622127622/V-KaK_0__normal.jpg',
		name: 'Jess Chasin',
		handle: 'jesschasin',
		unixDateMS: 1673199540000,
		platform: 'twitter',
		content: '@drewfallon12 Best move… i miss it whenever I’m away!',
		verified: true,
		url: 'https://twitter.com/jesschasin/status/1612141834368598017',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1601715015278551040/mWEsf0bM_normal.jpg',
		name: 'Kevan Sadigh',
		handle: 'KevanREX',
		unixDateMS: 1673225602000,
		platform: 'twitter',
		content: '@drewfallon12 If sleeping with the AC on irritates you it’s the best thing you can get',
		verified: true,
		url: 'https://twitter.com/KevanREX/status/1612251147510730754',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1586046189111123969/diei-uBo_normal.jpg',
		name: 'Ben Taft',
		handle: 'benmtaft',
		unixDateMS: 1673280501000,
		platform: 'twitter',
		content: '@drewfallon12 They’re reallllll good.',
		verified: true,
		url: 'https://twitter.com/benmtaft/status/1612481410862022656',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1631491255078117376/U82Dn7ZE_normal.jpg',
		name: 'Ben Schoeggl',
		handle: 'ben_schoeggl',
		unixDateMS: 1673228394000,
		platform: 'twitter',
		content: '@drewfallon12 One of the only health tech products where I noticed a significant and immediate difference',
		verified: false,
		url: 'https://twitter.com/ben_schoeggl/status/1612262857978036224',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1503079332746514435/yxthcdXR_normal.jpg',
		name: 'Andrew McCalip',
		handle: 'andrewmccalip',
		unixDateMS: 1673338004000,
		platform: 'twitter',
		content:
			"@drewfallon12 It's the one of the only tech purchases you'll make that actually improves the physical state of your body. I wish I could as easily improve the other 16hr a day. Do it, it's magical.",
		verified: true,
		url: 'https://twitter.com/andrewmccalip/status/1612722594788302848',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1613419409212833792/4vU_7alA_normal.jpg',
		name: 'Lukas Tanasiuk',
		handle: 'igobylukas',
		unixDateMS: 1672788238000,
		platform: 'twitter',
		content:
			'Investments that will supercharge you as a human, guaranteed. <br/><br/>Part 1: Pod 3 by @eightsleep <br/><br/>What is it? <br/><br/>Pod 3 is a water-cooled insert that slides over top of your bed and keeps your body at the perfect temperature all night. <br/><br/>But that’s just the start<br/><br/>👇 ',
		verified: true,
		url: 'https://twitter.com/igobylukas/status/1610416709281906688',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FllZdfKaEAIQFqz.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1613200477205798914/rKE9_pCP_normal.jpg',
		name: 'Brad Smith',
		handle: 'BradSmithBramar',
		unixDateMS: 1673232213000,
		platform: 'twitter',
		content:
			'@drewfallon12 Completely worth it...literally best purchase ever. Wife has health condition that neccitates it basically be a (for me) sauna in the house. Sleep hugely improved now that I spend every night on the coldest setting.',
		verified: true,
		url: 'https://twitter.com/BradSmithBramar/status/1612278876482281475',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1463562918696521734/abFehepO_normal.jpg',
		name: 'Just In',
		handle: 'just_in_are_not',
		unixDateMS: 1673268111000,
		platform: 'twitter',
		content:
			'@drewfallon12 I wanted one for probably 2 years before I bought one and my wife said I was an idiot for spending that much money on a mattress cover. After having it for a month she said it was the best thing I’ve ever spent $ on. We still feel the same after 8 months',
		verified: true,
		url: 'https://twitter.com/just_in_are_not/status/1612429445628837888',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1620861826563981312/W2oNlbCD_normal.jpg',
		name: 'Kelsey Raymond (she/her)',
		handle: 'Kelsey_M_Meyer',
		unixDateMS: 1673225811000,
		platform: 'twitter',
		content:
			'@drewfallon12 We’ve had one for a year and I will never own a diff mattress. Being able to heat up my side of the bed while husband’s side is cooled off is incredible. I’d buy again for that feature alone.',
		verified: false,
		url: 'https://twitter.com/Kelsey_M_Meyer/status/1612252024770265088',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1580698079883395073/baVGjrv3_normal.jpg',
		name: 'Patrick Comer',
		handle: 'comerpatrick',
		unixDateMS: 1673474763000,
		platform: 'twitter',
		content: "@PeterDiamandis @eightsleep @eightsleep pod for life! I even named mine 'Sleeping Beauty' because it's that good",
		verified: true,
		url: 'https://twitter.com/comerpatrick/status/1613296204120748032',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1635851459009937410/qp5yJO7i_normal.jpg',
		name: 'Justin Connor',
		handle: 'justinmconnor',
		unixDateMS: 1673478620000,
		platform: 'twitter',
		content: '@PeterDiamandis @eightsleep Love my pod 10/10',
		verified: false,
		url: 'https://twitter.com/justinmconnor/status/1613312384193515521',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1479068647419170821/Y5RTv2a9_normal.jpg',
		name: 'Fareed Adib',
		handle: 'FareedAdib',
		unixDateMS: 1673484398000,
		platform: 'twitter',
		content:
			'@PeterDiamandis @eightsleep Peter - you will love the analytics and data that @m_franceschetti and his team have built into the technology. The sleep data is vast wealth of riches for what could help diagnosis ongoing health issues. My wife and can’t live without it.',
		verified: false,
		url: 'https://twitter.com/FareedAdib/status/1613336617791651840',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1172592811885002752/V01ol79g_normal.jpg',
		name: 'Trevor Whyte',
		handle: 'trevorwhyte7',
		unixDateMS: 1666490250000,
		platform: 'twitter',
		content:
			'@pausedME I worked in ICU care for many years and became very familiar with tech that wearables use. <br/><br/>they all have various pros and cons with regards to sensors and algorithms.<br/><br/>the only one that actually makes massive a difference is the @eightsleep',
		verified: false,
		url: 'https://twitter.com/trevorwhyte7/status/1584001033776541697',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1564321826427359232/kA-JREMa_normal.jpg',
		name: 'jef.wa',
		handle: 'jef_wa',
		unixDateMS: 1664118076000,
		platform: 'twitter',
		content:
			'The way my @eightsleep has changed my life. I would get between 5-20 minutes max of “deep sleep” a night. Since night one of my eight sleep I’ve gotten almost 2 hours every night. I finally feel refreshed. Maybe I can get my life together now',
		verified: false,
		url: 'https://twitter.com/jef_wa/status/1574051416099930112',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1356612192657960968/m8vXg36K_normal.jpg',
		name: 'Stu 🤯',
		handle: 'stuthesmith',
		unixDateMS: 1663767858000,
		platform: 'twitter',
		content: '@ProductHunt @eightsleep has had the biggest impact on my daily life by far',
		verified: false,
		url: 'https://twitter.com/stuthesmith/status/1572582495912357893',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1607404671349424134/rOAC7eu6_normal.jpg',
		name: 'Robin',
		handle: 'codeOfRobin',
		unixDateMS: 1663633350000,
		platform: 'twitter',
		content: 'Getting an @eightsleep has genuinely been life changing for me. I haven’t slept like this ever my whole life ',
		verified: false,
		url: 'https://twitter.com/codeOfRobin/status/1572018328348024834',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1588244325791502336/FtdmRs13_normal.jpg',
		name: 'Derek Pankaew',
		handle: 'DPankaew',
		unixDateMS: 1667847605000,
		platform: 'twitter',
		content:
			"4/ Eight Sleep. Controlling and scheduling my bed's temperature increased my sleep significantly. I used to have a bad night's sleep 20% of the time. Now it's close to 0%.<br/><br/>@eightsleep",
		verified: false,
		url: 'https://twitter.com/DPankaew/status/1589694195169579008',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1271264092729335808/og1Gc6ot_normal.jpg',
		name: 'Dr. Sohaib Imtiaz, MD MPH',
		handle: 'digitald0ctor',
		unixDateMS: 1663601863000,
		platform: 'twitter',
		content: 'My wife likes a warmer temperature to sleep in while I like my cooler. @eightsleep allows us to have both 😴',
		verified: true,
		url: 'https://twitter.com/digitald0ctor/status/1571886258946805766',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/2340648815/qkv0rbqx9u7omq0tnxz7_normal.jpeg',
		name: 'David Ma',
		handle: 'dma315',
		unixDateMS: 1662432686000,
		platform: 'twitter',
		content: 'With the heat wave in SF and no AC, took a cold shower then cranked the @eightsleep to like -5 and laid in bed all day. Glorious invention.',
		verified: false,
		url: 'https://twitter.com/dma315/status/1566982375573438464',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1492277290574569477/nUEtpgiD_normal.jpg',
		name: '"Leigh Marie" Braswell',
		handle: 'LM_Braswell',
		unixDateMS: 1666196391000,
		platform: 'twitter',
		content: 'Basically 100% for converting ppl to @eightsleep when they are moving. Has been a game changer for my sleep 🙏',
		verified: true,
		url: 'https://twitter.com/LM_Braswell/status/1582768498396102660',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1556357390814965761/dp_g5jZl_normal.jpg',
		name: 'Sonia Baschez',
		handle: 'SoniaBaschez',
		unixDateMS: 1667542565000,
		platform: 'twitter',
		content: "it's officially cold in LA—made the transition from cold to warm on my @eightsleep 🥶 ",
		verified: false,
		url: 'https://twitter.com/SoniaBaschez/status/1588414762546651136',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FgsuzuXVIAE1f2G.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1513278732420755457/K-J-RVT-_normal.jpg',
		name: 'Michelle Bakels',
		handle: 'MichelleBakels',
		unixDateMS: 1663241689000,
		platform: 'twitter',
		content: 'My first perfect 100% and I can feel it🥲 a month ago there’s no way you could’ve convinced me I’d ever sleep this well 🖤 @eightsleep ',
		verified: false,
		url: 'https://twitter.com/MichelleBakels/status/1570375580188647425',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FcsYREuWIAIoelA.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1580188498748968961/L6lebFCL_normal.jpg',
		name: 'Louisa Nicola',
		handle: 'louisanicola_',
		unixDateMS: 1667749309000,
		platform: 'twitter',
		content: 'First night in my bed with my @eightsleep after 2 weeks of being away 😂 ',
		verified: true,
		url: 'https://twitter.com/louisanicola_/status/1589281911037644800',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/Fg5DeUaXgAEgKm3.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1356450053062201345/TxQN3Xy6_normal.jpg',
		name: 'Ian',
		handle: 'IanMcMilan',
		unixDateMS: 1662047812000,
		platform: 'twitter',
		content:
			'@andyellwood 1 - Incredible night sleep on the @eightsleep <br/>2 - Morning sweat<br/>3 - Play ACDC back in black as loud as possible<br/>4 - A quick cry under the desk in the fetal position<br/>5 - GAME TIME BABY',
		verified: true,
		url: 'https://twitter.com/IanMcMilan/status/1565368100094361600',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/898971025551101953/GoXmOnA2_normal.jpg',
		name: 'Joe Marchese',
		handle: 'joemarchese',
		unixDateMS: 1662007593000,
		platform: 'twitter',
		content:
			"@LaurenBerson @eightsleep is a life changer for me (I run hot) and Christie likes it too. Also, if it's anxiety based, which was also the case for me, once or twice a week, small dose (1/4 or 1/2) prescribed antianxiety med when you have 8-10 hours for sleep.",
		verified: false,
		url: 'https://twitter.com/joemarchese/status/1565199406072496128',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1636412054516158464/-R9_f5e-_normal.jpg',
		name: 'Brian Sugar',
		handle: 'briansugar',
		unixDateMS: 1662004356000,
		platform: 'twitter',
		content: '@LaurenBerson @eightsleep changed everything for me. highly recommend. only downfall is trying to sleep in a bed without, kinda like wanting that large monitor on the plane.',
		verified: true,
		url: 'https://twitter.com/briansugar/status/1565185830838120448',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1568255171897049089/Yv5zIyh-_normal.jpg',
		name: 'Brian M🟠sher(ace10suted.sol)🍣🐸🐺',
		handle: 'brianmosher99',
		unixDateMS: 1661998523000,
		platform: 'twitter',
		content: 'Out at a bar seeing a friend\'s band and get this notification and all I can think of is "yeeeesss....can\'t wait". Boomer mode officially unlocked. @eightsleep ',
		verified: false,
		url: 'https://twitter.com/brianmosher99/status/1565161366423470084',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FbiR9oUXoAAcyOT.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/709829117043154944/KBoQI26T_normal.jpg',
		name: 'Daniel Lucas',
		handle: 'ARenaissanceDan',
		unixDateMS: 1661958259000,
		platform: 'twitter',
		content: '@eightsleep An amenity I am willing to pay for!',
		verified: false,
		url: 'https://twitter.com/ARenaissanceDan/status/1564992486392692736',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1592250366485110784/AJwPNyyv_normal.png',
		name: 'Nick',
		handle: 'WatchNiBe',
		unixDateMS: 1661824271000,
		platform: 'twitter',
		content: '@null_eth 100% I dished out the money for @eightsleep and it changed my life.',
		verified: false,
		url: 'https://twitter.com/WatchNiBe/status/1564430500944433152',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1648885387555635203/A5zALdi7_normal.jpg',
		name: 'Chris Irving 🗽',
		handle: 'cirving',
		unixDateMS: 1661784438000,
		platform: 'twitter',
		content: 'We’re both excited for Day 1 of testing the @eightsleep pod 3 cover! 🐈‍⬛ ',
		verified: false,
		url: 'https://twitter.com/cirving/status/1564263427832422401',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FbVhS6qXkAI_0f-.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1593856923480125446/xakOL_ei_normal.jpg',
		name: 'Cameron Rad',
		handle: 'cameronrad',
		unixDateMS: 1661710464000,
		platform: 'twitter',
		content: "@elonmusk I bought a bed cover from @eightsleep that has cooling/heating and that's been helping me a lot with sleep. Especially with the recent heat waves",
		verified: false,
		url: 'https://twitter.com/cameronrad/status/1563953159990431744',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1469417059083771904/7KoZfm0i_normal.jpg',
		name: 'Marc Atiyeh',
		handle: 'MarcAtiyeh',
		unixDateMS: 1661545172000,
		platform: 'twitter',
		content: 'Friends don’t let friends sleep on “dumb” mattresses. @eightsleep ftw ',
		verified: true,
		url: 'https://twitter.com/MarcAtiyeh/status/1563259872757460993',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FbHQhsPVEAAwehw.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/978132096907599875/weBDVZH-_normal.jpg',
		name: 'Jason McMinn',
		handle: 'jasonmcminn',
		unixDateMS: 1661488512000,
		platform: 'twitter',
		content: '@paulg I am in our @eightsleep bed reading this with the cool setting at -7. I spent two nights at a hotel earlier this week and it was miserable without it.',
		verified: false,
		url: 'https://twitter.com/jasonmcminn/status/1563022222628835328',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1513278732420755457/K-J-RVT-_normal.jpg',
		name: 'Michelle Bakels',
		handle: 'MichelleBakels',
		unixDateMS: 1661173498000,
		platform: 'twitter',
		content:
			'Unexpected cost benefit of @eightsleep is not dropping the _entire_ house temp and running the fan high every night (≈250 hrs/mo) to cool one bedroom.<br/><br/>Will be interesting to see how soon it pays for itself.',
		verified: false,
		url: 'https://twitter.com/MichelleBakels/status/1561700959021039616',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1592894439479021568/FQsQbXd8_normal.jpg',
		name: 'Luke Fischer',
		handle: 'FischerLukeM',
		unixDateMS: 1661046152000,
		platform: 'twitter',
		content: '@eightsleep pretty awesome to see this every day! ',
		verified: true,
		url: 'https://twitter.com/FischerLukeM/status/1561166830449868801',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/Fapg9RNWAAIxByS.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1513278732420755457/K-J-RVT-_normal.jpg',
		name: 'Michelle Bakels',
		handle: 'MichelleBakels',
		unixDateMS: 1660912756000,
		platform: 'twitter',
		content:
			'Night one with @eightsleep:<br/><br/>Sleep score 88% (way higher than with previous tracking)<br/>BUT most importantly, no panic attacks/night anxiety bc I could fall asleep very quickly after wake ups.<br/>I could cry.<br/><br/>Also, my husbands sleep score was 100%... 🤯',
		verified: false,
		url: 'https://twitter.com/MichelleBakels/status/1560607327669161985',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1250191087353745408/PaKf9rvH_normal.png',
		name: 'José Ancer',
		handle: 'ancerj',
		unixDateMS: 1660785081000,
		platform: 'twitter',
		content:
			'When we first bought our @eightsleep mattress my wife thought it was overpriced.<br/><br/>She grew to love it, and then need it. Complains when we travel. <br/><br/>When grid leaked and they sent replacement, she was HOUNDING me to get it set up. <br/><br/>It really is a great health / sleep hack.',
		verified: true,
		url: 'https://twitter.com/ancerj/status/1560071819264069632',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1646196559946694677/5Om2CDEJ_normal.jpg',
		name: 'Jack Morgan',
		handle: 'jckmgn',
		unixDateMS: 1660531246000,
		platform: 'twitter',
		content: '@m_franceschetti Finally bought an @eightsleep. LFG',
		verified: false,
		url: 'https://twitter.com/jckmgn/status/1559007161451220992',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1608532441307701248/Tlaa94Zv_normal.jpg',
		name: 'Mike Benson',
		handle: 'Unbox_warehouse',
		unixDateMS: 1660526976000,
		platform: 'twitter',
		content:
			'I fell asleep on my on my Restoration Hardware couch last night that cost like 7k. I was hot as shit so I went back upstairs and got in my @eightsleep mattress. Seriously don’t buy this mattress. Everything will suck after you experience it. I am forever ruined. @m_franceschetti ',
		verified: true,
		url: 'https://twitter.com/Unbox_warehouse/status/1558989252083449856',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FaKkdj4XgAAEsVM.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1523142288892784640/yyQrxZ4U_normal.jpg',
		name: 'Antonio García Martínez (agm.eth)',
		handle: 'antoniogm',
		unixDateMS: 1660199864000,
		platform: 'twitter',
		content: 'Ice my ass @eightsleep. ',
		verified: true,
		url: 'https://twitter.com/antoniogm/status/1557617243919302656',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FZ3EoOGUUAA36Sm.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1633653457738694656/xWEtTTRc_normal.jpg',
		name: 'Andy R Torrez',
		handle: 'R4racecar',
		unixDateMS: 1659967852000,
		platform: 'twitter',
		content: '@MissGinaDarling @eightsleep FUCK YEA my gf and I have had one for a long time and it helps soo much',
		verified: false,
		url: 'https://twitter.com/R4racecar/status/1556644115755855872',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1671984627446063105/PcbmCt4w_normal.jpg',
		name: 'John Ennis',
		handle: 'john_ennis_btc',
		unixDateMS: 1659954466000,
		platform: 'twitter',
		content:
			'Wow, the combination of completely giving up alcohol and an @eightsleep mattress pad has dramatically improved my sleep quality.<br/><br/>Even something small like a glass of wine really disrupts sleep.<br/><br/>It’s been about 2 1/2 weeks since I drank anything and I feel much better!',
		verified: true,
		url: 'https://twitter.com/john_ennis_btc/status/1556587969234063360',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1441608316707164165/soy5vA5w_normal.jpg',
		name: 'Chris Kaleiki',
		handle: 'ckaleiki',
		unixDateMS: 1659819774000,
		platform: 'twitter',
		content: 'Haven’t used AC once this year despite living in SoCal. 😳<br/><br/>Been some rough 80F+ nights, but I think my island Hawaiian genes 😂 and my @eightsleep carried me through it.',
		verified: false,
		url: 'https://twitter.com/ckaleiki/status/1556023031922712576',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1600593627033501697/Oip202cn_normal.jpg',
		name: 'Greg Kahn | GK Digital Ventures',
		handle: 'GK_Ventures',
		unixDateMS: 1659816560000,
		platform: 'twitter',
		content: '@volvoshine Have u tried @eightsleep?<br/><br/>Game changer…',
		verified: false,
		url: 'https://twitter.com/GK_Ventures/status/1556009549127106561',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1172592811885002752/V01ol79g_normal.jpg',
		name: 'Trevor Whyte',
		handle: 'trevorwhyte7',
		unixDateMS: 1659803368000,
		platform: 'twitter',
		content:
			'Fifth. The Environment<br/><br/>Maintain bed/room for sleep. No Screens!<br/><br/>Room should be as dark as possible<br/><br/>Minimal ambient noise as possible<br/><br/>Clean bedding. Comfortable attire<br/><br/>Temperature is VERY important. <br/><br/>*Sleep Hack* I use @eightsleep the best product I have ever spent money on.',
		verified: false,
		url: 'https://twitter.com/trevorwhyte7/status/1555954216451026945',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1448489816040120324/WKd7Yv_2_normal.jpg',
		name: 'Barrett Shepherd 📦🚀',
		handle: 'BarrettShepherd',
		unixDateMS: 1659665115000,
		platform: 'twitter',
		content: '@DallasAptGP Get a nice mattress + topper + @eightsleep Pod Cover and never look back! It’s life changing!',
		verified: false,
		url: 'https://twitter.com/BarrettShepherd/status/1555374344506941440',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1659946435888963585/gn4dfBKE_normal.jpg',
		name: 'Nafis Azad',
		handle: 'AzadNafis',
		unixDateMS: 1659578547000,
		platform: 'twitter',
		content: '@pomajp @eightsleep Love my @eightsleep! I’ve tried Oura for sleep tracking and definitely think the Pod Pro is much better',
		verified: true,
		url: 'https://twitter.com/AzadNafis/status/1555011250849021953',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1595437961985888256/HpOiU_H3_normal.jpg',
		name: "Sean O'Dowd",
		handle: 'SeanODowd15',
		unixDateMS: 1659404622000,
		platform: 'twitter',
		content: '@TheRealJFeld @eightsleep It’s life changing for me',
		verified: true,
		url: 'https://twitter.com/SeanODowd15/status/1554281756689301504',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1600593627033501697/Oip202cn_normal.jpg',
		name: 'Greg Kahn | GK Digital Ventures',
		handle: 'GK_Ventures',
		unixDateMS: 1659392020000,
		platform: 'twitter',
		content: '@TheRealJFeld @eightsleep +1 on being excited about a mattress.<br/><br/>One year later, I’m still raving about Eight Sleep.',
		verified: false,
		url: 'https://twitter.com/GK_Ventures/status/1554228901123182593',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1492580015103520777/rwItTe-h_normal.jpg',
		name: 'Jesse R. Feldman',
		handle: 'TheRealJFeld',
		unixDateMS: 1659362047000,
		platform: 'twitter',
		content:
			'Finally bought our house in Miami. Not sure what I’m more excited about, the house, or that our first purchase (and my belated birthday present) was an @eightsleep Pod3 Max. I never thought I’d be so enthusiastic about a mattress.',
		verified: true,
		url: 'https://twitter.com/TheRealJFeld/status/1554103184179830785',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/597871331846262784/QqtH6jt2_normal.jpg',
		name: 'Julio A. Berroa',
		handle: 'jberroa0204',
		unixDateMS: 1659285317000,
		platform: 'twitter',
		content: 'I had a problem with my A/C unit last night and thanks to @eightsleep my family and I were able to sleep through the night. Thank god my son also has an eight sleep.',
		verified: false,
		url: 'https://twitter.com/jberroa0204/status/1553781355426766848',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1592194144608346112/NrTJ81lQ_normal.jpg',
		name: '𝖱𝖮𝖡 𝖬𝖠𝖣𝖤𝖫𝖬𝖠𝖸𝖤𝖱 🇺🇸',
		handle: 'RMadelmayer',
		unixDateMS: 1659150010000,
		platform: 'twitter',
		content: 'dialed in @eightsleep <br/><br/>this bed has been an absolute game changer for better sleep ',
		verified: true,
		url: 'https://twitter.com/RMadelmayer/status/1553213834730651648',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FY4fvuOWQAA6iev.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/989485987943284736/QhLnALiC_normal.jpg',
		name: 'Patrick Samy',
		handle: 'patricksamy',
		unixDateMS: 1658938056000,
		platform: 'twitter',
		content:
			"After spending 10 days traveling and sleeping on dum mattresses during a heatwave, my wife and I couldn't wait to get back to our @eightsleep pod. That first night back home yesterday was magical and this article tells you exactly why: ",
		verified: true,
		url: 'https://twitter.com/patricksamy/status/1552324837091278848',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1243334504472870913/MexkInax_normal.jpg',
		name: 'sean white',
		handle: 'Coach_Baldeagle',
		unixDateMS: 1658542826000,
		platform: 'twitter',
		content: 'Look what came today. A birthday present for my wife  @eightsleep @tferriss ',
		verified: false,
		url: 'https://twitter.com/Coach_Baldeagle/status/1550667121545314306',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FYUThhqX0AATOC9.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1641151579263729692/gPmx7xYz_normal.jpg',
		name: 'Danielle Chylinski',
		handle: 'd_chylinski',
		unixDateMS: 1658540616000,
		platform: 'twitter',
		content: 'Night 1 with @eightsleep 😴 ',
		verified: false,
		url: 'https://twitter.com/d_chylinski/status/1550657853526007808',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FYULGWjWIAA0djP.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1176273397724192768/LTP9KZ56_normal.jpg',
		name: 'Eric Rea',
		handle: 'ericwilliamrea',
		unixDateMS: 1657570491000,
		platform: 'twitter',
		content: '@elonmusk Buying the @eightsleep pod was the best investment I have ever made in sleep quality. 10/10, would recommend.',
		verified: false,
		url: 'https://twitter.com/ericwilliamrea/status/1546588852311056385',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/509770564123111424/aMuq_28G_normal.jpeg',
		name: 'David Eckstein',
		handle: 'davidneckstein',
		unixDateMS: 1657565425000,
		platform: 'twitter',
		content: '@elonmusk @elonmusk or you could just buy an @eightsleep Pod with heating/cooling. The insights on sleep patterns are out of this world.',
		verified: false,
		url: 'https://twitter.com/davidneckstein/status/1546567605481250816',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1356450053062201345/TxQN3Xy6_normal.jpg',
		name: 'Ian',
		handle: 'IanMcMilan',
		unixDateMS: 1657565031000,
		platform: 'twitter',
		content: '@elonmusk Or..... Buy an @eightsleep - many call it the tesla of mattresses :)',
		verified: true,
		url: 'https://twitter.com/IanMcMilan/status/1546565952753397760',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1527379518154887181/KQ_N4DsR_normal.jpg',
		name: 'Tom Scopazzi',
		handle: 'TomScopazzi',
		unixDateMS: 1657431094000,
		platform: 'twitter',
		content: "@eightsleep is one of the best products I've ever bought. <br/>Highly recommend!<br/><br/>Hmu with any questions ",
		verified: false,
		url: 'https://twitter.com/TomScopazzi/status/1546004179889623040',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1592654074972762113/ZdZyPNjy_normal.jpg',
		name: 'Nicole Corey Real Estate Agent',
		handle: 'NicoleCoreyRE',
		unixDateMS: 1656991469000,
		platform: 'twitter',
		content:
			"@eightsleep My third night with the pod cover I slept through the night for the first time in years. This thing was worth every penny. And for the first time in my life I'm looking forward to going to sleep.",
		verified: false,
		url: 'https://twitter.com/NicoleCoreyRE/status/1544160258720817152',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1447365971539337222/4_6pE2Mr_normal.jpg',
		name: 'Cameron McCord',
		handle: 'CameronLMcCord',
		unixDateMS: 1656866151000,
		platform: 'twitter',
		content: 'Setting my new @eightsleep up - IYKYK 😴<br/><br/>@m_franceschetti ',
		verified: true,
		url: 'https://twitter.com/CameronLMcCord/status/1543634636936470529',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FWwXhSFXEAAKEl4.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1673671769155153920/ta7C4qId_normal.jpg',
		name: 'Connor Kraft⚡️',
		handle: 'KraftConnor',
		unixDateMS: 1656500936000,
		platform: 'twitter',
		content: 'Having to sleep without my @eightsleep when traveling is guaranteed brain fog',
		verified: true,
		url: 'https://twitter.com/KraftConnor/status/1542102813492273152',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1015780951689793536/KmfO9mpv_normal.jpg',
		name: 'John Erik Metcalf',
		handle: 'johnerik',
		unixDateMS: 1656213506000,
		platform: 'twitter',
		content: 'Got an @eightsleep Pod yesterday. <br/><br/>Felt awesome to stay cool the whole night without having to kick the covers off.',
		verified: true,
		url: 'https://twitter.com/johnerik/status/1540897245784166400',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1671143758920839169/aZt9mhKT_normal.jpg',
		name: '꧁𝓝𝓲𝓬𝓸𝓵𝓮 𝓜𝓪𝓻𝓲𝓮꧂',
		handle: 'nmbernard',
		unixDateMS: 1655825158000,
		platform: 'twitter',
		content: '@m_franceschetti Checking my sleep fitness score and seeing it’s pretty, pretty good…<br/>👍🏼🙌🏼💤<br/>@eightsleep ',
		verified: false,
		url: 'https://twitter.com/nmbernard/status/1539268394955972609',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FVyUclQWAAAAM-5.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1596150277450653696/yQKnOmbF_normal.jpg',
		name: 'Omar Morales',
		handle: 'OmarMora1es',
		unixDateMS: 1655773307000,
		platform: 'twitter',
		content: 'big night !! <br/><br/>@eightsleep <br/>@m_franceschetti ',
		verified: true,
		url: 'https://twitter.com/OmarMora1es/status/1539050917949018116',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FVvOpu9X0AAliA7.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1268341972135301121/bKb7QX3x_normal.jpg',
		name: 'The1adydeath',
		handle: 'The1adydeath',
		unixDateMS: 1655729858000,
		platform: 'twitter',
		content:
			'@m_franceschetti @eightsleep Last week we had "rolling" blackouts and for the first 5 hours of my sleep I couldn\'t use my Eight Sleep and it was crazy how much it affected me.  I love my Eight Sleep too much to go without it.  haha',
		verified: false,
		url: 'https://twitter.com/The1adydeath/status/1538868680733478914',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1634310592256434176/RC9VngM9_normal.jpg',
		name: 'Matt Wis | DTC Email Marketing Aficionado',
		handle: 'mattwisdtc',
		unixDateMS: 1655673522000,
		platform: 'twitter',
		content:
			'@m_franceschetti @eightsleep I remember reading one of the testimonials before purchasing it. <br/><br/>Someone said they hate traveling because the sleep is so bad if it’s not on a  @eightsleep mattresses. <br/><br/>Now I understand…',
		verified: true,
		url: 'https://twitter.com/mattwisdtc/status/1538632387331952640',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1514973863112761347/xnRjBKW__normal.jpg',
		name: 'Joy',
		handle: 'xiyuen_ak47',
		unixDateMS: 1655493501000,
		platform: 'twitter',
		content: 'Need an @eightsleep mattress to help me sleep in such hot weather',
		verified: false,
		url: 'https://twitter.com/xiyuen_ak47/status/1537877324011405312',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1608532441307701248/Tlaa94Zv_normal.jpg',
		name: 'Mike Benson',
		handle: 'Unbox_warehouse',
		unixDateMS: 1655429736000,
		platform: 'twitter',
		content: 'This is only survivable if you have an @eightsleep mattress. ',
		verified: true,
		url: 'https://twitter.com/Unbox_warehouse/status/1537609876372967425',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FVawCB2XoAIogtE.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1537185506403880960/kgi06FdV_normal.jpg',
		name: 'trash hag',
		handle: 'hagatha_crispi',
		unixDateMS: 1655328133000,
		platform: 'twitter',
		content: '@eightsleep I will be recommending it to everyone I know. No exaggeration. It’s top notch; changing the sleep game.',
		verified: false,
		url: 'https://twitter.com/hagatha_crispi/status/1537183720515379200',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1441250428717387776/XBVpLgf-_normal.jpg',
		name: 'Tyler Tringas',
		handle: 'tylertringas',
		unixDateMS: 1655303760000,
		platform: 'twitter',
		content: 'the most underrated feature of my @eightsleep is the temperature + vibration alarm is so much nicer than an annoying noise. ',
		verified: true,
		url: 'https://twitter.com/tylertringas/status/1537081493008400390',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1483617130385924104/UlZjZqo3_normal.jpg',
		name: 'Andy Amador',
		handle: 'AmaMetaU',
		unixDateMS: 1655294926000,
		platform: 'twitter',
		content: '@mattragland @eightsleep Used to have to sleep with the ac at 64. Thankfully, we can sleep at 70 now, and have the eight sleep do the heavy lifting',
		verified: false,
		url: 'https://twitter.com/AmaMetaU/status/1537044443513163777',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1680592231462305793/hxbueUj__normal.jpg',
		name: 'DataFox',
		handle: 'datafox21',
		unixDateMS: 1655182429000,
		platform: 'twitter',
		content: 'AC has been out all day here in Texas. @eightsleep vs 87 degrees tonight. Hoping for just a little sleep. ',
		verified: true,
		url: 'https://twitter.com/datafox21/status/1536572594475999232',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FVMAoPkXEAAkoC9.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1672993619534389250/cfjRmQBo_normal.jpg',
		name: 'Darsh Shah, DO',
		handle: 'doctor_darsh',
		unixDateMS: 1655178350000,
		platform: 'twitter',
		content: '2 things that will never allow me to revert back to my previous life.<br/>1. @eightsleep <br/>2. @XeroShoes',
		verified: false,
		url: 'https://twitter.com/doctor_darsh/status/1536555485289136128',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1325863121165148161/6_J4KApT_normal.jpg',
		name: 'Sean Doolan',
		handle: 'seanwdoolan',
		unixDateMS: 1655046418000,
		platform: 'twitter',
		content: 'just bought an @eightsleep now 🙏🏼 i will finally get some good sleep 😴',
		verified: true,
		url: 'https://twitter.com/seanwdoolan/status/1536002121673351172',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1668259073664450560/2fucW__T_normal.jpg',
		name: 'CryptoJustin',
		handle: 'TheCryptoCatGuy',
		unixDateMS: 1654836215000,
		platform: 'twitter',
		content: '. @eightsleep meditation series is so underrated. 10/10 recommend.',
		verified: false,
		url: 'https://twitter.com/TheCryptoCatGuy/status/1535120466393825285',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1668259073664450560/2fucW__T_normal.jpg',
		name: 'CryptoJustin',
		handle: 'TheCryptoCatGuy',
		unixDateMS: 1654785545000,
		platform: 'twitter',
		content:
			"@jclcapital Getting a @eightsleep heating/cooling mattress pod. <br/><br/>Better health tracking, deeper sleep (cooling the real MVP), better insight into my habits and how the effect my sleep, health benefits can't be overstated.",
		verified: false,
		url: 'https://twitter.com/TheCryptoCatGuy/status/1534907945183588354',
		categories: ['Everyday love'],
	},
	{
		name: 'Justin Medeiros',
		thumbnail: 'https://eightsleep.imgix.net/Justin_Medeiros.png?v=1692031578',
		video: 'https://d115sb6i1ixllw.cloudfront.net/assets/29d3b71dc3274ddfbe184601df89990b.mp4',
		categories: ['Familiar faces'],
		title: 'CrossFit Athlete',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1686143160190394369/ovIoK1G9_normal.jpg',
		name: 'brexton',
		handle: 'brextonpham',
		unixDateMS: 1654541758000,
		platform: 'twitter',
		content: 'I hate to admit it but @eightsleep — turning that temperature setting down to -6 every night and having a cold bed to collapse into...nirvana ',
		verified: true,
		url: 'https://twitter.com/brextonpham/status/1533885426838122496',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1178687854303514625/EavPn0A__normal.jpg',
		name: 'Hills of Westwood',
		handle: 'tommyscopes',
		unixDateMS: 1654472792000,
		platform: 'twitter',
		content: '@LombardiHimself @eightsleep Pro Pod Cover is life changing',
		verified: false,
		url: 'https://twitter.com/tommyscopes/status/1533596160358612992',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1451603238755909632/SuDFeyEv_normal.jpg',
		name: 'Evan Shuster',
		handle: 'abraisnic',
		unixDateMS: 1654279370000,
		platform: 'twitter',
		content: '@AlexAuerbachPhD @eightsleep Oh man I’ve had mine for a year and it is a life changer',
		verified: false,
		url: 'https://twitter.com/abraisnic/status/1532784893217882112',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1688042342920699904/XisMGHdR_normal.jpg',
		name: 'Alex Auerbach, Ph.D., MBA',
		handle: 'AlexAuerbachPhD',
		unixDateMS: 1654279216000,
		platform: 'twitter',
		content: 'My @eightsleep has arrived and it feels like the holiday season… never been more excited to go to bed and wake up tomorrow',
		verified: true,
		url: 'https://twitter.com/AlexAuerbachPhD/status/1532784244501712896',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1660678902552100866/Cb8w90AS_normal.jpg',
		name: 'Bud Hennekes',
		handle: 'Aboundlessworld',
		unixDateMS: 1654271997000,
		platform: 'twitter',
		content: 'Traveled to my dads, staying for a week. I miss my @eightsleep',
		verified: true,
		url: 'https://twitter.com/Aboundlessworld/status/1532753964856393729',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1587176605369204736/SGu55GRI_normal.jpg',
		name: 'Frank Galanda',
		handle: 'fgalanda',
		unixDateMS: 1654194537000,
		platform: 'twitter',
		content: '@eightsleep  “dont touch me, I am too hot right now” problems should be solved now! 🧠 😇 ',
		verified: true,
		url: 'https://twitter.com/fgalanda/status/1532429076459311114',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FURIH2kWUAgxfrF.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1558521576534036482/F0_0H37a_normal.jpg',
		name: 'Chuck Walter @ FriendApp',
		handle: 'ChuckWalterFL',
		unixDateMS: 1653942791000,
		platform: 'twitter',
		content:
			'@brianbreslin Once I got @eightsleep, i don’t think it mattered much what i had on top. We generate so much heat when we sleep that other mattresses absorb. I do use an australian wool blanket, not a duvet, though have considered going weighted',
		verified: true,
		url: 'https://twitter.com/ChuckWalterFL/status/1531373177007480835',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/801465548387262465/enn_m4rT_normal.jpg',
		name: 'Michaël Ortali 👨🏼‍💻🇫🇷🇺🇲',
		handle: 'xethorn',
		unixDateMS: 1653940649000,
		platform: 'twitter',
		content:
			"@brianbreslin The duvet isn't always the issue, the sheet and humidity might be.<br/><br/>@SHEEX + @eightsleep have done wonders for my partner. He no longer sweats.<br/><br/>Also: keep the humidity of the room below 65.",
		verified: false,
		url: 'https://twitter.com/xethorn/status/1531364190434172928',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1376620536319643652/Dd5HmoJ9_normal.jpg',
		name: 'Jarron Jackson',
		handle: 'JarronJackson4',
		unixDateMS: 1653856175000,
		platform: 'twitter',
		content: '@ufuejfn @eightsleep Sleep 8 is the 🐐.  Been tracking my sleep data for 2yrs. Crank that bad boy up to 55 degrees and sleep like a baby.',
		verified: false,
		url: 'https://twitter.com/JarronJackson4/status/1531009883348295681',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1623899666004815873/b8aXW-S1_normal.jpg',
		name: 'Derek Gavigan',
		handle: 'gavigan25',
		unixDateMS: 1653758497000,
		platform: 'twitter',
		content: '@CheyCab @eightsleep Simply put, this is the best bed ever made for so many reasons.  ',
		verified: false,
		url: 'https://twitter.com/gavigan25/status/1530600191027556358',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1622872449993023489/Hd7eC624_normal.jpg',
		name: 'Smeet Bhatt⚡',
		handle: 'smeet_bhatt',
		unixDateMS: 1653640559000,
		platform: 'twitter',
		content: '@m_franceschetti @nathanbaugh27 @eightsleep You guys have literally changed the way I fall asleep! Thank you 🚀',
		verified: true,
		url: 'https://twitter.com/smeet_bhatt/status/1530105523793645568',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/960686425363464192/1039XZkK_normal.jpg',
		name: 'Time to Go',
		handle: 'grace_za',
		unixDateMS: 1653482568000,
		platform: 'twitter',
		content: "My smart bed has informed me that after 8 days of tossing and turning with covid, I finally got a good night's sleep last night. @eightsleep",
		verified: false,
		url: 'https://twitter.com/grace_za/status/1529442860893880324',
		categories: ['Everyday love'],
	},
	{
		name: 'EF Pro Cycling',
		thumbnail: 'https://eightsleep.imgix.net/EF_Pro_Cycling.png?v=1692031578',
		video: 'https://d115sb6i1ixllw.cloudfront.net/assets/0099d7cd58234faca35760e6e5f91c01.mp4',
		categories: ['Familiar faces'],
		title: 'Pro Cycling Team',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1305909764182900737/rhfM6u_8_normal.png',
		name: 'ᴋᴏᴠᴀʀ',
		handle: 'stevenkovar',
		unixDateMS: 1653335433000,
		platform: 'twitter',
		content: '@jaentwistle @eightsleep @wander The one thing I always miss while traveling is my Eight Sleep. This is great.',
		verified: false,
		url: 'https://twitter.com/stevenkovar/status/1528825733308719110',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1659946435888963585/gn4dfBKE_normal.jpg',
		name: 'Nafis Azad',
		handle: 'AzadNafis',
		unixDateMS: 1653080777000,
		platform: 'twitter',
		content: 'So excited to break this in! #PodPro thank you team @eightsleep @m_franceschetti ',
		verified: true,
		url: 'https://twitter.com/AzadNafis/status/1527757626825658368',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FTOvdssWUAMVNNq.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1572349977514414083/rGnpeXNx_normal.jpg',
		name: 'Jake Hurwitz',
		handle: 'HurwitzJake',
		unixDateMS: 1652635896000,
		platform: 'twitter',
		content: 'Okay friends - just had my first night on @eightsleep and yeah it was worth the hype. Can’t wait for it to fully calibrate to my body.',
		verified: true,
		url: 'https://twitter.com/HurwitzJake/status/1525891660084830208',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1409869971090444290/SQZNoBJi_normal.jpg',
		name: 'Brent KT',
		handle: 'brent_kt',
		unixDateMS: 1652573117000,
		platform: 'twitter',
		content: 'Home is where the @eightsleep is',
		verified: true,
		url: 'https://twitter.com/brent_kt/status/1525628347078025216',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1605615415018782724/InAUhlO4_normal.jpg',
		name: 'Matt Golt',
		handle: 'mgolteez',
		unixDateMS: 1652407002000,
		platform: 'twitter',
		content:
			'If you could build your perfect smart health home, what would you want in it? <br/><br/>- small gym, @onepeloton <br/>- fresh food delivered or grown <br/>- @eightsleep bed<br/>- bio-wearable gadget, like @whoop <br/>- lots of light <br/>- air purifier <br/>- standing desk @UPLIFTDesk <br/><br/>What am I missing?',
		verified: false,
		url: 'https://twitter.com/mgolteez/status/1524931612076609537',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1683151327206023168/kUVlxe7o_normal.jpg',
		name: 'tanya.',
		handle: 'tanyaxlis',
		unixDateMS: 1652332594000,
		platform: 'twitter',
		content: 'Sam buying a @eightsleep pod pro cover is one the best decisions he’s ever made. I’ve been sleeping so much better 😌',
		verified: false,
		url: 'https://twitter.com/tanyaxlis/status/1524619521688051712',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1661064871864147968/wddDl7kp_normal.jpg',
		name: 'Lily Hecht-Leavitt',
		handle: 'LilyAnneHL',
		unixDateMS: 1652093310000,
		platform: 'twitter',
		content: 'When your body says vaca mode but your brain says NOPE😑🙄 Headed off for some R&amp;R to come back🔋to continue to slay w/ @rootine_co team.<br/>@eightsleep I’m coming for you! ',
		verified: false,
		url: 'https://twitter.com/LilyAnneHL/status/1523615891551752192',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FST4k8JX0AAYahL.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1622232431905439745/fTeHiurb_normal.jpg',
		name: 'Ryan | Family & Sales',
		handle: 'RyanLatone',
		unixDateMS: 1651801155000,
		platform: 'twitter',
		content: '@FitFounder @SaveYourSons Highly recommend you get @eightsleep game changer',
		verified: false,
		url: 'https://twitter.com/RyanLatone/status/1522390503597162502',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1355920922159575043/ZYLtXXMS_normal.jpg',
		name: 'Jess Bruns',
		handle: 'JessicaABruns',
		unixDateMS: 1651748128000,
		platform: 'twitter',
		content: 'My @eightsleep has ruined my ability to sleep anywhere but my own bed.<br/><br/>@m_franceschetti + co: too much to ask to get the Pod in hotels everywhere, like ASAP? 🤪',
		verified: false,
		url: 'https://twitter.com/JessicaABruns/status/1522168092209651712',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1351678084219596806/-tYJnmv8_normal.jpg',
		name: 'Tewfik Cassis',
		handle: 'tewfik10',
		unixDateMS: 1651523950000,
		platform: 'twitter',
		content: 'Looking forward to a great night’s sleep coming up! @eightsleep @m_franceschetti ',
		verified: true,
		url: 'https://twitter.com/tewfik10/status/1521227823066853384',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FRx8pASXIAAqCo5.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1600593627033501697/Oip202cn_normal.jpg',
		name: 'Greg Kahn | GK Digital Ventures',
		handle: 'GK_Ventures',
		unixDateMS: 1650831800000,
		platform: 'twitter',
		content: 'Name one product/service you’ve purchased this past year that’s been a game changer.<br/><br/>Me: @eightsleep',
		verified: false,
		url: 'https://twitter.com/GK_Ventures/status/1518324735016378371',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1268341972135301121/bKb7QX3x_normal.jpg',
		name: 'The1adydeath',
		handle: 'The1adydeath',
		unixDateMS: 1650737204000,
		platform: 'twitter',
		content:
			'My young self went into surgical menopause a few days after Christmas.  Night sweats to a new level! @eightsleep makes my sleep amazing and I love being able to cuddling in my blankets. Not going to lie dread traveling because I won’t have it with me. Thank you! @m_franceschetti ',
		verified: false,
		url: 'https://twitter.com/The1adydeath/status/1517927971004293120',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1448052961746341892/UUOplDS1_normal.jpg',
		name: 'Jeremy',
		handle: 'jeremysciarappa',
		unixDateMS: 1650553321000,
		platform: 'twitter',
		content: 'Upgrading my sleep with @eightsleep @m_franceschetti 💤 ',
		verified: false,
		url: 'https://twitter.com/jeremysciarappa/status/1517156711060193281',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FQ4F_ALVsAADz-Y.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1228168901575376897/u68YnRuN_normal.jpg',
		name: 'Alex Zakupowsky',
		handle: 'azakupowsky',
		unixDateMS: 1650552247000,
		platform: 'twitter',
		content:
			'@eightsleep is a game changer. By far the best addition we made in our new house. The best unexpected feature is waking up because we set the mattress to get cooler. Wayyy better than a blaring alarm. <br/><br/>Thank you 🙏 @m_franceschetti and team for building such a kick-ass product.',
		verified: false,
		url: 'https://twitter.com/azakupowsky/status/1517152202275508225',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1590747762676715520/V4pRkEBS_normal.jpg',
		name: 'Mike Annunziata',
		handle: 'nunzi46',
		unixDateMS: 1650371459000,
		platform: 'twitter',
		content:
			'@m_franceschetti @pvm Give it a month, then sleep at a hotel, then come back home. That’s when you see a HUGE difference. Particularly if you sleep hot. <br/><br/>There’s also plenty of @ouraring users on here that post stats pre/post @eightsleep and see a big jump in metrics.',
		verified: true,
		url: 'https://twitter.com/nunzi46/status/1516393924155346946',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1671143758920839169/aZt9mhKT_normal.jpg',
		name: '꧁𝓝𝓲𝓬𝓸𝓵𝓮 𝓜𝓪𝓻𝓲𝓮꧂',
		handle: 'nmbernard',
		unixDateMS: 1650281368000,
		platform: 'twitter',
		content: 'Why yes @eightsleep, we were cleaning up late after our Easter celebration. It was a lot of work. This tech is so smart. 🙌🏼❤️ ',
		verified: false,
		url: 'https://twitter.com/nmbernard/status/1516016053939322883',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FQn4kOMWQAcSxlW.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1115760912080592897/SLzOMIKH_normal.jpg',
		name: 'Fitz',
		handle: 'fitzrocks',
		unixDateMS: 1649912847000,
		platform: 'twitter',
		content: '@anothercohen @eightsleep Had one for a over a year. It’s pretty great',
		verified: false,
		url: 'https://twitter.com/fitzrocks/status/1514470364956413956',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1111676647546081280/j_A__e6t_normal.png',
		name: 'Rachel Star 🌟',
		handle: 'rachel_a_star',
		unixDateMS: 1649865000000,
		platform: 'twitter',
		content: 'Modern day "facebook official" 💕<br/>cc: @eightsleep ',
		verified: false,
		url: 'https://twitter.com/rachel_a_star/status/1514269680541868047',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FQK4DT_VQAI6nZ7.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1524547464400826368/6o4-E2mS_normal.jpg',
		name: 'Hanz',
		handle: 'lepbrochaun',
		unixDateMS: 1649534547000,
		platform: 'twitter',
		content: '@tyrelkenmore @eightsleep Best thing I’ve ever bought',
		verified: false,
		url: 'https://twitter.com/lepbrochaun/status/1512883660483047424',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1451603238755909632/SuDFeyEv_normal.jpg',
		name: 'Evan Shuster',
		handle: 'abraisnic',
		unixDateMS: 1649520475000,
		platform: 'twitter',
		content: '@Danny07077566 @eightsleep My bedroom is the hottest room in our house. Our pod is a saviour.',
		verified: false,
		url: 'https://twitter.com/abraisnic/status/1512824636848943108',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1574893736144044032/NMV9FsCc_normal.jpg',
		name: 'Joe Baer',
		handle: 'Joe_Baer_74',
		unixDateMS: 1649284961000,
		platform: 'twitter',
		content: '@JesseGavin1 @edsbs @eightsleep is the move. We bought a pod and I haven’t woken up sweaty once since then.',
		verified: false,
		url: 'https://twitter.com/Joe_Baer_74/status/1511836822795477000',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1672993619534389250/cfjRmQBo_normal.jpg',
		name: 'Darsh Shah, DO',
		handle: 'doctor_darsh',
		unixDateMS: 1649156569000,
		platform: 'twitter',
		content: 'Used @eightsleep for one night and already can tell it’s a game-changer. <br/><br/>Excited to see data, progression',
		verified: false,
		url: 'https://twitter.com/doctor_darsh/status/1511298305367711748',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1666100771161620484/tdiTTYjS_normal.jpg',
		name: 'Glen Lubbert',
		handle: 'glenlubbert',
		unixDateMS: 1648928253000,
		platform: 'twitter',
		content: 'Taking my sleep fitness to the next level… experimenting with the @eightsleep pod to regulate temperate throughout the night for improved recovery. Data and results to follow. ',
		verified: false,
		url: 'https://twitter.com/glenlubbert/status/1510340681780408325',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FPXO2DfXwAcNNsK.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1621052344971444225/Ab3WzCHe_normal.jpg',
		name: 'Mark',
		handle: 'marknikoula',
		unixDateMS: 1648911878000,
		platform: 'twitter',
		content: "It's been two years of debating to try the @eightsleep. I feel like a kid on Christmas with how excited I am to finally get it. ",
		verified: false,
		url: 'https://twitter.com/marknikoula/status/1510271998060109832',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FPWQYOWXoAA_Pwb.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1629717894585110529/oiYPlvxp_normal.jpg',
		name: 'chelseas.eth 🔮',
		handle: 'chelseas_eth',
		unixDateMS: 1648815147000,
		platform: 'twitter',
		content:
			'GM!! Last night was my first night with my @eightsleep Pod Pro Max mattress (mouthful) and I’m already soo obsessed. I’m a hot sleeper and I think this is gonna be a game changer!! <br/>Does anyone else have one of these??',
		verified: true,
		url: 'https://twitter.com/chelseas_eth/status/1509866279028641843',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1689856030266576896/HMyStYDk_normal.jpg',
		name: 'Danielle💃🏼',
		handle: 'dani_elle831',
		unixDateMS: 1648766385000,
		platform: 'twitter',
		content:
			'@ACE_Trader8 @eightsleep This is insanely cold. I couldn’t tell you what the temp is, but for reference, the bed as my auto temp at -3 the coolest. You will def save on a/c. I like to wake up to a nice warm bed 😊 ',
		verified: true,
		url: 'https://twitter.com/dani_elle831/status/1509661758268526599',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FPNlXkOXwAUYcqw.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1480069597353054210/wSHUxs33_normal.jpg',
		name: 'Andrew Desmond',
		handle: 'andrewldesmond7',
		unixDateMS: 1648602838000,
		platform: 'twitter',
		content: '@Gojo_Crypto @thedefiedge @eightsleep I’ve just got the cover and it’s absolutely game changing.',
		verified: false,
		url: 'https://twitter.com/andrewldesmond7/status/1508975790301483009',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1234859826158743552/57DkMD7n_normal.jpg',
		name: 'Bob Freier',
		handle: 'bobfreier',
		unixDateMS: 1648601851000,
		platform: 'twitter',
		content: '@iPhonedo @eightsleep Same here. I’m 3 months in. I feel more energized during the day, working out more, and it’s just so comfortable. I only wish we could take it on vacation.',
		verified: false,
		url: 'https://twitter.com/bobfreier/status/1508971652272558080',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1667397726982774789/62cTmpAV_normal.jpg',
		name: 'Francis Plaza',
		handle: 'fplaza',
		unixDateMS: 1648228105000,
		platform: 'twitter',
		content: 'Had way too many face to face meets this week than I ever had over the last two years. Need to recharge and just curl in bed tonight. I’m happy to be back with my @eightsleep',
		verified: false,
		url: 'https://twitter.com/fplaza/status/1507404047388901377',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1147945077693698048/r-Gysy9L_normal.png',
		name: 'Michael Kummer',
		handle: 'mkummer82',
		unixDateMS: 1647870758000,
		platform: 'twitter',
		content: 'Based on my research, @eightsleep offers the most advanced “cooling mattress” and sleep technology on the market so I give it a try: ',
		verified: false,
		url: 'https://twitter.com/mkummer82/status/1505905224757788684',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FOYIUnVWUAUJby9.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1554522927147749378/Yt-P4DuE_normal.jpg',
		name: 'Ryan McAdams',
		handle: 'ryanmcadams',
		unixDateMS: 1647575419000,
		platform: 'twitter',
		content: 'The @eightsleep Pod Pro Max has changed my life.  I’ve never slept better.  Period.<br/><br/>#sleep #sleeping',
		verified: true,
		url: 'https://twitter.com/ryanmcadams/status/1504666484689227776',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1609246423702282240/Mi0Js6w__normal.jpg',
		name: 'AJ Wolf',
		handle: 'ajwolf93',
		unixDateMS: 1647526314000,
		platform: 'twitter',
		content: 'So fired up for my Eight sleep!! @m_franceschetti @eightsleep ',
		verified: true,
		url: 'https://twitter.com/ajwolf93/status/1504460519981424655',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FODq3lUXMAg2TXc.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1320748195924840449/aAbBLEJ3_normal.jpg',
		name: 'Jerry Levine',
		handle: 'jerry_levine',
		unixDateMS: 1647295800000,
		platform: 'twitter',
		content: '@ConnorColson I highly recommend @eightsleep.<br/><br/>I like the room to be cold and my Tauntaun to be warm. Except in the summer, where the room is warm and the tauntaun is cold.',
		verified: false,
		url: 'https://twitter.com/jerry_levine/status/1503493676676399113',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1600668706220171265/m2TJM5b6_normal.jpg',
		name: 'Dimitri Roumeliotis',
		handle: 'defiDimitri',
		unixDateMS: 1646962491000,
		platform: 'twitter',
		content: "@KraftConnor @eightsleep Once you join @eightsleep there's no going back to a regular mattress. So much so, that you miss it while on vacation!",
		verified: true,
		url: 'https://twitter.com/defiDimitri/status/1502095677316386818',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1242918994346205184/yQNtSgX5_normal.jpg',
		name: 'Symmetry',
		handle: 'Symmetry_Invest',
		unixDateMS: 1646939586000,
		platform: 'twitter',
		content:
			'My @eightsleep is probably the highest ROI investment i have ever made. I spend 8/24 or 1/3 of my life in my bed. Why not make the most out of it so i can be at my A-game the remaining 16 hours as a father, husband and investor 🤗 ',
		verified: false,
		url: 'https://twitter.com/Symmetry_Invest/status/1501999603851444224',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FNgsrOMXsA0WjNh.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1673671769155153920/ta7C4qId_normal.jpg',
		name: 'Connor Kraft⚡️',
		handle: 'KraftConnor',
		unixDateMS: 1646910922000,
		platform: 'twitter',
		content: 'First night in my @eightsleep was epic. Lived up to the hype.',
		verified: true,
		url: 'https://twitter.com/KraftConnor/status/1501879379638554624',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1633993252474064897/H8kgWbUv_normal.jpg',
		name: 'Dave Shinn',
		handle: 'davidjshinn',
		unixDateMS: 1646809517000,
		platform: 'twitter',
		content: 'Not gonna pretend it’s a small purchase. But this has changed my life. If you can - really consider @eightsleep ',
		verified: false,
		url: 'https://twitter.com/davidjshinn/status/1501454058611113984',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1552337344744660992/nZocPxou_normal.jpg',
		name: 'Nolan Church',
		handle: 'NolanChurch',
		unixDateMS: 1646670294000,
		platform: 'twitter',
		content: '@hankai1998 my drug of choice is @eightsleep',
		verified: true,
		url: 'https://twitter.com/NolanChurch/status/1500870112533901320',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/3532609754/802ea9940985853c3633d540f0428dd9_normal.jpeg',
		name: 'Tim Ryan',
		handle: 'ryantnt',
		unixDateMS: 1646613967000,
		platform: 'twitter',
		content:
			'I tweeted a couple of months ago how the @eightsleep Pod Pro Cover has changed the game by keeping my side of the bed perfectly tempered. <br/><br/>Now that temps are getting warmer outside and in the house. I just got this notification. Mind blown! Truly an amazing product. ',
		verified: true,
		url: 'https://twitter.com/ryantnt/status/1500633859125305344',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FNNSipyWQAc1m99.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1621385040881131520/Yuk_QX1S_normal.jpg',
		name: 'Tyrel Johnson',
		handle: 'TyrelJohnson',
		unixDateMS: 1646403943000,
		platform: 'twitter',
		content: "@theSamParr Get @eightsleep and don't look back",
		verified: false,
		url: 'https://twitter.com/TyrelJohnson/status/1499752957138673664',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1402815212252770308/XMzTsLXM_normal.jpg',
		name: 'Mateo Vega-Sanz🦄',
		handle: 'MattVanzz',
		unixDateMS: 1646402489000,
		platform: 'twitter',
		content: '@theSamParr @eightsleep! That quality from it is a productivity hack',
		verified: true,
		url: 'https://twitter.com/MattVanzz/status/1499746856091348994',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1234859826158743552/57DkMD7n_normal.jpg',
		name: 'Bob Freier',
		handle: 'bobfreier',
		unixDateMS: 1646401380000,
		platform: 'twitter',
		content: '@theSamParr @eightsleep is the only way to go.',
		verified: false,
		url: 'https://twitter.com/bobfreier/status/1499742205090619396',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1641945139177193473/SKxPKNAn_normal.jpg',
		name: 'Lorenzo Thione (he/him) 🏳️‍🌈',
		handle: 'thione',
		unixDateMS: 1646398051000,
		platform: 'twitter',
		content: '@theSamParr Hands down @eightsleep. I got a second one for my vacation home even. Will never sleep on anything else',
		verified: true,
		url: 'https://twitter.com/thione/status/1499728243481534467',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1532482365561491473/afASRH_t_normal.jpg',
		name: 'Jeff',
		handle: 'Swag_Stakepool',
		unixDateMS: 1646164540000,
		platform: 'twitter',
		content: '@bigpeyYT @Padierfind @eightsleep @hashoshi4 I got the mattress cover and pod. Worth every cent.',
		verified: false,
		url: 'https://twitter.com/Swag_Stakepool/status/1498748825653194757',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1214697958303838209/e8ELWupN_normal.jpg',
		name: 'Red Beard Ops',
		handle: 'RedBeardOps',
		unixDateMS: 1692492313000,
		platform: 'twitter',
		content: `
			I was in a hotel during this bracketed period. Away from our 8-sleep mattress cover… 
			<br/><br/>
			Now to be fair, there were other factors like bad pillows; elevation; hotel room light pollution; sub par climate control; etc…. But I sure am happy to be back home!
			<br/><br/>
			Data gathered with an 
			@ouraring
			`,
		verified: true,
		url: 'https://twitter.com/RedBeardOps/status/1693061588872245556',
		image: 'https://pbs.twimg.com/media/F372im-W8AAo7Nt?format=jpg&name=medium',
		categories: ['Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1375265268687982592/oLoZ8KQP_normal.jpg',
		name: 'Tate Hackert',
		handle: 'TateHackert',
		unixDateMS: 1646150020000,
		platform: 'twitter',
		content: "Now that I have an @eightsleep it's honestly depressing how bad Hotel sleeps are.",
		verified: true,
		url: 'https://twitter.com/TateHackert/status/1498687924803555328',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1598413406792347648/OLkN77PI_normal.jpg',
		name: 'Raymond Durk',
		handle: 'RaymondDurk',
		unixDateMS: 1645842226000,
		platform: 'twitter',
		content: '@BrianLockhart @APompliano @eightsleep Great bed. I love mine.',
		verified: false,
		url: 'https://twitter.com/RaymondDurk/status/1497396944120934400',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1674544382920335362/OMO0b0mS_normal.jpg',
		name: 'TheProducer.bit',
		handle: 'c0nt3mp7',
		unixDateMS: 1645765365000,
		platform: 'twitter',
		content: "@xtinacomputes @eightsleep This thing changed my life, and probably my wifes' as well. No more clammy hubby in the morning!",
		verified: false,
		url: 'https://twitter.com/c0nt3mp7/status/1497074567935758337',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1415085650034970624/tJxCcbcx_normal.jpg',
		name: 'Jake Schuster',
		handle: 'CoolHandJakeGS',
		unixDateMS: 1645710950000,
		platform: 'twitter',
		content: 'Day 22 on @eightsleep <br/><br/>I no longer need coffee',
		verified: false,
		url: 'https://twitter.com/CoolHandJakeGS/status/1496846331167621126',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1375265268687982592/oLoZ8KQP_normal.jpg',
		name: 'Tate Hackert',
		handle: 'TateHackert',
		unixDateMS: 1647786519000,
		platform: 'twitter',
		content:
			'@m_franceschetti Thank my @eightsleep for a non-groggy cold start. Then, ~1 hr morning mastery followed by quick shower, maybe a bite to eat, and then either coffee shop or home office. ',
		verified: true,
		url: 'https://twitter.com/TateHackert/status/1505551900774567942',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1403462068137869313/cE3sTeuQ_normal.jpg',
		name: 'Patrick Aloisio',
		handle: 'Patrick_Aloisio',
		unixDateMS: 1647736059000,
		platform: 'twitter',
		content: "@MrSollozzo @eightsleep is maybe the best purchase I've ever made. Went from being a terrible sleeper, to getting solid sleep every night. Highly recommend!",
		verified: false,
		url: 'https://twitter.com/Patrick_Aloisio/status/1505340256337502211',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1554522927147749378/Yt-P4DuE_normal.jpg',
		name: 'Ryan McAdams',
		handle: 'ryanmcadams',
		unixDateMS: 1647575419000,
		platform: 'twitter',
		content: 'The @eightsleep Pod Pro Max has changed my life.  I’ve never slept better.  Period.<br/><br/>#sleep #sleeping',
		verified: true,
		url: 'https://twitter.com/ryanmcadams/status/1504666484689227776',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1609246423702282240/Mi0Js6w__normal.jpg',
		name: 'AJ Wolf',
		handle: 'ajwolf93',
		unixDateMS: 1647526314000,
		platform: 'twitter',
		content: 'So fired up for my Eight sleep!! @m_franceschetti @eightsleep ',
		verified: true,
		url: 'https://twitter.com/ajwolf93/status/1504460519981424655',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FODq3lUXMAg2TXc.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1242918994346205184/yQNtSgX5_normal.jpg',
		name: 'Symmetry',
		handle: 'Symmetry_Invest',
		unixDateMS: 1646939586000,
		platform: 'twitter',
		content:
			'My @eightsleep is probably the highest ROI investment i have ever made. I spend 8/24 or 1/3 of my life in my bed. Why not make the most out of it so i can be at my A-game the remaining 16 hours as a father, husband and investor 🤗 ',
		verified: false,
		url: 'https://twitter.com/Symmetry_Invest/status/1501999603851444224',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FNgsrOMXsA0WjNh.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1673671769155153920/ta7C4qId_normal.jpg',
		name: 'Connor Kraft⚡️',
		handle: 'KraftConnor',
		unixDateMS: 1646910922000,
		platform: 'twitter',
		content: 'First night in my @eightsleep was epic. Lived up to the hype.',
		verified: true,
		url: 'https://twitter.com/KraftConnor/status/1501879379638554624',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1644469660979544064/68sijspI_normal.jpg',
		name: 'JonMichael Littleton',
		handle: 'jonmlittleton',
		unixDateMS: 1646783365000,
		platform: 'twitter',
		content:
			'@traestephens @m_franceschetti @eightsleep The hype is real. Bought one about a year ago and now hate traveling because I won’t have my @eightsleep bed. Had to talk my wife into getting it and after staying in a hotel Friday, she told me it was the best thing we’d ever bought 😂😂',
		verified: false,
		url: 'https://twitter.com/jonmlittleton/status/1501344369558929410',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1340390266935644161/ift6z1e5_normal.jpg',
		name: 'Nikhil "Nik" Kundra 🇺🇸🇺🇦',
		handle: 'NikKundra',
		unixDateMS: 1646411404000,
		platform: 'twitter',
		content: '@theSamParr @eightsleep should be the mattress everyone owns. My time and quality always skyrocket compared to when I don’t have it.',
		verified: true,
		url: 'https://twitter.com/NikKundra/status/1499784250207309830',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1402815212252770308/XMzTsLXM_normal.jpg',
		name: 'Mateo Vega-Sanz🦄',
		handle: 'MattVanzz',
		unixDateMS: 1646402489000,
		platform: 'twitter',
		content: '@theSamParr @eightsleep! That quality from it is a productivity hack',
		verified: true,
		url: 'https://twitter.com/MattVanzz/status/1499746856091348994',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1621385040881131520/Yuk_QX1S_normal.jpg',
		name: 'Tyrel Johnson',
		handle: 'TyrelJohnson',
		unixDateMS: 1646403943000,
		platform: 'twitter',
		content: "@theSamParr Get @eightsleep and don't look back",
		verified: false,
		url: 'https://twitter.com/TyrelJohnson/status/1499752957138673664',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1234859826158743552/57DkMD7n_normal.jpg',
		name: 'Bob Freier',
		handle: 'bobfreier',
		unixDateMS: 1646401380000,
		platform: 'twitter',
		content: '@theSamParr @eightsleep is the only way to go.',
		verified: false,
		url: 'https://twitter.com/bobfreier/status/1499742205090619396',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1641945139177193473/SKxPKNAn_normal.jpg',
		name: 'Lorenzo Thione (he/him) 🏳️‍🌈',
		handle: 'thione',
		unixDateMS: 1646398051000,
		platform: 'twitter',
		content: '@theSamParr Hands down @eightsleep. I got a second one for my vacation home even. Will never sleep on anything else',
		verified: true,
		url: 'https://twitter.com/thione/status/1499728243481534467',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1401728278285717507/m3GxMXvm_normal.jpg',
		name: 'Nikhil Goel',
		handle: 'ngoel36',
		unixDateMS: 1646352012000,
		platform: 'twitter',
		content: '@theSamParr All you need is @eightsleep',
		verified: true,
		url: 'https://twitter.com/ngoel36/status/1499535142351785986',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1375265268687982592/oLoZ8KQP_normal.jpg',
		name: 'Tate Hackert',
		handle: 'TateHackert',
		unixDateMS: 1646150020000,
		platform: 'twitter',
		content: "Now that I have an @eightsleep it's honestly depressing how bad Hotel sleeps are.",
		verified: true,
		url: 'https://twitter.com/TateHackert/status/1498687924803555328',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1532482365561491473/afASRH_t_normal.jpg',
		name: 'Jeff',
		handle: 'Swag_Stakepool',
		unixDateMS: 1646164149000,
		platform: 'twitter',
		content: '@Padierfind The @eightsleep is also a gamechanger. @hashoshi4 put me on game. Can’t go ever go back. 😂',
		verified: false,
		url: 'https://twitter.com/Swag_Stakepool/status/1498747186682777605',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1583307478007160832/yUz7UYVY_normal.jpg',
		name: 'David Sinclair',
		handle: 'davidasinclair',
		unixDateMS: 1641231255000,
		platform: 'twitter',
		content: 'Enjoying my @eightsleep bed @m_franceschetti. Figured I’d sleep better but didn’t realize it measures heart rate too 👏',
		verified: true,
		url: 'https://twitter.com/davidasinclair/status/1478057128430653442',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1415085650034970624/tJxCcbcx_normal.jpg',
		name: 'Jake Schuster',
		handle: 'CoolHandJakeGS',
		unixDateMS: 1645710950000,
		platform: 'twitter',
		content: 'Day 22 on @eightsleep <br/><br/>I no longer need coffee',
		verified: false,
		url: 'https://twitter.com/CoolHandJakeGS/status/1496846331167621126',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1408513446421827588/HYlON6TB_normal.jpg',
		name: 'Kyle Tibbitts',
		handle: 'KyleTibbitts',
		unixDateMS: 1645583684000,
		platform: 'twitter',
		content: '@TateHackert @wander @eightsleep We love @eightsleep :-)',
		verified: true,
		url: 'https://twitter.com/KyleTibbitts/status/1496312540506337283',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1623859785979564037/NAY02M2X_normal.jpg',
		name: 'Zach Tann',
		handle: 'ZachTann76',
		unixDateMS: 1645233346000,
		platform: 'twitter',
		content: '@JoePompliano @eightsleep I love this bed. Had it for about a year. Amazing.',
		verified: false,
		url: 'https://twitter.com/ZachTann76/status/1494843116469194752',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1608532441307701248/Tlaa94Zv_normal.jpg',
		name: 'Mike Benson',
		handle: 'Unbox_warehouse',
		unixDateMS: 1643935514000,
		platform: 'twitter',
		content:
			'I have an @eightsleep mattress I am trying to review. I fear that I will not convince you guys how life changing it is. I stayed in a nice hotel last week and was hot an miserable. I cannot imagine life without it.  #eightsleep',
		verified: true,
		url: 'https://twitter.com/Unbox_warehouse/status/1489399614050643969',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1482849191214915587/o-aZo4xw_normal.jpg',
		name: 'Joe Pompliano',
		handle: 'JoePompliano',
		unixDateMS: 1645233444000,
		platform: 'twitter',
		content: '@ZachTann76 @eightsleep It’s the best',
		verified: true,
		url: 'https://twitter.com/JoePompliano/status/1494843526026121218',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1667397726982774789/62cTmpAV_normal.jpg',
		name: 'Francis Plaza',
		handle: 'fplaza',
		unixDateMS: 1644039764000,
		platform: 'twitter',
		content: '.@eightsleep is definitely one of my worthy investments ever ',
		verified: false,
		url: 'https://twitter.com/fplaza/status/1489836872838774788',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1643372492784824320/DeDQxMuo_normal.jpg',
		name: 'Pauline P. Narvas ✨',
		handle: 'paulienuh',
		unixDateMS: 1645051655000,
		platform: 'twitter',
		content: "TFW you get to bed and it's at the perfect temperature because of @eightsleep. So good 😩",
		verified: false,
		url: 'https://twitter.com/paulienuh/status/1494081050187907080',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1584900693781057537/D1FQJ-Yu_normal.jpg',
		name: '1ZeusHux',
		handle: 'orwanifar',
		unixDateMS: 1643422895000,
		platform: 'twitter',
		content: '@rlj_law @eightsleep One of the few products that actually lives up to the hype. I bought two. And it only gets better from here.',
		verified: false,
		url: 'https://twitter.com/orwanifar/status/1487249534132629514',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1123341625948815361/F24b20N6_normal.jpg',
		name: 'Allison DeKuiper',
		handle: 'hrv_isham11',
		unixDateMS: 1643416704000,
		platform: 'twitter',
		content: '@rlj_law @eightsleep @m_franceschetti absolutely love the product. 10/10 would recommend 🔥',
		verified: false,
		url: 'https://twitter.com/hrv_isham11/status/1487223569109200901',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1451603238755909632/SuDFeyEv_normal.jpg',
		name: 'Evan Shuster',
		handle: 'abraisnic',
		unixDateMS: 1643891683000,
		platform: 'twitter',
		content: '@mgolteez @eightsleep Best investment we’ve ever made in our health',
		verified: false,
		url: 'https://twitter.com/abraisnic/status/1489215773746814976',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1655720647241113605/oGgRWD9w_normal.jpg',
		name: 'Rachel Renock',
		handle: 'rachren1',
		unixDateMS: 1641051889000,
		platform: 'twitter',
		content:
			'i got exposed to covid earlier this week and i’m symptomatic today but testing negative so far. <br/><br/>what’s crazy is that my @eightsleep detected my hrv dropping and shr spiking, and then told me they were both indicators of getting sick before i had symptoms 🤯<br/><br/>yes my bed texts me ',
		verified: false,
		url: 'https://twitter.com/rachren1/status/1477304815025889291',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FIBw5geWUAUZ4Qv.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1689729667148771328/-4YJA9bP_normal.jpg',
		name: 'AB 🍊 QuickNode.com',
		handle: 'bunsen',
		unixDateMS: 1640718108000,
		platform: 'twitter',
		content:
			'I recently bought an @EightSleep - I tracked my sleep score on an independent app for 30 days before making the switch…<br/><br/>My sleep score went up about 13% on average &amp; sleeping heart rate went down two points on the median. <br/><br/>Put simply: @eightsleep improves your sleep quality. ',
		verified: false,
		url: 'https://twitter.com/bunsen/status/1475904833953665026',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1461727266015039488/WPThZ6zo_normal.jpg',
		name: 'ticker talker',
		handle: 'talkingtickers',
		unixDateMS: 1642794130000,
		platform: 'twitter',
		content: '@sayinshallah Get an @eightsleep. Hands down the best purchase I’ve ever made.',
		verified: false,
		url: 'https://twitter.com/talkingtickers/status/1484612303555162114',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/975907833408454656/GKzx-fBD_normal.jpg',
		name: 'David Garcia',
		handle: 'DavidAG03',
		unixDateMS: 1642770010000,
		platform: 'twitter',
		content: '@AlissaWalsh21 @MattWalshBlog Get an @eightsleep and you can warm your side of the bed while he keeps his side cool. Game changer',
		verified: false,
		url: 'https://twitter.com/DavidAG03/status/1484511135076392962',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1547787118243561478/PxWzJo1H_normal.jpg',
		name: 'Pomp 🌪',
		handle: 'APompliano',
		unixDateMS: 1640716281000,
		platform: 'twitter',
		content: 'Eight Sleep is a game changer. Top 10% coldest sleeper on the way to top 1% hopefully 🙏🏼 ',
		verified: true,
		url: 'https://twitter.com/APompliano/status/1475897171442802688',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FHtwdlXWQAgu5vJ.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1556535390642835458/hLc-doOs_normal.jpg',
		name: 'Krish Jayaram',
		handle: 'thekrishjay',
		unixDateMS: 1645911096000,
		platform: 'twitter',
		content: '@mfg @eightsleep no question',
		verified: false,
		url: 'https://twitter.com/thekrishjay/status/1497685807259291648',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/718598294323073024/qu7IlBA8_normal.jpg',
		name: 'Billy Jalbert',
		handle: 'Mauibilly',
		unixDateMS: 1641151828000,
		platform: 'twitter',
		content: '@laurenakleinman @eightsleep 18 months in, it has improved my deep sleep by 35+%. Tons more energy / endurance. Best performance enhancing drug of the decade.',
		verified: false,
		url: 'https://twitter.com/Mauibilly/status/1477723988042211329',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1554827587523776514/FPUKHovB_normal.jpg',
		name: 'Matt Blank',
		handle: 'MattyBuilds',
		unixDateMS: 1640384079000,
		platform: 'twitter',
		content:
			"I was skeptical @eightsleep would improve my sleep as much as my timeline made me believe it would...<br/><br/>[nights I slept on my 8S + didn't drink] vs. [nights I didn't use 8S + didn't drink] since I got my pod:<br/>REM: 19% ⬆️<br/>Deep: 13% ⬆️<br/>@whoop recovery score: 28% ⬆️<br/><br/>...it works 😴 ",
		verified: true,
		url: 'https://twitter.com/MattyBuilds/status/1474503817383358466',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FHZ9HC_WUAAi083.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1644327398358368257/ZRAFaGPY_normal.jpg',
		name: 'Viktor Bunin 🛡️',
		handle: 'ViktorBunin',
		unixDateMS: 1640098251000,
		platform: 'twitter',
		content:
			"Folks, @eightsleep is by far the best mattress I've ever used and it's not even close. Best purchase of 2021 (other than our house).<br/><br/>Best feature: the bed warms and vibrates to very gently wake you up. I always feel refreshed instead of jarred by regular alarm clocks. 10/10",
		verified: true,
		url: 'https://twitter.com/ViktorBunin/status/1473304965863153677',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1592336881706242048/A3XE42m1_normal.png',
		name: 'MichelleLora.eth 🎈',
		handle: 'itsmichellelora',
		unixDateMS: 1641066197000,
		platform: 'twitter',
		content: '@uglyhugh @eightsleep has been a literal dream',
		verified: false,
		url: 'https://twitter.com/itsmichellelora/status/1477364828394164229',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1667397726982774789/62cTmpAV_normal.jpg',
		name: 'Francis Plaza',
		handle: 'fplaza',
		unixDateMS: 1639724684000,
		platform: 'twitter',
		content: 'After being away from home for 16 days, I realised I do miss my bed. You are something @eightsleep',
		verified: false,
		url: 'https://twitter.com/fplaza/status/1471738112879464449',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1477799215980032003/Iyq9h9GX_normal.jpg',
		name: 'BowTiedWookie',
		handle: 'BowTiedWookie',
		unixDateMS: 1642192241000,
		platform: 'twitter',
		content: '@BowTiedScar Dude get an @eightsleep. Life changing',
		verified: true,
		url: 'https://twitter.com/BowTiedWookie/status/1482087798123659264',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/892853725336817664/A553UePu_normal.jpg',
		name: 'Ben Morris',
		handle: 'bnmrrs',
		unixDateMS: 1639628133000,
		platform: 'twitter',
		content: '@rkhazzam @eightsleep is a game changer for temp. We have the pod + cover and it’s SO good',
		verified: false,
		url: 'https://twitter.com/bnmrrs/status/1471333150429167623',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1580188498748968961/L6lebFCL_normal.jpg',
		name: 'Louisa Nicola',
		handle: 'louisanicola_',
		unixDateMS: 1639170914000,
		platform: 'twitter',
		content:
			'Mum: Louisa, your father and I need a new mattress. Can you please get us the cold and hot mattress you use. <br/><br/>Me: Firstly, it’s an @eightsleep and secondly, when they are available to ship to Aus you will be the first ones to own one.',
		verified: true,
		url: 'https://twitter.com/louisanicola_/status/1469415435523088384',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1541552092103380993/8dyG2pAG_normal.jpg',
		name: 'Samir Kaul',
		handle: 'SamirKaul1',
		unixDateMS: 1643984387000,
		platform: 'twitter',
		content: 'My respiratory rate down 7% using @eightsleep',
		verified: false,
		url: 'https://twitter.com/SamirKaul1/status/1489604602269896706',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1660678902552100866/Cb8w90AS_normal.jpg',
		name: 'Bud Hennekes',
		handle: 'Aboundlessworld',
		unixDateMS: 1643943858000,
		platform: 'twitter',
		content: '3/ As a hot sleeper, I immediately found the cooling feature truly a game changer. I slept like a baby, and never woke up hot.',
		verified: true,
		url: 'https://twitter.com/Aboundlessworld/status/1489434611373461513',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1584868536811495424/rMNXT5pl_normal.jpg',
		name: 'Patrick OShaughnessy',
		handle: 'patrick_oshag',
		unixDateMS: 1643973754000,
		platform: 'twitter',
		content:
			'What’s interesting about @eightsleep relative to other health tech (wearables) is that it actually does something to or for you (thermoregulation), rather than simply measure data. <br/><br/>I usually churn off of other wearables. I suspect I’ll use eight sleep forever. ',
		verified: true,
		url: 'https://twitter.com/patrick_oshag/status/1489560003342217219',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/975907833408454656/GKzx-fBD_normal.jpg',
		name: 'David Garcia',
		handle: 'DavidAG03',
		unixDateMS: 1642770010000,
		platform: 'twitter',
		content: '@AlissaWalsh21 @MattWalshBlog Get an @eightsleep and you can warm your side of the bed while he keeps his side cool. Game changer',
		verified: false,
		url: 'https://twitter.com/DavidAG03/status/1484511135076392962',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1427017550169141250/g3k46xra_normal.jpg',
		name: 'Eddie ☮️',
		handle: 'pixelknitter',
		unixDateMS: 1643941747000,
		platform: 'twitter',
		content: 'HUGE. Eight Sleep has been a gamechanger for me and my bike rides. ❤️‍🔥🚴‍♂️ ',
		verified: false,
		url: 'https://twitter.com/pixelknitter/status/1489425757554106373',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1172592811885002752/V01ol79g_normal.jpg',
		name: 'Trevor Whyte',
		handle: 'trevorwhyte7',
		unixDateMS: 1643938559000,
		platform: 'twitter',
		content:
			'@m_franceschetti @eightsleep I feel like I fall asleep faster, sleep deeper and recover quicker especially after intense mental or physical exertions.<br/><br/>while there is definitely room to grow. the starting point is astounding positive.',
		verified: false,
		url: 'https://twitter.com/trevorwhyte7/status/1489412387941715968',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1234859826158743552/57DkMD7n_normal.jpg',
		name: 'Bob Freier',
		handle: 'bobfreier',
		unixDateMS: 1644507142000,
		platform: 'twitter',
		content: '@kingsidharth Easy. It’s called @eightsleep',
		verified: false,
		url: 'https://twitter.com/bobfreier/status/1491797198098481159',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1552445737719529472/1725q7Dk_normal.jpg',
		name: 'Lucy Guo',
		handle: 'lucy_guo',
		unixDateMS: 1643837349000,
		platform: 'twitter',
		content:
			'The best feature of the @eightsleep mattress is the vibrating alarm (in the mattress)<br/><br/>No matter how much I want to stay in bed, I get up because I know I won’t fall back asleep <br/><br/>Although it might be hurting my sleep fitness score 😂',
		verified: true,
		url: 'https://twitter.com/lucy_guo/status/1488987881553240071',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1328440064632819712/YP6jLQ3a_normal.jpg',
		name: 'Brian Robinson / brobinson.eth',
		handle: 'br524',
		unixDateMS: 1643744069000,
		platform: 'twitter',
		content: 'On the road this week.  I really miss my @eightsleep.  @Marriott get some please!',
		verified: false,
		url: 'https://twitter.com/br524/status/1488596638226522116',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1584938130196418560/Pmy1_HUT_normal.jpg',
		name: 'Alex Roy',
		handle: 'AlexRoy144',
		unixDateMS: 1643559045000,
		platform: 'twitter',
		content: '@ajtonge40 My @eightsleep is extraordinary<br/><br/>Every bit as great as CEO @m_franceschetti promised',
		verified: true,
		url: 'https://twitter.com/AlexRoy144/status/1487820590778138628',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1294808482391232513/Q6pjooOE_normal.jpg',
		name: 'Leigh Lytle',
		handle: 'leighlytle',
		unixDateMS: 1643471066000,
		platform: 'twitter',
		content: '@a_zatarain @eightsleep Love that pod heat!!!',
		verified: false,
		url: 'https://twitter.com/leighlytle/status/1487451577069686784',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1532008723417387011/EpSI3JLj_normal.jpg',
		name: 'Justin Darcy',
		handle: 'justinsdarcy',
		unixDateMS: 1643410716000,
		platform: 'twitter',
		content: '@m_franceschetti @eightsleep I love this company 👏🏼👏🏼👏🏼',
		verified: false,
		url: 'https://twitter.com/justinsdarcy/status/1487198452736012289',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1632861834679320577/igkfWFCj_normal.jpg',
		name: 'Rob Fraser',
		handle: 'robbfraser',
		unixDateMS: 1643413305000,
		platform: 'twitter',
		content:
			"@CharlieGrinnell @rlj_law @eightsleep @mrsharma The product is great. On balance, it's made my life better. <br/><br/>Agree that the post-purchase/delivery experience needs some work. <br/><br/>Overall, I recommend it.",
		verified: true,
		url: 'https://twitter.com/robbfraser/status/1487209310455173121',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/861132496276246528/LWdaCIhN_normal.jpg',
		name: 'Sameer Trikha',
		handle: 'SameerTrikha',
		unixDateMS: 1643410476000,
		platform: 'twitter',
		content: 'Real time physiological data while we sleep. This company is going to be truly transformative ! @m_franceschetti @eightsleep #MEDTECH ',
		verified: false,
		url: 'https://twitter.com/SameerTrikha/status/1487197444483989506',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1524849005666131968/d-S6tHPT_normal.png',
		name: 'Nik Sharma',
		handle: 'mrsharma',
		unixDateMS: 1643403376000,
		platform: 'twitter',
		content: '@rlj_law @eightsleep I’m neither and it’s 20/10. I get anxiety when I have to sleep without my eight sleep',
		verified: true,
		url: 'https://twitter.com/mrsharma/status/1487167665709981696',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1532008723417387011/EpSI3JLj_normal.jpg',
		name: 'Justin Darcy',
		handle: 'justinsdarcy',
		unixDateMS: 1643390789000,
		platform: 'twitter',
		content: 'On essentially the last selling today of our fiscal year @salesforce my @eightsleep delivered Cc @m_franceschetti thank you ',
		verified: false,
		url: 'https://twitter.com/justinsdarcy/status/1487114872013799427',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FKNLGBfXoAYFoUD.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1547787118243561478/PxWzJo1H_normal.jpg',
		name: 'Pomp 🌪',
		handle: 'APompliano',
		unixDateMS: 1643247245000,
		platform: 'twitter',
		content: '@JoePompliano @eightsleep Biggest no brainer ever 🔥',
		verified: true,
		url: 'https://twitter.com/APompliano/status/1486512803246706691',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1584900693781057537/D1FQJ-Yu_normal.jpg',
		name: '1ZeusHux',
		handle: 'orwanifar',
		unixDateMS: 1643055579000,
		platform: 'twitter',
		content: '@sidk_ @eightsleep Congrats. Love my @eightsleep so much!',
		verified: false,
		url: 'https://twitter.com/orwanifar/status/1485708900649377792',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1645543915846606848/L0Cqe7iL_normal.jpg',
		name: 'Matt Williams',
		handle: 'mattyboats',
		unixDateMS: 1643892599000,
		platform: 'twitter',
		content: 'Amazing product, amazing brand…@eightsleep ',
		verified: false,
		url: 'https://twitter.com/mattyboats/status/1489219617667092482',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1516269430086389762/erJa_6Gq_normal.jpg',
		name: 'B Fathieh 🦇🔊',
		handle: 'BFATHIEH',
		unixDateMS: 1643935169000,
		platform: 'twitter',
		content: '@AriannaSimpson @eightsleep @eightsleep 🤤🤤🤤🤤🤤 honestly game changer',
		verified: true,
		url: 'https://twitter.com/BFATHIEH/status/1489398168361914370',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1541552092103380993/8dyG2pAG_normal.jpg',
		name: 'Samir Kaul',
		handle: 'SamirKaul1',
		unixDateMS: 1643984387000,
		platform: 'twitter',
		content: 'My respiratory rate down 7% using @eightsleep',
		verified: false,
		url: 'https://twitter.com/SamirKaul1/status/1489604602269896706',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1566894252684091395/rXWMcJ5q_normal.jpg',
		name: 'ericosiu',
		handle: 'ericosiu',
		unixDateMS: 1644096982000,
		platform: 'twitter',
		content: '@Dmurr68 @eightsleep No brainer. I dont have it right now and i feel noticeably worse.',
		verified: true,
		url: 'https://twitter.com/ericosiu/status/1490076859542999045',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1234859826158743552/57DkMD7n_normal.jpg',
		name: 'Bob Freier',
		handle: 'bobfreier',
		unixDateMS: 1644189746000,
		platform: 'twitter',
		content: 'Yes, I’m excited to go down to Florida for vacation. But at the same time stressing that I won’t have my @eightsleep when I’m down there.',
		verified: false,
		url: 'https://twitter.com/bobfreier/status/1490465942542901259',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1585332383502372864/H1HKhMNg_normal.jpg',
		name: 'Nikita Shamgunov',
		handle: 'nikitabase',
		unixDateMS: 1639157306000,
		platform: 'twitter',
		content: 'Bought @eightsleep. Thank you @m_franceschetti. This has been life changing.',
		verified: true,
		url: 'https://twitter.com/nikitabase/status/1469358359010705410',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1500962201586253830/c54T_jUt_normal.jpg',
		name: 'Ndamukong Suh',
		handle: 'NdamukongSuh',
		unixDateMS: 1638882488000,
		platform: 'twitter',
		content: "Do we feel like my guy from @apexoptimizers gets my vibe right? <br/><br/>At a minimum, we're both clearly fans of @eightsleep. ",
		verified: false,
		url: 'https://twitter.com/NdamukongSuh/status/1468205688828706825',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FGAdFHqWYAQF17B.png',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1413162073354383371/7Kv17LAN_normal.jpg',
		name: 'Frank Dale',
		handle: 'frankcdale',
		unixDateMS: 1641945973000,
		platform: 'twitter',
		content: '@chris_herd Try @eightsleep. Game changer for me.',
		verified: false,
		url: 'https://twitter.com/frankcdale/status/1481054874406170629',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1559220981742424065/Ek08ry-E_normal.jpg',
		name: 'Danika gillian',
		handle: 'danika_gillian',
		unixDateMS: 1640740315000,
		platform: 'twitter',
		content:
			'@eightsleep @m_franceschetti I may be doing this wrong, sleeping that hot in Texas, but I will never again be without an Eight Sleep!   it is without a doubt the best investment in my health I made in 2021!  Love what yall are doing! 👍😊🔥',
		verified: false,
		url: 'https://twitter.com/danika_gillian/status/1475997978791972867',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/3532609754/802ea9940985853c3633d540f0428dd9_normal.jpeg',
		name: 'Tim Ryan',
		handle: 'ryantnt',
		unixDateMS: 1641434214000,
		platform: 'twitter',
		content: 'Oh baby! A preheated @eightsleep bed is a complete game changer! Life will never be the same.',
		verified: true,
		url: 'https://twitter.com/ryantnt/status/1478908400931061764',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1313100473520607232/ax-JOxeA_normal.jpg',
		name: 'Stephen Keenan',
		handle: '_stephenkeenan',
		unixDateMS: 1641330062000,
		platform: 'twitter',
		content: "@mollymaloofmd @ouraring @Levels Also nothing like a good night's sleep @eightsleep",
		verified: false,
		url: 'https://twitter.com/_stephenkeenan/status/1478471557852901379',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1644000570468859905/JjyHThYa_normal.jpg',
		name: 'they call me 𝙻𝚞𝚌𝚔𝚢',
		handle: 'markluck',
		unixDateMS: 1641306994000,
		platform: 'twitter',
		content: '@m_franceschetti @bchesky classic. seriously, anyone with an airbnb should be listing their eightsleep if they have one',
		verified: false,
		url: 'https://twitter.com/markluck/status/1478374802092175370',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1356450053062201345/TxQN3Xy6_normal.jpg',
		name: 'Ian',
		handle: 'IanMcMilan',
		unixDateMS: 1641259368000,
		platform: 'twitter',
		content:
			"@MattyBuilds @a_zatarain @eightsleep @whoop Incredible to see this.<br/><br/> The WORST part about getting an 8sleep is it ruins all other mattresses forever. <br/><br/>i need an 8sleep shirt for trips or something.... I'm sure @m_franceschetti and @a_zatarain are cooking up something...",
		verified: true,
		url: 'https://twitter.com/IanMcMilan/status/1478175046057091075',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1678815431451381760/c1hXiplk_normal.jpg',
		name: 'Brian Harrington',
		handle: 'BrainHarrington',
		unixDateMS: 1642719779000,
		platform: 'twitter',
		content:
			'@dillon_andrew_ Bro this is not even a question at all, the answer is @eightsleep and use my ref link<br/><br/>Hit @m_franceschetti or their live chat with any questions you have<br/><br/>',
		verified: true,
		url: 'https://twitter.com/BrainHarrington/status/1484300451528921089',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1536345370736726016/Zjog5dEy_normal.jpg',
		name: 'Iliana Roman',
		handle: 'ilianaRmn',
		unixDateMS: 1642713245000,
		platform: 'twitter',
		content: 'I’ve been giving my furnace a break and just using @eightsleep as a more sustainable heating solution for cold winter nights.',
		verified: false,
		url: 'https://twitter.com/ilianaRmn/status/1484273045611048960',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1172592811885002752/V01ol79g_normal.jpg',
		name: 'Trevor Whyte',
		handle: 'trevorwhyte7',
		unixDateMS: 1642639674000,
		platform: 'twitter',
		content:
			"Finally got my @eightsleep pod and so far its better than advertised. <br/><br/>last night got home late from soccer. normally I am too warm to sleep so used the cooling mode to get cooled off. it worked unbelievable well<br/><br/>challenge to the rest of you what's your best pod hack? ",
		verified: false,
		url: 'https://twitter.com/trevorwhyte7/status/1483964466282651650',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1438373437337219076/Wugy4CjI_normal.jpg',
		name: 'Morgan Barrett',
		handle: 'MorganBarrettX',
		unixDateMS: 1704397807000,
		platform: 'twitter',
		content: '@eightsleep is the goat - so grateful for the customer support <br/><br/>I was dreading getting help but it was 100% the best experience I’ve ever had',
		verified: false,
		url: 'https://twitter.com/MorganBarrettX/status/1742996851576778938',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1654565837666172929/rX7tvAXI_normal.jpg',
		name: 'Austin Schlessinger',
		handle: 'austinschless',
		unixDateMS: 1642612773000,
		platform: 'twitter',
		content: '@schethna @BioloopSleep for sleep coaching<br/>@eightsleep for mattress<br/>@whoop / @ouraring for sleep tracking<br/><br/>Apple Watch is also okay',
		verified: true,
		url: 'https://twitter.com/austinschless/status/1483851638133698569',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1663744547820560385/wRqPPl0q_normal.jpg',
		name: 'Jean',
		handle: 'Jean_Maes_1994',
		unixDateMS: 1642583155000,
		platform: 'twitter',
		content:
			"So it's been about a week with @eightsleep and people wanted to hear my thoughts on it so here it goes. <br/>I feel like it is the solution for couples that like to sleep in different climates. My SO likes to sleep hot, I like to sleep cold. This tech is the solution for that. ",
		verified: false,
		url: 'https://twitter.com/Jean_Maes_1994/status/1483727407987232768',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FJdCNh_WQAMw3-m.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1617947930715123712/GFyYARIR_normal.jpg',
		name: 'Charlie Grinnell 🔎',
		handle: 'CharlieGrinnell',
		unixDateMS: 1642577992000,
		platform: 'twitter',
		content:
			'@theSamParr The only thing that matters is that whatever setup you decide on, throw an @eightsleep on that thing and you’ll be all set. It has been a game changer for my sleep - both according to me and my @ouraring scores. 👍🏼',
		verified: false,
		url: 'https://twitter.com/CharlieGrinnell/status/1483705755752558594',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/978132096907599875/weBDVZH-_normal.jpg',
		name: 'Jason McMinn',
		handle: 'jasonmcminn',
		unixDateMS: 1642525185000,
		platform: 'twitter',
		content: '@ShervinShares @m_franceschetti @michelletandler @eightsleep Tesla. It is a radical step-change in driving that no one understands until they drive. Same with Eight Sleep.',
		verified: false,
		url: 'https://twitter.com/jasonmcminn/status/1483484266210025474',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1478610722087075841/YgNdgirz_normal.jpg',
		name: 'f boyer',
		handle: 'mrfboyer',
		unixDateMS: 1642452201000,
		platform: 'twitter',
		content:
			'@theSamParr I bought a @Casper after hearing the founder talk at @hustlecon and then bought an @eightsleep mattress cover for it on Cyber Monday - we prefer the Casper to our old Tempurpedic but the eight topper is a game changer!<br/>@RH sheets/duvet/down comforter are as good as I’ve found.',
		verified: false,
		url: 'https://twitter.com/mrfboyer/status/1483178147931590657',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1649601481392406535/OE_5h6c5_normal.jpg',
		name: 'Joe Du Bey',
		handle: 'joedubeytldr',
		unixDateMS: 1642293316000,
		platform: 'twitter',
		content: '@m_franceschetti @lucy_guo @rsg @FrancisSuarez thanks, matteo + lucy! that was fast<br/><br/>btw, @m_franceschetti , just got the @eightsleep pod for my mattress. game changer 🙌',
		verified: true,
		url: 'https://twitter.com/joedubeytldr/status/1482511739115782145',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1646531544071405572/fttMc_4J_normal.jpg',
		name: '🌶️👑 mags.eth ⬇️⬇️📍✈️',
		handle: 'magdalenakala',
		unixDateMS: 1642351991000,
		platform: 'twitter',
		content:
			'@jarridvtingle Oh my sleep stack is intense:<br/>• @priverevaux blue-light blocking glasses after 6pm<br/>• @house__of__wise CBD + melatonin sleep drops<br/>• @eightsleep mattress<br/>• Lunya sleep mask<br/>• @whoop sleep tracking',
		verified: false,
		url: 'https://twitter.com/magdalenakala/status/1482757836312567825',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1646531544071405572/fttMc_4J_normal.jpg',
		name: '🌶️👑 mags.eth ⬇️⬇️📍✈️',
		handle: 'magdalenakala',
		unixDateMS: 1642346654000,
		platform: 'twitter',
		content: '@jarridvtingle Oh 100%! Also Eight Sleep waking me up with temperature &gt; alarm clock ringing',
		verified: false,
		url: 'https://twitter.com/magdalenakala/status/1482735452851425289',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/801465548387262465/enn_m4rT_normal.jpg',
		name: 'Michaël Ortali 👨🏼‍💻🇫🇷🇺🇲',
		handle: 'xethorn',
		unixDateMS: 1642354905000,
		platform: 'twitter',
		content:
			"1. Overall<br/><br/>We're keeping it! I haven't had a single night where I sweat, and this helped me stay asleep.<br/><br/>If you live in Miami, between humidity and heat, you want to be able to regulate your body temperature to comfortably sleep.<br/><br/>It does just that, so we're happy.",
		verified: false,
		url: 'https://twitter.com/xethorn/status/1482770061345509377',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1594528532130332673/OeQzckmN_normal.jpg',
		name: 'Kourosh',
		handle: 'kouroshbaloo',
		unixDateMS: 1642387504000,
		platform: 'twitter',
		content: '@theSamParr @HelixSleep mattress + @eightsleep pod pro cover',
		verified: true,
		url: 'https://twitter.com/kouroshbaloo/status/1482906789448323078',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/718598294323073024/qu7IlBA8_normal.jpg',
		name: 'Billy Jalbert',
		handle: 'Mauibilly',
		unixDateMS: 1619577646000,
		platform: 'twitter',
		content: "@fortworthchris @eightsleep Do you track your sleep already? You'll be amazed at what it does for your sleep. 50% increase in deep sleep. You'll be even more productive.",
		verified: false,
		url: 'https://twitter.com/Mauibilly/status/1387235312221360129',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1538481272120541185/jWQ8RfMY_normal.jpg',
		name: 'Janis Krums (🥃,🦉)',
		handle: 'jkrums',
		unixDateMS: 1620036518000,
		platform: 'twitter',
		content: '@HarryStebbings @ouraring Have you tried the @eightsleep bed yet? Has been a game changer for me.',
		verified: true,
		url: 'https://twitter.com/jkrums/status/1389159959615639554',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/3626578923/16a123abc76f91319dcaf57cc206b0e8_normal.jpeg',
		name: 'Ben Morris',
		handle: 'benmorris',
		unixDateMS: 1619900622000,
		platform: 'twitter',
		content: '@louisanicola_ Not good but eight hours with @eightsleep doubles your potential to achieve PRs for an any activity. #performance',
		verified: false,
		url: 'https://twitter.com/benmorris/status/1388589969703198720',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/378800000417395175/d92544db943bacc7eb7ae65043bd1a4b_normal.jpeg',
		name: 'Kellie Wells Brinkley OLY',
		handle: 'KellieWellz',
		unixDateMS: 1618930104000,
		platform: 'twitter',
		content: 'Brooooooo my sleep life has changed so much since I got a @eightsleep pod!!!!! I can compete with myself to sleep better!!!!!',
		verified: false,
		url: 'https://twitter.com/KellieWellz/status/1384519322379247622',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1544831972874264578/ZUDVkvMZ_normal.jpg',
		name: 'Justin Kadis',
		handle: 'jkadis',
		unixDateMS: 1642264226000,
		platform: 'twitter',
		content: '@zoescaman My fix was getting an @eightsleep Pod Pro Cover for my mattress. Perfect temp under the covers every night',
		verified: false,
		url: 'https://twitter.com/jkadis/status/1482389726502043650',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1490367570406678528/Le9wA-nE_normal.jpg',
		name: 'Vatsal Singhal',
		handle: 'vatsalsinghal',
		unixDateMS: 1642230968000,
		platform: 'twitter',
		content: '@ksprashu @kunalb11 @eightsleep This is the thermo regulation unit of @eightsleep. It controls the temperature of the bed to get you a better sleep. Really powerful device.',
		verified: true,
		url: 'https://twitter.com/vatsalsinghal/status/1482250228808171523',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/978132096907599875/weBDVZH-_normal.jpg',
		name: 'Jason McMinn',
		handle: 'jasonmcminn',
		unixDateMS: 1642209910000,
		platform: 'twitter',
		content:
			'@a_zatarain @wodapalooza @eightsleep @FrancisSuarez I went to bed an hour early last night. About 10 minutes after getting in bed, my scheduled Eight Sleep cooling kicked in and I could the coolness crawling on my back. Funky feeling 😍😍',
		verified: false,
		url: 'https://twitter.com/jasonmcminn/status/1482161905766572032',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1363316601974644736/JKdIckKh_normal.jpg',
		name: 'Matthew Dellavedova',
		handle: 'matthewdelly',
		unixDateMS: 1642207141000,
		platform: 'twitter',
		content: '.@EightSleep is a game changer 💤📈 ',
		verified: true,
		url: 'https://twitter.com/matthewdelly/status/1482150291617513473',
		categories: ['Familiar faces'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1511452641880072200/fy9IxNlK_normal.jpg',
		name: 'Jay Kapoor',
		handle: 'JayKapoorNYC',
		unixDateMS: 1642204254000,
		platform: 'twitter',
		content:
			'@nihalmehta @eightsleep Set-up takes a little bit of time but the ability to cool the bed down when it’s time for bed and warm it up to wake in the morning has helped me sleep MUCH better<br/><br/>Plus dual-side temp control helps bc I’m a volcano/furnace when I sleep and @prer_sing is a normal human person.',
		verified: false,
		url: 'https://twitter.com/JayKapoorNYC/status/1482138185496944643',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1421932092649312268/1LLJY4nM_normal.jpg',
		name: 'GigaChad',
		handle: 'WallaceCrypto',
		unixDateMS: 1642202986000,
		platform: 'twitter',
		content: '@JayKapoorNYC @m_franceschetti @eightsleep I am am dialed in you could say. So much energy! ',
		verified: true,
		url: 'https://twitter.com/WallaceCrypto/status/1482132866536513536',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FJGX_EhXMAA8YcT.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1542499532184993793/JAcnFSvR_normal.jpg',
		name: 'Shervin Shares',
		handle: 'ShervinShares',
		unixDateMS: 1642189558000,
		platform: 'twitter',
		content: '@LakshMody @ouraring @MyFitnessPal @Levels @eightsleep @katietypea If you already have a mattress you like get the Pod Pro Cover!',
		verified: true,
		url: 'https://twitter.com/ShervinShares/status/1482076545552367618',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1519108511506046976/Hns7pWP__normal.jpg',
		name: 'Alex Iskold | 2048.vc',
		handle: 'alexiskold',
		unixDateMS: 1642204246000,
		platform: 'twitter',
		content: '@nihalmehta @JayKapoorNYC @eightsleep Do it',
		verified: true,
		url: 'https://twitter.com/alexiskold/status/1482138151338528776',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1511452641880072200/fy9IxNlK_normal.jpg',
		name: 'Jay Kapoor',
		handle: 'JayKapoorNYC',
		unixDateMS: 1642202648000,
		platform: 'twitter',
		content:
			'Only downside to having a @eightsleep is that people who don’t have one will look at me funny when I casually say<br/><br/>“So my bed texted me this morning to tell me I was feeling well rested”<br/><br/>I concur. Thx bed.',
		verified: false,
		url: 'https://twitter.com/JayKapoorNYC/status/1482131448039673865',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1664417891788402690/-fEQNRKt_normal.jpg',
		name: 'Dominic Carter',
		handle: 'DominicCarterLA',
		unixDateMS: 1642429063000,
		platform: 'twitter',
		content: '@JHawkShoots @Purple @Casper @nectar Toss @eightsleep in there',
		verified: false,
		url: 'https://twitter.com/DominicCarterLA/status/1483081103015510016',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1668259073664450560/2fucW__T_normal.jpg',
		name: 'CryptoJustin',
		handle: 'TheCryptoCatGuy',
		unixDateMS: 1642484929000,
		platform: 'twitter',
		content: '@PatriciaIrigo10 Check out @eightsleep. It could help give you better sleep and health.',
		verified: false,
		url: 'https://twitter.com/TheCryptoCatGuy/status/1483315421977755649',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1599954387924701184/RFplYXls_normal.jpg',
		name: 'Chase Flanery',
		handle: 'chase_flanery',
		unixDateMS: 1642527681000,
		platform: 'twitter',
		content:
			'@jasonmcminn @m_franceschetti @ShervinShares @michelletandler @eightsleep I added an additional life hack to my sleep besides the @eightsleep. Try sleeping with a gravity blanket on top of your sheets. I’m forever spoiled by the best sleep in the world lol',
		verified: true,
		url: 'https://twitter.com/chase_flanery/status/1483494735922663426',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/978132096907599875/weBDVZH-_normal.jpg',
		name: 'Jason McMinn',
		handle: 'jasonmcminn',
		unixDateMS: 1632972463000,
		platform: 'twitter',
		content: '@michelletandler Just get an @eightsleep and your sleep quality will be forever changed. Best thing I have bought in 5 years.',
		verified: false,
		url: 'https://twitter.com/jasonmcminn/status/1443417246001295362',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1591239817404338176/24WjaBNZ_normal.jpg',
		name: 'Chandler Quintin',
		handle: 'ChandlerQuintin',
		unixDateMS: 1642769859000,
		platform: 'twitter',
		content: '@AlissaWalsh21 @MattWalshBlog Just get an @eightsleep topper and make your side 110 and his 55. Worth the $. Room can be 40 if your side of the bed is toasty!',
		verified: false,
		url: 'https://twitter.com/ChandlerQuintin/status/1484510502051094528',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1547787118243561478/PxWzJo1H_normal.jpg',
		name: 'Pomp 🌪',
		handle: 'APompliano',
		unixDateMS: 1641301637000,
		platform: 'twitter',
		content: '@m_franceschetti @davidasinclair This is awesome. Eventually everyone gets an Eight Sleep',
		verified: true,
		url: 'https://twitter.com/APompliano/status/1478352334656790550',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1544387422728859649/zwvKGnpw_normal.jpg',
		name: 'AthletesAndAssets™',
		handle: 'AthletesAssets',
		unixDateMS: 1641407020000,
		platform: 'twitter',
		content: 'New follow of the day. Sleep is so underrated and is arguably the best performance enhancer. Excited to see all that @eightsleep is building 💤 ',
		verified: false,
		url: 'https://twitter.com/AthletesAssets/status/1478794342005293063',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FIW7najVkAUHBuV.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1633176372318289920/ozoj-v0J_normal.jpg',
		name: 'NickH 🟧 ⛏️',
		handle: 'hash_bender',
		unixDateMS: 1641706399000,
		platform: 'twitter',
		content: '@BroskiNakamoto @lopp I sleep 10x better with my eight sleep.  I’m a pretty bad sleeper so anything I can do to improve will pay dividends in my twilight years',
		verified: true,
		url: 'https://twitter.com/hash_bender/status/1480050029825966080',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1409869971090444290/SQZNoBJi_normal.jpg',
		name: 'Brent KT',
		handle: 'brent_kt',
		unixDateMS: 1642078390000,
		platform: 'twitter',
		content: 'Good morning. This was necessary, and almost never happens 🤣<br/><br/>Two months in and loving my @eightsleep <br/><br/>@m_franceschetti you have a great product ',
		verified: true,
		url: 'https://twitter.com/brent_kt/status/1481610272062197763',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FI-8sEEWQAEKryu.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1580188498748968961/L6lebFCL_normal.jpg',
		name: 'Louisa Nicola',
		handle: 'louisanicola_',
		unixDateMS: 1642006801000,
		platform: 'twitter',
		content:
			'Yesterday on a podcast I spoke about the difference between thermal temperature and ambient temperature as it relates to sleep fitness. <br/><br/>I spoke about why I chose the @eightsleep mattress in early 2020. Put simply; the design and science behind this sleep tool is unmatched.',
		verified: true,
		url: 'https://twitter.com/louisanicola_/status/1481310005777223684',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1499489445472616458/qKus9uDY_normal.jpg',
		name: 'arnav',
		handle: 'a7neo',
		unixDateMS: 1641955837000,
		platform: 'twitter',
		content:
			'Seeing everyone tweet about buying an @eightsleep pod makes me happy<br/><br/>Only recently been following @m_franceschetti journey with his team since hearing his story on my favorite podcast @thequestmedia by @justinkan <br/><br/>Idk I just love the company’s mission<br/><br/>Such a cool product',
		verified: false,
		url: 'https://twitter.com/a7neo/status/1481096249281593347',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1591229977168465921/vWTXbKku_normal.jpg',
		name: 'Kallaway',
		handle: 'kanekallaway',
		unixDateMS: 1642037934000,
		platform: 'twitter',
		content: '@sundaydrips Also @eightsleep for health and wellness!',
		verified: true,
		url: 'https://twitter.com/kanekallaway/status/1481440586015985664',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1504223351241277440/riN8Gb2K_normal.jpg',
		name: 'hus',
		handle: 'Hus_Gets_Dollas',
		unixDateMS: 1641905450000,
		platform: 'twitter',
		content: 'We ride at dawn, ready to conquer the world thanks to @eightsleep, carpe denim. ',
		verified: false,
		url: 'https://twitter.com/Hus_Gets_Dollas/status/1480884908952363008',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FI0o-TdXIAAFBmy.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1584868536811495424/rMNXT5pl_normal.jpg',
		name: 'Patrick OShaughnessy',
		handle: 'patrick_oshag',
		unixDateMS: 1641950393000,
		platform: 'twitter',
		content: '@chris_herd Eight sleep',
		verified: true,
		url: 'https://twitter.com/patrick_oshag/status/1481073413724721157',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1527379518154887181/KQ_N4DsR_normal.jpg',
		name: 'Tom Scopazzi',
		handle: 'TomScopazzi',
		unixDateMS: 1641997899000,
		platform: 'twitter',
		content: '@chris_herd For yourself: @eightsleep ProPod cover over whatever mattress you prefer. We went with the Casper Nova Hybrid.<br/><br/>For a STR property: ',
		verified: false,
		url: 'https://twitter.com/TomScopazzi/status/1481272670289563648',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1566894252684091395/rXWMcJ5q_normal.jpg',
		name: 'ericosiu',
		handle: 'ericosiu',
		unixDateMS: 1642005734000,
		platform: 'twitter',
		content: '@chris_herd @eightsleep',
		verified: true,
		url: 'https://twitter.com/ericosiu/status/1481305529318354946',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1399460671713120258/CeLq2c5-_normal.jpg',
		name: 'Lance',
		handle: 'LJambeck',
		unixDateMS: 1641943396000,
		platform: 'twitter',
		content: '@chris_herd @adamscrabble @eightsleep',
		verified: false,
		url: 'https://twitter.com/LJambeck/status/1481044064954896384',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1587931405278973953/BojuvUe5_normal.jpg',
		name: 'Matt Galligan',
		handle: 'mg',
		unixDateMS: 1641945842000,
		platform: 'twitter',
		content: '@chris_herd Eight Sleep',
		verified: true,
		url: 'https://twitter.com/mg/status/1481054324059000834',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1569837782620688384/00iZoTJN_normal.jpg',
		name: 'Joshua Bonhotal',
		handle: 'jbonhotal',
		unixDateMS: 1641323094000,
		platform: 'twitter',
		content:
			'@m_franceschetti @bchesky @eightsleep They love it!<br/><br/>A lot of folks who were not previously aware of your brand that message us saying how cool (literally &amp; figuratively) it is.',
		verified: true,
		url: 'https://twitter.com/jbonhotal/status/1478442329857880067',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1449927820847693826/qR22U9N7_normal.jpg',
		name: 'Dimitri Dadiomov',
		handle: 'dadiomov',
		unixDateMS: 1641084035000,
		platform: 'twitter',
		content:
			'This is wild. With @eightsleep we’re now collecting data on sleep &amp; health across people, and learning from it.<br/><br/>But unlike other quantified self devices, you also can’t forget to put it on, or stop using it. It’s your bed.<br/><br/>That combo makes it an amazing early warning system: ',
		verified: true,
		url: 'https://twitter.com/dadiomov/status/1477439642861400066',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1495397696730972162/xIY5dnq8_normal.jpg',
		name: 'Cody Plofker',
		handle: 'codyplof',
		unixDateMS: 1641095602000,
		platform: 'twitter',
		content:
			'@laurenakleinman @eightsleep Yup. My wife was a huge skeptic and only allowed me to get it because of the guarantee. She was convinced we would return it. Now she raves about it and tells everyone to get one.',
		verified: true,
		url: 'https://twitter.com/codyplof/status/1477488160301064197',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/829543250994266112/J7gFzLy5_normal.jpg',
		name: 'Candice Vega',
		handle: 'candiceskitchen',
		unixDateMS: 1641091476000,
		platform: 'twitter',
		content: 'The data from @eightsleep is the best part about having it. I woke up sick today. #datadoesntlie ',
		verified: false,
		url: 'https://twitter.com/candiceskitchen/status/1477470855911383047',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FIEH6XyWUA8fwkH.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1618825758956294144/xTZTeGPf_normal.jpg',
		name: 'Jake',
		handle: 'Just_Lew_it',
		unixDateMS: 1640801299000,
		platform: 'twitter',
		content:
			'@jeff_zifrony @eightsleep @whoop @whoop &amp; @eightsleep pricing is quite different. However if you are looking for a new mattress I can attest - best purchase I made in 2021. Well worth it',
		verified: true,
		url: 'https://twitter.com/Just_Lew_it/status/1476253763899908097',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1662177323276222470/-2iz2h7l_normal.jpg',
		name: 'Amanda Goetz',
		handle: 'AmandaMGoetz',
		unixDateMS: 1641044265000,
		platform: 'twitter',
		content: 'Started 2022 with a celery juice and 100% @eightsleep score. <br/><br/>Ready to make this year the healthiest one yet. ',
		verified: true,
		url: 'https://twitter.com/AmandaMGoetz/status/1477272837538951172',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FIBT0QGWYAYs5v6.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1558109948072153088/nIAs1cQi_normal.jpg',
		name: 'Dennis kioken@mastodon.xyz',
		handle: 'kioken',
		unixDateMS: 1640734871000,
		platform: 'twitter',
		content: '@m_franceschetti @eightsleep Thank you! My life has forever changed thanks to you and your team!',
		verified: false,
		url: 'https://twitter.com/kioken/status/1475975143390433283',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1019628812483604480/965ymS27_normal.jpg',
		name: 'Matt Haggman 🇺🇦',
		handle: 'matthaggman',
		unixDateMS: 1640732889000,
		platform: 'twitter',
		content: '@bunsen @eightsleep Totally agree, Auston. Been using Eight Sleep this past year and love it. Made such a difference.',
		verified: false,
		url: 'https://twitter.com/matthaggman/status/1475966830967676928',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1287042110412271619/hv5MHqGx_normal.jpg',
		name: 'Chris Young',
		handle: 'Homekitgeek',
		unixDateMS: 1640716403000,
		platform: 'twitter',
		content: 'Officially a Sleep Fitness Champion thanks to my @eightsleep! Focusing on sleep is still one of the best things I’ve done to improve my life! 👑',
		verified: false,
		url: 'https://twitter.com/Homekitgeek/status/1475897682631798793',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FHU7uBiUUAIHo78.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1526614458545491968/bwXqAC14_normal.jpg',
		name: 'Jeff Burke ⠕',
		handle: 'Jeff_Burke14',
		unixDateMS: 1640720277000,
		platform: 'twitter',
		content:
			'This year, I took a big step forward for my health and began focusing on sleep.<br/><br/>@eightsleep is the ultimate game changer.<br/><br/>I wish I’d had it while playing professional baseball.<br/><br/>So thankful for what @m_franceschetti is building, and I am excited for their future products 🙏 ',
		verified: true,
		url: 'https://twitter.com/Jeff_Burke14/status/1475913933445812226',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FHt_5kiX0AoLOPN.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1605768944467316737/QQ_MTtEZ_normal.jpg',
		name: 'Jack Altman',
		handle: 'jaltma',
		unixDateMS: 1638664253000,
		platform: 'twitter',
		content: '@m_franceschetti @rabois I am on night 5 of eight sleep and it’s amazing 💙',
		verified: true,
		url: 'https://twitter.com/jaltma/status/1467290344991518720',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1362511727066107904/yP4o43cz_normal.jpg',
		name: 'SAHIL',
		handle: 'SahilC0',
		unixDateMS: 1641759761000,
		platform: 'twitter',
		content: 'For the first few weeks in my Texas apartment all I had was my<br/>- @eightsleep bed<br/>- @UPLIFTDesk desk<br/>- @getumbrel #bitcoin node<br/><br/>priorities lmao 😅',
		verified: true,
		url: 'https://twitter.com/SahilC0/status/1480273843411308544',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/3401499052/0c16e8ad3d06a1102a8f96f82baed177_normal.jpeg',
		name: 'Ramsey Cox',
		handle: 'RamseyCox22',
		unixDateMS: 1641742752000,
		platform: 'twitter',
		content: 'Get an @eightsleep , the athlete’s bed! ',
		verified: false,
		url: 'https://twitter.com/RamseyCox22/status/1480202502620409857',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1667397726982774789/62cTmpAV_normal.jpg',
		name: 'Francis Plaza',
		handle: 'fplaza',
		unixDateMS: 1641737076000,
		platform: 'twitter',
		content: 'Improving day by day. From low 30’s two months ago, happy to get my highest score yet. Yes, to good sleep @eightsleep ',
		verified: false,
		url: 'https://twitter.com/fplaza/status/1480178698720481280',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FIqmrduVcAE67rq.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1542499532184993793/JAcnFSvR_normal.jpg',
		name: 'Shervin Shares',
		handle: 'ShervinShares',
		unixDateMS: 1641701146000,
		platform: 'twitter',
		content: '@maggijmitchell @eightsleep It’s life changing',
		verified: true,
		url: 'https://twitter.com/ShervinShares/status/1480027997382053893',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1599954387924701184/RFplYXls_normal.jpg',
		name: 'Chase Flanery',
		handle: 'chase_flanery',
		unixDateMS: 1641696011000,
		platform: 'twitter',
		content:
			'@maggijmitchell @m_franceschetti @eightsleep It’s incredible. Highly recommend it for many reasons. Even knowing how many hours you slept last night is crucial to understanding how to structure your days/feelings/emotions',
		verified: true,
		url: 'https://twitter.com/chase_flanery/status/1480006459186298882',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1610135606704603140/t2rQjDVU_normal.jpg',
		name: 'Alex Gonzalez',
		handle: 'AlexGLogics',
		unixDateMS: 1641671112000,
		platform: 'twitter',
		content: '@BobMorrisJr I use to have Purple but switched to @eightsleep. Worth every penny.',
		verified: true,
		url: 'https://twitter.com/AlexGLogics/status/1479902024816893956',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1536424105238441984/2uVVXJHl_normal.jpg',
		name: 'bk',
		handle: 'brandonkrull_',
		unixDateMS: 1641624536000,
		platform: 'twitter',
		content: 'my wife consistently chooses her @eightsleep stats over cuddling #marriage',
		verified: false,
		url: 'https://twitter.com/brandonkrull_/status/1479706671068835844',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1554827587523776514/FPUKHovB_normal.jpg',
		name: 'Matt Blank',
		handle: 'MattyBuilds',
		unixDateMS: 1641516404000,
		platform: 'twitter',
		content:
			'🏡 Long-term rentals are on the rise<br/><br/>If I owned properties, I would make the Willy Wonka for wellness products and charge a premium<br/><br/>Imagine a place stocked with:<br/>😴 @eightsleep @ChooseMuse <br/>🥵 Sauna<br/>🥶 Cold plunge<br/>🥬 @AthleticGreens <br/>🏋️ @onepeloton @tonal<br/><br/>I’d pay for that!',
		verified: true,
		url: 'https://twitter.com/MattyBuilds/status/1479253130537746441',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1634527327777423360/3bizbT0W_normal.jpg',
		name: 'Tim Griffin',
		handle: 'timgriffin77',
		unixDateMS: 1641558158000,
		platform: 'twitter',
		content: 'Shoutout to @eightsleep - I cut desserts from my diet cold turkey 5 days ago and my bed clearly noticed 🙂 ',
		verified: false,
		url: 'https://twitter.com/timgriffin77/status/1479428261163806720',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FIf8KOSXsAANV-H.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1422516562192445446/mCYUL_8-_normal.jpg',
		name: 'Bill Hanks',
		handle: 'hanksb00',
		unixDateMS: 1641306934000,
		platform: 'twitter',
		content: '@m_franceschetti @bchesky Truth.  One of the first thoughts I have when I travel is the loss of nights with my @eightsleep',
		verified: true,
		url: 'https://twitter.com/hanksb00/status/1478374551201402881',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1234859826158743552/57DkMD7n_normal.jpg',
		name: 'Bob Freier',
		handle: 'bobfreier',
		unixDateMS: 1642372113000,
		platform: 'twitter',
		content: '@a_zatarain @m_franceschetti @eightsleep It’s been only 3 nights but I already see a huge difference. If you ever need a good headhunter, I’ll help.',
		verified: false,
		url: 'https://twitter.com/bobfreier/status/1482842236089946117',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1596150277450653696/yQKnOmbF_normal.jpg',
		name: 'Omar Morales',
		handle: 'OmarMora1es',
		unixDateMS: 1642355022000,
		platform: 'twitter',
		content: 'An @eightsleep comes to mind @m_franceschetti',
		verified: true,
		url: 'https://twitter.com/OmarMora1es/status/1482770548904054787',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1673924664652091394/Mdeqd-Tp_normal.jpg',
		name: 'Daniel Scrivner ✦',
		handle: 'DanielScrivner',
		unixDateMS: 1608610388000,
		platform: 'twitter',
		content:
			'Here’s something I’d love for hotels to adopt.<br/><br/>Offer a Performance Suite for $50+ extra per night that includes:<br/><br/>- @eightsleep mattress<br/>- no blue light in room<br/>- blackout curtains<br/>- sound insulated walls<br/>- set of bands and weights from<br/>@RogueFitness <br/><br/>I’d pay every time.',
		verified: true,
		url: 'https://twitter.com/DanielScrivner/status/1341235297246466054',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1490367570406678528/Le9wA-nE_normal.jpg',
		name: 'Vatsal Singhal',
		handle: 'vatsalsinghal',
		unixDateMS: 1642230347000,
		platform: 'twitter',
		content: '@kunalb11 Average resting heart rate down by ~10% , and HRV up by ~8%! Nothing better than passive improvement to health. ',
		verified: true,
		url: 'https://twitter.com/vatsalsinghal/status/1482247626896850946',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FJIAWyUaUAM6ott.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1523714737216778240/Zw9K6_cO_normal.jpg',
		name: 'Dale Mueller',
		handle: 'dalemueller',
		unixDateMS: 1640720241000,
		platform: 'twitter',
		content: 'Ahhh quantified sleeping with @eightsleep . I am looking forward to the AI to take me to new levels next year. ',
		verified: false,
		url: 'https://twitter.com/dalemueller/status/1475913781712543745',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FHt_wtNVkAEZav4.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1665029530023788544/sZKrlN3z_normal.jpg',
		name: 'Jaden Feddock',
		handle: 'JadenFeddock',
		unixDateMS: 1640717026000,
		platform: 'twitter',
		content: "@m_franceschetti @eightsleep Thanks Matteo! Great tool you and your team have built here. I've recommended it to a few friends as well!",
		verified: true,
		url: 'https://twitter.com/JadenFeddock/status/1475900299168522240',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1555310621016461313/Tu87PLby_normal.jpg',
		name: 'Steven Galanis',
		handle: 'Mr312',
		unixDateMS: 1640441888000,
		platform: 'twitter',
		content: 'No better gift than waking up on Christmas morning after my best night of sleep all year 🎄🎅🏼🎁  @eightsleep @m_franceschetti ',
		verified: true,
		url: 'https://twitter.com/Mr312/status/1474746286167494668',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FHdZ7nVXoAMeQXY.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1612291013711642624/_XPFP-PP_normal.jpg',
		name: 'Apeistotle ⚡️',
		handle: 'apeistotle',
		unixDateMS: 1640444138000,
		platform: 'twitter',
		content:
			'2021 Top 5 Christmas Gifts:<br/>1. #Bitcoin <br/>2. @eightsleep 🛌 <br/>3. @Hyperice Normatec🦵💆‍♂️<br/>4. @oculus 🚀<br/>5. @solana NFTs<br/><br/>Anything else you fell in love with this year?',
		verified: true,
		url: 'https://twitter.com/apeistotle/status/1474755720964984837',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/840101055098568704/DrhYU232_normal.jpg',
		name: 'Omid',
		handle: 'omid',
		unixDateMS: 1640445025000,
		platform: 'twitter',
		content:
			"@zebulgar @kambojsimran @jasonljin @lucy_guo @Austen @eightsleep @levelshealth @whoop @ouraring I have all of the damn toys, the shortcoming with the 8sleep (which I love) is that it's not on you all day -- a lot happens during the day, stressors, activity, etc that are useful for the whole picture of health -- to Austen's question, 8sleep + either Oura or Whoop",
		verified: true,
		url: 'https://twitter.com/omid/status/1474759443833253888',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1599954387924701184/RFplYXls_normal.jpg',
		name: 'Chase Flanery',
		handle: 'chase_flanery',
		unixDateMS: 1640288819000,
		platform: 'twitter',
		content: '@slye @eightsleep mattress and Pod. I’m a really hot sleeper and the cooling features of the mattress are incredibly helpful',
		verified: true,
		url: 'https://twitter.com/chase_flanery/status/1474104266923016192',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/924621709088514048/4J_5Bmcy_normal.jpg',
		name: 'Madushan Gamage',
		handle: 'madushangmge',
		unixDateMS: 1641014734000,
		platform: 'twitter',
		content: 'Probably one of the best purchases in 2021. @eightsleep ',
		verified: false,
		url: 'https://twitter.com/madushangmge/status/1477148974620372996',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FH_jKQJVcAAw4ks.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1650512809740861442/2Rdhlvwu_normal.jpg',
		name: 'Noah Loffredo',
		handle: 'LoffredoNoah',
		unixDateMS: 1640531622000,
		platform: 'twitter',
		content:
			'@TravisMcGimsey @eightsleep Absolute game changer if you sleep extra hot or cold, but also just an incredible mattress for neutral sleepers too. <br/><br/>Worst part of owning one is you never want to travel without it…',
		verified: false,
		url: 'https://twitter.com/LoffredoNoah/status/1475122656886378502',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1633604159927730178/uZLfZGdR_normal.jpg',
		name: 'Matt Martin',
		handle: 'MattBMartin',
		unixDateMS: 1640497105000,
		platform: 'twitter',
		content: '@m_franceschetti @eightsleep My @eightsleep mattress has been my recovery multiplier for 3+ years.',
		verified: false,
		url: 'https://twitter.com/MattBMartin/status/1474977881747038210',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1375265268687982592/oLoZ8KQP_normal.jpg',
		name: 'Tate Hackert',
		handle: 'TateHackert',
		unixDateMS: 1640986519000,
		platform: 'twitter',
		content:
			"For reference, before this quarter I was more like 40% consistent. I'd wake up early, but It would eventually catch up to me. <br/><br/>Things that helped:<br/>- @eightsleep (cold + data accountability) <br/>- turning off electronics and grabbing a book<br/>- no eating within 2.5 hours ",
		verified: true,
		url: 'https://twitter.com/TateHackert/status/1477030633159499783',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FH93h8wXMAAEmvE.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1510946956637581316/S5XyDXNp_normal.jpg',
		name: 'Rishin Banker 🏛',
		handle: 'rishbank',
		unixDateMS: 1640879651000,
		platform: 'twitter',
		content:
			'Thanks to @eightsleep for showing me what consistently good sleep feels like. This month, I finally reached that elusive 8 hrs of sleep average (vs. usual 6 hrs) and my body appreciates it! ',
		verified: false,
		url: 'https://twitter.com/rishbank/status/1476582397919600640',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FH3f3T8XoAIOf6O.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/836055429981564928/FOVQOiHQ_normal.jpg',
		name: 'ovo_cody',
		handle: 'C_Ghillie',
		unixDateMS: 1640741864000,
		platform: 'twitter',
		content: '@m_franceschetti @eightsleep Congrats Matteo - I love my @eightsleep',
		verified: false,
		url: 'https://twitter.com/C_Ghillie/status/1476004477744431110',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1371654398355533825/2wx_92pa_normal.jpg',
		name: 'Jesse Kaufman',
		handle: 'chesterknows',
		unixDateMS: 1640762385000,
		platform: 'twitter',
		content: '@MichaelMartocci @eightsleep easy',
		verified: false,
		url: 'https://twitter.com/chesterknows/status/1476090547840294912',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/829543250994266112/J7gFzLy5_normal.jpg',
		name: 'Candice Vega',
		handle: 'candiceskitchen',
		unixDateMS: 1641091476000,
		platform: 'twitter',
		content: 'The data from @eightsleep is the best part about having it. I woke up sick today. #datadoesntlie ',
		verified: false,
		url: 'https://twitter.com/candiceskitchen/status/1477470855911383047',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FIEH6XyWUA8fwkH.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1332134245800751104/6xARs5TS_normal.jpg',
		name: 'Greg Frontiero',
		handle: 'SfwGreg',
		unixDateMS: 1641066880000,
		platform: 'twitter',
		content:
			'@uglyhugh Consistent bedtime<br/>Put your phone in another room. <br/>@eightsleep  <br/>Blackout curtains<br/>Firm mattress-  I like Helix. <br/> breathe-rite strips<br/>Oura ring to track.',
		verified: false,
		url: 'https://twitter.com/SfwGreg/status/1477367691656802310',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1640805591739256832/kMkr5co8_normal.jpg',
		name: 'Saagar Enjeti',
		handle: 'esaagar',
		unixDateMS: 1640544816000,
		platform: 'twitter',
		content:
			"3/ Try to optimize better sleeping conditions w/ @eightsleep or similar device. You don't need a bunch of $ just limit light a few hours before bed and make sleep a priority. Listen to @sleepdiplomat<br/>on @joerogan and @hubermanlab <br/><br/><br/><br/>",
		verified: false,
		url: 'https://twitter.com/esaagar/status/1475177997812609024',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1599482388811337728/Kw5y59bc_normal.jpg',
		name: 'nihal',
		handle: 'nihalmehta',
		unixDateMS: 1641070774000,
		platform: 'twitter',
		content: '@uglyhugh Just bought an @eightsleep hearing amazing things<br/><br/>Otherwise @whoop has def helped us track',
		verified: true,
		url: 'https://twitter.com/nihalmehta/status/1477384024016498689',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1495397696730972162/xIY5dnq8_normal.jpg',
		name: 'Cody Plofker',
		handle: 'codyplof',
		unixDateMS: 1641086267000,
		platform: 'twitter',
		content: '@uglyhugh Eight sleep, whoop, and some cbd.',
		verified: true,
		url: 'https://twitter.com/codyplof/status/1477449007018430467',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1719192076565540864/yU2B9dtt_normal.jpg',
		name: 'Ti Morse',
		handle: 'ti_morse',
		unixDateMS: 1704418604000,
		platform: 'twitter',
		content:
			'.@eightsleep is going to become a massive company. <br/><br/>I’ve been using the eightsleep pod the past few months and my sleep quality has improved by more than 50%. Instead of tossing and turning for hours, I fall asleep within 15-20 minutes. Going to bed is actually something I look… ',
		verified: false,
		url: 'https://twitter.com/ti_morse/status/1743084081406198036',
		categories: ['Everyday love', 'Sleep improvement'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1537628116595748866/csmeWuXq_normal.jpg',
		name: 'Diana Arellano',
		handle: 'diana_overproof',
		unixDateMS: 1641076010000,
		platform: 'twitter',
		content: 'This is crazy. @eightsleep is so ahead of the game. 🤯 ',
		verified: true,
		url: 'https://twitter.com/diana_overproof/status/1477405986226946050',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1375265268687982592/oLoZ8KQP_normal.jpg',
		name: 'Tate Hackert',
		handle: 'TateHackert',
		unixDateMS: 1641073475000,
		platform: 'twitter',
		content: "@SfwGreg @m_franceschetti @uglyhugh @eightsleep +1 to consistency and @eightsleep as a tool to help. <br/><br/>Here's some thoughts I had on sleep: ",
		verified: true,
		url: 'https://twitter.com/TateHackert/status/1477395354152624129',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/863573854110621697/Ptsdnqwx_normal.jpg',
		name: 'Vikram Dharasker™',
		handle: 'VikramD_81',
		unixDateMS: 1640743743000,
		platform: 'twitter',
		content: 'Officially a Sleep Fitness Champion thanks to my @eightsleep! 👑',
		verified: false,
		url: 'https://twitter.com/VikramD_81/status/1476012355217543174',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FHU7uBiUUAIHo78.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1459942498508238848/pAVmWxxi_normal.jpg',
		name: 'ben harris',
		handle: 'benharrisonline',
		unixDateMS: 1640750985000,
		platform: 'twitter',
		content: '@MichaelMartocci @eightsleep',
		verified: true,
		url: 'https://twitter.com/benharrisonline/status/1476042731935862785',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1559220981742424065/Ek08ry-E_normal.jpg',
		name: 'Danika gillian',
		handle: 'danika_gillian',
		unixDateMS: 1640739734000,
		platform: 'twitter',
		content: "If you don't have an Eight Sleep you are missing out!  :)<br/><br/>Clearly, I brought the heat this year. Shoutout to @eightsleep for always keeping me warm 🔥 ",
		verified: false,
		url: 'https://twitter.com/danika_gillian/status/1475995542903996422',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FHU7wc5UcBACJAT.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1686759481378795521/5NlxHFVu_normal.jpg',
		name: 'Dino Becirovic',
		handle: 'dinobecirovic',
		unixDateMS: 1640737985000,
		platform: 'twitter',
		content: 'Top 5% in lowest heart rate. Thank you @eightsleep for all the great rest in 2021 ',
		verified: false,
		url: 'https://twitter.com/dinobecirovic/status/1475988204826202112',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FHU7y7hVcAAvUGr.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1309990687643807744/GREzfA7c_normal.jpg',
		name: 'Steven',
		handle: 'StevenL0ve',
		unixDateMS: 1640722265000,
		platform: 'twitter',
		content: 'Home is where my @eightsleep Pod is 🛌 ❤️',
		verified: false,
		url: 'https://twitter.com/StevenL0ve/status/1475922270044315650',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FHU7x32VEAA3RWB.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1678815431451381760/c1hXiplk_normal.jpg',
		name: 'Brian Harrington',
		handle: 'BrainHarrington',
		unixDateMS: 1640717747000,
		platform: 'twitter',
		content: '@m_franceschetti @eightsleep thanks for a great year, love taking my sleep game seriously',
		verified: true,
		url: 'https://twitter.com/BrainHarrington/status/1475903319943966723',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1668259073664450560/2fucW__T_normal.jpg',
		name: 'CryptoJustin',
		handle: 'TheCryptoCatGuy',
		unixDateMS: 1640717744000,
		platform: 'twitter',
		content:
			'Only had my Eight Sleep for a few months but nothing but positive things to say - the health benefits have been legit!<br/><br/>Always elevating my sleep fitness game with my @eightsleep ♠ 🂡 ',
		verified: false,
		url: 'https://twitter.com/TheCryptoCatGuy/status/1475903308330057729',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FHU7rJ3UcAArxnX.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1475094357116166146/uxZ6B5uk_normal.jpg',
		name: '@R₿FCDallas 🔥',
		handle: 'RealBedfordDal1',
		unixDateMS: 1640717289000,
		platform: 'twitter',
		content: 'Clearly, I brought the heat this year. Shoutout to @eightsleep for always keeping me warm 🔥 ',
		verified: false,
		url: 'https://twitter.com/RealBedfordDal1/status/1475901399359795206',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FHU7wc5UcBACJAT.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1447942170204274690/tJSwdv5Y_normal.jpg',
		name: 'Curtis Autenrieth',
		handle: 'Curtisaa4242',
		unixDateMS: 1640715861000,
		platform: 'twitter',
		content: 'Shoutout to @eightsleep for keeping me cold this year 🧊',
		verified: false,
		url: 'https://twitter.com/Curtisaa4242/status/1475895412909232133',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FHU72fGUYAE0vA4.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1688945603030781952/5rg_06Lf_normal.png',
		name: 'EncinitasImposition.eth Ξ',
		handle: 'jrs25_SD',
		unixDateMS: 1640715761000,
		platform: 'twitter',
		content: 'It’s good to know your strengths…Officially a Sleep Fitness Champion thanks to my @eightsleep! 👑',
		verified: false,
		url: 'https://twitter.com/jrs25_SD/status/1475894991067271168',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FHU7uBiUUAIHo78.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1453810309831946251/i5XlmjqB_normal.jpg',
		name: 'JT ✨',
		handle: 'jtjdt',
		unixDateMS: 1640547902000,
		platform: 'twitter',
		content:
			'@aaronmfisher @eightsleep I have one. It’s pretty damn dope. I can use super heavy blankets like @gravityblankets and not overheat. I sleep way deeper and the insights are nice. Like, not @ouraring accurate, but consistent.',
		verified: false,
		url: 'https://twitter.com/jtjdt/status/1475190939782029313',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1542499532184993793/JAcnFSvR_normal.jpg',
		name: 'Shervin Shares',
		handle: 'ShervinShares',
		unixDateMS: 1640528508000,
		platform: 'twitter',
		content:
			"@TravisMcGimsey @eightsleep yes if you qualify for 2 reasons…<br/>1. hot/cold sleeper (best active cooling/heating device I've ever tried)<br/>2. you want to track &amp; improve your sleep behavior<br/><br/>Eight Sleep Discount Code “SHERVIN” for $$ off: ",
		verified: true,
		url: 'https://twitter.com/ShervinShares/status/1475109597740417035',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/679341407778115584/m5cbg_rt_normal.jpg',
		name: 'Jeffrey Levy',
		handle: 'jfflvy',
		unixDateMS: 1640447320000,
		platform: 'twitter',
		content: '@aaronmfisher @eightsleep Absolutely!',
		verified: false,
		url: 'https://twitter.com/jfflvy/status/1474769067688927236',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1547787118243561478/PxWzJo1H_normal.jpg',
		name: 'Pomp 🌪',
		handle: 'APompliano',
		unixDateMS: 1640453908000,
		platform: 'twitter',
		content: '@aaronmfisher @eightsleep Absolutely',
		verified: true,
		url: 'https://twitter.com/APompliano/status/1474796699268882435',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/676842341492101120/g3cKbc_A_normal.jpg',
		name: 'Andres Ro',
		handle: 'AndresARobelo',
		unixDateMS: 1640452601000,
		platform: 'twitter',
		content: '@aaronmfisher @eightsleep Worth every penny! 💯',
		verified: true,
		url: 'https://twitter.com/AndresARobelo/status/1474791217510690816',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1608281295918096385/D2kh-M28_normal.jpg',
		name: 'Austen Allred',
		handle: 'Austen',
		unixDateMS: 1592797490000,
		platform: 'twitter',
		content:
			'I have smart mattress with sleep sensors (@eightsleep), continuous glucose monitoring (@levelshealth), Apple Watch, looking into ketone reading.<br/><br/>What else am I missing in my “quantity and optimize everything happening to my body” stack?',
		verified: true,
		url: 'https://twitter.com/Austen/status/1274911194374000640',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1542499532184993793/JAcnFSvR_normal.jpg',
		name: 'Shervin Shares',
		handle: 'ShervinShares',
		unixDateMS: 1639167001000,
		platform: 'twitter',
		content: 'My favorite things in 1 photo... @eightsleep @onepeloton @YouTube #NYC ... big things coming in 2022 ',
		verified: true,
		url: 'https://twitter.com/ShervinShares/status/1469399022448558080',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FGRaa8tX0AYmFZp.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1241075459598753798/SjMj2QkC_normal.jpg',
		name: 'Karla Fernandez',
		handle: 'kfernandz',
		unixDateMS: 1641091441000,
		platform: 'twitter',
		content: '@SfwGreg @uglyhugh @eightsleep +1. What Greg said.',
		verified: false,
		url: 'https://twitter.com/kfernandz/status/1477470709282660352',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1668259073664450560/2fucW__T_normal.jpg',
		name: 'CryptoJustin',
		handle: 'TheCryptoCatGuy',
		unixDateMS: 1641057966000,
		platform: 'twitter',
		content:
			'A “very strange” symptom of the new #Covid variant is night sweats. <br/><br/>A @eightsleep cranked to -10 on cool should take care of those for ya. Never sleep warm again (unless you want to go +10).',
		verified: false,
		url: 'https://twitter.com/TheCryptoCatGuy/status/1477330303190507524',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1680758630084411393/z_1JkBvh_normal.jpg',
		name: 'Yesha',
		handle: 'YeshaCallahan',
		unixDateMS: 1641002599000,
		platform: 'twitter',
		content: 'It is 6pm &amp; my @eightsleep mattress is starting to warm up…might not make it to 12.',
		verified: false,
		url: 'https://twitter.com/YeshaCallahan/status/1477098078452662274',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1657448368757682177/ZxM4LsMs_normal.jpg',
		name: 'ᴬᵘˢᵗᴵᴺ',
		handle: 'PushinP100D',
		unixDateMS: 1640798854000,
		platform: 'twitter',
		content: 'Invest in an @eightsleep and you’ll never have to be traumatized by alarm sounds ever again 😇 ',
		verified: false,
		url: 'https://twitter.com/PushinP100D/status/1476243509250297856',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1459942498508238848/pAVmWxxi_normal.jpg',
		name: 'ben harris',
		handle: 'benharrisonline',
		unixDateMS: 1640751167000,
		platform: 'twitter',
		content: '@MichaelMartocci @eightsleep Dude it’s legit. Best purchase ever. However I’m excited to see returns on minting some @boblnft 🤣🤣',
		verified: true,
		url: 'https://twitter.com/benharrisonline/status/1476043496700059658',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1634527327777423360/3bizbT0W_normal.jpg',
		name: 'Tim Griffin',
		handle: 'timgriffin77',
		unixDateMS: 1640743708000,
		platform: 'twitter',
		content: 'Needless to say, @suzann3rl is definitely in the top 10% coldest sleeper category, which is why we love @eightsleep 🙂',
		verified: false,
		url: 'https://twitter.com/timgriffin77/status/1476012208652009477',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1558109948072153088/nIAs1cQi_normal.jpg',
		name: 'Dennis kioken@mastodon.xyz',
		handle: 'kioken',
		unixDateMS: 1640734871000,
		platform: 'twitter',
		content: '@m_franceschetti @eightsleep Thank you! My life has forever changed thanks to you and your team!',
		verified: false,
		url: 'https://twitter.com/kioken/status/1475975143390433283',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1031156227886084099/xPH5YsmC_normal.jpg',
		name: 'Tony Federico',
		handle: 'FitwithTfed',
		unixDateMS: 1640720217000,
		platform: 'twitter',
		content: '@m_franceschetti @eightsleep Thanks to you and the whole team for such a great product!',
		verified: false,
		url: 'https://twitter.com/FitwithTfed/status/1475913679791177728',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1194022415715561474/y1EF6f6U_normal.jpg',
		name: 'Jon MacDonald',
		handle: 'jonmacdonald',
		unixDateMS: 1640710727000,
		platform: 'twitter',
		content: '@MisterJHuffman I love mine. Not paid. AMA.',
		verified: true,
		url: 'https://twitter.com/jonmacdonald/status/1475873877288882177',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1532724565335592961/VMuWGoYA_normal.jpg',
		name: 'Josh Colston',
		handle: 'SuperColston',
		unixDateMS: 1640710146000,
		platform: 'twitter',
		content: '@ucf_problems EightSleep. I can get you $200 off!',
		verified: false,
		url: 'https://twitter.com/SuperColston/status/1475871441564450820',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1644000570468859905/JjyHThYa_normal.jpg',
		name: 'they call me 𝙻𝚞𝚌𝚔𝚢',
		handle: 'markluck',
		unixDateMS: 1640695634000,
		platform: 'twitter',
		content:
			'@aweberphoto @eightsleep Every time. And the issue gets more pronounced as we age. I assume we become less effective at processing alcohol. @whoop and @eightsleep help us learn and become better performers as we age.',
		verified: false,
		url: 'https://twitter.com/markluck/status/1475810575078858753',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1605768944467316737/QQ_MTtEZ_normal.jpg',
		name: 'Jack Altman',
		handle: 'jaltma',
		unixDateMS: 1638664253000,
		platform: 'twitter',
		content: '@m_franceschetti @rabois I am on night 5 of eight sleep and it’s amazing 💙',
		verified: true,
		url: 'https://twitter.com/jaltma/status/1467290344991518720',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1610135606704603140/t2rQjDVU_normal.jpg',
		name: 'Alex Gonzalez',
		handle: 'AlexGLogics',
		unixDateMS: 1638637090000,
		platform: 'twitter',
		content: 'It has officially been over one week of sleeping on my @eightsleep, it is easily one of the best investments I’ve ever made. 😴<br/><br/>10/10. @m_franceschetti',
		verified: true,
		url: 'https://twitter.com/AlexGLogics/status/1467176412264153091',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1563225490051149824/pOJuaBPA_normal.jpg',
		name: 'Courtney W. Holt',
		handle: 'mootron',
		unixDateMS: 1638137667000,
		platform: 'twitter',
		content: '@msuster @eightsleep Is amazing.  Cant imagine sleep without.',
		verified: false,
		url: 'https://twitter.com/mootron/status/1465081682269138945',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1431311053879717890/v_AmaOVj_normal.jpg',
		name: 'Andrew Weber',
		handle: 'aweberphoto',
		unixDateMS: 1638104578000,
		platform: 'twitter',
		content: '@onlyshitpost777 @eightsleep Yes!!! Just got one! Best night of sleep I have had in a while. ',
		verified: false,
		url: 'https://twitter.com/aweberphoto/status/1464942898382790660',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FFSFzSdXEA03lrJ.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/978132096907599875/weBDVZH-_normal.jpg',
		name: 'Jason McMinn',
		handle: 'jasonmcminn',
		unixDateMS: 1638064570000,
		platform: 'twitter',
		content: '@msuster @akruglov @eightsleep I have been using an Eight Sleep for 18 months now. Best product I have bought in 5 years. The worst part is not having it when you travel.',
		verified: false,
		url: 'https://twitter.com/jasonmcminn/status/1464775090705342470',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1451603238755909632/SuDFeyEv_normal.jpg',
		name: 'Evan Shuster',
		handle: 'abraisnic',
		unixDateMS: 1638020689000,
		platform: 'twitter',
		content: '@msuster @eightsleep Best product I’ve ever purchased.',
		verified: false,
		url: 'https://twitter.com/abraisnic/status/1464591040577482762',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1655449457163661312/wVOcczmQ_normal.jpg',
		name: 'Nate Johnson',
		handle: 'n8works',
		unixDateMS: 1638031684000,
		platform: 'twitter',
		content:
			"@msuster @eightsleep Yes you will!  Bought the entire mattress set months ago after hearing about it on Lex Fridman podcast.  It's game changing.  The data alone is mind blowing.  The heating and cooling functions are awesome and feel so good!   It learns your sleep patterns and adjusts for you!",
		verified: false,
		url: 'https://twitter.com/n8works/status/1464637158765985793',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1486241531627507712/w8IHwKAu_normal.jpg',
		name: 'Shahed Khan',
		handle: '_shahedk',
		unixDateMS: 1637978623000,
		platform: 'twitter',
		content: '@msuster @eightsleep You will love it. I love mine and recommend it to everyone. <br/><br/>@m_franceschetti',
		verified: false,
		url: 'https://twitter.com/_shahedk/status/1464414604692316162',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1079605439698939904/ejLPT5jm_normal.jpg',
		name: 'ibrahim',
		handle: 'ibrahim_0814',
		unixDateMS: 1637679972000,
		platform: 'twitter',
		content: '@eightsleep is actually life changing tech',
		verified: false,
		url: 'https://twitter.com/ibrahim_0814/status/1463161969272365065',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1599551581321678848/KfASQHiJ_normal.jpg',
		name: 'Samuel Rivera',
		handle: 'sriverag787',
		unixDateMS: 1637372758000,
		platform: 'twitter',
		content: '.@eightsleep saves marriages. What an amazing product!',
		verified: false,
		url: 'https://twitter.com/sriverag787/status/1461873419759280137',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1340390266935644161/ift6z1e5_normal.jpg',
		name: 'Nikhil "Nik" Kundra 🇺🇸🇺🇦',
		handle: 'NikKundra',
		unixDateMS: 1637339834000,
		platform: 'twitter',
		content:
			"@m_franceschetti @eightsleep Honestly, you've probably seen @eightsleep trending everywhere you look on Twitter. If you have been eyeing the Pod Pro, Cover, Air Carbon Pillows, etc. all year, this is your chance to get the best deal of the season.<br/><br/>Very few products are life-changing. This is one of them.",
		verified: true,
		url: 'https://twitter.com/NikKundra/status/1461735329262571520',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1683322059080826881/PAWMgbMD_normal.jpg',
		name: 'aidamumini',
		handle: 'amumini24',
		unixDateMS: 1637098384000,
		platform: 'twitter',
		content: 'Ry got us @eightsleep pillows and I’ve never slept better in my life!👏🏼',
		verified: false,
		url: 'https://twitter.com/amumini24/status/1460722612716384260',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1675122423044141056/wgjEnouL_normal.jpg',
		name: 'Adam Epstein',
		handle: 'aepstein_',
		unixDateMS: 1637978611000,
		platform: 'twitter',
		content: '@msuster @eightsleep You won’t regret it. 10/10 nps.',
		verified: true,
		url: 'https://twitter.com/aepstein_/status/1464414554574757889',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1503684620948283394/m8FSNScR_normal.jpg',
		name: 'quindarius',
		handle: 'quindarius_',
		unixDateMS: 1638619734000,
		platform: 'twitter',
		content: '@zachtratar @Aboundlessworld @eightsleep just get the eight sleep <br/>the app is awesome and insightful and the hearing and cooling feature is invaluable',
		verified: false,
		url: 'https://twitter.com/quindarius_/status/1467103618675490820',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1660678902552100866/Cb8w90AS_normal.jpg',
		name: 'Bud Hennekes',
		handle: 'Aboundlessworld',
		unixDateMS: 1638560414000,
		platform: 'twitter',
		content: 'Just spreading the word about @eightsleep and bidets to my homies. Doing the work. ',
		verified: true,
		url: 'https://twitter.com/Aboundlessworld/status/1466854813203894284',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FFtQrRgXMAU0jlJ.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1671143758920839169/aZt9mhKT_normal.jpg',
		name: '꧁𝓝𝓲𝓬𝓸𝓵𝓮 𝓜𝓪𝓻𝓲𝓮꧂',
		handle: 'nmbernard',
		unixDateMS: 1638566723000,
		platform: 'twitter',
		content: '@Aboundlessworld @m_franceschetti @eightsleep I second both of these! 🙌🏼 When I travel I miss them most of all…',
		verified: false,
		url: 'https://twitter.com/nmbernard/status/1466881272719028225',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1524849005666131968/d-S6tHPT_normal.png',
		name: 'Nik Sharma',
		handle: 'mrsharma',
		unixDateMS: 1638539359000,
		platform: 'twitter',
		content: '@eightsleep gm to only u ❤️ ',
		verified: true,
		url: 'https://twitter.com/mrsharma/status/1466766498677104646',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FFsAWx6XEAwP0fR.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1547787118243561478/PxWzJo1H_normal.jpg',
		name: 'Pomp 🌪',
		handle: 'APompliano',
		unixDateMS: 1638453827000,
		platform: 'twitter',
		content:
			'My big health hack in 2020 was using @eightsleep - completely changed my life.<br/><br/>My big health hack this year has been drinking @AthleticGreens every morning.<br/><br/>All the nutrition you need in one drink. Such a no brainer.<br/><br/>Discount here: ',
		verified: true,
		url: 'https://twitter.com/APompliano/status/1466407752868241410',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1484381041313730561/GxhfVHK9_normal.jpg',
		name: 'zackgrymes.eth',
		handle: 'ZackGrymes',
		unixDateMS: 1638326542000,
		platform: 'twitter',
		content: 'What they don’t tell you about @eightsleep is that it heats too, game changer in the winter',
		verified: false,
		url: 'https://twitter.com/ZackGrymes/status/1465873881802190848',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1483217677644615681/g_aVO4wI_normal.jpg',
		name: 'ant',
		handle: 'Jedi_ant',
		unixDateMS: 1638412398000,
		platform: 'twitter',
		content: '@coltentroy @eightsleep My buddy got the eight sleep says it’s legit',
		verified: true,
		url: 'https://twitter.com/Jedi_ant/status/1466233987752415233',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1662177323276222470/-2iz2h7l_normal.jpg',
		name: 'Amanda Goetz',
		handle: 'AmandaMGoetz',
		unixDateMS: 1638327051000,
		platform: 'twitter',
		content: 'My 4 year old walks to my room every night and steals one of my @eightsleep pillows because they are the “cold pillows”.',
		verified: true,
		url: 'https://twitter.com/AmandaMGoetz/status/1465876014970593283',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1581363841660850183/U69H2eiy_normal.jpg',
		name: 'camera1.eth',
		handle: 'redactedeth',
		unixDateMS: 1638323367000,
		platform: 'twitter',
		content: 'The future is @eightsleep',
		verified: false,
		url: 'https://twitter.com/redactedeth/status/1465860565172166657',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1606113903041191936/SPWVTeJr_normal.jpg',
		name: 'Mark McDermott',
		handle: 'mr_mcd',
		unixDateMS: 1638269371000,
		platform: 'twitter',
		content: 'I’m loving @eightsleep - after 2 weeks my sleep has improved so much &amp; is making a big difference to how I feel. Well worth investing in things like this ',
		verified: false,
		url: 'https://twitter.com/mr_mcd/status/1465634089973735425',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FFb6b6rXsAMDLH3.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1623368640681676801/2-mswfvO_normal.jpg',
		name: 'Jana',
		handle: 'TechieTex',
		unixDateMS: 1638226518000,
		platform: 'twitter',
		content: "@neilkusens @eightsleep @m_franceschetti @a_zatarain You won't regret the investment!",
		verified: false,
		url: 'https://twitter.com/TechieTex/status/1465454351779090435',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1524849005666131968/d-S6tHPT_normal.png',
		name: 'Nik Sharma',
		handle: 'mrsharma',
		unixDateMS: 1638148507000,
		platform: 'twitter',
		content: '@raymondchung @vincenzolandino @eightsleep Welcome to the first day of the rest of your life 😊',
		verified: true,
		url: 'https://twitter.com/mrsharma/status/1465127148507869187',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1542564325415591936/FFeuatbF_normal.jpg',
		name: 'Jason Levin',
		handle: 'iamjasonlevin',
		unixDateMS: 1638119702000,
		platform: 'twitter',
		content: 'the coolest mattress ever. @eightsleep. ',
		verified: true,
		url: 'https://twitter.com/iamjasonlevin/status/1465006330888994816',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FFS_fi2XMAssJeU.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1650566930133315584/3RpHIOi3_normal.jpg',
		name: 'Jon Carder',
		handle: 'joncarder',
		unixDateMS: 1637982534000,
		platform: 'twitter',
		content:
			"@msuster @eightsleep It takes a a little while to figure out the perfect temperature for each of the four stages but once that's dialed in you'll love it.  I had the Chili mattress cover and it was loud, this one is silent which was a big part of the purchase decision for me.",
		verified: false,
		url: 'https://twitter.com/joncarder/status/1464431006211981316',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1250191087353745408/PaKf9rvH_normal.png',
		name: 'José Ancer',
		handle: 'ancerj',
		unixDateMS: 1638027029000,
		platform: 'twitter',
		content:
			'@msuster I loved it from Day 1 and definitely feel it improves sleep, particularly around regulating temperature in summer and winter-time. The mattress itself is a bit stiff and took my wife some time to accept it, but now she likes it. Hopefully cover works well w/o stiffness.',
		verified: true,
		url: 'https://twitter.com/ancerj/status/1464617630719823874',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1615061535319261184/rZL66LLc_normal.jpg',
		name: 'Short Round',
		handle: 'KheeLee',
		unixDateMS: 1638032288000,
		platform: 'twitter',
		content: '@msuster @eightsleep I’m happy with it! Has made a difference',
		verified: false,
		url: 'https://twitter.com/KheeLee/status/1464639688950489090',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1658106505260826628/jXxWlLP5_normal.jpg',
		name: 'GuinnessStache',
		handle: 'guinnessstache',
		unixDateMS: 1637979520000,
		platform: 'twitter',
		content: '@msuster @m_franceschetti @eightsleep It will change your life',
		verified: true,
		url: 'https://twitter.com/guinnessstache/status/1464418366739914762',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1538481272120541185/jWQ8RfMY_normal.jpg',
		name: 'Janis Krums (🥃,🦉)',
		handle: 'jkrums',
		unixDateMS: 1637978794000,
		platform: 'twitter',
		content: '@msuster @eightsleep Have loved mine, had a leak and customer service was amazing to solve the issue.',
		verified: true,
		url: 'https://twitter.com/jkrums/status/1464415321553621000',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1675122423044141056/wgjEnouL_normal.jpg',
		name: 'Adam Epstein',
		handle: 'aepstein_',
		unixDateMS: 1637979007000,
		platform: 'twitter',
		content: '@m_franceschetti @msuster @eightsleep Honestly, best consumer product I’ve purchased since my first AirPods. Congrats to you and the team Matteo.',
		verified: true,
		url: 'https://twitter.com/aepstein_/status/1464416212859994113',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1663683920125128705/ZvLK8nls_normal.jpg',
		name: 'Eric',
		handle: 'EricKentEdstrom',
		unixDateMS: 1637770953000,
		platform: 'twitter',
		content: "@NeilpDo @eightsleep @tonal @Levels @ouraring If poor sleep is a big issue, I'd start with @eightsleep<br/><br/>then @Levels",
		verified: true,
		url: 'https://twitter.com/EricKentEdstrom/status/1463543570724438021',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1559220981742424065/Ek08ry-E_normal.jpg',
		name: 'Danika gillian',
		handle: 'danika_gillian',
		unixDateMS: 1637625716000,
		platform: 'twitter',
		content: '@m_franceschetti @eightsleep Love the app @eightsleep , and really love the insights!  Looking forward to seeing what you and your team roll out next!  👀😁',
		verified: false,
		url: 'https://twitter.com/danika_gillian/status/1462934403999490049',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1660678902552100866/Cb8w90AS_normal.jpg',
		name: 'Bud Hennekes',
		handle: 'Aboundlessworld',
		unixDateMS: 1637374546000,
		platform: 'twitter',
		content: '@m_franceschetti @alifetailored @eightsleep Andrew it’s worth it. I promise',
		verified: true,
		url: 'https://twitter.com/Aboundlessworld/status/1461880920038850571',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1589159524824326144/BCZe55Fl_normal.jpg',
		name: 'Karim Atiyeh',
		handle: 'karimatiyeh',
		unixDateMS: 1637335582000,
		platform: 'twitter',
		content: "Now's the time. Pull the trigger. Eight Sleep is the single biggest life and health upgrade I've made in recent years. Highly recommended. ",
		verified: true,
		url: 'https://twitter.com/karimatiyeh/status/1461717493467299851',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1469417059083771904/7KoZfm0i_normal.jpg',
		name: 'Marc Atiyeh',
		handle: 'MarcAtiyeh',
		unixDateMS: 1637334988000,
		platform: 'twitter',
		content: '@m_franceschetti @eightsleep No brainer!',
		verified: true,
		url: 'https://twitter.com/MarcAtiyeh/status/1461715002084274185',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1451603238755909632/SuDFeyEv_normal.jpg',
		name: 'Evan Shuster',
		handle: 'abraisnic',
		unixDateMS: 1637325044000,
		platform: 'twitter',
		content: '@m_franceschetti @lyttleletters @eightsleep Our furnace was broken last night and rather than use any supplemental heat we just warmed our bed up. It’s incredible.',
		verified: false,
		url: 'https://twitter.com/abraisnic/status/1461673292566351877',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1667397726982774789/62cTmpAV_normal.jpg',
		name: 'Francis Plaza',
		handle: 'fplaza',
		unixDateMS: 1637251723000,
		platform: 'twitter',
		content: 'One of the things I look forward to every night is hopping on to my cold bed. Thanks, @eightsleep',
		verified: false,
		url: 'https://twitter.com/fplaza/status/1461365762015129610',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/3537970603/168a2e664f9f3c87bde3bc83cebb9bf7_normal.jpeg',
		name: 'Mancino_Matthew',
		handle: 'Mancino_Matthew',
		unixDateMS: 1637250896000,
		platform: 'twitter',
		content: '@emmiyz @eightsleep How are use Eight Sleep or some version of it for the rest of my life. I love it.',
		verified: false,
		url: 'https://twitter.com/Mancino_Matthew/status/1461362296056270853',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1587432076126683136/q5KimvHi_normal.jpg',
		name: 'Dave 🎧⚛️ 🇨🇦🇬🇧🇺🇦📚',
		handle: 'rellim_evad',
		unixDateMS: 1636906622000,
		platform: 'twitter',
		content: '@eightsleep we’ve had our’s about two weeks now, and it’s made a huge difference already especially for my wife.',
		verified: false,
		url: 'https://twitter.com/rellim_evad/status/1459918303586140165',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1668259073664450560/2fucW__T_normal.jpg',
		name: 'CryptoJustin',
		handle: 'TheCryptoCatGuy',
		unixDateMS: 1637980819000,
		platform: 'twitter',
		content:
			'@michaelbercasio @m_franceschetti @eightsleep My wife and I had a heated mattress pad before on a sleep number - we never cha her our number once it was set. She likes her bed hot af I love mine freezing. I sleep sooo much deeper now so few wakeups during the night. Also the tech tracks your health is 🔥. Their support is 💯',
		verified: false,
		url: 'https://twitter.com/TheCryptoCatGuy/status/1464423815119880197',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1340390266935644161/ift6z1e5_normal.jpg',
		name: 'Nikhil "Nik" Kundra 🇺🇸🇺🇦',
		handle: 'NikKundra',
		unixDateMS: 1637339834000,
		platform: 'twitter',
		content:
			"@m_franceschetti @eightsleep Honestly, you've probably seen @eightsleep trending everywhere you look on Twitter. If you have been eyeing the Pod Pro, Cover, Air Carbon Pillows, etc. all year, this is your chance to get the best deal of the season.<br/><br/>Very few products are life-changing. This is one of them.",
		verified: true,
		url: 'https://twitter.com/NikKundra/status/1461735329262571520',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1335766593205374976/9YL4EV8w_normal.jpg',
		name: 'Mitchell Bernstein',
		handle: 'MitchBernstein',
		unixDateMS: 1635883130000,
		platform: 'twitter',
		content: 'Newest addition is my @eightsleep cover. Best sleeps I’ve ever had ',
		verified: true,
		url: 'https://twitter.com/MitchBernstein/status/1455625468087001092',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1481313178302754821/eeHGWpUF_normal.jpg',
		name: 'Michael Arrington 🏴‍☠️ 🛡️',
		handle: 'arrington',
		unixDateMS: 1607754673000,
		platform: 'twitter',
		content: 'The @eightsleep hype is justified. And all we’ve done so far is set up the mattress. The Pod is next. Have never slept on a finer bed.',
		verified: true,
		url: 'https://twitter.com/arrington/status/1337646167643975680',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1601042465389264896/sKlqXF19_normal.jpg',
		name: 'Mike Meyers',
		handle: 'MikeMeyers',
		unixDateMS: 1634398254000,
		platform: 'twitter',
		content: '@alexiskold @ouraring @eightsleep Best investment I have made this year was @eightsleep.',
		verified: false,
		url: 'https://twitter.com/MikeMeyers/status/1449397445780283394',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1584930300542558208/Ukt_j3Tm_normal.jpg',
		name: 'voska.eth 🍊',
		handle: 'mattvoska',
		unixDateMS: 1633780549000,
		platform: 'twitter',
		content:
			"Day 1 on the @eightsleep - I can't believe I lived without a thermo-regulating bed for so long. Tracking seems accurate; matches @ouraring's metrics.<br/><br/>This will come in handy for summers in Rio. ",
		verified: false,
		url: 'https://twitter.com/mattvoska/status/1446806605559894019',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FBQU7R7XEAQtRsp.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1212453858573737984/8cLpaqnE_normal.jpg',
		name: 'Aaron Kern',
		handle: 'aaronmkern',
		unixDateMS: 1633198007000,
		platform: 'twitter',
		content: '@PatrickWStanley @m_franceschetti @eightsleep Legitimately the best purchase I’ve ever made has been my @eightsleep',
		verified: true,
		url: 'https://twitter.com/aaronmkern/status/1444363244055977984',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1658106505260826628/jXxWlLP5_normal.jpg',
		name: 'GuinnessStache',
		handle: 'guinnessstache',
		unixDateMS: 1633745579000,
		platform: 'twitter',
		content:
			"When @eightsleep eventually goes public (no way it won't) it will be like getting into @Tesla early. They truly are the most  revolutionary technology for sleep since the invention of the mattress.",
		verified: true,
		url: 'https://twitter.com/guinnessstache/status/1446659927678783489',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1453814972056993792/XDevuXV0_normal.jpg',
		name: 'Mike Vinson',
		handle: 'mikevinson_',
		unixDateMS: 1634393898000,
		platform: 'twitter',
		content: '@justinsliao @eightsleep This is a must! Best purchase I made for my place',
		verified: false,
		url: 'https://twitter.com/mikevinson_/status/1449379174632894467',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1658106505260826628/jXxWlLP5_normal.jpg',
		name: 'GuinnessStache',
		handle: 'guinnessstache',
		unixDateMS: 1633740458000,
		platform: 'twitter',
		content:
			"@BradSiegler @eightsleep Funny you say that.. my wife always swore I had sleep apnea and should get tested. Woke up all night long sometimes choking. Ever since I got my @eightsleep I've never had that problem since. Weird huh?",
		verified: true,
		url: 'https://twitter.com/guinnessstache/status/1446638451802132482',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1528907467253596160/jfu8AffN_normal.jpg',
		name: 'Brad Siegler 🇺🇸 🚀',
		handle: 'BradSiegler',
		unixDateMS: 1633714948000,
		platform: 'twitter',
		content:
			"Out of all of the life-changing aspects of @eightsleep, the most surprising was that it effectively pre-diagnosed me with sleep apnea. <br/><br/>I was then properly diagnosed. I would've had no idea had it not been for the very irregular sleep metrics it provided. 🙏",
		verified: true,
		url: 'https://twitter.com/BradSiegler/status/1446531455488778242',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1363316601974644736/JKdIckKh_normal.jpg',
		name: 'Matthew Dellavedova',
		handle: 'matthewdelly',
		unixDateMS: 1633734659000,
		platform: 'twitter',
		content: '@stephsmithio @eightsleep - improve your sleep and it improves everything else you do. Hard to find a better return on investment',
		verified: true,
		url: 'https://twitter.com/matthewdelly/status/1446614128286523394',
		categories: ['Familiar faces'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1613611825316102189/exQko80R_normal.jpg',
		name: 'Ylan Richard',
		handle: 'ylanrich',
		unixDateMS: 1633516067000,
		platform: 'twitter',
		content:
			"I've had my @eightsleep for a month but I sadly had to spend a night in a normal bed. It was terrible, I'll never go back to a normal bed.<br/>Congratz to @m_franceschetti, @a_zatarain and the rest of the 8sleep team, you have built a killer product!",
		verified: true,
		url: 'https://twitter.com/ylanrich/status/1445697285543784452',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1130897086503948290/zwS5k25x_normal.jpg',
		name: 'Jillian Canning Saunders',
		handle: 'jilliankcanning',
		unixDateMS: 1634389781000,
		platform: 'twitter',
		content: '@alexiskold @ouraring @eightsleep It’s a total game changer 😴',
		verified: false,
		url: 'https://twitter.com/jilliankcanning/status/1449361907547258881',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1519108511506046976/Hns7pWP__normal.jpg',
		name: 'Alex Iskold | 2048.vc',
		handle: 'alexiskold',
		unixDateMS: 1634384654000,
		platform: 'twitter',
		content: '5 nights in and my @ouraring is really liking my @eightsleep',
		verified: true,
		url: 'https://twitter.com/alexiskold/status/1449340405892460544',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1562804983426207744/aUr_N2k8_normal.jpg',
		name: 'Nelly',
		handle: 'NelsonsThoughts',
		unixDateMS: 1635990224000,
		platform: 'twitter',
		content: '@hotgirlintech It’s life changing',
		verified: false,
		url: 'https://twitter.com/NelsonsThoughts/status/1456074654540451845',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1684207810916102145/qPxwTwNa_normal.jpg',
		name: 'J.',
		handle: 'jayrunquist',
		unixDateMS: 1636004148000,
		platform: 'twitter',
		content: '@hotgirlintech If Tesla made a bed… @eightsleep is it. We love ours!',
		verified: false,
		url: 'https://twitter.com/jayrunquist/status/1456133055651868673',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1470138740253286404/-Lji9DDh_normal.jpg',
		name: 'matt kita',
		handle: 'kitathinking',
		unixDateMS: 1635993680000,
		platform: 'twitter',
		content: '@ViejeP @ShrugCap @AdQuick @eightsleep It’s great.  Get the cover and just put it over your Casper.',
		verified: false,
		url: 'https://twitter.com/kitathinking/status/1456089146641031168',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1612291013711642624/_XPFP-PP_normal.jpg',
		name: 'Apeistotle ⚡️',
		handle: 'apeistotle',
		unixDateMS: 1635985027000,
		platform: 'twitter',
		content: '@yas_moaven @eightsleep Love my @eightsleep',
		verified: true,
		url: 'https://twitter.com/apeistotle/status/1456052856713265161',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1416285693370937344/vyhKi0nu_normal.jpg',
		name: 'Mat Hebert',
		handle: 'MatHebert',
		unixDateMS: 1635990299000,
		platform: 'twitter',
		content: '@hotgirlintech Had one for a couple years now. Great decision.',
		verified: false,
		url: 'https://twitter.com/MatHebert/status/1456074967754412035',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1524528385950593026/dMfDafhn_normal.jpg',
		name: 'Morgan Brown',
		handle: 'morganb',
		unixDateMS: 1635980061000,
		platform: 'twitter',
		content: 'Easily one of the best purchases I’ve ever made. 🖤 my @eightsleep - thanks @ShrugCap for helping to spread the word! ',
		verified: true,
		url: 'https://twitter.com/morganb/status/1456032026130325504',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FDTdaQ7VcAEpGfP.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1624645933488275457/CLTRuVZH_normal.jpg',
		name: '@A_ILovesTech®️ 🔑🚘',
		handle: 'T_W_T_R_Wallet',
		unixDateMS: 1635855009000,
		platform: 'twitter',
		content: 'Tesla (good car) 🚘 +@eightsleep (good sleep) <br/>@clubteslaquebec <br/><br/>= Life’s🌎simple pleasure. ',
		verified: false,
		url: 'https://twitter.com/T_W_T_R_Wallet/status/1455507521045549056',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1616362592112640005/1S_W6e1H_normal.jpg',
		name: 'Art Levy',
		handle: 'Alevy1511',
		unixDateMS: 1635616874000,
		platform: 'twitter',
		content: 'OH at brunch: “you gotta get the @eightsleep, people are out here throwing up 89’s on their @ouraring on a random Tuesday.”',
		verified: true,
		url: 'https://twitter.com/Alevy1511/status/1454508710664024064',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1668038158821343237/qGzX9SlE_normal.jpg',
		name: 'GJ Sequeira',
		handle: 'gjsequeira',
		unixDateMS: 1635599728000,
		platform: 'twitter',
		content:
			'There are a few things for certain in this life<br/><br/>- death<br/>- taxes<br/>- sleep<br/><br/>For those trying to sleep better, @eightsleep is the tool of choice for many top performers<br/><br/>Learn the story behind the tech ',
		verified: true,
		url: 'https://twitter.com/gjsequeira/status/1454436794636947457',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1547787118243561478/PxWzJo1H_normal.jpg',
		name: 'Pomp 🌪',
		handle: 'APompliano',
		unixDateMS: 1635380253000,
		platform: 'twitter',
		content:
			'@JoePompliano @eightsleep I thought @m_franceschetti was full of it when I first heard what he claimed the mattress would do, but I am a convert. <br/><br/>Eight Sleep is the real deal. I eventually tracked him down to invest.',
		verified: true,
		url: 'https://twitter.com/APompliano/status/1453516248625651715',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1091842698812710913/xFE18Hbc_normal.jpg',
		name: 'jo$iah$',
		handle: 'josiahsp',
		unixDateMS: 1635372359000,
		platform: 'twitter',
		content: '@m_franceschetti @eightsleep Thank you. You and your team have built an awesome product. Has truly changed our sleep quality.',
		verified: false,
		url: 'https://twitter.com/josiahsp/status/1453483137766285317',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1543367003502546944/C3b2PlyA_normal.jpg',
		name: 'pavan sethi',
		handle: 'pavansethi_',
		unixDateMS: 1635353412000,
		platform: 'twitter',
		content: '@MaxieRyan @eightsleep i love mine, keeps me accountable &amp; the vibrating alarm clock is amazing.',
		verified: true,
		url: 'https://twitter.com/pavansethi_/status/1453403667906715656',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1600216663319842835/4tk7mSB3_normal.jpg',
		name: 'E-wan Kenobi',
		handle: 'EwwyVert',
		unixDateMS: 1635177974000,
		platform: 'twitter',
		content:
			'@m_franceschetti Just finished my first week on the pod pro with the mattress, loving it so far I go for -10 to fall asleep. thanks for making a great product Matteo, now to convince my parents to get one',
		verified: false,
		url: 'https://twitter.com/EwwyVert/status/1452667830759985154',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1580188498748968961/L6lebFCL_normal.jpg',
		name: 'Louisa Nicola',
		handle: 'louisanicola_',
		unixDateMS: 1634824005000,
		platform: 'twitter',
		content:
			'I decided to partner with @eightsleep well and truly after I started sleeping on their mattress. After months of sleeping on it I knew I had to partner with them. For anybody wanting to buy the pod pro cover or mattress, check out my thoughts here.',
		verified: true,
		url: 'https://twitter.com/louisanicola_/status/1451183175405916165',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1668259073664450560/2fucW__T_normal.jpg',
		name: 'CryptoJustin',
		handle: 'TheCryptoCatGuy',
		unixDateMS: 1634697993000,
		platform: 'twitter',
		content: 'My @eightsleep mattress knew I was getting sick before my body told me I was. Crazy. 🤯',
		verified: false,
		url: 'https://twitter.com/TheCryptoCatGuy/status/1450654641604153348',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1512104948116381704/4GXgpkpQ_normal.jpg',
		name: 'Jamie Lee Melzer',
		handle: 'jleeku',
		unixDateMS: 1634677387000,
		platform: 'twitter',
		content:
			'@HithaPalepu @eightsleep Yes!! I was waking up EVERY night between 1-2 am drenched. And now I don’t. It was literally that easy 🤦🏻‍♀️ also, crawling into a warm bed to read at night is amazing',
		verified: false,
		url: 'https://twitter.com/jleeku/status/1450568214270906380',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/597871331846262784/QqtH6jt2_normal.jpg',
		name: 'Julio A. Berroa',
		handle: 'jberroa0204',
		unixDateMS: 1634608586000,
		platform: 'twitter',
		content: '@WizLikeWizard @m_franceschetti @alexiskold @eightsleep There is nothing like a warm bed, before you fall as sleep. ',
		verified: false,
		url: 'https://twitter.com/jberroa0204/status/1450279642649305090',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FCBtpiGXEAMBeSt.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1654499185259950084/B1KP8wsK_normal.jpg',
		name: 'LIA 👸📈',
		handle: 'Liathetrader',
		unixDateMS: 1634592395000,
		platform: 'twitter',
		content: '@gannonbreslin @eightsleep I got a cooling mattress from them. Amazing!',
		verified: true,
		url: 'https://twitter.com/Liathetrader/status/1450211733759569923',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1601703850246823937/FlH2N3_N_normal.jpg',
		name: 'Wiz 👨‍🚀',
		handle: 'WizLikeWizard',
		unixDateMS: 1634592224000,
		platform: 'twitter',
		content: 'Cold fall hobbies include:<br/><br/>- cranking @eightsleep to 10 and watching Succession',
		verified: true,
		url: 'https://twitter.com/WizLikeWizard/status/1450211015304650765',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1543682390643572738/OAstSy2O_normal.jpg',
		name: 'katcole.eth 🎈',
		handle: 'KatColeATL',
		unixDateMS: 1634412791000,
		platform: 'twitter',
		content: '@arrington @eightsleep Yes it is. 😴',
		verified: true,
		url: 'https://twitter.com/KatColeATL/status/1449458421451923458',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1604021476956463104/51uzxQOH_normal.jpg',
		name: 'Matt Alexander',
		handle: 'rationalduck',
		unixDateMS: 1634323729000,
		platform: 'twitter',
		content:
			'I don’t even have an @eightsleep mattress but I just can’t stop selling it to people. (Soon, my parents might get one). The product just seems like a game changer. <br/><br/>My dream/goal is to get one after college. ',
		verified: false,
		url: 'https://twitter.com/rationalduck/status/1449084867392806913',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FBwvAOmXsAciHCJ.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1600593627033501697/Oip202cn_normal.jpg',
		name: 'Greg Kahn | GK Digital Ventures',
		handle: 'GK_Ventures',
		unixDateMS: 1634212394000,
		platform: 'twitter',
		content: 'Another solid night of sleep w/ @eightsleep <br/><br/>Look forward to seeing how the company builds out its Sleep Tools, content and community.<br/><br/>#SleepFitness ',
		verified: false,
		url: 'https://twitter.com/GK_Ventures/status/1448617895165153284',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FBqGKeoWEAIIOQc.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/851192412206546944/OWZd_Jj3_normal.jpg',
		name: 'Anmol Gulati',
		handle: 'anmol01gulati',
		unixDateMS: 1634064776000,
		platform: 'twitter',
		content: '@KLdivergence @eightsleep is definitely worth the cost and the hype!',
		verified: false,
		url: 'https://twitter.com/anmol01gulati/status/1447998740174868480',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1476016971774713858/5RI9qTMv_normal.jpg',
		name: 'Ryan Gill',
		handle: 'ryangillshares',
		unixDateMS: 1634053199000,
		platform: 'twitter',
		content: '@DenehyXXL @APompliano turned me onto @eightsleep and it’s been a game changer. 🤟',
		verified: true,
		url: 'https://twitter.com/ryangillshares/status/1447950181354311684',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1566894252684091395/rXWMcJ5q_normal.jpg',
		name: 'ericosiu',
		handle: 'ericosiu',
		unixDateMS: 1633871317000,
		platform: 'twitter',
		content:
			'Sleep score has been more or less at 100% except for one night of alcohol.<br/><br/>Biggest x-factor for me personally has been taking multivitamins and fish oil in the last few weeks. <br/><br/>Had no idea it would have such a big impact. <br/><br/>Cc @eightsleep ',
		verified: true,
		url: 'https://twitter.com/ericosiu/status/1447187314510290948',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FBVxMPxXsAIe2BU.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1594528532130332673/OeQzckmN_normal.jpg',
		name: 'Kourosh',
		handle: 'kouroshbaloo',
		unixDateMS: 1633825556000,
		platform: 'twitter',
		content: '@m_franceschetti @TheCryptoCatGuy @eightsleep I love the new notifications letting me know I slept like a rock 🤣',
		verified: true,
		url: 'https://twitter.com/kouroshbaloo/status/1446995378235006977',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1319390665311096832/tiEkfAaJ_normal.jpg',
		name: 'Andy Marsh',
		handle: 'ImAndyMarsh',
		unixDateMS: 1633809892000,
		platform: 'twitter',
		content: '@m_franceschetti I’m loving my @eightsleep bed. Best. Investment. Ever.',
		verified: false,
		url: 'https://twitter.com/ImAndyMarsh/status/1446929678384582657',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1562804983426207744/aUr_N2k8_normal.jpg',
		name: 'Nelly',
		handle: 'NelsonsThoughts',
		unixDateMS: 1633809005000,
		platform: 'twitter',
		content: '@m_franceschetti @ouraring @eightsleep Best sleep ever since getting the eight sleep!',
		verified: false,
		url: 'https://twitter.com/NelsonsThoughts/status/1446925959332909061',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1668259073664450560/2fucW__T_normal.jpg',
		name: 'CryptoJustin',
		handle: 'TheCryptoCatGuy',
		unixDateMS: 1633797313000,
		platform: 'twitter',
		content:
			'Reason 5,555 why I love my @eightsleep mattress. <br/><br/>Air in the apt has been out and we reached temps of 85 last night. <br/><br/>Turned the mattress temp to -10 and was actually cold at one point... slept like a rock.',
		verified: false,
		url: 'https://twitter.com/TheCryptoCatGuy/status/1446876918217854976',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1581409751564316673/EmlXTpT-_normal.jpg',
		name: 'Eric Bahn 💛',
		handle: 'ericbahn',
		unixDateMS: 1633743519000,
		platform: 'twitter',
		content: '@paul_griffiths Yes but @eightsleep is so comfy',
		verified: true,
		url: 'https://twitter.com/ericbahn/status/1446651291300597760',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1586792485015035904/F98UUD2K_normal.jpg',
		name: 'Steph Smith',
		handle: 'stephsmithio',
		unixDateMS: 1633735407000,
		platform: 'twitter',
		content: '@matthewdelly @eightsleep Great, thanks! I also like getting HRV stats. Wild to see how bad they are after a night of drinking.',
		verified: true,
		url: 'https://twitter.com/stephsmithio/status/1446617264178941952',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1688969071172227072/1R9M7VC5_normal.jpg',
		name: 'Maddie',
		handle: 'Madamelic',
		unixDateMS: 1633726286000,
		platform: 'twitter',
		content:
			"@stephsmithio Surprisingly valuable: @eightsleep <br/><br/>I tried to return it before I tried it because I thought it was too expensive and wouldn't be any good.<br/><br/>Less value than expected: iPhone X. <br/><br/>It was my first &gt;$1k phone and my last. It was a phone. It did phone stuff.",
		verified: false,
		url: 'https://twitter.com/Madamelic/status/1446579006757916678',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1677703282234634245/NytOB4Up_normal.jpg',
		name: 'Jared Nations',
		handle: 'nations_jared',
		unixDateMS: 1633697976000,
		platform: 'twitter',
		content: '@dranthonygustin @eightsleep @m_franceschetti Got mine too. Have mine set to -8 when I fell asleep 🥶🥶',
		verified: true,
		url: 'https://twitter.com/nations_jared/status/1446460266951102468',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/705448193991974913/8pIvczEX_normal.jpg',
		name: 'Anthony Gustin',
		handle: 'dranthonygustin',
		unixDateMS: 1633697837000,
		platform: 'twitter',
		content: "I've formally joined the @eightsleep cult. 🙌 @m_franceschetti for making sleep so much better for all of us!",
		verified: true,
		url: 'https://twitter.com/dranthonygustin/status/1446459684005765123',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1600593627033501697/Oip202cn_normal.jpg',
		name: 'Greg Kahn | GK Digital Ventures',
		handle: 'GK_Ventures',
		unixDateMS: 1633609642000,
		platform: 'twitter',
		content: 'Another great night of sleep w/ @eightsleep <br/><br/>#SleepFitness ',
		verified: false,
		url: 'https://twitter.com/GK_Ventures/status/1446089768903843841',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FBGK-v5WQAEF_XR.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1618825758956294144/xTZTeGPf_normal.jpg',
		name: 'Jake',
		handle: 'Just_Lew_it',
		unixDateMS: 1633490477000,
		platform: 'twitter',
		content: 'First night with an @eightsleep mattress. Rivals the feeling of waiting to play @Xbox for the first time @m_franceschetti',
		verified: true,
		url: 'https://twitter.com/Just_Lew_it/status/1445589952771739656',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1547787118243561478/PxWzJo1H_normal.jpg',
		name: 'Pomp 🌪',
		handle: 'APompliano',
		unixDateMS: 1633405204000,
		platform: 'twitter',
		content: '@mr__hodl Only on my @eightsleep',
		verified: true,
		url: 'https://twitter.com/APompliano/status/1445232293149294592',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1608532441307701248/Tlaa94Zv_normal.jpg',
		name: 'Mike Benson',
		handle: 'Unbox_warehouse',
		unixDateMS: 1633402770000,
		platform: 'twitter',
		content: '@ijustine @m_franceschetti @mikekalamas @ouraring @eightsleep Justine. I’m sleeping on an eight sleep now. It’s life changing.',
		verified: true,
		url: 'https://twitter.com/Unbox_warehouse/status/1445222083223490562',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1146725292/1258_normal.gif',
		name: 'Mike Kalamas',
		handle: 'mikekalamas',
		unixDateMS: 1633399650000,
		platform: 'twitter',
		content: '@ijustine @ouraring Now you need to get an @eightsleep it’s truly life changing. @m_franceschetti',
		verified: false,
		url: 'https://twitter.com/mikekalamas/status/1445208996718301186',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1689344178335973376/-j42F-k2_normal.jpg',
		name: 'Sonny Byrd 💥💥',
		handle: 'sonnybyrd',
		unixDateMS: 1633385424000,
		platform: 'twitter',
		content:
			'Second: @eightsleep. I’ve been let down so many times by companies that make massive lofty promises. But Eight Sleep set the bar high and soared right over it. I’ll never sleep without it again. Improved sleep = improved awake time. I’m all in. Even the app is great.',
		verified: false,
		url: 'https://twitter.com/sonnybyrd/status/1445149328864075776',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1675861608306728960/pK9ZjluH_normal.jpg',
		name: 'THOMAS R. WEAVER',
		handle: 'tom_weaver',
		unixDateMS: 1633356721000,
		platform: 'twitter',
		content: '@m_franceschetti @eightsleep Great work. Can’t wait to try it, finally.',
		verified: false,
		url: 'https://twitter.com/tom_weaver/status/1445028940914085899',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1660678902552100866/Cb8w90AS_normal.jpg',
		name: 'Bud Hennekes',
		handle: 'Aboundlessworld',
		unixDateMS: 1633357936000,
		platform: 'twitter',
		content: '@m_franceschetti @eightsleep Cc @adriankpang best sleep you’ll ever have',
		verified: true,
		url: 'https://twitter.com/Aboundlessworld/status/1445034037148626948',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/869455594805092352/8j1ljPjg_normal.jpg',
		name: 'Ed',
		handle: 'eeddeellee',
		unixDateMS: 1633313831000,
		platform: 'twitter',
		content:
			"@CoryMckane Lowering of the body temperature by @eightsleep is surprisingly putting me to sleep very quickly. Can't even read a full chapter in bed anymore...which I kind of miss actually",
		verified: false,
		url: 'https://twitter.com/eeddeellee/status/1444849048830185477',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1580188498748968961/L6lebFCL_normal.jpg',
		name: 'Louisa Nicola',
		handle: 'louisanicola_',
		unixDateMS: 1633303682000,
		platform: 'twitter',
		content: '@blackatlantic @altini_marco Yes. Invest in an @eightsleep',
		verified: true,
		url: 'https://twitter.com/louisanicola_/status/1444806479194755075',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1524849005666131968/d-S6tHPT_normal.png',
		name: 'Nik Sharma',
		handle: 'mrsharma',
		unixDateMS: 1633223060000,
		platform: 'twitter',
		content: '@treypicou @m_franceschetti @eightsleep Best decision you’ll make is ordering one!',
		verified: true,
		url: 'https://twitter.com/mrsharma/status/1444468327217352706',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1401711730921717761/qAT6DXrW_normal.jpg',
		name: '🦷 Sunny 🦷',
		handle: 'DDSinvestor',
		unixDateMS: 1633210054000,
		platform: 'twitter',
		content: '@m_franceschetti @eightsleep If you have a passion for healthy living, you should have a @onepeloton  AND an @eightsleep',
		verified: false,
		url: 'https://twitter.com/DDSinvestor/status/1444413774107119619',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1249733927268306945/xQW0vSlj_normal.jpg',
		name: 'Brian Munroe',
		handle: 'munroebot',
		unixDateMS: 1633209510000,
		platform: 'twitter',
		content: '@gabeshep @eightsleep @gabeshep did you just get yours?  We had ours for most of the vegas summer and it can’t be beat.  Also, the sleep metrics are pretty fun / interesting.',
		verified: false,
		url: 'https://twitter.com/munroebot/status/1444411492388851713',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1271264092729335808/og1Gc6ot_normal.jpg',
		name: 'Dr. Sohaib Imtiaz, MD MPH',
		handle: 'digitald0ctor',
		unixDateMS: 1633169220000,
		platform: 'twitter',
		content: 'It’s fall and thank god for the warm settings on my @eightsleep without needed to turn on the radiators which dry out my skin',
		verified: true,
		url: 'https://twitter.com/digitald0ctor/status/1444242506644955137',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1521584892554973185/I56_yI6O_normal.jpg',
		name: 'Stephen Price',
		handle: 'stephenprice100',
		unixDateMS: 1633062989000,
		platform: 'twitter',
		content: '2/ CGMs @Levels and sleep @eightsleep are great examples of high-signal, passive, longitudinal health tracking',
		verified: false,
		url: 'https://twitter.com/stephenprice100/status/1443796938474893316',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1528907467253596160/jfu8AffN_normal.jpg',
		name: 'Brad Siegler 🇺🇸 🚀',
		handle: 'BradSiegler',
		unixDateMS: 1633714948000,
		platform: 'twitter',
		content:
			"Out of all of the life-changing aspects of @eightsleep, the most surprising was that it effectively pre-diagnosed me with sleep apnea. <br/><br/>I was then properly diagnosed. I would've had no idea had it not been for the very irregular sleep metrics it provided. 🙏",
		verified: true,
		url: 'https://twitter.com/BradSiegler/status/1446531455488778242',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1407563430236614657/plFtbHtj_normal.jpg',
		name: 'Sergio Collazos',
		handle: 'Sergio_Collazos',
		unixDateMS: 1633807725000,
		platform: 'twitter',
		content: '@m_franceschetti @katgleason I just got a pod cover and my Oura results have noticeable improved. <br/><br/>Though these ones are insane 😅',
		verified: false,
		url: 'https://twitter.com/Sergio_Collazos/status/1446920588191096832',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1654499185259950084/B1KP8wsK_normal.jpg',
		name: 'LIA 👸📈',
		handle: 'Liathetrader',
		unixDateMS: 1634592395000,
		platform: 'twitter',
		content: '@gannonbreslin @eightsleep I got a cooling mattress from them. Amazing!',
		verified: true,
		url: 'https://twitter.com/Liathetrader/status/1450211733759569923',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1654681648162566152/5fBKnRQh_normal.jpg',
		name: 'Justin 🏴',
		handle: 'justinsliao',
		unixDateMS: 1634625377000,
		platform: 'twitter',
		content: 'me 🤝 5 grams of magnesium threonate, 5 grams of glycine, and 800 mg of l-theanine rn while @eightsleep revs up to send me to the astral plane.',
		verified: false,
		url: 'https://twitter.com/justinsliao/status/1450350068876337156',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1601703850246823937/FlH2N3_N_normal.jpg',
		name: 'Wiz 👨‍🚀',
		handle: 'WizLikeWizard',
		unixDateMS: 1634592224000,
		platform: 'twitter',
		content: 'Cold fall hobbies include:<br/><br/>- cranking @eightsleep to 10 and watching Succession',
		verified: true,
		url: 'https://twitter.com/WizLikeWizard/status/1450211015304650765',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1687103722697162752/p523UB4V_normal.jpg',
		name: 'Yossi J Levi',
		handle: 'yossixciii',
		unixDateMS: 1631064941000,
		platform: 'twitter',
		content: '@danielfolkman @eightsleep Folk, I bought their cover. It’s worth it.',
		verified: false,
		url: 'https://twitter.com/yossixciii/status/1435416518322446336',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1512104948116381704/4GXgpkpQ_normal.jpg',
		name: 'Jamie Lee Melzer',
		handle: 'jleeku',
		unixDateMS: 1632189666000,
		platform: 'twitter',
		content: 'Just installed my new @eightsleep cover and am fully expecting this to be life changing 😴',
		verified: false,
		url: 'https://twitter.com/jleeku/status/1440133959313207296',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1538728146266206208/BA7_XN-F_normal.jpg',
		name: 'Simon Yu',
		handle: 'SimonYuSEA',
		unixDateMS: 1632081161000,
		platform: 'twitter',
		content:
			'@m_franceschetti @eightsleep I recently got a eight sleep bed because of the crypto cashback from @stormxio <br/><br/>One of the best purchases I’ve made this year for sure. <br/><br/>It’d be great if you take crypto payment as well.',
		verified: true,
		url: 'https://twitter.com/SimonYuSEA/status/1439678853455224838',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1490749003599912963/l1Rn_3ak_normal.jpg',
		name: 'Jeremy Hartwell',
		handle: 'jjhrtwll',
		unixDateMS: 1631550070000,
		platform: 'twitter',
		content:
			"@eightsleep The best sleep product on the market. You're missing out by not trying it. Best of all, the customer support is fantastic. Other companies take note, #eightsleep is how you grow your customer base #bestnightsleep #customerservice",
		verified: false,
		url: 'https://twitter.com/jjhrtwll/status/1437451295774978052',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1511718848902443008/I_ag2CJ9_normal.jpg',
		name: 'JPB',
		handle: 'JPENBEN',
		unixDateMS: 1632015265000,
		platform: 'twitter',
		content: '@m_franceschetti @willibeemin @eightsleep Congratulations on an amazing product. I have bought them for family members and am rooting for miami founders 🇺🇸',
		verified: false,
		url: 'https://twitter.com/JPENBEN/status/1439402465531674627',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/978132096907599875/weBDVZH-_normal.jpg',
		name: 'Jason McMinn',
		handle: 'jasonmcminn',
		unixDateMS: 1632972463000,
		platform: 'twitter',
		content: '@michelletandler Just get an @eightsleep and your sleep quality will be forever changed. Best thing I have bought in 5 years.',
		verified: false,
		url: 'https://twitter.com/jasonmcminn/status/1443417246001295362',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1031156227886084099/xPH5YsmC_normal.jpg',
		name: 'Tony Federico',
		handle: 'FitwithTfed',
		unixDateMS: 1632968036000,
		platform: 'twitter',
		content: '@m_franceschetti @peteralevin @eightsleep I love mine. I can’t sleep on anything else.',
		verified: false,
		url: 'https://twitter.com/FitwithTfed/status/1443398679424126979',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1574893736144044032/NMV9FsCc_normal.jpg',
		name: 'Joe Baer',
		handle: 'Joe_Baer_74',
		unixDateMS: 1632941232000,
		platform: 'twitter',
		content: '@UncleChaps That was us last month with our @eightsleep pod, but lemme tell ya, game changer and a half',
		verified: false,
		url: 'https://twitter.com/Joe_Baer_74/status/1443286253336662023',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1484379336857640960/Sis6_GKv_normal.png',
		name: 'Turner Novak 🍌🧢',
		handle: 'TurnerNovak',
		unixDateMS: 1632957971000,
		platform: 'twitter',
		content: 'just got an eight sleep and slept for 17 days straight',
		verified: true,
		url: 'https://twitter.com/TurnerNovak/status/1443356460197498882',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1454572411827851266/xo-4Kbu__normal.jpg',
		name: 'ZomZomsNFT',
		handle: 'ZomZomsNFT',
		unixDateMS: 1632921820000,
		platform: 'twitter',
		content: '@eightsleep woke up today with the best sleep ever after using eight sleep!  This is not an ad. I just love their product.',
		verified: false,
		url: 'https://twitter.com/ZomZomsNFT/status/1443204834044248072',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1580188498748968961/L6lebFCL_normal.jpg',
		name: 'Louisa Nicola',
		handle: 'louisanicola_',
		unixDateMS: 1632747033000,
		platform: 'twitter',
		content:
			'@LindenJohan I have a WHOOP and an Oura and the @eightsleep. There’s merit in owning at least one tracker but the mattress is a monopoly of its own. You can’t get temperature control from a wearable like the ring',
		verified: true,
		url: 'https://twitter.com/louisanicola_/status/1442471723425615873',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1683456114443137025/pix1OdaR_normal.jpg',
		name: 'Jonah Lupton',
		handle: 'JonahLupton',
		unixDateMS: 1632658898000,
		platform: 'twitter',
		content:
			'Several weeks ago I got my @EightSleep mattress and I love it so I reached out to the company and might have convinced them to send a demo pod/mattress down to the @FinTwitConf so people can try it out before/during/after the general session. ',
		verified: true,
		url: 'https://twitter.com/JonahLupton/status/1442102059520692224',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FANfcEgWQAcaFcM.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1650512809740861442/2Rdhlvwu_normal.jpg',
		name: 'Noah Loffredo',
		handle: 'LoffredoNoah',
		unixDateMS: 1632660457000,
		platform: 'twitter',
		content: '@JonahLupton @eightsleep @FinTwitConf Such a great idea, Jonah! Love my @eightsleep pod',
		verified: false,
		url: 'https://twitter.com/LoffredoNoah/status/1442108596406325251',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1464607055352934400/JDRXCF00_normal.jpg',
		name: 'hanson',
		handle: 'chrisha20185904',
		unixDateMS: 1632588314000,
		platform: 'twitter',
		content: '@PatrickWStanley @m_franceschetti Just got the topper because we like our bed.  Awesome upgrade and now I don’t want to get out of it 🤣',
		verified: false,
		url: 'https://twitter.com/chrisha20185904/status/1441806007076806658',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1521816458182995970/h0IRuI_8_normal.jpg',
		name: 'Jack Csenge, CFP®',
		handle: 'jackcsenge',
		unixDateMS: 1632497943000,
		platform: 'twitter',
		content:
			'@m_franceschetti Honestly never slept better in my life. Used to wake up at all hours of the night. Crank that Pod Pro down to -9 and I sleep like a rock! Keep putting out superb products',
		verified: false,
		url: 'https://twitter.com/jackcsenge/status/1441426966226161670',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1608532441307701248/Tlaa94Zv_normal.jpg',
		name: 'Mike Benson',
		handle: 'Unbox_warehouse',
		unixDateMS: 1632448070000,
		platform: 'twitter',
		content:
			'@m_franceschetti @freakinreviews @eightsleep I was just reading through these reviews and I understand exactly why everyone feels this way!  Might be my Favorite product of 2021!!! But let me sleep on it. ',
		verified: true,
		url: 'https://twitter.com/Unbox_warehouse/status/1441217780720668675',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1041742017611657217/A6lEXWfd_normal.jpg',
		name: 'Chet',
		handle: 'osuspaghetti20',
		unixDateMS: 1632439804000,
		platform: 'twitter',
		content:
			'@eightsleep I know I’m just a common person with an eight sleep mattress but they’ve been nothing but helpful when I’ve had problems. The customer service is top notch and the mattress is, once experienced, something we can’t live without!',
		verified: false,
		url: 'https://twitter.com/osuspaghetti20/status/1441183113699373056',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1667679219994992641/Bdide91j_normal.jpg',
		name: 'Nicko𝕏O',
		handle: 'nickkoXO',
		unixDateMS: 1632359203000,
		platform: 'twitter',
		content: '@m_franceschetti Both great pieces of tech. Can confirm. ⚡️💤',
		verified: false,
		url: 'https://twitter.com/nickkoXO/status/1440845048535404545',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1650512809740861442/2Rdhlvwu_normal.jpg',
		name: 'Noah Loffredo',
		handle: 'LoffredoNoah',
		unixDateMS: 1632353658000,
		platform: 'twitter',
		content: '@m_franceschetti My favorite purchases of the year 🤝',
		verified: false,
		url: 'https://twitter.com/LoffredoNoah/status/1440821789643313161',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1509530448958918663/SqxfRTpj_normal.jpg',
		name: 'Chris Power',
		handle: '2112Power',
		unixDateMS: 1632349011000,
		platform: 'twitter',
		content: '@lulumeservey @traestephens @eightsleep its literally the best',
		verified: true,
		url: 'https://twitter.com/2112Power/status/1440802297185849344',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1495528865279664128/IqP6mNGD_normal.jpg',
		name: 'Brandon Reeves',
		handle: 'breeves08',
		unixDateMS: 1632349483000,
		platform: 'twitter',
		content: '@2112Power @lulumeservey @traestephens @eightsleep Agree 100%',
		verified: false,
		url: 'https://twitter.com/breeves08/status/1440804279514632199',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/597871331846262784/QqtH6jt2_normal.jpg',
		name: 'Julio A. Berroa',
		handle: 'jberroa0204',
		unixDateMS: 1632252286000,
		platform: 'twitter',
		content: '@jleeku @eightsleep Welcome to the club. Trust me it will. 🙂',
		verified: false,
		url: 'https://twitter.com/jberroa0204/status/1440396604440125451',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1496580378525589504/q5ZHB4P3_normal.jpg',
		name: 'Michael Dorminey',
		handle: 'christisking404',
		unixDateMS: 1632190461000,
		platform: 'twitter',
		content: 'I’m a believer!!  @eightsleep has changed my wife and my life. Must have! ',
		verified: true,
		url: 'https://twitter.com/christisking404/status/1440137293696626701',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1031156227886084099/xPH5YsmC_normal.jpg',
		name: 'Tony Federico',
		handle: 'FitwithTfed',
		unixDateMS: 1632067780000,
		platform: 'twitter',
		content:
			'@elonmusk @28delayslater @AustinTeslaClub @Tesla @TeslaOwnersEBay @TeslaOwnersUAE @EvaFoxU @leastImAlive @teslaownersSV @gailalfa1 @live_munro @OwenSparks_ There are only a handful of products I truly love.  @Tesla of course, @eightsleep, @onepeloton, @tonal, and @geteero.  Each has had a hand in making my boring day to day life a little better.',
		verified: false,
		url: 'https://twitter.com/FitwithTfed/status/1439622731704815619',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1213509019794640899/uEz-S5Dw_normal.jpg',
		name: 'Will Brackett',
		handle: 'willibeemin',
		unixDateMS: 1631995067000,
		platform: 'twitter',
		content:
			'Called @eightsleep with a product issue today.<br/><br/>Spoke with a rep that was polite, extremely helpful, and immediately kicked off a process to resolve my issue while providing timeline estimates and confirmations.<br/><br/>Great customer service = customer retention<br/><br/>The details matter…',
		verified: true,
		url: 'https://twitter.com/willibeemin/status/1439317752129355780',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1581409751564316673/EmlXTpT-_normal.jpg',
		name: 'Eric Bahn 💛',
		handle: 'ericbahn',
		unixDateMS: 1631926162000,
		platform: 'twitter',
		content: '@sweatystartup I love sleeping next to my kids, so long as it’s on an @eightsleep.',
		verified: true,
		url: 'https://twitter.com/ericbahn/status/1439028743536668675',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1338352888389775362/awwnzOq5_normal.jpg',
		name: 'Greysen Paige Cohen',
		handle: 'greysen_paige',
		unixDateMS: 1631901737000,
		platform: 'twitter',
		content: '@aminshams___ Aww damn, I’m sorry :( only other bed I LOVED was @eightsleep',
		verified: false,
		url: 'https://twitter.com/greysen_paige/status/1438926296906670081',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/869455594805092352/8j1ljPjg_normal.jpg',
		name: 'Ed',
		handle: 'eeddeellee',
		unixDateMS: 1631836588000,
		platform: 'twitter',
		content: '@drewcoffman Obligatory @eightsleep mention. Being able to have my bed cooled when I call it a day, has helped me fall asleep so much quicker',
		verified: false,
		url: 'https://twitter.com/eeddeellee/status/1438653039259176961',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1658106505260826628/jXxWlLP5_normal.jpg',
		name: 'GuinnessStache',
		handle: 'guinnessstache',
		unixDateMS: 1631809368000,
		platform: 'twitter',
		content: '@m_franceschetti @KaylahRoxOut Congrats! You are about to have the best sleep of your life going forward!',
		verified: true,
		url: 'https://twitter.com/guinnessstache/status/1438538871067787266',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1600593627033501697/Oip202cn_normal.jpg',
		name: 'Greg Kahn | GK Digital Ventures',
		handle: 'GK_Ventures',
		unixDateMS: 1631718610000,
		platform: 'twitter',
		content: 'Prediction: @eightsleep is going to push all other end mattress companies (@sleepnumber @TempurPedic) to offer individual temperature control.<br/><br/>It’s a game changer.',
		verified: false,
		url: 'https://twitter.com/GK_Ventures/status/1438158203804700679',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1435825620110430212/2-iIQLte_normal.jpg',
		name: 'Randy Carballo',
		handle: 'randycarballo',
		unixDateMS: 1631704236000,
		platform: 'twitter',
		content: '@sammskiii @eightsleep Just did it a week ago, we love it',
		verified: true,
		url: 'https://twitter.com/randycarballo/status/1438097915965227011',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1288898761972801536/_Yi3FY4H_normal.jpg',
		name: 'Michelle',
		handle: 'michellechanme',
		unixDateMS: 1631680784000,
		platform: 'twitter',
		content: '@_tyoung @eightsleep Yesss. Temp control is everything 😴',
		verified: false,
		url: 'https://twitter.com/michellechanme/status/1437999552984858626',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1355698719723761668/KohYfwTX_normal.jpg',
		name: 'Mani',
		handle: 'manicakes',
		unixDateMS: 1631648293000,
		platform: 'twitter',
		content: '@wolfejosh @eightsleep @m_franceschetti @zebulgar i’ve got one too, love it.',
		verified: false,
		url: 'https://twitter.com/manicakes/status/1437863276168323081',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1463499282036756482/D3nSbqZb_normal.jpg',
		name: 'Josh Wolfe',
		handle: 'wolfejosh',
		unixDateMS: 1631627941000,
		platform: 'twitter',
		content:
			'GOOD news—i’m sleeping 😴better than ever<br/>thx to @eightsleep<br/>(plus no more stealthy spouse thermostat adjustments😂)<br/><br/>BAD news—I’ll now have sleepless nights for missing the Series A years ago! Congrats on beautiful product you’re building…Thank you @m_franceschetti + @zebulgar!',
		verified: true,
		url: 'https://twitter.com/wolfejosh/status/1437777913252827148',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1584868536811495424/rMNXT5pl_normal.jpg',
		name: 'Patrick OShaughnessy',
		handle: 'patrick_oshag',
		unixDateMS: 1631630899000,
		platform: 'twitter',
		content: '@wolfejosh @eightsleep @m_franceschetti @zebulgar rocks, right?!',
		verified: true,
		url: 'https://twitter.com/patrick_oshag/status/1437790319345549325',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1686409479070314496/ZPQ-ezta_normal.jpg',
		name: 'nic 🌠 carter',
		handle: 'nic__carter',
		unixDateMS: 1631480799000,
		platform: 'twitter',
		content: '@gckaloudis @Levels @ouraring @Garmin @eightsleep Liking it so far',
		verified: true,
		url: 'https://twitter.com/nic__carter/status/1437160755305009153',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/413037869448450048/WIKP9pY2_normal.jpeg',
		name: 'Sco Holm (Taylor’s Version)',
		handle: 'omnipotentsco',
		unixDateMS: 1631473820000,
		platform: 'twitter',
		content: 'Nope! The @eightsleep bed helped my back quite a bit!',
		verified: false,
		url: 'https://twitter.com/omnipotentsco/status/1437131480434716676',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1650512809740861442/2Rdhlvwu_normal.jpg',
		name: 'Noah Loffredo',
		handle: 'LoffredoNoah',
		unixDateMS: 1631457859000,
		platform: 'twitter',
		content: '@sairarahman @eightsleep @eightsleep is soooo good.',
		verified: false,
		url: 'https://twitter.com/LoffredoNoah/status/1437064538365571074',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1271264092729335808/og1Gc6ot_normal.jpg',
		name: 'Dr. Sohaib Imtiaz, MD MPH',
		handle: 'digitald0ctor',
		unixDateMS: 1631335674000,
		platform: 'twitter',
		content: 'When it’s too hot in the U.K. @eightsleep ',
		verified: true,
		url: 'https://twitter.com/digitald0ctor/status/1436552056962232320',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/E--oex8XIAEOy34.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1461727266015039488/WPThZ6zo_normal.jpg',
		name: 'ticker talker',
		handle: 'talkingtickers',
		unixDateMS: 1631287465000,
		platform: 'twitter',
		content:
			'@Lriberat @marceloclaure @whoop @shervin @eightsleep @andrewrsorkin @m_franceschetti I ended up buying one a couple months ago. Best purchase I’ve made. First night or two took a little getting used to. Bed was slightly stiffer. Once you get your settings figured out it is life changing though. Highly recommend.',
		verified: false,
		url: 'https://twitter.com/talkingtickers/status/1436349851243933706',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1432949568249999361/ybVhGs55_normal.jpg',
		name: 'CryptoKamado',
		handle: 'CryptoKamado_',
		unixDateMS: 1631276289000,
		platform: 'twitter',
		content: '@GainzyXBT Get @eightsleep bro. Did wonders for me',
		verified: false,
		url: 'https://twitter.com/CryptoKamado_/status/1436302976038096901',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1524849005666131968/d-S6tHPT_normal.png',
		name: 'Nik Sharma',
		handle: 'mrsharma',
		unixDateMS: 1631194078000,
		platform: 'twitter',
		content: '@m_franceschetti @ericosiu @eightsleep Better than bad sleep and then paying $3 a day for coffee!',
		verified: true,
		url: 'https://twitter.com/mrsharma/status/1435958160532049924',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1580188498748968961/L6lebFCL_normal.jpg',
		name: 'Louisa Nicola',
		handle: 'louisanicola_',
		unixDateMS: 1631112978000,
		platform: 'twitter',
		content:
			'I spent last night reviewing a study on the relationship between thermolregulation and depression. <br/><br/>As it turns out, in depressed people, something seems to go awry in the thermoregulation process. Research found that these patients body temperatures are higher.',
		verified: true,
		url: 'https://twitter.com/louisanicola_/status/1435618000036667393',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1680268585409847297/za1YjHcm_normal.jpg',
		name: 'Salina Mendoza',
		handle: 'inababi',
		unixDateMS: 1631054433000,
		platform: 'twitter',
		content:
			'Learned this recently. Investing in my sleep with @eightsleep, finally having a  routine, and seeing the benefits of sleeping on a cold mattress is unmatched 😍<br/><br/>Definitely feel like a different person ',
		verified: false,
		url: 'https://twitter.com/inababi/status/1435372445729366018',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1401163588258222087/H1V5cVVB_normal.jpg',
		name: 'Michael Ax',
		handle: 'axline3',
		unixDateMS: 1631010408000,
		platform: 'twitter',
		content: 'It’s been one week with my new @eightsleep mattress and the only problem so far is that I can’t make myself get out of bed!! This thing is life changing.',
		verified: false,
		url: 'https://twitter.com/axline3/status/1435187791042854914',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1379936079797755904/Q7GbtR7k_normal.jpg',
		name: 'Goodluck Raphael',
		handle: 'Goodluckraph',
		unixDateMS: 1630869331000,
		platform: 'twitter',
		content: '@m_franceschetti I so much admire the beauty of the product and the awesomeness of the team at @eightsleep',
		verified: false,
		url: 'https://twitter.com/Goodluckraph/status/1434596070609272840',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1561698926020988930/0fD0-mNc_normal.jpg',
		name: 'Brian J. Esposito',
		handle: 'brianjesposito',
		unixDateMS: 1630710659000,
		platform: 'twitter',
		content: '@m_franceschetti @marceloclaure @OpportunityFund @eightsleep @a_zatarain @maghis Such bad ass great work and execution.  The best is always still yet to come @eightsleep',
		verified: true,
		url: 'https://twitter.com/brianjesposito/status/1433930553124868099',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1019628812483604480/965ymS27_normal.jpg',
		name: 'Matt Haggman 🇺🇦',
		handle: 'matthaggman',
		unixDateMS: 1630679837000,
		platform: 'twitter',
		content: 'We’re a customer of @eightsleep and love it. Thrilled the company and @m_franceschetti call Miami home. ',
		verified: false,
		url: 'https://twitter.com/matthaggman/status/1433801275720847360',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1641945139177193473/SKxPKNAn_normal.jpg',
		name: 'Lorenzo Thione (he/him) 🏳️‍🌈',
		handle: 'thione',
		unixDateMS: 1630614633000,
		platform: 'twitter',
		content: 'Me and my @eightsleep . Yes, I’m a furnace. But this is #sleepfitness. Good sleep changes your life ',
		verified: true,
		url: 'https://twitter.com/thione/status/1433527792046641156',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/E-Tp7W5XsAcrGFG.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1495951506452926464/3YmnFVzr_normal.jpg',
		name: 'Vincenzo Landino',
		handle: 'vincenzolandino',
		unixDateMS: 1630511334000,
		platform: 'twitter',
		content: '@pomajp @whoop @eightsleep @gravityblankets @thornehealth Bro. Got my eight sleep. Haven’t slept on it yet. But I’m pumped.',
		verified: true,
		url: 'https://twitter.com/vincenzolandino/status/1433094521554980872',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1632572422636425217/ETR-Dpjp_normal.jpg',
		name: 'SlideJobSal',
		handle: 'SlideJobSal',
		unixDateMS: 1630502969000,
		platform: 'twitter',
		content: '@m_franceschetti @Nechiporchik @eightsleep @JamieVernon @joerogan With great a great product comes great praise! 🙏🏻🙌🏻',
		verified: false,
		url: 'https://twitter.com/SlideJobSal/status/1433059436726267904',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/978132096907599875/weBDVZH-_normal.jpg',
		name: 'Jason McMinn',
		handle: 'jasonmcminn',
		unixDateMS: 1630453042000,
		platform: 'twitter',
		content:
			"@MRossG199 @zebulgar 🙋🏽‍♂️Been using @eightsleep for over a year now. Best thing I have bought in 5 years.<br/><br/>The downside is I can't travel and sleep well anymore.<br/><br/>Would easily pay a hotel $15 extra per night for an @eightsleep and a @pillowcube combo upgrade.<br/><br/>Hotel = bed is hot &amp; pillow is too fluffy",
		verified: false,
		url: 'https://twitter.com/jasonmcminn/status/1432850027144105989',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1473747957568753682/0O5EufUp_normal.jpg',
		name: 'Dylan Korpita',
		handle: 'DKorp413',
		unixDateMS: 1630500320000,
		platform: 'twitter',
		content: "@crossan007 @KirkCodes @eightsleep might be worth a look. I don't have one but have heard good things.",
		verified: true,
		url: 'https://twitter.com/DKorp413/status/1433048327214575626',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1585498756493053954/D2BZg4T4_normal.jpg',
		name: 'Alex MacCaw',
		handle: 'maccaw',
		unixDateMS: 1630445248000,
		platform: 'twitter',
		content: "@Shpigford @eightsleep I borrowed a friend's recently and had one of the best night sleeps of my life on it.",
		verified: true,
		url: 'https://twitter.com/maccaw/status/1432817339490316289',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1587931405278973953/BojuvUe5_normal.jpg',
		name: 'Matt Galligan',
		handle: 'mg',
		unixDateMS: 1630443637000,
		platform: 'twitter',
		content: '@howardlindzon @bk_moore @eightsleep Yes it is',
		verified: true,
		url: 'https://twitter.com/mg/status/1432810579924766723',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1271264092729335808/og1Gc6ot_normal.jpg',
		name: 'Dr. Sohaib Imtiaz, MD MPH',
		handle: 'digitald0ctor',
		unixDateMS: 1631335674000,
		platform: 'twitter',
		content: 'When it’s too hot in the U.K. @eightsleep ',
		verified: true,
		url: 'https://twitter.com/digitald0ctor/status/1436552056962232320',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/E--oex8XIAEOy34.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1637570731314753536/Jp22Na2S_normal.jpg',
		name: 'Shreyans Singh',
		handle: 'shreyans512',
		unixDateMS: 1631250039000,
		platform: 'twitter',
		content:
			"Eight Sleep is another amazing startup I found on Twitter. They sell mattresses, but not normal ones.<br/><br/>Great people on Twitter can't stop praising Eight Sleep's mattress.<br/><br/>But what is so great in @eightsleep mattresses?<br/><br/>A thread👇👇",
		verified: false,
		url: 'https://twitter.com/shreyans512/status/1436192877827092506',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1031156227886084099/xPH5YsmC_normal.jpg',
		name: 'Tony Federico',
		handle: 'FitwithTfed',
		unixDateMS: 1631894975000,
		platform: 'twitter',
		content: "@tracyktownsend @eightsleep I get plenty of exercise and it's invaluable.  I sleep much better on it than I do on anything else.",
		verified: false,
		url: 'https://twitter.com/FitwithTfed/status/1438897933198450697',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1650512809740861442/2Rdhlvwu_normal.jpg',
		name: 'Noah Loffredo',
		handle: 'LoffredoNoah',
		unixDateMS: 1632662331000,
		platform: 'twitter',
		content: '@JonahLupton @eightsleep @FinTwitConf 2 months. I hate traveling without it😂',
		verified: false,
		url: 'https://twitter.com/LoffredoNoah/status/1442116459098828804',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1566894252684091395/rXWMcJ5q_normal.jpg',
		name: 'ericosiu',
		handle: 'ericosiu',
		unixDateMS: 1631193402000,
		platform: 'twitter',
		content:
			'Friend said they couldn’t justify paying for a $3,000 mattress.<br/><br/>If you use it for the next 5 years and you want high quality sleep:<br/><br/>$3k/(365 * 5) = $1.64<br/><br/>That’s $1.64/day to be well rested for the next 5 years.<br/><br/>Reframe.<br/><br/>Btw @eightsleep is the way. 😉',
		verified: true,
		url: 'https://twitter.com/ericosiu/status/1435955322825461763',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1014358626973069313/kuasBCIY_normal.jpg',
		name: 'Flo Crivello',
		handle: 'Altimor',
		unixDateMS: 1630345525000,
		platform: 'twitter',
		content: 'After a year with an eight sleep, strongly believe everyone will sleep like this 10-20 years from now. One of these life-changing innovations that seems obvious in hindsight. ',
		verified: false,
		url: 'https://twitter.com/Altimor/status/1432399070119833600',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1683667154435211264/JYiqb2DZ_normal.jpg',
		name: 'Tommy Collison',
		handle: 'tommycollison',
		unixDateMS: 1630333692000,
		platform: 'twitter',
		content:
			"The @eightsleep is one of those products where the delta between reality and the Twitter hype goes in the opposite direction than expected: the Pod is much better than you're expecting. Great product, can't wait to see what they do next. ",
		verified: true,
		url: 'https://twitter.com/tommycollison/status/1432349436336771073',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1369076725649346564/P8AMU3uL_normal.jpg',
		name: 'Bryan Rosenblatt',
		handle: 'BRosenblatt4',
		unixDateMS: 1630337464000,
		platform: 'twitter',
		content: 'Congrats to the entire @eightsleep team on this milestone. <br/><br/>I was a customer before I was an investor. I love this product. The hype is real on this one ⬆️🚀🎉❤️ ',
		verified: true,
		url: 'https://twitter.com/BRosenblatt4/status/1432365259893923844',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1584868536811495424/rMNXT5pl_normal.jpg',
		name: 'Patrick OShaughnessy',
		handle: 'patrick_oshag',
		unixDateMS: 1630162450000,
		platform: 'twitter',
		content: '@a_zatarain @eightsleep I recently moved into a new house and the only two things I moved by hand were my sons legos and eight sleep. Precious cargo!',
		verified: true,
		url: 'https://twitter.com/patrick_oshag/status/1431631198829355014',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1590747762676715520/V4pRkEBS_normal.jpg',
		name: 'Mike Annunziata',
		handle: 'nunzi46',
		unixDateMS: 1630332547000,
		platform: 'twitter',
		content: "@m_franceschetti @eightsleep Wouldn't trade my Eight Sleep for anything.  Congrats @m_franceschetti @a_zatarain and team!",
		verified: true,
		url: 'https://twitter.com/nunzi46/status/1432344637289598977',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1337800260639485955/kd8rUcqN_normal.jpg',
		name: 'Eric Glyman',
		handle: 'eglyman',
		unixDateMS: 1630331292000,
		platform: 'twitter',
		content:
			'Proud of @m_franceschetti, @a_zatarain and the @eightsleep team — we first met as batchmates in YC S15, unreal how much they’ve woken up the sleep industry in just a few short years. If you haven’t tried the pod pro yet, I highly recommend it, it’s made me much more productive ',
		verified: true,
		url: 'https://twitter.com/eglyman/status/1432339371445850112',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1488954469425369090/K6gc_LdO_normal.jpg',
		name: 'Trae Stephens',
		handle: 'traestephens',
		unixDateMS: 1630379287000,
		platform: 'twitter',
		content:
			'My friends are probably tired of me yammering on and on about @eightsleep, so I’ll just leave this right here without a bunch of added commentary.  Love being along for this ride!  And if you haven’t gotten a Pod yet, GET. ON. IT. ',
		verified: true,
		url: 'https://twitter.com/traestephens/status/1432540675891888140',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/811259797165195264/2jbXUZjE_normal.jpg',
		name: 'Lulu Cheng Meservey',
		handle: 'lulumeservey',
		unixDateMS: 1631140296000,
		platform: 'twitter',
		content: '@jimprosser Of course. It’s also company policy to only sleep on @eightsleep mattresses. We’re not amateurs',
		verified: true,
		url: 'https://twitter.com/lulumeservey/status/1435732580582760448',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1435825620110430212/2-iIQLte_normal.jpg',
		name: 'Randy Carballo',
		handle: 'randycarballo',
		unixDateMS: 1631191938000,
		platform: 'twitter',
		content: 'Making fun of me for getting a higher @eightsleep score last night (because she’s still in bed!) ',
		verified: true,
		url: 'https://twitter.com/randycarballo/status/1435949181009637378',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/E-2EKvZXIAIPXKn.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1633993252474064897/H8kgWbUv_normal.jpg',
		name: 'Dave Shinn',
		handle: 'davidjshinn',
		unixDateMS: 1627329618000,
		platform: 'twitter',
		content: 'Three nights in; I wish I had @eightsleep years ago. Unreal. Never slept better.',
		verified: false,
		url: 'https://twitter.com/davidjshinn/status/1419749440533893138',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1609005537416433665/MYcNjiJN_normal.jpg',
		name: 'Waldo',
		handle: 'waldobroodryk',
		unixDateMS: 1627758939000,
		platform: 'twitter',
		content:
			"@ShaneMac @eightsleep @m_franceschetti 👏 enjoy it, Shane! I've been struggling with insomnia for over 15 years and am sleeping WAY more while feeling rested in a long time on our POD Pro than ever before. The sleep tracking, heat/cool, and alarm features are awesome.<br/><br/>I have a goal of 8hrs of sleep per night ✨✨✨",
		verified: false,
		url: 'https://twitter.com/waldobroodryk/status/1421550141333544968',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1572058710297530371/chbrX2t-_normal.jpg',
		name: 'Marcos Ortiz',
		handle: 'marcosluis2186',
		unixDateMS: 1627751055000,
		platform: 'twitter',
		content: '@ShaneMac @eightsleep Best $2495 you will invest in your life. Seriously.',
		verified: true,
		url: 'https://twitter.com/marcosluis2186/status/1421517074187292677',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1526614458545491968/bwXqAC14_normal.jpg',
		name: 'Jeff Burke ⠕',
		handle: 'Jeff_Burke14',
		unixDateMS: 1627748707000,
		platform: 'twitter',
		content: '@ShaneMac @eightsleep Best purchase I made all year. Definitely worth it',
		verified: true,
		url: 'https://twitter.com/Jeff_Burke14/status/1421507226489872404',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1486241531627507712/w8IHwKAu_normal.jpg',
		name: 'Shahed Khan',
		handle: '_shahedk',
		unixDateMS: 1627743140000,
		platform: 'twitter',
		content: '@m_franceschetti @ShaneAParrish @ShaneMac @eightsleep Worth every penny',
		verified: false,
		url: 'https://twitter.com/_shahedk/status/1421483875788750849',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/428565835/Picture-1_normal.jpg',
		name: 'Thomas W. Clay',
		handle: 'thomaswclay',
		unixDateMS: 1627736554000,
		platform: 'twitter',
		content:
			"@ShaneMac @eightsleep Oh yes. Got mine in November. Since then, I haven't woken up in the middle of the night covered in sweat.<br/><br/>Used to happen once or twice a night.<br/><br/>Now, I sleep through the night unless my old man bladder wakes me up or something alerts the fur alarm and she barks.",
		verified: false,
		url: 'https://twitter.com/thomaswclay/status/1421456253264400387',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1551604023500980224/kYGGxPyq_normal.jpg',
		name: 'Ellen DaSilva',
		handle: 'ellenjdasilva',
		unixDateMS: 1627738367000,
		platform: 'twitter',
		content: '@ShaneMac @eightsleep So worth it. I truly can’t sleep without the cooling feature anymore. Believe the hype!',
		verified: false,
		url: 'https://twitter.com/ellenjdasilva/status/1421463855532752906',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1612942976359206913/dk9Olvw2_normal.jpg',
		name: 'Basil',
		handle: '_bsiddiqui',
		unixDateMS: 1627747206000,
		platform: 'twitter',
		content: '@ShaneMac @eightsleep 100% best investment I’ve made. <br/><br/>The mattress is solid but the tech is great. If you end up getting a different mattress you should still buy the cover.',
		verified: true,
		url: 'https://twitter.com/_bsiddiqui/status/1421500930969636864',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1661050115002449938/xXq_UwvD_normal.jpg',
		name: 'Andy McCune',
		handle: '9th',
		unixDateMS: 1627818725000,
		platform: 'twitter',
		content: '@herrmanndigital @bmoore__silly__ @PoppaReese @ShaneMac @eightsleep I use the cover and love it.',
		verified: true,
		url: 'https://twitter.com/9th/status/1421800902655053824',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1847044048/34047_409719181430_709001430_5120158_7524088_n_normal.jpg',
		name: 'Ammar Kubba',
		handle: 'AmmarKubba',
		unixDateMS: 1627832653000,
		platform: 'twitter',
		content: '@Shpigford @eightsleep You won’t regret it. I’m laying on top of mine as I type this, and I couldn’t be happier.',
		verified: true,
		url: 'https://twitter.com/AmmarKubba/status/1421859319193116675',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1162365327558225920/LBRRU1FK_normal.jpg',
		name: 'brian lamar moore 🥋',
		handle: 'bmoore__silly__',
		unixDateMS: 1627777267000,
		platform: 'twitter',
		content:
			'@herrmanndigital @PoppaReese @ShaneMac @eightsleep You’re going to love it. then you’re going to start thinking about how you wish you could go back in time and relive your life getting sleep as good as you’re about to start getting',
		verified: false,
		url: 'https://twitter.com/bmoore__silly__/status/1421627015589941258',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1259285101705396225/M3ZKDvYO_normal.jpg',
		name: 'Austin Rief ☕️',
		handle: 'austin_rief',
		unixDateMS: 1627762119000,
		platform: 'twitter',
		content: '@ShaneMac @eightsleep Big big fan.',
		verified: true,
		url: 'https://twitter.com/austin_rief/status/1421563480830889985',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1530185281843904512/e6JH2UUx_normal.jpg',
		name: 'David Ulevitch 🇺🇸',
		handle: 'davidu',
		unixDateMS: 1627734203000,
		platform: 'twitter',
		content: '@ShaneMac @eightsleep Can’t go back to life without it. Anxiety when I know I’ll be in a hotel without it. I prefer the cover over the bed but have both and like both.',
		verified: true,
		url: 'https://twitter.com/davidu/status/1421446390773731329',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1382796120611377154/0YbY53Mg_normal.jpg',
		name: 'Paul Yacoubian',
		handle: 'PaulYacoubian',
		unixDateMS: 1627687281000,
		platform: 'twitter',
		content: '@pvm @lizgiorgi I get 8 hours of amazing sleep with @eightsleep 😂. Love good rest. Twitter friends and team keep me sane too. Family keeps me grounded.',
		verified: true,
		url: 'https://twitter.com/PaulYacoubian/status/1421249587826864130',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1587931405278973953/BojuvUe5_normal.jpg',
		name: 'Matt Galligan',
		handle: 'mg',
		unixDateMS: 1627734911000,
		platform: 'twitter',
		content:
			'@sheynk @ShaneMac @eightsleep I’ve got the Pod Pro Cover on top of a 10 year old (but still awesome) mattress. Made such a huge difference in my quality of sleep as I’m a super hot sleeper.',
		verified: true,
		url: 'https://twitter.com/mg/status/1421449359728812039',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1609005537416433665/MYcNjiJN_normal.jpg',
		name: 'Waldo',
		handle: 'waldobroodryk',
		unixDateMS: 1627734538000,
		platform: 'twitter',
		content:
			"@ShaneMac @eightsleep I've had it for about a year and have loved it. Especially living somewhere with limited AC. My partner likes it warmer, and I like it cooler, so it helped a lot there. I recommend it, personally. 😁",
		verified: false,
		url: 'https://twitter.com/waldobroodryk/status/1421447797312671747',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1601732290685591557/UOaMnW3e_normal.jpg',
		name: 'Rebecca Lerner',
		handle: 'rebeccall80',
		unixDateMS: 1627768211000,
		platform: 'twitter',
		content: '@ShaneMac @eightsleep @m_franceschetti Best decision.',
		verified: false,
		url: 'https://twitter.com/rebeccall80/status/1421589031528378382',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1382320276021645315/ogKAu29l_normal.jpg',
		name: 'Jeroen Bertrams',
		handle: 'jeroenbertrams',
		unixDateMS: 1627761574000,
		platform: 'twitter',
		content: '@ShaneMac @eightsleep Totally recommend :)',
		verified: false,
		url: 'https://twitter.com/jeroenbertrams/status/1421561193974620164',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1689366142148542464/TiBxxmGj_normal.jpg',
		name: "Uncle Junior's Bag Man 💰",
		handle: 'moneyballinvest',
		unixDateMS: 1627910876000,
		platform: 'twitter',
		content:
			"Real talk regarding @eightsleep <br/><br/>It's been 1 week since I set it up and started sleeping on it.<br/><br/>Before @eightsleep I constantly woke up in the middle of the night (sometimes 3-4 times per night, and felt more tired when I woke up then when I went to bed)",
		verified: false,
		url: 'https://twitter.com/moneyballinvest/status/1422187412214665217',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1430670368960704513/sN3kclbe_normal.jpg',
		name: 'cmills.eth',
		handle: 'LymelightTO',
		unixDateMS: 1627749554000,
		platform: 'twitter',
		content:
			'@ShaneMac @eightsleep If you\'re a hot sleeper, it\'s the only "real" solution to your problem.<br/><br/>Anecdotally, I am much more likely to wake up spontaneously just before my alarm, and much less likely to wake up in the middle of the night. Both of these outcomes make it worth it, for me.',
		verified: false,
		url: 'https://twitter.com/LymelightTO/status/1421510776288735238',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1413162073354383371/7Kv17LAN_normal.jpg',
		name: 'Frank Dale',
		handle: 'frankcdale',
		unixDateMS: 1627747459000,
		platform: 'twitter',
		content: "@ShaneMac @eightsleep I sleep so much better since we got it. It isn't hype, it's real.",
		verified: false,
		url: 'https://twitter.com/frankcdale/status/1421501991210135558',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1528907467253596160/jfu8AffN_normal.jpg',
		name: 'Brad Siegler 🇺🇸 🚀',
		handle: 'BradSiegler',
		unixDateMS: 1627748656000,
		platform: 'twitter',
		content: '@ShaneMac @eightsleep Not a question. Do it. It’s not just a bed, it’s sleep software + hardware.',
		verified: true,
		url: 'https://twitter.com/BradSiegler/status/1421507012345667588',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1526614458545491968/bwXqAC14_normal.jpg',
		name: 'Jeff Burke ⠕',
		handle: 'Jeff_Burke14',
		unixDateMS: 1627568479000,
		platform: 'twitter',
		content: '@m_franceschetti @eightsleep The @eightsleep is one of the best investments we ever made. <br/><br/>Go ahead and sign me up for whatever you are building.',
		verified: true,
		url: 'https://twitter.com/Jeff_Burke14/status/1420751295737073674',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1366172005385596930/f__vrnow_normal.jpg',
		name: 'Max Wendkos',
		handle: 'maxwendkos',
		unixDateMS: 1627522810000,
		platform: 'twitter',
		content: "@jrichlive @tommycollison @eightsleep Can confirm it's a game-changer. Easily my #1.",
		verified: false,
		url: 'https://twitter.com/maxwendkos/status/1420559746466189313',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1683667154435211264/JYiqb2DZ_normal.jpg',
		name: 'Tommy Collison',
		handle: 'tommycollison',
		unixDateMS: 1627520258000,
		platform: 'twitter',
		content: '@jrichlive @eightsleep, Anki',
		verified: true,
		url: 'https://twitter.com/tommycollison/status/1420549041050460162',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1633993252474064897/H8kgWbUv_normal.jpg',
		name: 'Dave Shinn',
		handle: 'davidjshinn',
		unixDateMS: 1627331201000,
		platform: 'twitter',
		content:
			'@courtstarr @m_franceschetti @eightsleep The cover! Although starting to think I need to pick up the mattress too; my Casper is not looking it’s best anyway. Can’t recommend the cover enough if you’re on the fence.',
		verified: false,
		url: 'https://twitter.com/davidjshinn/status/1419756079861272597',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1668259073664450560/2fucW__T_normal.jpg',
		name: 'CryptoJustin',
		handle: 'TheCryptoCatGuy',
		unixDateMS: 1627911532000,
		platform: 'twitter',
		content:
			"I second this... <br/><br/>The only reason I wake up in the middle of the night anymore is because one of my 3 cats jumps on me. <br/><br/>Used to wake up 3-5 times a night from overheating prior to getting the @eightsleep <br/><br/>Life changing isn't an overstatement. ",
		verified: false,
		url: 'https://twitter.com/TheCryptoCatGuy/status/1422190160888872961',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1664270968662130694/PUSi89aW_normal.jpg',
		name: 'Jack Appleby',
		handle: 'jappleby',
		unixDateMS: 1631896617000,
		platform: 'twitter',
		content: "@AvaGG I'm team @eightsleep. Amazing mattress, plus I've used the temperature controls to help body pain on the heat vs. cold side.",
		verified: true,
		url: 'https://twitter.com/jappleby/status/1438904819989848066',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1470138740253286404/-Lji9DDh_normal.jpg',
		name: 'matt kita',
		handle: 'kitathinking',
		unixDateMS: 1627418838000,
		platform: 'twitter',
		content: '@PayItForwardDad Have you checked out @eightsleep?  It’s been a game changer for me.',
		verified: false,
		url: 'https://twitter.com/kitathinking/status/1420123655032426496',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1689366142148542464/TiBxxmGj_normal.jpg',
		name: "Uncle Junior's Bag Man 💰",
		handle: 'moneyballinvest',
		unixDateMS: 1627910876000,
		platform: 'twitter',
		content:
			"With @eightsleep I have woken up in the middle of the night....once. One. Time. Aaaannndddd it was because my 🐱jumped on the bed and it startled me, thus waking me up.<br/><br/>This is more than a bed, it's a life changer<br/><br/>#sleep #sleepfitness",
		verified: false,
		url: 'https://twitter.com/moneyballinvest/status/1422187413049315329',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1547787118243561478/PxWzJo1H_normal.jpg',
		name: 'Pomp 🌪',
		handle: 'APompliano',
		unixDateMS: 1605838319000,
		platform: 'twitter',
		content:
			"6/ The third most important thing I did to increase sleep quality was get a thermoregulating bed. <br/><br/>I've been sleeping on a @eightsleep Pod Pro and Pod Pro Cover.  <br/><br/>The secret — I make it cold AF and magically get DEEP sleep.",
		verified: true,
		url: 'https://twitter.com/APompliano/status/1329608398770483200',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1580188498748968961/L6lebFCL_normal.jpg',
		name: 'Louisa Nicola',
		handle: 'louisanicola_',
		unixDateMS: 1625713321000,
		platform: 'twitter',
		content: '@rowantonkin @eightsleep @whoop I have the pod pro cover. It’s been the best change I have implemented',
		verified: true,
		url: 'https://twitter.com/louisanicola_/status/1412970196395073536',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1524849005666131968/d-S6tHPT_normal.png',
		name: 'Nik Sharma',
		handle: 'mrsharma',
		unixDateMS: 1623250995000,
		platform: 'twitter',
		content: '@m_franceschetti @AndrewVoirol @eightsleep @APompliano 100% get the pod pro cover!!',
		verified: true,
		url: 'https://twitter.com/mrsharma/status/1402642452729765900',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1530185281843904512/e6JH2UUx_normal.jpg',
		name: 'David Ulevitch 🇺🇸',
		handle: 'davidu',
		unixDateMS: 1613192328000,
		platform: 'twitter',
		content:
			'@raja2fly @eightsleep 💯💯💯 I have two homes and now have two @eightsleep’s — one pod pro and one pod pro cover. Big fan of both. I already had a new mattress for one bed so got cover.',
		verified: true,
		url: 'https://twitter.com/davidu/status/1360453347682099202',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/2598602485/hl9eja8jl3zc2anxsode_normal.png',
		name: 'Peter Pham',
		handle: 'peterpham',
		unixDateMS: 1615153843000,
		platform: 'twitter',
		content:
			"@LaurenBerson @eightsleep Yes. No brainer. Don't have to wear anything. And it adjusts temp 4 times thru night. When you go to bed. Fall asleep, deep sleep and wake up. The alarm is best feature.",
		verified: true,
		url: 'https://twitter.com/peterpham/status/1368680536726671363',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1492261452815560707/2RCk60zz_normal.jpg',
		name: 'Thomas Smale',
		handle: 'ThomasSmale',
		unixDateMS: 1617469562000,
		platform: 'twitter',
		content:
			'@TaylorSicard I do - love it. Definitely helped with sleep and my wife loves it too (and was sceptical initially). We both sleep at quite different temps - not something that could be easily solved without it',
		verified: false,
		url: 'https://twitter.com/ThomasSmale/status/1378393366086381569',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1587931405278973953/BojuvUe5_normal.jpg',
		name: 'Matt Galligan',
		handle: 'mg',
		unixDateMS: 1609863812000,
		platform: 'twitter',
		content:
			'My @eightsleep Pod Pro cover takes the next spot. I didn’t know what thermoregulation even was, but after seeing @Matteoceschetti’s passion for the subject of sleep fitness, it seemed worthwhile to dig in.<br/><br/>So far it’s easy to say that it has given me the best sleep of my life. ',
		verified: true,
		url: 'https://twitter.com/mg/status/1346492539281551360',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/Eq-z0_wXEAAtKsY.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1562804983426207744/aUr_N2k8_normal.jpg',
		name: 'Nelly',
		handle: 'NelsonsThoughts',
		unixDateMS: 1609744291000,
		platform: 'twitter',
		content: '@mfg @eightsleep @thekrishjay @beijingdou It’s life changing. I highly recommend it. Getting the best sleep of my life.',
		verified: false,
		url: 'https://twitter.com/NelsonsThoughts/status/1345991231084527616',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1602122467002155010/MI7V7cqu_normal.png',
		name: 'Josh Ong',
		handle: 'beijingdou',
		unixDateMS: 1609727429000,
		platform: 'twitter',
		content:
			'@mfg @eightsleep @thekrishjay To be clear, I got a tattoo before I had the bed. But last two nights have been amazing. Really great for partners with different sleep temp preferences. Tracking is helpful.',
		verified: true,
		url: 'https://twitter.com/beijingdou/status/1345920506873208834',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1485777379075051522/3dF7aXYZ_normal.jpg',
		name: 'Aaron Suplizio 🎸',
		handle: 'AaronSuplizio',
		unixDateMS: 1626321138000,
		platform: 'twitter',
		content: '@DavidSven @eightsleep Pod Pro Cover is moh-ney',
		verified: false,
		url: 'https://twitter.com/AaronSuplizio/status/1415519567141425153',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1366172005385596930/f__vrnow_normal.jpg',
		name: 'Max Wendkos',
		handle: 'maxwendkos',
		unixDateMS: 1609101619000,
		platform: 'twitter',
		content:
			'I sleep really hot and have bought a bunch of self-proclaimed "cooling products" products over the years to try make myself more comfortable. None of them have made a difference.<br/><br/>I slept on @eightsleep\'s Pod Pro Cover for the first time last night and... it actually works! ',
		verified: false,
		url: 'https://twitter.com/maxwendkos/status/1343295667532394506',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1629526164665450500/MuIuDqqp_normal.jpg',
		name: 'Jesus Mu 2',
		handle: 'ManRam2',
		unixDateMS: 1606064691000,
		platform: 'twitter',
		content: 'First night with @eightsleep pod pro cover!  Wow me and the wife both noticed a difference night 1. ',
		verified: false,
		url: 'https://twitter.com/ManRam2/status/1330557868244946944',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EncXVE9XYAAZdOv.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1038827045684477953/pE0HRlx-_normal.jpg',
		name: 'Brett Wright',
		handle: 'wrightmbrett',
		unixDateMS: 1608989928000,
		platform: 'twitter',
		content: '@grahamcodes @zebulgar @rsg @davidu @eightsleep @Purple I have the purple with the pod pro cover. Great combo, highly recommend.',
		verified: false,
		url: 'https://twitter.com/wrightmbrett/status/1342827204565200901',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1292184882291113986/aMzbJNBl_normal.jpg',
		name: 'William ∞ Willis',
		handle: 'William3Willis',
		unixDateMS: 1610636644000,
		platform: 'twitter',
		content: '@kallawavy Great piece.<br/><br/>Kudos for introducing me to Oura Ring and Eight Sleep Pod Pro Cover!',
		verified: false,
		url: 'https://twitter.com/William3Willis/status/1349734031273861122',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1601002664640274433/985LZbUV_normal.jpg',
		name: 'John Egan',
		handle: 'john_m_egan',
		unixDateMS: 1623367183000,
		platform: 'twitter',
		content: '@RyanVestby @eightsleep The Pod Pro cover makes any bed feel comfortable',
		verified: true,
		url: 'https://twitter.com/john_m_egan/status/1403129780761923586',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1676982730461163521/RrktC3zP_normal.jpg',
		name: 'Marcelo Claure',
		handle: 'marceloclaure',
		unixDateMS: 1624026511000,
		platform: 'twitter',
		content:
			'Founders of @eightsleep @m_franceschetti and @a_zatarain saw a need to bring people the best sleep possible, so they wielded the power of tech to do it. As someone who uses a Pod Pro mattress, I can personally say they accomplished that dream. Never slept better!',
		verified: true,
		url: 'https://twitter.com/marceloclaure/status/1405895201894322182',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1592194144608346112/NrTJ81lQ_normal.jpg',
		name: '𝖱𝖮𝖡 𝖬𝖠𝖣𝖤𝖫𝖬𝖠𝖸𝖤𝖱 🇺🇸',
		handle: 'RMadelmayer',
		unixDateMS: 1623788428000,
		platform: 'twitter',
		content:
			'my wife and I love @eightsleep so much we have two, one for us, and one for our guest bedroom <br/><br/>after months trying to find a way to invest in the company, i found a way in through @APompliano’s syndicate and wired my investment today <br/><br/>invest in what you believe in<br/><br/>🚀LFG🚀',
		verified: true,
		url: 'https://twitter.com/RMadelmayer/status/1404896609536782337',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1622329017641705473/raG3jjGs_normal.jpg',
		name: 'Taylor Welch',
		handle: 'taylorawelch',
		unixDateMS: 1627138415000,
		platform: 'twitter',
		content:
			'3. Eight sleep pod cover <br/><br/>Lowers bed temperature in deep sleep, raises bed temperature in REM. Keeps you asleep longer and tracks metrics to regulate its “autopilot” smart temp.',
		verified: true,
		url: 'https://twitter.com/taylorawelch/status/1418947477181542415',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1518053055345831936/Agjy1JYZ_normal.jpg',
		name: 'Marcus Ellison',
		handle: 'marcojelli',
		unixDateMS: 1627250673000,
		platform: 'twitter',
		content:
			'@mynameis_davis @eightsleep They sell mattress covers and mattresses that regulate temperature throughout the night based on your preferences (cool or hot). <br/><br/>I get at least 1 more hour of sleep each night compared to without it.<br/><br/>The overall effect on my health and well being has been incredible.',
		verified: false,
		url: 'https://twitter.com/marcojelli/status/1419418318721544192',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1633993252474064897/H8kgWbUv_normal.jpg',
		name: 'Dave Shinn',
		handle: 'davidjshinn',
		unixDateMS: 1627331201000,
		platform: 'twitter',
		content:
			'@courtstarr @m_franceschetti @eightsleep The cover! Although starting to think I need to pick up the mattress too; my Casper is not looking it’s best anyway. Can’t recommend the cover enough if you’re on the fence.',
		verified: false,
		url: 'https://twitter.com/davidjshinn/status/1419756079861272597',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1518053055345831936/Agjy1JYZ_normal.jpg',
		name: 'Marcus Ellison',
		handle: 'marcojelli',
		unixDateMS: 1627249282000,
		platform: 'twitter',
		content: '@mynameis_davis Prob @eightsleep  mattress cover',
		verified: false,
		url: 'https://twitter.com/marcojelli/status/1419412483094183936',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1509244530427531266/HDbTVoOy_normal.png',
		name: 'Addison',
		handle: 'addison',
		unixDateMS: 1627344292000,
		platform: 'twitter',
		content: '@courtstarr @davidjshinn @m_franceschetti @eightsleep Cover boy here. Love it. Using Oura ring for data because of restless pets',
		verified: true,
		url: 'https://twitter.com/addison/status/1419810986706411525',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1637921189954723840/atXvIWqL_normal.jpg',
		name: 'Flynn 🌱',
		handle: 'Aaraflynn',
		unixDateMS: 1627068796000,
		platform: 'twitter',
		content: '@AniGeekVA @Venalis21 We got the eightsleep mattress cover! WATERCOOLED BED!',
		verified: false,
		url: 'https://twitter.com/Aaraflynn/status/1418655470508720130',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1601415524205809664/-2s0UsVT_normal.jpg',
		name: 'Dan Zurek',
		handle: 'DanZurek_',
		unixDateMS: 1623799632000,
		platform: 'twitter',
		content: '@RMadelmayer @eightsleep @APompliano Love @eightsleep. How do you get involved with @APompliano’s syndicate?',
		verified: true,
		url: 'https://twitter.com/DanZurek_/status/1404943606033518592',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1526614458545491968/bwXqAC14_normal.jpg',
		name: 'Jeff Burke ⠕',
		handle: 'Jeff_Burke14',
		unixDateMS: 1623812000000,
		platform: 'twitter',
		content: '@henrylmcnamara That’s where the @eightsleep comes in. Game changer in an SF apartment with no A/C!',
		verified: true,
		url: 'https://twitter.com/Jeff_Burke14/status/1404995481265053698',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1411412842037735424/rRL544u6_normal.jpg',
		name: 'Cindy Bi ㊙️',
		handle: 'CindyBiV',
		unixDateMS: 1623910729000,
		platform: 'twitter',
		content: "@depappas Finally getting an @eightsleep it's about time 😇",
		verified: false,
		url: 'https://twitter.com/CindyBiSV/status/1405409577517027336',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1610203616308084737/GQVx_oRg_normal.jpg',
		name: 'nathan',
		handle: 'nattyshaps',
		unixDateMS: 1624046220000,
		platform: 'twitter',
		content: '@karimatiyeh @eightsleep Just got mine last week! Its actually game changing. The vibrating alarm to wake up is awesome feature too',
		verified: true,
		url: 'https://twitter.com/nattyshaps/status/1405977867834458114',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1589159524824326144/BCZe55Fl_normal.jpg',
		name: 'Karim Atiyeh',
		handle: 'karimatiyeh',
		unixDateMS: 1624036150000,
		platform: 'twitter',
		content: 'New Home New Eight Sleep 🤩😍😍. Thanks @eightsleep ',
		verified: true,
		url: 'https://twitter.com/karimatiyeh/status/1405935634129342470',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/E4Li4_vXwAUYnJS.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1254825219962933255/OsNpqZwI_normal.jpg',
		name: 'Paul Tribbey',
		handle: 'PaulTribbey',
		unixDateMS: 1623068432000,
		platform: 'twitter',
		content:
			'@m_franceschetti @Zimvesting best product I’ve ever bought(aside from F1 sim...). Went from sleeping 3-4 hours a night to sleeping 7-8 easily. -6 for me +4 for my wife. Can’t recommend it enough. ',
		verified: false,
		url: 'https://twitter.com/PaulTribbey/status/1401876728667119621',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/E3R3dDCXwAUJEqd.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/751106598026637313/ocWvWRkq_normal.jpg',
		name: 'Erika Brown',
		handle: 'ErikaB',
		unixDateMS: 1622926037000,
		platform: 'twitter',
		content: 'Hey look ma I made it! :) Well, my tweet did! Still huge fans of our @eightsleep. Absolute game changer. ',
		verified: false,
		url: 'https://twitter.com/ErikaB/status/1401279479163473921',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1031156227886084099/xPH5YsmC_normal.jpg',
		name: 'Tony Federico',
		handle: 'FitwithTfed',
		unixDateMS: 1622753151000,
		platform: 'twitter',
		content:
			'@zebulgar @_CSMiller @m_franceschetti @eightsleep @mkummer82 @katietypea One thing I can add if it helps is 77-80 degrees is what we keep our bedroom at in the summer and I sleep with a blanket like a baby all night long (wife does too!). I didn’t see any type of spike in my electric bill since having it.',
		verified: false,
		url: 'https://twitter.com/FitwithTfed/status/1400554344274145284',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1541488975864537088/fuqAL7e3_normal.jpg',
		name: 'Erik Pavia',
		handle: 'erikpavia',
		unixDateMS: 1622774168000,
		platform: 'twitter',
		content:
			'@a_zatarain @m_franceschetti @_CSMiller @zebulgar @eightsleep @FitwithTfed @mkummer82 @katietypea This should be in your marketing for us people in the desert! It was a huge selling point for me (I didn’t know the wattage but I assumed cooling the surface of a 6’ x 2’ man is more efficient than cooling an entire 10’x 10’ x 10’ room, much less a whole apartment).',
		verified: true,
		url: 'https://twitter.com/erikpavia/status/1400642495499804674',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1679150934222413825/pcOefUzm_normal.jpg',
		name: 'Bruno Brasil Faviero | Co-Founder @ Magna',
		handle: 'Bfaviero',
		unixDateMS: 1622473583000,
		platform: 'twitter',
		content: 'The only thing you should be sleeping on is an @eightsleep ',
		verified: true,
		url: 'https://twitter.com/Bfaviero/status/1399381752301428744',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1525673296792113152/RwbJHL7-_normal.jpg',
		name: 'Jesse Daugherty',
		handle: 'jtdaugh',
		unixDateMS: 1622577085000,
		platform: 'twitter',
		content: '@swartzcenter Game changer, never wake up hot again: @eightsleep <br/><br/>',
		verified: true,
		url: 'https://twitter.com/jtdaugh/status/1399815870025814024',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1524849005666131968/d-S6tHPT_normal.png',
		name: 'Nik Sharma',
		handle: 'mrsharma',
		unixDateMS: 1622493607000,
		platform: 'twitter',
		content: '@AmandaMGoetz @house__of__wise @eightsleep The perfect sleep setup is the combo of 8 + HOW sleep gummy',
		verified: true,
		url: 'https://twitter.com/mrsharma/status/1399465738872799232',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1098432434507415552/wX4VHYgf_normal.jpg',
		name: 'Dave McDowell',
		handle: 'davemcdowell',
		unixDateMS: 1622517850000,
		platform: 'twitter',
		content: '@JoshuaBaer I think you were the first person I knew with @Tesla (orange, if I remember.) Do yourself a favor and get the Tesla of beds @eightsleep.',
		verified: false,
		url: 'https://twitter.com/davemcdowell/status/1399567422080782339',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/676842341492101120/g3cKbc_A_normal.jpg',
		name: 'Andres Ro',
		handle: 'AndresARobelo',
		unixDateMS: 1622510824000,
		platform: 'twitter',
		content: '@JoshuaBaer @eightsleep Hands Down! Got mine in Miami game changer!',
		verified: true,
		url: 'https://twitter.com/AndresARobelo/status/1399537950145368068',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1291481749399183360/xqPFhPBS_normal.jpg',
		name: 'Chris Lu',
		handle: 'chris__lu',
		unixDateMS: 1622756287000,
		platform: 'twitter',
		content: '@sharifshameem Love the sleeping tips! You have to check out @eightsleep!',
		verified: true,
		url: 'https://twitter.com/chris__lu/status/1400567497754124290',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1031156227886084099/xPH5YsmC_normal.jpg',
		name: 'Tony Federico',
		handle: 'FitwithTfed',
		unixDateMS: 1622753151000,
		platform: 'twitter',
		content:
			'@zebulgar @_CSMiller @m_franceschetti @eightsleep @mkummer82 @katietypea One thing I can add if it helps is 77-80 degrees is what we keep our bedroom at in the summer and I sleep with a blanket like a baby all night long (wife does too!). I didn’t see any type of spike in my electric bill since having it.',
		verified: false,
		url: 'https://twitter.com/FitwithTfed/status/1400554344274145284',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1653591604542799872/pdursReh_normal.jpg',
		name: 'Jason P. Yoong',
		handle: 'JasonYoong',
		unixDateMS: 1622522197000,
		platform: 'twitter',
		content: '@mattchinni I hear @eightsleep is good if you have different preferences (cold vs hot).',
		verified: false,
		url: 'https://twitter.com/JasonYoong/status/1399585654514716674',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1483572454979031040/HZgTqHjX_normal.jpg',
		name: '@jason',
		handle: 'Jason',
		unixDateMS: 1621451520000,
		platform: 'twitter',
		content: '@kitathinking @eightsleep @phneep @cryptograffiti love my @eightsleep so much I invested in the company!',
		verified: true,
		url: 'https://twitter.com/Jason/status/1395094909351120896',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1584868536811495424/rMNXT5pl_normal.jpg',
		name: 'Patrick OShaughnessy',
		handle: 'patrick_oshag',
		unixDateMS: 1621706070000,
		platform: 'twitter',
		content:
			'I’ve been a big fan of @eightsleep (I’m a long time user now, and an investor)...but I’d didn’t realize how big a fan I was until traveling and sleeping in hotel beds.<br/><br/>I hope hotel chains adopt them and let me load my settings. It’s the main way I’d pick hotels. Im hooked 4ever.',
		verified: true,
		url: 'https://twitter.com/patrick_oshag/status/1396162569346457603',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/509770564123111424/aMuq_28G_normal.jpeg',
		name: 'David Eckstein',
		handle: 'davidneckstein',
		unixDateMS: 1621858648000,
		platform: 'twitter',
		content:
			'@alexpriest @amysundae @eightsleep @m_franceschetti I’ve owned 4 of these hydro cooled mattresses. Never a single issue. Can’t recommend this product enough. Also helps cut down on AC bills during the hot summer.',
		verified: false,
		url: 'https://twitter.com/davidneckstein/status/1396802526155087874',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1363316601974644736/JKdIckKh_normal.jpg',
		name: 'Matthew Dellavedova',
		handle: 'matthewdelly',
		unixDateMS: 1621715089000,
		platform: 'twitter',
		content: '@patrick_oshag @eightsleep I feel exactly the same way when we go on the road - incredible the difference. Will be a huge advantage for hotels going forward',
		verified: true,
		url: 'https://twitter.com/matthewdelly/status/1396200396759003138',
		categories: ['Familiar faces'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/978132096907599875/weBDVZH-_normal.jpg',
		name: 'Jason McMinn',
		handle: 'jasonmcminn',
		unixDateMS: 1621721793000,
		platform: 'twitter',
		content: '@rjonesy @Casper You need @eightsleep anyway. It is the 💩and you will be glad this happened. Best purchase of mine in 5 years',
		verified: false,
		url: 'https://twitter.com/jasonmcminn/status/1396228514773291009',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1359759143687520259/fH_yh3s-_normal.jpg',
		name: 'Liana Herrera',
		handle: 'lianakherrera',
		unixDateMS: 1621708845000,
		platform: 'twitter',
		content: '@eightsleep 🔥 legibility makes everything great. ',
		verified: false,
		url: 'https://twitter.com/lianakherrera/status/1396174206887370752',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1526614458545491968/bwXqAC14_normal.jpg',
		name: 'Jeff Burke ⠕',
		handle: 'Jeff_Burke14',
		unixDateMS: 1621708127000,
		platform: 'twitter',
		content: '@SahilBloom @patrick_oshag @eightsleep Got it this week. I love it. Well worth it. @m_franceschetti knocked it out of the park.',
		verified: true,
		url: 'https://twitter.com/Jeff_Burke14/status/1396171198539583489',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1481313178302754821/eeHGWpUF_normal.jpg',
		name: 'Michael Arrington 🏴‍☠️ 🛡️',
		handle: 'arrington',
		unixDateMS: 1618874029000,
		platform: 'twitter',
		content: 'Life hack: @joinfightcamp + @levels + @eightsleep is probably what heaven feels like.',
		verified: true,
		url: 'https://twitter.com/arrington/status/1384284125817761794',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1255917943990312960/XaGzVhzh_normal.jpg',
		name: 'steijnpelle',
		handle: 'steijnpelle',
		unixDateMS: 1620490310000,
		platform: 'twitter',
		content: 'Slept like a 🪨 in the @eightsleep suite. Mattress kept me cool all night long. Pretty neat. ',
		verified: true,
		url: 'https://twitter.com/steijnpelle/status/1391063301589803010',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/E04Msi_XIAcsvhM.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1374866984781942788/PRyApeoC_normal.jpg',
		name: 'Princeps Randomus',
		handle: 'random986196507',
		unixDateMS: 1621792533000,
		platform: 'twitter',
		content: '@patrick_oshag @eightsleep @eightsleep kicks ass. Can’t go back',
		verified: false,
		url: 'https://twitter.com/random986196507/status/1396525221826400258',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1584868536811495424/rMNXT5pl_normal.jpg',
		name: 'Patrick OShaughnessy',
		handle: 'patrick_oshag',
		unixDateMS: 1621706256000,
		platform: 'twitter',
		content:
			'Once you realize how much better good sleep makes your life, the bed becomes a need to have, not a nice to have.<br/><br/>Will be interesting to see how @m_franceschetti @a_zatarain and team continue to improve these beds.<br/><br/>Feels like iPhone...something people will upgrade each cycle.',
		verified: true,
		url: 'https://twitter.com/patrick_oshag/status/1396163350216773635',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/718598294323073024/qu7IlBA8_normal.jpg',
		name: 'Billy Jalbert',
		handle: 'Mauibilly',
		unixDateMS: 1620077426000,
		platform: 'twitter',
		content: '@harleyf Sleep hygiene and appropriate tools for measurement and enhancement (@eightsleep @ouraring).',
		verified: false,
		url: 'https://twitter.com/Mauibilly/status/1389331540467875841',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1603446891944980481/-kSXN5NF_normal.jpg',
		name: 'Sahil from Bravado',
		handle: 'SVMansuri',
		unixDateMS: 1620912546000,
		platform: 'twitter',
		content: '@m_franceschetti I love the @eightsleep.<br/><br/>Thanks for making this amazing product. ',
		verified: true,
		url: 'https://twitter.com/SVMansuri/status/1392834289390080000',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/E1RXZ2LVUAUBnEy.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1473904471151464451/FknfQHg6_normal.jpg',
		name: 'Wesley Csendom',
		handle: 'wcsendom',
		unixDateMS: 1620841318000,
		platform: 'twitter',
		content: '@sweatystartup Have you checked out @eightsleep ? It’s been incredible for sleep analytics and my sleep quality.',
		verified: false,
		url: 'https://twitter.com/wcsendom/status/1392535537261613059',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1037814384049807360/BOHoIlET_normal.jpg',
		name: 'Jennifer Perkins',
		handle: 'jenperkinsaz',
		unixDateMS: 1621264176000,
		platform: 'twitter',
		content:
			'@emzanotti We love our @eightsleep mattress. It is memory foam and comfortable, but that it allows climate control--with separate zones for each side--has been a game-changer for good sleep.',
		verified: false,
		url: 'https://twitter.com/jenperkinsaz/status/1394309130572025865',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1637254773/mypic_normal.jpeg',
		name: 'Dan Patrick',
		handle: 'mdpatrick',
		unixDateMS: 1620840012000,
		platform: 'twitter',
		content: '@m_franceschetti @eightsleep You guys were crushing the innovation pre-softbank already. Can’t wait to see what kind of full dive magic gets unleashed now.',
		verified: true,
		url: 'https://twitter.com/mdpatrick/status/1392530058946908164',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1603446891944980481/-kSXN5NF_normal.jpg',
		name: 'Sahil from Bravado',
		handle: 'SVMansuri',
		unixDateMS: 1620913870000,
		platform: 'twitter',
		content: '@m_franceschetti @eightsleep It\'s not even the "fitness" club. I literally can\'t sleep without this magical product.',
		verified: true,
		url: 'https://twitter.com/SVMansuri/status/1392839842547986435',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/829543250994266112/J7gFzLy5_normal.jpg',
		name: 'Candice Vega',
		handle: 'candiceskitchen',
		unixDateMS: 1620263788000,
		platform: 'twitter',
		content: 'This makes me so happy. @eightsleep has been truly amazing. I get so excited to get into my warm bed. ',
		verified: false,
		url: 'https://twitter.com/candiceskitchen/status/1390113199983190016',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/E0qsloXWYAAbBqL.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1680268585409847297/za1YjHcm_normal.jpg',
		name: 'Salina Mendoza',
		handle: 'inababi',
		unixDateMS: 1620279898000,
		platform: 'twitter',
		content:
			'One thing I like about this bed as someone with adhd and also never really had parental figures that told me what to do.....having routine rocks 😍<br/><br/>Someone telling me I have a bedtime aka @eightsleep is cool and silences my calls, etc for me automatically. <br/><br/>Productivity 📈',
		verified: false,
		url: 'https://twitter.com/inababi/status/1390180767762771968',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1340847802037911554/yYzK9mBK_normal.jpg',
		name: 'Jeff Baietto',
		handle: 'jeffinjoyglobal',
		unixDateMS: 1620265211000,
		platform: 'twitter',
		content:
			'@candiceskitchen @m_franceschetti @eightsleep Nice!  I just got mine and I’m equally excited but on the other end of the temperature spectrum. I love getting into a cool/cold bed... thanks @m_franceschetti',
		verified: false,
		url: 'https://twitter.com/jeffinjoyglobal/status/1390119169660637184',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1587814919151489029/BcvGJvNf_normal.jpg',
		name: 'Ben Schmanke',
		handle: 'schmanke',
		unixDateMS: 1620358072000,
		platform: 'twitter',
		content: "@iPhonedo @eightsleep People don't know what they're missing 💪",
		verified: false,
		url: 'https://twitter.com/schmanke/status/1390508655183896577',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1383167918704234496/qdXVBhzP_normal.jpg',
		name: 'Charles Lubbat',
		handle: 'charleslubbat',
		unixDateMS: 1620078560000,
		platform: 'twitter',
		content: '@Shpigford @eightsleep It might be the best purchase I’ve ever made.',
		verified: true,
		url: 'https://twitter.com/charleslubbat/status/1389336296850706436',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1492261452815560707/2RCk60zz_normal.jpg',
		name: 'Thomas Smale',
		handle: 'ThomasSmale',
		unixDateMS: 1620076526000,
		platform: 'twitter',
		content: '@Shpigford @eightsleep Definitely recommend. Wife and I like the opposite temp wise and has been a game changer. We kept existing mattress and just added the cover.',
		verified: false,
		url: 'https://twitter.com/ThomasSmale/status/1389327767880093697',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1608281295918096385/D2kh-M28_normal.jpg',
		name: 'Austen Allred',
		handle: 'Austen',
		unixDateMS: 1620091834000,
		platform: 'twitter',
		content: '@Shpigford @eightsleep Do it. No brainer.',
		verified: true,
		url: 'https://twitter.com/Austen/status/1389391973048479748',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1615892975963328515/Xfdd6Do8_normal.jpg',
		name: 'Eddie Romanzo',
		handle: 'edwardspaghetti',
		unixDateMS: 1620090349000,
		platform: 'twitter',
		content: '@Shpigford @eightsleep Same situation. The cover is awesome.',
		verified: false,
		url: 'https://twitter.com/edwardspaghetti/status/1389385744486125568',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1259285101705396225/M3ZKDvYO_normal.jpg',
		name: 'Austin Rief ☕️',
		handle: 'austin_rief',
		unixDateMS: 1620077347000,
		platform: 'twitter',
		content: '@Shpigford @eightsleep Yes, it is amazing.',
		verified: true,
		url: 'https://twitter.com/austin_rief/status/1389331207352111111',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1149765490841178112/QkM2aJyN_normal.png',
		name: 'Evan Diaz de Arce',
		handle: 'ediazdearce',
		unixDateMS: 1620085936000,
		platform: 'twitter',
		content: '@Shpigford @eightsleep Yes, it’s great for that exact use case!',
		verified: false,
		url: 'https://twitter.com/ediazdearce/status/1389367232170450954',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1528907467253596160/jfu8AffN_normal.jpg',
		name: 'Brad Siegler 🇺🇸 🚀',
		handle: 'BradSiegler',
		unixDateMS: 1620442826000,
		platform: 'twitter',
		content: 'That third @eightsleep pod feeling 😏 (first pro (max)) CC @m_franceschetti @a_zatarain thanks for the hustle, can’t wait to try it out! ',
		verified: true,
		url: 'https://twitter.com/BradSiegler/status/1390864138075770880',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/E01Xj1rWYAIMVAh.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1255917943990312960/XaGzVhzh_normal.jpg',
		name: 'steijnpelle',
		handle: 'steijnpelle',
		unixDateMS: 1620490310000,
		platform: 'twitter',
		content: 'Slept like a 🪨 in the @eightsleep suite. Mattress kept me cool all night long. Pretty neat. ',
		verified: true,
		url: 'https://twitter.com/steijnpelle/status/1391063301589803010',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/E04Msi_XIAcsvhM.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1634706374516166657/PWZ1CEnl_normal.jpg',
		name: '𝚓𝚒𝚖𝚖𝚢 (𝙚/𝙥𝙧𝙤𝙢𝙥𝙩)',
		handle: '202accepted',
		unixDateMS: 1620143732000,
		platform: 'twitter',
		content:
			'@Shpigford @eightsleep No brainer move, absolutely love mine as I run crazy warm. I ended up using these settings and it still remains fairly quiet.<br/><br/>On the flipside, will say that the sheets they sell weren’t worth the cost as they really don’t fit the bed right (too loose). ',
		verified: true,
		url: 'https://twitter.com/202accepted/status/1389609650056695810',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/E0jinHyVgAI6uEP.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1288119133825978379/91_ZLc10_normal.jpg',
		name: 'Benji Hyam',
		handle: 'benjihyam',
		unixDateMS: 1620077296000,
		platform: 'twitter',
		content:
			'@Shpigford @eightsleep I have the cover and it has been a game changer for me (I sleep hot). However, I have not used it to change the temperature on either side of the bed *that is a feature though',
		verified: true,
		url: 'https://twitter.com/benjihyam/status/1389330994109423618',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1660102586320420865/a1rlydAJ_normal.jpg',
		name: 'Matt Paulson',
		handle: 'MattPaulsonSD',
		unixDateMS: 1620076470000,
		platform: 'twitter',
		content: '@Shpigford @eightsleep We have one. It works as advertised! A bit more firm than what I was used to though.',
		verified: true,
		url: 'https://twitter.com/MattPaulsonSD/status/1389327531116056578',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/829543250994266112/J7gFzLy5_normal.jpg',
		name: 'Candice Vega',
		handle: 'candiceskitchen',
		unixDateMS: 1620084897000,
		platform: 'twitter',
		content: '@Shpigford @eightsleep Get it! We’re the same- I sleep with my side warm @parisvega sleeps like he’s in the arctic. It’s been amazing.',
		verified: false,
		url: 'https://twitter.com/candiceskitchen/status/1389362878260645890',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/2181377388/image_normal.jpg',
		name: 'Nah',
		handle: 'nickduncs',
		unixDateMS: 1620084551000,
		platform: 'twitter',
		content:
			"@Shpigford @eightsleep have the cover and love it. Wife and I totally different settings which is cool. I think they're adding feature to set bed temp by room temp soon which will be a big plus.",
		verified: false,
		url: 'https://twitter.com/nickduncs/status/1389361426565722117',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1438265322923085825/KcGT10ST_normal.jpg',
		name: 'Paris Vega',
		handle: 'parisvega',
		unixDateMS: 1620089948000,
		platform: 'twitter',
		content: '@Shpigford @eightsleep Eight sleep solved the sleeping temp issue for us. I sleep on a cold setting, wife sleeps on hot',
		verified: true,
		url: 'https://twitter.com/parisvega/status/1389384063039987716',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1031156227886084099/xPH5YsmC_normal.jpg',
		name: 'Tony Federico',
		handle: 'FitwithTfed',
		unixDateMS: 1620078512000,
		platform: 'twitter',
		content: '@Shpigford @eightsleep We love ours. I like a nice cool bed and my wife prefers the 23rd level of hell and it’s not warm enough. Both of us get great sleep from it.',
		verified: false,
		url: 'https://twitter.com/FitwithTfed/status/1389336094270017536',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/718598294323073024/qu7IlBA8_normal.jpg',
		name: 'Billy Jalbert',
		handle: 'Mauibilly',
		unixDateMS: 1620078051000,
		platform: 'twitter',
		content: '@Shpigford @eightsleep Yup. Love it. Perfect for couples that run on different thermostats. Incredible investment.',
		verified: false,
		url: 'https://twitter.com/Mauibilly/status/1389334160964079619',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1514641142201028612/G-4a-7S2_normal.jpg',
		name: 'Jen Rubio',
		handle: 'jennifer',
		unixDateMS: 1619539212000,
		platform: 'twitter',
		content:
			"Not to be all Princess and the Pea here but can anyone else feel the tubes in their @chilipad Ooler when they're on it? Should I put a thin mattress pad on top of it? (We also have an @eightsleep cover on another bed and that feels totally smooth to me.)",
		verified: false,
		url: 'https://twitter.com/jennifer/status/1387074105929764869',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/718598294323073024/qu7IlBA8_normal.jpg',
		name: 'Billy Jalbert',
		handle: 'Mauibilly',
		unixDateMS: 1619577646000,
		platform: 'twitter',
		content: "@fortworthchris @eightsleep Do you track your sleep already? You'll be amazed at what it does for your sleep. 50% increase in deep sleep. You'll be even more productive.",
		verified: false,
		url: 'https://twitter.com/Mauibilly/status/1387235312221360129',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1538481272120541185/jWQ8RfMY_normal.jpg',
		name: 'Janis Krums (🥃,🦉)',
		handle: 'jkrums',
		unixDateMS: 1620036518000,
		platform: 'twitter',
		content: '@HarryStebbings @ouraring Have you tried the @eightsleep bed yet? Has been a game changer for me.',
		verified: true,
		url: 'https://twitter.com/jkrums/status/1389159959615639554',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1580188498748968961/L6lebFCL_normal.jpg',
		name: 'Louisa Nicola',
		handle: 'louisanicola_',
		unixDateMS: 1619902957000,
		platform: 'twitter',
		content: '@benmorris @eightsleep Yes! Just started sleeping with my pro this week. Loving the stats.',
		verified: true,
		url: 'https://twitter.com/louisanicola_/status/1388599763348729859',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1658106505260826628/jXxWlLP5_normal.jpg',
		name: 'GuinnessStache',
		handle: 'guinnessstache',
		unixDateMS: 1630402829000,
		platform: 'twitter',
		content:
			'3 things I like to see on Twitter lately. <br/><br/>@origin_trail $trac killing it with adoption becoming the next #Google2.0<br/>@eightsleep becoming more and more recognized as the @Tesla of  sleep <br/>@stoolpresidente pizza reviews.',
		verified: true,
		url: 'https://twitter.com/guinnessstache/status/1432639420751138826',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1469417059083771904/7KoZfm0i_normal.jpg',
		name: 'Marc Atiyeh',
		handle: 'MarcAtiyeh',
		unixDateMS: 1630388172000,
		platform: 'twitter',
		content:
			'I swear I use the 🎱🛏 every day for 6-7h STRAIGHT everyday.<br/>Can’t even say this about my own 🧠… talking about the straight part, of course (and the everyday). Bravo @a_zatarain @m_franceschetti ',
		verified: true,
		url: 'https://twitter.com/MarcAtiyeh/status/1432577944623501313',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1577803478914703362/yrUbU1U6_normal.jpg',
		name: 'Live Inspired',
		handle: 'JohnCioffoletti',
		unixDateMS: 1619901004000,
		platform: 'twitter',
		content: '@html5cat @eightsleep @m_franceschetti You’re very Welcome!  Enjoy!  That bed has changed my life!! 😂😂',
		verified: false,
		url: 'https://twitter.com/JohnCioffoletti/status/1388591573810679813',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1560334515985432577/Pr9NO6nX_normal.jpg',
		name: 'joey',
		handle: 'JGrant55',
		unixDateMS: 1619730028000,
		platform: 'twitter',
		content: '@m_franceschetti @harryhurst In Miami and in love my @eightsleep Many thanks for building an amazing product!',
		verified: true,
		url: 'https://twitter.com/JGrant55/status/1387874449144356865',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1445470366688636940/sONf1Ww4_normal.jpg',
		name: 'Rob Larson',
		handle: 'roblarson',
		unixDateMS: 1619624052000,
		platform: 'twitter',
		content: '@fortworthchris @eightsleep I just got the cover a couple days ago.  So far so good!',
		verified: false,
		url: 'https://twitter.com/roblarson/status/1387429951079653380',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1668448923692957696/MTtKNq1X_normal.jpg',
		name: 'Nate Williams',
		handle: 'naywilliams',
		unixDateMS: 1619824270000,
		platform: 'twitter',
		content: "@GK_Ventures I'm down with @eightsleep (not a paid spokesmodel btw!) and they have good comparison data cc: @m_franceschetti @a_zatarain @JNAranda",
		verified: true,
		url: 'https://twitter.com/naywilliams/status/1388269727768743936',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1521850871721734145/O_VGOu24_normal.jpg',
		name: 'Felipe Echandi',
		handle: 'felcheck',
		unixDateMS: 1619581586000,
		platform: 'twitter',
		content: '@m_franceschetti @zebulgar @eightsleep @rabois The @eightsleep recovery stack is dope!',
		verified: false,
		url: 'https://twitter.com/felcheck/status/1387251838282469379',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1031156227886084099/xPH5YsmC_normal.jpg',
		name: 'Tony Federico',
		handle: 'FitwithTfed',
		unixDateMS: 1619565682000,
		platform: 'twitter',
		content: '@m_franceschetti Anything that gets me moving. @tonal for weights, @onepeloton for cardio, and @eightsleep to recover. Starting another Tonal/Peloton challenge this coming Monday!',
		verified: false,
		url: 'https://twitter.com/FitwithTfed/status/1387185131748831235',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1620123043128541189/SFfmJtlU_normal.jpg',
		name: 'Sean Campbell',
		handle: 'seancworld',
		unixDateMS: 1619499768000,
		platform: 'twitter',
		content: '@ChrisSorta This guy needs @eightsleep and a #sleepfitness mindset',
		verified: false,
		url: 'https://twitter.com/seancworld/status/1386908666935214084',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1580188498748968961/L6lebFCL_normal.jpg',
		name: 'Louisa Nicola',
		handle: 'louisanicola_',
		unixDateMS: 1619472888000,
		platform: 'twitter',
		content:
			'@devin_cannady3 Mine is pretty intense but I use light to start my circadian rhythm, I sleep on an @eightsleep mattress to optimise my temperature and I use @whoop to measure the accuracy of my sleep cycles. Do you travel overnight often?',
		verified: true,
		url: 'https://twitter.com/louisanicola_/status/1386795923624312833',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1514641142201028612/G-4a-7S2_normal.jpg',
		name: 'Jen Rubio',
		handle: 'jennifer',
		unixDateMS: 1619544829000,
		platform: 'twitter',
		content: '@CalebPolley @eightsleep prefer @eightsleep!',
		verified: false,
		url: 'https://twitter.com/jennifer/status/1387097665150853124',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1495528865279664128/IqP6mNGD_normal.jpg',
		name: 'Brandon Reeves',
		handle: 'breeves08',
		unixDateMS: 1619281155000,
		platform: 'twitter',
		content:
			'Also had my first night in the @eightsleep and it really is special. 100% worth it and recommend. Noticeable difference in sleep quality. <br/><br/>Credit to @geoffreywoo and @zebulgar for the rec and thanks to @m_franceschetti for great customer service along the way. ',
		verified: false,
		url: 'https://twitter.com/breeves08/status/1385991737479417865',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1664270968662130694/PUSi89aW_normal.jpg',
		name: 'Jack Appleby',
		handle: 'jappleby',
		unixDateMS: 1631560017000,
		platform: 'twitter',
		content: '@alex_frostwolf I can’t offer sponsorship, but highly recommend @eightsleep',
		verified: true,
		url: 'https://twitter.com/jappleby/status/1437493018517454851',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1242922153080573952/_pg3fgpc_normal.jpg',
		name: 'katiewongspelledwang',
		handle: 'wongspelledwang',
		unixDateMS: 1631556132000,
		platform: 'twitter',
		content: 'can’t wait to get my Eight Sleep on!! 🙌🏽😴💤 ',
		verified: false,
		url: 'https://twitter.com/wongspelledwang/status/1437476721956036608',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1509530448958918663/SqxfRTpj_normal.jpg',
		name: 'Chris Power',
		handle: '2112Power',
		unixDateMS: 1632349011000,
		platform: 'twitter',
		content: '@lulumeservey @traestephens @eightsleep its literally the best',
		verified: true,
		url: 'https://twitter.com/2112Power/status/1440802297185849344',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1495528865279664128/IqP6mNGD_normal.jpg',
		name: 'Brandon Reeves',
		handle: 'breeves08',
		unixDateMS: 1632349483000,
		platform: 'twitter',
		content: '@2112Power @lulumeservey @traestephens @eightsleep Agree 100%',
		verified: false,
		url: 'https://twitter.com/breeves08/status/1440804279514632199',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/597871331846262784/QqtH6jt2_normal.jpg',
		name: 'Julio A. Berroa',
		handle: 'jberroa0204',
		unixDateMS: 1632252286000,
		platform: 'twitter',
		content: '@jleeku @eightsleep Welcome to the club. Trust me it will. 🙂',
		verified: false,
		url: 'https://twitter.com/jberroa0204/status/1440396604440125451',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1496580378525589504/q5ZHB4P3_normal.jpg',
		name: 'Michael Dorminey',
		handle: 'christisking404',
		unixDateMS: 1632190461000,
		platform: 'twitter',
		content: 'I’m a believer!!  @eightsleep has changed my wife and my life. Must have! ',
		verified: true,
		url: 'https://twitter.com/christisking404/status/1440137293696626701',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1031156227886084099/xPH5YsmC_normal.jpg',
		name: 'Tony Federico',
		handle: 'FitwithTfed',
		unixDateMS: 1632067780000,
		platform: 'twitter',
		content:
			'@elonmusk @28delayslater @AustinTeslaClub @Tesla @TeslaOwnersEBay @TeslaOwnersUAE @EvaFoxU @leastImAlive @teslaownersSV @gailalfa1 @live_munro @OwenSparks_ There are only a handful of products I truly love.  @Tesla of course, @eightsleep, @onepeloton, @tonal, and @geteero.  Each has had a hand in making my boring day to day life a little better.',
		verified: false,
		url: 'https://twitter.com/FitwithTfed/status/1439622731704815619',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1213509019794640899/uEz-S5Dw_normal.jpg',
		name: 'Will Brackett',
		handle: 'willibeemin',
		unixDateMS: 1631995067000,
		platform: 'twitter',
		content:
			'Called @eightsleep with a product issue today.<br/><br/>Spoke with a rep that was polite, extremely helpful, and immediately kicked off a process to resolve my issue while providing timeline estimates and confirmations.<br/><br/>Great customer service = customer retention<br/><br/>The details matter…',
		verified: true,
		url: 'https://twitter.com/willibeemin/status/1439317752129355780',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1581409751564316673/EmlXTpT-_normal.jpg',
		name: 'Eric Bahn 💛',
		handle: 'ericbahn',
		unixDateMS: 1631926162000,
		platform: 'twitter',
		content: '@sweatystartup I love sleeping next to my kids, so long as it’s on an @eightsleep.',
		verified: true,
		url: 'https://twitter.com/ericbahn/status/1439028743536668675',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1338352888389775362/awwnzOq5_normal.jpg',
		name: 'Greysen Paige Cohen',
		handle: 'greysen_paige',
		unixDateMS: 1631901737000,
		platform: 'twitter',
		content: '@aminshams___ Aww damn, I’m sorry :( only other bed I LOVED was @eightsleep',
		verified: false,
		url: 'https://twitter.com/greysen_paige/status/1438926296906670081',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/869455594805092352/8j1ljPjg_normal.jpg',
		name: 'Ed',
		handle: 'eeddeellee',
		unixDateMS: 1631836588000,
		platform: 'twitter',
		content: '@drewcoffman Obligatory @eightsleep mention. Being able to have my bed cooled when I call it a day, has helped me fall asleep so much quicker',
		verified: false,
		url: 'https://twitter.com/eeddeellee/status/1438653039259176961',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1658106505260826628/jXxWlLP5_normal.jpg',
		name: 'GuinnessStache',
		handle: 'guinnessstache',
		unixDateMS: 1631809368000,
		platform: 'twitter',
		content: '@m_franceschetti @KaylahRoxOut Congrats! You are about to have the best sleep of your life going forward!',
		verified: true,
		url: 'https://twitter.com/guinnessstache/status/1438538871067787266',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1600593627033501697/Oip202cn_normal.jpg',
		name: 'Greg Kahn | GK Digital Ventures',
		handle: 'GK_Ventures',
		unixDateMS: 1631718610000,
		platform: 'twitter',
		content: 'Prediction: @eightsleep is going to push all other end mattress companies (@sleepnumber @TempurPedic) to offer individual temperature control.<br/><br/>It’s a game changer.',
		verified: false,
		url: 'https://twitter.com/GK_Ventures/status/1438158203804700679',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1435825620110430212/2-iIQLte_normal.jpg',
		name: 'Randy Carballo',
		handle: 'randycarballo',
		unixDateMS: 1631704236000,
		platform: 'twitter',
		content: '@sammskiii @eightsleep Just did it a week ago, we love it',
		verified: true,
		url: 'https://twitter.com/randycarballo/status/1438097915965227011',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1288898761972801536/_Yi3FY4H_normal.jpg',
		name: 'Michelle',
		handle: 'michellechanme',
		unixDateMS: 1631680784000,
		platform: 'twitter',
		content: '@_tyoung @eightsleep Yesss. Temp control is everything 😴',
		verified: false,
		url: 'https://twitter.com/michellechanme/status/1437999552984858626',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1355698719723761668/KohYfwTX_normal.jpg',
		name: 'Mani',
		handle: 'manicakes',
		unixDateMS: 1631648293000,
		platform: 'twitter',
		content: '@wolfejosh @eightsleep @m_franceschetti @zebulgar i’ve got one too, love it.',
		verified: false,
		url: 'https://twitter.com/manicakes/status/1437863276168323081',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1463499282036756482/D3nSbqZb_normal.jpg',
		name: 'Josh Wolfe',
		handle: 'wolfejosh',
		unixDateMS: 1631627941000,
		platform: 'twitter',
		content:
			'GOOD news—i’m sleeping 😴better than ever<br/>thx to @eightsleep<br/>(plus no more stealthy spouse thermostat adjustments😂)<br/><br/>BAD news—I’ll now have sleepless nights for missing the Series A years ago! Congrats on beautiful product you’re building…Thank you @m_franceschetti + @zebulgar!',
		verified: true,
		url: 'https://twitter.com/wolfejosh/status/1437777913252827148',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1584868536811495424/rMNXT5pl_normal.jpg',
		name: 'Patrick OShaughnessy',
		handle: 'patrick_oshag',
		unixDateMS: 1631630899000,
		platform: 'twitter',
		content: '@wolfejosh @eightsleep @m_franceschetti @zebulgar rocks, right?!',
		verified: true,
		url: 'https://twitter.com/patrick_oshag/status/1437790319345549325',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1686409479070314496/ZPQ-ezta_normal.jpg',
		name: 'nic 🌠 carter',
		handle: 'nic__carter',
		unixDateMS: 1631480799000,
		platform: 'twitter',
		content: '@gckaloudis @Levels @ouraring @Garmin @eightsleep Liking it so far',
		verified: true,
		url: 'https://twitter.com/nic__carter/status/1437160755305009153',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/413037869448450048/WIKP9pY2_normal.jpeg',
		name: 'Sco Holm (Taylor’s Version)',
		handle: 'omnipotentsco',
		unixDateMS: 1631473820000,
		platform: 'twitter',
		content: 'Nope! The @eightsleep bed helped my back quite a bit!',
		verified: false,
		url: 'https://twitter.com/omnipotentsco/status/1437131480434716676',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1650512809740861442/2Rdhlvwu_normal.jpg',
		name: 'Noah Loffredo',
		handle: 'LoffredoNoah',
		unixDateMS: 1631457859000,
		platform: 'twitter',
		content: '@sairarahman @eightsleep @eightsleep is soooo good.',
		verified: false,
		url: 'https://twitter.com/LoffredoNoah/status/1437064538365571074',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1271264092729335808/og1Gc6ot_normal.jpg',
		name: 'Dr. Sohaib Imtiaz, MD MPH',
		handle: 'digitald0ctor',
		unixDateMS: 1631335674000,
		platform: 'twitter',
		content: 'When it’s too hot in the U.K. @eightsleep ',
		verified: true,
		url: 'https://twitter.com/digitald0ctor/status/1436552056962232320',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/E--oex8XIAEOy34.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1461727266015039488/WPThZ6zo_normal.jpg',
		name: 'ticker talker',
		handle: 'talkingtickers',
		unixDateMS: 1631287465000,
		platform: 'twitter',
		content:
			'@Lriberat @marceloclaure @whoop @shervin @eightsleep @andrewrsorkin @m_franceschetti I ended up buying one a couple months ago. Best purchase I’ve made. First night or two took a little getting used to. Bed was slightly stiffer. Once you get your settings figured out it is life changing though. Highly recommend.',
		verified: false,
		url: 'https://twitter.com/talkingtickers/status/1436349851243933706',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1432949568249999361/ybVhGs55_normal.jpg',
		name: 'CryptoKamado',
		handle: 'CryptoKamado_',
		unixDateMS: 1631276289000,
		platform: 'twitter',
		content: '@GainzyXBT Get @eightsleep bro. Did wonders for me',
		verified: false,
		url: 'https://twitter.com/CryptoKamado_/status/1436302976038096901',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1524849005666131968/d-S6tHPT_normal.png',
		name: 'Nik Sharma',
		handle: 'mrsharma',
		unixDateMS: 1631194078000,
		platform: 'twitter',
		content: '@m_franceschetti @ericosiu @eightsleep Better than bad sleep and then paying $3 a day for coffee!',
		verified: true,
		url: 'https://twitter.com/mrsharma/status/1435958160532049924',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1580188498748968961/L6lebFCL_normal.jpg',
		name: 'Louisa Nicola',
		handle: 'louisanicola_',
		unixDateMS: 1631112978000,
		platform: 'twitter',
		content:
			'I spent last night reviewing a study on the relationship between thermolregulation and depression. <br/><br/>As it turns out, in depressed people, something seems to go awry in the thermoregulation process. Research found that these patients body temperatures are higher.',
		verified: true,
		url: 'https://twitter.com/louisanicola_/status/1435618000036667393',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1680268585409847297/za1YjHcm_normal.jpg',
		name: 'Salina Mendoza',
		handle: 'inababi',
		unixDateMS: 1631054433000,
		platform: 'twitter',
		content:
			'Learned this recently. Investing in my sleep with @eightsleep, finally having a  routine, and seeing the benefits of sleeping on a cold mattress is unmatched 😍<br/><br/>Definitely feel like a different person ',
		verified: false,
		url: 'https://twitter.com/inababi/status/1435372445729366018',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1401163588258222087/H1V5cVVB_normal.jpg',
		name: 'Michael Ax',
		handle: 'axline3',
		unixDateMS: 1631010408000,
		platform: 'twitter',
		content: 'It’s been one week with my new @eightsleep mattress and the only problem so far is that I can’t make myself get out of bed!! This thing is life changing.',
		verified: false,
		url: 'https://twitter.com/axline3/status/1435187791042854914',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1379936079797755904/Q7GbtR7k_normal.jpg',
		name: 'Goodluck Raphael',
		handle: 'Goodluckraph',
		unixDateMS: 1630869331000,
		platform: 'twitter',
		content: '@m_franceschetti I so much admire the beauty of the product and the awesomeness of the team at @eightsleep',
		verified: false,
		url: 'https://twitter.com/Goodluckraph/status/1434596070609272840',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1561698926020988930/0fD0-mNc_normal.jpg',
		name: 'Brian J. Esposito',
		handle: 'brianjesposito',
		unixDateMS: 1630710659000,
		platform: 'twitter',
		content: '@m_franceschetti @marceloclaure @OpportunityFund @eightsleep @a_zatarain @maghis Such bad ass great work and execution.  The best is always still yet to come @eightsleep',
		verified: true,
		url: 'https://twitter.com/brianjesposito/status/1433930553124868099',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1019628812483604480/965ymS27_normal.jpg',
		name: 'Matt Haggman 🇺🇦',
		handle: 'matthaggman',
		unixDateMS: 1630679837000,
		platform: 'twitter',
		content: 'We’re a customer of @eightsleep and love it. Thrilled the company and @m_franceschetti call Miami home. ',
		verified: false,
		url: 'https://twitter.com/matthaggman/status/1433801275720847360',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1580188498748968961/L6lebFCL_normal.jpg',
		name: 'Louisa Nicola',
		handle: 'louisanicola_',
		unixDateMS: 1634565325000,
		platform: 'twitter',
		content: 'This is how I know that I’m going to have a good day. Thanks for keeping me in check @eightsleep ',
		verified: true,
		url: 'https://twitter.com/louisanicola_/status/1450098193686016000',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FB_InuLWYAslBjL.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1407563430236614657/plFtbHtj_normal.jpg',
		name: 'Sergio Collazos',
		handle: 'Sergio_Collazos',
		unixDateMS: 1633807725000,
		platform: 'twitter',
		content: '@m_franceschetti @katgleason I just got a pod cover and my Oura results have noticeable improved. <br/><br/>Though these ones are insane 😅',
		verified: false,
		url: 'https://twitter.com/Sergio_Collazos/status/1446920588191096832',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1361533249160237059/VfkVvsQE_normal.jpg',
		name: 'Aaron Bailey',
		handle: 'aaroncbailey',
		unixDateMS: 1632695759000,
		platform: 'twitter',
		content: "Avg sleep score over last 3 mo's: 64-72%<br/><br/>First night on @eightsleep: 90%<br/><br/>how tf ",
		verified: false,
		url: 'https://twitter.com/aaroncbailey/status/1442256664908861440',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1436090892092198912/MO3T7VZQ_normal.jpg',
		name: 'Matías Alba',
		handle: 'chemalba',
		unixDateMS: 1631760909000,
		platform: 'twitter',
		content: '@SamCorcos @eightsleep @m_franceschetti I just rely on autopilot. It’s magic. I’ve never slept better in my life (-4 to -2). Also, HRV is the first thing I check every morning 🤯',
		verified: false,
		url: 'https://twitter.com/chemalba/status/1438335619982237699',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1633993252474064897/H8kgWbUv_normal.jpg',
		name: 'Dave Shinn',
		handle: 'davidjshinn',
		unixDateMS: 1627350333000,
		platform: 'twitter',
		content:
			'@davidpiegaro @eightsleep I’ve used an Apple Watch forever; tried the Halo as well. I sleep hot, and have serious sleeping probs (5 y/o doesn’t help)! Being able to regulate temp thru cycles, and easing into wake up etc is legit (have tried sunrise function in hue etc.)',
		verified: false,
		url: 'https://twitter.com/davidjshinn/status/1419836324056473601',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1481512504521936897/tGao__Tu_normal.jpg',
		name: 'Rasty Turek',
		handle: 'synopsi',
		unixDateMS: 1627573153000,
		platform: 'twitter',
		content:
			'@ikirigin @vimcal I can’t imagine life without it (investor)<br/><br/>@eightsleep haven’t had such a good sleep for years<br/><br/>@ouraring can’t believe what they fit in such a small device<br/><br/>@tryramp to give employees better way to manage company purchases',
		verified: true,
		url: 'https://twitter.com/synopsi/status/1420770897330282498',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1626619278588772355/UMt4MIwB_normal.jpg',
		name: 'Lonely Rooster 🔺',
		handle: 'lonelyrooster_',
		unixDateMS: 1623873746000,
		platform: 'twitter',
		content: 'Just cranked this shit to negative double digits for a quick power nap after a long day at work. Feel like I’m laying in an ice bath and I’m loving it. Thanks @eightsleep @trylolli ',
		verified: false,
		url: 'https://twitter.com/lonelyrooster_/status/1405254459517714441',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/E4B3ew7VUAUAImU.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/3401499052/0c16e8ad3d06a1102a8f96f82baed177_normal.jpeg',
		name: 'Ramsey Cox',
		handle: 'RamseyCox22',
		unixDateMS: 1623934380000,
		platform: 'twitter',
		content: '@eightsleep ',
		verified: false,
		url: 'https://twitter.com/RamseyCox22/status/1405508776736657408',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/E4FeyG_VkAoizK0.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1519108511506046976/Hns7pWP__normal.jpg',
		name: 'Alex Iskold | 2048.vc',
		handle: 'alexiskold',
		unixDateMS: 1621515939000,
		platform: 'twitter',
		content:
			'7/ We are lucky, unlike previous generations that we can not only understand sleep better but we can measure and improve it! Great tools like @ouraring @whoop @eightsleep, sleep masks, blue light glasses, magnesium, etc can help create routines and optimize sleep. Do it! /end',
		verified: true,
		url: 'https://twitter.com/alexiskold/status/1395365100559679490',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/829543250994266112/J7gFzLy5_normal.jpg',
		name: 'Candice Vega',
		handle: 'candiceskitchen',
		unixDateMS: 1621688261000,
		platform: 'twitter',
		content: '@geoffrey_fyi @sleepnumber @eightsleep The system is small but mighty and worth every inch of space. My husband and I both sleep better now than before. It’s worth the hype.',
		verified: false,
		url: 'https://twitter.com/candiceskitchen/status/1396087870516088840',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1541951860726042625/uwMQGYYs_normal.jpg',
		name: 'MJ',
		handle: 'Mich3alJ',
		unixDateMS: 1621270626000,
		platform: 'twitter',
		content: '@JERICHO @eightsleep is your buddy. Once you sleep on an 8sleep pod, regular mattresses will feel like a dirt patch.',
		verified: false,
		url: 'https://twitter.com/Mich3alJ/status/1394336183199494144',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1363316601974644736/JKdIckKh_normal.jpg',
		name: 'Matthew Dellavedova',
		handle: 'matthewdelly',
		unixDateMS: 1621715033000,
		platform: 'twitter',
		content: '@SahilBloom @patrick_oshag @eightsleep Game changer!',
		verified: true,
		url: 'https://twitter.com/matthewdelly/status/1396200162565890049',
		categories: ['Familiar faces'],
	},
	{
		picture: 'https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png',
		name: 'Brian',
		handle: '5Isaacson',
		unixDateMS: 1620856410000,
		platform: 'twitter',
		content:
			'@andrewrsorkin @oura @eightsleep I have found taking magnesium 30 minutes before bedtime helps with deep sleep. After doing so, I have found that my Oura ring and my eight sleep both report a dramatically increase in deep sleep percentages and number of minutes/hours.',
		verified: false,
		url: 'https://twitter.com/5Isaacson/status/1392598835495686146',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1347761768597229568/uVOPRs8Q_normal.jpg',
		name: 'Brett Scheiner',
		handle: 'BrettScheiner',
		unixDateMS: 1620856629000,
		platform: 'twitter',
		content: '@m_franceschetti @eightsleep Night 1 was no sweat 🥶🛏 (literally).  Great to see deep sleep improved.  Looking forward to min HR and HRV trend 📉 w/o a wearable.  Ready for more 💤🥱 ',
		verified: false,
		url: 'https://twitter.com/BrettScheiner/status/1392599753037332486',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/E1OCGBcXoAIMGlH.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1507053329930674183/NAl2CNeb_normal.jpg',
		name: 'Joe Rice-Jones',
		handle: 'iWalkingCorpse',
		unixDateMS: 1620313319000,
		platform: 'twitter',
		content: "@iPhonedo @eightsleep 14 months or so on ours, some of the best nights of sleep I've ever had",
		verified: false,
		url: 'https://twitter.com/iWalkingCorpse/status/1390320945789906950',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1445760733971566594/UgP8fmeH_normal.jpg',
		name: 'AriannaSimpson.eth',
		handle: 'AriannaSimpson',
		unixDateMS: 1620388728000,
		platform: 'twitter',
		content:
			'I’m very curious whether there is any data on the effects of lower RHR over time. My nighttime RHR has dropped substantially since using @eightsleep and I’m wondering what the likely benefits are',
		verified: true,
		url: 'https://twitter.com/AriannaSimpson/status/1390637236581027840',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1554673291570212864/RWwZGZ1E_normal.png',
		name: 'Chris Cantino',
		handle: 'chriscantino',
		unixDateMS: 1620093363000,
		platform: 'twitter',
		content: '@Shpigford @eightsleep I’m -7. @jaimeschmidt is +1. Sleeping better than ever on our Eightsleep.',
		verified: true,
		url: 'https://twitter.com/chriscantino/status/1389398385799548932',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1492580015103520777/rwItTe-h_normal.jpg',
		name: 'Jesse R. Feldman',
		handle: 'TheRealJFeld',
		unixDateMS: 1634826641000,
		platform: 'twitter',
		content:
			'After using Ooler and a BedJet with my Tempur-Pedic I finally caved and bought an @eightsleep mattress. Wow. Absolutely unparalleled bed cooling and heating. Game changer. And the analytics are awesome. Super comfortable too. I waited too long to do this.',
		verified: true,
		url: 'https://twitter.com/TheRealJFeld/status/1451194234141167629',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1498084461958901763/Cm6f_NvL_normal.jpg',
		name: 'Alex Priest',
		handle: 'alexpriest',
		unixDateMS: 1630445622000,
		platform: 'twitter',
		content:
			'@Shpigford @eightsleep We love ours…. Both my wife and I frequently wake up hot without it. Had an Ooler and upgraded to the EightSleep when we moved to a king mattress. The smarts in the EightSleep are vastly superior imho.',
		verified: false,
		url: 'https://twitter.com/alexpriest/status/1432818906012475393',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1608532441307701248/Tlaa94Zv_normal.jpg',
		name: 'Mike Benson',
		handle: 'Unbox_warehouse',
		unixDateMS: 1632446775000,
		platform: 'twitter',
		content:
			'@m_franceschetti @freakinreviews @eightsleep James. You need to try this. Remember the Bedjet we both reviewed. That is silly compared to this. That cooled the wrong side of you. This is true bed tech. I’ve had many mattress products. This is different. And it looks good. ',
		verified: true,
		url: 'https://twitter.com/Unbox_warehouse/status/1441212348757282821',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/FAA2_TwUYAM_XyB.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1360103256777650176/IHQAJGRc_normal.jpg',
		name: 'Nick',
		handle: 'NickThoughtRepo',
		unixDateMS: 1632982508000,
		platform: 'twitter',
		content:
			"@jasonmcminn @michelletandler @eightsleep Which is not only higher quality hardware than something like a ChiliPad, but it also adjusts the temperature throughout the night depending on data it's getting from your sensors",
		verified: false,
		url: 'https://twitter.com/NickThoughtRepo/status/1443459376551923713',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1443098436383903747/8aPr97_A_normal.jpg',
		name: 'Jake',
		handle: 'JustJake',
		unixDateMS: 1631168395000,
		platform: 'twitter',
		content: "@itunpredictable But ya genuinely it's just MUCH better than my ChiliPad<br/><br/>UX, temperature, etc",
		verified: true,
		url: 'https://twitter.com/JustJake/status/1435850436334866435',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1683667154435211264/JYiqb2DZ_normal.jpg',
		name: 'Tommy Collison',
		handle: 'tommycollison',
		unixDateMS: 1627603873000,
		platform: 'twitter',
		content: '@TChadwell @jrichlive @eightsleep Have used both; I’ve found @eightsleep is both more comfortable and quieter. 🌙',
		verified: true,
		url: 'https://twitter.com/tommycollison/status/1420899747536150531',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1589159524824326144/BCZe55Fl_normal.jpg',
		name: 'Karim Atiyeh',
		handle: 'karimatiyeh',
		unixDateMS: 1624040037000,
		platform: 'twitter',
		content: '@khakimz @eightsleep Way better. It’s all about temperature control. You sleep so much better with a cool mattress',
		verified: true,
		url: 'https://twitter.com/karimatiyeh/status/1405951936139075585',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1528907467253596160/jfu8AffN_normal.jpg',
		name: 'Brad Siegler 🇺🇸 🚀',
		handle: 'BradSiegler',
		unixDateMS: 1623630190000,
		platform: 'twitter',
		content:
			'@Ozarka69 @m_franceschetti Not even a question. I’ve owned a Casper and seen a purple. Those are just mattresses with different materials. The @eightsleep literally adjusts to meet your needs. I have 3 now.',
		verified: true,
		url: 'https://twitter.com/BradSiegler/status/1404232911482728448',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/978132096907599875/weBDVZH-_normal.jpg',
		name: 'Jason McMinn',
		handle: 'jasonmcminn',
		unixDateMS: 1595448187000,
		platform: 'twitter',
		content:
			"@jonmacdonald @eightsleep I had the new Pod for 6 weeks now. Came from mattress plus BedJet.<br/><br/>If temp control is at the top of your list (it was mine) then @eightsleep is your solution. Don't waste your money on trying everything else as I did.",
		verified: false,
		url: 'https://twitter.com/jasonmcminn/status/1286029024041435136',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1031156227886084099/xPH5YsmC_normal.jpg',
		name: 'Tony Federico',
		handle: 'FitwithTfed',
		unixDateMS: 1612708525000,
		platform: 'twitter',
		content: "I seriously question whether @ConsumerReports is aware of what sleep quality is all about if they don't rate @eightsleep but include Chili, Perfect Sleep, and BedJet.",
		verified: false,
		url: 'https://twitter.com/FitwithTfed/status/1358424131511980038',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1265358289627344897/hz8hIp9r_normal.jpg',
		name: 'Connor Bowlan',
		handle: 'ConnorBowlan',
		unixDateMS: 1597701947000,
		platform: 'twitter',
		content:
			'@a_zatarain @annarchyy @eightsleep The Chili Pad products are another slightly worse (but cheaper) alternative, and behind that would probably be the BedJet. The Eight Sleep Pod is definitely the Tesla of all the options out there though.',
		verified: false,
		url: 'https://twitter.com/ConnorBowlan/status/1295481978326720514',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/978132096907599875/weBDVZH-_normal.jpg',
		name: 'Jason McMinn',
		handle: 'jasonmcminn',
		unixDateMS: 1592513598000,
		platform: 'twitter',
		content:
			'3 nights with @eightsleep and it is awesome! The bed temp control is the killer feature. Sleep analytics are a bonus but coming off a BedJet for temp control there is no comparison.<br/><br/>@m_franceschetti chalk this CAC up to organic Twitter talk from you and @rabois - LOL',
		verified: false,
		url: 'https://twitter.com/jasonmcminn/status/1273720466276904961',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1565913855674441728/BEqVYGhA_normal.jpg',
		name: 'Austin Garland 🐺',
		handle: 'ausgarland',
		unixDateMS: 1583607073000,
		platform: 'twitter',
		content: '@alanklement @Casper @Purple Definitely @eightsleep <br/>Best mattress I’ve ever been on. The bad actually cools without using fans like a BedJet',
		verified: false,
		url: 'https://twitter.com/ausgarland/status/1236363792398909440',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1291481749399183360/xqPFhPBS_normal.jpg',
		name: 'Chris Lu',
		handle: 'chris__lu',
		unixDateMS: 1614226797000,
		platform: 'twitter',
		content:
			'@TheBuilderJR @eightsleep @PaulYacoubian I have! I had the BedJet before and temperature wise it was amazing! The @eightsleep does a better job at the small things which I think matter here (vibration alarm, intuitive app design, the extra metrics are nice, etc)',
		verified: true,
		url: 'https://twitter.com/chris__lu/status/1364792224802865156',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1514641142201028612/G-4a-7S2_normal.jpg',
		name: 'Jen Rubio',
		handle: 'jennifer',
		unixDateMS: 1619544829000,
		platform: 'twitter',
		content: '@CalebPolley @eightsleep prefer @eightsleep!',
		verified: false,
		url: 'https://twitter.com/jennifer/status/1387097665150853124',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1580188498748968961/L6lebFCL_normal.jpg',
		name: 'Louisa Nicola',
		handle: 'louisanicola_',
		unixDateMS: 1619292526000,
		platform: 'twitter',
		content: '@DanZahler @eightsleep 3 degrees lower than my usual temp.',
		verified: true,
		url: 'https://twitter.com/louisanicola_/status/1386039431338016770',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1637254773/mypic_normal.jpeg',
		name: 'Dan Patrick',
		handle: 'mdpatrick',
		unixDateMS: 1616724072000,
		platform: 'twitter',
		content:
			'The user experience of @eightsleep is an order of magnitude better than all comparable sleep optimization products of the same category.<br/><br/>It’s like the extra 25% that takes something from good to excellent.<br/><br/>- Quiet<br/>- Less complexity<br/>- Dummy proofing maintenance w/ prompts, etc.',
		verified: true,
		url: 'https://twitter.com/mdpatrick/status/1375266554925834241',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1291481749399183360/xqPFhPBS_normal.jpg',
		name: 'Chris Lu',
		handle: 'chris__lu',
		unixDateMS: 1614226797000,
		platform: 'twitter',
		content:
			'@TheBuilderJR @eightsleep @PaulYacoubian I have! I had the BedJet before and temperature wise it was amazing! The @eightsleep does a better job at the small things which I think matter here (vibration alarm, intuitive app design, the extra metrics are nice, etc)',
		verified: true,
		url: 'https://twitter.com/chris__lu/status/1364792224802865156',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1353476879458570240/kZCcE5Ep_normal.jpg',
		name: 'Ian Mikutel',
		handle: 'IanMikutel',
		unixDateMS: 1612235989000,
		platform: 'twitter',
		content: '@dougsimp @ChrisMasterjohn @kevinrose @eightsleep Used ChiliPad for 3 years or so, worked great but more limited given it was one temp all night.',
		verified: false,
		url: 'https://twitter.com/IanMikutel/status/1356442170773827587',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1532141963754893312/KAYcFHSm_normal.png',
		name: 'Shawn Sakamoto',
		handle: 'shawnsakamoto',
		unixDateMS: 1612286508000,
		platform: 'twitter',
		content: '@stuartparmenter @kevinrose @eightsleep Single unit for dual zones. App works very reliably. Sleep tracking is better than dedicated devices such as @ouraring and #AppleWatch',
		verified: true,
		url: 'https://twitter.com/shawnsakamoto/status/1356654061718704128',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1422516562192445446/mCYUL_8-_normal.jpg',
		name: 'Bill Hanks',
		handle: 'hanksb00',
		unixDateMS: 1612269447000,
		platform: 'twitter',
		content:
			"@ChrisMasterjohn @kevinrose @eightsleep I've had both the chili pad and the sleep eight.  Sleep eight is far superior.  More settings for night variability and much cooler. The customer support has been good as well.  I had my pad spring a leak and they sent me a new mattress and upgraded my pad. Also measures HRV &amp; HR",
		verified: true,
		url: 'https://twitter.com/hanksb00/status/1356582503570636802',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1353476879458570240/kZCcE5Ep_normal.jpg',
		name: 'Ian Mikutel',
		handle: 'IanMikutel',
		unixDateMS: 1612235836000,
		platform: 'twitter',
		content: '@jonrojas @kevinrose @eightsleep Quieter than my ChiliPad cube was.',
		verified: false,
		url: 'https://twitter.com/IanMikutel/status/1356441528273563648',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1594041375414108160/tMNoKm4B_normal.jpg',
		name: 'Doug Simpson',
		handle: 'dougsimp',
		unixDateMS: 1612235305000,
		platform: 'twitter',
		content:
			'@ChrisMasterjohn @kevinrose @eightsleep Use chill iPad for a year and then Ooler for 1-2 years. Been using Eight Sleep for a month. Much higher quality, much better app, more consistent sleep improvements, has Sensors for sleep tracking including HRV',
		verified: true,
		url: 'https://twitter.com/dougsimp/status/1356439300582174721',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1353476879458570240/kZCcE5Ep_normal.jpg',
		name: 'Ian Mikutel',
		handle: 'IanMikutel',
		unixDateMS: 1612235836000,
		platform: 'twitter',
		content: '@jonrojas @kevinrose @eightsleep Quieter than my ChiliPad cube was.',
		verified: false,
		url: 'https://twitter.com/IanMikutel/status/1356441528273563648',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/449619947636162560/tvsoV1uf_normal.jpeg',
		name: 'Isabeau Miller',
		handle: 'isabeaumiller',
		unixDateMS: 1601510458000,
		platform: 'twitter',
		content:
			'@tferriss @eightsleep Bought @eightsleep pod cover and it arrived yesterday. It blows Ooler OUT OF THE WATER. The metrics alone are game changing, but I can’t feel the grid at all, and it’s intuitive and comfortable. Returning our Ooler.',
		verified: false,
		url: 'https://twitter.com/isabeaumiller/status/1311456031248183302',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1037050644455215105/Uv-lPrNF_normal.jpg',
		name: 'Armand Patella.com',
		handle: 'ap3co',
		unixDateMS: 1600881043000,
		platform: 'twitter',
		content: '@tferriss Had a chiliPAD...was great when it worked, but it was unreliable and would stop cooling fairly frequently (1-2x/month). Switched to an @eightsleep mattress + pad and love it.',
		verified: true,
		url: 'https://twitter.com/ap3co/status/1308816076365393920',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1601002664640274433/985LZbUV_normal.jpg',
		name: 'John Egan',
		handle: 'john_m_egan',
		unixDateMS: 1600872292000,
		platform: 'twitter',
		content:
			'@tferriss Used the Chilipad for years, it required too much maintenance. Switch to @eightsleep and never looked back. Eight sleep is 10x better than Chilipad. Much more expensive, but worth the investment. Always invest in a good (cooling) bed',
		verified: true,
		url: 'https://twitter.com/john_m_egan/status/1308779371088556033',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/2598602485/hl9eja8jl3zc2anxsode_normal.png',
		name: 'Peter Pham',
		handle: 'peterpham',
		unixDateMS: 1607100461000,
		platform: 'twitter',
		content: '@alexroche @natebosshard @tonal @eightsleep You get what you pay for. Chillpad us a dumb device , louder, and not smart or any good data.',
		verified: true,
		url: 'https://twitter.com/peterpham/status/1334902205661339648',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1338649139077402624/fcdueB9Q_normal.jpg',
		name: 'teddy',
		handle: 'TeddyTheWhale',
		unixDateMS: 1606799976000,
		platform: 'twitter',
		content: '@jack1251180 @eightsleep 8 Sleep has much better design in both software and hardware. Design matters.',
		verified: false,
		url: 'https://twitter.com/TeddyTheWhale/status/1333641879305527298',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1601002664640274433/985LZbUV_normal.jpg',
		name: 'John Egan',
		handle: 'john_m_egan',
		unixDateMS: 1600872292000,
		platform: 'twitter',
		content:
			'@tferriss Used the Chilipad for years, it required too much maintenance. Switch to @eightsleep and never looked back. Eight sleep is 10x better than Chilipad. Much more expensive, but worth the investment. Always invest in a good (cooling) bed',
		verified: true,
		url: 'https://twitter.com/john_m_egan/status/1308779371088556033',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1037050644455215105/Uv-lPrNF_normal.jpg',
		name: 'Armand Patella.com',
		handle: 'ap3co',
		unixDateMS: 1600881043000,
		platform: 'twitter',
		content: '@tferriss Had a chiliPAD...was great when it worked, but it was unreliable and would stop cooling fairly frequently (1-2x/month). Switched to an @eightsleep mattress + pad and love it.',
		verified: true,
		url: 'https://twitter.com/ap3co/status/1308816076365393920',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/2598602485/hl9eja8jl3zc2anxsode_normal.png',
		name: 'Peter Pham',
		handle: 'peterpham',
		unixDateMS: 1600886537000,
		platform: 'twitter',
		content:
			'@tferriss I would get @eightsleep they updated their mattress. problem with oooler and chilipad is they are much louder, and you can feel the tubing underneath, and much slower to cool/heat. @m_franceschetti can give you more info but the sensors are the important part',
		verified: true,
		url: 'https://twitter.com/peterpham/status/1308839117158051845',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1600888417616859137/zBjSRd8J_normal.jpg',
		name: 'Composability Kyle',
		handle: 'KyleSamani',
		unixDateMS: 1597853854000,
		platform: 'twitter',
		content: '@tomhschmidt @FranklinBi @NeerajKA @scupytrooples @eightsleep @hongkim__ I used to use chilipad, switched to Eight<br/><br/>never going back',
		verified: true,
		url: 'https://twitter.com/KyleSamani/status/1296119121877372930',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1256442563076034560/4o8NFrtO_normal.jpg',
		name: 'andy yeo',
		handle: 'yeoa',
		unixDateMS: 1598504099000,
		platform: 'twitter',
		content:
			"@Julian @eightsleep @Procreate @YouTube We had started with Chilipad first but it was loud and not powerful enough. I'd recommend it - I'm still experimenting with the temperatures but it really does make a difference esp with the recent heat wave. And @m_franceschetti takes feedback well!",
		verified: false,
		url: 'https://twitter.com/yeoa/status/1298846450462281729',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1637254773/mypic_normal.jpeg',
		name: 'Dan Patrick',
		handle: 'mdpatrick',
		unixDateMS: 1609040906000,
		platform: 'twitter',
		content: '@jasonmcminn @awilkinson @eightsleep Seconded on temperature! ChiliPad is a problematic product I am utterly addicted to.<br/><br/>(Noisy and can have moisture issues.)',
		verified: true,
		url: 'https://twitter.com/mdpatrick/status/1343041019412062209',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1593770033729134598/_cpaqRxH_normal.jpg',
		name: 'bradhailey',
		handle: 'bradhailey',
		unixDateMS: 1608063065000,
		platform: 'twitter',
		content:
			'@m_franceschetti @bedsalesman @eightsleep @ChiliSleep @ebbtherapeutics @sleepnumber @TempurPedic That is for sure.  I have used both chili pad and sleep number and can tell you they are no where close to @eightsleep',
		verified: true,
		url: 'https://twitter.com/bradhailey/status/1338939656491180033',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1593770033729134598/_cpaqRxH_normal.jpg',
		name: 'bradhailey',
		handle: 'bradhailey',
		unixDateMS: 1609026820000,
		platform: 'twitter',
		content: '@zlwaterfield @eightsleep I got the pad since I already had invested in a sleep number(mistake). It is great!  I had the chili pad which did not work well at all.',
		verified: true,
		url: 'https://twitter.com/bradhailey/status/1342981938458595328',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1541488975864537088/fuqAL7e3_normal.jpg',
		name: 'Erik Pavia',
		handle: 'erikpavia',
		unixDateMS: 1608840998000,
		platform: 'twitter',
		content:
			"First night with @eightsleep and I'm reminded of how ambitious entrants can beat cozy incumbents. I previously had a Chili Pad and the performance on the cooling element alone is worlds apart. Just because someone is already doing it doesnt mean you can't do it better.",
		verified: true,
		url: 'https://twitter.com/erikpavia/status/1342202547117953025',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1514641142201028612/G-4a-7S2_normal.jpg',
		name: 'Jen Rubio',
		handle: 'jennifer',
		unixDateMS: 1619544829000,
		platform: 'twitter',
		content: '@CalebPolley @eightsleep prefer @eightsleep!',
		verified: false,
		url: 'https://twitter.com/jennifer/status/1387097665150853124',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1495528865279664128/IqP6mNGD_normal.jpg',
		name: 'Brandon Reeves',
		handle: 'breeves08',
		unixDateMS: 1619281155000,
		platform: 'twitter',
		content:
			'Also had my first night in the @eightsleep and it really is special. 100% worth it and recommend. Noticeable difference in sleep quality. <br/><br/>Credit to @geoffreywoo and @zebulgar for the rec and thanks to @m_franceschetti for great customer service along the way. ',
		verified: false,
		url: 'https://twitter.com/breeves08/status/1385991737479417865',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1340847802037911554/yYzK9mBK_normal.jpg',
		name: 'Jeff Baietto',
		handle: 'jeffinjoyglobal',
		unixDateMS: 1619371056000,
		platform: 'twitter',
		content: 'Night #1 on my @eightsleep pod. Sleep has always been my kryptonite. It’s literally a new dawn. Thanks @m_franceschetti ',
		verified: false,
		url: 'https://twitter.com/jeffinjoyglobal/status/1386368808139464706',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/Ez1fE9XVcAIylPs.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1683537760135819265/cTCfSJW7_normal.png',
		name: 'Kevin Rose 🦉',
		handle: 'kevinrose',
		unixDateMS: 1619388665000,
		platform: 'twitter',
		content: '@amywebb @eightsleep awesome, enjoy!!! we love ours cc: @summertomato',
		verified: true,
		url: 'https://twitter.com/kevinrose/status/1386442668885037056',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1515904510467575808/JIw_vnm7_normal.png',
		name: 'Dr. Darya Rose',
		handle: 'summertomato',
		unixDateMS: 1619390296000,
		platform: 'twitter',
		content: '@amywebb @kevinrose @eightsleep I am so obsessed 😍',
		verified: true,
		url: 'https://twitter.com/summertomato/status/1386449508117016580',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1547787118243561478/PxWzJo1H_normal.jpg',
		name: 'Pomp 🌪',
		handle: 'APompliano',
		unixDateMS: 1619272311000,
		platform: 'twitter',
		content: '@awilkinson Try using @eightsleep. Was game changer for me',
		verified: true,
		url: 'https://twitter.com/APompliano/status/1385954645068103687',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1676259816296120321/-F8xAVB3_normal.jpg',
		name: 'Patrick',
		handle: 'woloveck',
		unixDateMS: 1619298108000,
		platform: 'twitter',
		content: "@VanessaAlvarez1 @eightsleep @m_franceschetti I had the same experience. You're going to love it.",
		verified: false,
		url: 'https://twitter.com/woloveck/status/1386062842177200134',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/676842341492101120/g3cKbc_A_normal.jpg',
		name: 'Andres Ro',
		handle: 'AndresARobelo',
		unixDateMS: 1618952845000,
		platform: 'twitter',
		content: '@AustinWalper @eightsleep Its awesome! game changer in every aspect. You will improve your sleep 10x. Go for it!',
		verified: true,
		url: 'https://twitter.com/AndresARobelo/status/1384614706212597764',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1259285101705396225/M3ZKDvYO_normal.jpg',
		name: 'Austin Rief ☕️',
		handle: 'austin_rief',
		unixDateMS: 1618949894000,
		platform: 'twitter',
		content: "@AustinWalper @eightsleep Yes it's absolutely incredible. Love my Eight!",
		verified: true,
		url: 'https://twitter.com/austin_rief/status/1384602326678204423',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/718598294323073024/qu7IlBA8_normal.jpg',
		name: 'Billy Jalbert',
		handle: 'Mauibilly',
		unixDateMS: 1618944427000,
		platform: 'twitter',
		content: '@AustinWalper @eightsleep Yes and it is beyond amazing. Best investment of 2020. The data on my increase in deep sleep is very compelling and the way I feel is beyond.',
		verified: false,
		url: 'https://twitter.com/Mauibilly/status/1384579398087573507',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1528907467253596160/jfu8AffN_normal.jpg',
		name: 'Brad Siegler 🇺🇸 🚀',
		handle: 'BradSiegler',
		unixDateMS: 1618945683000,
		platform: 'twitter',
		content: "@AustinWalper @eightsleep 100% - if your sleep is affected in any way by temp, it's a no brainer. I have two @eightsleep pods at my house and a third on the way.",
		verified: true,
		url: 'https://twitter.com/BradSiegler/status/1384584664522842127',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1250191087353745408/PaKf9rvH_normal.png',
		name: 'José Ancer',
		handle: 'ancerj',
		unixDateMS: 1618948244000,
		platform: 'twitter',
		content: '@patmatthews @coinbase @eightsleep had the eight sleep mattress for about 6 weeks now. easily worth it. my wife likes it hot, but i like it cold. may save marriages.',
		verified: true,
		url: 'https://twitter.com/ancerj/status/1384595409448804357',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1687556223938203648/laQhr70x_normal.jpg',
		name: 'Aubrey Strobel',
		handle: 'aubreystrobel',
		unixDateMS: 1618927188000,
		platform: 'twitter',
		content: '@mrsharma @eightsleep haha already got my Eight Sleep! I love it!',
		verified: true,
		url: 'https://twitter.com/aubreystrobel/status/1384507094741696512',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1017174077046448128/osp05a7l_normal.jpg',
		name: 'Marc Garcia',
		handle: 'TonyPerkisEsq',
		unixDateMS: 1619058374000,
		platform: 'twitter',
		content: '@eightsleep easily has the best customer service.',
		verified: false,
		url: 'https://twitter.com/TonyPerkisEsq/status/1385057324960731136',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1524849005666131968/d-S6tHPT_normal.png',
		name: 'Nik Sharma',
		handle: 'mrsharma',
		unixDateMS: 1618945217000,
		platform: 'twitter',
		content:
			"@AustinWalper @eightsleep Coming up on 2 years with my @eightsleep and I can't tell you enough how amazing my sleep game has gotten... <br/><br/>Consistently hitting 50% REM/deep sleep when I'm in my own bed (according to @whoop). Waking up more refreshed. <br/><br/>I'd spend $15k if that's what this product costed.",
		verified: true,
		url: 'https://twitter.com/mrsharma/status/1384582711969468425',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1618979714982416384/B9aLgZNX_normal.jpg',
		name: 'Chris Moreno ⚡️',
		handle: 'morenochris',
		unixDateMS: 1619047827000,
		platform: 'twitter',
		content: '@srcasm Check out the @eightsleep Suite at The Hyde hotel. @m_franceschetti can probably help reserve it for you. It’ll be the best night (or day) sleep you ever had 🏝',
		verified: false,
		url: 'https://twitter.com/morenochris/status/1385013090236911616',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1525673296792113152/RwbJHL7-_normal.jpg',
		name: 'Jesse Daugherty',
		handle: 'jtdaugh',
		unixDateMS: 1619186987000,
		platform: 'twitter',
		content: 'Is a nice hotel room without an @eightsleep really a nice hotel room?',
		verified: true,
		url: 'https://twitter.com/jtdaugh/status/1385596767559774208',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1590747762676715520/V4pRkEBS_normal.jpg',
		name: 'Mike Annunziata',
		handle: 'nunzi46',
		unixDateMS: 1619095124000,
		platform: 'twitter',
		content: '@Shivansh_C @eightsleep Skip the video demo and just buy it, you won’t regret it',
		verified: true,
		url: 'https://twitter.com/nunzi46/status/1385211469025796099',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1481603783482294275/zRQ-PbRF_normal.jpg',
		name: 'Jimmy Is Promo',
		handle: 'jimmyispromo',
		unixDateMS: 1619063618000,
		platform: 'twitter',
		content: '@dinotjardin @eightsleep For night one, I have it at -2 though it can go to -10 (55 degrees)<br/>Can for sure feel the difference',
		verified: false,
		url: 'https://twitter.com/jimmyispromo/status/1385079323372183554',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1019628812483604480/965ymS27_normal.jpg',
		name: 'Matt Haggman 🇺🇦',
		handle: 'matthaggman',
		unixDateMS: 1617885982000,
		platform: 'twitter',
		content: 'Great news. @DanetLinares and I have used @eightsleep for the past six weeks and it really makes a difference. We love it. ',
		verified: false,
		url: 'https://twitter.com/matthaggman/status/1380139958590115842',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1667916360876269569/84rl3aPV_normal.jpg',
		name: 'Casey',
		handle: 'Case4Cleveland',
		unixDateMS: 1617909990000,
		platform: 'twitter',
		content: '@BrianSloss @eightsleep I think it’s great for side sleepers.',
		verified: false,
		url: 'https://twitter.com/Case4Cleveland/status/1380240656686272512',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1482849191214915587/o-aZo4xw_normal.jpg',
		name: 'Joe Pompliano',
		handle: 'JoePompliano',
		unixDateMS: 1617153164000,
		platform: 'twitter',
		content: '@austin_rief @eightsleep Best in the game 🔥',
		verified: true,
		url: 'https://twitter.com/JoePompliano/status/1377066295150182402',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1611971890532487168/vgWE_5cj_normal.jpg',
		name: 'Zach Tratar',
		handle: 'zachtratar',
		unixDateMS: 1618368509000,
		platform: 'twitter',
		content: '@LubaYudasina @eightsleep I like mine.',
		verified: true,
		url: 'https://twitter.com/zachtratar/status/1382163824749338629',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1031156227886084099/xPH5YsmC_normal.jpg',
		name: 'Tony Federico',
		handle: 'FitwithTfed',
		unixDateMS: 1618333522000,
		platform: 'twitter',
		content: '@m_franceschetti @mannyskutally @eightsleep I love this feature because it doesn’t wake my wife up but it’s enough for me to feel it and start my day.',
		verified: false,
		url: 'https://twitter.com/FitwithTfed/status/1382017076039012353',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1532008723417387011/EpSI3JLj_normal.jpg',
		name: 'Justin Darcy',
		handle: 'justinsdarcy',
		unixDateMS: 1618189389000,
		platform: 'twitter',
		content: '@credibleutility @eightsleep We put the cover over our Casper and love it',
		verified: false,
		url: 'https://twitter.com/justinsdarcy/status/1381412538584272896',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/2360535353/20120630-face_normal.jpg',
		name: 'Justin Searls',
		handle: 'searls',
		unixDateMS: 1616787125000,
		platform: 'twitter',
		content:
			'@spicycode @eightsleep Yes, it actually captures more useful health data (and writes it to the Health app) than the series 6 watch, which I had been sleeping with every night since September.',
		verified: false,
		url: 'https://twitter.com/searls/status/1375531016245362688',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1440796750449504256/NHl126K3_normal.jpg',
		name: 'Michael Spiro',
		handle: 'mspiro3',
		unixDateMS: 1617244702000,
		platform: 'twitter',
		content: '@m_franceschetti @eightsleep Thanks to Eight for changing the way we sleep!',
		verified: false,
		url: 'https://twitter.com/mspiro3/status/1377450234234687488',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1547787118243561478/PxWzJo1H_normal.jpg',
		name: 'Pomp 🌪',
		handle: 'APompliano',
		unixDateMS: 1617424090000,
		platform: 'twitter',
		content: '@CryptoStorm3 @aurelius_noel I’ve never been paid by Eight Sleep. In fact, after sleeping on it I tracked down the founders and invested in the company because it was so legit.',
		verified: true,
		url: 'https://twitter.com/APompliano/status/1378202644162969600',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1260139805058502662/IZzOtRYM_normal.jpg',
		name: 'Mike The Connector',
		handle: 'MRiegelman',
		unixDateMS: 1617547790000,
		platform: 'twitter',
		content: '@eightsleep is a game changer for sleep fitness. I’m a believer, 100%. @m_franceschetti @whoop',
		verified: false,
		url: 'https://twitter.com/MRiegelman/status/1378721478242406405',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1259285101705396225/M3ZKDvYO_normal.jpg',
		name: 'Austin Rief ☕️',
		handle: 'austin_rief',
		unixDateMS: 1617567386000,
		platform: 'twitter',
		content: "@AndrewMedal @eightsleep For me it's all about the cooling features. So nice to keep you cool at night instead of sweating all night.",
		verified: true,
		url: 'https://twitter.com/austin_rief/status/1378803670410756101',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1368918273232957440/STvAyVRX_normal.jpg',
		name: 'Zach Moss',
		handle: 'zachlmoss',
		unixDateMS: 1617822322000,
		platform: 'twitter',
		content: '@2PMinc @SoftBank @eightsleep Love my Eight Cover!',
		verified: false,
		url: 'https://twitter.com/zachlmoss/status/1379872950283857921',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1602122467002155010/MI7V7cqu_normal.png',
		name: 'Josh Ong',
		handle: 'beijingdou',
		unixDateMS: 1617822857000,
		platform: 'twitter',
		content: 'Congrats to the @eightsleep team! Such an incredible product, thankful for it every night. 🛌 ',
		verified: true,
		url: 'https://twitter.com/beijingdou/status/1379875191380131845',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1380604432526741505/ZoxckFlA_normal.jpg',
		name: 'guillaume cabane',
		handle: 'guillaumecabane',
		unixDateMS: 1617996652000,
		platform: 'twitter',
		content:
			'@m_franceschetti @eightsleep @SoftBank @marceloclaure @Gosia_Karas_ @MiamiHerald @rjwile Had an @eightsleep mattress pad since the original kickstarter campaign. Been loving it ever since!',
		verified: false,
		url: 'https://twitter.com/guillaumecabane/status/1380604141911867397',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/718598294323073024/qu7IlBA8_normal.jpg',
		name: 'Billy Jalbert',
		handle: 'Mauibilly',
		unixDateMS: 1618356007000,
		platform: 'twitter',
		content: '@raisingrippers Not organic, but you should explore @eightsleep. Life changing.',
		verified: false,
		url: 'https://twitter.com/Mauibilly/status/1382111386134749188',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1594360270301941765/hqISnZk4_normal.jpg',
		name: 'Kenan Saleh',
		handle: 'kenanhsaleh',
		unixDateMS: 1618368348000,
		platform: 'twitter',
		content: '@LubaYudasina @eightsleep Favorite purchase',
		verified: true,
		url: 'https://twitter.com/kenanhsaleh/status/1382163148367536129',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1477841735170473985/9JV9uHYe_normal.jpg',
		name: 'luba',
		handle: 'LubaYudasina',
		unixDateMS: 1618410743000,
		platform: 'twitter',
		content: 'Where can I sign up for a sleeping pros competition? Cc @eightsleep ',
		verified: false,
		url: 'https://twitter.com/LubaYudasina/status/1382340964417605636',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/Ey8Px3vVcAQo6y9.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1689076141490163712/ovtxGi10_normal.jpg',
		name: 'Ben',
		handle: 'benstreeet',
		unixDateMS: 1618554913000,
		platform: 'twitter',
		content: 'What are we long on? I’m long on @eightsleep personally',
		verified: false,
		url: 'https://twitter.com/benstreeet/status/1382945657971830792',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1667916360876269569/84rl3aPV_normal.jpg',
		name: 'Casey',
		handle: 'Case4Cleveland',
		unixDateMS: 1617879912000,
		platform: 'twitter',
		content: 'First night sleeping with an @eightsleep pillow and I gotta say, it is a game changer.',
		verified: false,
		url: 'https://twitter.com/Case4Cleveland/status/1380114499647250432',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1366533656685260802/HzyZ5Ivr_normal.jpg',
		name: 'Adam Kownacki',
		handle: 'AKbabyface',
		unixDateMS: 1618278174000,
		platform: 'twitter',
		content: 'Final night in Miami. No better way to end an amazing trip than a good night sleep @eightsleep  hub ',
		verified: false,
		url: 'https://twitter.com/AKbabyface/status/1381784929965637632',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/Ey0WDKpXIAYPoB2.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1514641142201028612/G-4a-7S2_normal.jpg',
		name: 'Jen Rubio',
		handle: 'jennifer',
		unixDateMS: 1619539212000,
		platform: 'twitter',
		content:
			"Not to be all Princess and the Pea here but can anyone else feel the tubes in their @chilipad Ooler when they're on it? Should I put a thin mattress pad on top of it? (We also have an @eightsleep cover on another bed and that feels totally smooth to me.)",
		verified: false,
		url: 'https://twitter.com/jennifer/status/1387074105929764869',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/718598294323073024/qu7IlBA8_normal.jpg',
		name: 'Billy Jalbert',
		handle: 'Mauibilly',
		unixDateMS: 1619577646000,
		platform: 'twitter',
		content: "@fortworthchris @eightsleep Do you track your sleep already? You'll be amazed at what it does for your sleep. 50% increase in deep sleep. You'll be even more productive.",
		verified: false,
		url: 'https://twitter.com/Mauibilly/status/1387235312221360129',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1538481272120541185/jWQ8RfMY_normal.jpg',
		name: 'Janis Krums (🥃,🦉)',
		handle: 'jkrums',
		unixDateMS: 1620036518000,
		platform: 'twitter',
		content: '@HarryStebbings @ouraring Have you tried the @eightsleep bed yet? Has been a game changer for me.',
		verified: true,
		url: 'https://twitter.com/jkrums/status/1389159959615639554',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1580188498748968961/L6lebFCL_normal.jpg',
		name: 'Louisa Nicola',
		handle: 'louisanicola_',
		unixDateMS: 1619902957000,
		platform: 'twitter',
		content: '@benmorris @eightsleep Yes! Just started sleeping with my pro this week. Loving the stats.',
		verified: true,
		url: 'https://twitter.com/louisanicola_/status/1388599763348729859',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1658106505260826628/jXxWlLP5_normal.jpg',
		name: 'GuinnessStache',
		handle: 'guinnessstache',
		unixDateMS: 1630402829000,
		platform: 'twitter',
		content:
			'3 things I like to see on Twitter lately. <br/><br/>@origin_trail $trac killing it with adoption becoming the next #Google2.0<br/>@eightsleep becoming more and more recognized as the @Tesla of  sleep <br/>@stoolpresidente pizza reviews.',
		verified: true,
		url: 'https://twitter.com/guinnessstache/status/1432639420751138826',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1469417059083771904/7KoZfm0i_normal.jpg',
		name: 'Marc Atiyeh',
		handle: 'MarcAtiyeh',
		unixDateMS: 1630388172000,
		platform: 'twitter',
		content:
			'I swear I use the 🎱🛏 every day for 6-7h STRAIGHT everyday.<br/>Can’t even say this about my own 🧠… talking about the straight part, of course (and the everyday). Bravo @a_zatarain @m_franceschetti ',
		verified: true,
		url: 'https://twitter.com/MarcAtiyeh/status/1432577944623501313',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1577803478914703362/yrUbU1U6_normal.jpg',
		name: 'Live Inspired',
		handle: 'JohnCioffoletti',
		unixDateMS: 1619901004000,
		platform: 'twitter',
		content: '@html5cat @eightsleep @m_franceschetti You’re very Welcome!  Enjoy!  That bed has changed my life!! 😂😂',
		verified: false,
		url: 'https://twitter.com/JohnCioffoletti/status/1388591573810679813',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1560334515985432577/Pr9NO6nX_normal.jpg',
		name: 'joey',
		handle: 'JGrant55',
		unixDateMS: 1619730028000,
		platform: 'twitter',
		content: '@m_franceschetti @harryhurst In Miami and in love my @eightsleep Many thanks for building an amazing product!',
		verified: true,
		url: 'https://twitter.com/JGrant55/status/1387874449144356865',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1445470366688636940/sONf1Ww4_normal.jpg',
		name: 'Rob Larson',
		handle: 'roblarson',
		unixDateMS: 1619624052000,
		platform: 'twitter',
		content: '@fortworthchris @eightsleep I just got the cover a couple days ago.  So far so good!',
		verified: false,
		url: 'https://twitter.com/roblarson/status/1387429951079653380',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1668448923692957696/MTtKNq1X_normal.jpg',
		name: 'Nate Williams',
		handle: 'naywilliams',
		unixDateMS: 1619824270000,
		platform: 'twitter',
		content: "@GK_Ventures I'm down with @eightsleep (not a paid spokesmodel btw!) and they have good comparison data cc: @m_franceschetti @a_zatarain @JNAranda",
		verified: true,
		url: 'https://twitter.com/naywilliams/status/1388269727768743936',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1521850871721734145/O_VGOu24_normal.jpg',
		name: 'Felipe Echandi',
		handle: 'felcheck',
		unixDateMS: 1619581586000,
		platform: 'twitter',
		content: '@m_franceschetti @zebulgar @eightsleep @rabois The @eightsleep recovery stack is dope!',
		verified: false,
		url: 'https://twitter.com/felcheck/status/1387251838282469379',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1031156227886084099/xPH5YsmC_normal.jpg',
		name: 'Tony Federico',
		handle: 'FitwithTfed',
		unixDateMS: 1619565682000,
		platform: 'twitter',
		content: '@m_franceschetti Anything that gets me moving. @tonal for weights, @onepeloton for cardio, and @eightsleep to recover. Starting another Tonal/Peloton challenge this coming Monday!',
		verified: false,
		url: 'https://twitter.com/FitwithTfed/status/1387185131748831235',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1620123043128541189/SFfmJtlU_normal.jpg',
		name: 'Sean Campbell',
		handle: 'seancworld',
		unixDateMS: 1619499768000,
		platform: 'twitter',
		content: '@ChrisSorta This guy needs @eightsleep and a #sleepfitness mindset',
		verified: false,
		url: 'https://twitter.com/seancworld/status/1386908666935214084',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1580188498748968961/L6lebFCL_normal.jpg',
		name: 'Louisa Nicola',
		handle: 'louisanicola_',
		unixDateMS: 1619472888000,
		platform: 'twitter',
		content:
			'@devin_cannady3 Mine is pretty intense but I use light to start my circadian rhythm, I sleep on an @eightsleep mattress to optimise my temperature and I use @whoop to measure the accuracy of my sleep cycles. Do you travel overnight often?',
		verified: true,
		url: 'https://twitter.com/louisanicola_/status/1386795923624312833',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1514641142201028612/G-4a-7S2_normal.jpg',
		name: 'Jen Rubio',
		handle: 'jennifer',
		unixDateMS: 1619544829000,
		platform: 'twitter',
		content: '@CalebPolley @eightsleep prefer @eightsleep!',
		verified: false,
		url: 'https://twitter.com/jennifer/status/1387097665150853124',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1495528865279664128/IqP6mNGD_normal.jpg',
		name: 'Brandon Reeves',
		handle: 'breeves08',
		unixDateMS: 1619281155000,
		platform: 'twitter',
		content:
			'Also had my first night in the @eightsleep and it really is special. 100% worth it and recommend. Noticeable difference in sleep quality. <br/><br/>Credit to @geoffreywoo and @zebulgar for the rec and thanks to @m_franceschetti for great customer service along the way. ',
		verified: false,
		url: 'https://twitter.com/breeves08/status/1385991737479417865',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1340847802037911554/yYzK9mBK_normal.jpg',
		name: 'Jeff Baietto',
		handle: 'jeffinjoyglobal',
		unixDateMS: 1619371056000,
		platform: 'twitter',
		content: 'Night #1 on my @eightsleep pod. Sleep has always been my kryptonite. It’s literally a new dawn. Thanks @m_franceschetti ',
		verified: false,
		url: 'https://twitter.com/jeffinjoyglobal/status/1386368808139464706',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/Ez1fE9XVcAIylPs.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1683537760135819265/cTCfSJW7_normal.png',
		name: 'Kevin Rose 🦉',
		handle: 'kevinrose',
		unixDateMS: 1619388665000,
		platform: 'twitter',
		content: '@amywebb @eightsleep awesome, enjoy!!! we love ours cc: @summertomato',
		verified: true,
		url: 'https://twitter.com/kevinrose/status/1386442668885037056',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1515904510467575808/JIw_vnm7_normal.png',
		name: 'Dr. Darya Rose',
		handle: 'summertomato',
		unixDateMS: 1619390296000,
		platform: 'twitter',
		content: '@amywebb @kevinrose @eightsleep I am so obsessed 😍',
		verified: true,
		url: 'https://twitter.com/summertomato/status/1386449508117016580',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1547787118243561478/PxWzJo1H_normal.jpg',
		name: 'Pomp 🌪',
		handle: 'APompliano',
		unixDateMS: 1619272311000,
		platform: 'twitter',
		content: '@awilkinson Try using @eightsleep. Was game changer for me',
		verified: true,
		url: 'https://twitter.com/APompliano/status/1385954645068103687',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1676259816296120321/-F8xAVB3_normal.jpg',
		name: 'Patrick',
		handle: 'woloveck',
		unixDateMS: 1619298108000,
		platform: 'twitter',
		content: "@VanessaAlvarez1 @eightsleep @m_franceschetti I had the same experience. You're going to love it.",
		verified: false,
		url: 'https://twitter.com/woloveck/status/1386062842177200134',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/676842341492101120/g3cKbc_A_normal.jpg',
		name: 'Andres Ro',
		handle: 'AndresARobelo',
		unixDateMS: 1618952845000,
		platform: 'twitter',
		content: '@AustinWalper @eightsleep Its awesome! game changer in every aspect. You will improve your sleep 10x. Go for it!',
		verified: true,
		url: 'https://twitter.com/AndresARobelo/status/1384614706212597764',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1259285101705396225/M3ZKDvYO_normal.jpg',
		name: 'Austin Rief ☕️',
		handle: 'austin_rief',
		unixDateMS: 1618949894000,
		platform: 'twitter',
		content: "@AustinWalper @eightsleep Yes it's absolutely incredible. Love my Eight!",
		verified: true,
		url: 'https://twitter.com/austin_rief/status/1384602326678204423',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/718598294323073024/qu7IlBA8_normal.jpg',
		name: 'Billy Jalbert',
		handle: 'Mauibilly',
		unixDateMS: 1618944427000,
		platform: 'twitter',
		content: '@AustinWalper @eightsleep Yes and it is beyond amazing. Best investment of 2020. The data on my increase in deep sleep is very compelling and the way I feel is beyond.',
		verified: false,
		url: 'https://twitter.com/Mauibilly/status/1384579398087573507',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1528907467253596160/jfu8AffN_normal.jpg',
		name: 'Brad Siegler 🇺🇸 🚀',
		handle: 'BradSiegler',
		unixDateMS: 1618945683000,
		platform: 'twitter',
		content: "@AustinWalper @eightsleep 100% - if your sleep is affected in any way by temp, it's a no brainer. I have two @eightsleep pods at my house and a third on the way.",
		verified: true,
		url: 'https://twitter.com/BradSiegler/status/1384584664522842127',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1250191087353745408/PaKf9rvH_normal.png',
		name: 'José Ancer',
		handle: 'ancerj',
		unixDateMS: 1618948244000,
		platform: 'twitter',
		content: '@patmatthews @coinbase @eightsleep had the eight sleep mattress for about 6 weeks now. easily worth it. my wife likes it hot, but i like it cold. may save marriages.',
		verified: true,
		url: 'https://twitter.com/ancerj/status/1384595409448804357',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1687556223938203648/laQhr70x_normal.jpg',
		name: 'Aubrey Strobel',
		handle: 'aubreystrobel',
		unixDateMS: 1618927188000,
		platform: 'twitter',
		content: '@mrsharma @eightsleep haha already got my Eight Sleep! I love it!',
		verified: true,
		url: 'https://twitter.com/aubreystrobel/status/1384507094741696512',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1017174077046448128/osp05a7l_normal.jpg',
		name: 'Marc Garcia',
		handle: 'TonyPerkisEsq',
		unixDateMS: 1619058374000,
		platform: 'twitter',
		content: '@eightsleep easily has the best customer service.',
		verified: false,
		url: 'https://twitter.com/TonyPerkisEsq/status/1385057324960731136',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1524849005666131968/d-S6tHPT_normal.png',
		name: 'Nik Sharma',
		handle: 'mrsharma',
		unixDateMS: 1618945217000,
		platform: 'twitter',
		content:
			"@AustinWalper @eightsleep Coming up on 2 years with my @eightsleep and I can't tell you enough how amazing my sleep game has gotten... <br/><br/>Consistently hitting 50% REM/deep sleep when I'm in my own bed (according to @whoop). Waking up more refreshed. <br/><br/>I'd spend $15k if that's what this product costed.",
		verified: true,
		url: 'https://twitter.com/mrsharma/status/1384582711969468425',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1618979714982416384/B9aLgZNX_normal.jpg',
		name: 'Chris Moreno ⚡️',
		handle: 'morenochris',
		unixDateMS: 1619047827000,
		platform: 'twitter',
		content: '@srcasm Check out the @eightsleep Suite at The Hyde hotel. @m_franceschetti can probably help reserve it for you. It’ll be the best night (or day) sleep you ever had 🏝',
		verified: false,
		url: 'https://twitter.com/morenochris/status/1385013090236911616',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1525673296792113152/RwbJHL7-_normal.jpg',
		name: 'Jesse Daugherty',
		handle: 'jtdaugh',
		unixDateMS: 1619186987000,
		platform: 'twitter',
		content: 'Is a nice hotel room without an @eightsleep really a nice hotel room?',
		verified: true,
		url: 'https://twitter.com/jtdaugh/status/1385596767559774208',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1590747762676715520/V4pRkEBS_normal.jpg',
		name: 'Mike Annunziata',
		handle: 'nunzi46',
		unixDateMS: 1619095124000,
		platform: 'twitter',
		content: '@Shivansh_C @eightsleep Skip the video demo and just buy it, you won’t regret it',
		verified: true,
		url: 'https://twitter.com/nunzi46/status/1385211469025796099',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1481603783482294275/zRQ-PbRF_normal.jpg',
		name: 'Jimmy Is Promo',
		handle: 'jimmyispromo',
		unixDateMS: 1619063618000,
		platform: 'twitter',
		content: '@dinotjardin @eightsleep For night one, I have it at -2 though it can go to -10 (55 degrees)<br/>Can for sure feel the difference',
		verified: false,
		url: 'https://twitter.com/jimmyispromo/status/1385079323372183554',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1019628812483604480/965ymS27_normal.jpg',
		name: 'Matt Haggman 🇺🇦',
		handle: 'matthaggman',
		unixDateMS: 1617885982000,
		platform: 'twitter',
		content: 'Great news. @DanetLinares and I have used @eightsleep for the past six weeks and it really makes a difference. We love it. ',
		verified: false,
		url: 'https://twitter.com/matthaggman/status/1380139958590115842',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1667916360876269569/84rl3aPV_normal.jpg',
		name: 'Casey',
		handle: 'Case4Cleveland',
		unixDateMS: 1617909990000,
		platform: 'twitter',
		content: '@BrianSloss @eightsleep I think it’s great for side sleepers.',
		verified: false,
		url: 'https://twitter.com/Case4Cleveland/status/1380240656686272512',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1482849191214915587/o-aZo4xw_normal.jpg',
		name: 'Joe Pompliano',
		handle: 'JoePompliano',
		unixDateMS: 1617153164000,
		platform: 'twitter',
		content: '@austin_rief @eightsleep Best in the game 🔥',
		verified: true,
		url: 'https://twitter.com/JoePompliano/status/1377066295150182402',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1611971890532487168/vgWE_5cj_normal.jpg',
		name: 'Zach Tratar',
		handle: 'zachtratar',
		unixDateMS: 1618368509000,
		platform: 'twitter',
		content: '@LubaYudasina @eightsleep I like mine.',
		verified: true,
		url: 'https://twitter.com/zachtratar/status/1382163824749338629',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1031156227886084099/xPH5YsmC_normal.jpg',
		name: 'Tony Federico',
		handle: 'FitwithTfed',
		unixDateMS: 1618333522000,
		platform: 'twitter',
		content: '@m_franceschetti @mannyskutally @eightsleep I love this feature because it doesn’t wake my wife up but it’s enough for me to feel it and start my day.',
		verified: false,
		url: 'https://twitter.com/FitwithTfed/status/1382017076039012353',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1532008723417387011/EpSI3JLj_normal.jpg',
		name: 'Justin Darcy',
		handle: 'justinsdarcy',
		unixDateMS: 1618189389000,
		platform: 'twitter',
		content: '@credibleutility @eightsleep We put the cover over our Casper and love it',
		verified: false,
		url: 'https://twitter.com/justinsdarcy/status/1381412538584272896',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/2360535353/20120630-face_normal.jpg',
		name: 'Justin Searls',
		handle: 'searls',
		unixDateMS: 1616787125000,
		platform: 'twitter',
		content:
			'@spicycode @eightsleep Yes, it actually captures more useful health data (and writes it to the Health app) than the series 6 watch, which I had been sleeping with every night since September.',
		verified: false,
		url: 'https://twitter.com/searls/status/1375531016245362688',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1440796750449504256/NHl126K3_normal.jpg',
		name: 'Michael Spiro',
		handle: 'mspiro3',
		unixDateMS: 1617244702000,
		platform: 'twitter',
		content: '@m_franceschetti @eightsleep Thanks to Eight for changing the way we sleep!',
		verified: false,
		url: 'https://twitter.com/mspiro3/status/1377450234234687488',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1547787118243561478/PxWzJo1H_normal.jpg',
		name: 'Pomp 🌪',
		handle: 'APompliano',
		unixDateMS: 1617424090000,
		platform: 'twitter',
		content: '@CryptoStorm3 @aurelius_noel I’ve never been paid by Eight Sleep. In fact, after sleeping on it I tracked down the founders and invested in the company because it was so legit.',
		verified: true,
		url: 'https://twitter.com/APompliano/status/1378202644162969600',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1260139805058502662/IZzOtRYM_normal.jpg',
		name: 'Mike The Connector',
		handle: 'MRiegelman',
		unixDateMS: 1617547790000,
		platform: 'twitter',
		content: '@eightsleep is a game changer for sleep fitness. I’m a believer, 100%. @m_franceschetti @whoop',
		verified: false,
		url: 'https://twitter.com/MRiegelman/status/1378721478242406405',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1259285101705396225/M3ZKDvYO_normal.jpg',
		name: 'Austin Rief ☕️',
		handle: 'austin_rief',
		unixDateMS: 1617567386000,
		platform: 'twitter',
		content: "@AndrewMedal @eightsleep For me it's all about the cooling features. So nice to keep you cool at night instead of sweating all night.",
		verified: true,
		url: 'https://twitter.com/austin_rief/status/1378803670410756101',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1368918273232957440/STvAyVRX_normal.jpg',
		name: 'Zach Moss',
		handle: 'zachlmoss',
		unixDateMS: 1617822322000,
		platform: 'twitter',
		content: '@2PMinc @SoftBank @eightsleep Love my Eight Cover!',
		verified: false,
		url: 'https://twitter.com/zachlmoss/status/1379872950283857921',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1602122467002155010/MI7V7cqu_normal.png',
		name: 'Josh Ong',
		handle: 'beijingdou',
		unixDateMS: 1617822857000,
		platform: 'twitter',
		content: 'Congrats to the @eightsleep team! Such an incredible product, thankful for it every night. 🛌 ',
		verified: true,
		url: 'https://twitter.com/beijingdou/status/1379875191380131845',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1380604432526741505/ZoxckFlA_normal.jpg',
		name: 'guillaume cabane',
		handle: 'guillaumecabane',
		unixDateMS: 1617996652000,
		platform: 'twitter',
		content:
			'@m_franceschetti @eightsleep @SoftBank @marceloclaure @Gosia_Karas_ @MiamiHerald @rjwile Had an @eightsleep mattress pad since the original kickstarter campaign. Been loving it ever since!',
		verified: false,
		url: 'https://twitter.com/guillaumecabane/status/1380604141911867397',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/718598294323073024/qu7IlBA8_normal.jpg',
		name: 'Billy Jalbert',
		handle: 'Mauibilly',
		unixDateMS: 1618356007000,
		platform: 'twitter',
		content: '@raisingrippers Not organic, but you should explore @eightsleep. Life changing.',
		verified: false,
		url: 'https://twitter.com/Mauibilly/status/1382111386134749188',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1594360270301941765/hqISnZk4_normal.jpg',
		name: 'Kenan Saleh',
		handle: 'kenanhsaleh',
		unixDateMS: 1618368348000,
		platform: 'twitter',
		content: '@LubaYudasina @eightsleep Favorite purchase',
		verified: true,
		url: 'https://twitter.com/kenanhsaleh/status/1382163148367536129',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1477841735170473985/9JV9uHYe_normal.jpg',
		name: 'luba',
		handle: 'LubaYudasina',
		unixDateMS: 1618410743000,
		platform: 'twitter',
		content: 'Where can I sign up for a sleeping pros competition? Cc @eightsleep ',
		verified: false,
		url: 'https://twitter.com/LubaYudasina/status/1382340964417605636',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/Ey8Px3vVcAQo6y9.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1689076141490163712/ovtxGi10_normal.jpg',
		name: 'Ben',
		handle: 'benstreeet',
		unixDateMS: 1618554913000,
		platform: 'twitter',
		content: 'What are we long on? I’m long on @eightsleep personally',
		verified: false,
		url: 'https://twitter.com/benstreeet/status/1382945657971830792',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1667916360876269569/84rl3aPV_normal.jpg',
		name: 'Casey',
		handle: 'Case4Cleveland',
		unixDateMS: 1617879912000,
		platform: 'twitter',
		content: 'First night sleeping with an @eightsleep pillow and I gotta say, it is a game changer.',
		verified: false,
		url: 'https://twitter.com/Case4Cleveland/status/1380114499647250432',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1366533656685260802/HzyZ5Ivr_normal.jpg',
		name: 'Adam Kownacki',
		handle: 'AKbabyface',
		unixDateMS: 1618278174000,
		platform: 'twitter',
		content: 'Final night in Miami. No better way to end an amazing trip than a good night sleep @eightsleep  hub ',
		verified: false,
		url: 'https://twitter.com/AKbabyface/status/1381784929965637632',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/Ey0WDKpXIAYPoB2.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1587814919151489029/BcvGJvNf_normal.jpg',
		name: 'Ben Schmanke',
		handle: 'schmanke',
		unixDateMS: 1618371431000,
		platform: 'twitter',
		content: "@iPhonedo @eightsleep It's amazing. I've been using for over a year",
		verified: false,
		url: 'https://twitter.com/schmanke/status/1382176077934702594',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1122244336144359425/Tlj3Q6el_normal.jpg',
		name: 'Yas',
		handle: 'yas_moaven',
		unixDateMS: 1617628136000,
		platform: 'twitter',
		content:
			'Just woke up in Miami after my first night in an @eightsleep suite. All I can say is... wow. <br/><br/>If that’s sleep fitness then Barry’s is in trouble. I may convert all my workouts into naps 😳',
		verified: false,
		url: 'https://twitter.com/yas_moaven/status/1379058475372843009',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1218405967915704320/QgdC_9bw_normal.jpg',
		name: 'Richard Murray',
		handle: 'richardamurray',
		unixDateMS: 1618408701000,
		platform: 'twitter',
		content:
			"@LubaYudasina @eightsleep I've somehow become someone that can fall asleep. I believe primarily due to the temperature setting. Coupled with how easy it's become to wake up, we're enjoying immensely.",
		verified: false,
		url: 'https://twitter.com/richardamurray/status/1382332399674007553',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1676982730461163521/RrktC3zP_normal.jpg',
		name: 'Marcelo Claure',
		handle: 'marceloclaure',
		unixDateMS: 1615986845000,
		platform: 'twitter',
		content:
			'After sleeping 3 nights in my new @eightsleep mattress I can attest (see below from my @whoop app) that the quality of my sleep has never been better.  Never have I been on green for 3 days. @andrewrsorkin you will not regret your order. @m_franceschetti ',
		verified: true,
		url: 'https://twitter.com/marceloclaure/status/1372174399286173700',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EwrxWABXMAQa0T9.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1585345902117519381/VPardKWK_normal.jpg',
		name: 'Demian 🍄🌵bellumio.eth',
		handle: 'Bellumio',
		unixDateMS: 1617018772000,
		platform: 'twitter',
		content: 'My first 💯on my @eightsleep!! @m_franceschetti ',
		verified: false,
		url: 'https://twitter.com/Bellumio/status/1376502617728245762',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/ExpR1dhXAAE6INP.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1668909037755281408/iqbafvib_normal.jpg',
		name: 'Gary J Allman',
		handle: 'GaryJAllman1',
		unixDateMS: 1616437931000,
		platform: 'twitter',
		content: '@m_franceschetti @altini_marco @eightsleep Best tracker on the market for HRV 👌🏻',
		verified: false,
		url: 'https://twitter.com/GaryJAllman1/status/1374066393893720067',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/950134514113691648/7dt7ECh2_normal.jpg',
		name: 'Dan Friedman',
		handle: '_DanFriedman',
		unixDateMS: 1616525027000,
		platform: 'twitter',
		content: '@willium @eightsleep I love it! I run warm and the cooling helps me sleep through the night. Without it, I would wake up hot a couple hours after getting into bed.',
		verified: false,
		url: 'https://twitter.com/_DanFriedman/status/1374431700340547590',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1144717059042861056/IrsMNQB7_normal.png',
		name: 'delian',
		handle: 'zebulgar',
		unixDateMS: 1615991408000,
		platform: 'twitter',
		content: 'sleep is the most important input to your productivity ie your IRR, why would you not invest in a bed that improves it by 20%<br/><br/>my eight sleep is my competitive advantage ',
		verified: true,
		url: 'https://twitter.com/zebulgar/status/1372193538218278920',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1271909212793290752/eQEEnwbK_normal.jpg',
		name: 'Charles Irick',
		handle: 'charlesirick',
		unixDateMS: 1616443670000,
		platform: 'twitter',
		content:
			"@m_franceschetti @altini_marco @eightsleep Reading about the downward RHR trend here I went to check my @eightsleep data last night. I had a great night of sleep, and it's awesome to see the recovery in the data. ",
		verified: false,
		url: 'https://twitter.com/charlesirick/status/1374090463163117571',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/ExG__l5WEAA-wDE.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1566894252684091395/rXWMcJ5q_normal.jpg',
		name: 'ericosiu',
		handle: 'ericosiu',
		unixDateMS: 1616420373000,
		platform: 'twitter',
		content:
			'There’s something to be said about just being home (just got back from Miami). <br/><br/>First time I scored 100 on my @eightsleep and had a high score on my REM and deep sleep on @ouraring. ',
		verified: true,
		url: 'https://twitter.com/ericosiu/status/1373992750127874049',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/ExFnHvoUYAQJqW5.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1379051826/46747_1517981421999_1008390004_1516221_1265122_n_normal.jpg',
		name: 'Joe Aranda',
		handle: 'JNAranda',
		unixDateMS: 1616688960000,
		platform: 'twitter',
		content:
			'One of the Superpowers that sleeping on an @eightsleep gives you is a baseline that helps you under major health events. My partner and I are waiting for our COVID test results, but in the meantime here is the RHR impact on our fevers. ',
		verified: false,
		url: 'https://twitter.com/JNAranda/status/1375119284108582912',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/ExVnsz8VcAMSjl5.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1637254773/mypic_normal.jpeg',
		name: 'Dan Patrick',
		handle: 'mdpatrick',
		unixDateMS: 1616724072000,
		platform: 'twitter',
		content:
			'The user experience of @eightsleep is an order of magnitude better than all comparable sleep optimization products of the same category.<br/><br/>It’s like the extra 25% that takes something from good to excellent.<br/><br/>- Quiet<br/>- Less complexity<br/>- Dummy proofing maintenance w/ prompts, etc.',
		verified: true,
		url: 'https://twitter.com/mdpatrick/status/1375266554925834241',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1398046034605973508/B4Z7VwJI_normal.jpg',
		name: 'Rexhi Dollaku',
		handle: 'RexhiDollaku',
		unixDateMS: 1616518383000,
		platform: 'twitter',
		content: '@chriszeoli @eightsleep @ouraring I love it',
		verified: false,
		url: 'https://twitter.com/RexhiDollaku/status/1374403833300885504',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1614759269940985857/pRCs5I_s_normal.jpg',
		name: 'D. Fines',
		handle: 'domoniquefines',
		unixDateMS: 1616613195000,
		platform: 'twitter',
		content: 'I’m normally the sleeper, wasn’t sleeping well the past year, and was blessed with a @eightsleep upgrade! I’m on a road to recovery 😴 🛌. Thank you @a_zatarain !',
		verified: false,
		url: 'https://twitter.com/domoniquefines/status/1374801503395844098',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1379051826/46747_1517981421999_1008390004_1516221_1265122_n_normal.jpg',
		name: 'Joe Aranda',
		handle: 'JNAranda',
		unixDateMS: 1616437919000,
		platform: 'twitter',
		content:
			'The New Year led me and my partner to focus on reducing our alcohol consumption during the week. Per our @eightsleep I’m still shocked at the impact on my RHR when we indulge on the weekends. ',
		verified: false,
		url: 'https://twitter.com/JNAranda/status/1374066342203072513',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/ExGqDhZUYAA5MJU.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1031156227886084099/xPH5YsmC_normal.jpg',
		name: 'Tony Federico',
		handle: 'FitwithTfed',
		unixDateMS: 1615646373000,
		platform: 'twitter',
		content:
			"@zsilvs303 @eightsleep We've had ours for almost two years and love it.  I'm a cold sleeper in the summer and my wife isn't.  With this bed we both get a comfortable full night sleep all year round.",
		verified: false,
		url: 'https://twitter.com/FitwithTfed/status/1370746356508278786',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1421932092649312268/1LLJY4nM_normal.jpg',
		name: 'GigaChad',
		handle: 'WallaceCrypto',
		unixDateMS: 1616020465000,
		platform: 'twitter',
		content: '@AnthonyHolley_ @eightsleep Hands down best investment me and my wife have ever made!',
		verified: true,
		url: 'https://twitter.com/WallaceCrypto/status/1372315412273172488',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1689327864896737280/RsPOJTvW_normal.jpg',
		name: 'Justin McCarty',
		handle: 'JustinDMcCarty',
		unixDateMS: 1615863858000,
		platform: 'twitter',
		content:
			'So I think this is now a tech person right of passage? 2 out of my first 3 nights using @eightsleep came in at 100. Great product @m_franceschetti and thanks for the continued push @zebulgar ! ',
		verified: false,
		url: 'https://twitter.com/JustinDMcCarty/status/1371658557699977216',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EwkcL_mVEAUGa4l.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1687556223938203648/laQhr70x_normal.jpg',
		name: 'Aubrey Strobel',
		handle: 'aubreystrobel',
		unixDateMS: 1615899256000,
		platform: 'twitter',
		content: 'This feels like a report card I want to hang on the fridge. My first 💯 w/ @eightsleep. ',
		verified: true,
		url: 'https://twitter.com/aubreystrobel/status/1371807025290739714',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EwmjN_eXAAcfjYS.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1408233261503959040/x1NpY_-3_normal.jpg',
		name: 'Todd McKinnon',
		handle: 'toddmckinnon',
		unixDateMS: 1615749442000,
		platform: 'twitter',
		content:
			'5+ weeks sleeping on my @eightsleep Pod Pro Smart Bed.  It\'s amazing. I highly recommend. Basically silent, no need for heavy blanket on cold nights, sleep data/analytics "just work" no set up or app to start etc. And data is very useful.   Nice work @m_franceschetti',
		verified: false,
		url: 'https://twitter.com/toddmckinnon/status/1371178659025842181',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1562804983426207744/aUr_N2k8_normal.jpg',
		name: 'Nelly',
		handle: 'NelsonsThoughts',
		unixDateMS: 1615749860000,
		platform: 'twitter',
		content: '@m_franceschetti @toddmckinnon @eightsleep It’s a game changer!',
		verified: false,
		url: 'https://twitter.com/NelsonsThoughts/status/1371180413767479296',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1328440064632819712/YP6jLQ3a_normal.jpg',
		name: 'Brian Robinson / brobinson.eth',
		handle: 'br524',
		unixDateMS: 1615851250000,
		platform: 'twitter',
		content: 'My @eightsleep HRV data is tracking pretty well with my @futurefitapp workouts lately.  Supposedly when HRV is up, so does performance.  I believe it! ',
		verified: false,
		url: 'https://twitter.com/br524/status/1371605675411963913',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EwjsFz-W8AEcdiI.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1144717059042861056/IrsMNQB7_normal.png',
		name: 'delian',
		handle: 'zebulgar',
		unixDateMS: 1615991408000,
		platform: 'twitter',
		content: 'sleep is the most important input to your productivity ie your IRR, why would you not invest in a bed that improves it by 20%<br/><br/>my eight sleep is my competitive advantage ',
		verified: true,
		url: 'https://twitter.com/zebulgar/status/1372193538218278920',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1408233261503959040/x1NpY_-3_normal.jpg',
		name: 'Todd McKinnon',
		handle: 'toddmckinnon',
		unixDateMS: 1615749442000,
		platform: 'twitter',
		content:
			'5+ weeks sleeping on my @eightsleep Pod Pro Smart Bed.  It\'s amazing. I highly recommend. Basically silent, no need for heavy blanket on cold nights, sleep data/analytics "just work" no set up or app to start etc. And data is very useful.   Nice work @m_franceschetti',
		verified: false,
		url: 'https://twitter.com/toddmckinnon/status/1371178659025842181',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1287042110412271619/hv5MHqGx_normal.jpg',
		name: 'Chris Young',
		handle: 'Homekitgeek',
		unixDateMS: 1615168208000,
		platform: 'twitter',
		content:
			'@LaurenBerson @eightsleep Oura is awesome throughout the day and night. But it’s purely a sensor. Pod Pro actively manages temp throughout the night to your preference. Oura can show you how amazing eight sleep is for you.',
		verified: false,
		url: 'https://twitter.com/Homekitgeek/status/1368740788046331904',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1552979440547704832/WX5crG9I_normal.jpg',
		name: 'Mckay Wrigley',
		handle: 'mckaywrigley',
		unixDateMS: 1615480849000,
		platform: 'twitter',
		content:
			"@george__mack @eightsleep How much would you pay to wake up feeling refreshed, rested, and alert every single day?<br/><br/>I bet it's a lot more than this bed costs, so buy it. Consider it an investment in your health and daily energy levels.<br/><br/>Something like that.",
		verified: true,
		url: 'https://twitter.com/mckaywrigley/status/1370052101527638017',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1683537760135819265/cTCfSJW7_normal.png',
		name: 'Kevin Rose 🦉',
		handle: 'kevinrose',
		unixDateMS: 1612234435000,
		platform: 'twitter',
		content:
			"I've experimented with temperature-controlled (cold) mattresses for a couple of years to increase deep sleep, recently I tried @eightsleep (not the mattress but the cover for existing mattresses), it's fantastic. This isn't an ad and I'm not an investor, just a fan, great product",
		verified: true,
		url: 'https://twitter.com/kevinrose/status/1356435651625852928',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1259285101705396225/M3ZKDvYO_normal.jpg',
		name: 'Austin Rief ☕️',
		handle: 'austin_rief',
		unixDateMS: 1614534832000,
		platform: 'twitter',
		content: 'Officially one week of sleeping on my @eightsleep in the books. <br/><br/>I’m pretty obsessed.',
		verified: true,
		url: 'https://twitter.com/austin_rief/status/1366084217390661632',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/978132096907599875/weBDVZH-_normal.jpg',
		name: 'Jason McMinn',
		handle: 'jasonmcminn',
		unixDateMS: 1615219693000,
		platform: 'twitter',
		content: '@panekkkk @brian_armstrong @eightsleep I slept hot as well and @eightsleep fixed all those problems. The only problem now is when I travel and not having it 😬',
		verified: false,
		url: 'https://twitter.com/jasonmcminn/status/1368956733498925056',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1590747762676715520/V4pRkEBS_normal.jpg',
		name: 'Mike Annunziata',
		handle: 'nunzi46',
		unixDateMS: 1615125482000,
		platform: 'twitter',
		content: '@pomajp @eightsleep My wife rolled her eyes at the beginning too. Now she loves it and won’t go back.',
		verified: true,
		url: 'https://twitter.com/nunzi46/status/1368561583018885124',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1162365327558225920/LBRRU1FK_normal.jpg',
		name: 'brian lamar moore 🥋',
		handle: 'bmoore__silly__',
		unixDateMS: 1615149569000,
		platform: 'twitter',
		content:
			'@pomajp @eightsleep I added the cover to the mattress a little over a year before I found @eightsleep and it’s amazing! <br/><br/>I’m falling asleep faster than I have in my entire life and sleeping better too.',
		verified: false,
		url: 'https://twitter.com/bmoore__silly__/status/1368662610133016582',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/2598602485/hl9eja8jl3zc2anxsode_normal.png',
		name: 'Peter Pham',
		handle: 'peterpham',
		unixDateMS: 1615153843000,
		platform: 'twitter',
		content:
			"@LaurenBerson @eightsleep Yes. No brainer. Don't have to wear anything. And it adjusts temp 4 times thru night. When you go to bed. Fall asleep, deep sleep and wake up. The alarm is best feature.",
		verified: true,
		url: 'https://twitter.com/peterpham/status/1368680536726671363',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1353476879458570240/kZCcE5Ep_normal.jpg',
		name: 'Ian Mikutel',
		handle: 'IanMikutel',
		unixDateMS: 1612235989000,
		platform: 'twitter',
		content: '@dougsimp @ChrisMasterjohn @kevinrose @eightsleep Used ChiliPad for 3 years or so, worked great but more limited given it was one temp all night.',
		verified: false,
		url: 'https://twitter.com/IanMikutel/status/1356442170773827587',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1683537760135819265/cTCfSJW7_normal.png',
		name: 'Kevin Rose 🦉',
		handle: 'kevinrose',
		unixDateMS: 1612234505000,
		platform: 'twitter',
		content: "@joetimmons79 @eightsleep Yes, via Oura and their app, it's helping for sure.",
		verified: true,
		url: 'https://twitter.com/kevinrose/status/1356435947001323520',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1639344653484060672/gBvsLaUy_normal.jpg',
		name: 'TBiz',
		handle: 'TBizTrades',
		unixDateMS: 1615404256000,
		platform: 'twitter',
		content:
			'@showalter_sam @eightsleep Thanks. I love it. Our HVAC doesn’t get our second floor (where we sleep) warm enough in the winter or cool enough in summer. This has been a game changer and will get the kids each one when the stop having accidents at night.',
		verified: false,
		url: 'https://twitter.com/TBizTrades/status/1369730843716050948',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1376620536319643652/Dd5HmoJ9_normal.jpg',
		name: 'Jarron Jackson',
		handle: 'JarronJackson4',
		unixDateMS: 1615465072000,
		platform: 'twitter',
		content: 'Solid 8hrs 30mins lastnight 🔥 @eightsleep ',
		verified: false,
		url: 'https://twitter.com/JarronJackson4/status/1369985927091720198',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EwMq8EhXEAQcSGc.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1587931405278973953/BojuvUe5_normal.jpg',
		name: 'Matt Galligan',
		handle: 'mg',
		unixDateMS: 1615137872000,
		platform: 'twitter',
		content:
			'@LaurenBerson @eightsleep It’s been a game changer for me in terms of sleep quality. @ouraring confirms easily 2-3x more deep sleep on some nights.<br/><br/>I still find the Oura to be more interesting sleep data, but the Eight’s cooling for me has been clutch.',
		verified: true,
		url: 'https://twitter.com/mg/status/1368613550508417027',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/718598294323073024/qu7IlBA8_normal.jpg',
		name: 'Billy Jalbert',
		handle: 'Mauibilly',
		unixDateMS: 1615144288000,
		platform: 'twitter',
		content:
			'@LaurenBerson @mg @eightsleep @ouraring I long suffered from lack of deep sleep for years. With the pod pro cover, my deep sleep is about 1.5X pre-pod sleep. The pod pro cover sensors register more deep sleep than Oura. Reality...I feel incredible. Best investment of 2020 for me.',
		verified: false,
		url: 'https://twitter.com/Mauibilly/status/1368640458457370627',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1647015695882788865/7Dqoy_rV_normal.jpg',
		name: 'Sean Johnson 🔥',
		handle: 'intentionally',
		unixDateMS: 1615147926000,
		platform: 'twitter',
		content: '@LaurenBerson @eightsleep Found very high correlation betwee app and Oura ring. I’ve also found the vibrating alarm (which I didn’t know about until it arrived) surprisingly pleasant.',
		verified: true,
		url: 'https://twitter.com/intentionally/status/1368655718363041800',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1584868536811495424/rMNXT5pl_normal.jpg',
		name: 'Patrick OShaughnessy',
		handle: 'patrick_oshag',
		unixDateMS: 1613955520000,
		platform: 'twitter',
		content: '@DCoolican @eightsleep The cooling is really game changer for me. I have full pod though. Not sure the difference.',
		verified: true,
		url: 'https://twitter.com/patrick_oshag/status/1363654405615218689',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1611971890532487168/vgWE_5cj_normal.jpg',
		name: 'Zach Tratar',
		handle: 'zachtratar',
		unixDateMS: 1614547749000,
		platform: 'twitter',
		content:
			'3 week update on the @eightsleep! Still great overall. Making my bed cold in the mornings has enabled me to get up far easier than ever before.<br/><br/>The data on waking up and falling asleep is just ok. But easy software that can be updated.<br/><br/>1/n',
		verified: true,
		url: 'https://twitter.com/zachtratar/status/1366138393902477313',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1128432937966325760/2q2UErFu_normal.jpg',
		name: 'Raphael - Landing Pages for DTC',
		handle: 'Rpaulindaigle',
		unixDateMS: 1614359570000,
		platform: 'twitter',
		content:
			"@timsoco @eightsleep Quite a few things actually! Love how it wakes me up more naturally than my alarm, but also love how I can finally use my duvet cover without feeling ridiculously hot. <br/><br/>I don't really get night sweats during winter, but also looking forward to seeing how it helps this summer.",
		verified: true,
		url: 'https://twitter.com/Rpaulindaigle/status/1365349115857694722',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1590197951804088325/cL9dGDRL_normal.jpg',
		name: 'Ryan Bonnici ⚡️ CMO 🇦🇺🇲🇹🏳️‍🌈 (he/him)',
		handle: 'ryanbonnici',
		unixDateMS: 1613955401000,
		platform: 'twitter',
		content: '@DCoolican @eightsleep Highly recommend. Had one for about a year and love it.',
		verified: false,
		url: 'https://twitter.com/ryanbonnici/status/1363653906937634818',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1376620536319643652/Dd5HmoJ9_normal.jpg',
		name: 'Jarron Jackson',
		handle: 'JarronJackson4',
		unixDateMS: 1614437379000,
		platform: 'twitter',
		content: "My sleep health is consistently getting better, I'm about crush that 400lb bench today! @eightsleep @m_franceschetti ",
		verified: false,
		url: 'https://twitter.com/JarronJackson4/status/1365675468557606914',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EvPamVNXUAAL7tj.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1482849191214915587/o-aZo4xw_normal.jpg',
		name: 'Joe Pompliano',
		handle: 'JoePompliano',
		unixDateMS: 1613326821000,
		platform: 'twitter',
		content: 'Night one using @eightsleep ✅<br/><br/>Soon to be sleep champ ✅ ',
		verified: true,
		url: 'https://twitter.com/JoePompliano/status/1361017451845148672',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EuNOKI7WQAInxBX.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1530185281843904512/e6JH2UUx_normal.jpg',
		name: 'David Ulevitch 🇺🇸',
		handle: 'davidu',
		unixDateMS: 1613192328000,
		platform: 'twitter',
		content:
			'@raja2fly @eightsleep 💯💯💯 I have two homes and now have two @eightsleep’s — one pod pro and one pod pro cover. Big fan of both. I already had a new mattress for one bed so got cover.',
		verified: true,
		url: 'https://twitter.com/davidu/status/1360453347682099202',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1524528385950593026/dMfDafhn_normal.jpg',
		name: 'Morgan Brown',
		handle: 'morganb',
		unixDateMS: 1613247087000,
		platform: 'twitter',
		content: 'Got my @eightsleep in mid-November.  Seeing a near 50% gain in deep sleep since. (Data from my @ouraring) ',
		verified: true,
		url: 'https://twitter.com/morganb/status/1360683020831756288',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EuId_wCUUAAJ3TE.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/751106598026637313/ocWvWRkq_normal.jpg',
		name: 'Erika Brown',
		handle: 'ErikaB',
		unixDateMS: 1613275203000,
		platform: 'twitter',
		content: 'This increase is actually pretty amazing. As his wife I feel I’m often the direct beneficiary of this increased restorative sleep so sending my warmest regards to @eightsleep.  ;) ',
		verified: false,
		url: 'https://twitter.com/ErikaB/status/1360800947757223936',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/104845294/DSC_0155_normal.jpg',
		name: 'John Fees',
		handle: 'JohnTFees',
		unixDateMS: 1612497452000,
		platform: 'twitter',
		content: '@jtangoVC Great post.  We moved to @eightsleep and been very happy with the data and sleep results.  Modern waterbed meets  data.  #sleep',
		verified: false,
		url: 'https://twitter.com/JohnTFees/status/1357538826109947905',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/2598602485/hl9eja8jl3zc2anxsode_normal.png',
		name: 'Peter Pham',
		handle: 'peterpham',
		unixDateMS: 1612476507000,
		platform: 'twitter',
		content:
			'.@eightsleep suites in Miami! Partnered perfectly with @Hyperice. @m_franceschetti even got me some @LiquidDeath! But every hotel needs to have an upgrade room to an @eightsleep bed. No more blasting the noisy/windy AC. #MiamiTech ',
		verified: true,
		url: 'https://twitter.com/peterpham/status/1357450975028609027',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EtaidN3XIAQBBTR.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1587931405278973953/BojuvUe5_normal.jpg',
		name: 'Matt Galligan',
		handle: 'mg',
		unixDateMS: 1612455708000,
		platform: 'twitter',
		content:
			'@DrewCoffman @eightsleep Simply put, the Eight did dramatically upgrade my sleep quality, so it’s an easy endorsement.<br/><br/>And yeah, I take a spoon and dip it in water, then mix up the beans with it. Works well!',
		verified: true,
		url: 'https://twitter.com/mg/status/1357363736495980545',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/658653336728637441/OtrOzbuH_normal.jpg',
		name: 'Rob Nance',
		handle: 'RJNance',
		unixDateMS: 1614053504000,
		platform: 'twitter',
		content: '@alexpriest @eightsleep It’s so good. We made the switch right when the pod pro came out. It’s helped me compress sleep. Sleeping on anything else feels medieval.',
		verified: true,
		url: 'https://twitter.com/RJNance/status/1364065381753626624',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1525673296792113152/RwbJHL7-_normal.jpg',
		name: 'Jesse Daugherty',
		handle: 'jtdaugh',
		unixDateMS: 1612400814000,
		platform: 'twitter',
		content: '@m_franceschetti Worked late last night so sadly no 8 hours, but still 3 hours deep. @eightsleep can really make 7 feel like 8 ',
		verified: true,
		url: 'https://twitter.com/jtdaugh/status/1357133497782181889',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EtWBuTVXYAEMQvJ.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1413336844381696000/j9-Tux9q_normal.jpg',
		name: 'Dean Sterrett',
		handle: 'dean_sterrett',
		unixDateMS: 1614782285000,
		platform: 'twitter',
		content: 'It’s disgusting how twitter has just become an echo chamber for techy brands<br/><br/>On an unrelated note I love my brand new @eightsleep Pod Pro ',
		verified: false,
		url: 'https://twitter.com/dean_sterrett/status/1367122110745870339',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/Evj-TrLXIAAuxTw.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1363658791154298884/j2bgrl00_normal.jpg',
		name: 'Roger Boodoo, MD 💀',
		handle: 'boodoodoll',
		unixDateMS: 1614734495000,
		platform: 'twitter',
		content: '@bertcmiller @JeffBooth @eightsleep Nice. This is my winter sleep setting ☺️. Love the @eightsleep ',
		verified: false,
		url: 'https://twitter.com/boodoodoll/status/1366921663149871107',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EvhIAYlVIAA21eE.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1353476879458570240/kZCcE5Ep_normal.jpg',
		name: 'Ian Mikutel',
		handle: 'IanMikutel',
		unixDateMS: 1612286717000,
		platform: 'twitter',
		content:
			'Highest HRV (80ms) in over a year after two weeks of @eightsleep &amp; one week of consistent sleep/wake times.<br/><br/>However, right after that peak, 3 consecutive day HRV decline (53ms today).<br/><br/>Ideas on why? Just overtraining due to @onepeloton every day for last 45 days? Sleep related? ',
		verified: false,
		url: 'https://twitter.com/IanMikutel/status/1356654936893710336',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EtPOeX_VoAE14Xw.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1639344653484060672/gBvsLaUy_normal.jpg',
		name: 'TBiz',
		handle: 'TBizTrades',
		unixDateMS: 1614274372000,
		platform: 'twitter',
		content:
			'@chris__lu @m_franceschetti @theandreboso @eightsleep @PaulYacoubian Here in Michigan it’s an absolute game changer this winter when it’s COLD outside and your HVAC just doesn’t do it two floors up. <br/><br/>I have literally never fallen asleep faster. The data gamifies sleep, making me want to get to bed on time. Can’t to use the cooling over summer.',
		verified: false,
		url: 'https://twitter.com/TBizTrades/status/1364991769151176707',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1291481749399183360/xqPFhPBS_normal.jpg',
		name: 'Chris Lu',
		handle: 'chris__lu',
		unixDateMS: 1614273609000,
		platform: 'twitter',
		content:
			'@theandreboso @eightsleep @PaulYacoubian I personally like the cooling ability to help you fall asleep faster, the embedded vibration and heat alarm to help you wake up, and the metrics to track your sleep!',
		verified: true,
		url: 'https://twitter.com/chris__lu/status/1364988569366970372',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1639344653484060672/gBvsLaUy_normal.jpg',
		name: 'TBiz',
		handle: 'TBizTrades',
		unixDateMS: 1614274614000,
		platform: 'twitter',
		content:
			'@chris__lu @m_franceschetti @theandreboso @eightsleep @PaulYacoubian Should have been “can’t wait”.<br/><br/>Summer gets hot and humid and put A/C struggles cooling the second floor (where we all sleep). Even debated putting in a second unit last year.',
		verified: false,
		url: 'https://twitter.com/TBizTrades/status/1364992784864804864',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1521659560376913920/dQ7Rzjd3_normal.jpg',
		name: 'MichΞllΞ',
		handle: 'MichelleAbbs',
		unixDateMS: 1614271429000,
		platform: 'twitter',
		content:
			'I feel like I got an A+ on my report card after checking my @eightsleep data this morning. Especially loved the Blue Wave glasses to protect my eyes while looking at my screen before bed. Be sure to give it a try if you’re in Miami! ',
		verified: false,
		url: 'https://twitter.com/MichelleAbbs/status/1364979424597278722',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EvFhjQ_XIAAsQ_J.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1291481749399183360/xqPFhPBS_normal.jpg',
		name: 'Chris Lu',
		handle: 'chris__lu',
		unixDateMS: 1614226244000,
		platform: 'twitter',
		content:
			'Ok, I’ve had the @eightsleep for around a week (of active usage) now! It’s actually amazing and lives up to the hype. I’d argue the best part is feeling like waking up naturally every single day...literally a game changer @PaulYacoubian it should be an employee benefit 😂',
		verified: true,
		url: 'https://twitter.com/chris__lu/status/1364789905373724672',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1584868536811495424/rMNXT5pl_normal.jpg',
		name: 'Patrick OShaughnessy',
		handle: 'patrick_oshag',
		unixDateMS: 1613956697000,
		platform: 'twitter',
		content:
			'@m_franceschetti @DCoolican @eightsleep @DCoolican the daily data also just forces you to change to improve it. Drinking way way less (my top learning is drinking destroys HRV and heart rate and makes you feel like you are poisoning yourself). Also the consistency piece. Same time to bed. I get up earlier too.',
		verified: true,
		url: 'https://twitter.com/patrick_oshag/status/1363659343506382850',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1532141963754893312/KAYcFHSm_normal.png',
		name: 'Shawn Sakamoto',
		handle: 'shawnsakamoto',
		unixDateMS: 1612286139000,
		platform: 'twitter',
		content:
			'@kevinrose @eightsleep 💯 @eightsleep is a game changer + best sleep tracking (@ouraring didn’t work well for me and I prefer not to wear Apple Watch to bed). Other cooling solutions output too much heat - now where’s that @eightsleep (or #AppleHealth) iOS widget 🤔',
		verified: true,
		url: 'https://twitter.com/shawnsakamoto/status/1356652515484340230',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1425302879204790273/yI1wTvBH_normal.jpg',
		name: 'Brian Huskinson',
		handle: 'brianhuskinson',
		unixDateMS: 1613956213000,
		platform: 'twitter',
		content: '@DCoolican @eightsleep I did the cover only and zero regrets. I prefer the option of keeping own mattress and being able to change if desired. Seemed like best of both worlds',
		verified: false,
		url: 'https://twitter.com/brianhuskinson/status/1363657312020402176',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1664270968662130694/PUSi89aW_normal.jpg',
		name: 'Jack Appleby',
		handle: 'jappleby',
		unixDateMS: 1614865347000,
		platform: 'twitter',
		content: 'It took Advil PM &amp; cutting some caffeine, but finally got a full night of sleep without waking up. Also, bless @eightsleep - I love this bed so much. ',
		verified: true,
		url: 'https://twitter.com/jappleby/status/1367470498171539465',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/Evo7K6KWgAMl7yS.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1639344653484060672/gBvsLaUy_normal.jpg',
		name: 'TBiz',
		handle: 'TBizTrades',
		unixDateMS: 1612280203000,
		platform: 'twitter',
		content: "Alright, @eightsleep I'm tired of sleeping like crap.<br/><br/>Let's do this! ",
		verified: false,
		url: 'https://twitter.com/TBizTrades/status/1356627618645352448',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EtO1ionWYAIvdBx.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1445760733971566594/UgP8fmeH_normal.jpg',
		name: 'AriannaSimpson.eth',
		handle: 'AriannaSimpson',
		unixDateMS: 1608996943000,
		platform: 'twitter',
		content:
			'Got an @eightsleep for my parents and my mom (who usually wakes up at 3 or 4 am) slept until 7:20, which she hasn’t done in a decade. She was astounded. Now I’m just upset I didn’t get one for myself yet 😂 thanks @m_franceschetti @a_zatarain!',
		verified: true,
		url: 'https://twitter.com/AriannaSimpson/status/1342856627309916163',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1530185281843904512/e6JH2UUx_normal.jpg',
		name: 'David Ulevitch 🇺🇸',
		handle: 'davidu',
		unixDateMS: 1608957148000,
		platform: 'twitter',
		content:
			'I didn’t expect to write this tweet, but I’ve had an @eightsleep mattress for the last week or so and it’s a game changer. Probably a life changer. I’m not an investor, no incentive, etc. but sleep is crucial and this product helps me sleep better and provides interesting data.',
		verified: true,
		url: 'https://twitter.com/davidu/status/1342689713975541762',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1524849005666131968/d-S6tHPT_normal.png',
		name: 'Nik Sharma',
		handle: 'mrsharma',
		unixDateMS: 1613957802000,
		platform: 'twitter',
		content: '@DCoolican @eightsleep Pod user here and it’s been a game changer for my sleep and daily mental performance',
		verified: true,
		url: 'https://twitter.com/mrsharma/status/1363663975385358336',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1363658791154298884/j2bgrl00_normal.jpg',
		name: 'Roger Boodoo, MD 💀',
		handle: 'boodoodoll',
		unixDateMS: 1615383883000,
		platform: 'twitter',
		content: "So badly needed last night's 💯after the previous night work-related interrupted sleep. @eightsleep @bertcmiller @brian_armstrong ",
		verified: false,
		url: 'https://twitter.com/boodoodoll/status/1369645393823272963',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EwH1OctWYAE4vVW.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1643539922886983680/tqYOYBz9_normal.jpg',
		name: 'docceng',
		handle: 'docceng',
		unixDateMS: 1613958099000,
		platform: 'twitter',
		content: '@DCoolican @eightsleep Makes all the difference for me',
		verified: false,
		url: 'https://twitter.com/docceng/status/1363665221622124544',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1259285101705396225/M3ZKDvYO_normal.jpg',
		name: 'Austin Rief ☕️',
		handle: 'austin_rief',
		unixDateMS: 1614535525000,
		platform: 'twitter',
		content: '@AndresMazabel @eightsleep I get super hot when I sleep so the cooling feature is incredible',
		verified: true,
		url: 'https://twitter.com/austin_rief/status/1366087122281787393',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1641945139177193473/SKxPKNAn_normal.jpg',
		name: 'Lorenzo Thione (he/him) 🏳️‍🌈',
		handle: 'thione',
		unixDateMS: 1614789474000,
		platform: 'twitter',
		content: '@eightsleep has transformed my sleep and I am a passionate investor and avid ambassador for their technology and products. #SleepIsFitness ',
		verified: true,
		url: 'https://twitter.com/thione/status/1367152264591335427',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1585345902117519381/VPardKWK_normal.jpg',
		name: 'Demian 🍄🌵bellumio.eth',
		handle: 'Bellumio',
		unixDateMS: 1614529784000,
		platform: 'twitter',
		content:
			'I can’t believe that I have been so busy that didn’t realize that my @eightsleep was in my mail room for two weeks!!🤦‍♂️. Well, now I have it and can’t wait for tonight!! Thanks @m_franceschetti 🙏 #miamitechlife ',
		verified: false,
		url: 'https://twitter.com/Bellumio/status/1366063044904833025',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EvU7GPeWYAgIZxi.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1683667154435211264/JYiqb2DZ_normal.jpg',
		name: 'Tommy Collison',
		handle: 'tommycollison',
		unixDateMS: 1613960323000,
		platform: 'twitter',
		content: "@DCoolican @eightsleep Getting one has been, if you'll pardon the expression, night and day for me.",
		verified: true,
		url: 'https://twitter.com/tommycollison/status/1363674551779880962',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1664270968662130694/PUSi89aW_normal.jpg',
		name: 'Jack Appleby',
		handle: 'jappleby',
		unixDateMS: 1614865411000,
		platform: 'twitter',
		content: '@austin_rief @eightsleep It’s unreal. The temp controls make it the only bed I’ve ever been able to take quick naps on, too',
		verified: true,
		url: 'https://twitter.com/jappleby/status/1367470764857978881',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1289245327388823559/JupiUAKf_normal.jpg',
		name: 'Josh Clemente',
		handle: 'joshuasforrest',
		unixDateMS: 1614217479000,
		platform: 'twitter',
		content: 'No time to wait for a proper bed frame. Finally firing up my sleep fitness @eightsleep ',
		verified: true,
		url: 'https://twitter.com/joshuasforrest/status/1364753140684382209',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EvCSUwrUcAEUaXB.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1528907467253596160/jfu8AffN_normal.jpg',
		name: 'Brad Siegler 🇺🇸 🚀',
		handle: 'BradSiegler',
		unixDateMS: 1614199426000,
		platform: 'twitter',
		content: "@JoeVennare Don't sleep on @eightsleep (well, literally DO sleep on it, but don't leave them out 🙃)",
		verified: true,
		url: 'https://twitter.com/BradSiegler/status/1364677420964474883',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1259285101705396225/M3ZKDvYO_normal.jpg',
		name: 'Austin Rief ☕️',
		handle: 'austin_rief',
		unixDateMS: 1614549822000,
		platform: 'twitter',
		content:
			"@cpen @eightsleep None. It took me about 3 days to figure out my ideal temperature to sleep to and wake up to and it's awesome. I also really like the built in vibrations as an alarm clock. I have gotten up much easier.",
		verified: true,
		url: 'https://twitter.com/austin_rief/status/1366147090871685125',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1031156227886084099/xPH5YsmC_normal.jpg',
		name: 'Tony Federico',
		handle: 'FitwithTfed',
		unixDateMS: 1614128540000,
		platform: 'twitter',
		content:
			"Just had the opportunity to talk to someone about how much @eightsleep has improved my sleep.  I can't thank @m_franceschetti, @a_zatarain, and team enough for such an awesome product and the people there.  Truly great folks!",
		verified: false,
		url: 'https://twitter.com/FitwithTfed/status/1364380104973905920',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1590747762676715520/V4pRkEBS_normal.jpg',
		name: 'Mike Annunziata',
		handle: 'nunzi46',
		unixDateMS: 1614285832000,
		platform: 'twitter',
		content: '@eightsleep and #HRV tracking is quickly shaming me into quitting drinking... ',
		verified: true,
		url: 'https://twitter.com/nunzi46/status/1365039834306281472',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EvGYfmUUcAIGSce.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1584868536811495424/rMNXT5pl_normal.jpg',
		name: 'Patrick OShaughnessy',
		handle: 'patrick_oshag',
		unixDateMS: 1613604065000,
		platform: 'twitter',
		content: 'Then add in @eightsleep for sleep and it’s an incredible health stack.',
		verified: true,
		url: 'https://twitter.com/patrick_oshag/status/1362180295894720512',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1622205874121969664/n_zZjDKn_normal.jpg',
		name: 'Nikos Drandakis',
		handle: 'drandakis',
		unixDateMS: 1614070573000,
		platform: 'twitter',
		content:
			'I had my first sleep on my new @eightsleep matress cover and boy was it a sleep experience. Not only I had my bed’s temperature fully controlled but also had advanced reporting on my sleep patterns too. The era of quantified self is exciting. [disclosure: I’m also an investor]',
		verified: true,
		url: 'https://twitter.com/drandakis/status/1364136971426267137',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1259285101705396225/M3ZKDvYO_normal.jpg',
		name: 'Austin Rief ☕️',
		handle: 'austin_rief',
		unixDateMS: 1614865361000,
		platform: 'twitter',
		content: '@JuiceboxCA @eightsleep The best product I own',
		verified: true,
		url: 'https://twitter.com/austin_rief/status/1367470555792887813',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1253596896800542720/_AYMSHsD_normal.jpg',
		name: 'Niv Dror',
		handle: 'NivDror',
		unixDateMS: 1614110928000,
		platform: 'twitter',
		content: 'The @eightsleep has arrived 🥳 ',
		verified: true,
		url: 'https://twitter.com/NivDror/status/1364306234409586692',
		categories: ['Everyday love'],
		video: 'https://video.twimg.com/ext_tw_video/1364306191338201091/pu/pl/F7jUi2_oAiAHT-iK.m3u8?tag=10',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1590747762676715520/V4pRkEBS_normal.jpg',
		name: 'Mike Annunziata',
		handle: 'nunzi46',
		unixDateMS: 1613481461000,
		platform: 'twitter',
		content: 'Night 1 with @eightsleep...I’ll take it. <br/><br/>😴 🛌 💤  ',
		verified: true,
		url: 'https://twitter.com/nunzi46/status/1361666057581309954',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EuWcD9wWgAIwc7v.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1661561909538160641/8zaQQ6-J_normal.jpg',
		name: 'LaVonne Idlette, OLY',
		handle: 'idlette',
		unixDateMS: 1613469150000,
		platform: 'twitter',
		content: 'I don’t sleep that long but it’s quality @eightsleep #bestrecovery ',
		verified: false,
		url: 'https://twitter.com/idlette/status/1361614421743828998',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EuVtGQEXMAMnGGn.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1567592923293794304/89IH2_M-_normal.jpg',
		name: 'jill gunter',
		handle: 'jillrgunter',
		unixDateMS: 1613170754000,
		platform: 'twitter',
		content: "What is the highest impact purchase you've ever made on improving your sleep.<br/><br/>Note: I already have an @eightsleep",
		verified: true,
		url: 'https://twitter.com/jillrgunter/status/1360362859893460992',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1570527639726850048/7b4kgBet_normal.jpg',
		name: 'Casey Means, MD',
		handle: 'DrCaseysKitchen',
		unixDateMS: 1613159535000,
		platform: 'twitter',
		content: '@Mark_Sisson @eightsleep is the only way to go. <br/><br/>@m_franceschetti @a_zatarain',
		verified: false,
		url: 'https://twitter.com/DrCaseysKitchen/status/1360315803736805377',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1328440064632819712/YP6jLQ3a_normal.jpg',
		name: 'Brian Robinson / brobinson.eth',
		handle: 'br524',
		unixDateMS: 1613154007000,
		platform: 'twitter',
		content: 'I’ve never been so excited to go to sleep in my entire life @eightsleep ! ',
		verified: false,
		url: 'https://twitter.com/br524/status/1360292614919053317',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EuC666hXAAUcX8s.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1615403830702637057/DOWAiimW_normal.jpg',
		name: 'Andres Arias',
		handle: 'andresarias',
		unixDateMS: 1613321325000,
		platform: 'twitter',
		content:
			'I got my @eightsleep pod this week. Fantastic system, the cooling/heating unit is super quiet, comfort is top-notch, and I have been getting pretty intense dreams due to higher REM overall percentage measured with @ouraring #MiamiTechlife thanks @m_franceschetti',
		verified: false,
		url: 'https://twitter.com/andresarias/status/1360994400541814789',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1031156227886084099/xPH5YsmC_normal.jpg',
		name: 'Tony Federico',
		handle: 'FitwithTfed',
		unixDateMS: 1613314787000,
		platform: 'twitter',
		content:
			"@ILOVEMATTYICE @eightsleep We love ours.  Awesome technology backed by folks that really care about sleep fitness.  I'm sleeping much cooler in the summer, warmer in the winter and the sleep stats are pretty interesting too.  I can visually see where I slept better after changing roles at work.",
		verified: false,
		url: 'https://twitter.com/FitwithTfed/status/1360966975271407621',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1517853802531012613/auKFB_bW_normal.png',
		name: 'Edward Gorbis',
		handle: 'edward_gorbis',
		unixDateMS: 1613263202000,
		platform: 'twitter',
		content: '@mattsandrini 8 hrs of sleep.<br/><br/>S/O @eightsleep',
		verified: false,
		url: 'https://twitter.com/edward_gorbis/status/1360750613806546954',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1524528385950593026/dMfDafhn_normal.jpg',
		name: 'Morgan Brown',
		handle: 'morganb',
		unixDateMS: 1613250370000,
		platform: 'twitter',
		content: '@mikesimonsen @eightsleep @ouraring Pod Pro. The mattress is firmer (which I like) and the temp control is 💯. Can also do the cover only if you like your current mattress.',
		verified: true,
		url: 'https://twitter.com/morganb/status/1360696792682098688',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1421932092649312268/1LLJY4nM_normal.jpg',
		name: 'GigaChad',
		handle: 'WallaceCrypto',
		unixDateMS: 1613241391000,
		platform: 'twitter',
		content: '@JoePompliano @eightsleep Have had the Pod for 2 years, it will change your life Pomp!',
		verified: true,
		url: 'https://twitter.com/WallaceCrypto/status/1360659131460386816',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1482849191214915587/o-aZo4xw_normal.jpg',
		name: 'Joe Pompliano',
		handle: 'JoePompliano',
		unixDateMS: 1613237794000,
		platform: 'twitter',
		content: '👀👀👀 @eightsleep ',
		verified: true,
		url: 'https://twitter.com/JoePompliano/status/1360644045975019529',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EuH6i2kXAAAajgz.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1490357341824290816/TY4X-KoW_normal.jpg',
		name: 'Girl Gone Crypto',
		handle: 'girlgone_crypto',
		unixDateMS: 1613274357000,
		platform: 'twitter',
		content:
			'@hashoshi4 @JoePompliano @eightsleep I really love being able to control the temperature. I’ve been using an Oura ring to track my sleep quality for about a year now, and my overall sleep numbers really increased when I started using 8sleep.',
		verified: true,
		url: 'https://twitter.com/girlgone_crypto/status/1360797401276063745',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1673689002782736384/hbq64sxL_normal.jpg',
		name: 'Todd Saunders',
		handle: 'toddsaunders',
		unixDateMS: 1613228093000,
		platform: 'twitter',
		content: '@JonahLupton @Apple @whoop @ouraring Get @eightsleep 💤💤',
		verified: true,
		url: 'https://twitter.com/toddsaunders/status/1360603356125941764',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1643090628895715328/uBkRC8tC_normal.jpg',
		name: 'Sara',
		handle: 'ifeellikemacmac',
		unixDateMS: 1613447069000,
		platform: 'twitter',
		content: '@a_zatarain @eightsleep I’m going to start telling everyone I sleep in the mattress equivalent of a Ferrari 🏎',
		verified: false,
		url: 'https://twitter.com/ifeellikemacmac/status/1361521807111561229',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1683456114443137025/pix1OdaR_normal.jpg',
		name: 'Jonah Lupton',
		handle: 'JonahLupton',
		unixDateMS: 1613229442000,
		platform: 'twitter',
		content: "@Zimvesting @Apple @whoop @ouraring @m_franceschetti @a_zatarain @eightsleep @Purple Never thought I'd own a mattress that was smarter than me :)",
		verified: true,
		url: 'https://twitter.com/JonahLupton/status/1360609012954365953',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1532008723417387011/EpSI3JLj_normal.jpg',
		name: 'Justin Darcy',
		handle: 'justinsdarcy',
		unixDateMS: 1613337594000,
		platform: 'twitter',
		content: "@ILOVEMATTYICE @eightsleep We put it over our Casper and it's been great.  Temp control is a game changer and the haptic feedback wake up ensures a consistent schedule",
		verified: false,
		url: 'https://twitter.com/justinsdarcy/status/1361062636037808130',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1688969071172227072/1R9M7VC5_normal.jpg',
		name: 'Maddie',
		handle: 'Madamelic',
		unixDateMS: 1613334244000,
		platform: 'twitter',
		content: '@JoePompliano @eightsleep Sunday is my sleep-in day. 😂 ',
		verified: false,
		url: 'https://twitter.com/Madamelic/status/1361048585530454017',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EuNqeYOWgAAs88T.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1644135420148723715/FBn1yiz__normal.jpg',
		name: 'michael stavros',
		handle: 'mstavros',
		unixDateMS: 1613327961000,
		platform: 'twitter',
		content: '@ILOVEMATTYICE @eightsleep Do it.  Great product.',
		verified: false,
		url: 'https://twitter.com/mstavros/status/1361022234169667587',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1686281143816257536/evtefap-_normal.jpg',
		name: 'Ian Macalinao',
		handle: 'simplyianm',
		unixDateMS: 1613468707000,
		platform: 'twitter',
		content: 'Love my @eightsleep warm mode for this Texas polar vortex 🥰🛏',
		verified: false,
		url: 'https://twitter.com/simplyianm/status/1361612562199162882',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1254825219962933255/OsNpqZwI_normal.jpg',
		name: 'Paul Tribbey',
		handle: 'PaulTribbey',
		unixDateMS: 1613230567000,
		platform: 'twitter',
		content:
			'@Zimvesting @JonahLupton @Apple @whoop @ouraring @m_franceschetti @a_zatarain @eightsleep @Purple I’ve had it for a couple months. I’m not sure I agree with the sleep score data that I receive (up often at night with young kids, and wake up to a 100), but the bed/temp control is incredible - waking up via vibration is nice too. I’d recommend it.',
		verified: false,
		url: 'https://twitter.com/PaulTribbey/status/1360613731860951040',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1490357341824290816/TY4X-KoW_normal.jpg',
		name: 'Girl Gone Crypto',
		handle: 'girlgone_crypto',
		unixDateMS: 1613271806000,
		platform: 'twitter',
		content: '@JoePompliano @eightsleep Got one a few months ago and LOVE it. 😴',
		verified: true,
		url: 'https://twitter.com/girlgone_crypto/status/1360786703032619008',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1328440064632819712/YP6jLQ3a_normal.jpg',
		name: 'Brian Robinson / brobinson.eth',
		handle: 'br524',
		unixDateMS: 1613600582000,
		platform: 'twitter',
		content:
			'The day after my first @eightsleep I got a PR in the 1k row in my @futurefitapp workout.  Super proud of the splits; I executed EXACTLY how I wanted to!  Thanks as always to @rosstcurtis, the SUPER COACH! #manageYourEnergy #BOOYAH ',
		verified: false,
		url: 'https://twitter.com/br524/status/1362165686420856837',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EudieCbXYAIlz9e.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1328440064632819712/YP6jLQ3a_normal.jpg',
		name: 'Brian Robinson / brobinson.eth',
		handle: 'br524',
		unixDateMS: 1613567226000,
		platform: 'twitter',
		content: 'Well that went well for the first night with @eightsleep! ',
		verified: false,
		url: 'https://twitter.com/br524/status/1362025783552864265',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EubjOpRXYAAU4-h.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1524849005666131968/d-S6tHPT_normal.png',
		name: 'Nik Sharma',
		handle: 'mrsharma',
		unixDateMS: 1613580763000,
		platform: 'twitter',
		content: '@eightsleep Got a 100 last night too!! 🔥🔥💪 ',
		verified: true,
		url: 'https://twitter.com/mrsharma/status/1362082561208569859',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EucW3qUWQAMcige.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/2598602485/hl9eja8jl3zc2anxsode_normal.png',
		name: 'Peter Pham',
		handle: 'peterpham',
		unixDateMS: 1613503197000,
		platform: 'twitter',
		content:
			"11pm last night temp was 95! Went to bed at 2am freezing even after 20 min hot shower, hands wouldn't warm up. Cranked my @eightsleep bed to +5 hot (I normally sleep -2 cold) to stay warm. had to take advil. Feel fine this morn. I think I got the real thing! ",
		verified: true,
		url: 'https://twitter.com/peterpham/status/1361757225883115520',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/718598294323073024/qu7IlBA8_normal.jpg',
		name: 'Billy Jalbert',
		handle: 'Mauibilly',
		unixDateMS: 1613667710000,
		platform: 'twitter',
		content: '@eightsleep Granted you led the witness, but I’ll bite...Pod Pro Cover. Every other hack was incremental. Pod pro cover was an order of magnitude improvement in deep sleep.',
		verified: false,
		url: 'https://twitter.com/Mauibilly/status/1362447244956364801',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1570527639726850048/7b4kgBet_normal.jpg',
		name: 'Casey Means, MD',
		handle: 'DrCaseysKitchen',
		unixDateMS: 1613159535000,
		platform: 'twitter',
		content: '@Mark_Sisson @eightsleep is the only way to go. <br/><br/>@m_franceschetti @a_zatarain',
		verified: false,
		url: 'https://twitter.com/DrCaseysKitchen/status/1360315803736805377',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1590747762676715520/V4pRkEBS_normal.jpg',
		name: 'Mike Annunziata',
		handle: 'nunzi46',
		unixDateMS: 1613152504000,
		platform: 'twitter',
		content: '@m_franceschetti Night 1 with @eightsleep was awesome last night! As if I didn’t already love sleeping, now I look forward to it even more...',
		verified: true,
		url: 'https://twitter.com/nunzi46/status/1360286310884286466',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1688969071172227072/1R9M7VC5_normal.jpg',
		name: 'Maddie',
		handle: 'Madamelic',
		unixDateMS: 1613140089000,
		platform: 'twitter',
		content: "@m_franceschetti @FitwithTfed @jtdaugh @eightsleep (I was suspicious of the Eight Sleep, but my partner and I can't live without it now.) ",
		verified: false,
		url: 'https://twitter.com/Madamelic/status/1360234238667329540',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/2149669470/524135_10150814683765016_674325015_11943648_1147003446_n_normal.jpeg',
		name: 'Razvan Roman',
		handle: 'razvanr',
		unixDateMS: 1612936505000,
		platform: 'twitter',
		content:
			'@IanMikutel @Levels My sleep is influenced by too many factors to isolate just glucose effects, but I sleep better than I’ve ever had and very reliably with @eightsleep, which we got around the same time as @levels. <br/>You won’t regret using these products. I also had @ouraring for readonly before.',
		verified: true,
		url: 'https://twitter.com/razvanr/status/1359380345959845888',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1611971890532487168/vgWE_5cj_normal.jpg',
		name: 'Zach Tratar',
		handle: 'zachtratar',
		unixDateMS: 1612732189000,
		platform: 'twitter',
		content:
			'Fun fact: I have learned that the @eightsleep is the ultimate defense against alarm snoozing.<br/><br/>Warm the bed in my final hours of sleep and then crash the temperature to “extreme cold” and you bet I’m getting up.<br/><br/>It’s like turning a nice, warm cocoon into a winter hellscape.',
		verified: true,
		url: 'https://twitter.com/zachtratar/status/1358523383172849664',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1669521226673291266/k4IciI3K_normal.jpg',
		name: 'Alex Rosen',
		handle: 'Lexiro',
		unixDateMS: 1612725547000,
		platform: 'twitter',
		content:
			"@m_franceschetti @eightsleep update - I am a month into my eight sleep pod, and LOVE it. It's definitely alerted me to when I get 7% less sleep than average user 😳and also ended up at a 3-4 setting on heat haha. I'm coming to Miami next week, where can I learn more about the pod suites there?",
		verified: false,
		url: 'https://twitter.com/Lexiro/status/1358495523695513600',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1287042110412271619/hv5MHqGx_normal.jpg',
		name: 'Chris Young',
		handle: 'Homekitgeek',
		unixDateMS: 1612402530000,
		platform: 'twitter',
		content: '@m_franceschetti @ouraring @eightsleep Absolutely! The worst thing about my @eightsleep is that someday soon I’ll have to travel and be without it!',
		verified: false,
		url: 'https://twitter.com/Homekitgeek/status/1357140693093875716',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1276558311538724865/5pZVupde_normal.jpg',
		name: 'ruthannharnisch',
		handle: 'ruthannharnisch',
		unixDateMS: 1612663124000,
		platform: 'twitter',
		content:
			'@LindseyTWood It is magic &amp; should be medically deductible. Sleep is essential. @tferriss recommendations that were gold include @eightsleep &amp; #BedOfNails for acupressure We can’t remember a bigger bang- for-the-buck purchase.',
		verified: true,
		url: 'https://twitter.com/ruthannharnisch/status/1358233703537922050',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1525673296792113152/RwbJHL7-_normal.jpg',
		name: 'Jesse Daugherty',
		handle: 'jtdaugh',
		unixDateMS: 1612221591000,
		platform: 'twitter',
		content:
			'“You can’t optimize what you can’t measure” is spilling more and more from business into health<br/><br/>@eightsleep has been a game changer for me<br/><br/>I can’t wait to receive my @Levels<br/><br/>I use an Apple Watch, but am open to switching.<br/><br/>What do I need to add?',
		verified: true,
		url: 'https://twitter.com/jtdaugh/status/1356381779347853315',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1653857508400742400/yojLhV4Z_normal.jpg',
		name: 'AC',
		handle: 'ACthecollector',
		unixDateMS: 1611959172000,
		platform: 'twitter',
		content:
			'Had my first sleep on @eightsleep last night and wow. <br/><br/>I’ve never been so happy being woken up in my entire life. <br/><br/>This is not just a mattress, it is a requirement for improving quality of life. Really incredible thing you guys are building @m_franceschetti @a_zatarain',
		verified: true,
		url: 'https://twitter.com/ACthecollector/status/1355281115779641349',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1643354251932909570/6PakMDxx_normal.jpg',
		name: 'Lucas Vaz',
		handle: 'lucasbagnocvaz',
		unixDateMS: 1611611071000,
		platform: 'twitter',
		content:
			'Just chatting with someone over Zoom and we randomly started chatting about @eightsleep  - "I\'ve referred it to 10+ people who bought one at this point. It\'s not an understatement to say that it is life-changing. It really does change everything."',
		verified: true,
		url: 'https://twitter.com/lucasbagnocvaz/status/1353821072974483456',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1492589577508249602/zDWsH3Fk_normal.jpg',
		name: 'Catherine Shyu Sullivan 🛡️',
		handle: 'cthrin',
		unixDateMS: 1610825539000,
		platform: 'twitter',
		content: 'Day 1 of @eightsleep: my bed feels like a cocoon and we no longer need AC and 2 fans to keep it cool in there so we can sleep through the night 😂 Also, the stats are so fun. ',
		verified: false,
		url: 'https://twitter.com/cthrin/status/1350526314474733573',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/Er4IhofVcAAQy5P.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/978132096907599875/weBDVZH-_normal.jpg',
		name: 'Jason McMinn',
		handle: 'jasonmcminn',
		unixDateMS: 1610574958000,
		platform: 'twitter',
		content: '@RyanNegri @eightsleep @m_franceschetti My @eightsleep is the best thing I have purchased in 5 years. Now . . . The big problem is sleeping without it when you travel 😬',
		verified: false,
		url: 'https://twitter.com/jasonmcminn/status/1349475301823115265',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1372568694426120195/TXPcONdr_normal.jpg',
		name: 'parth (jvmname@androiddev.social)',
		handle: 'JvmName',
		unixDateMS: 1610291628000,
		platform: 'twitter',
		content:
			"@valera_zakharov Big fan of @eightsleep! I've been using it since 2019 and it's been great!<br/><br/>It's spendy, but given how often I overheated in the middle of the night, it's so worth it",
		verified: false,
		url: 'https://twitter.com/JvmName/status/1348286930425933831',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1562804983426207744/aUr_N2k8_normal.jpg',
		name: 'Nelly',
		handle: 'NelsonsThoughts',
		unixDateMS: 1610225008000,
		platform: 'twitter',
		content:
			'@Rpaulindaigle @eightsleep I do and I love it.  Sleep has improved dramatically and is more enjoyable.  No more waking up in the middle of the night due to temperature being too hot or too cold.',
		verified: false,
		url: 'https://twitter.com/NelsonsThoughts/status/1348007505947889664',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1337800260639485955/kd8rUcqN_normal.jpg',
		name: 'Eric Glyman',
		handle: 'eglyman',
		unixDateMS: 1605208243000,
		platform: 'twitter',
		content:
			'@awilkinson Easy, it’s the @eightsleep ! Better sleep, better workouts (shows when you’re in better condition for heavier days), and incredible pace of improvement. Could not recommend more highly',
		verified: true,
		url: 'https://twitter.com/eglyman/status/1326965668827312128',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/509770564123111424/aMuq_28G_normal.jpeg',
		name: 'David Eckstein',
		handle: 'davidneckstein',
		unixDateMS: 1605206389000,
		platform: 'twitter',
		content:
			"@awilkinson @eightsleep has changed my life. I can no longer sleep without it. We spend 1/3 of our life on a dumb piece of springs, coils and fabric. It's critical we bring the same intelligence to our mattress that we have in our cars.",
		verified: false,
		url: 'https://twitter.com/davidneckstein/status/1326957889974661123',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1206207547453005825/u1HEyuJQ_normal.jpg',
		name: 'Alexander A. Morgan, MD PhD',
		handle: 'genomicsdoc',
		unixDateMS: 1604419534000,
		platform: 'twitter',
		content:
			"@m_franceschetti @patrick_oshag @ouraring @eightsleep @whoop @Apple Absolutely, I just wanted to anything something that wasn't on the list.  A key differentiator for these approaches (e.g. @eightsleep) is that they aren't just tracking/reporting, but they actually work to directly, physically improve your sleep by their operation.",
		verified: false,
		url: 'https://twitter.com/genomicsdoc/status/1323657583169736704',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/751106598026637313/ocWvWRkq_normal.jpg',
		name: 'Erika Brown',
		handle: 'ErikaB',
		unixDateMS: 1604375506000,
		platform: 'twitter',
		content:
			'So @morganb has been forwarding me @eightsleep emails for almost a year now. I just received another and said “what do I have to do to get one of these in our bedroom?” His reply “say yes.” Haha. (And I said yes, duh.)',
		verified: false,
		url: 'https://twitter.com/ErikaB/status/1323472913849176064',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1618234123117633537/HxwoOZVI_normal.jpg',
		name: 'Brett Bivens',
		handle: 'brettbivens',
		unixDateMS: 1604349564000,
		platform: 'twitter',
		content:
			'Which precision wellness branch has the largest positive dollar for dollar spillover behavioral impact on other categories?<br/><br/>Sleep (@eightsleep)<br/>Nutrition (@Levels)<br/>Performance Analytics (@Whoop)<br/>Mental Health (@Headspace)<br/>Training (@Tonal) ',
		verified: false,
		url: 'https://twitter.com/brettbivens/status/1323364104216678401',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/509770564123111424/aMuq_28G_normal.jpeg',
		name: 'David Eckstein',
		handle: 'davidneckstein',
		unixDateMS: 1604348338000,
		platform: 'twitter',
		content: '@patrick_oshag @ouraring @eightsleep @whoop @Apple No question! @eightsleep is incredible. Just spent weekend away from my apartment and desperately missed my mattress!',
		verified: false,
		url: 'https://twitter.com/davidneckstein/status/1323358964118151169',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1584868536811495424/rMNXT5pl_normal.jpg',
		name: 'Patrick OShaughnessy',
		handle: 'patrick_oshag',
		unixDateMS: 1604347280000,
		platform: 'twitter',
		content: 'Who is at the forefront of sleep technology?<br/><br/>@ouraring <br/>@eightsleep <br/>@whoop <br/>@Apple',
		verified: true,
		url: 'https://twitter.com/patrick_oshag/status/1323354527723102215',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1337800260639485955/kd8rUcqN_normal.jpg',
		name: 'Eric Glyman',
		handle: 'eglyman',
		unixDateMS: 1604075467000,
		platform: 'twitter',
		content:
			'Late to the party because I’ve been sleeping so soundly on my @eightsleep. This is awesome. #1 reason people didn’t buy before was they hesitated to replace their old mattress (liked firmness or w/e). Now not an issue. Just so good ',
		verified: true,
		url: 'https://twitter.com/eglyman/status/1322214460895989760',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1368899577844281348/m-uUNi0i_normal.jpg',
		name: 'Graham Mann',
		handle: 'grahamkmann',
		unixDateMS: 1612450841000,
		platform: 'twitter',
		content:
			"Make your bedroom cold.<br/><br/>Everyone is different, but most people sleep better when it's cold.<br/><br/>If you can afford it, buy an @eightsleep mattress or pod to control the temperature of your bed.",
		verified: true,
		url: 'https://twitter.com/grahamkmann/status/1357343324873449480',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1361653609436508163/SwifQB0X_normal.jpg',
		name: 'Julia',
		handle: 'juulmh',
		unixDateMS: 1603901260000,
		platform: 'twitter',
		content:
			'You spend 1/3 of your life sleeping. Might as well make your bed as smart, comfortable, and cool as possible. <br/><br/>Upgrade your sleep, change your life, thank me later. <br/><br/>@eightsleep 🦾 ',
		verified: false,
		url: 'https://twitter.com/juulmh/status/1321483781069561856',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1600930787586514944/NBp1c2nT_normal.jpg',
		name: 'Celine Halioua',
		handle: 'celinehalioua',
		unixDateMS: 1603862346000,
		platform: 'twitter',
		content: "having a 10pm coffee for the third night in a row but it's ok I have an @eightsleep",
		verified: false,
		url: 'https://twitter.com/celinehalioua/status/1321320565584527363',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1633013403689132032/yfYVezSB_normal.jpg',
		name: 'Ana Paula',
		handle: 'anapaulahur',
		unixDateMS: 1603839911000,
		platform: 'twitter',
		content: '‘The best way to predict the future is to create it.’ <br/><br/>@eightsleep is creating the future of SLEEP 💤 — the greatest productivity, health and longevity hack. ',
		verified: false,
		url: 'https://twitter.com/anapaulahur/status/1321226466328268802',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1488954469425369090/K6gc_LdO_normal.jpg',
		name: 'Trae Stephens',
		handle: 'traestephens',
		unixDateMS: 1603825427000,
		platform: 'twitter',
		content: "If you've been interesting in an @eightsleep pod, but haven't wanted to replace your whole mattress, now is the time to get into the game.  Treat yo self! ",
		verified: true,
		url: 'https://twitter.com/traestephens/status/1321165714963591169',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/540367571988389888/I0PmoBNB_normal.jpeg',
		name: 'Michael Seibel',
		handle: 'mwseibel',
		unixDateMS: 1603821254000,
		platform: 'twitter',
		content: "Sleep is massive underrated - I'm buying this today: ",
		verified: true,
		url: 'https://twitter.com/mwseibel/status/1321148212191715328',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1144717059042861056/IrsMNQB7_normal.png',
		name: 'delian',
		handle: 'zebulgar',
		unixDateMS: 1603818736000,
		platform: 'twitter',
		content:
			"If you've been waiting to upgrade to the Pod because you don't want to swap out your mattress...<br/><br/>They just launched the Pod Pro Cover which can integrate with your pre-exisiting mattress and make it a smart thermoregulating bed<br/><br/>Now you've got no excuse to upgrade your sleep ",
		verified: true,
		url: 'https://twitter.com/zebulgar/status/1321137651034980352',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1528907467253596160/jfu8AffN_normal.jpg',
		name: 'Brad Siegler 🇺🇸 🚀',
		handle: 'BradSiegler',
		unixDateMS: 1603475469000,
		platform: 'twitter',
		content:
			'The only "user" I\'ve seen seen promote @eightsleep more than myself in my feed is @zebulgar and he is 100% correct. <br/><br/>Sleep is paramount in life. Eight Sleep makes my sleep an order of magnitude better. If you haven\'t yet, buy a damn @eightsleep already! ',
		verified: true,
		url: 'https://twitter.com/BradSiegler/status/1319697885777285123',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1144717059042861056/IrsMNQB7_normal.png',
		name: 'delian',
		handle: 'zebulgar',
		unixDateMS: 1603468593000,
		platform: 'twitter',
		content:
			"I'll say this one more time for everyone in the back<br/><br/>If you're an ambitious professional and know that great sleep is the biggest input into your productivity...<br/><br/>Then why are you sleeping on a box of foam??? Rather than a bed that can improve your sleep as much as below: ",
		verified: true,
		url: 'https://twitter.com/zebulgar/status/1319669044094513152',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/478728143209902080/qmf4rxWZ_normal.jpeg',
		name: 'Arturo SánchezCorrea',
		handle: 'ArturoSaCo',
		unixDateMS: 1603382468000,
		platform: 'twitter',
		content: 'That’s the effect of sleeping on an @eightsleep pod, pretty amazing tbh ',
		verified: false,
		url: 'https://twitter.com/ArturoSaCo/status/1319307811680063493',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/Ek8fddyW0AEmnkZ.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1031156227886084099/xPH5YsmC_normal.jpg',
		name: 'Tony Federico',
		handle: 'FitwithTfed',
		unixDateMS: 1603243820000,
		platform: 'twitter',
		content: 'Today was the day. Between my @Beachbody program and this @onepeloton Bike+ now sitting in my workout room my @eightsleep mattress will be the perfect end to a perfect day. ',
		verified: false,
		url: 'https://twitter.com/FitwithTfed/status/1318726281828159490',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/Ek0OkBLXYAU8BFd.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1539686784828940288/1OpSY-J3_normal.jpg',
		name: 'Phil Jacobson',
		handle: 'phil_jacobson',
		unixDateMS: 1602118263000,
		platform: 'twitter',
		content:
			"My sister asked what I want for my bday. <br/><br/>I said either an @eightsleep or a @tesla or 1 @bitcoin. <br/><br/>She told me to be realistic. <br/><br/>But I've always been a dreamer.",
		verified: false,
		url: 'https://twitter.com/phil_jacobson/status/1314005351566581760',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1014950270873284609/XaYEGVkn_normal.jpg',
		name: 'mordecaiholtz 🤳🔉💻',
		handle: 'mordecaiholtz',
		unixDateMS: 1601917080000,
		platform: 'twitter',
		content:
			'My Fav. brands w/ amazing social media marketing<br/><br/>1. @Nike<br/>2. @LEGO_Group<br/>3. @ChipotleTweets<br/>4. @AviationGin<br/>5. @Starbucks<br/>6. @Adobe<br/>7. @redbull<br/>8. @CocaCola<br/>9. @Airbnb<br/>10. @fast<br/>11.@dunkindonuts<br/>12. @eightsleep',
		verified: false,
		url: 'https://twitter.com/mordecaiholtz/status/1313161527789318145',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/917522147182104576/kPHORfT-_normal.jpg',
		name: 'Sam Cholera',
		handle: 'sam_cholera',
		unixDateMS: 1601823509000,
		platform: 'twitter',
		content: 'I’m having this conversation more and more with athletes and clubs.<br/><br/>@eightsleep is one of our go to recommendations (US) for essential recovery, and sleep happiness. ',
		verified: false,
		url: 'https://twitter.com/sam_cholera/status/1312769065732247553',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1547787118243561478/PxWzJo1H_normal.jpg',
		name: 'Pomp 🌪',
		handle: 'APompliano',
		unixDateMS: 1609204655000,
		platform: 'twitter',
		content:
			'Eight Sleep is the most impactful product I started using in 2020. <br/><br/>I sleep like a baby now and I’ll never go back to sleep deprecation again. <br/><br/>Can’t recommend enough.  ',
		verified: true,
		url: 'https://twitter.com/APompliano/status/1343727834443997186',
		categories: ['Everyday love'],
		video: 'https://video.twimg.com/ext_tw_video/1343645808432984065/pu/vid/480x270/V8mclSwAFE_XMN11.mp4?tag=10',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1562804983426207744/aUr_N2k8_normal.jpg',
		name: 'Nelly',
		handle: 'NelsonsThoughts',
		unixDateMS: 1609204695000,
		platform: 'twitter',
		content: '@APompliano Same here! Just got it recently and love it.  Sleep quality has dramatically improved!',
		verified: false,
		url: 'https://twitter.com/NelsonsThoughts/status/1343728001268080640',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1690048661638795264/qJ61-V8T_normal.jpg',
		name: 'Chase Roberts',
		handle: 'chsrbrts',
		unixDateMS: 1609202832000,
		platform: 'twitter',
		content: '@davidu @eightsleep 💯% agree. <br/><br/>Since I can’t take it with me, it’s ruined travel for me. Well, that and the pandemic.',
		verified: false,
		url: 'https://twitter.com/chsrbrts/status/1343720186730475523',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1031156227886084099/xPH5YsmC_normal.jpg',
		name: 'Tony Federico',
		handle: 'FitwithTfed',
		unixDateMS: 1609188767000,
		platform: 'twitter',
		content: '@m_franceschetti Thank you and @a_zatarain and the whole @eightsleep team from the bottom of my heart for helping me to reclaim my nights again. Here’s to an amazing 2021!',
		verified: false,
		url: 'https://twitter.com/FitwithTfed/status/1343661196759728128',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1618144815623057408/jnkjIO6L_normal.jpg',
		name: 'Kanu Gulati',
		handle: 'KanuGulati',
		unixDateMS: 1609132948000,
		platform: 'twitter',
		content:
			'@Lexiro @eightsleep 🙋🏽‍♀️The monthly changes in my basal body temperature alone makes it challenging to agree to a constant temperature in the bedroom! <br/>Being able to adjust the temperature of my side of @eightsleep independently is incredibly helpful for our marital harmony and better sleep.',
		verified: false,
		url: 'https://twitter.com/KanuGulati/status/1343427074246131713',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1643090628895715328/uBkRC8tC_normal.jpg',
		name: 'Sara',
		handle: 'ifeellikemacmac',
		unixDateMS: 1609114473000,
		platform: 'twitter',
		content: '@Lexiro @eightsleep I love setting my AC to ~72 and turning up the heat on the mattress to +4 for the perfect warm and comfy bed',
		verified: false,
		url: 'https://twitter.com/ifeellikemacmac/status/1343349583112335360',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1031156227886084099/xPH5YsmC_normal.jpg',
		name: 'Tony Federico',
		handle: 'FitwithTfed',
		unixDateMS: 1609110318000,
		platform: 'twitter',
		content:
			"@zlwaterfield @eightsleep I like that it tracks my sleep patterns and gives me a score the next day.  If I have a low score or bad night I can start looking for patterns in my own life to see if it's diet, stress, etc. and try to adjust. The heating and cooling has done wonders and this can all be 1/2",
		verified: false,
		url: 'https://twitter.com/FitwithTfed/status/1343332154697252865',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1031156227886084099/xPH5YsmC_normal.jpg',
		name: 'Tony Federico',
		handle: 'FitwithTfed',
		unixDateMS: 1609110491000,
		platform: 'twitter',
		content:
			"@zlwaterfield @eightsleep 2/2 controlled through an app on my phone.  I live in Texas and summers are brutal.  Being able to cool the bed and sleep has given me back 3-4 months of sleep that I was missing out on.  Much better than AC running all night ($$$). Try it! You can return it if you don't like it.",
		verified: false,
		url: 'https://twitter.com/FitwithTfed/status/1343332881700163586',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1366172005385596930/f__vrnow_normal.jpg',
		name: 'Max Wendkos',
		handle: 'maxwendkos',
		unixDateMS: 1609101619000,
		platform: 'twitter',
		content:
			'I sleep really hot and have bought a bunch of self-proclaimed "cooling products" products over the years to try make myself more comfortable. None of them have made a difference.<br/><br/>I slept on @eightsleep\'s Pod Pro Cover for the first time last night and... it actually works! ',
		verified: false,
		url: 'https://twitter.com/maxwendkos/status/1343295667532394506',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/978132096907599875/weBDVZH-_normal.jpg',
		name: 'Jason McMinn',
		handle: 'jasonmcminn',
		unixDateMS: 1609016000000,
		platform: 'twitter',
		content: '@awilkinson Bed temp control is crucial for me. @eightsleep is by far the best way to do this. Radically changed my sleep quality as a hot sleeper.',
		verified: false,
		url: 'https://twitter.com/jasonmcminn/status/1342936559348289536',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/978132096907599875/weBDVZH-_normal.jpg',
		name: 'Jason McMinn',
		handle: 'jasonmcminn',
		unixDateMS: 1609016158000,
		platform: 'twitter',
		content:
			'@roblarson @awilkinson @eightsleep 🙋@eightsleep user for 9 months. Hands down the best way to control bed temp which is crucial for me. Bought one for my brother and sister for Christmas - both are “hot” sleepers like me 😬',
		verified: false,
		url: 'https://twitter.com/jasonmcminn/status/1342937219791728640',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/829543250994266112/J7gFzLy5_normal.jpg',
		name: 'Candice Vega',
		handle: 'candiceskitchen',
		unixDateMS: 1608994660000,
		platform: 'twitter',
		content:
			'When my husband bought @eightsleep I didn’t truly understand the value. After having it for several weeks now, I can’t imagine not sleeping on it. Some days I would wake up exhausted after sleeping 8hrs and not know why- now because of the data I understand.',
		verified: false,
		url: 'https://twitter.com/candiceskitchen/status/1342847049826496519',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1038827045684477953/pE0HRlx-_normal.jpg',
		name: 'Brett Wright',
		handle: 'wrightmbrett',
		unixDateMS: 1608989928000,
		platform: 'twitter',
		content: '@grahamcodes @zebulgar @rsg @davidu @eightsleep @Purple I have the purple with the pod pro cover. Great combo, highly recommend.',
		verified: false,
		url: 'https://twitter.com/wrightmbrett/status/1342827204565200901',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1115760912080592897/SLzOMIKH_normal.jpg',
		name: 'Fitz',
		handle: 'fitzrocks',
		unixDateMS: 1608969144000,
		platform: 'twitter',
		content: '@davidu @eightsleep +1. Had it for like 3 weeks so far. It will really come in handy for those hot SF summer nights when everyone is dying because no AC',
		verified: false,
		url: 'https://twitter.com/fitzrocks/status/1342740029550911488',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1541488975864537088/fuqAL7e3_normal.jpg',
		name: 'Erik Pavia',
		handle: 'erikpavia',
		unixDateMS: 1608840998000,
		platform: 'twitter',
		content:
			"First night with @eightsleep and I'm reminded of how ambitious entrants can beat cozy incumbents. I previously had a Chili Pad and the performance on the cooling element alone is worlds apart. Just because someone is already doing it doesnt mean you can't do it better.",
		verified: true,
		url: 'https://twitter.com/erikpavia/status/1342202547117953025',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/582389274651332608/3311050v_normal.jpg',
		name: 'Charlotte Flannery',
		handle: 'cflannery22',
		unixDateMS: 1608757855000,
		platform: 'twitter',
		content: '@Michaeludine @eightsleep Best bed ever!!! Improves your sleep tremendously.',
		verified: false,
		url: 'https://twitter.com/cflannery22/status/1341853819647516678',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1600898941540671488/vZ-cvZ4W_normal.jpg',
		name: 'Jake Chapman 🇺🇸🚀 ✨🇺🇸',
		handle: 'vc',
		unixDateMS: 1608602681000,
		platform: 'twitter',
		content:
			'Just started using @eightsleep a 2 weeks ago. <br/><br/>I *think* my sleep is improving but I *know*  it has lowered my resting heart rate from the high forties into the mid to low forties. <br/><br/>That’s gotta be good for a 1 or 2 % boost to life expectancy right? <br/><br/>Singularity here I come.',
		verified: false,
		url: 'https://twitter.com/vc/status/1341202972147994625',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1157006069035520000/dDMKej74_normal.jpg',
		name: 'Matt Mazzeo',
		handle: 'Mazzeo',
		unixDateMS: 1608410048000,
		platform: 'twitter',
		content:
			'@nicksoman 1. No phone in bedroom<br/>2. Sleep cold: @eightsleep has been game changer. <br/>3. I found I had shifted to biphasic sleep during quarantine. Up at 4 am. Rather than fight it, I get up, walk around, write something/do something creative for 45 min. Then get back in bed and try to sleep',
		verified: true,
		url: 'https://twitter.com/Mazzeo/status/1340395008440995840',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/658653336728637441/OtrOzbuH_normal.jpg',
		name: 'Rob Nance',
		handle: 'RJNance',
		unixDateMS: 1608365844000,
		platform: 'twitter',
		content: '@ClayCollins If you haven’t bought one already, @eightsleep is the only way to go.',
		verified: true,
		url: 'https://twitter.com/RJNance/status/1340209604039467008',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1683537760135819265/cTCfSJW7_normal.png',
		name: 'Kevin Rose 🦉',
		handle: 'kevinrose',
		unixDateMS: 1612234505000,
		platform: 'twitter',
		content: "@joetimmons79 @eightsleep Yes, via Oura and their app, it's helping for sure.",
		verified: true,
		url: 'https://twitter.com/kevinrose/status/1356435947001323520',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/992106460346662913/gj0Fpprg_normal.jpg',
		name: 'Manish Chablani',
		handle: 'ManishChablani',
		unixDateMS: 1601559146000,
		platform: 'twitter',
		content: 'Spent a day in the mountains with family yesterday and my HRV is well recovered @eightsleep ',
		verified: false,
		url: 'https://twitter.com/ManishChablani/status/1311660243596701698',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EjP0CTTUYAIjmi0.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1544702977163907072/rdofE-yo_normal.jpg',
		name: 'Nat Eliason',
		handle: 'nateliason',
		unixDateMS: 1600953311000,
		platform: 'twitter',
		content:
			"The @eightsleep Mattress 🛌<br/><br/>$3,000<br/><br/>I've tried most of the \"cold bed\" solutions and this is the only bed I've slept in where I don't regularly wakeup in a pool of sweat (yuck)<br/><br/>It gets COLD, is super comfy, and I miss it dearly when I'm away from home. <br/><br/>",
		verified: true,
		url: 'https://twitter.com/nateliason/status/1309119186887733255',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1627740030641836060/brT91lRN_normal.jpg',
		name: 'Joe Vennare',
		handle: 'JoeVennare',
		unixDateMS: 1601513992000,
		platform: 'twitter',
		content:
			'𝗧𝗵𝗲 𝗛𝗶𝗴𝗵-𝗣𝗲𝗿𝗳𝗼𝗿𝗺𝗮𝗻𝗰𝗲 𝗟𝗶𝗳𝗲𝘀𝘁𝘆𝗹𝗲 <br/> <br/>The pursuit of optimal performance is forming a new wellness vertical.<br/> <br/>From @whoop to @ouraring @Levels @eightsleep &amp; @XPTLife, unlocking human potential is the ultimate endgame.<br/> <br/>The pillars of high-performance <br/><br/>↓',
		verified: true,
		url: 'https://twitter.com/JoeVennare/status/1311470854350548992',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1354964702921699331/8_yhQiBe_normal.jpg',
		name: 'brekolazh🔮',
		handle: '0xbrekolazh',
		unixDateMS: 1601481083000,
		platform: 'twitter',
		content: 'only a matter of time before companies like @eightsleep and @ouraring + now apple can detect and potentially catch neurodegeneration before it’s too late 💤🧠 ',
		verified: false,
		url: 'https://twitter.com/0xbrekolazh/status/1311332823253614592',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1511768269883064327/HxmEjBLB_normal.jpg',
		name: 'Nick',
		handle: 'nickcammarata',
		unixDateMS: 1601417496000,
		platform: 'twitter',
		content:
			"My nightly HRV basically 100% predicts my easily quantifiable juggling ability the next day, and I suspect it sets the quality of everything else I do that isn't measurable too<br/><br/>I get so unreasonably excited when I bump into evidence that the universe isn't random ",
		verified: true,
		url: 'https://twitter.com/nickcammarata/status/1311066121382932480',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EjHXr8NVkAESI4R.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1552979440547704832/WX5crG9I_normal.jpg',
		name: 'Mckay Wrigley',
		handle: 'mckaywrigley',
		unixDateMS: 1600888037000,
		platform: 'twitter',
		content: '@tferriss Would recommend the @eightsleep pod as a 10/10. Have convinced 6 friends to buy and they all would say the exact same.',
		verified: true,
		url: 'https://twitter.com/mckaywrigley/status/1308845408479784960',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1588417451569475584/V4IQxQU7_normal.jpg',
		name: 'Sean Rose',
		handle: 'seanrose',
		unixDateMS: 1598383783000,
		platform: 'twitter',
		content:
			'@m_franceschetti @JSchwarz9 @eightsleep @zebulgar @Austen @matthewdelly @markacyr The whole thing is great but the thermoregulation in particular is life changing. You’ll never be able to go back.',
		verified: true,
		url: 'https://twitter.com/seanrose/status/1298341807927910401',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1144717059042861056/IrsMNQB7_normal.png',
		name: 'delian',
		handle: 'zebulgar',
		unixDateMS: 1598383866000,
		platform: 'twitter',
		content:
			"@seanrose @m_franceschetti @JSchwarz9 @eightsleep @Austen @matthewdelly @markacyr It's so disturbing to go onto a bed without thermoregulation now<br/><br/>it's like... am i back in the stone age??",
		verified: true,
		url: 'https://twitter.com/zebulgar/status/1298342155950280704',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1031156227886084099/xPH5YsmC_normal.jpg',
		name: 'Tony Federico',
		handle: 'FitwithTfed',
		unixDateMS: 1598393142000,
		platform: 'twitter',
		content: "@JSchwarz9 @eightsleep Best investment I've made.  First summer in forever I've been able to sleep because my bed is cool enough.",
		verified: false,
		url: 'https://twitter.com/FitwithTfed/status/1298381060489842690',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/950134514113691648/7dt7ECh2_normal.jpg',
		name: 'Dan Friedman',
		handle: '_DanFriedman',
		unixDateMS: 1598551707000,
		platform: 'twitter',
		content: "@JSchwarz9 @eightsleep It's an amazing product! I can't imagine trying to sleep without it. I'm traveling to my moms place next weekend and taking my Pod with me for a few days",
		verified: false,
		url: 'https://twitter.com/_DanFriedman/status/1299046132069412864',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1682471054231437312/oHvlchM7_normal.jpg',
		name: 'Matt Redler 🎙️',
		handle: 'MattRedler',
		unixDateMS: 1598384960000,
		platform: 'twitter',
		content: "@JSchwarz9 @eightsleep it's great. temperature control &gt;&gt;&gt;&gt;&gt;",
		verified: true,
		url: 'https://twitter.com/MattRedler/status/1298346743579529218',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1662301833149448192/ukhJDVvK_normal.jpg',
		name: 'RetwitBrad',
		handle: 'iambradcahoon',
		unixDateMS: 1603806629000,
		platform: 'twitter',
		content:
			'@EthanDParker @KovachR @eightsleep I’ve had it for several months now. For my purposes it works amazing. My wife is a little indifferent to the temperature thing since she like a really cozy bed and memory foam works well for her but the cooling on it for me is a game changer. Perfect temperature every night.',
		verified: true,
		url: 'https://twitter.com/iambradcahoon/status/1321086873293352960',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1544702977163907072/rdofE-yo_normal.jpg',
		name: 'Nat Eliason',
		handle: 'nateliason',
		unixDateMS: 1602103147000,
		platform: 'twitter',
		content: '@dennishegstad @eightsleep 🔥🔥🔥 total game changer, honestly makes me wanna travel less',
		verified: true,
		url: 'https://twitter.com/nateliason/status/1313941950828027905',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1363316601974644736/JKdIckKh_normal.jpg',
		name: 'Matthew Dellavedova',
		handle: 'matthewdelly',
		unixDateMS: 1600881043000,
		platform: 'twitter',
		content: '@tferriss @eightsleep is next level! Absolute game changer',
		verified: true,
		url: 'https://twitter.com/matthewdelly/status/1308816075203641344',
		categories: ['Familiar faces'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1588417451569475584/V4IQxQU7_normal.jpg',
		name: 'Sean Rose',
		handle: 'seanrose',
		unixDateMS: 1600875742000,
		platform: 'twitter',
		content:
			"@Austen @tferriss ➕➕ to this as someone with &gt;decade of struggling to deal as a hot sleeper. Dynamic temp of eight sleep has been life changing here too. Works incredibly well, no maintenance, nothing to worry about, really kind of invisible. Couldn't ask for more.",
		verified: true,
		url: 'https://twitter.com/seanrose/status/1308793839553974273',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1608281295918096385/D2kh-M28_normal.jpg',
		name: 'Austen Allred',
		handle: 'Austen',
		unixDateMS: 1600875119000,
		platform: 'twitter',
		content:
			'@tferriss You HAVE to try an eight sleep pod. Much smarter, adjusts during the night based on sleep phase which is IMO the most important thing.<br/><br/>I tried them all and the eight sleep literally changed my life.',
		verified: true,
		url: 'https://twitter.com/Austen/status/1308791226427006978',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1662301833149448192/ukhJDVvK_normal.jpg',
		name: 'RetwitBrad',
		handle: 'iambradcahoon',
		unixDateMS: 1600820739000,
		platform: 'twitter',
		content:
			'@TrentMano @eightsleep has been a revelation for me. The temperature control works perfect and my sleep is so much better for it. Also, highly suggest getting setting up with @unlocklevels. My sleep quality is directly tied to my diet especially that last meal of the day.',
		verified: true,
		url: 'https://twitter.com/iambradcahoon/status/1308563141261799424',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1144717059042861056/IrsMNQB7_normal.png',
		name: 'delian',
		handle: 'zebulgar',
		unixDateMS: 1605281572000,
		platform: 'twitter',
		content:
			'My favorite quote from @traestephens during the @eightsleep investment discussion was...<br/><br/>"High NPS products almost always turn into great businesses"<br/><br/>Well it\'s finally turning into a great business not just a great product :)<br/><br/>And even @Pavel_Asparagus finally gets the hype!!! ',
		verified: true,
		url: 'https://twitter.com/zebulgar/status/1327273231158415361',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/Emtr-CwVQAExhMk.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1683667154435211264/JYiqb2DZ_normal.jpg',
		name: 'Tommy Collison',
		handle: 'tommycollison',
		unixDateMS: 1605282415000,
		platform: 'twitter',
		content:
			'@zebulgar @traestephens @eightsleep @Pavel_Asparagus Mine arrived a month ago and I underestimated the extra happiness associated with “what if you got predictably good sleep-through-the-night sleep every night.” 😍',
		verified: true,
		url: 'https://twitter.com/tommycollison/status/1327276766663098368',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1633604159927730178/uZLfZGdR_normal.jpg',
		name: 'Matt Martin',
		handle: 'MattBMartin',
		unixDateMS: 1605377878000,
		platform: 'twitter',
		content: '@theSamParr Really happy with my @eightsleep for the last 3 years. Proper firmness and give.',
		verified: false,
		url: 'https://twitter.com/MattBMartin/status/1327677165874388994',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1446607456029888517/yUhqzHiD_normal.jpg',
		name: 'Simone Panfilo',
		handle: 'simonepanfilo',
		unixDateMS: 1605377239000,
		platform: 'twitter',
		content: '@theSamParr @eightsleep is a life changer',
		verified: false,
		url: 'https://twitter.com/simonepanfilo/status/1327674487303757830',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1646569278366441472/EiuSuLm9_normal.jpg',
		name: 'Zach Ware',
		handle: 'zachware',
		unixDateMS: 1605380301000,
		platform: 'twitter',
		content: '@m_franceschetti @theSamParr @eightsleep @Saatva 100% of my biohackers WhatsApp group recommend @eightsleep FWIW.',
		verified: true,
		url: 'https://twitter.com/zachware/status/1327687330333855745',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1629526164665450500/MuIuDqqp_normal.jpg',
		name: 'Jesus Mu 2',
		handle: 'ManRam2',
		unixDateMS: 1606064691000,
		platform: 'twitter',
		content: 'First night with @eightsleep pod pro cover!  Wow me and the wife both noticed a difference night 1. ',
		verified: false,
		url: 'https://twitter.com/ManRam2/status/1330557868244946944',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EncXVE9XYAAZdOv.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1213509019794640899/uEz-S5Dw_normal.jpg',
		name: 'Will Brackett',
		handle: 'willibeemin',
		unixDateMS: 1605886750000,
		platform: 'twitter',
		content: 'My sleep is literally 1000x better with @eightsleep 🙏🏼<br/><br/>Honestly f***ing remarkable.<br/><br/>Thanks @RMadelmayer and Michael as always for shedding light on these guys.',
		verified: true,
		url: 'https://twitter.com/willibeemin/status/1329811532255883264',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1547787118243561478/PxWzJo1H_normal.jpg',
		name: 'Pomp 🌪',
		handle: 'APompliano',
		unixDateMS: 1605838319000,
		platform: 'twitter',
		content:
			"6/ The third most important thing I did to increase sleep quality was get a thermoregulating bed. <br/><br/>I've been sleeping on a @eightsleep Pod Pro and Pod Pro Cover.  <br/><br/>The secret — I make it cold AF and magically get DEEP sleep.",
		verified: true,
		url: 'https://twitter.com/APompliano/status/1329608398770483200',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1362614048416796675/CikOTl1o_normal.jpg',
		name: 'Lolli 🍭',
		handle: 'trylolli',
		unixDateMS: 1605804435000,
		platform: 'twitter',
		content: 'Of all the mattresses our users have stacked sats on, we get the most positive reviews on @eightsleep. <br/><br/>People are obsessed.',
		verified: false,
		url: 'https://twitter.com/trylolli/status/1329466275790249987',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/896784604757192704/Z-zzHo_O_normal.jpg',
		name: 'Greg Priester',
		handle: '_GreggyP',
		unixDateMS: 1567652667000,
		platform: 'twitter',
		content:
			"Three months into sleeping on my @eightsleep Pod mattress,  and I've got to say this bed is amazing! I could never have imagined that a self regulated  temperature changing mattress would be such a game changer in terms of the night sleep. I can't wait to see what comes next!",
		verified: false,
		url: 'https://twitter.com/_GreggyP/status/1169446162379812864',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1488954469425369090/K6gc_LdO_normal.jpg',
		name: 'Trae Stephens',
		handle: 'traestephens',
		unixDateMS: 1565325838000,
		platform: 'twitter',
		content: "I'll admit, I was skeptical, but @eightsleep is actually a game-changer.  If you sleep hot, you really need to get one of these bad boys.  @m_franceschetti",
		verified: true,
		url: 'https://twitter.com/traestephens/status/1159686736840024065',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/2598602485/hl9eja8jl3zc2anxsode_normal.png',
		name: 'Peter Pham',
		handle: 'peterpham',
		unixDateMS: 1564704467000,
		platform: 'twitter',
		content:
			"1 week with @eightsleep, it's a game changer. CoincidentallyA/C went out &amp; I prefer sleeping with a cold room. Super quiet. Can get ice cold at - 10 setting, Im at - 4. Temp adjusts thru the night including picking a temp to wake up to! ",
		verified: true,
		url: 'https://twitter.com/peterpham/status/1157080516106915840',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EA7Gkn5UcAAhSQw.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/785918015179194368/xvXWgfMb_normal.jpg',
		name: 'Steven Chiocchi',
		handle: 'StevenChiocchi',
		unixDateMS: 1559753303000,
		platform: 'twitter',
		content:
			'At first, skeptical about @eightsleep, but they nailed it. Struggled with “cooling sheets” and blankets for years until I tried #thepod. Keeping the bed surface cool is the key to great sleep. Hats off! I don’t even need my air conditioner on anymore. $ saved!',
		verified: false,
		url: 'https://twitter.com/StevenChiocchi/status/1136313830735265792',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/905929486415290368/zCq1n6hc_normal.jpg',
		name: 'Jonathan Liem',
		handle: 'elanvitaljon',
		unixDateMS: 1561493346000,
		platform: 'twitter',
		content: 'Sleeping cold = sleeping better! @eightsleep you have an amazing product. ',
		verified: false,
		url: 'https://twitter.com/elanvitaljon/status/1143612098036916224',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/D97tI67UcAAw7e_.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1373476268553900032/oe6Ed65u_normal.jpg',
		name: 'David Tostado',
		handle: 'Wildeir',
		unixDateMS: 1562946079000,
		platform: 'twitter',
		content: "@eightsleep first night on The Pod and I would seriously love to know how it can see my heart's BPM. Btw, best mattress ever! 😂",
		verified: false,
		url: 'https://twitter.com/Wildeir/status/1149705304419926016',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1567592923293794304/89IH2_M-_normal.jpg',
		name: 'jill gunter',
		handle: 'jillrgunter',
		unixDateMS: 1560225766000,
		platform: 'twitter',
		content: 'Calling it now: @eightsleep is the new @Allbirds ',
		verified: true,
		url: 'https://twitter.com/jillrgunter/status/1138295483338596352',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1242269303702982656/lwqJ3t3S_normal.jpg',
		name: 'earth is cancelled',
		handle: 'mstrpotatohead',
		unixDateMS: 1567280744000,
		platform: 'twitter',
		content: 'This bed that cools itself internally is the best thing we have ever bought',
		verified: false,
		url: 'https://twitter.com/mstrpotatohead/status/1167886204286537728',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1144096848375713792/61N9qWBN_normal.jpg',
		name: 'Dave Shull',
		handle: 'daveshull',
		unixDateMS: 1561171912000,
		platform: 'twitter',
		content:
			'@eightsleep mattresses are awesome. Great product and love the personal touch @m_franceschetti!  With all of my travels as I visit our amazing team around the world at @tivo its great to come home for a good nights sleep.',
		verified: false,
		url: 'https://twitter.com/daveshull/status/1142263907467198464',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1529486760069238789/mhfPlESH_normal.jpg',
		name: '@nickdio',
		handle: 'NickDiodato',
		unixDateMS: 1564425842000,
		platform: 'twitter',
		content:
			'@willahmed @IRLJAYZ @eightsleep Have been on the @eightsleep for just over a month now. Not a professional athlete. But a professional, who runs, spins, puts in daily strain. This mattress has been a game changer for me and a topic of conversation. Outside of temperature control for you and your partner... ',
		verified: false,
		url: 'https://twitter.com/NickDiodato/status/1155911880579399681',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EAqfumAX4AEzdmQ.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1373476268553900032/oe6Ed65u_normal.jpg',
		name: 'David Tostado',
		handle: 'Wildeir',
		unixDateMS: 1606369892000,
		platform: 'twitter',
		content:
			"@eightsleep I don't know what to say. Sleeping on the Pod for a year now &amp; I didn't think my bed could get any better... But then I got the Pod Pro... 🤦‍♂️ I'm positively speechless 😂",
		verified: false,
		url: 'https://twitter.com/Wildeir/status/1331837976339390465',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1662301833149448192/ukhJDVvK_normal.jpg',
		name: 'RetwitBrad',
		handle: 'iambradcahoon',
		unixDateMS: 1606613364000,
		platform: 'twitter',
		content:
			'@WinginItWife @eightsleep Have had pod pro for several months now. Was a little too stiff for us so we added a thin foam pad on top. Less accurate readings I’m sure but the temp control is actually the very best sleep experience ever. Huge thumbs up even if you have to slightly modify. No fires here. Lol',
		verified: true,
		url: 'https://twitter.com/iambradcahoon/status/1332859173084663809',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1031156227886084099/xPH5YsmC_normal.jpg',
		name: 'Tony Federico',
		handle: 'FitwithTfed',
		unixDateMS: 1606572136000,
		platform: 'twitter',
		content:
			"@WinginItWife @eightsleep I've used it for over a year and won't go back.  Summers were brutal for me and I'm now able to sleep comfortably.  Winters, just the opposite.  Nothing like getting into a warm bed on a cold night.  Not only those things but with the sleep tracking and waking features it rocks!",
		verified: false,
		url: 'https://twitter.com/FitwithTfed/status/1332686248545226754',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1587931405278973953/BojuvUe5_normal.jpg',
		name: 'Matt Galligan',
		handle: 'mg',
		unixDateMS: 1606398234000,
		platform: 'twitter',
		content:
			'@m_franceschetti @eightsleep Two nights ago I accidentally my new Pod Pro cover. Within an hour I was super hot and couldn’t figure out why I was having such a rough go…turns out the Pod Pro cover never turned on due to my own stupidity.<br/><br/>As a new user, I’m addicted! Makes such a difference.',
		verified: true,
		url: 'https://twitter.com/mg/status/1331956849810300929',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1179935091621027840/i35Pl9Aw_normal.jpg',
		name: 'Erika✨',
		handle: 'erikapeter',
		unixDateMS: 1553198849000,
		platform: 'twitter',
		content:
			'I think the best part of the @eightsleep Pod is that it changes the game on alarms. No more awful noises that you can turn off — now your actual bed just gradually heats up until you’re forced to get up or melt your skin off and boil all your organs. I’m so here for it',
		verified: false,
		url: 'https://twitter.com/erikapeter/status/1108822455878119424',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1594866686104162304/WIyLjAdM_normal.jpg',
		name: 'sari azout',
		handle: 'sariazout',
		unixDateMS: 1562814560000,
		platform: 'twitter',
		content: '@peterpham @eightsleep Yes. The best quality of life improvement.',
		verified: true,
		url: 'https://twitter.com/sariazout/status/1149153674377273349',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/830517502010613762/ZMmbHr07_normal.jpg',
		name: 'Dipul Patel',
		handle: 'DipulP',
		unixDateMS: 1565730068000,
		platform: 'twitter',
		content:
			'Today I hooked a computer and radiator up to my bed. @eightsleep uses the peltier effect to allow me to set the temp of each side. Also, sleep sensors to help optimize. 30 day trial here I come! ',
		verified: false,
		url: 'https://twitter.com/DipulP/status/1161382201038180355',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EB4O9DfWkAAOfKk.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1588417451569475584/V4IQxQU7_normal.jpg',
		name: 'Sean Rose',
		handle: 'seanrose',
		unixDateMS: 1560475720000,
		platform: 'twitter',
		content:
			'I sleep hot and wouldn’t have survived this past week’s heat wave without the @eightsleep mattress I luckily just got. It’s the sort of thing that feels extremely obvious and essential after you’ve used it.',
		verified: true,
		url: 'https://twitter.com/seanrose/status/1139343868250341379',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/413037869448450048/WIKP9pY2_normal.jpeg',
		name: 'Sco Holm (Taylor’s Version)',
		handle: 'omnipotentsco',
		unixDateMS: 1560555265000,
		platform: 'twitter',
		content: '@elizhargrave I’m a fan of my @eightsleep mattress. It has sleep tracking, and the newer ones have heating and cooling!',
		verified: false,
		url: 'https://twitter.com/omnipotentsco/status/1139677502635479041',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1134629657624682496/XFNK2B8S_normal.jpg',
		name: 'Khaled salem',
		handle: 'KhaledSalemMKE',
		unixDateMS: 1560269125000,
		platform: 'twitter',
		content:
			"Eight Sleep is one of the best purchases I've made for a bed. They really break down cost to benefit ratio compared to comparable beds. Their a clear winner, changing the fitness/sleep game. @eightsleep",
		verified: false,
		url: 'https://twitter.com/KhaledSalemMKE/status/1138477343566159873',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/877178432702033920/MViFHONq_normal.jpg',
		name: 'Shonna Walker',
		handle: 'shonnasvintage',
		unixDateMS: 1559251167000,
		platform: 'twitter',
		content: 'I started the 30-Day Sleep Fitness Challenge with @eightsleep Aiming for perfect 100. Stay tuned.',
		verified: false,
		url: 'https://twitter.com/shonnasvintage/status/1134207718816792576',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1483572454979031040/HZgTqHjX_normal.jpg',
		name: '@jason',
		handle: 'Jason',
		unixDateMS: 1562823466000,
		platform: 'twitter',
		content:
			'The @eightsleep bed is AMAZING!!!! I’m in it right now with my bulldog; she is on -10 (freezing cold) and I’m on a crisp -5 cold<br/><br/>When my wife gets in bed she will set her side on +5 to be toasty<br/><br/>&amp; the sleep data is amazing... it’s the @tesla of beds <br/> ',
		verified: true,
		url: 'https://twitter.com/Jason/status/1149191026495483905',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1648690293762293761/suyNZ6bv_normal.jpg',
		name: 'Tom Schmidt ＞|＜',
		handle: 'tomhschmidt',
		unixDateMS: 1560188294000,
		platform: 'twitter',
		content: "Just two 80F+ days in SF and I've already sold two people  on buying @eightsleemattresses 😎",
		verified: true,
		url: 'https://twitter.com/tomhschmidt/status/1138138315540971520',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1469417059083771904/7KoZfm0i_normal.jpg',
		name: 'Marc Atiyeh',
		handle: 'MarcAtiyeh',
		unixDateMS: 1562807243000,
		platform: 'twitter',
		content: '@peterpham @eightsleep Highly recommend it!! Been sleeping much better since I got it.',
		verified: true,
		url: 'https://twitter.com/MarcAtiyeh/status/1149122984155799553',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1520585524481716225/PYR2mRcA_normal.png',
		name: 'Jonathan Tame',
		handle: 'JonathanTame',
		unixDateMS: 1545092696000,
		platform: 'twitter',
		content:
			'Much love to the support team at @eightsleep! If you’re not familiar with their sleep-tracking, bed-warming tech ... check it out! The product is incredible and the customer support is unmatched. #SleepingBeauty',
		verified: false,
		url: 'https://twitter.com/JonathanTame/status/1074822786458234880',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1592194144608346112/NrTJ81lQ_normal.jpg',
		name: '𝖱𝖮𝖡 𝖬𝖠𝖣𝖤𝖫𝖬𝖠𝖸𝖤𝖱 🇺🇸',
		handle: 'RMadelmayer',
		unixDateMS: 1560223283000,
		platform: 'twitter',
		content: '@AdamSinger My @eightsleep mattress came in very handy last night. I had that bad boy dialed way down in the uper 50’s. <br/><br/>Slept like a baby.',
		verified: true,
		url: 'https://twitter.com/RMadelmayer/status/1138285068856651776',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1514117096719978498/ZPrlr5f-_normal.jpg',
		name: 'Dale Moss',
		handle: 'DaleMoss',
		unixDateMS: 1548446602000,
		platform: 'twitter',
		content:
			'I have a completely new perspective of fitness after my experience with @eightsleep and how they’re revolutionizing the way we sleep &amp; recover!  <br/>Excited to share more on this soon! 😴… ',
		verified: false,
		url: 'https://twitter.com/DaleMoss/status/1088890088937918464',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1649453091362869249/CBpPxWZY_normal.jpg',
		name: 'Micah Baldwin',
		handle: 'micah',
		unixDateMS: 1553113858000,
		platform: 'twitter',
		content: "@gilbert What are you using to track sleep heart rate? Watch/app/device? I've been using @eightsleep and enjoy it",
		verified: true,
		url: 'https://twitter.com/micah/status/1108465979913175041',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1648690293762293761/suyNZ6bv_normal.jpg',
		name: 'Tom Schmidt ＞|＜',
		handle: 'tomhschmidt',
		unixDateMS: 1560195818000,
		platform: 'twitter',
		content:
			"@Jon_A_Haas @eightsleep @impcapital Depends on what you're measuring :) -- anecdotally, once I got used to e change in bed firmness and adjusted temp settings, I've slept amazingly. Woken up refreshed before my alarm nearly every morning, which never happened with my Casper.",
		verified: true,
		url: 'https://twitter.com/tomhschmidt/status/1138169872158842881',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1599635838312140800/9cGb83Vc_normal.jpg',
		name: 'Erik Till',
		handle: 'eriktill',
		unixDateMS: 1557953602000,
		platform: 'twitter',
		content: 'Great product AND support from @eightsleep! Making/keeping customers happy isn’t always easy but it is usually simple. Thanks again #eightsleep',
		verified: false,
		url: 'https://twitter.com/eriktill/status/1128765337552375808',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1268221924938743809/BnoLQ-tF_normal.jpg',
		name: 'Kiren Srinivasan',
		handle: 'srinitude',
		unixDateMS: 1554085024000,
		platform: 'twitter',
		content:
			"@rabois @blakeir Optimizing for 8-9 hours of sleep every night and being present in the moment has done wonders for me. Effortless to sustain 11-12 consecutive hours of work. <br/><br/>That being said, I'm very excited for my @eightsleep Pod 😉",
		verified: true,
		url: 'https://twitter.com/srinitude/status/1112539343694884869',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/896784604757192704/Z-zzHo_O_normal.jpg',
		name: 'Greg Priester',
		handle: '_GreggyP',
		unixDateMS: 1560256820000,
		platform: 'twitter',
		content:
			'I love the sleep fitness score in my @eightsleep app! I had my best night sleep so far! This app tracks things like heart rate, respiratory rate, toss and turns, sleep cycles, etc. The only thing keeping me from a perfect score was how long it took to fall asleep #strivefor100 ',
		verified: false,
		url: 'https://twitter.com/_GreggyP/status/1138425733783494656',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/D8yAKqEVsAAHp4F.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/378800000540267683/31ab9f825cd79e61791b84ffd428be56_normal.jpeg',
		name: 'Monika Aufdermauer',
		handle: 'chargergirl07',
		unixDateMS: 1560369461000,
		platform: 'twitter',
		content: 'Been getting a good nights sleep with this Bay Area heat thanks to my @eightsleep mattress!  Love the cool setting!',
		verified: false,
		url: 'https://twitter.com/chargergirl07/status/1138898183130476544',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1626009023785885698/g5HG64ge_normal.jpg',
		name: 'Glenn Linhoff',
		handle: 'LexiMaxL',
		unixDateMS: 1561078769000,
		platform: 'twitter',
		content: '@m_franceschetti  My son got his bed today. You have a fan for life! Thanks Matteo and @eightsleep',
		verified: false,
		url: 'https://twitter.com/LexiMaxL/status/1141873238630445056',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1524849005666131968/d-S6tHPT_normal.png',
		name: 'Nik Sharma',
		handle: 'mrsharma',
		unixDateMS: 1550696387000,
		platform: 'twitter',
		content: '@JeremyShure @brooklinen @Casper @InsightTimer I recently discovered the @eightsleep mattress and thought it was awesome!',
		verified: true,
		url: 'https://twitter.com/mrsharma/status/1098326370495676416',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1379051826/46747_1517981421999_1008390004_1516221_1265122_n_normal.jpg',
		name: 'Joe Aranda',
		handle: 'JNAranda',
		unixDateMS: 1560865930000,
		platform: 'twitter',
		content:
			'We’ve been sleeping on the Pod for about 10 months now. Really great to see the improvement over the last 6mos. More energy, more alert. Definitely have needed to make some difficult lifestyle changes, but worth it @eightsleep ',
		verified: false,
		url: 'https://twitter.com/JNAranda/status/1140980525735337984',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/D9WTvTDWsAAKnul.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1524849005666131968/d-S6tHPT_normal.png',
		name: 'Nik Sharma',
		handle: 'mrsharma',
		unixDateMS: 1562347826000,
		platform: 'twitter',
		content: '@a_zatarain @m_franceschetti @eightsleep I over-performed on my workout today and the only difference is that I had a much more balanced sleep last night.',
		verified: true,
		url: 'https://twitter.com/mrsharma/status/1147196050358198273',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1413197437351694342/QyXkx-62_normal.jpg',
		name: 'Tony',
		handle: 'tonelove14',
		unixDateMS: 1562357091000,
		platform: 'twitter',
		content: 'Actual conversation: Me: "I\'m not staying at my place, I better turn my bed off." Other person: "what?" Me: "what?" @eightsleep',
		verified: false,
		url: 'https://twitter.com/tonelove14/status/1147234909800534017',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/2598602485/hl9eja8jl3zc2anxsode_normal.png',
		name: 'Peter Pham',
		handle: 'peterpham',
		unixDateMS: 1607100382000,
		platform: 'twitter',
		content: "@beninato @natebosshard @m_franceschetti @tonal @eightsleep Yes. That's a good solution but you'll be surprised the pro is awesome . I have a bad back &amp; it's been great",
		verified: true,
		url: 'https://twitter.com/peterpham/status/1334901872751079424',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1587931405278973953/BojuvUe5_normal.jpg',
		name: 'Matt Galligan',
		handle: 'mg',
		unixDateMS: 1607097512000,
		platform: 'twitter',
		content:
			'@khalil_fuller @eightsleep @ouraring If you already feel great, awesome!<br/><br/>I would say that my total volume of productive time increases on days that I’ve had a night with a lot of deep sleep. It’s also hugely beneficial for muscle recovery.<br/><br/>I always thought I slept “fine” but now I sleep much better.',
		verified: true,
		url: 'https://twitter.com/mg/status/1334889836696711168',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1029791436529487873/S5elp_Ou_normal.jpg',
		name: 'Nate Bosshard',
		handle: 'natebosshard',
		unixDateMS: 1607041168000,
		platform: 'twitter',
		content:
			"The two products everyone should own that will immediately and fundamentally change your life for the better = @tonal &amp; @eightsleep. As a new @eightsleep customer I've been blown away by the impact it has had on my well-being and health.",
		verified: true,
		url: 'https://twitter.com/natebosshard/status/1334653511179460610',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1664827948321632257/Hl7v5881_normal.jpg',
		name: 'Eade',
		handle: 'eade_bengard',
		unixDateMS: 1607046305000,
		platform: 'twitter',
		content:
			'@loomstate1 @arrington @m_franceschetti @zebulgar @eightsleep At first I was opposed- another way for the patriarchy to make spaces colder?? <br/><br/>But then I warmed up to it, literally. I cranked that sucker up. Given I love sleeping in a warm bed but cold room, it’s been nice. Plus it was clutch when it was hot af in sf and I had no AC',
		verified: true,
		url: 'https://twitter.com/eade_bengard/status/1334675059869302785',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1635345555982262287/mPLtuGhl_normal.jpg',
		name: 'Jenny Lefcourt',
		handle: 'jennylefcourt',
		unixDateMS: 1607046219000,
		platform: 'twitter',
		content:
			'@m_franceschetti @JonZanoff @eightsleep I LOVE!!!!  We just got an upgrade and it vibrates to wake me, has awesome analytics and I love being heated as I get into bed and when I wake (originally got it for cooling!)  highly recommend',
		verified: true,
		url: 'https://twitter.com/jennylefcourt/status/1334674695602360321',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/509770564123111424/aMuq_28G_normal.jpeg',
		name: 'David Eckstein',
		handle: 'davidneckstein',
		unixDateMS: 1607045691000,
		platform: 'twitter',
		content: '@jennylefcourt @eightsleep Greatest device of all time!',
		verified: false,
		url: 'https://twitter.com/davidneckstein/status/1334672481102295040',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/959553338118348800/6e4Y8FTN_normal.jpg',
		name: 'Stephen M. Collins',
		handle: 'eesmc',
		unixDateMS: 1606811335000,
		platform: 'twitter',
		content:
			"@SavorTooth I'm a little embarrassed to admit it, but honestly my @eightsleep water-cooled mattress fits this description perfectly. Together with the fact that I can now sleep comfortably under a heavy weighted blanket without overheating, it's been a game changer.",
		verified: false,
		url: 'https://twitter.com/eesmc/status/1333689522840764416',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1562804983426207744/aUr_N2k8_normal.jpg',
		name: 'Nelly',
		handle: 'NelsonsThoughts',
		unixDateMS: 1606800331000,
		platform: 'twitter',
		content: '@m_franceschetti @eightsleep I just got mine and set it up two days ago and it’s been a game changer for my sleep!  I felt super well rested each of the mornings!',
		verified: false,
		url: 'https://twitter.com/NelsonsThoughts/status/1333643369285701633',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/718598294323073024/qu7IlBA8_normal.jpg',
		name: 'Billy Jalbert',
		handle: 'Mauibilly',
		unixDateMS: 1606783123000,
		platform: 'twitter',
		content: "@m_franceschetti @eightsleep Loving the cover and the thermoregulation. I was worried about comfort and it isn't an issue. Happy to provide additional FB via DM or email.",
		verified: false,
		url: 'https://twitter.com/Mauibilly/status/1333571190586183680',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1432726717265817611/-Ao7ILrU_normal.jpg',
		name: 'Ashton',
		handle: 'aclemens185',
		unixDateMS: 1606741224000,
		platform: 'twitter',
		content: 'Got the best sleep of my life last night after combining an Eight Sleep water cooled topper and a Nova Hybrid Casper mattress. @Casper @eightsleep 🙌',
		verified: false,
		url: 'https://twitter.com/aclemens185/status/1333395455548547076',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1565913855674441728/BEqVYGhA_normal.jpg',
		name: 'Austin Garland 🐺',
		handle: 'ausgarland',
		unixDateMS: 1606618788000,
		platform: 'twitter',
		content:
			'@m_franceschetti @WinginItWife @eightsleep Technically I don’t have the pro. But the pod is amazing! I would love to upgrade, but I had just paid off my pod when the pro came out. Would love the extra sensors and the vibrating alarm! Also love the dark gray color. Just have to find someone willing to buy a used bed 😂',
		verified: false,
		url: 'https://twitter.com/ausgarland/status/1332881921978888195',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1662301833149448192/ukhJDVvK_normal.jpg',
		name: 'RetwitBrad',
		handle: 'iambradcahoon',
		unixDateMS: 1606613364000,
		platform: 'twitter',
		content:
			'@WinginItWife @eightsleep Have had pod pro for several months now. Was a little too stiff for us so we added a thin foam pad on top. Less accurate readings I’m sure but the temp control is actually the very best sleep experience ever. Huge thumbs up even if you have to slightly modify. No fires here. Lol',
		verified: true,
		url: 'https://twitter.com/iambradcahoon/status/1332859173084663809',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1616420853645713409/u_wnjXwu_normal.jpg',
		name: 'Jason',
		handle: 'json0bject',
		unixDateMS: 1609369584000,
		platform: 'twitter',
		content: '@katiewav @briannekimmel @public @eightsleep @unspun_io Eight has been awesome for my insomnia',
		verified: false,
		url: 'https://twitter.com/json0bject/status/1344419598066737152',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1668448923692957696/MTtKNq1X_normal.jpg',
		name: 'Nate Williams',
		handle: 'naywilliams',
		unixDateMS: 1609351077000,
		platform: 'twitter',
		content:
			"Circling back on this since I got soo many DMs and emails. 3 weeks in definitely a big fan of @eightsleep. Combined w/ my @whoop, 🥤 💤, and lots of @onepeloton (and outside 🚵🏾riding) feeling 🏆. I'm not joining @m_franceschetti in MIA but will be ready if I need to hit Barrys 🤪",
		verified: true,
		url: 'https://twitter.com/naywilliams/status/1344341970328567809',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1486729278725103616/2Pkq288U_normal.jpg',
		name: 'Rahul Desai',
		handle: 'rahuld__',
		unixDateMS: 1609281761000,
		platform: 'twitter',
		content:
			'@ericbahn @eightsleep Pod Pro has been great (used to overheat before it in any normal ambient temperature). <br/><br/>Also rec no coffee or alcohol. Sleep mask and/or blackout shades help a ton.',
		verified: false,
		url: 'https://twitter.com/rahuld__/status/1344051241035456512',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1409938156246364163/wQ5wLT8i_normal.jpg',
		name: 'Vishal Kapur',
		handle: 'figelwump',
		unixDateMS: 1607886256000,
		platform: 'twitter',
		content:
			"The hardest habit for me to crack has been getting consistent, good sleep.<br/><br/>Ordered the @eightsleep mattress cover, which lets you control your existing mattress temp and vary it over time. Also gives you built-in sleep tracking. Can't wait to try it out ",
		verified: false,
		url: 'https://twitter.com/figelwump/status/1338198068764655619',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1524528385950593026/dMfDafhn_normal.jpg',
		name: 'Morgan Brown',
		handle: 'morganb',
		unixDateMS: 1609471707000,
		platform: 'twitter',
		content: '6 weeks with @eightsleep &amp; ~23% increase in deep sleep. I’m feeling better than ever even w/out a big change in time slept. ',
		verified: true,
		url: 'https://twitter.com/morganb/status/1344847930801340416',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EqncEKpVoAAj2Ja.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1587931405278973953/BojuvUe5_normal.jpg',
		name: 'Matt Galligan',
		handle: 'mg',
		unixDateMS: 1607977327000,
		platform: 'twitter',
		content:
			'Experimenting w/ variables that contribute to better sleep &amp; am surprised at the most recent one.<br/><br/>Regulating temp with @eightsleep was a big win but somewhat counterintuitively I’ve learned that nights that I *don’t* take a melatonin supplement I sleep better. ',
		verified: true,
		url: 'https://twitter.com/mg/status/1338580048933236736',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EpOXdZeXMAIW0uw.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1587931405278973953/BojuvUe5_normal.jpg',
		name: 'Matt Galligan',
		handle: 'mg',
		unixDateMS: 1607980252000,
		platform: 'twitter',
		content:
			'@m_franceschetti @eightsleep Specifically I’ve noticed that my deep sleep is longer, and I’m prone to less frequent wake-ups.<br/><br/>Anecdotally:<br/>→ No Eight, no melatonin — little deep sleep<br/>→ Eight, melatonin — better deep sleep, but sometimes inconsistent<br/>→ Eight, no melatonin — deep sleep, more consistent',
		verified: true,
		url: 'https://twitter.com/mg/status/1338592315993190404',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1544831972874264578/ZUDVkvMZ_normal.jpg',
		name: 'Justin Kadis',
		handle: 'jkadis',
		unixDateMS: 1608301346000,
		platform: 'twitter',
		content: '@m_franceschetti @eightsleep Look at my improvement in just this first week! ',
		verified: false,
		url: 'https://twitter.com/jkadis/status/1339939080700899343',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EphrfctW8AA8eFW.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1683667154435211264/JYiqb2DZ_normal.jpg',
		name: 'Tommy Collison',
		handle: 'tommycollison',
		unixDateMS: 1608325642000,
		platform: 'twitter',
		content: 'A couple months in, an @eightsleep really is the step-change improvement in😴that everyone talks about.',
		verified: true,
		url: 'https://twitter.com/tommycollison/status/1340040987121311745',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1593770033729134598/_cpaqRxH_normal.jpg',
		name: 'bradhailey',
		handle: 'bradhailey',
		unixDateMS: 1608383975000,
		platform: 'twitter',
		content: '@austinrileygray @ouraring +1 for @eightsleep I trust the sleep and HRV data better than my @ouraring .  I have tested across at least 6 devices and it is the best.',
		verified: true,
		url: 'https://twitter.com/bradhailey/status/1340285653511233536',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1687464127764054017/EBUBu2_j_normal.jpg',
		name: 'Tim D’Agostino',
		handle: 'iamtdags',
		unixDateMS: 1596259106000,
		platform: 'twitter',
		content:
			'I’ve been sleeping on the @eightsleep Pod Pro for a while and it’s a beautiful thing when you’re warm and can just adjust the temperature of your bed accordingly. Could never go back to a standard “dumb” mattress. ',
		verified: false,
		url: 'https://twitter.com/iamtdags/status/1289430263865409537',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EeT5_ayWoAIl6Ho.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/2598602485/hl9eja8jl3zc2anxsode_normal.png',
		name: 'Peter Pham',
		handle: 'peterpham',
		unixDateMS: 1593817584000,
		platform: 'twitter',
		content:
			"Upgraded to the new @eightsleep pod pro! I loved the original, it was a game changer. But now it's got room climate &amp; weather response to dynamically change. Also HRV monitoring &amp; vibrate for awake. Perfect for summer. ",
		verified: true,
		url: 'https://twitter.com/peterpham/status/1279189780123680768',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EcCYTf_UYAAcsQy.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1363316601974644736/JKdIckKh_normal.jpg',
		name: 'Matthew Dellavedova',
		handle: 'matthewdelly',
		unixDateMS: 1596597016000,
		platform: 'twitter',
		content: 'Loved the Pod from @eightsleep, can’t wait to try the Pod Pro tonight!! 💤 💤 @m_franceschetti @a_zatarain ',
		verified: true,
		url: 'https://twitter.com/matthewdelly/status/1290847564795768834',
		categories: ['Familiar faces'],
		image: 'https://pbs.twimg.com/media/EeoDAVfUEAIPnTX.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1105156855595188224/6OCHLEuw_normal.png',
		name: 'Austin Lieberman',
		handle: 'LiebermanAustin',
		unixDateMS: 1594390903000,
		platform: 'twitter',
		content:
			"@Flash80jr @m_franceschetti @eightsleep @a_zatarain YES! We actually can keep our bedroom A/C much higher because our mattress can cool to whatever setting we want (I haven't dared to go lower than the -3 setting yet).<br/><br/>It also heats for athletic recovery/winter time. <br/><br/>added bonus, can adjust both sides for diff ppl",
		verified: true,
		url: 'https://twitter.com/LiebermanAustin/status/1281594453288013825',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1552979440547704832/WX5crG9I_normal.jpg',
		name: 'Mckay Wrigley',
		handle: 'mckaywrigley',
		unixDateMS: 1594418446000,
		platform: 'twitter',
		content:
			'@MaxNPalmer @adrianalfieri @eightsleep Slept super hot my whole life to the point where it makes it extremely difficult to sleep well. Cooling solved my problem completely. Sleep like a baby every night now.',
		verified: true,
		url: 'https://twitter.com/mckaywrigley/status/1281709976398921728',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1105156855595188224/6OCHLEuw_normal.png',
		name: 'Austin Lieberman',
		handle: 'LiebermanAustin',
		unixDateMS: 1578575067000,
		platform: 'twitter',
		content: 'I felt like I was logging into a space shuttle command center last night. So awesome. Happy @eightsleep user ',
		verified: true,
		url: 'https://twitter.com/LiebermanAustin/status/1215258030880755712',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1273285530281578507/MRY6iM4I_normal.jpg',
		name: 'Kristy Kowal',
		handle: 'KristyKowal',
		unixDateMS: 1578619091000,
		platform: 'twitter',
		content: '@m_franceschetti The best part about traveling is coming home to my @eightsleep bed! ❤️❤️',
		verified: false,
		url: 'https://twitter.com/KristyKowal/status/1215442681314205699',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1485777379075051522/3dF7aXYZ_normal.jpg',
		name: 'Aaron Suplizio 🎸',
		handle: 'AaronSuplizio',
		unixDateMS: 1578705438000,
		platform: 'twitter',
		content:
			'@a19grey @eightsleep I rocked my 8 sleep last night :)<br/><br/>It is pretty cool, and definitely has made my wife and me more mindful about our sleep.  Temp control is pretty sweet, and the sleep tracking is very interesting. ',
		verified: false,
		url: 'https://twitter.com/AaronSuplizio/status/1215804846344785925',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/EN9oD9nU4AAi22E.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1524019081132748800/KGBjq7gs_normal.jpg',
		name: 'PM',
		handle: 'pm',
		unixDateMS: 1579128959000,
		platform: 'twitter',
		content:
			".@eightsleep's approach to improving sleep is so simple but so interesting<br/><br/>like self driving cars, because they eventually get really good, saves millions (duh, been said before)<br/><br/>but the idea of making a computer that self-drives sleep, so still 🤯. so many people sleep!",
		verified: false,
		url: 'https://twitter.com/pm/status/1217581221959606273',
		categories: ['Everyday love'],
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/996698294935007233/0r5K2Mmf_normal.jpg',
		name: 'Máté Kovács',
		handle: 'kovacsamate',
		unixDateMS: 1692683427000,
		platform: 'twitter',
		content: 'So I received my @eightsleep 5 nights ago and so far I only wish I ordered it sooner. Zero night sweats since, my deep sleep (during summer) is 2x longer compared what it was before. ',
		verified: true,
		url: 'https://twitter.com/kovacsamate/status/1693863183184789870',
		categories: ['Sleep improvement'],
		image: 'https://pbs.twimg.com/media/F4HPlmIWoAA2cX6.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1677530541606989825/n0TQ5Wv4_normal.jpg',
		name: 'Isaac Bratzel',
		handle: 'isaacbratzel',
		unixDateMS: 1696086733000,
		platform: 'twitter',
		content: 'Got an @eightsleep two nights ago. Pretty impressive results ',
		verified: false,
		url: 'https://twitter.com/isaacbratzel/status/1708137680213848220',
		categories: ['Sleep improvement', 'Everyday love'],
		image: 'https://pbs.twimg.com/media/F7SGKpba4AAmcTe.jpg',
	},
	{
		picture: 'https://pbs.twimg.com/profile_images/1658106505260826628/jXxWlLP5_normal.jpg',
		name: 'GuinnessStache',
		handle: 'guinnessstache',
		unixDateMS: 1701178256000,
		platform: 'twitter',
		content: "Even my @eightsleep bed knows I've been sick and not getting enough rest 🤧 ",
		verified: false,
		url: 'https://twitter.com/guinnessstache/status/1729493077688037610',
		categories: ['Everyday love'],
		image: 'https://pbs.twimg.com/media/GABkyU1WYAAycC1.jpg',
	},
]
