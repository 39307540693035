import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import styles from './UserReviews.module.scss'
import { ReviewsSliderHandle, ReviewsSliderProps } from './UserReviews.types'
import { useSwipe } from 'components/_hooks/useSwipe'
import { clamp } from 'components/_utils/mathUtils'
import { amChangeSlideImageCarousel, amSwipeImageCarousel } from 'events/amplitude'
import { Type } from 'components/Type'
import useIsMobile from 'hooks/useIsMobile'
import { Img } from 'components/basic/Img'

const ITEMS = [
	{
		text: '“Really enjoy taking Sleep Elixir. It helps me relax.”',
		author: 'Jess L.',
		subtitle: 'Executive',
		img: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/7218hr08fh0h103h10321%C4%91.png',
	},
	{
		text: '“My deep and REM sleep have both improved while taking Sleep Elixir.”',
		author: 'Bryan R.',
		subtitle: 'Tech founder',
		img: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/bray-r-sleep-elixir.png',
	},
	{
		text: '“Knowing that I’m taking natural supplements is wonderful.”',
		author: 'Tori B.',
		subtitle: 'Iron man runner',
		img: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/ff4d2e98c6bc9107c13cccb86f813b69.png',
	},
	{
		text: '“I definitely noticed an improvement in falling asleep.”',
		author: 'Mitch D.',
		subtitle: 'Dad and business owner',
		img: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/mitch-d-sleep-elixir.png',
	},
]

interface ReviewItemProps {
	text: string
	author: string
	subtitle: string
	img: string
}

export const UserReviews = () => {
	const isMobile = useIsMobile(1023)

	return (
		<section className={styles.container}>
			<header>
				<Type.Headline2
					className={styles.heading}
					animateOnScroll
				>
					Tailored for busy minds seeking deep, restorative rest
				</Type.Headline2>
			</header>
			{isMobile ? <ReviewsMobile startIndex={0} /> : <ReviewsDesktop />}
		</section>
	)
}

export const ReviewsDesktop = () => {
	return (
		<div className={styles.grid}>
			{ITEMS.map((item, index) => (
				<ReviewItem
					key={index}
					item={item}
				/>
			))}
		</div>
	)
}

export const ReviewsMobile = forwardRef<ReviewsSliderHandle, ReviewsSliderProps>((props, ref) => {
	const { startIndex } = props
	const items = Array(10).fill(ITEMS).flat()

	const sliderRef = useRef<HTMLUListElement>(null)

	const [currentIndex, setCurrentIndex] = useState(startIndex)

	useSwipe(
		sliderRef,
		(direction: 'left' | 'right') => {
			const length = items.length
			amSwipeImageCarousel(direction, props.id)
			if (direction === 'left') {
				setCurrentIndex((prev) => Math.min(length - 1, prev + 1))
			} else if (direction === 'right') {
				setCurrentIndex((prev) => Math.max(0, prev - 1))
			}
		},
		50,
		500,
		[]
	)

	useImperativeHandle(ref, () => ({
		goToSlide: (index: number) => {
			setCurrentIndex(index)
			amChangeSlideImageCarousel(props.id, index)
		},
	}))

	useEffect(() => {
		setCurrentIndex(startIndex)
		amChangeSlideImageCarousel(props.id, startIndex)
	}, [startIndex, props.id])

	const clampedIndex = clamp(currentIndex, 0, items.length - 1)

	return (
		<div className={styles.slider_wrapper}>
			<ul
				className={styles.media_stack}
				ref={sliderRef}
			>
				{items.map((item, index) => (
					<li
						key={`${index}`}
						className={styles.media_item}
						style={{
							transform: `translateX(calc(${(index - clampedIndex) * 100}%))`,
							opacity: index === clampedIndex ? 1 : 0.3,
						}}
						onClick={() => setCurrentIndex(index)}
					>
						<ReviewItem item={item} />
					</li>
				))}
			</ul>
		</div>
	)
})

export const ReviewItem = ({ item }: { item: ReviewItemProps }) => {
	const { text, author, subtitle } = item

	return (
		<div className={styles.review_item}>
			<Img
				src={item.img}
				alt={item.author}
				className={styles.review_item_img}
				simple
			/>
			<div className={styles.review_item_text}>
				<Type.Headchapter>{text}</Type.Headchapter>
				{/* <Type.Headline5 className={styles.review_item_author}>{author}</Type.Headline5> */}
				<Type.Body2 className={styles.review_item_subtitle}>{author}</Type.Body2>
			</div>
		</div>
	)
}
