import { FC, useState } from 'react'
import styles from './Ingredients.module.scss'
import { IngredientsProps } from './Ingredients.types'
import { Type } from 'components/Type'
import { Img } from 'components/basic/Img'
import { A } from 'components/basic/A'

export const Ingredients: FC<IngredientsProps> = (props) => {
	const { ingredients = getDefaultIngredients() } = props
	const [itemVisibility, setItemVisibility] = useState(Array.from({ length: ingredients.length }).fill(true))
	return (
		<section className={styles.container}>
			<div className={styles.content}>
				<header>
					<Type.Headline2 animateOnScroll>A proprietary blend of four natural ingredients</Type.Headline2>

					<Type.Body1
						animateOnScroll
						className={styles.bodytext}
					>
						A science-driven blend made in perfect balance to align with your body’s natural sleep cycles, promoting relaxation without melatonin or habit-forming ingredients. Read more about the
						clinical evidence of these ingredients{' '}
						<A
							id={'ingredients-clinical-evidence'}
							href={'/blog/sleep-elixir-science'}
						>
							here
						</A>
						.²
					</Type.Body1>
				</header>
				<div className={styles.gallery}>
					{ingredients.map((ingredient, i) => (
						<div
							className={styles.card}
							key={`ingredient-${i}`}
						>
							<Img
								src={ingredient.image}
								alt={ingredient.name}
							/>
							<Type.Body1
								as={'p'}
								className={styles.description}
							>
								<span className={styles.ingredient_name}>{ingredient.name}</span>
								<Type.Body2 className={`${styles.additional_info} ${itemVisibility[i] ? styles.show : ''}`}>
									<span>
										{ingredient.description}
										{ingredient.superscript && <sup>{ingredient.superscript}</sup>}
									</span>
								</Type.Body2>
							</Type.Body1>
							{/* <button
							className={styles.button}
							onClick={() => setItemVisibility((prev) => prev.map((it, index) => (index === i ? !it : it)))}
						>
							{itemVisibility[i] ? (
								<Icon
									icon={minus}
									color={'black'}
								/>
							) : (
								<Icon
									icon={plus}
									color={'black'}
								/>
							)}
						</button> */}
						</div>
					))}
					<Type.SmallPrint className={styles.small_print}>
						² Results may vary. This product has not been evaluated by the Food and Drug Administration and is not intended to diagnose, treat, cure, or prevent any disease.
						<br />³ Though tart cherries contain trace amounts of naturally-occurring melatonin, the tart cherry in Sleep Elixir is formulated far below the minimum effective dose of melatonin.
					</Type.SmallPrint>
				</div>
			</div>
		</section>
	)
}

const getDefaultIngredients = (): IngredientsProps['ingredients'] => [
	{
		name: 'L-Tryptophan',
		image: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-waitlist/image+(1).png',
		description: 'Supports serotonin and melatonin production to improve sleep quality and regulate your sleep-wake cycle.',
		link: 'https://pubmed.ncbi.nlm.nih.gov/33942088/',
		superscript: '2',
	},
	{
		name: 'Valerian Root',
		image: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-waitlist/image+(2).png',
		description: 'Helps anyone who has trouble sleeping by increasing levels of the calming neurotransmitter GABA.',
		link: 'https://pubmed.ncbi.nlm.nih.gov/37899385/',
		superscript: '2',
	},
	{
		name: 'Ashwagandha',
		image: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-waitlist/image.png',
		description: 'Fights stress by lowering cortisol levels and calming your mind to promote deep restorative sleep.',
		link: 'https://pubmed.ncbi.nlm.nih.gov/32540634/',
		superscript: '2',
	},
	{
		name: 'Tart Cherry',
		image: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-waitlist/image+(3).png',
		description: 'Provides vital building blocks for melatonin production to help you sleep for longer each night.',
		link: 'https://pubmed.ncbi.nlm.nih.gov/22038497/',
		superscript: '2,3',
	},
]
