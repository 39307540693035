import { SHOPIFY_SITE_DOMAIN } from '../constants'

export const shopifyUrl = (path: string): string => {
	return `https://${SHOPIFY_SITE_DOMAIN}/${path}`
}

export const queryString = (obj: any) => {
	return Object.keys(obj)
		.map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
		.join('&')
}

export const loadExternalScript = (url: string, script_id: string, callback?: () => void) => {
	if (typeof window === 'undefined') {
		return
	}
	const existingScript = document.getElementById(script_id)
	if (!existingScript) {
		const script = document.createElement('script')
		script.src = url
		script.id = script_id
		script.async = true
		document.body.appendChild(script)

		script.onload = () => {
			if (typeof callback === 'function') {
				callback()
			}
		}
	}

	if (existingScript && callback) callback()
}
