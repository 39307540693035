import AdjustmentsDark from './icons/dark/Adjustments'
import AlarmDark from './icons/dark/Alarm'
import ArrowCircleRightDark from './icons/dark/ArrowCircleRight'
import ArrowCircleUpDark from './icons/dark/ArrowCircleUp'
import ArrowDownDark from './icons/dark/ArrowDown'
import ArrowLeftDark from './icons/dark/ArrowLeft'
import ArrowRightDark from './icons/dark/ArrowRight'
import ArrowUpDark from './icons/dark/ArrowUp'
import BackspaceDark from './icons/dark/Backspace'
import BanDark from './icons/dark/Ban'
import BedDark from './icons/dark/Bed'
import BellDark from './icons/dark/Bell'
import BellAlertDark from './icons/dark/BellAlert'
import BookmarkDark from './icons/dark/Bookmark'
import BookOpenDark from './icons/dark/BookOpen'
import CalendarDark from './icons/dark/Calendar'
import CameraDark from './icons/dark/Camera'
import CartDark from './icons/dark/Cart'
import ChartDark from './icons/dark/Chart'
import ChatDark from './icons/dark/Chat'
import Chat2Dark from './icons/dark/Chat2'
import CheckDark from './icons/dark/Check'
import CheckCircleDark from './icons/dark/CheckCircle'
import ChevronDownDark from './icons/dark/ChevronDown'
import ChevronLeftDark from './icons/dark/ChevronLeft'
import ChevronRightDark from './icons/dark/ChevronRight'
import ChevronUpDark from './icons/dark/ChevronUp'
import ChillDark from './icons/dark/Chill'
import ClockDark from './icons/dark/Clock'
import CloseDark from './icons/dark/Close'
import CloseCircleDark from './icons/dark/CloseCircle'
import CloudDownloadDark from './icons/dark/CloudDownload'
import CocktailDark from './icons/dark/Cocktail'
import CoffeeDark from './icons/dark/Coffee'
import CogDark from './icons/dark/Cog'
import CoolDark from './icons/dark/Cool'
import CoverDark from './icons/dark/Cover'
import CreditCardDark from './icons/dark/CreditCard'
import CustomerServiceDark from './icons/dark/CustomerService'
import DeliveryDark from './icons/dark/Delivery'
import DisturbanceDark from './icons/dark/Disturbance'
import DotsCircleDark from './icons/dark/DotsCircle'
import DotsHorizontalDark from './icons/dark/DotsHorizontal'
import DualTempDark from './icons/dark/DualTemp'
import EditDark from './icons/dark/Edit'
import Edit2Dark from './icons/dark/Edit2'
import EightBoxDark from './icons/dark/EightBox'
import ElevationDark from './icons/dark/Elevation'
import ExclamationDark from './icons/dark/Exclamation'
import ExerciseDark from './icons/dark/Exercise'
import ExternalLinkDark from './icons/dark/ExternalLink'
import EyeDark from './icons/dark/Eye'
import FellAsleepDark from './icons/dark/FellAsleep'
import FilterDark from './icons/dark/Filter'
import GiftDark from './icons/dark/Gift'
import GlobeDark from './icons/dark/Globe'
import HeartDark from './icons/dark/Heart'
import HeartReportDark from './icons/dark/HeartReport'
import HistoryDark from './icons/dark/History'
import HomeDark from './icons/dark/Home'
import HubDark from './icons/dark/Hub'
import InformationCircleDark from './icons/dark/InformationCircle'
import LightBulbDark from './icons/dark/LightBulb'
import LightningBoltDark from './icons/dark/LightningBolt'
import LinkDark from './icons/dark/Link'
import LoaderDark from './icons/dark/Loader'
import LocalWeatherDark from './icons/dark/LocalWeather'
import LocationDark from './icons/dark/Location'
import LockClosedDark from './icons/dark/LockClosed'
import LockOpenDark from './icons/dark/LockOpen'
import LoginDark from './icons/dark/Login'
import LogoutDark from './icons/dark/Logout'
import LungDark from './icons/dark/Lung'
import MagicDark from './icons/dark/Magic'
import MailDark from './icons/dark/Mail'
import MattressDark from './icons/dark/Mattress'
import MealDark from './icons/dark/Meal'
import MeditationDark from './icons/dark/Meditation'
import MenuDark from './icons/dark/Menu'
import MicrophoneDark from './icons/dark/Microphone'
import MinusDark from './icons/dark/Minus'
import MinusCircleDark from './icons/dark/MinusCircle'
import MoonDark from './icons/dark/Moon'
import MultiPodDark from './icons/dark/MultiPod'
import MusicNoteDark from './icons/dark/MusicNote'
import NumberCircleDark from './icons/dark/NumberCircle'
import OffDark from './icons/dark/Off'
import PauseDark from './icons/dark/Pause'
import PhoneDark from './icons/dark/Phone'
import PiggyBankDark from './icons/dark/PiggyBank'
import PlayDark from './icons/dark/Play'
import PlusDark from './icons/dark/Plus'
import PlusCircleDark from './icons/dark/PlusCircle'
import PowerDark from './icons/dark/Power'
import PriceDark from './icons/dark/Price'
import PrimeDark from './icons/dark/Prime'
import QuestionCircleDark from './icons/dark/QuestionCircle'
import ReadingDark from './icons/dark/Reading'
import ReferralDark from './icons/dark/Referral'
import RefreshDark from './icons/dark/Refresh'
import RelaxingDark from './icons/dark/Relaxing'
import ReplyDark from './icons/dark/Reply'
import SaveDark from './icons/dark/Save'
import SearchDark from './icons/dark/Search'
import SearchCircleDark from './icons/dark/SearchCircle'
import SelectorDark from './icons/dark/Selector'
import ShareDark from './icons/dark/Share'
import ShieldCheckDark from './icons/dark/ShieldCheck'
import ShoppingBagDark from './icons/dark/ShoppingBag'
import SleepingDark from './icons/dark/Sleeping'
import SmartPhoneDark from './icons/dark/SmartPhone'
import SnoreMitigationDark from './icons/dark/SnoreMitigation'
import SnoringDark from './icons/dark/Snoring'
import SoundDark from './icons/dark/Sound'
import SoundBarDark from './icons/dark/SoundBar'
import SoundOffDark from './icons/dark/SoundOff'
import StarDark from './icons/dark/Star'
import SunDark from './icons/dark/Sun'
import Sun2Dark from './icons/dark/Sun2'
import TapDark from './icons/dark/Tap'
import TemperatureDark from './icons/dark/Temperature'
import ThumbDownDark from './icons/dark/ThumbDown'
import ThumbUpDark from './icons/dark/ThumbUp'
import TimeBedDark from './icons/dark/TimeBed'
import TrashDark from './icons/dark/Trash'
import TrendingUpDark from './icons/dark/TrendingUp'
import TwoUsersDark from './icons/dark/TwoUsers'
import UploadDark from './icons/dark/Upload'
import UploadCloudDark from './icons/dark/UploadCloud'
import UserDark from './icons/dark/User'
import VibrationDark from './icons/dark/Vibration'
import VideoCameraDark from './icons/dark/VideoCamera'
import WarmDark from './icons/dark/Warm'
import WarrantyDark from './icons/dark/Warranty'
import WatchDark from './icons/dark/Watch'
import WaterDark from './icons/dark/Water'
import WifiDark from './icons/dark/Wifi'
import WifiOffDark from './icons/dark/WifiOff'
import ZoomInDark from './icons/dark/ZoomIn'
import ZoomOutDark from './icons/dark/ZoomOut'

import AdjustmentsLight from './icons/light/Adjustments'
import AlarmLight from './icons/light/Alarm'
import ArrowCircleRightLight from './icons/light/ArrowCircleRight'
import ArrowCircleUpLight from './icons/light/ArrowCircleUp'
import ArrowDownLight from './icons/light/ArrowDown'
import ArrowLeftLight from './icons/light/ArrowLeft'
import ArrowRightLight from './icons/light/ArrowRight'
import ArrowUpLight from './icons/light/ArrowUp'
import BackspaceLight from './icons/light/Backspace'
import BanLight from './icons/light/Ban'
import BedLight from './icons/light/Bed'
import BellLight from './icons/light/Bell'
import BookmarkLight from './icons/light/Bookmark'
import BookOpenLight from './icons/light/BookOpen'
import CalendarLight from './icons/light/Calendar'
import CameraLight from './icons/light/Camera'
import CartLight from './icons/light/Cart'
import ChartLight from './icons/light/Chart'
import ChatLight from './icons/light/Chat'
import Chat2Light from './icons/light/Chat2'
import CheckLight from './icons/light/Check'
import CheckCircleLight from './icons/light/CheckCircle'
import ChevronDownLight from './icons/light/ChevronDown'
import ChevronLeftLight from './icons/light/ChevronLeft'
import ChevronRightLight from './icons/light/ChevronRight'
import ChevronUpLight from './icons/light/ChevronUp'
import ChillLight from './icons/light/Chill'
import ClockLight from './icons/light/Clock'
import CloseLight from './icons/light/Close'
import CloseCircleLight from './icons/light/CloseCircle'
import CloudDownloadLight from './icons/light/CloudDownload'
import CocktailLight from './icons/light/Cocktail'
import CoffeeLight from './icons/light/Coffee'
import CogLight from './icons/light/Cog'
import ComfortLight from './icons/light/Comfort'
import CoolLight from './icons/light/Cool'
import CoverLight from './icons/light/Cover'
import CreditCardLight from './icons/light/CreditCard'
import CustomerServiceLight from './icons/light/CustomerService'
import DeliveryLight from './icons/light/Delivery'
import DisturbanceLight from './icons/light/Disturbance'
import DotsCircleLight from './icons/light/DotsCircle'
import DotsHorizontalLight from './icons/light/DotsHorizontal'
import DualTempLight from './icons/light/DualTemp'
import EditLight from './icons/light/Edit'
import Edit2Light from './icons/light/Edit2'
import EightBoxLight from './icons/light/EightBox'
import ElevationLight from './icons/light/Elevation'
import ExclamationLight from './icons/light/Exclamation'
import ExclamationCircleLight from './icons/light/ExclamationCircle'
import ExerciseLight from './icons/light/Exercise'
import ExternalLinkLight from './icons/light/ExternalLink'
import EyeLight from './icons/light/Eye'
import EyeNewOpenLight from './icons/light/EyeNewOpen'
import EyeNewSlashLight from './icons/light/EyeNewSlash'
import FellAsleepLight from './icons/light/FellAsleep'
import FilterLight from './icons/light/Filter'
import GenderLight from './icons/light/Gender'
import GiftLight from './icons/light/Gift'
import GlobeLight from './icons/light/Globe'
import HeartLight from './icons/light/Heart'
import HeartReportLight from './icons/light/HeartReport'
import HistoryLight from './icons/light/History'
import HomeLight from './icons/light/Home'
import HubLight from './icons/light/Hub'
import InformationCircleLight from './icons/light/InformationCircle'
import LightBulbLight from './icons/light/LightBulb'
import LightningBoltLight from './icons/light/LightningBolt'
import LinkLight from './icons/light/Link'
import LoaderLight from './icons/light/Loader'
import LocalWeatherLight from './icons/light/LocalWeather'
import LocationLight from './icons/light/Location'
import LockClosedLight from './icons/light/LockClosed'
import LockOpenLight from './icons/light/LockOpen'
import LoginLight from './icons/light/Login'
import LogoutLight from './icons/light/Logout'
import LungLight from './icons/light/Lung'
import MagicLight from './icons/light/Magic'
import MailLight from './icons/light/Mail'
import MattressLight from './icons/light/Mattress'
import MealLight from './icons/light/Meal'
import MeditationLight from './icons/light/Meditation'
import MenuLight from './icons/light/Menu'
import MicrophoneLight from './icons/light/Microphone'
import MinusLight from './icons/light/Minus'
import MinusCircleLight from './icons/light/MinusCircle'
import MoonLight from './icons/light/Moon'
import MultiPodLight from './icons/light/MultiPod'
import MusicNoteLight from './icons/light/MusicNote'
import NumberCircleLight from './icons/light/NumberCircle'
import OffLight from './icons/light/Off'
import PauseLight from './icons/light/Pause'
import PhoneLight from './icons/light/Phone'
import PiggyBankLight from './icons/light/PiggyBank'
import PlayLight from './icons/light/Play'
import PlusLight from './icons/light/Plus'
import PlusCircleLight from './icons/light/PlusCircle'
import PowerLight from './icons/light/Power'
import PriceLight from './icons/light/Price'
import PrimeLight from './icons/light/Prime'
import ReadingLight from './icons/light/Reading'
import ReferralLight from './icons/light/Referral'
import RefreshLight from './icons/light/Refresh'
import RelaxingLight from './icons/light/Relaxing'
import ReplyLight from './icons/light/Reply'
import SaveLight from './icons/light/Save'
import SearchLight from './icons/light/Search'
import SearchCircleLight from './icons/light/SearchCircle'
import SelectorLight from './icons/light/Selector'
import ShareLight from './icons/light/Share'
import ShieldCheckLight from './icons/light/ShieldCheck'
import ShoppingBagLight from './icons/light/ShoppingBag'
import SleepingLight from './icons/light/Sleeping'
import SmartPhoneLight from './icons/light/SmartPhone'
import SnoreMitigationLight from './icons/light/SnoreMitigation'
import SnoringLight from './icons/light/Snoring'
import SoundLight from './icons/light/Sound'
import SoundBarLight from './icons/light/SoundBar'
import SoundOffLight from './icons/light/SoundOff'
import StarLight from './icons/light/Star'
import SunLight from './icons/light/Sun'
import Sun2Light from './icons/light/Sun2'
import SurfaceLight from './icons/light/Surface'
import TapLight from './icons/light/Tap'
import TemperatureLight from './icons/light/Temperature'
import ThumbDownLight from './icons/light/ThumbDown'
import ThumbUpLight from './icons/light/ThumbUp'
import TimeBedLight from './icons/light/TimeBed'
import TrashLight from './icons/light/Trash'
import TrendingUpLight from './icons/light/TrendingUp'
import TwoUsersLight from './icons/light/TwoUsers'
import UploadLight from './icons/light/Upload'
import UploadCloudLight from './icons/light/UploadCloud'
import UserLight from './icons/light/User'
import VibrationLight from './icons/light/Vibration'
import VideoCameraLight from './icons/light/VideoCamera'
import WarmLight from './icons/light/Warm'
import WarrantyLight from './icons/light/Warranty'
import WatchLight from './icons/light/Watch'
import WaterLight from './icons/light/Water'
import WifiLight from './icons/light/Wifi'
import WifiOffLight from './icons/light/WifiOff'
import ZoomInLight from './icons/light/ZoomIn'
import ZoomOutLight from './icons/light/ZoomOut'

// Custom
import { HomeCustom } from './icons/custom/HomeCustom'
import { AlarmCustom } from './icons/custom/AlarmCustom'
import { BarCustom } from './icons/custom/BarCustom'
import { CalendarCustom } from './icons/custom/CalendarCustom'
import { ClockCustom } from './icons/custom/ClockCustom'
import { GenderCustom } from './icons/custom/GenderCustom'
import { WeatherCustom } from './icons/custom/WeatherCustom'
import { TempCustom } from './icons/custom/TempCustom'
import { TapCustom } from 'components/Phantom/Icon/icons/custom/TapCustom'
import { BookCustom } from 'components/Phantom/Icon/icons/custom/BookCustom'
import { SnoreMitigationCustom } from 'components/Phantom/Icon/icons/custom/SnoreMitigationCustom'
import { ComfortCustom } from './icons/custom/Comfort'
import { SleepScore } from './icons/custom/SleepScore'

export const icons = {
	AdjustmentsDark: AdjustmentsDark,
	AlarmDark: AlarmDark,
	ArrowCircleRightDark: ArrowCircleRightDark,
	ArrowCircleUpDark: ArrowCircleUpDark,
	ArrowDownDark: ArrowDownDark,
	ArrowLeftDark: ArrowLeftDark,
	ArrowRightDark: ArrowRightDark,
	ArrowUpDark: ArrowUpDark,
	BackspaceDark: BackspaceDark,
	BanDark: BanDark,
	BedDark: BedDark,
	BellDark: BellDark,
	BellAlertDark: BellAlertDark,
	BookmarkDark: BookmarkDark,
	BookOpenDark: BookOpenDark,
	CalendarDark: CalendarDark,
	CameraDark: CameraDark,
	CartDark: CartDark,
	ChartDark: ChartDark,
	ChatDark: ChatDark,
	Chat2Dark: Chat2Dark,
	CheckDark: CheckDark,
	CheckCircleDark: CheckCircleDark,
	ChevronDownDark: ChevronDownDark,
	ChevronLeftDark: ChevronLeftDark,
	ChevronRightDark: ChevronRightDark,
	ChevronUpDark: ChevronUpDark,
	ChillDark: ChillDark,
	ClockDark: ClockDark,
	CloseDark: CloseDark,
	CloseCircleDark: CloseCircleDark,
	CloudDownloadDark: CloudDownloadDark,
	CocktailDark: CocktailDark,
	CoffeeDark: CoffeeDark,
	CogDark: CogDark,
	CoolDark: CoolDark,
	CoverDark: CoverDark,
	CreditCardDark: CreditCardDark,
	CustomerServiceDark: CustomerServiceDark,
	DeliveryDark: DeliveryDark,
	DisturbanceDark: DisturbanceDark,
	DotsCircleDark: DotsCircleDark,
	DotsHorizontalDark: DotsHorizontalDark,
	DualTempDark: DualTempDark,
	EditDark: EditDark,
	Edit2Dark: Edit2Dark,
	EightBoxDark: EightBoxDark,
	ElevationDark: ElevationDark,
	ExclamationDark: ExclamationDark,
	ExerciseDark: ExerciseDark,
	ExternalLinkDark: ExternalLinkDark,
	EyeDark: EyeDark,
	FellAsleepDark: FellAsleepDark,
	FilterDark: FilterDark,
	GiftDark: GiftDark,
	GlobeDark: GlobeDark,
	HeartDark: HeartDark,
	HeartReportDark: HeartReportDark,
	HistoryDark: HistoryDark,
	HomeDark: HomeDark,
	HubDark: HubDark,
	InformationCircleDark: InformationCircleDark,
	LightBulbDark: LightBulbDark,
	LightningBoltDark: LightningBoltDark,
	LinkDark: LinkDark,
	LoaderDark: LoaderDark,
	LocalWeatherDark: LocalWeatherDark,
	LocationDark: LocationDark,
	LockClosedDark: LockClosedDark,
	LockOpenDark: LockOpenDark,
	LoginDark: LoginDark,
	LogoutDark: LogoutDark,
	LungDark: LungDark,
	MagicDark: MagicDark,
	MailDark: MailDark,
	MattressDark: MattressDark,
	MealDark: MealDark,
	MeditationDark: MeditationDark,
	MenuDark: MenuDark,
	MicrophoneDark: MicrophoneDark,
	MinusDark: MinusDark,
	MinusCircleDark: MinusCircleDark,
	MoonDark: MoonDark,
	MultiPodDark: MultiPodDark,
	MusicNoteDark: MusicNoteDark,
	NumberCircleDark: NumberCircleDark,
	OffDark: OffDark,
	PauseDark: PauseDark,
	PhoneDark: PhoneDark,
	PiggyBankDark: PiggyBankDark,
	PlayDark: PlayDark,
	PlusDark: PlusDark,
	PlusCircleDark: PlusCircleDark,
	PowerDark: PowerDark,
	PriceDark: PriceDark,
	PrimeDark: PrimeDark,
	QuestionCircleDark: QuestionCircleDark,
	ReadingDark: ReadingDark,
	ReferralDark: ReferralDark,
	RefreshDark: RefreshDark,
	RelaxingDark: RelaxingDark,
	ReplyDark: ReplyDark,
	SaveDark: SaveDark,
	SearchDark: SearchDark,
	SearchCircleDark: SearchCircleDark,
	SelectorDark: SelectorDark,
	ShareDark: ShareDark,
	ShieldCheckDark: ShieldCheckDark,
	ShoppingBagDark: ShoppingBagDark,
	SleepingDark: SleepingDark,
	SmartPhoneDark: SmartPhoneDark,
	SnoreMitigationDark: SnoreMitigationDark,
	SnoringDark: SnoringDark,
	SoundDark: SoundDark,
	SoundBarDark: SoundBarDark,
	SoundOffDark: SoundOffDark,
	StarDark: StarDark,
	SunDark: SunDark,
	Sun2Dark: Sun2Dark,
	TapDark: TapDark,
	TemperatureDark: TemperatureDark,
	ThumbDownDark: ThumbDownDark,
	ThumbUpDark: ThumbUpDark,
	TimeBedDark: TimeBedDark,
	TrashDark: TrashDark,
	TrendingUpDark: TrendingUpDark,
	TwoUsersDark: TwoUsersDark,
	UploadDark: UploadDark,
	UploadCloudDark: UploadCloudDark,
	UserDark: UserDark,
	VibrationDark: VibrationDark,
	VideoCameraDark: VideoCameraDark,
	WarmDark: WarmDark,
	WarrantyDark: WarrantyDark,
	WatchDark: WatchDark,
	WaterDark: WaterDark,
	WifiDark: WifiDark,
	WifiOffDark: WifiOffDark,
	ZoomInDark: ZoomInDark,
	ZoomOutDark: ZoomOutDark,

	// Light

	AdjustmentsLight: AdjustmentsLight,
	AlarmLight: AlarmLight,
	ArrowCircleRightLight: ArrowCircleRightLight,
	ArrowCircleUpLight: ArrowCircleUpLight,
	ArrowDownLight: ArrowDownLight,
	ArrowLeftLight: ArrowLeftLight,
	ArrowRightLight: ArrowRightLight,
	ArrowUpLight: ArrowUpLight,
	BackspaceLight: BackspaceLight,
	BanLight: BanLight,
	BedLight: BedLight,
	BellLight: BellLight,
	BookmarkLight: BookmarkLight,
	BookOpenLight: BookOpenLight,
	CalendarLight: CalendarLight,
	CameraLight: CameraLight,
	CartLight: CartLight,
	ChartLight: ChartLight,
	ChatLight: ChatLight,
	Chat2Light: Chat2Light,
	CheckLight: CheckLight,
	CheckCircleLight: CheckCircleLight,
	ChevronDownLight: ChevronDownLight,
	ChevronLeftLight: ChevronLeftLight,
	ChevronRightLight: ChevronRightLight,
	ChevronUpLight: ChevronUpLight,
	ChillLight: ChillLight,
	ClockLight: ClockLight,
	CloseLight: CloseLight,
	CloseCircleLight: CloseCircleLight,
	CloudDownloadLight: CloudDownloadLight,
	CocktailLight: CocktailLight,
	CoffeeLight: CoffeeLight,
	CogLight: CogLight,
	ComfortLight: ComfortLight,
	CoolLight: CoolLight,
	CoverLight: CoverLight,
	CreditCardLight: CreditCardLight,
	CustomerServiceLight: CustomerServiceLight,
	DeliveryLight: DeliveryLight,
	DisturbanceLight: DisturbanceLight,
	DotsCircleLight: DotsCircleLight,
	DotsHorizontalLight: DotsHorizontalLight,
	DualTempLight: DualTempLight,
	EditLight: EditLight,
	Edit2Light: Edit2Light,
	EightBoxLight: EightBoxLight,
	ElevationLight: ElevationLight,
	ExclamationLight: ExclamationLight,
	ExclamationCircleLight: ExclamationCircleLight,
	ExerciseLight: ExerciseLight,
	ExternalLinkLight: ExternalLinkLight,
	EyeLight: EyeLight,
	EyeNewOpenLight: EyeNewOpenLight,
	EyeNewSlashLight: EyeNewSlashLight,
	FellAsleepLight: FellAsleepLight,
	FilterLight: FilterLight,
	GenderLight: GenderLight,
	GiftLight: GiftLight,
	GlobeLight: GlobeLight,
	HeartLight: HeartLight,
	HeartReportLight: HeartReportLight,
	HistoryLight: HistoryLight,
	HomeLight: HomeLight,
	HubLight: HubLight,
	InformationCircleLight: InformationCircleLight,
	LightBulbLight: LightBulbLight,
	LightningBoltLight: LightningBoltLight,
	LinkLight: LinkLight,
	LoaderLight: LoaderLight,
	LocalWeatherLight: LocalWeatherLight,
	LocationLight: LocationLight,
	LockClosedLight: LockClosedLight,
	LockOpenLight: LockOpenLight,
	LoginLight: LoginLight,
	LogoutLight: LogoutLight,
	LungLight: LungLight,
	MagicLight: MagicLight,
	MailLight: MailLight,
	MattressLight: MattressLight,
	MealLight: MealLight,
	MeditationLight: MeditationLight,
	MenuLight: MenuLight,
	MicrophoneLight: MicrophoneLight,
	MinusLight: MinusLight,
	MinusCircleLight: MinusCircleLight,
	MoonLight: MoonLight,
	MultiPodLight: MultiPodLight,
	MusicNoteLight: MusicNoteLight,
	NumberCircleLight: NumberCircleLight,
	OffLight: OffLight,
	PauseLight: PauseLight,
	PhoneLight: PhoneLight,
	PiggyBankLight: PiggyBankLight,
	PlayLight: PlayLight,
	PlusLight: PlusLight,
	PlusCircleLight: PlusCircleLight,
	PowerLight: PowerLight,
	PriceLight: PriceLight,
	PrimeLight: PrimeLight,
	ReadingLight: ReadingLight,
	ReferralLight: ReferralLight,
	RefreshLight: RefreshLight,
	RelaxingLight: RelaxingLight,
	ReplyLight: ReplyLight,
	SaveLight: SaveLight,
	SearchLight: SearchLight,
	SearchCircleLight: SearchCircleLight,
	SelectorLight: SelectorLight,
	ShareLight: ShareLight,
	ShieldCheckLight: ShieldCheckLight,
	ShoppingBagLight: ShoppingBagLight,
	SleepingLight: SleepingLight,
	SmartPhoneLight: SmartPhoneLight,
	SnoreMitigationLight: SnoreMitigationLight,
	SnoringLight: SnoringLight,
	SoundLight: SoundLight,
	SoundBarLight: SoundBarLight,
	SoundOffLight: SoundOffLight,
	StarLight: StarLight,
	SunLight: SunLight,
	Sun2Light: Sun2Light,
	SurfaceLight: SurfaceLight,
	TapLight: TapLight,
	TemperatureLight: TemperatureLight,
	ThumbDownLight: ThumbDownLight,
	ThumbUpLight: ThumbUpLight,
	TimeBedLight: TimeBedLight,
	TrashLight: TrashLight,
	TrendingUpLight: TrendingUpLight,
	TwoUsersLight: TwoUsersLight,
	UploadLight: UploadLight,
	UploadCloudLight: UploadCloudLight,
	UserLight: UserLight,
	VibrationLight: VibrationLight,
	VideoCameraLight: VideoCameraLight,
	WarmLight: WarmLight,
	WarrantyLight: WarrantyLight,
	WatchLight: WatchLight,
	WaterLight: WaterLight,
	WifiLight: WifiLight,
	WifiOffLight: WifiOffLight,
	ZoomInLight: ZoomInLight,
	ZoomOutLight: ZoomOutLight,

	SleepScore: SleepScore,
	AlarmCustom: AlarmCustom,
	BarCustom: BarCustom,
	CalendarCustom: CalendarCustom,
	ComfortCustom: ComfortCustom,
	ClockCustom: ClockCustom,
	GenderCustom: GenderCustom,
	HomeCustom: HomeCustom,
	TempCustom: TempCustom,
	WeatherCustom: WeatherCustom,
	TapCustom: TapCustom,
	BookCustom: BookCustom,
	SnoreMitigationCustom: SnoreMitigationCustom,
}

export type IconName = keyof typeof icons
