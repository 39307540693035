import { FC, useEffect, useRef } from 'react'
import styles from './Hero.module.scss'
import { HeroProps } from './Hero.types'
import { Type } from 'components/Type'
import { Button } from 'components/Phantom/Button'
import { Img } from 'components/basic/Img'
import { createNoise2D } from 'simplex-noise'
import { Clock } from 'three'

/**
 * Generates a random state for the simplex noise generator. The offsets here aren't super important, it's
 * just to make each element start at a different position in the noise field. 2000 is an arbitrary number.
 * @param element The element to generate the state for
 */
const generateRandomState = (element: HTMLElement) => {
	return {
		xOffset: Math.round(Math.random() * 2000),
		yOffset: Math.round(Math.random() * 2000),
		element,
	}
}

export const Hero: FC<HeroProps> = (props) => {
	const { headline = 'Great sleep starts before bedtime' } = props
	const containerRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const bfcm_hub = document.getElementById('bfcm_hub')
		if (!bfcm_hub) return () => {}

		const noise = createNoise2D()
		const SCALE = 30

		const state = generateRandomState(bfcm_hub)

		let animationId: number

		const clock = new Clock()

		const animate = () => {
			animationId = requestAnimationFrame(animate)

			const time = clock.getElapsedTime()

			const xNoise = noise(state.xOffset, time * 0.1)
			const yNoise = noise(state.yOffset, time * 0.1)

			const rotationalNoise = noise(state.xOffset + state.yOffset, time * 0.1)

			state.element.style.setProperty('--gift-x', `${xNoise * SCALE}px`)
			state.element.style.setProperty('--gift-y', `${yNoise * SCALE}px`)
			state.element.style.setProperty('--gift-rotation', `${rotationalNoise * 2}deg`)
		}
		animationId = requestAnimationFrame(animate)

		return () => {
			cancelAnimationFrame(animationId)
		}
	})

	return (
		<section
			className={styles.container}
			ref={containerRef}
		>
			<header>
				<Type.Headline1 className={styles.headline}>{headline}</Type.Headline1>
				<Button.White
					id={'hero-cta'}
					className={styles.cta}
					href={'#waitlist-signup'}
				>
					Join the waitlist
				</Button.White>
				<div
					id="bfcm_hub_outer"
					className={styles.image_wrapper}
				>
					<Img
						className={styles.product_image}
						src="https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-waitlist/elixir-header-v5.png"
						alt="Sleep Elixir Product"
						id="bfcm_hub"
					/>
				</div>
			</header>
		</section>
	)
}
