import { datadogRum } from '@datadog/browser-rum'
import { client, v1 } from '@datadog/datadog-api-client'
import { EventsApi } from '@datadog/datadog-api-client/dist/packages/datadog-api-client-v1'

class DataDogManager {
	private client: EventsApi

	constructor() {
		const configurationOpts = {
			authMethods: {
				apiKeyAuth: process.env.DATADOG_KEY,
			},
			enableRetry: true,
		}

		const configuration = client.createConfiguration(configurationOpts)

		this.client = new v1.EventsApi(configuration)
	}

	public async logEvent(key: string, title: string, text: string, tags?: string[], eventType?: string) {
		try {
			let t = ['eightsleep-vercel']
			if (tags && tags.length) {
				t = [...t, ...tags]
			}
			const params: v1.EventsApiCreateEventRequest = {
				body: {
					sourceTypeName: 'node',
					title: title,
					text: text,
					tags: t,
				},
			}
			if (key) {
				params.body.aggregationKey = key
			}
			if (eventType) {
				params.body.additionalProperties = {
					event_type: eventType,
				}
			}
			const response = await this.client.createEvent(params)
			return response
		} catch (e) {
			console.log('failed to log event')
			return undefined
		}
	}
}

export const datadogEventClient = new DataDogManager()

export const datadogReportEvent = (status: 'success' | 'failure', eventName: string, eventProperties: any = null) => {
	try {
		datadogRum.addAction(eventName, {
			...eventProperties,
			status: status,
		})
	} catch (err) {
		datadogRum.addError(err)
	}
}

export const datadogReportAPICall = (statusCode: number, eventName: string, eventProperties: any = null) => {
	try {
		datadogRum.addAction(eventName, {
			...eventProperties,
			status: statusCode,
		})
	} catch (err) {
		datadogRum.addError(err)
	}
}

export const datadogReportError = (err: Error, eventName: string, eventProperties: any = null) => {
	try {
		datadogRum.addError(err, {
			...eventProperties,
			eventName: eventName,
		})
	} catch (err) {
		datadogRum.addError(err)
	}
}
