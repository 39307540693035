import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import styles from './Rituals.module.scss'
import { RitualsSliderProps, RitualsSliderHandle } from './Rituals.types'
import { useSwipe } from 'components/_hooks/useSwipe'
import { clamp } from 'components/_utils/mathUtils'
import { amChangeSlideImageCarousel, amSwipeImageCarousel } from 'events/amplitude'
import { Type } from 'components/Type'
import useIsMobile from 'hooks/useIsMobile'
import { Img } from 'components/basic/Img'
import { Thumbnails } from 'components/ImageCarousel/subcomponents/Thumbnails'

const ITEMS: RitualItemProps[] = [
	{
		text: 'Calmer mind and relaxation',
		label: 'bedtime',
		subtitle: 'Relaxes your mind and body, setting the stage for rest. Valerian root promotes relaxation, while Ashwagandha eases stress by lowering cortisol levels, helping you unwind effortlessly.',
		img: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/rituals-card1.png',
	},
	{
		text: 'Improved sleep cycles',
		label: 'nighttime',
		subtitle:
			'Support your body’s natural rhythms for deeper, restorative rest. Tart cherry fuels melatonin production, while L-Tryptophan regulates sleep cycles for uninterrupted, high-quality sleep.',
		img: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/rituals-card2.png',
	},
	{
		text: 'Energized days',
		label: 'morning',
		subtitle: 'Wake refreshed and energized, ready to take on the day. Sleep Elixir ensures restorative sleep without grogginess or dependency.',
		img: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/rituals-card3.png',
	},
]

interface RitualItemProps {
	text: string
	label: string
	subtitle: string
	img: string
}

export const Rituals = () => {
	const isMobile = useIsMobile(1023)

	return (
		<section className={styles.container}>
			<div className={styles.header_container}>
				<header className={styles.header}>
					<Type.Eyebrow className={styles.eyebrow}>Designed for nightly use</Type.Eyebrow>
					<Type.Headline2>The bedtime ritual for calmer nights and better mornings</Type.Headline2>
					<Type.Headchapter className={styles.subheader}>Take 1-3 capsules, 60 minutes before bed every night, to prepare your body for optimal rest</Type.Headchapter>
				</header>
			</div>
			{isMobile ? <ReviewsMobile startIndex={0} /> : <ReviewsDesktop />}
		</section>
	)
}

export const ReviewsDesktop = () => {
	return (
		<div className={styles.grid}>
			{ITEMS.map((item, index) => (
				<ReviewItem
					key={index}
					item={item}
				/>
			))}
		</div>
	)
}

export const ReviewsMobile = forwardRef<RitualsSliderHandle, RitualsSliderProps>((props, ref) => {
	const { startIndex } = props
	const items = ITEMS

	const sliderRef = useRef<HTMLUListElement>(null)

	const [currentIndex, setCurrentIndex] = useState(startIndex)

	useSwipe(
		sliderRef,
		(direction: 'left' | 'right') => {
			const length = items.length
			amSwipeImageCarousel(direction, props.id)
			if (direction === 'left') {
				setCurrentIndex((prev) => Math.min(length - 1, prev + 1))
			} else if (direction === 'right') {
				setCurrentIndex((prev) => Math.max(0, prev - 1))
			}
		},
		50,
		500,
		[]
	)

	useImperativeHandle(ref, () => ({
		goToSlide: (index: number) => {
			setCurrentIndex(index)
			amChangeSlideImageCarousel(props.id, index)
		},
	}))

	useEffect(() => {
		setCurrentIndex(startIndex)
		amChangeSlideImageCarousel(props.id, startIndex)
	}, [startIndex, props.id])

	const clampedIndex = clamp(currentIndex, 0, items.length - 1)

	return (
		<div className={styles.slider}>
			<div className={styles.nav}>
				<Thumbnails
					currentIndex={clampedIndex}
					setCurrentIndex={setCurrentIndex}
					useDots={true}
					itemsLength={items.length}
					items={items.map((it) => ({
						data: {
							src: it.img,
							alt: '',
						},
						type: 'image',
					}))}
				/>
				<div className={styles.nav_buttons_wrapper}>
					<button
						disabled={clampedIndex === 0}
						style={{ opacity: clampedIndex === 0 ? 0.3 : 1 }}
						className={`${styles.nav_button} ${styles.prev_slide}`}
						onClick={() => {
							setCurrentIndex(clampedIndex - 1)
							amChangeSlideImageCarousel(props.id, clampedIndex - 1)
						}}
					/>

					<button
						disabled={clampedIndex === items.length - 1}
						style={{ opacity: clampedIndex === items.length - 1 ? 0.3 : 1 }}
						className={`${styles.nav_button} ${styles.next_slide}`}
						onClick={() => {
							setCurrentIndex(clampedIndex + 1)
							amChangeSlideImageCarousel(props.id, clampedIndex + 1)
						}}
					/>
				</div>
			</div>
			<div className={styles.slider_wrapper}>
				<ul
					className={styles.media_stack}
					ref={sliderRef}
				>
					{items.map((item, index) => (
						<li
							key={`${index}`}
							className={styles.media_item}
							style={{
								transform: `translateX(calc(${(index - clampedIndex) * 100}%))`,
								opacity: index === clampedIndex ? 1 : 0.3,
							}}
							onClick={() => setCurrentIndex(index)}
						>
							<ReviewItem item={item} />
						</li>
					))}
				</ul>
			</div>
		</div>
	)
})

export const ReviewItem = ({ item }: { item: RitualItemProps }) => {
	const { text, subtitle } = item

	return (
		<div className={styles.review_item}>
			<Img
				src={item.img}
				alt={''}
				className={styles.review_item_img}
			/>
			<div className={styles.review_item_text}>
				<Type.Headline4>{text}</Type.Headline4>
				<Type.Body2 className={styles.review_item_subtitle}>{subtitle}</Type.Body2>
			</div>
		</div>
	)
}
