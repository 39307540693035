import { FC } from 'react'
import { ThumbnailsProps } from 'components/ImageCarousel/ImageCarousel.types'
import useIsMobile from 'hooks/useIsMobile'
import styles from './NavigationButtons.module.scss'

export const NavigationButtons: FC<ThumbnailsProps> = (props) => {
	const isMobile = useIsMobile(600)
	// if (isMobile) return null

	const { itemsLength, currentIndex, setCurrentIndex } = props
	return (
		<>
			{currentIndex > 0 && (
				<button
					className={`${styles.nav_button} ${styles.prev_slide}`}
					onClick={() => setCurrentIndex(currentIndex - 1)}
				/>
			)}
			{currentIndex < itemsLength - 1 && (
				<button
					className={`${styles.nav_button} ${styles.next_slide}`}
					onClick={() => setCurrentIndex(currentIndex + 1)}
				/>
			)}
		</>
	)
}
