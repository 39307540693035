import { FC, useRef, useState } from 'react'
import styles from './SleepFitness.module.scss'
import { SleepFitnessProps } from './SleepFitness.types'
import { Type } from 'components/Type'
import { Img } from 'components/basic/Img'
import { CountUp } from 'components/CountUp'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { useGSAP } from '@gsap/react'

export const SleepFitness: FC<SleepFitnessProps> = (props) => {
	const {} = props
	const cardRef = useRef(null)
	const [timeline, setTimeline] = useState<gsap.core.Timeline | null>(null)

	useGSAP(
		() => {
			gsap.registerPlugin(ScrollTrigger)
			if (!timeline) return

			const tl = gsap.timeline({
				ease: 'none',
				scrollTrigger: {
					trigger: cardRef.current,
					start: `0 95%`,
					end: '+=150px',
					toggleActions: 'restart none none reverse',
				},
			})

			tl.fromTo(
				cardRef.current,
				{
					opacity: 0,
					y: 100,
				},
				{
					opacity: 1,
					y: 0,
					duration: 1,
				},
				0
			)
			tl.add(timeline, 0)
		},
		{
			dependencies: [timeline],
			revertOnUpdate: true,
		}
	)

	const handleCountUpTimelineReady = (timeline: gsap.core.Timeline) => {
		setTimeline(timeline)
	}

	return (
		<section className={styles.container}>
			<Img
				src="https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-waitlist/sleepfitness-desktop.jpg"
				alt="Runners"
				className={styles.image}
				objectFit="cover"
				sources={[
					{
						mediaQuery: '(max-width: 1023px)',
						src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-waitlist/sleep-fitness-mobile-v5.png',
						dprHeight: 1000,
					},
					{
						mediaQuery: '(min-width: 1023px)',
						src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-waitlist/sleepfitness-desktop.jpg',
						dprHeight: 1200,
					},
				]}
			/>
			<div className={styles.content}>
				<header>
					<Type.Headline2 animateOnScroll>Tackling sleep fitness from the inside out</Type.Headline2>
					<Type.Body1 animateOnScroll>We revolutionized sleep with a technology used by thousands of people to improve their sleep and recovery.</Type.Body1>
					<Type.Body1 animateOnScroll>With Sleep Elixir, we are bringing you one step closer to your best-ever sleep.</Type.Body1>
				</header>
			</div>
			<div
				className={styles.widget}
				ref={cardRef}
			>
				<Img
					src="https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-waitlist/Group+2607385-v2.png"
					alt=""
				/>
				<CountUp
					className={styles.countup}
					startValue={60}
					endValue={100}
					repeat={false}
					ease="none"
					trigger={true}
					duration={1.5}
					animationStyle="in-place"
					onTimelineReady={handleCountUpTimelineReady}
				/>
				<div className={styles.widget_text}>
					<span className={styles.green}>Good</span>
					<span className={styles.desc}>Sleep fitness score</span>
				</div>
			</div>
		</section>
	)
}
