import { FC } from 'react'
import styles from './Footer.module.scss'
import { FooterProps } from './Footer.types'
import { Type } from 'components/Type'
import { Button } from 'components/Phantom/Button'
import { Img } from 'components/basic/Img'

export const Footer: FC<FooterProps> = (props) => {
	const {} = props
	return (
		<section className={styles.container}>
			<div className={styles.inset_container}>
				<div className={styles.header_wrapper}>
					<header className={styles.header}>
						<Type.Headline4
							as={'h2'}
							animateOnScroll
						>
							Join the waitlist today and take the next step in your sleep fitness journey
						</Type.Headline4>
						<Button.White
							id={'footer-cta'}
							className={styles.cta}
							href={'#waitlist-signup'}
							disableSmoothScroll={true}
						>
							Join the waitlist
						</Button.White>
					</header>
				</div>

				<Img
					src="https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-waitlist/waitlist-reminder-new-jar.png"
					alt="Sleep Elixir Jar"
					className={styles.jar}
				/>
			</div>
		</section>
	)
}
