import { VisualProps } from 'components/Phantom/_shop/Visuals'

export const getTheBaseVisuals = (): VisualProps => {
	return {
		type: 'new_carousel',
		associatedSelectorKeys: ['the-base'],
		data: [
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Base+%E2%80%93+Wide-v21.png',
					alt: 'The Base product image',
				},
				associatedSelections: [],
			},
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Pod+4+Ultra+Product+Breakdown+%E2%80%93+Wide-v20.png',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Pod+4+Ultra+Product+Breakdown+%E2%80%93+Mobile-v19.png',
					alt: 'Pod 4 Ultra stack',
				},
				associatedSelections: [],
			},
			{
				type: 'video-gif',
				data: {
					src: 'https://d115sb6i1ixllw.cloudfront.net/assets/Ultra-Slide6-Mobile-Oct24.mp4',
					mobileSrc: 'https://d115sb6i1ixllw.cloudfront.net/assets/Ultra-Slide6-Mobile-Oct24.mp4',
					poster: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/elevation-placeholder-thumbnail-v19.jpg',
				},
				associatedSelections: [],
			},
			{
				type: 'video-gif',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/base-shop-page-reading.mp4',
					poster: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/base-shop-page-reading.png',
				},
				associatedSelections: [],
			},
		],
	}
}
