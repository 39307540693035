import * as eventNames from './eventNames'

const BREAKPOINT = 1024

declare global {
	interface Window {
		__attentive?: any
	}
}

export interface TimerTreshold {
	mobile: number
	desktop: number
}

// TODO, clean up this array so that it doesn't include unnecessary redundancies.
//  pathname should return everything (including the trailing slash) after and including the / after eightsleep.com
//  i.e, if we're on https://www.eightsleep.com/travel/, window.location.pathname === '/travel/' or '/travel'
const excludedPages = [
	'/checkout/contact',
	'/checkout/payment',
	'/checkout/contact/',
	'/checkout/payment/',
	'/referral',
	'/referral/',
	'/international',
	'/international/',
	'/member-accessories',
	'/member-accessories/',
	'/manuals',
	'/manuals/',
	'/accessibility',
	'/accessibility/',
	'/return-policy',
	'/return-policy/',
	'/warranty',
	'/warranty/',
	'/press',
	'/press/',
	'/product/premium-mattress',
	'/product/premium-mattress/',
	'referral',
	'referral/',
	'/referral/',
	'/referral',
	'member-referrals',
	'member-referrals/',
	'/member-referrals/',
	'/member-referrals',
	'brooke',
	'brooke/',
	'/brooke/',
	'/brooke',
	'/product',
]

export default class ModalEvent {
	hasBeenTriggered: boolean
	canBeTriggered: boolean
	timers: TimerTreshold

	constructor() {
		this.hasBeenTriggered = false
		this.canBeTriggered = false
		this.timers = { mobile: 2, desktop: 2 }
	}

	run = (pagesVisited: any) => {
		if (this.hasBeenTriggered) {
			this.canBeTriggered = false
			return
		}
		this.updateCanTrigger(pagesVisited)

		if (this.canBeTriggered) {
			this.action()
		}
	}

	updateCanTrigger = (pagesVisited): boolean => {
		const currentPage = window.location.pathname
		const onShopPage = currentPage.includes('product/')
		const onCheckoutPage = currentPage.includes('/checkout/')
		const done = this.getHasBeenTriggered() || sessionStorage.getItem(eventNames.emailCapModalTriggerDone) || localStorage.getItem('email')
		if (done) {
			return false
		}

		const reffrb = sessionStorage.getItem('appliedDiscountCode') === 'REFFRB'

		if (reffrb) {
			return false
		}

		if (
			localStorage.getItem('confirmedRegion') == null &&
			sessionStorage.getItem('automated') !== 'true' &&
			(currentPage.startsWith('/uk/') ||
				currentPage.startsWith('/ca/') ||
				currentPage.startsWith('/au/') ||
				currentPage.startsWith('/se/') ||
				currentPage.startsWith('/dk/') ||
				currentPage.startsWith('/ae/') ||
				currentPage.startsWith('/eu/'))
		) {
			return false
		}

		const _pages = Object.keys(pagesVisited) // loop through ALL pages

		const firstVisitEpoch = parseInt(localStorage.getItem('firstVisitEpoch'))
		const TEN_MINUTES = 10 * 60 * 1000
		const isFirstTimeVisitor = Date.now() - firstVisitEpoch < TEN_MINUTES
		let viewingExcludedPage = excludedPages.includes(currentPage) || onShopPage || onCheckoutPage
		if (isFirstTimeVisitor && onShopPage) {
			viewingExcludedPage = false
		}

		const hasVisitedMemberAccessories = _pages.some((page) => page.includes('member-accessories'))
		const hasVisitedPodProUpgradeDiscounted = _pages.some(
			(page) => page.includes('eba234937b8d54e2d519b318707ea9713e6087b402b40670319c4c1e166e9208') || page.includes('dce234937b8d54e2r417b318707ea9713e6087b402b40670319c4c1e166e9101')
		)
		const isAppVisitor = typeof window !== 'undefined' && window && window.location.href.includes('utm_source=app')
		const isEmail = typeof window !== 'undefined' && window && window.location.href.includes('utm_source=klaviyo')
		const isSMS = typeof window !== 'undefined' && window && window.location.href.includes('utm_medium=sms')

		if (hasVisitedMemberAccessories || hasVisitedPodProUpgradeDiscounted || viewingExcludedPage || isAppVisitor || isEmail || isSMS) {
			this.hasBeenTriggered = true
		}

		if (!this.hasBeenTriggered) {
			this.canBeTriggered = true
		}

		return this.canBeTriggered
	}

	action = () => {
		const emailAvailable = !!localStorage.getItem('email')
		if (!this.hasBeenTriggered && !emailAvailable) {
			window.dispatchEvent(new Event(eventNames.emailCapModalTrigger))
			this.setTriggered()
		}
	}

	setTriggered = () => {
		if (sessionStorage.getItem('block-modals') === 'true') {
			return
		}

		this.hasBeenTriggered = true
		this.canBeTriggered = false

		try {
			sessionStorage.setItem(eventNames.emailCapModalTriggerDone, JSON.stringify(Date.now()))
			sessionStorage.setItem(eventNames.lastEventTime, JSON.stringify(Date.now()))
		} catch (e) {
			console.log(e)
		}
	}

	getHasBeenTriggered = () => {
		const sessionItem = sessionStorage.getItem(eventNames.emailCapModalTriggerDone)
		const alreadyTriggered = (sessionItem && sessionItem.length) || this.hasBeenTriggered || sessionStorage.getItem(eventNames.referralWelcomeTriggerDone)
		return alreadyTriggered
	}
}
