import { createClient, SanityClient } from '@sanity/client'
import { Pod4FAQProps } from 'components/Pod4FAQ/types'

let SANITY_CLIENT: SanityClient

export const getSanityClient = () => {
	if (!SANITY_CLIENT) {
		SANITY_CLIENT = createClient({
			projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID || '84jk6xzf',
			dataset: process.env.NEXT_PUBLIC_SANITY_DATASET || 'production',
			apiVersion: '2025-01-16',
			useCdn: true,
		})
	}
	return SANITY_CLIENT
}

export const getFAQByDocumentTitle = async (documentTitle: string): Promise<Pod4FAQProps> => {
	try {
		const query = `*[_type == "faq" && documentTitle == $documentTitle][0]{title,items[]{question,answer,filters}}`

		const result = await getSanityClient().fetch(query, { documentTitle })
		if (result) {
			return {
				title: result.title,
				faq: result.items,
			}
		} else {
			return {
				faq: [],
			}
		}
	} catch (error) {
		console.error('Error fetching FAQ data:', error)
		return {
			faq: [],
		}
	}
}

export interface SanityFilter {
	regions?: string[]
	isMember?: boolean
}

export const passesFilter = (filter: SanityFilter, region: string, isMember: boolean) => {
	if (!filter) return true
	if (filter.regions && !filter.regions.includes(region)) return false
	if (filter.isMember && !isMember) return false
	return true
}
