import { FC } from 'react'
import styles from './WaitlistSignup.module.scss'
import { WaitlistSignupProps } from './WaitlistSignup.types'
import { Type } from 'components/Type'
import { ClientOnly } from 'components/ClientOnly'
import Script from 'next/script'
import cx from 'classnames'

const WaitlistComponent = () => {
	const cssLoader = `
		let head = document.getElementsByTagName('HEAD')[0];
		let link = document.createElement('link');
		link.rel = 'stylesheet';
		link.type = 'text/css';
		link.href = 'https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.css';
		head.appendChild(link);
	`

	return (
		<>
			<Script
				type={''}
				dangerouslySetInnerHTML={{ __html: cssLoader }}
				id={'getWaitlistCssLoader'}
			/>
			<Script
				src="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.js"
				id={'getWaitlistScript'}
			/>
			<div
				id="getWaitlistContainer"
				data-waitlist_id="20053"
				data-widget_type="WIDGET_1"
			/>
		</>
	)
}

export const WaitlistSignup: FC<WaitlistSignupProps> = (props) => {
	const {} = props
	return (
		<section
			className={styles.container}
			id="waitlist-signup"
		>
			<div className={styles.content}>
				<div className={styles.signup_container}>
					<Type.Headline3 as={'h2'}>Get your dose of Sleep Elixir</Type.Headline3>
					<Type.Body1 className={styles.bodytext}>Available to order soon. Secure your spot on the waitlist today. Refer friends after signing up to move up in the waitlist priority.</Type.Body1>
					<Type.Body1 className={cx(styles.bodytext)}>Starting at $59/month</Type.Body1>
					<div className={styles.giveaway_container}>
						<ClientOnly>
							<WaitlistComponent />
						</ClientOnly>
					</div>
					{/* <Type.Body1 className={cx(styles.bodytext, styles.centertext)}>Joined the waitlist before? Get your referral link</Type.Body1> */}
				</div>
			</div>
		</section>
	)
}
