import { StackItemProps } from 'components/Phantom/_shop/Visuals'
import { create, StoreApi, UseBoundStore } from 'zustand'

export interface VariantData {
	key: string
	id: string
	name: string
	price: number
	comparePrice: number
	priceString: string
	comparePriceString: string
	saveText?: string
	badge?: string
	description?: string
	checklist?: string[]
	disabled?: boolean
}
export interface ShopStoreData {
	selectedVariant: string
	handle: string
	productTitle: string
	productDescription: string
	productId: string
	variants: VariantData[]
	tabs: {
		title: string
		content: string
	}[]
	features: any[]
	visuals: StackItemProps[]
}

export interface ShopStoreState {
	data: ShopStoreData
	selectedVariant: string
	setData: (newData: any) => void
	changeSelectedVariant: (newVariant: string) => void
}

let store: UseBoundStore<StoreApi<ShopStoreState>>

const initializeShopStore = (preloadedState: Partial<ShopStoreState> = {}) => {
	const initialState = {} as ShopStoreState
	if (preloadedState?.data) {
		initialState.data = preloadedState.data
		initialState.selectedVariant = preloadedState.data.selectedVariant
	}
	return create<ShopStoreState>((set) => ({
		selectedVariant: 'monthly',
		setData: (newData) => set(() => ({ data: newData })),
		changeSelectedVariant: (newVariant: string) => set(() => ({ selectedVariant: newVariant })),
		...initialState,
	}))
}

const useStore = (initialState?: Partial<ShopStoreState>) => {
	const isServer = typeof window === 'undefined'
	if (isServer) {
		return initializeShopStore(initialState)
	}

	if (!store) {
		store = initializeShopStore(initialState)
	}
	return store
}

// Create a selector hook
export const useShopStore = <T>(selector: (state: ShopStoreState) => T, initialState?: Partial<ShopStoreState>): T => {
	const store = useStore(initialState)
	return store(selector)
}

export default useShopStore
