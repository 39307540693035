import React, { FC } from 'react'
import styles from './LoadingIcon.module.scss'
import cx from 'classnames'

/*
	For accessbility should have aria-live="polite|assertive" and busy="true|false" 
	on whatever parent uses this component;
*/

export interface LoadingIconProps {
	isLoading: boolean
	width?: string
	stroke?: string
	bigColor?: string
	smallColor?: string
	speed?: string
	accessibilityTextLoading?: string
	accessibilityTextNotLoading?: string
}

export const LoadingCircle: FC<LoadingIconProps> = (props) => {
	const {
		stroke = '2px',
		width = '10px',
		bigColor = '#ddd',
		smallColor = '#F00',
		speed = '2s',
		accessibilityTextLoading = 'Content is loading.',
		accessibilityTextNotLoading = 'Content has loaded.',
		isLoading = false,
	} = props

	const text = isLoading ? accessibilityTextLoading : accessibilityTextNotLoading
	return (
		<div
			role="alert"
			aria-live="assertive"
			className={cx(styles.c_loading, { loading: isLoading })}
			style={{
				['--stroke' as any]: stroke,
				['--width' as any]: width,
				['--big-color' as any]: bigColor,
				['--small-color' as any]: smallColor,
				['--speed' as any]: speed,
			}}
		>
			<p className="vh">{text}</p>
		</div>
	)
}
