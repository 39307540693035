export const getBrowserIdentity = () => {
	// taken from https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
	try {
		// Opera 8.0+
		const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0
		if (isOpera) {
			return 'Opera'
		}

		// Firefox 1.0+
		const isFirefox = typeof InstallTrigger !== 'undefined'
		if (isFirefox) {
			return 'Firefox'
		}

		// Safari 3.0+ "[object HTMLElementConstructor]"
		const isSafari =
			/constructor/i.test(window.HTMLElement) ||
			(function (p) {
				return p.toString() === '[object SafariRemoteNotification]'
			})(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification))
		if (isSafari) {
			return 'Safari'
		}

		// Internet Explorer 6-11
		const isIE = /*@cc_on!@*/ false || !!document.documentMode
		if (isIE) {
			return 'Internet Explorer'
		}

		// Edge 20+
		const isEdge = !isIE && !!window.StyleMedia
		if (isEdge) {
			return 'Edge'
		}

		// Chrome 1 - 71
		const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
		if (isChrome) {
			return 'Chrome'
		}

		// Edge (based on chromium) detection
		const isEdgeChromium = isChrome && navigator.userAgent.indexOf('Edg') != -1
		if (isEdgeChromium) {
			return 'Edge Chromium'
		}

		// Blink engine detection
		const isBlink = (isChrome || isOpera) && !!window.CSS
		if (isBlink) {
			return 'Blink'
		}

		return 'Other'
	} catch (e) {
		console.log('Error while trying to find browser: ', e)
	}
}

export const getUUID = () => {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16))
}
