import { FC, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import cx from 'classnames'
import { trackAddThermoCoverToCart, trackAddToCart, trackClickedButton } from 'events/index'
import { fbViewContent } from 'events/facebook'
import { Attribute, CartLineItem, LineItem } from 'cart/types'
import { ProductFeaturesData, ProductShopDataType } from 'products/types'
import { ShopMain } from 'sections/Shop/ShopMainB'
import SettingsStore from 'settings/store'
import { amSelectDropdown, amSelectedMembership, amSelectedProduct, amSelectedVariant } from 'events/amplitude'
import { createCartLineItem, isPodBedProductId, isPodCoverProductId, OFFERED_MEMBERSHIPS } from 'products/utils'
import { MattressProducts, MembershipProducts } from 'products/allProducts'
import { PriceStore } from 'prices'
import { NewCartStore } from 'cart'
import { RootStore } from 'stores'
import { SKIP_MEMBERSHIP_CHECKOUT_KEY } from 'constants/index'
import { getConnectedModal, ModalKey } from 'components/Phantom/_shop/Modals/modalMapping'
import { Modal } from 'components/Phantom/_shop/Modals/Modal'
import { usePrevious } from 'components/_hooks/usePrevious'
import { LoadingSpinner } from 'components/WebEv/Shop/HelperComponents/LoadingSpinner/LoadingSpinner'
import { replaceMetricInProduct } from 'utils/internationalization'

function sendTrackingEvents(item: CartLineItem, buttonID: string) {
	trackAddToCart(item)
	trackClickedButton('Add to cart', buttonID)
	const coverProduct = item.productId && isPodCoverProductId(item.productId)
	if (coverProduct) {
		trackAddThermoCoverToCart()
	}
}

const getIpLocations = ({ isCanada, isUK, isEULegacy, isAU, isAE }: SettingsStore): Pick<SettingsStore, 'isCanada' | 'isUK' | 'isEULegacy' | 'isAU' | 'isAE'> => {
	return { isCanada, isUK, isEULegacy, isAU, isAE }
}

export interface ShopWrapperProps extends ProductShopDataType {
	settingsStore: SettingsStore
	priceStore: PriceStore
	cartStoreNew: NewCartStore
	path: string
	rootStore?: RootStore
	currentModal?: ModalKey
}

export const ShopWrapper: FC<ShopWrapperProps> = (props) => {
	const router = useRouter()
	const { settingsStore, priceStore, cartStoreNew, rootStore } = props
	const products = props.products.map((p) => priceStore.withPrices(p))
	const [selectedProduct, setSelectedProduct] = useState(props.defaultProductSelected)
	const [dropdownOptions, setDropdownOptions] = useState(0)
	const currentProduct = replaceMetricInProduct(products[selectedProduct] ?? products[0], settingsStore.currentRegion)

	// in order for this to work, the variants in Stripe MUST be called us | eu | au | etc.
	const singleOptionKey = settingsStore.currentRegion in currentProduct.variants ? settingsStore.currentRegion : Object.keys(currentProduct.variants)[0]
	const legkitUpsell = rootStore.shopStore.getLegKitUpsellData()
	const { currentModal } = props
	const prevMod = usePrevious(currentModal)
	let defaultVariant = singleOptionKey
	if (props.dropdownItems.length) {
		const dv = props.dropdownItems[dropdownOptions].defaultVariantSelected
		defaultVariant = props.dropdownItems[dropdownOptions].items.find((it) => it.value === dv)?.value ?? props.dropdownItems[dropdownOptions].items[0].value
	} else if (props.multiVariantItems?.length) {
		defaultVariant = `${props.multiVariantItems[0].defaultVariantSelected}-${props.multiVariantItems[1].defaultVariantSelected}`
	}

	const [selectedVariant, setSelectedVariant] = useState(defaultVariant)
	const [selectedMembership, setSelectedMembership] = useState(1)
	const [hasOverridenMembership, setHasOverridenMembership] = useState(false)

	const [selectedSize, setSelectedSize] = useState<undefined | string>(undefined)
	const [noSizeError, setNoSizeError] = useState(false)
	const [addMattress, setAddMattress] = useState(false)
	const [addLegkit, setAddLegkit] = useState('none')
	const [loading, setLoading] = useState(router.asPath.indexOf('/pod4-insert/') !== -1)
	useEffect(() => {
		if (hasOverridenMembership) {
			return
		}
		const i = cartStoreNew.getIndexOfCurrentMembership()
		setSelectedMembership(i > -1 ? i : 1)
	}, [hasOverridenMembership, cartStoreNew])

	const productImages = props.images[selectedProduct] ?? props.images[0]
	const variantImages = productImages.filter((it) => it.variant && it.variant === selectedVariant)
	const images = variantImages.length ? variantImages : productImages
	const features: ProductFeaturesData = props.features ? props.features[selectedProduct] || props.features[0] : { items: [], title: '' }

	const currentDropdownOptions = props.dropdownItems ? props.dropdownItems[dropdownOptions] : null

	const showSubscriptionPlans = typeof window === 'undefined' || (typeof window !== 'undefined' && localStorage.getItem('member') !== 'true') // props.priceStore.currency === 'USD'

	const currency = priceStore.currency

	function onSelectModel(index: number) {
		setSelectedProduct(index)
		amSelectedProduct(products[index].name, products[index].id)
	}

	function onChangeDropdownOptions(value: string) {
		const parsed = parseInt(value, 10)
		if (!isNaN(parsed)) {
			setDropdownOptions(parsed)
			amSelectDropdown(props.dropdownItems[parsed].title)
		}
	}

	function onSelectMembership(selectedMembership: number) {
		setHasOverridenMembership(true)
		setSelectedMembership(selectedMembership)
		amSelectedMembership(OFFERED_MEMBERSHIPS()[selectedMembership].name, OFFERED_MEMBERSHIPS()[selectedMembership].id)
	}

	function onSelectVariant(selectedVariant: string | number | undefined) {
		if (!selectedVariant) {
			return
		}
		setSelectedVariant(selectedVariant.toString())
		setSelectedSize(selectedVariant.toString())
		setNoSizeError(false)
		amSelectedVariant(selectedVariant.toString())

		const product = products[selectedProduct] || products[0]
		const price = product.variants[selectedVariant].prices.priceString
		const productPath = window.location.pathname.split('product/')[1].replace(/\//g, '')
		rootStore.shopStore.trackViewAccessoryShop(price, selectedVariant as any, productPath)
	}

	function onSelectVariantIndex(index: number) {
		onSelectVariant(props.dropdownItems[dropdownOptions].items[index].value)
	}

	useEffect(() => {
		setSelectedVariant(defaultVariant)
	}, [props.dropdownItems, dropdownOptions, defaultVariant])

	function sendPageTracking() {
		const product = products[props.defaultProductSelected] || products[0]
		fbViewContent({
			content_ids: product.id,
			content_name: product.name,
			content_type: 'product',
			contents: product.name,
			currency: 'USD',
			value: product.variants[defaultVariant].prices.comparePrice,
		})
	}

	useEffect(sendPageTracking, [props.defaultProductSelected, defaultVariant, products])

	async function onAtcClick(membership: boolean | undefined = undefined, buttonID: string, addMattress = false) {
		const needSizeSelected = props.dropdownItems?.length && props.variantSelectComponentType === 'dropdown'
		if (needSizeSelected && !selectedSize) {
			setNoSizeError(true)
			const el = document.getElementById('variant_select')
			if (el) {
				el.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
				})
				// window.scrollBy(0, -150)
			}
			return
		}
		const product = products[selectedProduct] || products[0]
		const currentVariantName = product.variants[selectedVariant].name

		const mattressesInCart = cartStoreNew.getFullCartItems().filter((item) => item.productId === MattressProducts.FiveLayerDiscounted.id)

		const coversInCart = cartStoreNew.getFullCartItems().filter((item) => isPodCoverProductId(item.productId))

		// Handle case where we've already added a mattress to the cart
		// and now we're adding a cover
		let mattressToBeReplaced = false
		if (isPodCoverProductId(product.id)) {
			for (const mattress of mattressesInCart) {
				// Does the mattresses variant name match the selected variant name?
				if (mattress.variantTitle === currentVariantName) {
					// If so, we need to remove the mattress from the cart
					await cartStoreNew.removeItems([mattress])
					mattressToBeReplaced = true
					break
				}
			}
		}

		// Handle case where we've already added a cover to the cart
		// and now we're adding a mattress
		let coverToBeReplaced = false
		if (product.id === MattressProducts.FiveLayerDiscounted.id) {
			for (const cover of coversInCart) {
				// Does the cover variant name match the selected variant name?
				if (cover.variantTitle === currentVariantName) {
					// If so, we need to remove the cover from the cart
					// and replace the product to be added with the cover, so it can be swapped out
					await cartStoreNew.removeItems([cover])
					product.id = cover.productId
					coverToBeReplaced = true
					break
				}
			}
		}

		const currentVariant = product.variants[selectedVariant]

		const isValidPod = isPodBedProductId(product.id)
		const isValidCover = isPodCoverProductId(product.id)

		let attributes: Attribute[] = [{ key: 'size', value: selectedVariant }]
		const lineItemIndex = cartStoreNew.cart.lineItems.length + 1
		if (product.custom?.isCoverMembershipBundle) {
			attributes = [...attributes, { key: 'coverMembershipBundle', value: 'true' }, { key: 'i', value: lineItemIndex.toString() }]
		}
		if (isValidPod || isValidCover) {
			const sessionCode = sessionStorage.getItem('appliedDiscountCode')
			if (sessionCode.length) {
				attributes = [...attributes, { key: 'vanitycode', value: sessionCode.toUpperCase() }]
			}
		}

		const item: LineItem = {
			variantId: currentVariant.id,
			quantity: 1,
			attributes: attributes,
		}

		// If Monthly Performance Report Coaching is in Cart, add the selling ID
		if (currentVariant.id === 39800867160152) {
			item.sellingPlanId = 477560920
		}

		const itemsToAdd: LineItem[] = []
		const itemsToRemove: LineItem[] = []

		if (addMattress) {
			if (currency === 'CAD') {
				itemsToAdd.push({
					variantId: MattressProducts.ThreeLayer.variants[selectedVariant].id,
					quantity: 1,
					attributes: [{ key: 'size', value: selectedVariant }],
				})
			} else {
				itemsToAdd.push({
					variantId: MattressProducts.FiveLayerDiscounted.variants[selectedVariant].id,
					quantity: 1,
					attributes: [{ key: 'size', value: selectedVariant }],
				})
			}
		}

		const addMembership = membership && sessionStorage.getItem(SKIP_MEMBERSHIP_CHECKOUT_KEY) == null
		if (addMembership) {
			const selectedMembershipObject = showSubscriptionPlans ? OFFERED_MEMBERSHIPS()[selectedMembership] : MembershipProducts.AutopilotEnhanced
			const newMembership = {
				variantId: selectedMembershipObject.variants.standard.id,
				sellingPlanId: selectedMembershipObject.sellingPlanId,
				quantity: 1,
				attributes: MembershipProducts.AutopilotEnhanced.id === selectedMembershipObject.id ? [{ key: 'warranty', value: 'extended warranty' }] : [],
			}
			if (!cartStoreNew.hasMembership) {
				itemsToAdd.push(newMembership)
			} else if (cartStoreNew.hasMembership) {
				const index = cartStoreNew.getIndexOfCurrentMembership()
				const currentMembership = OFFERED_MEMBERSHIPS()[index] ?? selectedMembershipObject
				itemsToRemove.push({
					variantId: currentMembership.variants.standard.id,
					sellingPlanId: currentMembership.sellingPlanId,
					quantity: 1,
					attributes: [],
				})
				itemsToAdd.push(newMembership)
			}
		}

		if (addLegkit !== 'none' && showLegKitUpsell) {
			itemsToAdd.push({
				variantId: legkitUpsell.options?.[1].data.product.variants.standard.id,
				quantity: 1,
				attributes: attributes,
			})
		}
		itemsToAdd.push(item)

		sendTrackingEvents(createCartLineItem(currentVariant, product, addMembership !== undefined ? { addMembership: addMembership } : null), buttonID)

		const isValidPodOrCoverButNotUpgrade = (isValidPod || isValidCover) && !cartStoreNew.hasMemberUpgradeInCart

		if (isValidPodOrCoverButNotUpgrade) {
			await cartStoreNew.removeItems(itemsToRemove)
			await cartStoreNew.addItems(itemsToAdd)

			if (cartStoreNew.hasMembership) {
				if (cartStoreNew.numPodsAndCovers < 2) {
					cartStoreNew.redirectToCheckout()
				} else {
					cartStoreNew.toggleCartOpen()
				}
			} else {
				// Router.push(formatLink((region ? '/' + region : '') + '/select-plan'))
				cartStoreNew.toggleCartOpen()
			}
		} else {
			await cartStoreNew.removeItems(itemsToRemove)
			await cartStoreNew.addItems(itemsToAdd)
			cartStoreNew.toggleCartOpen()
		}
	}

	const isCover = router.asPath.indexOf('/pod-cover/') !== -1 || router.asPath.indexOf('/notboring-prospecting') !== -1 || router.asPath.indexOf('/pod-prospecting') !== -1 || props.path === 'cover'

	const isMembershipCheckStyle = isCover

	const stickyATCTest = !router.asPath.includes('automated') && !router.asPath.includes('eba2349')

	const modelSelectProps = props.modelSelectProps
		? {
				labels: props.modelSelectProps.labels,
				legendLabel: props.modelSelectProps.legendLabel,
				selectedModel: 0,
				onCheck: onSelectModel,
		  }
		: null

	const cartLoading = cartStoreNew.updatingCart

	const showMattressUpsell = isCover && (currency === 'USD' || currency === 'CAD') // TODO disable for users with T1 discount
	const showLegKitUpsell = router.asPath.indexOf('/the-base/') !== -1
	const showSticky = router.asPath.indexOf('/pod-cover') !== -1 && showMattressUpsell
	const podMattressPrices = priceStore.withPrices(currency === 'CAD' ? MattressProducts.ThreeLayer : MattressProducts.FiveLayerDiscounted)

	let priceNumber = currentProduct.variants[selectedVariant].prices.price
	let strikePriceNumber = currentProduct.variants[selectedVariant].prices.comparePrice
	const regularDiscount = strikePriceNumber - priceNumber
	const upsellDiscount = (priceStore.totalProductDiscountNumber - priceStore.coverDiscountNumber) * 100
	const mattressPrice = showMattressUpsell ? podMattressPrices.variants[selectedVariant].prices.price - priceNumber : 0
	const mattressStrikePrice = showMattressUpsell ? mattressPrice + upsellDiscount : 0
	const overrideSalestag = ''
	if (addMattress) {
		priceNumber = podMattressPrices.variants[selectedVariant].prices.price
		strikePriceNumber = priceNumber + upsellDiscount + regularDiscount
	}

	useEffect(() => {
		const isCartridgeUrl = window.location.href.includes('product/pod4-insert')
		const isSignedIn = localStorage.getItem('tokenData')

		if (isCartridgeUrl && !isSignedIn) {
			localStorage.setItem('redirectToCartridge', 'true')
			router.push('/login')
			return
		} else {
			localStorage.setItem('redirectToCartridge', 'false')
			setLoading(false)
		}
	}, [])

	return (
		<>
			<section
				id="main_shop"
				style={{ backgroundColor: '#fff', color: '#000' }}
				className={`${showSticky && 'topPaddingShop'}`}
			>
				<Modal
					open={!!currentModal}
					handleClose={rootStore.shopStore.closeModal}
				>
					{currentModal ? getConnectedModal(currentModal) : prevMod ? getConnectedModal(prevMod) : null}
				</Modal>
				<div className={cx('inner-paddings-x', 'inner-paddings-top-md', 'inner-paddings-bottom', 'max-1920')}>
					{loading ? (
						<div style={{ height: '100vh', display: 'flex', alignItems: 'center' }}>
							<LoadingSpinner />
						</div>
					) : (
						<ShopMain
							products={products}
							images={images}
							features={features}
							selectedProduct={selectedProduct}
							selectedVariant={selectedVariant}
							variantSelectLabel={props.variantSelectLabel}
							dropdownItems={props.dropdownItems}
							modelSelectProps={modelSelectProps}
							currentProduct={currentProduct}
							onSelectVariant={onSelectVariant}
							onAtcClick={onAtcClick}
							modelSelectComponentType={props.modelSelectComponentType}
							variantSelectComponentType={props.variantSelectComponentType}
							shopSavingsTag={props.shopSavingsTag || overrideSalestag || settingsStore.settings.savingsTag}
							saleShopSavingsTag={overrideSalestag || settingsStore.settings.saleShopSavingsTag}
							onChangeDropdownOptions={onChangeDropdownOptions}
							showCannotShipAlert={false}
							currentDropdownOptions={currentDropdownOptions}
							ipLocations={getIpLocations(settingsStore)}
							shipping={props.shipping}
							currency={priceStore.currency}
							showMembershipCheck={isMembershipCheckStyle}
							membershipPrices={undefined}
							legKitSelectData={legkitUpsell}
							// affirmFormattedPrice={affirmFormattedPrice}
							// affirmOldFormattedPrice={affirmOldFormattedPrice}
							region={settingsStore.currentRegion}
							displayPrice={props.displayPrice}
							onSelectModel={onSelectModel}
							onSelectVariantIndex={onSelectVariantIndex}
							cartLoading={cartLoading}
							stickyATCTest={stickyATCTest}
							multiVariantItems={props.multiVariantItems}
							note={props.note}
							numPodsAndCovers={cartStoreNew.numPodsAndCovers}
							selectedSize={selectedSize}
							noSizeError={noSizeError}
							saleEndDate={settingsStore.saleEndDate}
							whatsInTheBoxSrc={props.whatsInTheBoxSrc}
							showSubscriptionPlans={showSubscriptionPlans}
							selectedMembership={selectedMembership}
							selectMembership={onSelectMembership}
							showMattressUpsell={showMattressUpsell}
							setAddMattress={setAddMattress}
							addMattress={addMattress}
							mattressUpsellPrice={mattressPrice}
							mattressUpsellStrikePrice={mattressStrikePrice}
							showSticky={showSticky}
							strikePriceNumber={strikePriceNumber}
							priceNumber={priceNumber}
							selectModal={rootStore.shopStore.selectModal}
							outOfStock={props.path.includes('lavender')}
							showLegKitUpsell={!!showLegKitUpsell}
							legKitSelection={addLegkit}
							legKitSelect={setAddLegkit}
						/>
					)}
				</div>
			</section>
		</>
	)
}
