export const podDescription = ['The only smart mattress with advanced cooling and heating technology designed to help you fall asleep faster and keep you asleep.']

export const podProDescription = [
	'The most advanced Pod yet introducing new Room Climate and Weather Response, a Comfort Blend Integrated Topper, GentleRise Wake Up Technology, and double the number of sensors enabling new Heart Rate Variability monitoring and a Daily Health Check report.',
]

export const pod2ProDescription = [
	'The Pod Mattress improves your sleep, gives you more energy, and upgrades your entire sleep experience. Each side cools and heats you and your partner, all while tracking your sleep without a wearable.',
]

export const pod3Description = [
	'The Pod Mattress improves your sleep, gives you more energy, and upgrades your entire sleep experience. Each side cools and heats you and your partner, all while tracking your sleep without a wearable.',
]

export const podProMaxDescription = [
	"Pod Pro Max features everything in the Pod Pro plus a new MaxChill™ layer infused with silver, graphite and copper to offer maximum heat dissipation and antimicrobial properties that further enhance the Pod Pro's temperature regulation technology and help you maintain a thermo neutral environment while you sleep.",
]

export const podProCoverDescription = [
	"Love your current mattress but having temperature issues? Upgrade your sleep by adding the Pod's patented thermoregulation technology to your current mattress. Plus, enjoy the best in seamless sleep and health tracking.",
	' Zips on to any 10" to 11" tall mattress.',
]

export const podProCoverPerfectFitDescription = [
	"Love your current mattress but having temperature issues? Upgrade your sleep by adding the Pod's patented thermoregulation technology to your current mattress. Plus, enjoy the best in seamless sleep and health tracking.",
	'Zips on to any 10" to 16" tall mattress.',
]

export const podPro2CoverDescription = [
	'The Pod 2 Cover improves your sleep, gives you more energy, and seamlessly fits onto a 10” - 11” mattress. Each side cools and heats you and your partner, all while tracking your sleep without a wearable.',
]

export const podPro3CoverStandardDescription = [
	'The Pod 3 Cover improves your sleep, gives you more energy, and seamlessly fits onto a 10” - 11” mattress. Each side cools and heats you and your partner, all while tracking your sleep without a wearable.',
]

export const podPro3CoverPerfectFitDescription = [
	'The Pod 3 Cover improves your sleep, gives you more energy, and seamlessly fits onto any bed. Each side cools and heats you and your partner, all while tracking your sleep without a wearable.',
]

export const podProCoverUpgradeDescription = [
	`Includes Active Grid Cover, Hub and Power Cable.`,
	`Upgrade your current Pod to the latest Eight Sleep technologyTake your sleep to the next level with the Pod 3 Member Upgrade. Get all your favorite features of Pod 2 plus improved comfort, 4000x higher sleep and health tracking resolution, Hub powered by quad-core CPU, 5Ghz Wi-Fi support, and more. Simply zip the Pod 3 Cover onto your current encasement and hook up your new Hub for enhanced sleep.`,
]

export const anniversaryUpgradeDescription = [
	`Cheers to another year of sleep fitness. With this Upgrade Bundle, you’ll receive the following to enhance your sleep setup:`,
	`<ul style="margin-left: 20px;">
	<li>Pod 3 Upgrade: includes 1 Active Grid Cover, 1 Hub, and 1 Power Cable</li>
	<li>The Air Pillow Set</li>
	<li>Air Lite Comforter</>
	<li>Pod Protector</li>
	</ul>`,
	`Upgrade your current Pod to the latest Eight Sleep technology: Pod 3. Take your sleep to the next level with all your favorite features plus improved comfort, 4000x higher sleep and health tracking resolution, Hub powered by quad-core CPU, 5Ghz Wi-Fi support, and more.`,
]

export const podProtectorDescription = [
	'The ultimate mattress protection for your Pod. Made of TENCEL® jersey blend. Works over the Active Grid and under your bed sheets to shield the Pod from dust, pet dander, allergens, bacteria, mold, and liquid spills.',
]
export const gravityBlanketDescription = ['Relax before bedtime with a Gravity weighted blanket. Reduce stress and anxiety, fall asleep faster, and sleep deeper after spending time under its weight.']

export const podFoundationDescription = [
	'Designed to the fit any Pod, the Foundation eliminates the need for a box spring. Its slatted design is engineered with the strongest spruce for utmost support while you sleep.',
]
export const sleepEssentialsDescription = ['Includes everything you need to pair with your Pod: one Pod Sheet Set (white), one Pod Protector, and two Air Pillows.']
export const carbonPillowDescription = [
	'Premium memory foam meets a proprietary temperature-regulating gel to absorb and dissipate heat away from the body. Its air ventilation system, encased in a high-quality removable cover, increases airflow and breathability.',
]
export const podSheetSetDescription = [
	'Exclusive Sheet Set designed specifically to fit your Pod. Made with percale weave for smooth feel and high breathability so your body can feel the temperature effects of the Pod immediately.',
]
export const podSheetSetGrayDescription = [
	'Exclusive Sheet Set designed specifically to fit your Pod. Made with percale weave for smooth feel and high breathability so your body can feel the temperature effects of the Pod immediately.',
]

export const airLiteComforterDescription = [
	'Our favorite comforter, the ideal partner for your Pod sleep experience. The Air Lite Comforter will keep you cool and comfortable all night long. Made out of Hypoallergenic Down Alternative fill, it is soft to the touch and breathable. Pair it with your Pod and the Pod Sheet Set for an elevated sleep experience every night.',
]
export const lavenderSprayDescription = [
	'Enhance your sleep environment with this Lavender Aromatherapy spray designed to promote relaxation. Made with no artificial scents or colors, this natural scent can be sprayed on pillows, sheets, and comforters to help you naturally relax and fall asleep faster.',
]
export const hypervoltPlusDescription = [
	"Hyperice's Hypervolt Plus uses percussion therapy to relieve muscle soreness and stiffness to improve range of motion and promote circulation. The massage gun comes with 5 interchangeable head attachments to adjust your massage experience and target various muscle groups. Use it before you get into your Pod to relax your muscles and achieve optimal recovery during the night or wake your muscles up to start your day.",
]
export const hypervoltProDescription = [
	"Hyperice's Hypervolt 2 Pro uses percussion therapy to relieve muscle soreness and stiffness to improve range of motion and promote circulation. The massage gun comes with 5 interchangeable head attachments to adjust your massage experience and target various muscle groups. Use it before you get into your Pod to relax your muscles and achieve optimal recovery during the night or wake your muscles up to start your day.",
]
export const comfortBundleDescription = 'Everything you need to pair with you Pod: two Air Pillows, one Air Lite Comforter, and one Sheet Set. Save $150 by buying these items together.'
export const priceBedframeDescription = [
	'Enhance your sleep experience with the modern Prince Bed Frame from Modloft. With a five foot upholstered channeled headboard in white eco leather and a solid pine platform, this foundation will fit your Pod perfectly and elevate your room’s design. No box spring necessary.',
]
export const venomBackDescription = [
	"Hyperice's Venom Back is a digital wearable device that combines heat and vibration to warm up and loosen sore muscles. Simply put it around your torso and set the heat levels and vibration patterns at your personal preference to warm up your back before you get into your Pod at night.",
]
export const legPulseDescription = [
	"The NormaTec 2.0 Compression Recovery Boots increase circulation to reduce pain and soreness in the legs and help you recover quickly. NormaTec's Patented PULSE technology with Zone Boost allows you to give targeted attention where you need it most.",
]
export const mattressMemberDescription = [
	'Six-layer premium foam mattress, 12 inches deep for the ideal support in all the right places. The MaxChill™ Layer infused with silver, copper, and graphite for maximal heat dissipation and antimicrobial properties to further enhance temperature regulation. ',
	' Includes only the mattress (no Active Grid or Hub).',
]

export const theBaseDescription = [
	'Upgrade your Pod 4 to the Pod 4 Ultra by adding the adjustable Base. The Base adds automatic snoring reduction and custom body position to your sleep set up. Only <metric>7.5cm</metric><nonMetric>3"</nonMetric> thick, holds up to <metric>340 kg</metric><nonMetric>750 lbs</nonMetric> and fits inside your existing bed frame, right under your mattress.',
]
export const pod4CartridgeDescription = [
	`Keep your Pod 4 performing at its best with this maintenance filter, designed to maintain optimal water quality and system efficiency. The filter's built-in treatment helps prevent performance issues by keeping the water clean. Replace every 6 months to ensure continuous, uninterrupted temperature regulation.`,
]

export const sleepElixirDescription = [`Sleep Elixir helps you ease into sleep, faster, by leveraging over 600 million hours of sleep data and 100% natural ingredients. `]
