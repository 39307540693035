import { RegionShort } from 'utils/internationalization'
import { FooterLink, FooterSocialLinksProps } from 'components/Footer/types'
import { facebook, instagram, twitter } from 'components/Icon'

/**
 * Generate ID for the footer links
 *
 * TODO - This should be moved to a more generic location, it's pretty useful, and not specific to the footer
 * @param text - The text to generate the ID from
 */
export const generateID = (text: string): string => {
	return text.toLowerCase().replace(' ', '-')
}

export const getFooterLinks = (region: RegionShort): FooterLink[] => {
	const links: FooterLink[] = []

	switch (region) {
		default: {
			links.push({
				href: '/blog',
				text: 'Blog',
				sublinks: [
					{
						href: '/blog/sleep',
						text: 'Sleep',
					},
					{
						href: '/blog/fitness',
						text: 'Fitness',
					},
					{
						href: '/blog/wellness',
						text: 'Wellness',
					},
					{
						href: '/blog/science',
						text: 'Science',
					},
				],
			})
			links.push({
				text: 'Company',
				sublinks: [
					{
						href: '/press',
						text: 'Press',
					},
					{
						href: '/athletes',
						text: 'Athletes',
					},
					{
						href: 'https://jobs.ashbyhq.com/eightsleep',
						text: 'Careers',
					},
				],
			})
			links.push({
				text: 'Support',
				sublinks: [
					{
						href: 'https://help.eightsleep.com/',
						text: 'FAQs',
					},
					{
						href: '#',
						text: 'Shipping',
					},
					{
						href: '/warranty',
						text: 'Warranty',
					},
					{
						href: '/return-policy',
						text: 'Returns',
					},
					{
						href: '/accessibility',
						text: 'Accessibility',
					},
				],
			})
			links.push({
				text: 'Legal',
				sublinks: [
					{ text: 'Financing Policy', href: '/financing' },
					{ text: 'Privacy Policy', href: 'https://www.iubenda.com/privacy-policy/379226/full-legal' },
					{ text: 'Terms and Conditions', href: '/terms-and-conditions' },
				],
			})
			break
		}
	}

	return links
}

export const getSocialLinks = (): FooterSocialLinksProps[] => {
	return [
		{
			href: 'https://www.facebook.com/eightsleep',
			icon: facebook,
		},
		{
			href: 'https://www.instagram.com/eightsleep',
			icon: instagram,
		},
		{
			href: 'https://twitter.com/eightsleep',
			icon: twitter,
		},
	]
}
