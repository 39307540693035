import { VisualProps } from 'components/Phantom/_shop/Visuals'
import { getLanguageAssets } from './localization'

export const getAutopilotVisuals = (metric: boolean, languageCode: string): VisualProps => {
	return {
		type: 'new_carousel',
		associatedSelectorKeys: ['auto-pilot'],
		data: [
			{
				type: 'image',
				data: {
					src: getLanguageAssets('autopilot-slide-1', languageCode),
					mobileSrc: getLanguageAssets('autopilot-slide-1-mobile', languageCode),
					alt: 'App showing autopilot',
				},
				associatedSelections: [],
				// pills: [
				// 	{
				// 		icon: 'CoolLight',
				// 		text: 'Sleep and health reports',
				// 	},
				// 	{
				// 		icon: 'BedLight',
				// 		text: 'Automatic temperature adjustments',
				// 	},
				// 	{
				// 		icon: 'TwoUsersLight',
				// 		text: 'One plan for you and your partner',
				// 	},
				// ],
			},
			{
				type: 'image',
				data: {
					src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/autopilot-breakdown-slide-2.png',
					mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/shop_assets_1112/In+situ+%E2%80%93+Mobile.png',
					alt: 'Autopilot explanation slide 2',
					objectFit: 'cover',
				},
				associatedSelections: [],
			},
			{
				type: 'image',
				data: {
					src: metric ? getLanguageAssets('autopilot-slide-3', languageCode) : 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/autopilotfinalwideslide.png',
					mobileSrc: metric
						? getLanguageAssets('autopilot-slide-3-mobile', languageCode)
						: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/shop_assets_1112/Temperature+%E2%80%93+Mobile-2.png',
					alt: 'Autopilot explanation slide 3',
					objectFit: 'cover',
					doNotCompress: true,
					dprHeight: 2000,
				},
				associatedSelections: [],
			},
			{
				type: 'image',
				data: {
					src: getLanguageAssets('autopilot-slide-4', languageCode),
					mobileSrc: getLanguageAssets('autopilot-slide-4-mobile', languageCode),
					alt: 'Autopilot explanation slide 4',
					objectFit: 'cover',
					doNotCompress: true,
					dprHeight: 2000,
				},
				associatedSelections: [],
			},
			{
				type: 'image',
				data: {
					src: getLanguageAssets('autopilot-slide-5', languageCode),
					mobileSrc: getLanguageAssets('autopilot-slide-5-mobile', languageCode),
					alt: 'Autopilot explanation slide 5',
					objectFit: 'cover',
					doNotCompress: true,
					dprHeight: 2000,
				},
				associatedSelections: [],
			},
			{
				type: 'image',
				data: {
					src: getLanguageAssets('autopilot-slide-6', languageCode),
					mobileSrc: getLanguageAssets('autopilot-slide-6-mobile', languageCode),
					alt: 'Autopilot explanation slide 6',
					objectFit: 'cover',
					doNotCompress: true,
					dprHeight: 2000,
					className: 'slide_offset_desktop',
				},
				associatedSelections: [],
			},
		],
	}
}
