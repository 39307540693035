import { RegionShort } from '../utils/internationalization'
import { BundleProducts, MattressProducts, PodCoverProducts } from '../products/allProducts'

export function getRegionMattress(region: RegionShort) {
	switch (region) {
		case 'uk':
			return null
		case 'eu':
			return null
		case 'se':
			return null
		case 'dk':
			return null
		case 'ae':
			return null
		case 'au':
			return null
		case 'ca':
			return MattressProducts.ThreeLayer.variants.caqueen
		default:
			return MattressProducts.FiveLayerDiscounted.variants.queen
	}
}

export function getRegionCover(region: RegionShort) {
	switch (region) {
		case 'uk':
			return PodCoverProducts.Pod3CoverPerfect.variants.ukking
		case 'eu':
			return PodCoverProducts.Pod3CoverPerfect.variants.eulargedouble
		case 'se':
			return PodCoverProducts.Pod3CoverPerfect.variants.seking
		case 'dk':
			return PodCoverProducts.Pod3CoverPerfect.variants.dkking
		case 'ae':
			return PodCoverProducts.Pod3CoverPerfect.variants.aeking
		case 'au':
			return PodCoverProducts.Pod3CoverPerfect.variants.auqueen
		case 'ca':
			return PodCoverProducts.Pod3CoverPerfect.variants.caqueen
		default:
			return PodCoverProducts.Pod3CoverPerfect.variants.queen
	}
}

export function getRegionPod4DefaultKey(region: RegionShort) {
	switch (region) {
		case 'uk':
			return 'ukking'
		case 'eu':
			return 'eulargedouble'
		case 'se':
			return 'seking'
		case 'dk':
			return 'dkking'
		case 'ae':
			return 'aeking'
		case 'au':
			return 'auqueen'
		case 'ca':
			return 'caqueen'
		default:
			return 'queen'
	}
}

export function getRegionPod4(region: RegionShort) {
	const variantKey = getRegionPod4DefaultKey(region)
	switch (region) {
		case 'uk':
			return PodCoverProducts.Pod4.variants[variantKey]
		case 'eu':
			return PodCoverProducts.Pod4.variants[variantKey]
		case 'se':
			return PodCoverProducts.Pod4.variants[variantKey]
		case 'dk':
			return PodCoverProducts.Pod4.variants[variantKey]
		case 'ae':
			return PodCoverProducts.Pod4.variants[variantKey]
		case 'au':
			return PodCoverProducts.Pod4.variants[variantKey]
		case 'ca':
			return PodCoverProducts.Pod4.variants[variantKey]
		default:
			return PodCoverProducts.Pod4.variants[variantKey]
	}
}

export function getRegionPod4UltraDefaultKey(region: RegionShort) {
	switch (region) {
		case 'uk':
			return 'uksuperking'
		case 'eu':
			return 'eusuperking'
		case 'se':
			return 'sesuperking'
		case 'dk':
			return 'dksuperking'
		case 'ae':
			return 'aeking'
		case 'au':
			return 'auqueen'
		case 'ca':
			return 'caqueen'
		default:
			return 'queen'
	}
}

export function getRegionPod4Ultra(region: RegionShort) {
	const variantKey = getRegionPod4UltraDefaultKey(region)
	switch (region) {
		case 'uk':
			return PodCoverProducts.Pod4Ultra.variants[variantKey]
		case 'eu':
			return PodCoverProducts.Pod4Ultra.variants[variantKey]
		case 'se':
			return PodCoverProducts.Pod4Ultra.variants[variantKey]
		case 'dk':
			return PodCoverProducts.Pod4Ultra.variants[variantKey]
		case 'ae':
			return PodCoverProducts.Pod4Ultra.variants[variantKey]
		case 'au':
			return PodCoverProducts.Pod4Ultra.variants[variantKey]
		case 'ca':
			return PodCoverProducts.Pod4Ultra.variants[variantKey]
		default:
			return PodCoverProducts.Pod4Ultra.variants[variantKey]
	}
}

export function getRegionCoverName(region: RegionShort) {
	switch (region) {
		case 'uk':
			return 'ukking'
		case 'eu':
			return 'eulargedouble'
		case 'se':
			return 'seking'
		case 'dk':
			return 'dkking'
		case 'ae':
			return 'aeking'
		case 'au':
			return 'auqueen'
		case 'ca':
			return 'caqueen'
		default:
			return 'queen'
	}
}

export function getRegionSleepEssentialsBundle(region: RegionShort) {
	switch (region) {
		case 'uk':
			return null
		case 'eu':
			return null
		case 'se':
			return null
		case 'dk':
			return null
		case 'ae':
			return null
		case 'au':
			return null
		case 'ca':
			return null
		default:
			return BundleProducts.SleepEssentialBundle.variants.queen
	}
}
