import { VisualProps } from 'components/Phantom/_shop/Visuals'
import { getLanguageAssets } from './localization'

export const getModelVisuals = (modelValue: string, warrantyLength: number, isMetricRegion: boolean, languageCode: string): VisualProps => {
	switch (modelValue) {
		case 'pod_3_cover_perfect':
			return {
				type: 'new_carousel',
				associatedSelectorKeys: ['pod-model'],
				data: [
					{
						type: 'video-gif',
						data: {
							src: 'https://d115sb6i1ixllw.cloudfront.net/assets/new_carousel_pod3_gif.mp4',
							poster: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/3_model_poster.jpg',
						},
						associatedSelections: [],
					},
					{
						type: 'image',
						data: {
							src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/Pod+3+Product+Elements+%E2%80%93+Wide.png',
							mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/Pod+3+Product+Elements+%E2%80%93+Mobile.png',
							alt: 'Pod 4',
						},
						associatedSelections: [],
					},
				],
				bannerBenefits: [
					{
						icon: 'ShieldCheckLight',
						text: `${warrantyLength}-year warranty`,
					},
					{
						icon: 'MoonLight',
						text: `30-night risk-free trial`,
					},
					{
						icon: 'EightBoxLight',
						text: 'Free returns',
					},
					{
						icon: 'DeliveryLight',
						text: 'Free shipping',
					},
				],
			}
		case 'pod_4':
			return {
				type: 'new_carousel',
				associatedSelectorKeys: ['pod-model'],
				data: [
					{
						type: 'video-gif',
						data: {
							src: 'https://d115sb6i1ixllw.cloudfront.net/assets/boxing_day_pod4.mp4',
							poster: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/pod4_carousel_poster_boxing_day.jpg',
						},
						associatedSelections: [],
					},
					{
						type: 'video',
						data: {
							src: 'https://d115sb6i1ixllw.cloudfront.net/assets/pod-4-slide-2-new-ad.mp4',
							poster: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/pod+4+launch+poster.png',
						},
						associatedSelections: [],
					},
					{
						type: 'image',
						data: {
							src: getLanguageAssets('product-breakdown-wide', languageCode),
							mobileSrc: getLanguageAssets('product-breakdown-mobile', languageCode),
							alt: 'Pod 4 stack',
						},
						associatedSelections: [],
					},
					{
						type: 'image',
						data: {
							src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/Product+Elements+%E2%80%93+Wide.png',
							mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/Product+Elements+%E2%80%93+Mobile.png',
							alt: 'Pod 4',
						},
						associatedSelections: [],
					},
					{
						type: 'image',
						data: {
							objectFit: 'contain',
							src: isMetricRegion
								? 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/Hub+Non-US+%E2%80%93+Wide.png'
								: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/Hub+US+%E2%80%93+Wide.png',
							mobileSrc: isMetricRegion
								? 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Hub+Non-US+%E2%80%93+Mobile+-+Edit+slide+5.png'
								: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Hub+US+%E2%80%93+Mobile-Edit+slide+5.png',
							alt: 'Pod 4 hub dimensions',
						},
						associatedSelections: [],
					},
				],
				bannerBenefits: [
					{
						icon: 'ShieldCheckLight',
						text: `${warrantyLength}-year warranty`,
					},
					{
						icon: 'MoonLight',
						text: `30-night risk-free trial`,
					},
					{
						icon: 'EightBoxLight',
						text: 'Free returns',
					},
					{
						icon: 'DeliveryLight',
						text: 'Free shipping',
					},
				],
			}
		default:
		case 'pod_4_ultra':
			return {
				type: 'new_carousel',
				associatedSelectorKeys: ['pod-model'],
				data: [
					{
						type: 'video-gif',
						data: {
							src: 'https://d115sb6i1ixllw.cloudfront.net/assets/boxing_day_ultra.mp4',
							poster: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/ultra_carousel_poster_boxing_day.jpg',
						},
						associatedSelections: [],
					},
					{
						type: 'video',
						data: {
							src: 'https://d115sb6i1ixllw.cloudfront.net/assets/shop_video_cropped.mp4',
							poster: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/pod+4+launch+poster.png',
						},
						associatedSelections: [],
					},
					{
						type: 'image',
						data: {
							src: getLanguageAssets('product-ultra-breakdown-wide', languageCode),
							mobileSrc: getLanguageAssets('product-ultra-breakdown-mobile', languageCode),
							alt: 'Pod 4 Ultra stack',
						},
						associatedSelections: [],
					},
					{
						type: 'image',
						data: {
							src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/Pod+4+Ultra+Product+Elements+%E2%80%93+Wide.png',
							mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/Pod+4+Ultra+Product+Elements+%E2%80%93+Mobile.png',
							alt: 'Pod 4 Ultra',
						},
						associatedSelections: [],
					},
					{
						type: 'image',
						data: {
							objectFit: 'contain',
							src: isMetricRegion
								? 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/Hub+Non-US+%E2%80%93+Wide.png'
								: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/carousel-ab/Hub+US+%E2%80%93+Wide.png',
							mobileSrc: isMetricRegion
								? 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Hub+Non-US+%E2%80%93+Mobile+-+Edit+slide+5.png'
								: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/Hub+US+%E2%80%93+Mobile-Edit+slide+5.png',
							alt: 'Pod 4 hub dimensions',
						},
						associatedSelections: [],
					},
					{
						type: 'video-gif',
						data: {
							src: 'https://d115sb6i1ixllw.cloudfront.net/assets/Ultra-Slide6-Mobile-Oct24.mp4',
							mobileSrc: 'https://d115sb6i1ixllw.cloudfront.net/assets/Ultra-Slide6-Mobile-Oct24.mp4',
							poster: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/elevation-placeholder-thumbnail-v19.jpg',
						},
						associatedSelections: [],
					},
				],
				bannerBenefits: [
					{
						icon: 'ShieldCheckLight',
						text: `${warrantyLength}-year warranty`,
					},
					{
						icon: 'MoonLight',
						text: `30-night risk-free trial`,
					},
					{
						icon: 'EightBoxLight',
						text: 'Free returns',
					},
					{
						icon: 'DeliveryLight',
						text: 'Free shipping',
					},
				],
			}
	}
}
