import { FC, useRef, useState } from 'react'
import styles from './SleepElixirStats.module.scss'
import { SleepElixirStatsProps } from './SleepElixirStats.types'
import { Type } from 'components/Type'
import { Img } from 'components/basic/Img'
import { CountUp } from 'components/CountUp'
import { useGSAP } from '@gsap/react'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'

export const SleepElixirStats: FC<SleepElixirStatsProps> = () => {
	const cardRef1 = useRef(null)
	const cardRef2 = useRef(null)
	const [timeline1, setTimeline1] = useState<gsap.core.Timeline | null>(null)
	const [timeline2, setTimeline2] = useState<gsap.core.Timeline | null>(null)
	const stagger = 0

	useGSAP(
		() => {
			gsap.registerPlugin(ScrollTrigger)
			if (!timeline1) return

			const tl = gsap.timeline({
				ease: 'none',
				scrollTrigger: {
					trigger: cardRef1.current,
					start: `${stagger * 60}px 95%`,
					end: '+=150px',
					toggleActions: 'restart none none reverse',
				},
			})

			tl.fromTo(
				cardRef1.current,
				{
					opacity: 0,
				},
				{
					opacity: 1,
					duration: 1,
				},
				0
			)
			tl.add(timeline1, 0)
		},
		{
			dependencies: [timeline1],
			revertOnUpdate: true,
		}
	)

	useGSAP(
		() => {
			gsap.registerPlugin(ScrollTrigger)
			if (!timeline2) return

			const tl = gsap.timeline({
				ease: 'none',
				scrollTrigger: {
					trigger: cardRef2.current,
					start: `${stagger * 60}px 95%`,
					end: '+=150px',
					toggleActions: 'restart none none reverse',
				},
			})

			tl.fromTo(
				cardRef2.current,
				{
					opacity: 0,
				},
				{
					opacity: 1,
					duration: 1,
				},
				0
			)
			tl.add(timeline2, 0)
		},
		{
			dependencies: [timeline2],
			revertOnUpdate: true,
		}
	)

	const handleCountUpTimelineReady1 = (timeline: gsap.core.Timeline) => {
		setTimeline1(timeline)
	}

	const handleCountUpTimelineReady2 = (timeline: gsap.core.Timeline) => {
		setTimeline2(timeline)
	}

	return (
		<section className={styles.container}>
			<div className={styles.container_inner}>
				<Type.Headline2
					animateOnScroll
					className={styles.heading}
				>
					Wind down faster to wake up stronger
				</Type.Headline2>
				<div className={styles.content}>
					<div className={styles.stats}>
						<div className={styles.stat_wrapper}>
							<div
								className={styles.stat}
								ref={cardRef1}
							>
								<span className={styles.megatitle}>
									<CountUp
										startValue={24}
										endValue={34}
										repeat={false}
										ease="power2.out"
										trigger={true}
										duration={1}
										animationStyle={'wheel'}
										onTimelineReady={handleCountUpTimelineReady1}
									/>
								</span>
								<Type.Headline1 as={'span'}>%</Type.Headline1>
							</div>
							<Type.Headchapter
								as={'p'}
								className={styles.stat_text}
							>
								Users reported falling asleep 34% faster¹
							</Type.Headchapter>
						</div>
						<Img
							src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-waitlist/sleep-elixir-athlete-1.png'}
							alt={'Elixir user sleeping'}
						/>
					</div>
					<div className={styles.stats}>
						<div className={styles.stat_wrapper}>
							<div
								className={styles.stat}
								ref={cardRef2}
							>
								<span className={styles.megatitle}>
									<CountUp
										startValue={54}
										endValue={64}
										repeat={false}
										ease="power2.out"
										trigger={true}
										duration={1}
										animationStyle={'wheel'}
										onTimelineReady={handleCountUpTimelineReady2}
									/>
								</span>
								<Type.Headline1 as={'span'}>%</Type.Headline1>
							</div>
							<Type.Headchapter
								as={'p'}
								className={styles.stat_text}
							>
								Users felt 64% better during the day¹
							</Type.Headchapter>
						</div>
						<Img
							src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir-waitlist/sleep-elixir-athlete-2.png'}
							alt={'Elixir athlete'}
						/>
					</div>
				</div>
				<Type.SmallPrint className={styles.small_print}>
					¹Based on an Eight Sleep clinical study including Sleep Elixir users evaluating self-perceived efficacy. Individual results may vary.
				</Type.SmallPrint>
			</div>
		</section>
	)
}
