import {
	BlanketProducts,
	BundleProducts,
	CartridgeProducts,
	ComforterProducts,
	FoundationProducts,
	HypericeProducts,
	MattressProducts,
	NormaTecProducts,
	PillowProducts,
	PodCoverProducts,
	ProtectorProducts,
	SheetProducts,
	SprayProducts,
	SupplementProducts,
	TheBaseProducts,
} from 'products/allProducts'
import { ProductCore } from 'products/types'
import { getImage } from 'utils/narvarUtils'

export const productPages = {
	'the-air-pillow': { product: PillowProducts.CarbonAirPillow },
	'the-base': { product: TheBaseProducts.TheBase },
	'pod4-insert': { product: CartridgeProducts.Pod4Cartridge },
	'waterproof-protector': { product: ProtectorProducts.PodProtector },
	foundation: { product: FoundationProducts.SimpleFoundation },
	'gravity-blanket': { product: BlanketProducts.BrandedGravity },
	'the-pod-sheet-set': { product: SheetProducts.SheetSet },

	'the-sleep-essentials-bundle': { product: BundleProducts.SleepEssentialBundle },
	'air-lite-comforter': { product: ComforterProducts.AirLiteComforter },
	'lavender-sleep-spray': { product: SprayProducts.LavenderSpray },
	'hypervolt-plus': { product: HypericeProducts.HypervoltPlus },
	'hypervolt-2-pro': { product: HypericeProducts.HypervoltPlus },
	'venom-back': { product: HypericeProducts.VenomBack },
	'leg-pulse': { product: NormaTecProducts.LegPulse2 },
	'sleep-elixir': { product: SupplementProducts.SleepElixir},
	'pod-cover': { product: PodCoverProducts.Pod4 },
	'shop-pod-cover': { product: PodCoverProducts.Pod4 },
	'pod-pro-cover': { product: PodCoverProducts.Pod4 },
	'premium-mattress': { product: MattressProducts.FiveLayerDiscounted },
	pod4: { product: PodCoverProducts.Pod4 },
	pod4ultra: { product: PodCoverProducts.Pod4Ultra },
}

export function getProductCategory(itemName: string) {
	// const name = itemName.toLowerCase()
	if (itemName === PodCoverProducts.Pod3CoverPerfect.name || itemName === PodCoverProducts.Pod4.name || itemName === PodCoverProducts.Pod4Ultra.name) {
		return 'Pod'
	}

	if (itemName.toLowerCase().includes('elixir')) {
		return 'Supplement'
	}

	return 'Accessory'
}

export function getProductShopPageData(pageViewed: keyof typeof productPages) {
	let product: ProductCore
	if ((pageViewed as any) === 'pod_4_ultra') {
		product = PodCoverProducts.Pod4Ultra
	} else if ((pageViewed as any) === 'pod_4') {
		product = PodCoverProducts.Pod4
	} else if ((pageViewed as any) === 'pod_3_cover_perfect') {
		product = PodCoverProducts.Pod3CoverPerfect
	} else {
		product = productPages[pageViewed].product
	}
	return {
		productId: product.id,
		productName: product.name,
		productCategory: getProductCategory(product.name),
		productUrl: `https://www.eightsleep.com/product/${pageViewed}`,
		productImageURL: getImage(product.name),
	}
}
