import { FC, useEffect, useState } from 'react'
import styles from './CleanNav.module.scss'
import { CleanNavProps } from './CleanNav.types'
import { Img } from 'components/basic/Img'
import * as React from 'react'
import { useRouter } from 'next/router'
import { close, Icon } from 'components/Icon'
import cx from 'classnames'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { useScrollDirection } from 'components/_hooks/useScrollDirection'

const PromoBar = ({ promo_bar_text, promo_bar_href, promo_bar_height }: { promo_bar_text: string; promo_bar_href: string; promo_bar_height: number }) => {
	const [mounted, setMounted] = useState(true)
	const [open, setOpen] = useState(true)
	const scrollDirection = useScrollDirection(20)

	useEffect(() => {
		setMounted(scrollDirection !== 'down')
	}, [scrollDirection])

	useEffect(() => {
		if (!open) document.body.style.setProperty('--clean-nav-promo-height', `0px`)
		else document.body.style.setProperty('--clean-nav-promo-height', `${promo_bar_height}px`)
	}, [open, promo_bar_height])

	const handleCloseClick = () => {
		setMounted(false)
		setOpen(false)
	}

	return (
		<div className={cx(styles.promo_bar, mounted ? styles.shown : '')}>
			<AnchorLink
				href={promo_bar_href}
				className={`${styles.single_element}`}
			>
				{promo_bar_text}
			</AnchorLink>
			<button
				id={'promo-bar-toggle-button'}
				className={styles.close_button}
				onClick={handleCloseClick}
			>
				<p className={'vh'}>close</p>
				<Icon
					color={'black'}
					icon={close}
				/>
			</button>
		</div>
	)
}

export const CleanNav: FC<CleanNavProps> = () => {
	const router = useRouter()
	return (
		<>
			<header
				className={styles.container}
				tabIndex={-1}
				id="header"
			>
				{router.query.ref_id && (
					<PromoBar
						promo_bar_href={'#waitlist-signup'}
						promo_bar_text="Join your friend to be among the first to try Sleep Elixir"
						promo_bar_height={42}
					/>
				)}
				<Img
					className={styles.logo}
					src={'https://eightsleep.imgix.net/Logo_White.svg?10878386720812330155'}
					alt={'Eight Sleep Logo'}
					// href={`/${props.region}`}
					href={`/`}
					id={'8s-logo-desktop'}
				/>
			</header>
		</>
	)
}
