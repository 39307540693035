import styles from './ATC.module.scss'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { ImageCarousel, ImageCarouselHandle } from 'components/ImageCarousel'
import useShopStore, { ShopStoreState, VariantData } from 'stores/shop-store'
import { PropsWithInjectedRootStore } from 'constants/types'
import { inject, observer } from 'mobx-react'
import { Type } from 'components/Type'
import { Button } from 'components/WebEv/Buttons'
import { Badge } from 'components/Badge'
import cx from 'classnames'
import { Icon } from 'components/Phantom/Icon'
import { getShippingText } from 'config/shipping_timelines'
import { RegionShort } from 'utils/internationalization'
import { VariantCore } from 'products/types'
import { NewCartStore } from 'cart/store'
import { Currency } from 'prices/types'
import { useRouter } from 'next/router'

interface Props extends PropsWithInjectedRootStore {
	initialShopStoreState: ShopStoreState
	handleCheckout: (cartStore: NewCartStore, variantKey: string, variant: VariantData, buttonID: string, currency: Currency) => Promise<void>
	setCurrentVariant: (variant: VariantData | null) => void
	children?: React.ReactNode
}

interface CardSelectionProps {
	selectedVariantKey: string
	variants: VariantData[]
}

interface CardSelectionItemProps {
	title: string
	description?: string
	checklist?: string[]
	disabled?: boolean
	highlightColor?: string
	selected?: boolean
	error?: string
	price: string
	comparePrice: string
	badge?: string
}

interface TabProps {
	tabs: { title: string; content: string }[]
}

export const ATC = inject('rootStore')(
	observer((props: Props) => {
		const router = useRouter()
		const data = useShopStore((state) => state.data, props.initialShopStoreState)
		const selectedVariant = useShopStore((state) => state.selectedVariant)
		const ref = useRef<ImageCarouselHandle>(null)
		const [loading, setLoading] = useState(false)
		const { cartStoreNew, priceStore, settingsStore } = props.rootStore!

		const currentVariant = data.variants.find((v) => v.key === selectedVariant)

		useEffect(() => {
			props.setCurrentVariant(currentVariant)
		}, [currentVariant, props])

		const isWaitlistAd = true //router.query.utm_source === 'waitlist'

		return (
			<section className={styles.shop_section}>
				<div>
					<ImageCarousel
						ref={ref}
						items={data.visuals}
						className={styles.carousel}
						maxThumbnails={3}
					/>
				</div>
				<div className={styles.shop_section__content}>
					<Type.Headline3 className={styles.product_title}>{data.productTitle}</Type.Headline3>
					<Type.Headline4 className={styles.product_prices}>
						{currentVariant.priceString} {currentVariant.comparePriceString !== currentVariant.priceString ? <span className={styles.strikethrough}>{currentVariant.comparePriceString}</span> : null}
						{currentVariant.saveText ? <Badge.Orange type={'discount'}>{currentVariant.saveText}</Badge.Orange> : null}
					</Type.Headline4>
					<ProductTabs tabs={data.tabs} />
					<CardSelectionContainer
						selectedVariantKey={currentVariant.key}
						variants={data.variants}
					/>
					{isWaitlistAd ? (
						<WaitlistForm />
					) : (
						<>
							<Button.Primary
								className={styles.atc_button}
								onClick={async () => {
									setLoading(true)
									await props.handleCheckout(cartStoreNew, selectedVariant, currentVariant, 'add-to-cart-button', priceStore.currency)
								}}
								id={'add-to-cart-button'}
								disabled={loading}
								loading={loading}
							>
								Add to cart
							</Button.Primary>
							<Features
								features={data.features.filter((feature) => !feature.variant_key || feature.variant_key === selectedVariant)}
								region={settingsStore.currentRegion}
								variant={{
									...currentVariant,
									description: null,
									currency: priceStore.currency,
								}}
							/>
						</>
					)}
				</div>
			</section>
		)
	})
)

export const CardSelectionContainer: FC<CardSelectionProps> = (props) => {
	const changeSelectedVariant = useShopStore((state) => state.changeSelectedVariant)
	const variantSelectionChange = useCallback(
		(variantKey: string) => {
			changeSelectedVariant(variantKey)
		},
		[changeSelectedVariant]
	)

	return (
		<div className={styles.selection_container}>
			{props.variants.map((option, i: number) => {
				const isSelected = () => {
					return props.selectedVariantKey === option.key
				}

				return (
					<label
						key={`${option.key}${i}`}
						aria-label={option.key}
					>
						<input
							type="radio"
							name="card-selection"
							value={option.key}
							className="vh"
							onChange={() => {
								if (option.disabled) return
								variantSelectionChange(option.key)
							}}
							checked={isSelected()}
							disabled={option.disabled}
						/>
						<CardSelectionItem
							selected={isSelected()}
							title={option.name}
							description={option.description}
							checklist={option.checklist}
							price={option.priceString + (option.key === 'monthly' ? '/mo' : '')}
							comparePrice={option.comparePriceString + (option.key === 'monthly' ? '/mo' : '')}
							badge={option.badge}
						/>
					</label>
				)
			})}
		</div>
	)
}

export const CardSelectionItem: FC<CardSelectionItemProps> = (props) => {
	const { highlightColor = '#0038FF', selected, error, title, checklist, price, comparePrice, badge } = props

	const boxShadowColor = error ? '#df1c2f' : selected ? highlightColor : '#d3d3d3'
	const boxShadowWidth = selected ? '2px' : '1px'

	return (
		<div
			className={cx(styles.card_container, { [styles.selected]: selected })}
			style={{
				boxShadow: `${boxShadowColor} 0 0 0 ${boxShadowWidth} inset`,
			}}
		>
			<Type.Headline6
				as={'p'}
				className={styles.card_selection__title}
			>
				{title}
				{badge && (
					<Badge.Blue
						className={styles.eyebrow}
						type={'discount'}
					>
						{badge}
					</Badge.Blue>
				)}
				<Type.Body1
					as="span"
					className={styles.card_price}
				>
					{comparePrice !== price ? <s>{comparePrice}</s> : null}
					<strong>{price}</strong>
				</Type.Body1>
			</Type.Headline6>
			{checklist?.length > 0 && (
				<ul className={styles.checklist}>
					{checklist.map((item, index) => (
						<li key={index}>
							<Icon
								name={'CheckDark'}
								color={'#1862FF'}
								size={16}
							/>
							<Type.Body2>{item}</Type.Body2>
						</li>
					))}
				</ul>
			)}
		</div>
	)
}

export const Features = ({ features, region, variant }: { features: any[]; region: RegionShort; variant: VariantCore }) => {
	return (
		<div className={styles.card_features}>
			{features.map((feature, index) => (
				<div
					key={index}
					className={styles.card_feature}
				>
					<Icon
						name={feature.icon}
						size={24}
						color="#000"
					/>
					<Type.Body2>
						{feature.title === 'getShippingText' ? getShippingText(variant, region) : feature.title}
						{/* <span className={styles.card_feature_description}>{feature.description}</span> */}
					</Type.Body2>
				</div>
			))}
		</div>
	)
}

export const ProductTabs: FC<TabProps> = ({ tabs }) => {
	const [activeTab, setActiveTab] = useState(0)
	return (
		<div className={styles.tabs_wrapper}>
			<div className={styles.tabs_buttons}>
				{tabs.map((tab, index) => (
					<Button.Empty
						id={'tab-button-' + index}
						key={index}
						onClick={() => setActiveTab(index)}
						className={cx(styles.tab_button, {
							[styles.active]: activeTab === index,
						})}
					>
						<Type.Body1>{tab.title}</Type.Body1>
					</Button.Empty>
				))}
			</div>
			<div className={styles.tab_content}>
				<Type.Body1>
					<span dangerouslySetInnerHTML={{ __html: tabs[activeTab].content }} />
				</Type.Body1>
			</div>
		</div>
	)
}

const WaitlistForm = () => {
	const [selectedReason, setSelectedReason] = useState('Fall asleep faster')
	const [email, setEmail] = useState('')
	const [isSubmitted, setIsSubmitted] = useState(false)
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(false)

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		setError('')
		setLoading(true)
		try {
			const response = await fetch('/api/klaviyo-sleep-elixir', {
				method: 'post',
				body: JSON.stringify({ email, reason: selectedReason }),
				headers: { 'content-type': 'application/json' },
			})
			const result = await response.json()
			if (result.success) {
				setIsSubmitted(true)
			} else {
				setError('Failed to submit. Please try again.')
			}
		} catch (err) {
			setError('An error occurred. Please try again later.')
		}
		setLoading(false)
	}

	return (
		<form
			onSubmit={handleSubmit}
			className={styles.waitlist_form}
		>
			{!isSubmitted ? (
				<>
					<Type.Headline5>Join the Sleep Elixir waitlist</Type.Headline5>
					<Type.Body1 className={styles.email_label}>Enter your email to be notified when Sleep Elixir is available</Type.Body1>
					<input
						type="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						className={styles.waitlist_input}
						placeholder="Email"
						required
					/>
					<Type.Body1>What benefit most excites you about Sleep Elixir?</Type.Body1>
					<div className={styles.waitlist_radio_group}>
						{['Fall asleep faster', 'Calm racing thoughts at bedtime', 'Melatonin-free formula'].map((label) => (
							<label
								key={label}
								className={styles.waitlist_radio_label}
							>
								<input
									type="radio"
									name="reason"
									value={label}
									checked={selectedReason === label}
									onChange={() => setSelectedReason(label)}
									className={styles.waitlist_radio_input}
									required
								/>
								<Type.Body2 as="span">{label}</Type.Body2>
							</label>
						))}
					</div>
					<Button.Primary
						id="submit-waitlist-email"
						type="submit"
						className={styles.waitlist_submit}
						loading={loading}
					>
						Submit
					</Button.Primary>
					{error && <Type.Body1 className={styles.error_message}>{error}</Type.Body1>}
				</>
			) : (
				<Type.Headline5 className={styles.success_message}>Thank you for joining the waitlist. We will notify you when Sleep Elixir is available for purchase.</Type.Headline5>
			)}
		</form>
	)
}
