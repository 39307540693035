import { clock, delivery, eightbox, IconGenWithLabel, moon, shield_check } from 'components/Icon'

export const getBenefits = (warrantyAmount: number): { icon: IconGenWithLabel; description: string }[] => {
	const warranty = {
		icon: shield_check,
		description: `${warrantyAmount}-year warranty`,
	}
	const trial = {
		icon: moon,
		description: '30-night risk-free trial',
	}

	const returns = {
		icon: eightbox,
		description: 'Free returns',
	}

	const shipping = {
		icon: delivery,
		description: 'Free shipping',
	}

	if (warrantyAmount <= 0) {
		return [trial, returns, shipping]
	}

	return [warranty, trial, returns, shipping]
}

export const getSleepElixirBenefits = (hasSleepElixirSubscription = false): { icon: IconGenWithLabel; description: string }[] => {
	const returns = {
		icon: clock,
		description: 'Cancel any time',
	}

	const shipping = {
		icon: delivery,
		description: 'Free shipping',
	}

	return hasSleepElixirSubscription ? [shipping, returns] : [shipping]
}
