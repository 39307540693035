import { StackItemProps } from 'components/Phantom/_shop/Visuals'

export const getSleepElixirVisuals = (): StackItemProps[] => {
	return [
		{
			type: 'image',
			data: {
				src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/slide1-desktop-v4.png',
				mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/slide1-mobile-v4.png',
				alt: '',
				className: 'sleep_elixir_product',
			},
			pills: [
				{
					text: 'Non-habit forming',
				},
				{
					text: 'No melatonin',
				},
				{
					text: 'Free of heavy metals',
				},
				{
					text: 'No added sugar ',
				},
				{
					text: 'No artificial sweeteners',
				},
			],
		},
		{
			type: 'image',
			data: {
				src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/new-carousel-assets/Slide+2.png',
				mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/new-carousel-assets/Slide+2+-+Mobile.png',
				alt: '',
				objectFit: 'cover',
			},
		},
		{
			type: 'image',
			data: {
				src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/new-carousel-assets/Slide+3.png',
				mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/new-carousel-assets/Slide+3-+Mobile.png',
				alt: '',
				objectFit: 'cover',
			},
		},
		{
			type: 'image',
			data: {
				src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/new-carousel-assets/slide-4-v11-desktop.png',
				mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/new-carousel-assets/slide-4-v11-mobile.png',
				alt: '',
				objectFit: 'cover',
			},
		},
		{
			type: 'image',
			data: {
				src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/new-carousel-assets/Slide+5.png',
				mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/new-carousel-assets/Slide+5+-+Mobile.png',
				alt: '',
				objectFit: 'cover',
			},
		},
		{
			type: 'image',
			data: {
				src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/new-carousel-assets/Slide+6.png',
				mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/new-carousel-assets/Slide+6+-+Mobile.png',
				alt: '',
				objectFit: 'cover',
				className: 'sleep_elixir_nutritional_info',
			},
		},
		{
			type: 'image',
			data: {
				src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/new-carousel-assets/Slide+7.png',
				mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/new-carousel-assets/Slide+7+-+Mobile.png',
				alt: '',
				objectFit: 'cover',
				className: 'sleep_elixir_nutritional_info',
			},
		},
		{
			type: 'image',
			data: {
				src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/new-carousel-assets/Slide+8.png',
				mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/new-carousel-assets/Slide+8+-+Mobile.png',
				alt: '',
				objectFit: 'cover',
			},
		},
		{
			type: 'image',
			data: {
				src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/new-carousel-assets/Slide+9.png',
				mobileSrc: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/sleep-elixir/new-carousel-assets/Slide+9+Mobile.png',
				alt: '',
				objectFit: 'cover',
				className: 'sleep_elixir_nutritional_info',
			},
		},
	]
}
