import { FC, useEffect, useState } from 'react'
import { useScrollDirection } from 'components/_hooks/useScrollDirection'
import cx from 'classnames'
import styles from 'components/NavBar/NavBar.module.scss'
import { A } from 'components/basic/A'
import * as React from 'react'
import { Countdown } from './Countdown'
import { Settings } from 'react-slick'
import { SlickSlider } from 'components/SlickSlider'
import { RegionShort } from 'utils/internationalization'
import { amClosedPromoBar } from 'events/amplitude'
import { ClientOnly } from 'components/ClientOnly'
import { close, Icon } from 'components/Icon'
import { EMAIL_SMS_DISCOUNT_CODE } from 'stripe_lib/discounts'

interface PromoBarProps {
	promo_bar_text?: string
	promo_bar_href?: string
	promo_bar_date?: Date | string
	promo_bar_click?: () => void
	hidePromoBar?: boolean
	path?: string
	showTimer: boolean
	region: RegionShort
	saleName: string

	setClosed: (closed: boolean) => void
}

const settings: Settings = {
	slidesToShow: 1,
	speed: 400,
	variableWidth: true,
	centerMode: false,
	autoplay: true,
	autoplaySpeed: 5000,
	arrows: false,
	draggable: true,
}

const filterPromoText = (promo_bar_text: string, saleName: string) => {
	const regexyp = new RegExp(`${saleName}: Get up to .+ off`, 'gm')
	return promo_bar_text.replace(' exclusive:', ':').replace(' the Pod', '').replace(regexyp, '')
}

export const PromoBar: FC<PromoBarProps> = (props) => {
	const [mounted, setMounted] = useState(props.region !== '')
	const [open, setOpen] = useState(true)
	const scrollDirection = useScrollDirection(50)

	useEffect(() => {
		setOpen(scrollDirection !== 'down')
	}, [scrollDirection])

	const handleCloseClick = () => {
		amClosedPromoBar()
		setOpen(false)
		window.sessionStorage.setItem('closedPromoBar', 'true')
	}

	useEffect(() => {
		const permanentlyClosed = getClosed()
		const promoBarClosed = permanentlyClosed || !open
		props.setClosed(promoBarClosed)
	}, [open])

	const promoStyle = {
		display: props.hidePromoBar ? 'none' : 'inherit',
	}

	// Purely for the making the sentence read better with the timer
	const promoBarText = props.showTimer ? props.promo_bar_text.replace('Get up', 'Up') : props.promo_bar_text

	const main_item = {
		src: '',
		alt: '',
		component: (
			<div className={styles.main_item}>
				{props.promo_bar_href ? (
					<A
						href={props.promo_bar_href}
						id={'promo-bar-link'}
						className={styles.promo_text}
					>
						<span
							className={styles.promo_inner_text_desktop}
							dangerouslySetInnerHTML={{ __html: props.hidePromoBar ? '' : promoBarText }}
						/>
						<span
							className={styles.promo_inner_text_mobile}
							dangerouslySetInnerHTML={{ __html: props.hidePromoBar ? '' : filterPromoText(promoBarText, props.saleName) }}
						/>
					</A>
				) : (
					<>
						<p
							className={cx(styles.promo_text, styles.promo_inner_text_desktop, { [styles.promo_inner_text_clickable]: props.promo_bar_click })}
							dangerouslySetInnerHTML={{ __html: props.hidePromoBar ? '' : promoBarText }}
							onClick={props.promo_bar_click}
						/>
						<p
							className={cx(styles.promo_text, styles.promo_inner_text_mobile, { [styles.promo_inner_text_clickable]: props.promo_bar_click })}
							dangerouslySetInnerHTML={{ __html: props.hidePromoBar ? '' : filterPromoText(promoBarText, props.saleName) }}
							onClick={props.promo_bar_click}
						/>
					</>
				)}
				{props.showTimer && (
					<>
						<br className={'hide_sm_up'} />
						<Countdown date={props.promo_bar_date} />
					</>
				)}
			</div>
		),
	}

	const emailDiscount = typeof window !== 'undefined' && sessionStorage.getItem('appliedDiscountCode')?.toLowerCase() === EMAIL_SMS_DISCOUNT_CODE.toLowerCase()

	let items =
		(props.region === '' || props.region === 'ca') && !props.path?.includes('/product/the-base')
			? [
					main_item,
					{
						src: '',
						alt: '',
						component: (
							<A
								className={styles.promo_text}
								href={'/financing'}
								id={'promo-bar-financing-link'}
							>
								Financing starting from 0% APR with $0 down
							</A>
						),
					},
			  ]
			: [main_item]

	//don't show any usual promos on this page
	if (props.path?.startsWith('/bed-cooling-hsa') || (typeof window !== 'undefined' && props.path?.includes('/pod4-member') && localStorage.getItem('redirectToBase') === 'true')) {
		items = []
	}
	if (props.region === '' && (props.path?.startsWith('/bed-cooling-hsa') || (!props.path?.includes('/product/the-base') && !props.path?.includes('/pod4-member')))) {
		items.push({
			src: '',
			alt: '',
			component: props.path?.startsWith('/truemed') ? (
				<p className={styles.promo_text}>
					<span style={{ paddingRight: '0.5ch' }}>Save an average of 30% with FSA/HSA</span> <u> Learn how</u>
				</p>
			) : (
				<A
					className={styles.promo_text}
					href={'/truemed'}
					id={'promo-bar-hsahfa-link'}
				>
					<span style={{ paddingRight: '0.5ch' }}>Save an average of 30% with FSA/HSA</span> <u> Learn how</u>
				</A>
			),
		})
	}

	const noScrollPromoBar = true
	if (props.promo_bar_text.includes('with code') || emailDiscount || noScrollPromoBar) {
		items.length = 1
	}

	return (
		<div
			className={cx(styles.promo_bar, mounted ? styles.shown : '')}
			id={'promo_bar'}
			style={promoStyle}
		>
			<ClientOnly>
				<div className={`${items.length === 1 ? styles.single_element : ''}`}>
					{items.length > 1 ? (
						<SlickSlider
							settings={{ ...settings, onInit: () => setMounted(true), initialSlide: 6, autoplay: true }}
							id={'promo-bar-slider'}
							showThumbs={false}
							items={[...items, ...items, ...items, ...items, ...items, ...items, ...items]}
							className={`${styles.slider}`}
						/>
					) : (
						items[0]?.component
					)}
				</div>
			</ClientOnly>

			<button
				id={'promo-bar-toggle-button'}
				className={styles.close_button}
				onClick={handleCloseClick}
			>
				<p className={'vh'}>close</p>
				<Icon
					color={'black'}
					icon={close}
				/>
			</button>
		</div>
	)
}

const getClosed = () => {
	if (typeof window !== 'undefined') {
		return !!window.sessionStorage.getItem('closedPromoBar')
	}
	return false
}
