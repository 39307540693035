import { FC, useRef } from 'react'
import styles from './TextChange.module.scss'
import { TextChangeProps } from './TextChange.types'
import { Type } from 'components/Type'
import { useGSAP } from '@gsap/react'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import gsap from 'gsap'

export const TextChange: FC<TextChangeProps> = (props) => {
	const { className } = props

	const mainRef = useRef(null)
	const animatedRef1 = useRef(null)
	const animatedRef2 = useRef(null)

	useGSAP(() => {
		gsap.registerPlugin(ScrollTrigger)
		ScrollTrigger.create({
			trigger: mainRef.current,
			animation: gsap.to(animatedRef1.current, {
				opacity: 0.2,
				duration: 3,
				translateY: 0,
			}),
			start: 'top center',
			end: 'top 100px',
			pin: false,
			markers: false,
			scrub: true,
		})
		ScrollTrigger.create({
			trigger: mainRef.current,
			animation: gsap.to(animatedRef2.current, {
				opacity: 1,
				duration: 3,
				scale: 1,
				translateY: 0,
			}),
			start: 'top center',
			end: 'top 200px',
			pin: false,
			markers: false,
			scrub: true,
		})
	})

	return (
		<section className={`${styles.container} ${className}`}>
			<header
				className={`${styles.heading}`}
				ref={animatedRef1}
			>
				<div ref={mainRef}></div>
				<Type.Headline2 animateOnScroll>Sleep Elixir helps you ease into sleep, faster, by leveraging over 600 million hours of sleep data and 100% natural ingredients</Type.Headline2>
			</header>
			<div
				ref={animatedRef2}
				className={styles.content}
			>
				<Type.Headline3>Developed in scientific collaboration with Dr. Peter Attia</Type.Headline3>
				<Type.Body1 className={styles.text}>
					Precisely formulated in collaboration with leading experts in longevity and sleep science, Sleep Elixir sets a new standard for sleep supplementation.
				</Type.Body1>
			</div>
		</section>
	)
}

export const TextChangeSingle: FC<TextChangeProps> = (props) => {
	return (
		<section className={`${styles.container} ${styles.gray}`}>
			<header className={`${styles.heading2}`}>
				<div className={styles.content}>
					<Type.Headline3 animateOnScroll>Developed in scientific collaboration with Dr. Peter Attia</Type.Headline3>
					<Type.Body1
						className={styles.text}
						animateOnScroll
					>
						Precisely formulated in collaboration with leading experts in longevity and sleep science, Sleep Elixir sets a new standard for sleep supplementation.
					</Type.Body1>
				</div>
			</header>
		</section>
	)
}
