import { FC, useEffect, useRef, useState } from 'react'
import styles from './AppTour.module.scss'
import { AppTourProps, KeyframeSectionProps, KeyframesProps } from './types'
import animation from './tour_animation.json'
import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { getDefaultKeyframes } from './utils'
import { Img } from 'components/basic/Img'
import { Type } from 'components/Type'

export const AppTour: FC<AppTourProps> = (props) => {
	const [Lottie, setLottie] = useState(undefined)

	async function loadLottie() {
		const dependency = await import('lottie-react')
		setLottie(dependency)
	}

	useEffect(() => {
		loadLottie()
	}, [])

	return Lottie ? (
		<AppTourClientComponent
			Lottie={Lottie}
			{...props}
		/>
	) : (
		<></>
	)
}

const AppTourClientComponent: FC<AppTourProps> = (props) => {
	const LottieObject = props.Lottie.useLottie({
		animationData: animation,
		loop: false,
		autoplay: false,
	})
	const containerRef = useRef(null)
	const keyframes = getDefaultKeyframes()

	useGSAP(
		() => {
			gsap.registerPlugin(ScrollTrigger)
			if (!LottieObject) return

			LottieObject.goToAndStop(0, true)

			for (let i = 0; i < keyframes.length; i++) {
				const keyframe = keyframes[i]
				ScrollTrigger.create({
					trigger: `#keyframe-section-${i}`,
					start: () => `${window.innerHeight * -0.25} center`,
					onEnter: () => {
						const startFrame = keyframe.startFrame
						const endFrame = keyframe.endFrame
						LottieObject.playSegments([startFrame, endFrame], true)
					},
					onLeaveBack: () => {
						const endFrame = keyframe.endFrame
						const startFrame = keyframe.startFrame
						LottieObject.playSegments([endFrame, startFrame], true)
					},
				})

				gsap.from(`#keyframe-section-${i}-title`, {
					y: 100,
					opacity: 0,
					scrollTrigger: {
						trigger: `#keyframe-section-${i}`,
						toggleActions: 'restart none none reverse',
						start: () => `${window.innerHeight * -0.2} center`,
					},
				})

				gsap.from(`#keyframe-section-${i}-image`, {
					y: 100,
					opacity: 0,
					scrollTrigger: {
						trigger: `#keyframe-section-${i}`,
						toggleActions: 'restart none none reverse',
						start: () => `${window.innerHeight * -0.2} center`,
					},
				})

				gsap.from(`#keyframe-section-${i}-tag`, {
					y: 100,
					opacity: 0,
					delay: 0,
					scrollTrigger: {
						trigger: `#keyframe-section-${i}`,
						toggleActions: 'restart none none reverse',
						start: () => `${window.innerHeight * -0.2} center`,
					},
				})
			}
		},
		{ dependencies: [LottieObject], revertOnUpdate: true }
	)

	return (
		<section
			className={styles.container}
			ref={containerRef}
		>
			<div className={styles.header_container}>
				<header className={styles.header}>
					<Type.Eyebrow>Coming soon</Type.Eyebrow>
					<Type.Headline2>Track and optimize Sleep Elixir with an integrated app experience</Type.Headline2>
					<Type.Body1 className={styles.subheader}>
						From your first dose to long-term results, Sleep Elixir is the only supplement to integrate seamlessly with Pod technology and the Eight Sleep app for outstanding impact⁴
					</Type.Body1>
				</header>
			</div>
			<Keyframes keyframes={keyframes} />
			<div className={styles.rails}>{LottieObject.View}</div>
			<Type.SmallPrint className={styles.smallprint}>⁴Tracking and tagging only available for Eight Sleep Autopilot members</Type.SmallPrint>
		</section>
	)
}

const Keyframes: FC<KeyframesProps> = (props) => (
	<div className={styles.keyframes}>
		{props.keyframes.map((keyframe, i) => (
			<KeyframeSection
				{...keyframe}
				key={i}
				id={`keyframe-section-${i}`}
			/>
		))}
	</div>
)

const KeyframeSection: FC<KeyframeSectionProps> = (props) => (
	<article
		className={styles.keyframe_section}
		id={props.id}
	>
		<div className={styles.divider}>
			<Img
				src={props.mobileImage}
				alt={''}
				id={props.id + '-image'}
				className={styles.mobile_image}
			/>
		</div>

		{/* <Type.Eyebrow
			className={styles.tag}
			id={props.id + '-tag'}
		>
			{props.tag}
		</Type.Eyebrow> */}
		<Type.Headline2
			as={'h3'}
			id={props.id + '-title'}
		>
			{props.title}
		</Type.Headline2>

		<Type.Body1
			className={styles.description}
			id={props.id + '-description'}
		>
			{props.description}
		</Type.Body1>
	</article>
)
