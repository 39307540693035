import { CSSProperties, FC, useState } from 'react'
import styles from './Checkbox.module.scss'
import { CheckboxProps } from './Checkbox.types'
import cx from 'classnames'

export const Checkbox: FC<CheckboxProps> = (props) => {
	const { checked, onChange, color = '#246aff', id, label, interactive = true, className } = props

	const [localChecked, setLocalChecked] = useState(false)

	const handleChange = (checked: boolean) => {
		setLocalChecked(checked)
		onChange?.(checked)
	}

	const _checked = checked ?? localChecked

	if (!interactive) {
		return (
			<span
				className={cx(styles.checkbox, className)}
				style={{ '--checkbox-color': color } as CSSProperties}
				data-checked={_checked}
				id={id}
			/>
		)
	}

	return (
		<label
			className={cx(styles.checkbox, className)}
			style={{ '--checkbox-color': color } as CSSProperties}
			data-checked={_checked}
			id={id}
		>
			<input
				type={'checkbox'}
				checked={_checked}
				onChange={(e) => handleChange(e.target.checked)}
				className={'vh'}
				disabled={!interactive}
			/>
			<span className={styles.focus_target} />
			<span className={'vh'}>{label}</span>
		</label>
	)
}
