import { FC } from 'react'
import styles from './MembershipModal.module.scss'
import { MembershipModalProps } from './types'
import { useRootStore } from 'components/_hooks/useRootStore'
import { observer } from 'mobx-react'
import SlideDown from 'components/SlideDown/SlideDown'
import cx from 'classnames'
import { Img } from 'components/basic/Img'
import { getTemperature } from 'components/_utils/temperatureUtils'

export const MembershipModalConnected = observer(() => {
	const { settingsStore } = useRootStore()

	return <MembershipModal metric={settingsStore.metricRegion} />
})

interface FeatureBoxProps {
	title: string
	features: { title: string; description: string }[]
}

export const FeatureBox = ({ title, features }: FeatureBoxProps) => {
	return (
		<div className={styles.feature_box}>
			<h3>{title}</h3>
			<div className={styles.feature_list}>
				{features.map((feature, i) => (
					<SlideDown
						top={<div className={styles.sliderFeature}>{feature.title}</div>}
						key={i}
						className={styles.sliderFeatureContainer}
						inverted={true}
					>
						<span className={styles.sliderDescription}>{feature.description}</span>
					</SlideDown>
				))}
			</div>
		</div>
	)
}

export const MembershipModal: FC<MembershipModalProps> = (props) => {
	const { metric = false } = props

	const lowTemp = getTemperature(55, metric)
	const highTemp = getTemperature(110, metric)

	const autopilotPlans = [
		{
			name: 'Standard',
			features: [
				{ title: 'Automatic temperature', description: `Automatic temperature changes, with temperatures between ${lowTemp} - ${highTemp}.` },
				{ title: 'Sleep & health reports', description: 'Reports on your sleep time, sleep phases, HRV, heart rate, respiratory rate, and more.' },
				{ title: 'Vibration & thermal alarm', description: 'A chest-level vibration and gradual thermal change to wake you up' },
				{ title: 'Snoring detection', description: 'Detailed reports of when you (or your partner) snore and how strong the snoring is.' },
				{ title: 'Snoring mitigation', description: 'Automatic elevation of the head area to reduce or stop snoring without you waking up.' },
				{ title: 'Complimentary maintenance insert delivery', description: 'Automatically delivers inserts to maintain your Pod and keep it at peak performance' },
			],
		},
		{
			name: 'Enhanced',
			features: [
				{ title: 'Automatic temperature', description: `Automatic temperature changes, with temperatures between ${lowTemp} - ${highTemp}.` },
				{ title: 'Sleep & health reports', description: 'Reports on your sleep time, sleep phases, HRV, heart rate, respiratory rate, and more.' },
				{ title: 'Vibration & thermal alarm', description: 'A chest-level vibration and gradual thermal change to wake you up' },
				{ title: 'Snoring detection', description: 'Detailed reports of when you (or your partner) snore and how strong the snoring is.' },
				{ title: 'Snoring mitigation', description: 'Automatic elevation of the head area to reduce or stop snoring without you waking up.' },
				{ title: 'Complimentary maintenance insert delivery', description: 'Automatically delivers inserts to maintain your Pod and keep it at peak performance' },
				{ title: 'Extended 5-year warranty', description: 'We will replace your Pod  if any issues occur, as long as you purchased through this site and have an active plan (terms apply).' },
			],
		},
	]

	return (
		<div className={styles.container}>
			<div className={styles.content}>
				<div className={styles.header}>
					<h2>Better sleep, effortlessly</h2>
					<div>
						<div className={styles.normal_text}>
							Autopilot automatically adjusts temperature and elevation* so that you get the optimal amount of Deep and REM sleep every night. If you haven't had optimal Deep or REM sleep in previous
							nights, Autopilot will adjust temperature the next night to make up for it.
						</div>
						<div className={styles.footer}>*Exclusive to Pod 4 Ultra</div>
					</div>
				</div>
				<div className={styles.comparison_section}>
					<h2>What's included in Autopilot?</h2>
					<div className={styles.normal_text}>Depending on what plan you choose, Autopilot includes the following</div>
					<div className={styles.side_by_side}>
						<FeatureBox
							title={autopilotPlans[0].name}
							features={autopilotPlans[0].features}
						/>
						<FeatureBox
							title={autopilotPlans[1].name}
							features={autopilotPlans[1].features}
						/>
					</div>
				</div>
				<div className={styles.question_section}>
					<div>
						<div className={cx(styles.normal_text, styles.question)}>Why does the Pod need Autopilot?</div>
						<div className={styles.normal_text}>
							{`The Pod needs Autopilot to ensure sleepers experience optimal sleep stages by adjusting bed temperature and elevation throughout the night. This is crucial because a key factor for poor sleep is maintaining the wrong temperature. Autopilot aims to match your body’s natural circadian rhythm and temperature needs for each sleep stage, enhancing the quality of deep and REM sleep.`}
						</div>
					</div>
					<div>
						<div className={cx(styles.normal_text, styles.question)}>Can I buy the Pod without Autopilot?</div>
						<div className={styles.normal_text}>Autopilot is required for the first 12 months (cancel any time), and covers two separate users per Pod.</div>
					</div>
					<div>
						<div className={cx(styles.normal_text, styles.question)}>What is the commitment length for Autopilot?</div>
						<div className={styles.normal_text}>Autopilot comes in annual plans and is billed once yearly </div>
					</div>
					<div>
						<div className={cx(styles.normal_text, styles.question)}>Do I need to get Autopilot for both sides of the bed?</div>
						<div className={styles.normal_text}>If one partner has Autopilot, they can add a partner, for free so they can both enjoy the better sleep that comes with Autopilot. </div>
					</div>
				</div>
				<div className={styles.image_section}>
					{' '}
					<Img
						alt={''}
						className={styles.the_img}
						src={'https://eightsleep.imgix.net/assets/member_modal_2019.png'}
					/>
				</div>
				{!metric && (
					<div className={styles.final_section}>
						<div className={styles.final_pitch}>Save up to $20/month on your energy bills*</div>
						<div className={styles.normal_text}>
							With the Pod, you can save as much as $20/mo* on average by using your AC and heat less. The Pod cools and heats you so efficiently, you may not have to rely on external cooling or
							heating sources.
						</div>
						<div className={styles.footer}>* Individual experiences may differ based on house size, ambient and outdoor temperatures, and HVAC type and condition</div>
					</div>
				)}
			</div>
		</div>
	)
}
