import { ButtonComponent, ButtonInteriorProps, ButtonProps } from './types'
import { FC, MouseEvent, useEffect, useRef } from 'react'
import styles from './Button.module.scss'
import { formatLink } from 'utils'
import { amClickedButton } from 'events/amplitude'
import cx from 'classnames'
import { ButtonLoadingSpinner } from './ButtonLoadingSpinner'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Link from 'components/basic/LinkWithLocale/LinkWithLocale'
import { Icon } from 'components/Phantom/Icon'
import * as THREE from 'three'
import { PerspectiveCamera, Scene, WebGLRenderer } from 'three'
import { isWebGLAvailable } from 'components/_utils/threeUtils'
import { useAnimateInOnScroll } from 'components/_hooks/useAnimateInOnScroll'
import { A } from '../../basic/A'

/**
 * A generic component for clickable objects with analytic tracking. This component supports
 * regular buttons as well as links. You can use the  `<Button.Empty/>` tag to create a clickable object
 * that behaves effectively like a `<div/>`.
 *
 * The component can be extended to create new button types. Please opt to extend the component
 * rather than passing new styles to the `className` prop.
 */
export const Button: ButtonComponent = (props) => {
	const ref = useRef(null)
	const href = typeof props.href === 'string' ? formatLink(props.href) : props.href

	useAnimateInOnScroll(ref, { ...props.animationSettings, disable: !props.animateOnScroll })

	const handleClick = (e: MouseEvent) => {
		amClickedButton(props.title || '', props.id)

		if (props.onClick) {
			props.onClick(e)
		}
	}

	const handleLinkClick = (e: MouseEvent) => {
		const link = href.toString()

		if (typeof props.onClick === 'function') {
			if (link === '#') e.preventDefault()
			props.onClick(e)
		}

		const linkID = props.id || 'unknown_id'
		amClickedButton(link, linkID)
	}

	if (href) {
		if (href.toString().includes('#') && !props.disableSmoothScroll) {
			return (
				<AnchorLink
					ref={ref}
					id={props.id}
					className={cx(styles.base, props.injectedClass, props.className)}
					role={'link'}
					target={props.target}
					rel={props.target === '_blank' ? 'noopener' : undefined}
					onClick={handleLinkClick}
					href={href.toString()}
					aria-label={props.ariaLabel}
					style={props.style}
				>
					<ButtonContent {...props} />
				</AnchorLink>
			)
		}

		if (props.aElement) {
			return (
				<A
					id={props.id}
					className={cx(styles.base, props.injectedClass, props.className)}
					role={'link'}
					target={props.target}
					rel={props.target === '_blank' ? 'noopener' : undefined}
					aria-label={props.ariaLabel}
					href={href}
				>
					<ButtonContent {...props} />
				</A>
			)
		}

		return (
			<Link
				href={href}
				legacyBehavior
			>
				<a
					ref={ref}
					id={props.id}
					className={cx(styles.base, props.injectedClass, props.className)}
					role={'link'}
					target={props.target}
					rel={props.target === '_blank' ? 'noopener' : undefined}
					onClick={handleLinkClick}
					aria-label={props.ariaLabel}
					style={props.style}
				>
					<ButtonContent {...props} />
				</a>
			</Link>
		)
	}

	return (
		<button
			ref={ref}
			className={cx(styles.base, props.injectedClass, props.className)}
			onClick={handleClick}
			disabled={props.disabled || props.loading}
			id={props.id}
			title={props.title}
			type={props.type}
			aria-label={props.ariaLabel}
			style={props.style}
		>
			<ButtonContent {...props} />
		</button>
	)
}

const ButtonContent: FC<ButtonInteriorProps> = (props) => {
	return (
		<>
			<span
				style={{ opacity: props.loading ? 0 : 1 }}
				className={props.empty ? '' : styles.button_span}
			>
				{props.children}
			</span>
			{props.loading && <ButtonLoadingSpinner color={props.spinnerColor} />}
		</>
	)
}

const Primary: FC<ButtonProps> = (props) => {
	return (
		<Button
			{...props}
			injectedClass={styles.primary}
			spinnerColor={'black'}
		>
			{props.children}
		</Button>
	)
}

const White: FC<ButtonProps> = (props) => {
	return (
		<Button
			{...props}
			injectedClass={styles.white}
			spinnerColor={'black'}
		>
			{props.children}
		</Button>
	)
}

const Blue: FC<ButtonProps> = (props) => {
	return (
		<Button
			{...props}
			injectedClass={styles.blue}
			spinnerColor={'black'}
		>
			{props.children}
		</Button>
	)
}

const Dark: FC<ButtonProps> = (props) => {
	return (
		<Button
			{...props}
			injectedClass={styles.dark}
			spinnerColor={'black'}
		>
			{props.children}
		</Button>
	)
}

const TextLink: FC<ButtonProps> = (props) => {
	return (
		<Button
			{...props}
			injectedClass={styles.text_link}
			spinnerColor={'black'}
		>
			{props.children}
			<Icon
				name={'ChevronRightLight'}
				color={'#1862FF'}
			/>
		</Button>
	)
}

const TextLinkWhite: FC<ButtonProps> = (props) => {
	return (
		<Button
			{...props}
			injectedClass={styles.text_link_white}
			spinnerColor={'black'}
		>
			{props.children}
			<Icon
				name={'ArrowRightLight'}
				color={'white'}
			/>
		</Button>
	)
}

const Empty: FC<ButtonProps> = (props) => {
	return (
		<Button
			{...props}
			injectedClass={styles.empty}
			spinnerColor={'black'}
			empty
		>
			{props.children}
		</Button>
	)
}

const Plus: FC<ButtonProps> = (props) => {
	return (
		<Button
			{...props}
			injectedClass={styles.plus}
			spinnerColor={'black'}
		>
			{props.children}
			<Icon
				name="PlusDark"
				color={'black'}
			/>
		</Button>
	)
}

const PlusSmall: FC<ButtonProps> = (props) => {
	return (
		<Button
			{...props}
			injectedClass={styles.plus_small}
			spinnerColor={'black'}
		>
			{props.children}
			<Icon
				name="PlusDark"
				color={'black'}
				size={'16px'}
			/>
		</Button>
	)
}

const VideoGrey: FC<ButtonProps> = (props) => {
	return (
		<Button
			{...props}
			injectedClass={styles.plus}
			spinnerColor={'black'}
		>
			<Icon
				name="PlayDark"
				color={'black'}
				size={'24px'}
			/>
			{props.children}
		</Button>
	)
}

const VideoGreyOutline: FC<ButtonProps> = (props) => {
	return (
		<Button
			{...props}
			injectedClass={styles.video_grey_outline}
			spinnerColor={'black'}
		>
			<Icon
				name="PlayDark"
				color={'black'}
				size={'24px'}
			/>
			{props.children}
		</Button>
	)
}

const InfoGrey: FC<ButtonProps> = (props) => {
	return (
		<Button
			{...props}
			injectedClass={styles.info_grey}
			spinnerColor={'black'}
		>
			<Icon
				name="InformationCircleLight"
				color={'black'}
				size={16}
			/>
			{props.children}
		</Button>
	)
}

const vertexShader = `
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
`

const fragmentShader = `
    varying vec2 vUv;
    uniform float u_time;

	// Helper functions to generate pseudo-random noise
    vec3 mod289(vec3 x) {return x - floor(x * (1.0 / 289.0)) * 289.0;}
 	vec2 mod289(vec2 x) {return x - floor(x * (1.0 / 289.0)) * 289.0;}
 	vec3 permute(vec3 x) {return mod289(((x*34.0)+1.0)*x);}

	// Simplex noise. Don't worry about understanding this,
	// it's the kind of function that one guy wrote in a textbook
	// and now everyone uses it without understanding it.
    float snoise(vec2 v) {
      const vec4 C = vec4(0.211324865405187, 0.366025403784439,
                -0.577350269189626, 0.024390243902439);
      vec2 i  = floor(v + dot(v, C.yy) );
      vec2 x0 = v -   i + dot(i, C.xx);

      vec2 i1;
      i1.x = (x0.x > x0.y) ? 1.0 : 0.0;
      i1.y = (x0.x > x0.y) ? 0.0 : 1.0;
      vec4 x12 = x0.xyxy + C.xxzz;
      x12.xy -= i1;

      i = mod289(i);
      vec3 p = permute( permute( i.y + vec3(0.0, i1.y, 1.0 ))
        + i.x + vec3(0.0, i1.x, 1.0 ));

      vec3 m = max(0.5 - vec3(dot(x0,x0), dot(x12.xy,x12.xy), dot(x12.zw,x12.zw)), 0.0);
      m = m*m ;
      m = m*m ;

      vec3 x = 2.0 * fract(p * C.www) - 1.0;
      vec3 h = abs(x) - 0.5;
      vec3 ox = floor(x + 0.5);
      vec3 a0 = x - ox;

      m *= 1.79284291400159 - 0.85373472095314 * (a0*a0+h*h);

      vec3 g;
      g.x  = a0.x  * x0.x  + h.x  * x0.y;
      g.yz = a0.yz * x12.xz + h.yz * x12.yw;
      return 130.0 * dot(m, g);
    }

	// Add rotation helper to make the noise feel more organic, not like panning
    vec2 rotate(vec2 v, float a) {
      float s = sin(a);
      float c = cos(a);
      mat2 m = mat2(c, -s, s, c);
      return m * v;
    }

	// Use 3 "octaves" of noise to create a layered effect
	// (we're not actually using octaves, I want the layering to be a 
	// bit more subtle, so we're just modulating the amplitude and changing
	// the offset and rotation of each layer)
    float layeredNoise(vec2 position, float time) {
      float n = 0.0;
      float amplitude = 2.0;
      
      // Define the offsets and rotation speeds for each layer
      vec2 offsets[3] = vec2[](vec2(1.7, 9.2), vec2(-3.1, 4.3), vec2(5.8, -7.1));
      float rotationSpeeds[3] = float[](0.1, -0.2, 0.3);
      
      for (int i = 0; i < 3; i++) {
        vec2 newPos = position + offsets[i] * time;  // Apply unique offset per layer
        float rotation = rotationSpeeds[i] * time;  // Unique rotation speed per layer
        newPos = rotate(newPos, rotation);
        n += snoise(newPos) * amplitude;
        amplitude *= 0.5;  // Decrease the amplitude each layer
      }
      
      return n;
    }

    void main() {
    	// You can think of scale as the "zoom" level of the noise.
    	// Higher values will make the noise appear smaller and more detailed.
    	float scale = 8.0;
        vec2 pos = vUv * scale;
        
        float speed = 0.04;
        float noiseValue = 0.5 * (1.0 + layeredNoise(pos, u_time * speed));
        
        vec3 color1 = vec3(0.42, 0.41, 1.0); //#6C69FF
        vec3 color2 = vec3(0.76, 0.37, 1.0); //#C260FF

        // Interpolate between the two colors based on the noise value
        vec3 color = mix(color1, color2, noiseValue);

        // Output the color to the screen
        gl_FragColor = vec4(color, 1.0);
    }
`

const Autopilot: FC<ButtonProps> = (props) => {
	const ref = useRef(null)

	useEffect(() => {
		if (!ref.current) return () => {}
		if (!isWebGLAvailable()) return () => {}
		const scene = new Scene()
		const canvas = ref.current as HTMLCanvasElement
		const parent = canvas.parentElement.parentElement // Since the canvas is wrapped in a span

		const parentWidth = parent.clientWidth || 500
		const parentHeight = parent.clientHeight || 500
		const camera = new PerspectiveCamera(75, 2, 0.1, 1000)
		camera.position.z = 0.2

		const renderer = new WebGLRenderer({ canvas: ref.current as HTMLCanvasElement, alpha: true })
		const pixelRatio = Math.max(2, window.devicePixelRatio)
		renderer.setPixelRatio(pixelRatio)
		renderer.setSize(parentWidth, parentHeight)
		renderer.setClearColor(0x000000, 0)
		renderer.toneMapping = THREE.ReinhardToneMapping

		const resizeCanvas = () => {
			const parent = canvas.parentElement.parentElement // Since the canvas is wrapped in a span

			const parentWidth = parent.clientWidth || 500
			const parentHeight = parent.clientHeight || 500
			camera.aspect = parentWidth / parentHeight
			camera.updateProjectionMatrix()
			renderer.setSize(parentWidth, parentHeight)
		}

		window.addEventListener('resize', resizeCanvas)

		const geometry = new THREE.PlaneGeometry(5, 5) // Simple quad
		const material = new THREE.ShaderMaterial({
			uniforms: {
				u_time: { value: 0.0 },
			},
			vertexShader: vertexShader,
			fragmentShader: fragmentShader,
		})
		const quad = new THREE.Mesh(geometry, material)
		scene.add(quad)

		const clock = new THREE.Clock()
		let animationID: number
		const animate = () => {
			animationID = requestAnimationFrame(animate)
			material.uniforms.u_time.value = clock.getElapsedTime()
			renderer.render(scene, camera)
		}

		animationID = requestAnimationFrame(animate)

		return () => {
			cancelAnimationFrame(animationID)
			window.removeEventListener('resize', resizeCanvas)
		}
	}, [])

	return (
		<Button
			{...props}
			injectedClass={styles.autopilot}
			spinnerColor={'black'}
		>
			<canvas ref={ref} />
			{props.children}
		</Button>
	)
}

Button.Primary = Primary
Button.White = White
Button.Blue = Blue
Button.Dark = Dark
Button.TextLink = TextLink
Button.TextLinkWhite = TextLinkWhite
Button.Empty = Empty
Button.Plus = Plus
Button.PlusSmall = PlusSmall
Button.VideoGrey = VideoGrey
Button.InfoGrey = InfoGrey
Button.VideoGreyOutline = VideoGreyOutline
Button.Autopilot = Autopilot
