import { FC, useEffect, useRef, useState } from 'react'
import { StackItemProps } from 'components/Phantom/_shop/Visuals'
import useIsMobile from 'hooks/useIsMobile'
import { ImageProps, VideoProps } from 'components/_utils/utilityTypes'
import { Img, srcToImgix } from 'components/basic/Img'
import styles from './MediaRendered.module.scss'
import { Button } from 'components/Phantom/Button'
import { Icon } from 'components/Phantom/Icon'
import { VideoGif } from 'components/basic/VideoGif'

export const MediaRendered: FC<StackItemProps & { active: boolean }> = (props) => {
	const { type, data, active } = props

	const [muted, setMuted] = useState(true)

	const videoRef = useRef<HTMLVideoElement>(null)

	const isMobile = useIsMobile(600)

	useEffect(() => {
		if (!videoRef.current) return
		if (type === 'video') {
			if (active) {
				videoRef.current.currentTime = 0
				videoRef.current.play()
			} else {
				videoRef.current.pause()
			}
		}
	}, [active])

	switch (type) {
		case 'image': {
			const _data = data as ImageProps

			return (
				<Img
					src={_data.src}
					alt={_data.alt}
					sources={processImageSources(_data)}
					// objectFit={'cover'}
					dprHeight={_data.dprHeight ?? 1500}
					doNotCompress={_data.doNotCompress}
					objectFit={_data.objectFit}
					className={_data.className ? styles[_data.className] : undefined}
				/>
			)
		}
		case 'video': {
			const _data = data as VideoProps
			return (
				<div className={styles.video_wrapper}>
					<video
						src={isMobile && _data.mobileSrc ? _data.mobileSrc : _data.src}
						poster={processVideoPoster(_data.poster)}
						controls={false}
						autoPlay={false}
						muted={muted}
						playsInline
						loop
						ref={videoRef}
					/>
					<Button.Empty
						id={'video-mute'}
						className={styles.video_mute}
						onClick={() => setMuted((prev) => !prev)}
					>
						<Icon
							name={muted ? 'SoundOffLight' : 'SoundLight'}
							color={'white'}
						/>
					</Button.Empty>
				</div>
			)
		}
		case 'video-gif': {
			const _data = data as VideoProps
			return (
				<VideoGif
					src={_data.src}
					poster={_data.poster}
				/>
			)
		}
	}
}

const processVideoPoster = (poster?: string) => {
	if (!poster) return undefined

	return srcToImgix(poster)
}

const processImageSources = (data: ImageProps) => {
	if (!data.mobileSrc) return undefined

	const sources = [
		{
			mediaQuery: '(max-width:599px)',
			src: data.mobileSrc,
		},
	]

	if (data.tabletSrc) {
		sources.push(
			{
				mediaQuery: '(min-width:600px) and (max-width:1023px)',
				src: data.tabletSrc,
			},
			{
				mediaQuery: '(min-width:1023px)',
				src: data.src,
			}
		)
	} else {
		sources.push({
			mediaQuery: '(min-width:600px)',
			src: data.src,
		})
	}

	return sources
}
