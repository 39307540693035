import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { trackCartOpen } from 'events/index'
import { RootStore } from 'stores'
import { NavBar } from 'components/NavBar/NavBar'
import { FC } from 'react'
import { amClickPromoBar } from 'events/amplitude'
import { CleanNav } from 'components/CleanNav'

interface Props {
	any?: string
	rootStore?: RootStore
}

const HeaderComponent: FC<Props> = (props) => {
	const openCart = () => {
		trackCartOpen()
		props.rootStore.cartStoreNew.toggleCartOpen()
	}

	const { settingsStore, promoStore, cartStoreNew, priceStore } = props.rootStore
	const { currentPage, currentRegion } = props.rootStore.settingsStore
	const endDate = settingsStore.saleEndDate

	const promoBarClick = () => {
		amClickPromoBar()
		cartStoreNew.redirectToCheckout()
	}

	// const promoLink = cartStoreNew.hasPodOrCover ? undefined : promoStore.promoBarLink
	const promoLink = promoStore.promoBarLink
	const activeTimer = settingsStore.settings.activeTimer
	const showTimer = promoStore.showTimer

	const getPromoMessage = () => {
		// if (cartStoreNew.hasPodOrCover) {
		// 	const addComma = activeTimer && showTimer && endDate ? ',' : ''
		//
		// 	return `Check out now and get up to ${priceStore.allProductsDiscountNumberFormatted} off${addComma}`
		// }

		return promoStore.promoBarTitle
	}

	const promoMessage = getPromoMessage()

	let path = currentPage.split('?')[0].replace(`/${currentRegion}`, '')
	if (!path.endsWith('/')) {
		path = `${path}/`
	}
	if (!path.startsWith('/')) {
		path = `/${path}`
	}

	const isCoverLP = path === '/pod-cover/'
	const isProspecting = path === '/pod-prospecting/' || path === '/cooling-sleep/' || path === '/pod-compare/' || path === '/pod-prospecting-yt/'
	const isMember = ['/pod4-member/', '/product/pod4-insert', '/member-accessories/'].includes(path)

	const isShopPage = path.includes('product')

	const isVanity = settingsStore.isVanity

	const handleLocalize = currentRegion === 'eu' && typeof window !== 'undefined'

	if (path === '/sleep-elixir-waitlist/') {
		return (
			<CleanNav
				region={currentRegion}
				saleName={settingsStore.settings.saleName}
			/>
		)
	}

	return (
		<NavBar
			promo_bar_text={promoMessage}
			promo_bar_href={promoLink}
			promo_bar_date={endDate}
			// promo_bar_click={cartStoreNew.hasPodOrCover && promoBarClick}
			region={currentRegion}
			cartClick={openCart}
			numberOfCartItems={cartStoreNew.numItems}
			ctaVisible={!isShopPage}
			ctaLabel={path === '/sleep-elixir-waitlist/' ? 'Shop the Pod' : 'Shop now'}
			activeTimer={activeTimer}
			showTimer={showTimer}
			excludeLinks={isMember || isProspecting}
			onlyLogo={isProspecting}
			ctaAnchorHref={isProspecting ? '#shop' : null}
			transparency={settingsStore.navBarTransparency}
			isCoverLPAB={isCoverLP}
			isVanity={isVanity}
			saleName={settingsStore.settings.saleName}
			onPickerClick={
				handleLocalize
					? () => {
							props.rootStore.modalStore.toggleLocalizeModal()
					  }
					: undefined
			}
			renderCountryPicker={isShopPage}
		/>
	)
}

export const Header = inject('rootStore')(observer(HeaderComponent))

export default Header
