import { NextRequest, NextResponse } from 'next/server'
import { geolocation } from '@vercel/functions'
import { chooseExperimentVariant, COOKIE_FOR_IDS, COOKIE_FOR_KEYS, getActiveExperiments, IVariant } from 'ab_testing'
import { config as region_config } from 'utils/internationalization'

export const config = {
	matcher: '/((?!api|blog|truemed-order|vanities|getcart|_next|static|public|favicon|robots|apple|sitemap|android|manifest|.well-known).*)',
}

export const MAIN_EXPERIMENT = 'root'

export function middleware(req: NextRequest) {
	let response: NextResponse
	const cookie1 = req.cookies.get(COOKIE_FOR_IDS)?.value
	const cookie2 = req.cookies.get(COOKIE_FOR_KEYS)?.value
	const cookie_ab_tests = cookie1?.split(';')
	const cookie_ab_tests_keys = cookie2?.split(';')

	const {} = req
	const geo = geolocation(req)
	const country = geo?.country ?? 'US'
	const city = geo?.city ?? 'San Francisco'
	const region = geo?.region ?? 'CA'

	const IS_NEW_YORK = country === 'US' && region === 'NY'
	const IS_CALIFORNIA = country === 'US' && region === 'CA'

	const url = req.nextUrl
	const fullURL = url.href.toLowerCase()
	const original_pathname = url.pathname

	if (!region_config.allRegions.find((it) => original_pathname.startsWith(`/${it}/`))) {
		url.pathname = original_pathname.replace('/', '/us/')
	}

	const skipABTest = fullURL.toLowerCase().includes('automated=true')
	if (skipABTest) {
		url.pathname = url.pathname.replace('/', `/${MAIN_EXPERIMENT}/`)
		return NextResponse.rewrite(url)
	}

	const active_experiments = getActiveExperiments()
	const testVariants: string[] = []
	const testKeys: string[] = []
	active_experiments.forEach((test) => {
		const active_cookie_test = cookie_ab_tests ? cookie_ab_tests.find((it) => it.startsWith(test.id)) : null
		const active_cookie_test_key = cookie_ab_tests_keys ? cookie_ab_tests_keys.find((it) => it.startsWith(test.key)) : null
		if (active_cookie_test && active_cookie_test_key && !!test.variants.find((it) => it.id === Number(active_cookie_test.split('.')[1]))) {
			testVariants.push(active_cookie_test)
			testKeys.push(active_cookie_test_key)
		} else if ((test.paths.length === 0 || test.paths.includes(original_pathname)) && !test.excludedPaths?.includes(original_pathname)) {
			let variant: IVariant = null
			do {
				variant = chooseExperimentVariant(test)
			} while (variant.exclude && variant.exclude.some((it) => fullURL.includes(it)))
			testVariants.push(`${test.id}.${variant.id}`)
			testKeys.push(`${test.key}_${variant.id}`)
		}
	})

	if (testVariants.length > 0) {
		// inject into the URL only those experiments which are not in the default variant 0
		const active_variants = testVariants
			.filter((it) => !it.endsWith('.0'))
			.filter((it) => {
				const at = active_experiments.find((test) => test.id === it.split('.')[0])
				return at && !at.client_only && (at.paths.length === 0 || at.paths.includes(original_pathname)) && !at.excludedPaths?.includes(original_pathname)
			})

		active_variants.sort((a, b) => a.length - b.length || a.localeCompare(b))

		url.pathname = active_variants.length > 0 ? url.pathname.replace('/', `/${active_variants.join(';')}/`) : url.pathname.replace('/', `/${MAIN_EXPERIMENT}/`)
		response = NextResponse.rewrite(url)

		const new_id_cookie = testVariants.join(';')
		if (cookie1 !== new_id_cookie) {
			const keys_cookie = testKeys.join(';')
			const days = 60
			const domain = !url.hostname.startsWith('localhost') ? `.${url.hostname}` : url.hostname
			response.cookies.set(COOKIE_FOR_IDS, new_id_cookie, { maxAge: 60 * 60 * 24 * days, domain })
			response.cookies.set(COOKIE_FOR_KEYS, keys_cookie, { maxAge: 60 * 60 * 24 * days, domain })
		}
	} else {
		url.pathname = url.pathname.replace('/', `/${MAIN_EXPERIMENT}/`)
		response = NextResponse.rewrite(url)
	}

	response.cookies.set('geo-country', country, { maxAge: 60 * 60 * 24 })

	return response
}
