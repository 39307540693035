import { PortableText, PortableTextComponents } from '@portabletext/react'
import { A } from 'components/basic/A'
import { Img } from 'components/basic/Img'
import styles from './PortableTextRenderer.module.scss'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const portableTextComponents: PortableTextComponents = {
	types: {
		image: ({ value }) => (
			<Img
				src={value.asset.url}
				alt={value.alt || 'Image'}
			/>
		),
	},
	marks: {
		link: ({ children, value }) => {
			if (value.href === 'AFFIRM-MODAL') {
				return (
					<a
						className={'affirm-site-modal'}
						data-provider="Affirm"
						data-track="['click','mouseover'], 'Financing', 'Pre qualify button for Affirm'"
						tabIndex={0}
						aria-label="Pre qualify button for affirm"
					>
						{children}
					</a>
				)
			}

			if (value.href.startsWith('#')) {
				const query = new URLSearchParams(value.href.split('#')[1])
				const offset = query.get('offset')

				const hrefWithoutQueryParams = value.href.split('?')[0]

				return (
					<AnchorLink
						offset={offset ? parseInt(offset) : 0}
						href={hrefWithoutQueryParams}
						onClick={(e) => {
							e.preventDefault()
						}}
					>
						{children}
					</AnchorLink>
				)
			}

			return (
				<A
					href={value.href}
					target={value.blank ? '_blank' : '_self'}
					id={value.id}
				>
					{children}
				</A>
			)
		},
	},
	block: {
		normal: ({ children }) => {
			if (!children.toString()) {
				return <br />
			}
			return <span className={styles.normal_span}>{children}</span>
		},
	},
	list: {
		bullet: ({ children }) => <ul>{children}</ul>,
		number: ({ children }) => <ol className={styles.number_list}>{children}</ol>,
	},
	listItem: {
		bullet: ({ children }) => <li>{children}</li>,
		number: ({ children }) => <li>{children}</li>,
	},
}

export const PortableTextRenderer = ({ value }: { value: any }) => {
	return (
		<PortableText
			value={value}
			components={portableTextComponents}
		/>
	)
}
