import { EMAIL_SMS_DISCOUNT_CODE, GLOBAL_SALE_DISCOUNT_CODE } from 'stripe_lib/discounts'
import productDescriptions from '../constants/productDescriptions'

export const b2bSaleText = {
	LAMBDA15: {
		saleTag: '15% OFF WITH CODE LAMBDA15',
		productText:
			'Eight Sleep wants to ensure that everyone has the tools to improve their sleep quality. That is why we are offering Lambda School an exclusive 15% off of the Eight Sleep Pod and Pod 3, the #1 smart mattress. Code auto-applied at checkout.',
		lineItemText: 'LAMBDA SCHOOL EXCLUSIVE: 15% Off',
	},
}

export const EMAIL_PROMO_QUERY = 'utm_medium=email&utm_source=klaviyo'
export const ONE_TEXT_PROMO_QUERY = 'utm_source=email&utm_medium=sms'
export const SMS_PROMO_QUERY = 'utm_medium=sms&utm_source=klaviyo'

const memberPromo = {
	queryParams: ['member-accessories=1'],
	sessionItem: 'member_accessories_email',
	localItem: 'member',
	settings: {
		promoBar: {
			'*': {
				message: 'Member Better Me, Better Sleep Sale: Get up to ### off',
				link: null,
			},
			'/pod-cover': {
				message: 'Member Better Me, Better Sleep Sale: Get up to ### off',
				link: null,
			},
			'/product/pod-cover': {
				message: 'Member Better Me, Better Sleep Sale: Get up to ###  off',
				link: null,
			},
			'/member-accessories': {
				message: 'Member Better Me, Better Sleep Sale: Get up to ###  off',
				link: null,
			},
			'/product/sleep-elixir': {
				message: 'Discover calm before bedtime with Sleep Elixir',
				link: null,
			},
		},
		promoCode: 'members',
	},
}

const pod4promo = {
	path: '/pod4-member',
	sessionItem: 'member_accessories_email',
	localItem: 'member',
	settings: {
		promoBar: {
			'*': {
				message: 'Member Better Me, Better Sleep Sale: Get up to ### off',
				link: null,
			},
			'/pod-cover': {
				message: 'Member Better Me, Better Sleep Sale: Get up to ### off',
				link: null,
			},
			'/product/pod-cover': {
				message: 'Member Better Me, Better Sleep Sale: Get up to ### off',
				link: null,
			},
			'/member-accessories': {
				message: 'Member Better Me, Better Sleep Sale: Get up to ### off',
				link: null,
			},
			'/product/sleep-elixir': {
				message: 'Discover calm before bedtime with Sleep Elixir',
				link: null,
			},
		},
		promoCode: 'members',
	},
}

const theBasePromo = {
	path: '/product/the-base',
	sessionItem: 'member_accessories_email',
	localItem: 'member',
	settings: {
		promoBar: {
			'/pod4-member': {
				message: 'Member Better Me, Better Sleep Sale: Get up to ### off',
				link: null,
			},
		},
		promoCode: 'members',
	},
}

const saPromo = {
	path: '/sa',
	sessionItem: 'sa_promo',
	settings: {
		promoBar: {
			'*': {
				message: 'Coming soon to Saudi Arabia',
				link: null,
			},
			'/sa': {
				message: 'Coming soon to Saudi Arabia',
				link: null,
			},
		},
		promoCode: GLOBAL_SALE_DISCOUNT_CODE.toLowerCase(),
	},
}

const urlPromos = [
	theBasePromo as any,
	pod4promo as any,
	memberPromo as any,
	saPromo as any,
	{
		queryParams: ['discount=snore50'],
		sessionItem: 'snore50_discount',
		settings: {
			promoBar: {
				'*': {
					message: 'Get $150 off Pod 4 Ultra with code SNORE50 →',
					link: {
						to: '/product/pod-cover',
					},
				},
				'/pod-prospecting': {
					message: 'Get $150 off Pod 4 Ultra with code SNORE50 →',
					link: {
						to: '/product/pod-cover',
					},
				},
				'/pod-prospecting-yt': {
					message: 'Get $150 off Pod 4 Ultra with code SNORE50 →',
					link: {
						to: '/product/pod-cover',
					},
				},
				'/pod-cover': {
					message: 'Get $150 off Pod 4 Ultra with code SNORE50 →',
					link: {
						to: '/product/pod-cover',
					},
				},
				'/product/pod-cover': {
					message: 'Get $150 off Pod 4 Ultra with code SNORE50',
					link: null,
				},
			},
			promoCode: 'snore50',
		},
	},

	{
		queryParams: ['utm_campaign=The%20Quantified%20Scientist'],
		sessionItem: 'quantified_scientist',
		settings: {
			promoBar: {
				'*': {
					message: 'Code TQS applied. Get up to $600 off at checkout.',
					link: null,
				},
				'/pod-prospecting': {
					message: 'Code TQS applied. Get up to $600 off at checkout.',
					link: null,
				},
				'/pod-prospecting-yt': {
					message: 'Code TQS applied. Get up to $600 off at checkout.',
					link: null,
				},
				'/pod-cover': {
					message: 'Code TQS applied. Get up to $600 off at checkout.',
					link: null,
				},
				'/product/pod-cover': { message: 'Code TQS applied. Get up to $600 off at checkout.', link: null },
			},
			promoCode: 'TQS',
		},
	},

	{
		sessionItem: 'paceline',
		path: '/paceline/',
		settings: {
			promoBar: {
				'*': {
					message: 'Up to ### off the Pod with code PACELINE',
					link: null,
				},
				'/paceline/': {
					message: 'Up to ### off the Pod with code PACELINE',
					link: null,
				},
			},
			promoCode: 'paceline',
		},
	},

	{
		sessionItem: 'efprocycling_member',
		path: '/efprocycling',
		settings: {
			promoBar: {
				'*': {
					message: 'EF Pro Cycling Exclusive: ### off the Pod with code EF200',
					link: null,
				},
				'/efprocycling': {
					message: 'EF Pro Cycling Exclusive: ### off the Pod with code EF200',
					link: null,
				},
			},
			promoCode: 'EF200',
		},
	},

	{
		sessionItem: 'ringer',
		sessionValue: '1',
		path: '/ringer',
		settings: {
			promoBar: {
				'*': {
					message: 'The Ringer Exclusive: Up to ### off the Pod with code RINGER',
					link: null,
				},
				'/ringer': {
					message: 'The Ringer Exclusive: Up to ### off the Pod with code RINGER',
					link: null,
				},
				'/pod-cover': {
					message: 'The Ringer Exclusive: Up to ### off the Pod with code RINGER',
					link: null,
				},
				'/product/pod-cover': {
					message: 'The Ringer Exclusive: Up to ### off the Pod with code RINGER',
					link: null,
				},
			},
			promoCode: 'RINGER',
		},
	},

	{
		queryParams: ['utm_source=friendbuy'],
		sessionItem: 'referral',
		saveParam: 'referralCode',
		settings: {
			promoBar: {
				priority: 1,
				'*': {
					message: 'Your friend gifted you up to ### off',
					link: null,
				},
			},
			promoCode: 'REFFRB',
			shopCardTag: '',
			stickyCardTag: '',
			saleShopSavingsTag: '',
			// activeTimer: true,
		},
	},

	{
		queryParams: ['utm_campaign=referral'],
		sessionItem: 'referral',
		saveParam: 'referralCode',
		settings: {
			promoBar: {
				priority: 1,
				'*': {
					message: 'Your friend gifted you up to ### off',
					link: null,
				},
			},
			promoCode: 'REFFRB',
			shopCardTag: '',
			stickyCardTag: '',
			saleShopSavingsTag: '',
			// activeTimer: true,
		},
	},

	{
		queryParams: ['utm_medium=partner&utm_source=remedy'],
		sessionItem: 'remedy',
		settings: {
			promoBar: {
				'*': {
					message: 'Exclusive to Remedy Place members: ### off the Pod Cover',
					link: null,
				},
				'/pod-cover': {
					message: '### off the Pod Cover',
					link: null,
				},
				'/product/pod-cover': {
					message: '### off the Pod Cover',
					link: null,
				},
			},
			promoCode: 'remedy',
		},
	},
	{
		queryParams: ['utm_medium=hypecard'],
		sessionItem: 'hypericeperks',
		settings: {
			promoBar: {
				'*': {
					message: 'Hypecard exclusive: 10% off the Pod Cover',
					link: null,
				},
				'/pod-cover': {
					message: 'Hypecard exclusive: 10% off the Pod Cover',
					link: null,
				},
				'/product/pod-cover': {
					message: 'Hypecard exclusive: 10% off the Pod Cover',
					link: null,
				},
			},
			promoCode: 'hypericeperks',
		},
	},
	{
		queryParams: ['utm_medium=equinoxspa'],
		sessionItem: 'equinoxspa',
		settings: {
			promoBar: {
				'*': {
					message: 'Equinox Spa Exclusive: ### off the Pod Cover',
					link: null,
				},
				'/pod-cover': {
					message: 'Equinox Spa Exclusive: ### off the Pod Cover',
					link: null,
				},
				'/product/pod-cover': {
					message: 'Equinox Spa Exclusive: ### off the Pod Cover',
					link: null,
				},
			},
			promoCode: 'equinoxspa',
		},
	},
	{
		queryParams: ['campaign=cancelled-order'],
		sessionItem: 'cancelled-order',
		settings: {
			promoBar: {
				'*': {
					message: 'Back in stock. Order now and save ###.',
					link: {
						to: '/product/pod-cover',
					},
				},
				'/pod-prospecting': {
					message: 'Back in stock. Order now and save ###.',
					link: {
						to: '/product/pod-cover',
					},
				},
				'/pod-prospecting-yt': {
					message: 'Back in stock. Order now and save ###.',
					link: {
						to: '/product/pod-cover',
					},
				},
				'/pod-cover': {
					message: 'Back in stock. Order now and save ###.',
					link: {
						to: '/product/pod-cover',
					},
				},
				'/product/pod-cover': {
					message: 'Back in stock. Order now and save ###.',
					link: null,
				},
			},
			promoCode: EMAIL_SMS_DISCOUNT_CODE.toLowerCase(),
			savingsTag: '',
		},
	},
	{
		queryParams: [EMAIL_PROMO_QUERY],
		sessionItem: 'welcome-email',
		settings: {
			promoBar: {
				'*': {
					message: 'Better Me, Better Sleep Sale: Up to ### off unlocked',
					link: {
						to: '/product/pod-cover',
					},
				},
				'/pod-prospecting': {
					message: 'Better Me, Better Sleep Sale: Up to ### off unlocked',
					link: {
						to: '/product/pod-cover',
					},
				},
				'/pod-prospecting-yt': {
					message: 'Better Me, Better Sleep Sale: Up to ### off unlocked',
					link: {
						to: '/product/pod-cover',
					},
				},
				'/pod-cover': {
					message: 'Better Me, Better Sleep Sale: Up to ### off unlocked',
					link: {
						to: '/product/pod-cover',
					},
				},
				'/product/pod-cover': {
					message: 'Better Me, Better Sleep Sale: Up to ### off unlocked',
					link: null,
				},
			},
			promoCode: EMAIL_SMS_DISCOUNT_CODE.toLowerCase(),
			savingsTag: '',
		},
	},
	{
		queryParams: [ONE_TEXT_PROMO_QUERY],
		sessionItem: 'welcome-email',
		settings: {
			promoBar: {
				'*': {
					message: 'Better Me, Better Sleep Sale: Up to ### off unlocked',
					link: null,
				},
				'/pod-prospecting': {
					message: 'Better Me, Better Sleep Sale: Up to ### off unlocked',
					link: null,
				},
				'/pod-prospecting-yt': {
					message: 'Better Me, Better Sleep Sale: Up to ### off unlocked',
					link: null,
				},
				'/pod-cover': {
					message: 'Better Me, Better Sleep Sale: Up to ### off unlocked',
					link: null,
				},
				'/product/pod-cover': {
					message: 'Better Me, Better Sleep Sale: Up to ### off unlocked',
					link: null,
				},
			},
			promoCode: EMAIL_SMS_DISCOUNT_CODE.toLowerCase(),
			savingsTag: '',
		},
	},
	{
		queryParams: [SMS_PROMO_QUERY],
		sessionItem: 'welcome-sms',
		settings: {
			// promoBar: {
			// 	'*': {
			// 		message: 'SMS offer applied. <u>Shop now</u> and get ### off Pod 4 Ultra',
			// 		link: {
			// 			to: '/product/pod-cover',
			// 		},
			// 	},
			// 	'/pod-prospecting': {
			// 		message: 'SMS offer applied. <u>Shop now</u> and get ### off Pod 4 Ultra',
			// 		link: {
			// 			to: '/product/pod-cover',
			// 		},
			// 	},
			// 	'/pod-cover': {
			// 		message: 'SMS offer applied. <u>Shop now</u> and get ### off Pod 4 Ultra',
			// 		link: {
			// 			to: '/product/pod-cover',
			// 		},
			// 	},
			// 	'/product/pod-cover': {
			// 		message: 'SMS offer applied. Shop now and get ### off Pod 4 Ultra',
			// 		link: null,
			// 	},
			// },
			promoCode: EMAIL_SMS_DISCOUNT_CODE.toLowerCase(),
			savingsTag: '',
		},
	},

	{
		queryParams: ['utm_campaign=1215_shipping_cutoff'],
		sessionItem: 'shippingcutoffone',
		settings: {
			promoBar: {
				'*': {
					message: 'Up to $250 off. Order by Dec 15 for Holiday Shipping',
					link: null,
				},
				'/pod-cover': {
					message: '$100 off. Order by Dec 15 for Holiday Shipping',
					link: null,
				},
				'/product/pod-cover': {
					message: '$100 off. Order by Dec 15 for Holiday Shipping',
					link: null,
				},
			},
		},
	},
	{
		queryParams: ['utm_campaign=1218_shipping_cutoff'],
		sessionItem: 'shippingcutofftwo',
		settings: {
			promoBar: {
				'*': {
					message: 'Up to $250 off. Order by Dec 18 for Holiday Shipping',
					link: null,
				},
				'/pod-cover': {
					message: '$100 off. Order by Dec 18 for Holiday Shipping',
					link: null,
				},
				'/product/pod-cover': {
					message: '$100 off. Order by Dec 18 for Holiday Shipping',
					link: null,
				},
			},
		},
	},

	{
		queryParams: ['new_financing'],
		sessionItem: 'new_financing',
		settings: {
			promoBar: {
				'*': {
					message: `
            <p class="promo-new-financing no-mb">
              New financing <span class="temp">options now</span> available
              <img class="affirm" src="https://eightsleep.imgix.net/affirm_white_2.png?v=1598556944" alt="Affirm logo"/>
              <span class="link">Learn more</span>
            </p>
          `,
					link: null,
				},
			},
		},
	},

	{
		queryParams: ['btrpod=1'],
		sessionItem: 'PodProUpgradeEmail',
		sessionValue: '1',
		settings: {
			promoBar: {
				'*': {
					message: 'Code BTRPOD applied. $200 off the Pod 3 at checkout',
					link: null,
				},
			},
			promoCode: 'BTRPOD',
		},
	},

	{
		queryParams: ['btrcover=1'],
		sessionItem: 'PodProUpgradeEmail',
		sessionValue: '1',
		settings: {
			promoBar: {
				'*': {
					message: 'Code BTRCOVER applied. $100 off the Pod 3 Cover at checkout',
					link: null,
				},
			},
			promoCode: 'BTRCOVER',
		},
	},

	{
		queryParams: ['discounted_venomback_legpulse2_hypervoltplus=1'],
		sessionItem: 'venomback_normatech_hypervolt_email',
		sessionValue: '3',
		settings: {
			promoBar: {
				'*': {
					message: 'MEMBER EXCLUSIVE Get 10% off all Hyperice Products, discount automatically applied at checkout',
					link: null,
				},
			},
			promoCode: 'MEMBERACCESS10',
		},
	},

	{
		queryParams: ['employer_discount='],
		sessionItem: 'b2b_discount',
		sessionValue: 'LAMBDA',
		savingsTag: '15% OFF WITH CODE LAMBDA15',
		settings: {
			podShopPromoPill: 'LAMBDA SCHOOL EXCLUSIVE: 15% Off',
			alternatePodDescription:
				productDescriptions.podDescription +
				'<br/><br/>Eight Sleep wants to ensure that everyone has the tools to improve their sleep quality. That is why we are offering Lambda School an exclusive 15% off of the Eight Sleep Pod and Pod 3, the #1 smart mattress. Code auto-applied at checkout.',
			mainPodDescription:
				productDescriptions.podProDescription +
				'<br/><br/>Eight Sleep wants to ensure that everyone has the tools to improve their sleep quality. That is why we are offering Lambda School an exclusive 15% off of the Eight Sleep Pod and Pod 3, the #1 smart mattress. Code auto-applied at checkout.',
			promoBar: {
				'*': {
					message: '15% OFF WITH CODE LAMBDA15',
					link: null,
				},
			},
			promoCode: 'LAMBDA15',
		},
	},
	{
		queryParams: ['sweeps10=1'],
		sessionItem: 'sweeps10',
		settings: {
			promoBar: {
				'/accessories': {
					message: 'Enjoy 10% off best-selling Eight Sleep accessories. Discount applied at checkout.',
					link: null,
				},
				'/product/the-air-pillow': {
					message: 'Enjoy 10% off best-selling Eight Sleep accessories. Discount applied at checkout.',
					link: null,
				},
				'/product/the-pod-sheet-set': {
					message: 'Enjoy 10% off best-selling Eight Sleep accessories. Discount applied at checkout.',
					link: null,
				},
				'/product/air-lite-comforter': {
					message: 'Enjoy 10% off best-selling Eight Sleep accessories. Discount applied at checkout.',
					link: null,
				},
			},
			promoCode: 'SWEEPS10',
		},
	},
	{
		queryParams: ['foundation100=1'],
		sessionItem: 'foundation100',
		settings: {
			promoBar: {
				'/product/foundation': {
					message: 'Member savings activated: save $100 on the Pod Foundation. <br class="hideSmUp"/>Discount applied in checkout.',
					link: null,
				},
			},
			promoCode: 'FOUNDATION100',
		},
	},
	{
		queryParams: ['comfysale=1'],
		sessionItem: 'comfysale',
		settings: {
			promoBar: {
				'/product/air-lite-comforter': {
					message: 'Member savings activated: save 25% on the <br class="hideSmUp"/>Air Lite Comforter. Discount applied in checkout.',
					link: null,
				},
				'/product/waterproof-protector': {
					message: 'Member savings activated: save 25% on the <br class="hideSmUp"/> Pod Protector. Discount applied in checkout.',
					link: null,
				},
			},
			promoCode: 'COMFY25',
		},
	},
	{
		queryParams: ['member_comforter_flash=1'],
		sessionItem: 'member_comfort_flash',
		settings: {
			promoBar: {
				'/product/air-lite-comforter': {
					message: 'Member Exclusive: 30% off the <br class="hideSmUp"/>Air Lite Comforter. Discount applied at checkout.',
					link: null,
				},
			},
			promoCode: 'COMFORTER30',
		},
	},
	{
		queryParams: ['utm_source=influencer&utm_medium=kristykowal'],
		sessionItem: 'kristykowal',
		settings: {
			promoBar: {
				'*': {
					message: 'Code KRISTY150 applied.<br class="hideSmUp"/> Get $150 off in checkout.',
					link: null,
				},
			},
			promoCode: 'KRISTY150',
		},
	},

	{
		queryParams: ['utm_source=impact&utm_campaign=miguel%20bertonatti'],
		sessionItem: 'Miguel',
		settings: {
			promoBar: {
				'*': {
					message: 'Code PC150 applied. Get $150 off the Pod Cover in checkout.',
					link: null,
				},
			},
			promoCode: 'PC150',
		},
	},

	{
		queryParams: ['utm_source=impact&utm_campaign=cameron%20secore'],
		sessionItem: 'Cam',
		settings: {
			promoBar: {
				'*': {
					message: 'Code CAM applied. Get up to $350 off at checkout.',
					link: null,
				},
				'/pod-prospecting': {
					message: 'Code CAM applied. Get up to $350 off at checkout.',
					link: null,
				},
				'/pod-prospecting-yt': {
					message: 'Code CAM applied. Get up to $350 off at checkout.',
					link: null,
				},
				'/pod-cover': {
					message: 'Code CAM applied. Get up to $350 off at checkout.',
					link: null,
				},
				'/product/pod-cover': {
					message: 'Code CAM applied. Get up to $350 off at checkout.',
					link: null,
				},
			},
			promoCode: 'CAM',
		},
	},

	{
		queryParams: ['air_bday'],
		sessionItem: 'air_bday',
		settings: {
			promoBar: {
				'*': {
					message: 'Birthday Special: 30% off the Air Lite <br class="hideSmUp"/> Comforter. Discount applied at checkout.',
					link: null,
				},
			},
			promoCode: 'BIRTHDAY30',
			airliteShopPromoPill: 'Birthday Special: 30% off',
		},
	},
	{
		queryParams: ['canada=1'],
		sessionItem: 'canada',
		settings: {
			promoBar: {
				'/product/pod-cover': {
					message: 'Now available in Canada: Pod Cover. Save $200 through May 7.',
					link: null,
				},
			},
		},
	},
	{
		queryParams: ['utm_content=01-29-2022'],
		sessionItem: 'flashsale',
		settings: {
			promoCode: 'FLASHSALE',
		},
	},
	{
		queryParams: ['utm_source=hospitality_sleep_report'],
		sessionItem: 'hospitality-sleep-report',
		settings: {
			promoBar: {
				'*': {
					message: 'Exclusive for Hotel guests: $150 off the Pod with code TRAVEL',
					link: null,
				},
			},
			promoCode: 'travel',
		},
	},
]

export const getUrlPromoSettings = (urlSearch: string) => {
	let settings = null
	// path for previous bug
	sessionStorage.removeItem('undefined')

	if (typeof window !== 'undefined' && window && window.location.href && (window.location.pathname.includes('/member-accessories') || window.location.pathname.includes('/pod4-member'))) {
		settings = memberPromo.settings
		settings.promoCode = memberPromo.settings.promoCode
		if (settings && memberPromo.localItem) {
			localStorage.setItem(memberPromo.localItem, 'true')
		}
		if (memberPromo.sessionItem) {
			sessionStorage.setItem(memberPromo.sessionItem, memberPromo.sessionValue ? memberPromo.sessionValue : 'true')
		}
	}

	const temp = urlSearch.toLowerCase().replace('?', '').split('&')
	for (const promo of urlPromos) {
		if (promo && !promo.queryParams && promo.path && promo.settings) {
			if (typeof window !== 'undefined' && window && window.location.pathname.includes(promo.path)) {
				settings = promo.settings
				settings.promoCode = promo.settings?.promoCode
				if (promo.sessionItem && promo.sessionValue) {
					sessionStorage.setItem(promo.sessionItem, promo.sessionValue ? promo.sessionValue : 'true')
				}
				break
			} else if (promo.sessionItem && promo.sessionValue && promo.path) {
				// don't do anything
			} else {
				continue
			}
		}
		let hasAllPartsNeeded = false
		let hasSessionItem = false

		if (promo.queryParams?.length > 0) {
			for (const qp of promo.queryParams) {
				const parameters = qp.split('&')
				if (parameters.length > 0 && temp.length > 0 && parameters.every((it) => temp.includes(it.toLowerCase()))) {
					if (promo.sessionItem) {
						sessionStorage.setItem(promo.sessionItem, promo.sessionValue ? promo.sessionValue : 'true')
					}
					hasAllPartsNeeded = true
				} else {
					hasAllPartsNeeded = false
					break
				}
			}
		}

		if (
			(promo.sessionItem && sessionStorage.getItem(promo.sessionItem) && promo.sessionValue && sessionStorage.getItem(promo.sessionItem) === promo.sessionValue) ||
			(!promo.sessionValue && promo.sessionItem && sessionStorage.getItem(promo.sessionItem))
		) {
			hasSessionItem = true
		}

		if (hasAllPartsNeeded || hasSessionItem) {
			settings = promo.settings
			settings.promoCode = promo.settings?.promoCode
			if (settings && promo.localItem) {
				localStorage.setItem(promo.localItem, 'true')
			}
			if (promo.saveParam) {
				try {
					const searchParams = new URLSearchParams(window.location.search)
					const val = searchParams.get(promo.saveParam)
					sessionStorage.setItem(promo.saveParam, val)
				} catch (e) {
					console.log("couldn't save queryparam " + promo.saveParam)
				}
			}
			break
		}
	}

	return settings
}

export default urlPromos
