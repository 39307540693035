import { PortableTextRenderer } from 'components/PortableTextRenderer'
import SlideDown from 'components/SlideDown'
import { FC } from 'react'
import styles from './Pod4FAQ.module.scss'
import { Pod4FAQProps } from './types'

export const Pod4FAQ: FC<Pod4FAQProps> = (props) => {
	const { faq, title = 'FAQs' } = props

	if (!faq) {
		return null
	}

	return (
		<div className={styles.container}>
			<div className={styles.inner}>
				<div className={styles.title}>{title}</div>
				<div className={styles.faqs}>
					{faq.map((qa, i) => (
						<SlideDown
							top={<p className={styles.question}>{qa.question}</p>}
							key={i}
							className={styles.question_container}
							inverted={true}
						>
							<span className={styles.answer}>
								<PortableTextRenderer value={qa.answer} />
							</span>
						</SlideDown>
					))}
				</div>
			</div>
		</div>
	)
}
