import { generatePathPermutationsForExperiments } from 'ab_testing'
import { setCookie } from 'config/utils'
import { PropsWithRootStoreAndInjectedABCookie } from 'constants/types'
import { GetServerSideProps, GetStaticPaths, GetStaticProps, GetStaticPropsContext } from 'next'
import type { NextRouter } from 'next/router'
import { Product } from 'products/types'
import { UrlObject } from 'url'

// https://dev.to/adrai/static-html-export-with-i18n-compatibility-in-nextjs-8cd
// https://github.com/Xairoo/nextjs-i18n-static-page-starter

export type RegionShort = '' | 'ca' | 'eu' | 'uk' | 'au' | 'se' | 'dk' | 'us' | 'ae'
export type RegionPageConfig = { [key: string]: RegionShort[] }
export type CountryMap = Record<RegionShort, string>
export const countryFlags: CountryMap = {
	'': 'https://eightsleep.imgix.net/USA_flag.svg?v=1645557678',
	us: 'https://eightsleep.imgix.net/USA_flag.svg?v=1645557678',
	ca: 'https://eightsleep.imgix.net/Canada_flag.svg?v=1645557692',
	uk: 'https://eightsleep.imgix.net/UK_flag.svg?v=1645557692',
	eu: 'https://eightsleep.imgix.net/EU_flag_4507c1b5-cecb-407f-8f26-3f320a8c32ce.svg?v=1645557692',
	au: 'https://eightsleep.imgix.net/au.svg?v=1651858106',
	se: 'https://eightsleep.imgix.net/se.svg?v=1677826325',
	dk: 'https://eightsleep.imgix.net/dk.svg?v=1677826325',
	ae: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/UAE-flag.png',
}

export const countryNames: CountryMap = {
	'': 'United States',
	us: 'United States',
	ca: 'Canada',
	uk: 'United Kingdom',
	eu: 'Europe',
	au: 'Australia',
	se: 'Sweden',
	dk: 'Denmark',
	ae: 'United Arab Emirates',
}

export const regions: RegionShort[] = ['', 'ca', 'eu', 'uk', 'au', 'se', 'dk', 'ae']

export const EU_REGIONS = ['eu', 'fi', 'se', 'dk', 'at', 'be', 'bg', 'cz', 'de', 'dk', 'ee', 'es', 'fr', 'hr', 'hu', 'ie', 'it', 'lt', 'lu', 'lv', 'nl', 'pl', 'pt', 'ro', 'se', 'si', 'sk']
export interface CountryPickerProps {
	region: RegionShort
	setRegion: (region: RegionShort) => void
	onClick?: () => void
}

export interface IConfig {
	defaultRegion: RegionShort
	regions: RegionShort[]
	allRegions: RegionShort[]
}

export type BaseLocalePageProps = {
	locale?: string | string[]
}

export const config: IConfig = {
	defaultRegion: '',
	regions: ['ca', 'uk', 'eu', 'au', 'se', 'dk', 'ae'],
	allRegions: ['us', 'ca', 'uk', 'eu', 'au', 'se', 'dk', 'ae'],
}

// make sure the pages listed here are correctly set up in /pages/[locale] and /pageCores
export const availableRegionsForPaths: RegionPageConfig = {
	'/': [...config.regions],
	'/financing/': ['ca'],
	'/accessories/': ['ca'],
	'/member-accessories/': [...config.regions],
	'/pod-cover/': [...config.regions],
	'/product/pod-cover/': [...config.regions],
	'/product/shop-pod-cover/': [...config.regions],
	'/accessibility/': [...config.regions],
	'/return-policy/': [...config.regions],
	'/press/': [...config.regions],
	'/wall-of-love/': [...config.regions],
}

export const hreflangMap: Record<string, string[]> = {
	us: ['en-us'],
	ca: ['en-ca'],
	uk: ['en-gb'],
	au: ['en-au'],
	se: ['en-se'],
	dk: ['en-dk'],
	ae: ['en-ae'],
	eu: EU_REGIONS.slice(1).map((it) => `en-${it}`),
}

export const getFullPath = (path: string) => {
	if (typeof window === 'undefined') {
		return path
	}
	return window.location.pathname + (window.location.search ? '?' + window.location.search : '')
}

export const extractRegionFromPath = (path: string): RegionShort => {
	let region: RegionShort = ''
	config.allRegions.forEach((r) => {
		if (path === '/' + r || path.startsWith('/' + r + '/')) {
			region = r
		}
	})
	return region
}

export const injectRegionIntoPath = (path: string | UrlObject, region?: RegionShort) => {
	if (!region || !path || region === 'us') {
		return path
	}

	if (typeof path === 'string') {
		if (path.indexOf('http') !== -1) {
			return path
		}
		return `${region ? '/' + region : ''}${path}`
	}

	if (path.pathname) {
		path.pathname = `${region ? '/' + region : ''}${path}`
	}

	return path
}

export const getStaticFunctionsWithLocale = (
	path?: string,
	options?: {
		fetchData?: (context: GetStaticPropsContext) => Promise<any>
		revalidate?: number
	}
) => {
	const regions = process.env.NEXT_PUBLIC_IS_PROD || process.env.NEXT_PUBLIC_IS_STAGING ? config.allRegions : ['us']

	const getStaticPaths: GetStaticPaths = async () => {
		return {
			paths: regions.map((l) => ({ params: { index: '', locale: l } })).flatMap((it) => generatePathPermutationsForExperiments(path).map((p) => ({ params: { ...it.params, experiments: p } }))),
			fallback: false,
		}
	}

	const getStaticProps: GetStaticProps = async (context) => {
		const baseProps = {
			props: {
				locale: context.params?.locale,
			},
		}

		if (!options?.fetchData) {
			return options?.revalidate ? { ...baseProps, revalidate: options.revalidate } : baseProps
		}

		try {
			const additionalData = await options.fetchData(context)
			return {
				...baseProps,
				props: {
					...baseProps.props,
					...additionalData,
				},
				...(options?.revalidate ? { revalidate: options.revalidate } : {}),
			}
		} catch (error) {
			console.error('Error fetching additional data:', error)
			return options?.revalidate ? { ...baseProps, revalidate: options.revalidate } : baseProps
		}
	}

	const getServerSideProps: GetServerSideProps<PropsWithRootStoreAndInjectedABCookie> = async ({ res, req }) => {
		// res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59')
		res.setHeader('Cache-Control', 'no-store')
		return {
			props: {
				'ab-tests': req.cookies['ab-tests'] ?? null,
			},
		}
	}

	return { getStaticPaths, getStaticProps, getServerSideProps }
}

export const regionIsEU = (region: RegionShort) => {
	return EU_REGIONS.includes(region)
}

export const regionIsMetric = (region: RegionShort) => {
	return region !== ''
}

export const redirectToRegion = (region: RegionShort, router: NextRouter) => {
	// const pathWithoutPrefix = getFullPath(router.asPath).replace(`/${extractRegionFromPath(getFullPath(router.asPath))}`, '')
	const pathWithoutPrefix = router.asPath.replace(`/${router.query.locale ?? extractRegionFromPath(router.asPath)}`, '')
	if (region === '') {
		setCookie('choseusa', 'true', 365)
	} else {
		setCookie('choseusa', 'false', 365)
	}
	window.location.href = `${region !== '' ? '/' + region : ''}${pathWithoutPrefix.startsWith('/') ? pathWithoutPrefix : '/' + pathWithoutPrefix}`
}

export const replaceMetricInProduct = (product: Product, region: RegionShort) => {
	const tagToExclude = regionIsMetric(region) ? 'nonMetric' : 'metric'
	const regex = new RegExp(`<${tagToExclude}>(.*?)</${tagToExclude}>`, 'g')
	//deletes the unwanted tag and contents, and then removes all tags
	product.description = product.description.map((desc) => desc.replace(regex, '').replace(/<(\/)?(non)?[M|m]etric>/g, ''))
	return product
}
