import { CSSProperties, FC } from 'react'
import { ThumbnailsProps } from 'components/ImageCarousel/ImageCarousel.types'
import styles from './Thumbnails.module.scss'
import { Button } from 'components/Phantom/Button'
import { StackItemProps } from 'components/Phantom/_shop/Visuals'
import { ImageProps, VideoProps } from 'components/_utils/utilityTypes'
import { Img } from 'components/basic/Img'
import { Icon } from 'components/Phantom/Icon'

export const Thumbnails: FC<ThumbnailsProps> = (props) => {
	const { items, currentIndex, setCurrentIndex, useDots, maxThumbnails = items.length } = props
	if (items.length <= 1) return null

	const getVisibleThumbnails = () => {
		const halfMax = Math.floor(maxThumbnails / 2)
		let start = 0
		let end = Math.min(maxThumbnails, items.length)

		if (currentIndex >= halfMax && items.length > maxThumbnails) {
			// If we're not near the end of the list
			if (currentIndex < items.length - halfMax) {
				start = currentIndex - halfMax
				end = start + maxThumbnails
			} else {
				// If we're near the end, show the last `maxThumbnails` items
				end = items.length
				start = Math.max(0, end - maxThumbnails)
			}
		}

		return items.slice(start, end)
	}

	const visibleThumbnails = getVisibleThumbnails()

	return (
		<ul
			className={styles.thumbnails}
			data-use-dots={useDots}
			style={{ '--num-columns': visibleThumbnails.length } as CSSProperties}
		>
			{visibleThumbnails.map((item) => {
				const actualIndex = items.indexOf(item)
				return (
					<li
						key={actualIndex}
						className={styles.thumbnail}
						data-highlight={actualIndex === currentIndex}
					>
						<Button.Empty
							id={`thumbnail-${actualIndex}`}
							ariaLabel={`Go to slide ${actualIndex + 1}`}
							className={styles.thumbnail_button}
							onClick={() => setCurrentIndex(actualIndex)}
						>
							<div className={styles.thumbnail_dot} />
							<ThumbnailRendered
								{...item}
								index={actualIndex}
								active={currentIndex === actualIndex}
							/>
						</Button.Empty>
					</li>
				)
			})}
		</ul>
	)
}

// ThumbnailRendered component remains unchanged

const ThumbnailRendered: FC<StackItemProps & { index: number; active: boolean }> = (props) => {
	const { type, data, index, active } = props

	switch (type) {
		case 'image': {
			const _data = data as ImageProps
			return (
				<Img
					src={_data.src}
					dprHeight={300}
					alt={`Thumbnail for slide ${index + 1}`}
					className={styles.thumbnail_image}
					objectFit={_data.objectFit ?? 'cover'}
				/>
			)
		}
		case 'video': {
			const _data = data as VideoProps
			return (
				<div className={styles.thumbnail_video_container}>
					<Icon
						name={active ? 'PauseDark' : 'PlayDark'}
						color={'white'}
					/>
					<Img
						src={_data.poster}
						dprHeight={300}
						alt={`Thumbnail for slide ${index + 1}`}
						className={styles.thumbnail_image}
						objectFit={'cover'}
					/>
				</div>
			)
		}
		case 'video-gif': {
			const _data = data as VideoProps
			return (
				<Img
					src={_data.poster}
					dprHeight={300}
					alt={`Thumbnail for slide ${index + 1}`}
					className={styles.thumbnail_image}
					objectFit={'cover'}
				/>
			)
		}
	}
}
