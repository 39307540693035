import { FC, useRef } from 'react'
import styles from './FourColumnBenefits.module.scss'
import { FourColumnBenefitsProps } from './FourColumnBenefits.types'
import { BenefitProps } from 'components/_utils/utilityTypes'
import { useAnimateInOnScroll } from 'components/_hooks/useAnimateInOnScroll'
import { Icon } from 'components/Phantom/Icon'
import { Type } from 'components/Type'

export const FourColumnBenefits: FC<FourColumnBenefitsProps> = (props) => {
	const { benefits = getDefaultBenefits(), header } = props
	return (
		<section className={styles.benefits_container}>
			<div className={styles.wrapper}>
				<Type.Headline3
					as={'h2'}
					className={styles.heading}
				>
					{header}
				</Type.Headline3>
				<ul className={styles.benefits_list}>
					{benefits.map((benefit, index) => (
						<li key={benefit.text}>
							<Benefit
								{...benefit}
								index={index}
							/>
						</li>
					))}
				</ul>
			</div>
		</section>
	)
}

const getDefaultBenefits = (): BenefitProps[] => [
	{
		icon: 'TemperatureLight',
		text: 'Perfect temperature all night',
		secondaryText: 'Keeps you and your partner at your optimal throughout the night',
	},
	{
		icon: 'MoonLight',
		text: `Optimized sleep quality`,
		secondaryText: 'Tailored adjustments to give you deeper sleep',
	},
	{
		icon: 'SnoringLight',
		text: `Reduced snoring`,
		secondaryText: 'Automated elevation changes to stop you or your partner’s snoring',
	},
	{
		icon: 'ChartDark',
		text: `No wearables needed`,
		secondaryText: 'The Pod’s intelligent sensors track your sleep and health vitals',
	},
]

const Benefit: FC<BenefitProps & { index: number }> = (props) => {
	const ref = useRef<HTMLDivElement>(null)
	useAnimateInOnScroll(ref, { delay: props.index * 0.1 })

	return (
		<div
			ref={ref}
			className={styles.benefit}
		>
			<Icon
				name={props.icon}
				color={'#636363'}
				size={32}
			/>
			<div>
				<Type.Headchapter>{props.text}</Type.Headchapter>
				<Type.Body2 className={styles.subcopy}>{props.secondaryText}</Type.Body2>
			</div>
		</div>
	)
}
