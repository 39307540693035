import { Type } from 'components/Type'
import styles from './Pod4ShopQualityPromises.module.scss'
import { VideoGif } from 'components/basic/VideoGif'
import { Button } from 'components/Phantom/Button'

export const Pod4ShopQualityPromises = () => {
	return (
		<section className={`inner-paddings-y ${styles.root}`}>
			<header className={styles.header}>
				<Type.Headline2 animateOnScroll>The Pod’s enduring quality promise </Type.Headline2>
			</header>
			<VideoGif
				src="https://d115sb6i1ixllw.cloudfront.net/assets/pod4_promises_no_sound.mp4"
				className={styles.desktop_only}
			/>

			<VideoGif
				className={styles.mobile_only}
				src="https://d115sb6i1ixllw.cloudfront.net/assets/pod4_promises_mob_no_sound.mp4 "
			/>
			<div className={styles.content}>
				<div>
					<Type.Headline4
						animateOnScroll
						as={'h3'}
					>
						Over 200 tests guarantee its lasting performance
					</Type.Headline4>
					<Type.Body1 animateOnScroll>
						Crafted with precision engineering and cutting-edge materials, Pod 4 was crafted to stand the test of time. Its design has undergone over 200 different tests that ensure durability,
						including simulating 10,000 use cycles.
					</Type.Body1>
				</div>
				<div>
					<Type.Headline4
						animateOnScroll
						as={'h3'}
					>
						Sleep with confidence with our warranty{' '}
					</Type.Headline4>
					<Type.Body1 animateOnScroll>
						Our dedication to quality ensures that each Pod comes with a warranty. Sleep soundly backed by our promise to maintain the highest standards of durability and support. Your comfort is
						secured by our commitment to excellence.
					</Type.Body1>
				</div>
			</div>

			<div className={styles.cta_container}>
				<Button.Dark
					id={'pod4-shop-quality-promises-button'}
					href={'#shop'}
					className={styles.button}
				>
					Shop now
				</Button.Dark>
			</div>
		</section>
	)
}

export default Pod4ShopQualityPromises
