import { FC, useEffect, useRef, useState } from 'react'
import styles from './EvolvingFormula.module.scss'
import { EvolvingFormulaProps } from './EvolvingFormula.types'
import { Type } from 'components/Type'
import animation from './pill_animation.json'
import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { useRefreshScrollTrigger } from 'components/_hooks/useRefreshScrollTrigger'

export const EvolvingFormulaConnected: FC<Partial<EvolvingFormulaProps>> = (props) => {
	const [Lottie, setLottie] = useState(undefined)

	async function loadLottie() {
		const dependency = await import('lottie-react')
		setLottie(dependency)
	}

	useEffect(() => {
		loadLottie()
	}, [])

	if (Lottie) {
		return (
			<EvolvingFormula
				Lottie={Lottie}
				{...props}
			/>
		)
	}

	return null
}

export const EvolvingFormula: FC<EvolvingFormulaProps> = (props) => {
	const { Lottie } = props

	const containerRef = useRef<HTMLDivElement>(null)

	const LottieObject = Lottie.useLottie({
		animationData: animation,
		loop: false,
		autoplay: false,
	})

	useGSAP(() => {
		gsap.registerPlugin(ScrollTrigger)
		LottieObject.goToAndStop(0, true)

		const NUM_FRAMES = 720
		ScrollTrigger.create({
			trigger: '#evolving-formula-container',
			start: 'top 40%',
			end: 'bottom 60%',
			scrub: 1,
			// markers: true,
			onUpdate: (self) => {
				const frame = Math.round(self.progress * NUM_FRAMES)
				LottieObject.goToAndStop(frame, true)
			},
		})
	}, [])

	useEffect(() => {
		const resizeHandler = () => {
			const element = document.getElementById('evolving-formula-lottie')

			const height = element.getBoundingClientRect().height
			const innerHeight = window.visualViewport?.height || window.innerHeight

			element.style.top = `${innerHeight / 2 - height / 2}px`
		}

		resizeHandler()
		window.addEventListener('resize', resizeHandler)
		return () => window.removeEventListener('resize', resizeHandler)
	}, [])

	useRefreshScrollTrigger(containerRef)

	return (
		<section
			className={styles.container}
			ref={containerRef}
		>
			<Type.Headline4
				className={styles.heading}
				as={'h2'}
				animateOnScroll
			>
				<span>An evolving formula based on</span>
				<em>
					600M<span>+</span>
				</em>
				<span>hours of sleep data</span>
			</Type.Headline4>
			<Type.Headchapter
				animateOnScroll
				className={styles.subheader}
			>
				Sleep Elixir combines a deep understanding of global sleep data with ongoing insights from real users. The result is a formula that’s always learning, always improving — and always working for
				you.
			</Type.Headchapter>

			<div
				id={'evolving-formula-container'}
				className={styles.lottie_wrapper}
			>
				<div
					className={styles.lottie_container}
					id={'evolving-formula-lottie'}
				>
					{LottieObject.View}
				</div>
			</div>
		</section>
	)
}
