import { IconComponentType } from '../../Icon.types'

const icon: IconComponentType = ({ size = 24, color = 'white', className = '', ariaLabel }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 16 16"
		fill={'none'}
		xmlns="http://www.w3.org/2000/svg"
		aria-label={ariaLabel}
		className={className}
	>
		<path
			d="M4.66667 7.33325H6V8.66658H4.66667V7.33325ZM4.66667 9.99992H6V11.3333H4.66667V9.99992ZM7.33333 7.33325H8.66667V8.66658H7.33333V7.33325ZM7.33333 9.99992H8.66667V11.3333H7.33333V9.99992ZM10 7.33325H11.3333V8.66658H10V7.33325ZM10 9.99992H11.3333V11.3333H10V9.99992Z"
			fill={color}
		/>
		<path
			d="M3.33333 14.6666H12.6667C13.402 14.6666 14 14.0686 14 13.3333V3.99992C14 3.26459 13.402 2.66659 12.6667 2.66659H11.3333V1.33325H10V2.66659H6V1.33325H4.66667V2.66659H3.33333C2.598 2.66659 2 3.26459 2 3.99992V13.3333C2 14.0686 2.598 14.6666 3.33333 14.6666ZM12.6667 5.33325L12.6673 13.3333H3.33333V5.33325H12.6667Z"
			fill={color}
		/>
	</svg>
)

export default icon
